import { Box, Typography, Button } from "@mui/material";
import QuickAdd from "../../shared/components/QuickAdd";
import { useNavigate } from "react-router";
//import ArticleIcon from "@mui/icons-material/Article";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { getPractices } from "../../admin/actions";
import * as actionType from "../actions";
//import { refundApplicationConstants } from "./../../shared/constants/constants";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
// import { FapRowData } from "../../workspace/actions";
// import { resetEstimateCards } from "../../workspace/actions";

const RequestedCycle = (props) => {
  const defaultValue = {
    patientNameOrId: ""
  };
  const {
    actions,

    loading,
    practices = [],
    requestedCycleList = []
  } = props;
  //const dispatch = useDispatch();
  const columns = [
    {
      field: "patientName",
      headerName: "PATIENT NAME & ID",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <div style={{ flexFlow: "row" }}>
            <Typography> {row.patientName}</Typography>
            <Typography> {row.personKey}</Typography>
          </div>
        );
      }
    },
    {
      field: "medcaseID",
      headerName: "MEDCASE ID",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{`${row.medcaseID}`}</Typography>;
      }
    },
    {
      field: "practiceName",
      headerName: "PRACTICE ",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{`${row.practiceName}`}</Typography>;
      }
    },
    {
      field: "progShortName",
      headerName: "PROGRAM",
      flex: 1
    },
    {
      field: "medcaseStatus",
      headerName: "MEDCASE STATUS",
      flex: 1,
      renderCell: ({ row }) => <Typography>{row.medcaseStatus}</Typography>
    },

    {
      field: "currentAuthorizationCycle",
      headerName: "CURRENT AUTH STATUS",
      flex: 1,
      renderCell: ({ row }) => (
        <Button
          color="primary"
          variant="text"
          onClick={() => {
            navigateToMedcasesScreen(row.medcaseID, row.cycleNo);
          }}
          className="word-wrap">
          {row.currentAuthorizationCycle}
        </Button>
      )
    },
    {
      field: "cycleNo",
      headerName: "CYCLE NO",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.cycleNo}</Typography>;
      }
    },
    {
      field: "normalEmbryosRemain",
      headerName: "NORMAL EMBRYOS REMAIN",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.normalEmbryosRemain}</Typography>;
      }
    },
    {
      field: "estimatedCycleStartDate",
      headerName: "ESTIMATED CYCLE START DATE",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography>
            {row.estimatedCycleStartDate
              ? moment(new Date(row.estimatedCycleStartDate)).format("L")
              : "--"}
          </Typography>
        );
      }
    },
    {
      field: "lastKnownEvent",
      headerName: "LAST KNOWN EVENT",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.lastKnownEvent}</Typography>;
      }
    },
    {
      field: "requestedCycle",
      headerName: "REQUESTED CYCLE",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.requestedCycle}</Typography>;
      }
    },
    {
      field: "patientAge",
      headerName: "PATIENT AGE",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.patientAge}</Typography>;
      }
    },
    {
      field: "contractExpDate",
      headerName: "CONTRACT EXPIRATION DATE",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.contractExpDate}</Typography>;
      }
    }
  ];
  const [rows, setRows] = useState([]);
  const [values, setValues] = useState(defaultValue);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const navigate = useNavigate();
  useEffect(() => {
    if (requestedCycleList.length === 0) {
      getList();
    }
  }, [values]);

  useEffect(() => {
    if (practices.length === 0) {
      actions.getPractices();
    }
  }, []);

  useEffect(() => {
    if (requestedCycleList.length) {
      let rows = requestedCycleList.map((item, index) => {
        item.id = index;
        return item;
      });
      setRows(rows);
    } else {
      setRows([]);
    }
  }, [loading]);

  const navigateToMedcasesScreen = (medcaseId, cycleNo) => {
    console.log("cycleNo", cycleNo);
    navigate(`/trak/caselookup/caselist/${medcaseId}/financial-snapshot?cycle=${cycleNo}`);
  };

  const getList = () => {
    actions.getRequestedCycleList({ values });
    //actions.getRefundApplicationList({ values });
  };
  // const handleInputChange = (e) => {
  //   actions.resetRefundApplicationList();
  //   const { name, value } = e.target;
  //   setValues({
  //     ...values,
  //     [name]: value
  //   });
  // };

  const handleSearchClick = (e, searchText) => {
    actions.resetRefundApplicationList();
    values.patientNameOrId = searchText;
    setValues({
      ...values
    });
    actions.getRequestedCycleList({ values });
  };

  const clearSearchClick = () => {
    values.patientNameOrId = "";
    setValues({ ...values });
  };

  return (
    <Box className="refund-application">
      <Box className="teal-bg-color pad-5">
        <QuickAdd
          text={1}
          search={7}
          handleSearchClick={handleSearchClick}
          clearSearchClick={clearSearchClick}
        />
        {/* <Grid container className="align-center pad-5">
          <Grid item xs={1}>
            <Typography>{refundApplicationConstants.status.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              name="refundApplicationStatus"
              fullWidth
              value={values?.refundApplicationStatus}
              onChange={handleInputChange}>
              {refundApplicationConstants.status.values.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={1}>
            <Typography ml={1}>Practice</Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              name="practiceId"
              fullWidth
              value={values.practiceId}
              onChange={handleInputChange}>
              {practices &&
                practices.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.name} - {item.practiceId}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
        </Grid>
        <Grid container className="align-center pad-5">
          <Grid item xs={1}>
            <Typography>{refundApplicationConstants.daysSinceCreation.name}</Typography>
          </Grid>
          <Grid item xs={3}>
            <Select
              name="daysSinceCreation"
              value={values?.daysSinceCreation}
              fullWidth
              onChange={handleInputChange}>
              {refundApplicationConstants.daysSinceCreation.values.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {`${item} days`}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid> */}
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <Typography>{rows.length} person matching your criteria</Typography>
        <DataGridPremiumComp
          loading={loading}
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          pageSizeOptions={[10, 25, 50, 100]}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          className="mt-8"
          pagination
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ practices, refundApplicationTrakReducer }) => {
  return {
    practices: practices.practices,
    refundApplicationList: refundApplicationTrakReducer.refundApplicationList,
    loading: refundApplicationTrakReducer.loading,
    error: refundApplicationTrakReducer.error,
    requestedCycleList: refundApplicationTrakReducer.requestedCycleList
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(getPractices());
    },
    getRefundApplicationList: (payload) => {
      dispatch(actionType.getRefundApplicationList(payload));
    },
    resetRefundApplicationList: () => {
      dispatch(actionType.resetRefundApplicationList());
    },
    getRequestedCycleList: (payload) => {
      dispatch(actionType.getRequestedCycleList(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestedCycle);
