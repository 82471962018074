import React, { useState, useEffect } from "react";
import { Close, Percent } from "@mui/icons-material";
// import { CalendarPicker } from "@mui/lab";s
import {
  Modal,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  IconButton,
  TextField,
  InputAdornment,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import moment from "moment/moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const GlobalDataModelPopup = (props) => {
  const [effectiveDate, setEffectiveDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [assistPricePercentage, setAssistPercent] = useState("");
  //const [minDate, setMinDate] = useState("");
  const [gdmStatusValue, setGdmStatus] = useState("In-progress");
  const gdmStatus = [
    { key: "Active", value: "Active" },
    { key: "In-progress", value: "In-progress" },
    { key: "Inactive", value: "Inactive" }
  ];
  const { data } = props;
  const handleClose = () => {
    setEffectiveDate("");
    setEndDate("");
    setAssistPercent("");
    props.handleClose();
  };
  const handleAssistPriceChange = (e) => {
    if (e.target.value <= 100) setAssistPercent(e.target.value);
  };

  const handleSave = () => {
    let gdmData = {};
    // const d = new Date();
    // let hour = d.getHours();
    // let MM = d.getMinutes();
    // let ss = d.getSeconds();
    // console.log("===>", d);
    // console.log("===>", hour + ":" + MM + ":" + ss);
    // const mmdate = moment().format("HH:mm:ss");
    // console.log("momentdate", mmdate);
    // const tz = moment.tz.guess();
    // console.log("momentdateTZ", tz);

    gdmData.status = gdmStatusValue;
    gdmData.effectiveDate = moment(effectiveDate.toString()).format("YYYY-MM-DD");
    gdmData.endDate = moment(endDate.toString()).format("YYYY-MM-DD");
    if (data)
      gdmData.assistPricePercentage =
        assistPricePercentage == "" ? data.assistPricePercentage : assistPricePercentage;
    else gdmData.assistPricePercentage = assistPricePercentage;

    // let chkdate = moment().isBefore(gdmData.effectiveDate);

    // if (!chkdate) {
    //   alert("Effective Date should be future date.");
    //   return;
    // }

    props.handleCreateGdm(gdmData);
    handleClose();
  };
  useEffect(() => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    const currDate = yyyy + "-" + mm + "-" + dd;
    console.log("currDate", currDate);
    //setMinDate(currDate);
    console.log("data", data);
    const formatDate = moment(data?.effectiveDate).format("YYYY-MM-DD");
    const formatEndDate = moment(data?.endDate).format("YYYY-MM-DD");
    if (props.edit) {
      setEffectiveDate(formatDate);
      setEndDate(formatEndDate);
      setAssistPercent(data.assistPricePercentage);
    } else {
      setEffectiveDate("");
      setEffectiveDate("");
      setAssistPercent("");
    }
  }, [props]);

  const handleGdmChange = (event) => {
    setGdmStatus(event.target.value);
  };

  return (
    <Modal open={props.open}>
      <Box className="modal-style">
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Typography>{props.edit ? "Edit Global Data Model" : "New Global Data Model"}</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Divider sx={{ backgroundColor: "#177483" }} orientation="horizontal" />
        <Stack
          direction="row"
          sx={{
            my: 2,
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <InputLabel sx={{ flex: 3 }} required>
            Effective Date
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              //minDate={minDate}
              value={effectiveDate}
              onChange={(newValue) => {
                setEffectiveDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} sx={{ flex: 5.5 }} />}
            />
          </LocalizationProvider>
        </Stack>
        <Stack
          direction="row"
          sx={{
            my: 2,
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <InputLabel sx={{ flex: 3 }} required>
            End Date
          </InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={endDate}
              onChange={(newValue) => {
                setEndDate(newValue);
              }}
              renderInput={(params) => <TextField {...params} sx={{ flex: 5.5 }} />}
            />
          </LocalizationProvider>
        </Stack>
        <Stack
          direction="row"
          sx={{
            my: 2,
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <Typography sx={{ flex: 3 }}>Assist Price % (If Applicable)</Typography>

          <TextField
            sx={{ flex: 5.5 }}
            type="number"
            value={assistPricePercentage}
            onChange={handleAssistPriceChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Percent sx={{ color: "#177483" }} />
                </InputAdornment>
              )
            }}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            my: 2,
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <Typography sx={{ flex: 3 }}>Status</Typography>

          <Select
            sx={{ flex: 5.5 }}
            name="gmId"
            value={gdmStatusValue}
            onChange={handleGdmChange}
            className="stage-practice-dropdown">
            {gdmStatus.map((item, index) => {
              return (
                <MenuItem value={item.key} key={index}>
                  {item.value}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
          <Typography className="stage-details-section">
            Note: Assist price will get calculated according to percent value entered above.
          </Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
          <Button variant="outlined" className="btn-primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            className="btn-usf"
            disabled={effectiveDate === ""}
            onClick={handleSave}>
            {props.edit ? "Save" : "Create"}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default GlobalDataModelPopup;
