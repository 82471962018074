import React, { useEffect, useState } from "react";
import moment from "moment";
import { Typography } from "@mui/material";
import { Stack, Box } from "@mui/system";
import { connect } from "react-redux";
import * as actionType from "../actions";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const ProviderReimbursementProgramCycle = (props) => {
  const { actions, programCycle, loading } = props;
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });

  useEffect(() => {
    actions.getProgramCycle({ medcaseId: props.medcaseId });
  }, [props.medcaseId]);

  const rows = programCycle.map((item, index) => {
    item.id = index;
    return item;
  });

  const columns = [
    {
      field: "clinicalTreatmentNo",
      headerName: "CYCLE NO",
      width: 70,
      sortable: false
    },
    {
      field: "authorizedTreatment",
      headerName: "AUTHORIZED TREATMENT",
      width: 100,
      editable: false
    },
    {
      field: "performedTreatment",
      headerName: "PERFORMED TREATMENT",
      width: 100,
      editable: false
    },
    {
      field: "outcome",
      headerName: "OUTCOME",
      width: 110,
      editable: false
    },
    {
      field: "outcomeDate",
      headerName: "OUTCOME DATE",
      width: 90,
      editable: false,
      renderCell: ({ row }) =>
        row.outcomeDate ? moment(row.outcomeDate).format("MM/DD/YYYY") : "--"
    },
    // {
    //   field: "embryosRemaining",
    //   headerName: "EMBRYOS REMAINING",
    //   width: 100,
    //   editable: false
    // },
    {
      field: "normalembryosRemaining",
      headerName: "NORMAL EMBRYOS REMAINING",
      width: 100,
      editable: false
    },
    {
      field: "transferDate",
      headerName: "TRANSFER DATE",
      width: 100,
      editable: false,
      renderCell: ({ row }) =>
        row.transferDate ? moment(row.transferDate).format("MM/DD/YYYY") : "--"
    },
    {
      field: "misscarriageDate",
      headerName: "MISS CARRIAGE DATE",
      width: 100,
      editable: false,
      renderCell: ({ row }) =>
        row.misscarriageDate ? moment(row.misscarriageDate).format("MM/DD/YYYY") : "--"
    },
    {
      field: "authorizedDate",
      headerName: "AUTH DATE",
      width: 90,
      editable: false,
      renderCell: ({ row }) =>
        row.authorizedDate ? moment(row.authorizedDate).format("MM/DD/YYYY") : "--"
    },
    {
      field: "clinicalDecisions",
      headerName: "CLINICAL DECISIONS",
      width: 80,
      editable: false
    },
    {
      field: "requestedCycle",
      headerName: "REQUESTED CYCLE",
      width: 90,
      editable: false,
      renderCell: ({ row }) => {
        let isWhole = Number.isInteger(row.requestedCycle);
        if (isWhole === true) {
          return row.requestedCycle + ".0";
        } else {
          return row.requestedCycle;
        }
      }
    },
    {
      field: "requestedDate",
      headerName: "REQUESTED DATE",
      width: 90,
      editable: false,
      renderCell: ({ row }) =>
        row.requestedDate ? moment(row.requestedDate).format("MM/DD/YYYY") : "--"
    }
  ];
  return (
    <>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="modal-stack">
          <Typography>Program Cycle</Typography>
        </Stack>
        <Box className="grid-style" sx={{ maxHeight: "650px" }}>
          <DataGridPremiumComp
            columns={columns}
            rows={rows}
            rowCount={rows.length}
            disabled
            loading={loading}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            pageSizeOptions={[10, 25, 50, 100]}
            pagination
          />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({ programCycleReducer }) => {
  return {
    programCycle: programCycleReducer.programCycle,
    loading: programCycleReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getProgramCycle: (payload) => {
      dispatch(actionType.getProgramCycle(payload));
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connect(mapStateToProps, mapDispatchToProps)(ProviderReimbursementProgramCycle));
