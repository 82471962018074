import jwtDecode from "jwt-decode";
import { userGroupFormat } from "../constants/rolerightsTable";
import { fetchAndStoreAWSTemporaryCredentails } from "../components/SignApi";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const getLoginScreenRights = () => {
  return localStorage.getItem("screenRights");
};

let paramString = window.location.href.split("/#")[1];
let userScreenRights = [];
let screenRights = JSON.parse(getLoginScreenRights());
let isValidToken = screenRights && screenRights.length == 0 ? false : true;
var cognitoUserId = "";
let token = "";
if (!screenRights || screenRights.length == 0 || paramString !== undefined) {
  let tokenDetails = "";
  //console.log("GK ===queryString===>", paramString.split("&"));
  let tokenDetailsSplit = "";
  tokenDetailsSplit = paramString !== undefined && paramString.split("&");
  let idTokenIndex = "";
  if (tokenDetailsSplit.length > 0) {
    tokenDetailsSplit.forEach((tokenValues, index) => {
      if (tokenValues.includes("id_token")) {
        idTokenIndex = index;
      }
    });
  }
  let queryString =
    paramString !== undefined ? new URLSearchParams(paramString.split("&")[idTokenIndex]) : [];
  for (let pair of queryString.entries()) {
    tokenDetails = pair[1];
  }
  token = tokenDetails.split("&")[0];
  let decoded = "";
  try {
    // store aws temporary credentials in local storage for accessing later
    fetchAndStoreAWSTemporaryCredentails(token);
    decoded = jwtDecode(token);
    localStorage.setItem("token", token);
    isValidToken = true;
  } catch (error) {
    isValidToken = false;
  }
  cognitoUserId = decoded["cognito:username"];
  localStorage.setItem("cognitoUserId", decoded["cognito:username"]); //remvoe and chek
  //localStorage.setItem("isValidToken", isValidToken); //remove and check
  //console.log("isValidToken -->", isValidToken);

  let userScreenRightDetails = [];
  Object.keys(decoded).forEach((userDetails) => {
    userGroupFormat.includes(userDetails) &&
      userScreenRightDetails.push({ userDetails: decoded[userDetails] });
  });

  //store user screen details
  localStorage.setItem("screenRights", JSON.stringify(userScreenRightDetails));
  screenRights = JSON.parse(getLoginScreenRights());
}
let screenRightsCollections = [];

screenRights.length > 0 &&
  Object.values(screenRights).forEach((screenRightsvalue) => {
    let screenRightsInfo = screenRightsvalue?.userDetails?.split(":");
    let screenRightsDetails = screenRightsInfo?.filter((e) => e);
    screenRightsCollections.push(screenRightsDetails);
  });
userScreenRights = screenRightsCollections.length > 0 ? screenRightsCollections.flat() : [];

//redirection to login page
const isAccess = (accessValue) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (
      localStorage.cognitoUserId === "undefined" ||
      localStorage.cognitoUserId === null ||
      (localStorage === null && (token === "undefined" || token === "" || token === null)) ||
      isValidToken === false
    ) {
      navigate("/login");
    }
  }, []);
  if (userScreenRights.length > 0) {
    return isValidToken && userScreenRights.includes(accessValue);
  } else {
    return false;
  }
};
export { userScreenRights, isValidToken, cognitoUserId };

export default isAccess;
