import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPricingModalDataList(action) {
  const { payload } = action;
  try {
    const params = {
      ...payload
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId}/stages`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PRICING_MODAL_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PRICING_MODAL_DATA_FAILED, errorMsg: error.message });
    }
  }
}
function* fetchHistoryPricingModalDataList(action) {
  const { payload } = action;
  try {
    const params = {
      ...payload
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId}/stages-All`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.HISTORY_PRICING_MODAL_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.HISTORY_PRICING_MODAL_DATA_FAILED, errorMsg: error.message });
    }
  }
}

function* getPricingModalSaga() {
  yield takeEvery(actionTypes.PRICING_MODAL_DATA_REQUESTED, fetchPricingModalDataList);
}
function* getHistoryPricingModalSaga() {
  yield takeEvery(
    actionTypes.HISTORY_PRICING_MODAL_DATA_REQUESTED,
    fetchHistoryPricingModalDataList
  );
}

export default function* pricingModalSaga() {
  yield all([getPricingModalSaga(), getHistoryPricingModalSaga()]);
}
