import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { connect, useSelector } from "react-redux";
import * as actionType from "../actions";
import { Checkbox, Box } from "@mui/material";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const FeeSchedule = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const { loading, actions, treatmentPlan } = props;
  const gdmRowData = useSelector((state) => state.globalDataModelReducer.gdmRowData);
  const feeScheduleList = useSelector((state) => state.feeScheduleReducer.feeScheduleList);
  const gdmId = sessionStorage.getItem("gdmId");
  const practiceId = sessionStorage.getItem("practiceId");
  const [values, setValues] = useState({});
  const columns = [
    {
      field: "stageCode",
      headerName: "STAGE SIM GROUP CODE",
      minWidth: 100,
      flex: 1,
      sortable: false
    },
    {
      field: "stageName",
      headerName: "STAGES INCLUDED IN TREATMENT PLAN",
      minWidth: 100,
      editable: false,
      flex: 2
    },
    {
      field: "stagePassthrough",
      headerName: "IS STAGE PASSTHROUGH",
      minWidth: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Checkbox
          disabled={gdmRowData.status == "Active" ? true : false}
          onChange={(e) => handleCheckBox(e, row)}
          defaultChecked={row.stagePassthrough}
        />
      )
    },
    {
      field: "globalPrice",
      headerName: "GLOBAL STAGE PRICE IN TREATMENT PLAN",
      minWidth: 100,
      editable: false,
      flex: 2
    },
    {
      field: "globalPassthroughAmount",
      headerName: "PASSTHROUGH GLOBAL PRICE",
      minWidth: 100,
      editable: false,
      flex: 2
    },
    {
      field: "cancelGlobalDiscount",
      headerName: "CANCEL DISCOUNT AMOUNT FOR GLOBAL PRICE",
      minWidth: 100,
      editable: false,
      flex: 2
    },
    {
      field: "assistPrice",
      headerName: "ASSIST STAGE PRICE IN TREATMENT PLAN",
      minWidth: 100,
      editable: false,
      flex: 2
    },
    {
      field: "assistPassthroughAmount",
      headerName: "PASSTHROUGH ASSIST PRICE",
      minWidth: 100,
      editable: false,
      flex: 2
    },
    {
      field: "cancelAssistDiscount",
      headerName: "CANCEL DISCOUNT AMOUNT FOR ASSIST",
      minWidth: 100,
      editable: false,
      flex: 2
    }
    // {
    //   field: "passthroughAmount",
    //   headerName: "PASSTHROUGH AMOUNT (TO PRACTICE)",
    //   minWidth: 100,
    //   editable: false,
    //   flex: 2,
    //   renderCell: ({ row }) => (
    //     <TextField
    //       value={values[row.treatmentPlanId][row.id.split("_")[1]]?.passthroughAmount}
    //       onChange={(e) => handleTxt(e, row)}
    //       disabled={gdmRowData.status == "Active" ? true : false}
    //       size="small"
    //       onBlur={(e) => handleBlur(e, row)}
    //     />
    //   )
    // }
  ];

  // const handleTxt = (e, row) => {
  //   let val = values;
  //   val[row.treatmentPlanId][row.id.split("_")[1]].passthroughAmount = e.target.value;

  //   setValues({ ...values, ...val });
  // };

  // const handleBlur = (e, row) => {
  //   let values = row;
  //   values.stagePassthrough = row.stagePassthrough;

  //   actions.updateFeeSchedule({
  //     practiceId: practiceId,
  //     gdmId: gdmId,
  //     tpStageId: row.treatmentPlanStageId,
  //     treatmentPlan: values
  //   });
  // };

  const handleCheckBox = (e, row) => {
    let val = values;
    val[row.treatmentPlanId][row.id.split("_")[1]].stagePassthrough = e.target.checked;
    if (e.target.checked) {
      val[row.treatmentPlanId][row.id.split("_")[1]].globalPassthroughAmount =
        val[row.treatmentPlanId][row.id.split("_")[1]].globalPrice;
      val[row.treatmentPlanId][row.id.split("_")[1]].globalPrice = 0;
      val[row.treatmentPlanId][row.id.split("_")[1]].assistPassthroughAmount =
        val[row.treatmentPlanId][row.id.split("_")[1]].assistPrice;
      val[row.treatmentPlanId][row.id.split("_")[1]].assistPrice = 0;
    } else {
      val[row.treatmentPlanId][row.id.split("_")[1]].globalPrice =
        val[row.treatmentPlanId][row.id.split("_")[1]].globalPassthroughAmount;
      val[row.treatmentPlanId][row.id.split("_")[1]].globalPassthroughAmount = 0;
      val[row.treatmentPlanId][row.id.split("_")[1]].assistPrice =
        val[row.treatmentPlanId][row.id.split("_")[1]].assistPassthroughAmount;
      val[row.treatmentPlanId][row.id.split("_")[1]].assistPassthroughAmount = 0;
    }
    setValues({ ...values, ...val });
  };
  useEffect(() => {
    treatmentPlan.length > 0 && setShowLoader(true);

    for (let i = 0; i < treatmentPlan.length; i++) {
      actions.getFeeSchedule({
        offset: 0,
        limit: 20,
        feesSch: true,
        category: "included",
        practiceId: practiceId,
        gdmId: gdmId,
        treatmentPlanId: treatmentPlan[i].treatmentPlanId
      });
    }
  }, [treatmentPlan]);

  useEffect(() => {
    let val = values;
    const feeScheduleData = Object.keys(feeScheduleList).map((index) => {
      feeScheduleList[index]["id"] = feeScheduleList[index].stageId + "_" + index;
      feeScheduleList[index]["isPassThrough"] = false;
      if (feeScheduleList[index].passthroughAmount == null) {
        feeScheduleList[index].passthroughAmount = 0;
      }
      return feeScheduleList[index];
    });
    Object.keys(treatmentPlan)?.map((index) => {
      if (treatmentPlan[index]?.treatmentPlanId === feeScheduleData[0]?.treatmentPlanId) {
        val[treatmentPlan[index]?.treatmentPlanId] = feeScheduleData;
      }
    });

    setValues({ ...values, ...val });
    setShowLoader(false);
  }, [feeScheduleList]);
  useEffect(() => {
    actions.getTreatmentPlan({ practiceId: practiceId, gdmId: gdmId });
  }, []);

  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? "panel" + id : false);
  };

  return (
    <div>
      {treatmentPlan.map((plan) => {
        return (
          <>
            {showLoader && <LoadingSpinner />}
            {/* <Box className="grid-style"> */}
            <Accordion
              expanded={expanded === "panel" + plan.treatmentPlanId}
              onChange={handleChange(plan.treatmentPlanId)}
              className={showLoader ? "disable-section" : " "}
              key={plan.treatmentPlanId}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  flexDirection: "row-reverse"
                }}>
                <Typography component="div">
                  <Box sx={{ textAlign: "left", m: 1, fontWeight: "bold", display: "inline" }}>
                    Treatment Plan:&nbsp;{plan.treatmentPlanName} &ensp; | &ensp;
                  </Box>
                  <Box sx={{ textAlign: "left", m: 1, display: "inline", marginLeft: "20px" }}>
                    Treatment Plan Internal Name:&nbsp;{plan.treatmentPlanInternalName}
                  </Box>
                  <Box sx={{ textAlign: "right", m: 1, display: "inline", marginLeft: "20px" }}>
                    Treatment Plan Global Price:&nbsp;{plan.globalPrice}
                  </Box>
                  <Box sx={{ textAlign: "right", m: 1, display: "inline", marginLeft: "20px" }}>
                    Treatment Plan Assist Price:&nbsp;{plan.assistPrice}
                  </Box>
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  minHeight: "660px"
                }}>
                {values[plan.treatmentPlanId] !== undefined && (
                  <DataGridPremiumComp
                    rows={values[plan.treatmentPlanId]}
                    columns={columns}
                    loading={loading}
                    pageSizeOptions={[10, 25, 50, 100]}
                    pageSize={pageSize.pageSize}
                    paginationModel={pageSize}
                    onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
                    pagination
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </div>
  );
};
const mapStateToProps = ({ treatmentPlanReducer }) => {
  return {
    treatmentPlan: treatmentPlanReducer.treatmentPlanList
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getTreatmentPlan: (payload) => {
      dispatch(actionType.getTreatmentPlan(payload));
    },
    updateFeeSchedule: (payload) => {
      dispatch(actionType.updateFeeSchedule(payload));
    },
    getFeeSchedule: (payload) => {
      dispatch(actionType.getFeeSchedule(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FeeSchedule);
