import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchFinancialSnapshotPgta(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/PGTASummary`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.FINANCIAL_SNAPSHOT_PGTA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.FINANCIAL_SNAPSHOT_PGTA_FAILED, errorMsg: error.message });
    }
  }
}

function* getFinancialSnapshotPgta() {
  yield takeEvery(actionTypes.FINANCIAL_SNAPSHOT_PGTA_REQUESTED, fetchFinancialSnapshotPgta);
}

export default function* financialSnapshotPgtaSaga() {
  yield all([getFinancialSnapshotPgta()]);
}
