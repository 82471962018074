import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

// get All Multiplier
function* fetchMultiplierList(action) {
  const { payload } = action;
  const { practiceId, gdmId } = payload;

  try {
    const params = {
      offset: "",
      limit: ""
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/program-multipliers`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.MULTIPLIER_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.MULTIPLIER_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getMultiplierSaga() {
  yield takeEvery(actionTypes.MULTIPLIER_LIST_REQUESTED, fetchMultiplierList);
}

// Add program
function* addMultiplier(action) {
  const { payload } = action;
  const { practiceId, gdmId } = payload;
  const body = {
    programName: payload.programName,
    treatmentPlanName: payload.treatmentPlanName,
    globalPrice: payload.globalPrice,
    assistPrice: payload.assistPrice,
    minimumAge: payload.minimumAge,
    maximumAge: payload.maximumAge,
    multiplier: payload.multiplier,
    refundPercentage: payload.refundPercentage,
    programId: payload.programId,
    treatmentPlanId: payload.treatmentPlanId
  };

  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/gdms/${gdmId}/program-multipliers`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_MULTIPLIER_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_MULTIPLIER_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* addMultiplierSaga() {
  yield takeEvery(actionTypes.ADD_MULTIPLIER_REQUESTED, addMultiplier);
}

/* Edit program */
function* editMultipler(action) {
  const { payload } = action;
  const { practiceId, gdmId, programMultiplierId } = payload;
  const body = {
    programName: payload.programName,
    treatmentPlanName: payload.treatmentPlanName,
    globalPrice: payload.globalPrice,
    assistPrice: payload.assistPrice,
    minimumAge: payload.minimumAge,
    maximumAge: payload.maximumAge,
    multiplier: payload.multiplier,
    refundPercentage: payload.refundPercentage,
    programId: payload.programId,
    treatmentPlanId: payload.treatmentPlanId
  };

  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/gdms/${gdmId}/program-multipliers/${programMultiplierId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_MULTIPLIER_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_MULTIPLIER_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* editMultiplierSaga() {
  yield takeEvery(actionTypes.EDIT_MULTIPLIER_REQUESTED, editMultipler);
}

/* Edit program */
function* deleteMultiplier(action) {
  const { payload } = action;
  const { practiceId, gdmId, programMultiplierId } = payload;
  try {
    const requestBody = createRequestBody(
      "delete",
      `/practices/${practiceId}/gdms/${gdmId}/program-multipliers/${programMultiplierId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.DELETE_MULTIPLIER_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_MULTIPLIER_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteMultiplierSaga() {
  yield takeEvery(actionTypes.DELETE_MULTIPLIER_REQUESTED, deleteMultiplier);
}

function* getProgramMultiplier(action) {
  const { payload } = action;
  const { practiceId, gdmId, tpId, patientId } = payload;
  try {
    const params = {
      offset: "0",
      limit: "1000",
      tpId: tpId,
      patientId: patientId
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/program-multipliers`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_PROGRAM_MULTIPLIER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_PROGRAM_MULTIPLIER_FAIL,
        message: error.message
      });
    }
  }
}

function* getProgramMultiplierSaga() {
  yield takeEvery(actionTypes.GET_PROGRAM_MULTIPLIER_REQUESTED, getProgramMultiplier);
}

export default function* multiplierSaga() {
  yield all([
    getMultiplierSaga(),
    addMultiplierSaga(),
    editMultiplierSaga(),
    deleteMultiplierSaga(),
    getProgramMultiplierSaga()
  ]);
}
