import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
// import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* getOutcomeReport(action) {
  const { payload } = action;
  try {
    const params = {
      outcomeDateFrom: payload.outcomeDateFrom || "",
      outcomeDateTo: payload.outcomeDateTo || "",
      practiceId: payload.practiceId || ""
    };
    const requestBody = createRequestBody(
      "get",
      `/financial_snapshots/outcome-reports`,
      params,
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    console.log("zhr response", response);
    yield put({
      type: actionTypes.GET_OUTCOME_REPORT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_OUTCOME_REPORT_FAILED,
        errorMsg: error.message
      });
    }
  }
}

function* getOutcomeReportSaga() {
  yield takeEvery(actionTypes.GET_OUTCOME_REPORT, getOutcomeReport);
}

export default function* outcomeReportSaga() {
  yield all([getOutcomeReportSaga()]);
}
