import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

// get All email list
function* fetchEmailList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/${payload}/emails`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EMAIL_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EMAIL_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* fetchEmailSaga() {
  yield takeEvery(actionTypes.EMAIL_LIST_REQUESTED, fetchEmailList);
}

// // Add Email
function* addEmail(action) {
  const { payload } = action;
  const { practiceId } = payload;

  const body = {
    emailName: payload.emailName,
    emailAddress: payload.emailAddress,
    status: payload.status
  };
  try {
    const requestBody = createRequestBody("post", `/practices/${practiceId}/emails`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_EMAIL_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_EMAIL_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addEmailSaga() {
  yield takeEvery(actionTypes.ADD_EMAIL_REQUESTED, addEmail);
}

/* Edit Email */
function* editEmail(action) {
  const { payload } = action;
  const { practiceId, emailId } = payload;

  const body = {
    emailName: payload.emailName,
    emailAddress: payload.emailAddress,
    status: payload.status
  };

  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/emails/${emailId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_EMAIL_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_EMAIL_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editEmailSaga() {
  yield takeEvery(actionTypes.EDIT_EMAIL_REQUESTED, editEmail);
}

export default function* emailSaga() {
  yield all([fetchEmailSaga(), addEmailSaga(), editEmailSaga()]);
}
