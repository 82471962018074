import * as actionType from "../actions/ActionTypes";

const initialState = {
  participantList: {},
  editParticipant: null,
  loading: false,
  error: null,
  success: null
};

const participantReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PARTICIPANT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PARTICIPANT_LIST_SUCCESS: {
      return {
        ...state,
        participantList: action.payload,
        loading: false
      };
    }
    case actionType.PARTICIPANT_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.EDIT_PARTICIPANT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PARTICIPANT_SUCCESS: {
      return {
        ...state,
        editParticipant: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_PARTICIPANT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.PARTICIPANT_LIST_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PARTICIPANT_LIST_UPDATE_SUCCESS: {
      return {
        ...state,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.PARTICIPANT_LIST_UPDATE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_PARTICIPANT: {
      return {
        ...state,
        participantList: [],
        loading: null
      };
    }
    case actionType.RESET_PARTICIPANT_LIST: {
      return {
        ...state,
        participantList: [],
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default participantReducer;
