import * as actionType from "../actions/ActionTypes";

const initialState = {
  notificationList: [],
  metadataList: [],
  loading: false,
  error: null,
  success: null
};
const notificationViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_USER_NOTIFICATIONS_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_USER_NOTIFICATIONS_REPORT_SUCCESS: {
      return {
        ...state,
        notificationList: action.payload,
        loading: false
      };
    }
    case actionType.GET_USER_NOTIFICATIONS_REPORT_FAILURE: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.GET_USER_NOTIFICATION_METADATA_REPORT_REQUESTED: {
      return {
        ...state,
        loading: false
      };
    }
    case actionType.GET_USER_NOTIFICATION_METADATA_REPORT_SUCCESS: {
      return {
        ...state,
        metadataList: action.payload,
        loading: false
      };
    }
    case actionType.GET_USER_NOTIFICATION_METADATA_REPORT_FAILURE: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.UPDATE_USER_NOTIFICATION_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.UPDATE_USER_NOTIFICATION_REPORT_SUCCESS: {
      return {
        ...state,
        success: action.success,
        loading: false
      };
    }
    case actionType.UPDATE_USER_NOTIFICATION_REPORT_FAILURE: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default notificationViewReducer;
