import * as actionType from "../actions/ActionTypes";

const initialState = {
  profileDetails: [],
  loading: false,
  error: null,
  success: null
};

const profileDrawerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.USER_PROFILE_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.USER_PROFILE_DETAILS_SUCCESS: {
      return {
        ...state,
        profileDetails: action.payload,
        loading: false
      };
    }
    case actionType.USER_PROFILE_DETAILS_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default profileDrawerReducer;
