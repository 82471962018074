import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPaymentRegisterReportList(action) {
  const { payload } = action;
  try {
    const params = {
      startDate: payload.startDate,
      reportType: payload.reportType,
      endDate: payload.endDate,
      recordedOnStartDate: payload.recordedOnStart,
      recordedOnEndDate: payload.recordedOnEnd
    };
    const requestBody = createRequestBody("get", `/financial_snapshots/reports`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GET_PAYMENTREGISTER_REPORT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_PAYMENTREGISTER_REPORT_FAILED, errorMsg: error.message });
    }
  }
}

function* getPaymentRegReportSaga() {
  yield takeEvery(actionTypes.GET_PAYMENTREGISTER_REPORT_REQUESTED, fetchPaymentRegisterReportList);
}
export default function* paymentRegSaga() {
  yield all([getPaymentRegReportSaga()]);
}
