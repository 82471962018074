import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Typography, Stack } from "@mui/material";
import CommonModal from "../../shared/components/CommonModal";
import { address1Config } from "../../shared/constants/renderConfigs";
import Radio from "@mui/material/Radio";
import { connect } from "react-redux";
import * as actionType from "../actions";
import { enumUsStates } from "../../shared/constants/constants";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const PartnerAddress = (props) => {
  const { billingAddress, actions, partnerList, loading } = props;
  let addressFormData = {
    type: "",
    mailingAddrLine1: null,
    mailingAddrLine2: null,
    billingAddrLine1: null,
    billingAddrLine2: null,
    billingCity: null,
    billingState: null,
    mailingCity: null,
    mailingState: null,
    mailingZip: null,
    billingZip: null,
    personId: partnerList.personId,
    personKey: partnerList.personKey,
    activePartner: partnerList.activePartner
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [inputNames, setInputNames] = useState({});
  const [values, setValues] = useState({ ...partnerList });
  const [changeType, setChangeType] = useState("");

  const rows = billingAddress.map((item, index) => {
    item.id = index;
    return item;
  });

  const columns = [
    {
      field: "deafult",
      headerName: "DEFAULT",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <Radio
          checked={partnerList.defaultAddress == row.type}
          value={partnerList.defaultAddress}
        />
      )
    },
    {
      field: "type",
      headerName: "TYPE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.type[0].toUpperCase() + row.type.slice(1);
      }
    },
    {
      field: "billingAddrLine1",
      headerName: "STREET LINE 1",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.billingAddrLine1 == null ? "--" : row.billingAddrLine1;
      }
    },
    {
      field: "billingAddrLine2",
      headerName: "STREET LINE 2",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.billingAddrLine2 == null ? "--" : row.billingAddrLine2;
      }
    },
    {
      field: "billingCity",
      headerName: "CITY",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.billingCity == null ? "--" : row.billingCity;
      }
    },
    {
      field: "billingState",
      headerName: "STATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.billingState == null ? "--" : row.billingState;
      }
    },
    {
      field: "billingZip",
      headerName: "ZIP",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.billingZip == null ? "--" : row.billingZip;
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <IconButton
            onClick={() => {
              handleEditAddress(row);
            }}>
            <EditIcon color="primary"></EditIcon>
          </IconButton>
        </>
      )
    }
  ];
  const getStateCode = (stateName) => {
    let stateCode = "";
    enumUsStates.forEach((item) => {
      if (item.name === stateName) {
        stateCode = item.value;
      }
    });
    return stateCode;
  };
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    if (name === "billingState") {
      let nameCode = "",
        mailingnameCode = "";
      nameCode = getStateCode(value);
      mailingnameCode = getStateCode(values.mailingState);
      setValues({
        ...values,
        [name]: value,
        ["billingStateCode"]: nameCode,
        ["mailingStateCode"]: mailingnameCode
      });
    } else if (name === "mailingState") {
      let nameCode = "",
        billingnameCode = "";
      nameCode = getStateCode(value);
      billingnameCode = getStateCode(values.billingState);
      setValues({
        ...values,
        [name]: value,
        ["mailingStateCode"]: nameCode,
        ["billingStateCode"]: billingnameCode
      });
    } else {
      setValues({
        ...values,
        [name]: value,
        ["billingStateCode"]: getStateCode(values.billingState),
        ["mailingStateCode"]: getStateCode(values.mailingState)
      });
    }
    setChangeType(name.slice(0, 7));
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const saveAddress = (event) => {
    setIsOpen(false);
    event.preventDefault();
    const defaultType =
      values.billingDefault == "on" || values.mailingDefault == "on"
        ? changeType
        : props.partnerList.defaultAddress;
    const payload = {
      practiceId: props.practiceId,
      value: { ...values, email: props.partnerList.email },
      defaultAddress: defaultType,
      participantList: props.participantList,
      partnerId: partnerList.partnerId,
      ...props.partnerList?.patient
    };
    actions.editPartner(payload);
  };
  const stateParser = (statecode) => {
    let stateName = "";
    enumUsStates.forEach((item) => {
      if (item.value === statecode) {
        stateName = item.name;
      }
    });
    return stateName;
  };
  const handleEditAddress = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    rows.forEach((items) => {
      if (items.type == "billing") {
        addressFormData["billingAddrLine1"] = items["billingAddrLine1"];
        addressFormData["billingAddrLine2"] = items["billingAddrLine2"];
        addressFormData["billingCity"] = items["billingCity"];
        addressFormData["billingState"] = stateParser(items["billingState"]);
        addressFormData["billingZip"] = items["billingZip"];
        addressFormData["defaultAddress"] = partnerList.defaultAddress;
      } else {
        addressFormData["mailingAddrLine1"] = items["billingAddrLine1"];
        addressFormData["mailingAddrLine2"] = items["billingAddrLine2"];
        addressFormData["mailingCity"] = items["billingCity"];
        addressFormData["mailingState"] = stateParser(items["billingState"]);
        addressFormData["mailingZip"] = items["billingZip"];
        addressFormData["defaultAddress"] = partnerList.defaultAddress;
      }
    });
    addressFormData["type"] = row.type;
    const billing = {
      line1: "billingAddrLine1",
      line2: "billingAddrLine2",
      city: "billingCity",
      state: "billingState",
      zip: "billingZip",
      default: "billingDefault"
    };
    const mailing = {
      line1: "mailingAddrLine1",
      line2: "mailingAddrLine2",
      city: "mailingCity",
      state: "mailingState",
      zip: "mailingZip",
      default: "mailingDefault"
    };
    if (row.type == "mailing") {
      setInputNames({ ...mailing });
    } else {
      setInputNames({ ...billing });
    }
    setValues(addressFormData);
  };
  const address = address1Config({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    inputNames,
    options: enumUsStates.map((item) => {
      return item.name;
    })
  });
  return (
    <>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="grid-header" sx={{ marginBottom: "10px" }}>
          <Typography>Address</Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
            // minHeight: "650px"
          }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal open={isOpen} config={address} isAdd={isAdd} handleSubmit={saveAddress} />
    </>
  );
};

const mapStateToProps = ({ addressReducer, partnerReducer }) => {
  return {
    partnerList: partnerReducer.partnerList,
    loading: addressReducer.loading,
    addressList: addressReducer.addressList,
    addAddress: addressReducer.addAddress,
    editAddress: addressReducer.editAddress,
    errorMsg: addressReducer.error,
    successMsg: addressReducer.success,
    editPartner: partnerReducer.editPartner
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getAddress: (payload) => {
      dispatch(actionType.getAddress(payload));
    },
    editPartner: (payload) => {
      dispatch(actionType.editPartner(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnerAddress);
