import * as actionType from "../actions/ActionTypes";

const initialState = {
  auditLogsList: [],
  impersonateLogsList: [],
  impersonateLog: "",
  loading: false,
  error: null,
  success: false
};

const auditLogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.AUDIT_LOGS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.AUDIT_LOGS_SUCCESS: {
      return {
        ...state,
        auditLogsList: action.payload,
        loading: false
      };
    }
    case actionType.AUDIT_LOGS_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_AUDIT_LOGS_LIST: {
      return {
        ...state,
        auditLogsList: [],
        impersonateLogsList: []
      };
    }
    case actionType.ADD_IMPERSONATE_LOGS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_IMPERSONATE_LOGS_SUCCESS: {
      return {
        ...state,
        impersonateLog: action.payload,
        success: action.success,
        loading: false
      };
    }
    case actionType.ADD_IMPERSONATE_LOGS_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.IMPERSONATE_LOGS_SUCCESS: {
      return {
        ...state,
        impersonateLogsList: action.payload,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default auditLogsReducer;
