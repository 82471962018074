import * as actionType from "../actions/ActionTypes";
const initialState = {
  manualRefundList: [],
  loading: false,
  error: null,
  success: null
};

const manualRefundReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.MANUAL_REFUND_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.MANUAL_REFUND_SUCCESS: {
      return {
        ...state,
        manualRefundList: action.payload,
        loading: false,
        success: true
      };
    }
    case actionType.MANUAL_REFUND_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.MANUAL_REFUND_RESET: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default manualRefundReducer;
