import React, { useEffect, useState, useCallback } from "react";
import EditIcon from "@mui/icons-material/Edit";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { Box, IconButton, Button } from "@mui/material";
import { locationConfig } from "../../shared/constants/renderConfigs";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const Location = (props) => {
  const paymentTimingListStore = useSelector((state) => state.paymentTiming.paymentTimingList);
  const optionData = [];
  paymentTimingListStore.map((item) => {
    optionData.push({ id: item.paymentTimingId, name: item.description });
  });
  const columns = [
    {
      field: "locationId",
      headerName: "ID",
      width: 50,
      flex: 0.5,
      sortable: false
    },
    {
      field: "name",
      headerName: "NAME",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => <Link to={row.locationId}>{row.name}</Link>
    },
    {
      field: "longName",
      headerName: "LONG NAME",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "relationship",
      headerName: "RELATIONSHIP",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <span
          className={!row.relationship ? "m-l-50" : ""}
          style={{ textAlign: `${row.ivfPaymentTiming ? "left" : "center"}` }}>
          {row.relationship ? row.relationship : "--"}
        </span>
      )
    },
    {
      field: "ivfPaymentTiming",
      headerName: "IVF PAYMENT TIMING",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <span
          className={!row.ivfPaymentTiming ? "m-l-50" : ""}
          style={{ textAlign: `${row.ivfPaymentTiming ? "left" : "center"}` }}>
          {row.ivfPaymentTiming ? row.ivfPaymentTiming : "--"}
        </span>
      )
    },
    {
      field: "fetPaymentTiming",
      headerName: "FET PAYMENT TIMING",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <span
          className={!row.fetPaymentTiming ? "m-l-50" : ""}
          style={{ textAlign: `${row.ivfPaymentTiming ? "left" : "center"}` }}>
          {row.fetPaymentTiming ? row.fetPaymentTiming : "--"}
        </span>
      )
    },
    {
      field: "freezeAllPaymentTiming",
      headerName: "FREEZE ALL PAYMENT TIMING",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <span
          className={!row.freezeAllPaymentTiming ? "m-l-50" : ""}
          style={{ textAlign: `${row.ivfPaymentTiming ? "left" : "center"}` }}>
          {row.freezeAllPaymentTiming ? row.freezeAllPaymentTiming : "--"}
        </span>
      )
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleEditLocation(row);
          }}>
          <EditIcon color="primary"></EditIcon>
        </IconButton>
      )
    }
  ];
  const { practiceId } = useParams();
  let locationFormData = {
    practiceId: practiceId,
    name: "",
    longName: "",
    relationship: "Partner",
    ivfPaymentTiming: "",
    fetPaymentTiming: "",
    freezeAllPaymentTiming: "",
    gl: ""
  };
  const {
    locations = [],
    actions,
    addLocation,
    editLocation,
    loading,
    errorMsg,
    successMsg
  } = props;
  const locationList = locations;
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState(locationFormData);
  const rows = locationList.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    actions.getLocations(practiceId);
  }, [addLocation, editLocation]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (!paymentTimingListStore.length) {
      actions.getPaymentTimingList({ offset: 0, limit: 100 });
    }
    if (locations.length === 0 || addLocation || editLocation) {
      initFetch();
    }
    return () => {
      actions.resetLocationList();
    };
  }, [addLocation, editLocation, successMsg, errorMsg]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  const addNewLocation = () => {
    setValues(locationFormData);
    setIsOpen(true);
    setIsAdd(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const saveLocation = (event) => {
    event.preventDefault();
    values.ivfPaymentTiming = values.ivfPaymentTiming.length ? values.ivfPaymentTiming : null;
    values.fetPaymentTiming = values.fetPaymentTiming.length ? values.fetPaymentTiming : null;
    values.freezeAllPaymentTiming = values.freezeAllPaymentTiming.length
      ? values.freezeAllPaymentTiming
      : null;
    values.gl = values.gl.length ? values.gl : null;
    if (isAdd) {
      actions.addNewLocation(values);
    } else {
      actions.editLocation(values);
    }
    closeModal();
  };

  const handleEditLocation = (row) => {
    setIsAdd(false);
    let ivfPaymentTiming = "",
      fetPaymentTiming = "",
      freezeAllPaymentTiming = "";
    paymentTimingListStore.forEach((item) => {
      if (item.description == row.ivfPaymentTiming) {
        ivfPaymentTiming = item.paymentTimingId;
      }
      if (item.description == row.fetPaymentTiming) {
        fetPaymentTiming = item.paymentTimingId;
      }
      if (item.description == row.freezeAllPaymentTiming) {
        freezeAllPaymentTiming = item.paymentTimingId;
      }
    });
    locationFormData = {
      practiceId: practiceId,
      locationId: row.locationId,
      name: row.name,
      longName: row.longName,
      relationship: row.relationship,
      ivfPaymentTiming: ivfPaymentTiming,
      fetPaymentTiming: fetPaymentTiming,
      freezeAllPaymentTiming: freezeAllPaymentTiming,
      gl: row?.gl || ""
    };
    setValues(locationFormData);
    setIsOpen(true);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const locationCon = locationConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.name && values.longName ? false : true,
    optionData: optionData
  });
  return (
    <>
      <Box>
        <Button variant="contained" className="btn-primary tab-panel-btn" onClick={addNewLocation}>
          New Location
        </Button>
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
        />
      </Box>
      {/* Add/edit location modal*/}
      <CommonModal open={isOpen} config={locationCon} isAdd={isAdd} handleSubmit={saveLocation} />
      {/* Message modal */}
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};
const mapStateToProps = ({ locationReducer }) => {
  return {
    locations: locationReducer.locations,
    addLocation: locationReducer.addLocation,
    editLocation: locationReducer.editLocation,
    loading: locationReducer.loading,
    errorMsg: locationReducer.error,
    successMsg: locationReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPaymentTimingList: (payload) => {
      dispatch(actionType.getPaymentTimingList(payload));
    },
    getLocations: (practiceId) => {
      dispatch(actionType.getLocations(practiceId));
    },
    addNewLocation: (payload) => {
      dispatch(actionType.addNewLocation(payload));
    },
    editLocation: (payload) => {
      dispatch(actionType.editLocation(payload));
    },
    resetLocationList: () => {
      dispatch(actionType.resetLocationList());
    },

    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Location);
