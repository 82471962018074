import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

//get stages
function* getStages(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "0",
      limit: "10000",
      category: "Included"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ESTIMATE_STAGE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.ESTIMATE_STAGE_LIST_FAILED, message: error.message });
    }
  }
}

function* getStagesSaga() {
  yield takeEvery(actionTypes.ESTIMATE_STAGE_LIST_REQUESTED, getStages);
}

//get necessary
function* fetchNecessary(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;

  try {
    const params = {
      offset: "",
      limit: "10000",
      category: "Necessary"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages-service-items`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.NECESSARY_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.NECESSARY_LIST_FAILED, message: error.message });
    }
  }
}

function* fetchNecessarySaga() {
  yield takeEvery(actionTypes.NECESSARY_LIST_REQUESTED, fetchNecessary);
}

//get optional
function* fetchOptional(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000",
      category: "Optional"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages-service-items`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.OPTIONAL_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.OPTIONAL_LIST_FAILED, message: error.message });
    }
  }
}

function* fetchOptionalSaga() {
  yield takeEvery(actionTypes.OPTIONAL_LIST_REQUESTED, fetchOptional);
}

//add adjustments
function* addAdjustment(action) {
  const { payload } = action;
  const { estimateId, estimateTpId /* , values */ } = payload;
  try {
    const requestBody = createRequestBody(
      "post",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/adjustments`,
      "",
      // values
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_ADJUSTMENTS_SUCCESS,
      payload: response.data,
      successMsg: "Adjustments added successfully"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_ADJUSTMENTS_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addAdjustmentSaga() {
  yield takeEvery(actionTypes.ADD_ADJUSTMENTS_REQUESTED, addAdjustment);
}

//get adjustments
function* fetchAdjustment(action) {
  const { payload } = action;
  const { estimateId, estimateTpId } = payload;
  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/adjustments`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GET_ADJUSTMENTS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_ADJUSTMENTS_FAILED, message: error.message });
    }
  }
}

function* fetchAdjustmentSaga() {
  yield takeEvery(actionTypes.GET_ADJUSTMENTS_REQUESTED, fetchAdjustment);
}

//update adjustments
function* updateAdjustment(action) {
  const { payload } = action;
  const { estimateId, estimateTpId /* , values */ } = payload;
  try {
    const requestBody = createRequestBody(
      "put",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/adjustments`,
      "",
      // values
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.UPDATE_ADJUSTMENTS_SUCCESS,
      payload: response.data,
      successMsg: "Adjustments updated successfully"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.UPDATE_ADJUSTMENTS_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* updateAdjustmentSaga() {
  yield takeEvery(actionTypes.UPDATE_ADJUSTMENTS_REQUESTED, updateAdjustment);
}

export default function* costCoverageSaga() {
  yield all([
    getStagesSaga(),
    fetchNecessarySaga(),
    fetchOptionalSaga(),
    addAdjustmentSaga(),
    fetchAdjustmentSaga(),
    updateAdjustmentSaga()
  ]);
}
