import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Grid,
  Box,
  Checkbox,
  Select,
  MenuItem,
  Button,
  Autocomplete
} from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { useNavigate } from "react-router-dom";
import StageCompletion from "./StageCompletion";
import ProviderReimbursementProgramCycle from "./PRProgramCycle";
import LoadingModal from "./modals/loadingModal";
import moment from "moment";
import { priceFormat, priceFormatToNumber } from "../../shared/common";

const ReimbursementDetails = (props) => {
  const [isOpen] = useState(false);
  const [isAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState({});
  const [prid, setPrid] = useState();
  const navigate = useNavigate();
  const style = {
    flex: 6,
    backgroundColor: "#EDEDED"
  };
  const {
    metaData = {},
    providerReimbursementList = [],
    actions,
    errorMsg,
    successMsg,
    editReimbursementDetails,
    loading,
    tabName,
    compCheck
  } = props;

  const initFetch = useCallback(() => {}, [editReimbursementDetails]);

  useEffect(() => {
    actions.getMetaData();
  }, []);

  useEffect(() => {
    setPrid(props.selectedPRId);
  }, [props.selectedPRId]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (providerReimbursementList.length == 0 || editReimbursementDetails) {
      initFetch();
    }
    return () => {
      actions.resetReimbursementDetailsList();
    };
  }, [errorMsg, successMsg, editReimbursementDetails]);

  useEffect(() => {
    console.log("props.selectedPR:", props.selectedPR);
    setValues(props.selectedPR);
  }, [props.selectedPR]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const toMedcaseDetails = () => {
    navigate(`/trak/caselookup/caselist/${values.medcaseId}/status`);
  };

  const handleChange = (e, name, value) => {
    if (value) {
      if (name === "medcaseStatus") {
        setValues({ ...values, [name]: value?.value });
      }
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };
  tabName === "Manual Review" && successMsg === false; /* && window.location.reload(false);*/
  const handleSave = () => {
    console.log("values", values);
    let payload = {
      ...values,
      approvedReimbursementAmount: priceFormatToNumber(values.approvedReimbursementAmount),
      programFee: priceFormatToNumber(values.programFee)
    };
    actions.editPR(payload);
  };

  console.log("zhr values", values);

  return (
    <>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Typography>Reimbursement Details</Typography>
        <Stack className="pad-5" direction="row"></Stack>

        <Stack className="pad-5" direction="row">
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography>Medcase</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                onClick={toMedcaseDetails}
                sx={style}
                size="small"
                fullWidth
                id="filled-basic"
                value={values.medcaseId}
                className="add-description"
                disabled
              />
            </Grid>
            {/* <Grid item xs={2}>
              <Typography style={{ marginLeft: "10px" }}>Cycle Id</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.clinicalTreatmentId}
                className="add-description"
                disabled
              />
            </Grid> */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Cycle no</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.clinicalTreatmentNo}
                className="add-description"
                disabled
              />
            </Grid>
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Estimate Created Date</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="estimateCreatedDate"
                fullWidth
                id="filled-basic"
                value={
                  values?.estimateCreatedDate != null
                    ? moment(values?.estimateCreatedDate).format("L")
                    : null
                }
                className="add-description"
                disabled
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack className="pad-5" direction="row">
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography> First Name</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.patientFirstName}
                className="add-description"
                disabled
              />
            </Grid>

            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Enrolled Treatment</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="enrolledTpName"
                fullWidth
                id="filled-basic"
                value={values?.enrolledTpName}
                className="add-description"
                disabled
              />
            </Grid>

            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}> Enrollement Date</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={
                  values.enrollementDate == null ? "" : moment(values.enrollementDate).format("L")
                }
                className="add-description"
                disabled
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack className="pad-5" direction="row">
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography>Last Name</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.patientLastName}
                className="add-description"
                disabled
              />
            </Grid>

            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}> Authorized service</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.requestedTreatmentPlan}
                className="add-description"
                disabled
              />
            </Grid>

            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Outcome Date</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.outcomeDate == null ? "" : moment(values.outcomeDate).format("L")}
                className="add-description"
                disabled
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack className="pad-5" direction="row">
          <Grid container className="align-center">
            {/* Medcase Status */}
            <Grid item xs={1.5}>
              <Typography> Medcase Status</Typography>
            </Grid>
            <Grid item xs={2.5}>
              {!compCheck ? (
                <TextField
                  sx={style}
                  size="small"
                  name="dob"
                  fullWidth
                  id="filled-basic"
                  value={values.medcaseStatus}
                  className="add-description"
                  disabled
                />
              ) : (
                <Autocomplete
                  disablePortal
                  fullWidth
                  name="medcaseStatus"
                  value={
                    metaData?.medcaseStatus?.find((item) => item === values.medcaseStatus) || null
                  }
                  className="patient-dropdown"
                  options={
                    Object.keys(metaData).length > 0 &&
                    metaData?.medcaseStatus.map((item, index) => {
                      return {
                        id: index,
                        label: item,
                        name: "medcaseStatus",
                        value: item
                      };
                    })
                  }
                  onChange={(e, value) => {
                    handleChange(e, "medcaseStatus", value);
                  }}
                  renderInput={(params) => <TextField {...params} label="Medcase Status..." />}
                />
                // <Select
                //   fullWidth
                //   name="medcaseStatus"
                //   value={values.medcaseStatus}
                //   className="patient-dropdown"
                //   onChange={handleChange}>
                //   <MenuItem value="">Select</MenuItem>
                //   {Object.keys(metaData).length > 0 &&
                //     metaData?.medcaseStatus.map((item, index) => {
                //       return (
                //         <MenuItem value={item} key={index}>
                //           {item}
                //         </MenuItem>
                //       );
                //     })}
                // </Select>
              )}
            </Grid>

            {/* Performed service */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}> Performed Service</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.performedTreatmentPlan}
                className="add-description"
                disabled
              />
            </Grid>

            {/* Approved Date */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Approved Date</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={
                  tabName === "Approved" && values?.approvedDate !== null
                    ? moment(values.approvedDate).format("L")
                    : null
                }
                className="add-description"
                disabled
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack className="pad-5" direction="row">
          <Grid container className="align-center">
            {/* Program Fee */}
            <Grid item xs={1.5}>
              <Typography> Program Fee</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={
                  values.programFee
                    ? ("$" + priceFormat(values.programFee)).replaceAll("$-", "-$")
                    : "$0.00"
                }
                className="add-description"
                disabled
              />
            </Grid>

            {/* Outcome */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Cycle outcome </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.outcomeStatus}
                className="add-description"
                disabled
              />
            </Grid>

            {/* Exported Date */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}> Exported date</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.exportedDate == null ? "" : moment(values.exportedDate).format("L")}
                className="add-description"
                disabled
              />
            </Grid>

            {/*  */}
            {/* <Grid item xs={2}>
              <Typography style={{ marginLeft: "10px" }}> Trans Date</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={
                  values.transactionDate == null ? "" : moment(values.transactionDate).format("L")
                }
                className="add-description"
                disabled
              />
            </Grid> */}
          </Grid>
        </Stack>

        <Stack className="pad-5" direction="row">
          <Grid container className="align-center">
            {/* Enrolled Program */}
            <Grid item xs={1.5}>
              <Typography>Enrolled Program</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values?.progShortName}
                className="add-description"
                disabled
              />
            </Grid>

            {/* Transaction Type */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}> Transaction Type</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Select
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={"Practice - Medical Cost Reserve"}
                className="add-description"
                disabled>
                <MenuItem value="Practice">Practice</MenuItem>
                <MenuItem value="Practice - Medical Cost Reserve">
                  {values.transactionTypeDesc}
                </MenuItem>
              </Select>
            </Grid>

            {/* Rejected Date */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}> Rejected Date</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.rejectedDate == null ? "" : moment(values.rejectedDate).format("L")}
                className="add-description"
                disabled
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack className="pad-5" direction="row">
          <Grid container className="align-center">
            {/* Refund Percentage */}
            <Grid item xs={1.5}>
              <Typography>Refund Percentage </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.refundPercentage}
                className="add-description"
                disabled
              />
            </Grid>

            {/* Location Reimbursement */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}> Location Reimbursement</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.reimbursementPercentage}
                className="add-description"
                disabled
              />
            </Grid>

            {/* Payable To */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}> Payable To</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.practiceName}
                className="add-description"
                disabled
              />
            </Grid>
          </Grid>
        </Stack>

        <Stack className="pad-5" direction="row">
          <Grid container className="align-center">
            {/* Manual Review */}
            <Grid item xs={1.5}>
              <Typography>Manual Review </Typography>
            </Grid>
            <Grid item xs={2.5}>
              {tabName === "Manual Review" ? (
                <Checkbox
                  defaultChecked
                  sx={{ flex: 6, backgroundColor: "primary" }}
                  size="small"
                  name="isManualReview"
                  fullWidth
                  id="filled-basic"
                  onChange={handleChange}
                  value={values.isManualReview}
                  className="add-description"
                  disabled
                />
              ) : (
                <Checkbox
                  sx={{ flex: 6, backgroundColor: "primary" }}
                  size="small"
                  name="isManualReview"
                  fullWidth
                  id="filled-basic"
                  onChange={handleChange}
                  value={values.isManualReview}
                  className="add-description"
                  disabled
                />
              )}
            </Grid>

            {/* Amount */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}> Amount</Typography>
            </Grid>
            <Grid item xs={2.5}>
              {tabName === "Manual Review" ? (
                <TextField
                  sx={style}
                  size="small"
                  name="approvedReimbursementAmount"
                  fullWidth
                  id="filled-basic"
                  value={values.approvedReimbursementAmount}
                  className="add-description"
                  onChange={handleChange}
                />
              ) : (
                <TextField
                  sx={style}
                  size="small"
                  name="approvedReimbursementAmount"
                  fullWidth
                  id="filled-basic"
                  value={"$" + priceFormat(values.approvedReimbursementAmount)}
                  className="add-description"
                  disabled
                />
              )}
            </Grid>

            {/* GL NO */}
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>GL No</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                sx={style}
                size="small"
                name="dob"
                fullWidth
                id="filled-basic"
                value={values.glNo}
                className="add-description"
                disabled
              />
            </Grid>
          </Grid>
        </Stack>

        {/* Save */}
        <Stack className="pad-5" direction="row">
          <Button
            variant="contained"
            className="btn-primary"
            sx={{ left: "965px" }}
            onClick={handleSave}>
            Save
          </Button>
        </Stack>
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Comments</Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              sx={{ flex: 6 }}
              size="small"
              name="comment"
              fullWidth
              id="filled-basic"
              value={values.comment}
              onChange={handleChange}
              className="add-description"
            />
          </Grid>
        </Grid>
      </Box>
      <LoadingModal open={loading} />
      {prid != undefined && prid != 0 ? <ProviderReimbursementProgramCycle medcaseId={prid} /> : ""}
      <StageCompletion medcaseId={prid} clinicalTreatmentNo={values.clinicalTreatmentNo} />
      <CommonModal open={isOpen} isAdd={isAdd} />
      {isMessageModal && (
        <MessageModal
          open={isMessageModal}
          message={successMsg}
          error={errorMsg}
          closeModal={closeMessageModal}
        />
      )}
    </>
  );
};
const mapStateToProps = ({ caseUpdateReducer, providerReimbursementReducer }) => {
  return {
    metaData: caseUpdateReducer.metaData,
    providerReimbursementList: providerReimbursementReducer.providerReimbursementList,
    loading: providerReimbursementReducer.loading,
    errorMsg: providerReimbursementReducer.error,
    successMsg: providerReimbursementReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    },
    getProviderReimbursement: (payload) => {
      dispatch(actionType.getProviderReimbursement(payload));
    },
    editPR: (payload) => {
      dispatch(actionType.editPR(payload));
    },
    resetReimbursementDetailsList: (payload) => {
      dispatch(actionType.resetReimbursementDetailsList(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ReimbursementDetails);
