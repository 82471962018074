import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchUserNotifications(action) {
  const { payload } = action;
  try {
    let url = `/users/${payload.userId}/notifications`;
    const params = {};

    if (null != payload.subject) {
      params["subject"] = payload.subject;
    }

    if (null != payload.category) {
      params["category"] = payload.category;
    }

    if (null != payload.startDate) {
      params["startDate"] = payload.startDate;
    }

    if (null != payload.endDate) {
      params["endDate"] = payload.endDate;
    }
    if (null != payload.patientName) {
      params["patientName"] = payload.patientName;
    }
    if (null != payload.selectedProgram) {
      params["selectedProgram"] = payload.selectedProgram;
    }
    if (null != payload.treatmentType) {
      params["treatmentType"] = payload.treatmentType;
    }
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_USER_NOTIFICATIONS_REPORT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_USER_NOTIFICATIONS_REPORT_FAILURE,
        errorMsg: error.message
      });
    }
  }
}

function* fetchUserNotificationMetadata() {
  try {
    const requestBody = createRequestBody("get", `/notifications/metadata`, "", "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    console.log("response:", response);

    yield put({
      type: actionTypes.GET_USER_NOTIFICATION_METADATA_REPORT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_USER_NOTIFICATION_METADATA_REPORT_FAILURE,
        errorMsg: error.message
      });
    }
  }
}

function* toggleUserNotificationMessageStatus(action) {
  const { payload } = action;

  const body = {
    notificationId: payload.notificationId,
    isRead: payload.isRead
  };
  try {
    let url = `/users/${payload.userId}/notifications`;
    const requestBody = createRequestBody("put", url, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.UPDATE_USER_NOTIFICATION_REPORT_SUCCESS,
      payload: response.data,
      success: actionTypes.UPDATE_USER_NOTIFICATION_REPORT_SUCCESS
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.UPDATE_USER_NOTIFICATION_REPORT_FAILURE,
        errorMsg: error.message
      });
    }
  }
}

function* updateUserNotificationMessageStatus() {
  yield takeEvery(
    actionTypes.UPDATE_USER_NOTIFICATION_REPORT_REQUESTED,
    toggleUserNotificationMessageStatus
  );
}

function* getUserNotificationSaga() {
  yield takeEvery(actionTypes.GET_USER_NOTIFICATIONS_REPORT_REQUESTED, fetchUserNotifications);
}

function* getUserNotificationMetadataSaga() {
  yield takeEvery(
    actionTypes.GET_USER_NOTIFICATION_METADATA_REPORT_REQUESTED,
    fetchUserNotificationMetadata
  );
}

export default function* userNotificationSaga() {
  yield all([
    getUserNotificationSaga(),
    getUserNotificationMetadataSaga(),
    updateUserNotificationMessageStatus()
  ]);
}
