import * as actionType from "../actions/ActionTypes";

const initialState = {
  addressList: [],
  editParticipant: null,
  addAddress: null,
  editAddress: null,
  loading: false,
  error: null,
  success: null
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADDRESS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADDRESS_LIST_SUCCESS: {
      return {
        ...state,
        statusList: action.payload,
        loading: false
      };
    }
    case actionType.ADDRESS_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_ADDRESS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_ADDRESS_SUCCESS: {
      return {
        ...state,
        addAddress: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_ADDRESS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.RESET_ADDRESS: {
      return {
        ...state,
        addAddress: null,
        loading: null
      };
    }
    // case actionType.RESET_MESSAGE: {
    //   return {
    //     ...state,
    //     error: null,
    //     success: false
    //   };
    // }
    case actionType.RESET_ADDRESS_LIST: {
      return {
        ...state,
        addressList: [],
        loading: null
      };
    }
    case actionType.EDIT_PARTICIPANT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PARTICIPANT_SUCCESS: {
      return {
        ...state,
        editParticipant: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_PARTICIPANT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default addressReducer;
