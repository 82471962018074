import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Button,
  Typography,
  Divider,
  Modal,
  Stack,
  TextField,
  InputLabel,
  Grid,
  InputAdornment
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import PercentIcon from "@mui/icons-material/Percent";

var prsFormData = {
  type: "",
  description: "",
  cycleCount: 0,
  maxPregnentPercent: 0,
  maxNonPregnentPercent: 0,
  cycle_info: [
    {
      cycle_no: 1,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 2,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 3,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 4,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 5,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 6,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 7,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 8,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 9,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 10,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    }
  ]
};

const PRSModal = (props) => {
  const [enableSave, setEnableSave] = useState(false);
  const [values, setValues] = useState(prsFormData);
  const [type, setType] = useState("Fixed");
  const [isOpen, setNewPRS] = useState(false);

  useEffect(() => {
    setNewPRS(props.isOpen);
  }, [props.isOpen]);
  useEffect(() => {
    if (values.cycleCount && values.maxPregnentPercent && values.maxNonPregnentPercent) {
      if (type !== "Fixed" && type !== "") {
        enableSaveSlidingScenario();
      } else {
        setEnableSave(true);
      }
    }
  }, [
    type,
    values.cycleCount,
    values.maxPregnentPercent,
    values.maxNonPregnentPercent,
    values.cycle_info
  ]);

  const handleInputChange = (e, index, name) => {
    let data = values.cycle_info;
    let description = "";
    if (name == "p_cycle_percent") data[index].p_cycle_percent = e.target.value;
    else if (name == "np_cycle_percent") data[index].np_cycle_percent = e.target.value;
    else description = e.target.value; // name  ==  description

    setValues({
      ...values,
      cycle_info: data,
      description: description.length > 0 ? description : values.description
    });
    if (type == "Sliding") enableSaveSlidingScenario();
  };
  const handleBlur = () => {
    if (type == "Sliding") {
      slidingScale();
    } else {
      fixedScale();
    }
  };
  const handleRadioChange = (e) => {
    let data = values.cycle_info;
    for (let i = 0; i < 10; i++) {
      data[i].p_cycle_percent = "";
      data[i].np_cycle_percent = "";
    }
    setType(e.target.value);
    setValues({
      ...values,
      cycle_info: data
    });

    if (e.target.value == "Fixed") fixedScale();
    else slidingScale();
  };

  const slidingScale = () => {
    let data = values;
    if (values.cycleCount > 0) {
      data.cycle_info[0].np_cycle_percent = parseInt(values.maxNonPregnentPercent);
      data.cycle_info[0].p_cycle_percent = parseInt(values.maxPregnentPercent);
      for (let i = 0; i < values.cycleCount; i++) {
        data.cycle_info[i].disabled = false;
      }
      for (let i = values.cycleCount; i < 10; i++) {
        data.cycle_info[i].disabled = true;
      }
    }
    enableSaveSlidingScenario();
    setValues({ ...values, cycle_info: data.cycle_info });
  };

  const fixedScale = () => {
    let data = values;
    if (values.cycleCount > 0) {
      for (let i = 0; i < values.cycleCount; i++) {
        data.cycle_info[i].np_cycle_percent = parseInt(values.maxNonPregnentPercent);
        data.cycle_info[i].p_cycle_percent = parseInt(values.maxPregnentPercent);
      }
      for (let i = values.cycleCount; i < 10; i++) {
        data.cycle_info[i].np_cycle_percent = "";
        data.cycle_info[i].p_cycle_percent = "";
      }
    }
    setValues({ ...values, cycle_info: data.cycle_info });
  };
  const updateDescription = () => {
    let description =
      " " + type + " " + values.maxPregnentPercent + "% upto " + values.cycleCount + " cycles";
    setValues({ ...values, description: description });
  };
  useEffect(() => {
    updateDescription();
  }, [type, values.maxPregnentPercent, values.maxNonPregnentPercent, values.cycleCount]);

  const enableSaveSlidingScenario = () => {
    for (let i = 0; i < values.cycleCount; i++) {
      if (
        values.cycle_info[i].p_cycle_percent.length == 0 ||
        values.cycle_info[i].np_cycle_percent == 0
      ) {
        setEnableSave(false);
        break;
      } else {
        setEnableSave(true);
      }
    }
  };
  const closeAddNewPRS = () => {
    let data = values.cycle_info;
    for (let i = 0; i < 10; i++) {
      data[i].p_cycle_percent = 0;
      data[i].np_cycle_percent = 0;
      data[i].disabled = true;
    }
    setValues({
      ...values,
      description: "",
      cycleCount: 0,
      maxPregnentPercent: 0,
      maxNonPregnentPercent: 0,
      cycle_info: data
    });
    setType("Fixed");
    setNewPRS(false);
    props.closeAddNewPRS();
  };
  const handleSubmit = () => {
    props.savePrs(values);
  };
  return (
    <>
      <Modal open={isOpen} backdrop="static" aria-labelledby="modal-add-title">
        <Box component="form" onSubmit={handleSubmit} className="modal-style-prs">
          <Stack direction="row" className="modal-stack">
            <Typography>
              <b>New Physician Reimbursement Scale</b>
            </Typography>
            <IconButton onClick={closeAddNewPRS}>
              <Close />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack direction="column">
                <Box className="modal-stack">
                  <FormControl>
                    <Typography>Type of scale?*</Typography>
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={(e) => handleRadioChange(e)}
                      defaultValue="Fixed">
                      <FormControlLabel value="Fixed" control={<Radio />} label="Fixed" />
                      <FormControlLabel value="Sliding" control={<Radio />} label="Sliding" />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Stack direction="row">
                  <Box sx={{ flex: 4 }}>
                    <Typography sx={{ wordBreak: "break-all" }}>Max % for</Typography>
                    <Typography sx={{ wordBreak: "break-all" }}>Pregnant*</Typography>
                  </Box>
                  <TextField
                    sx={{ flex: 8 }}
                    required
                    type="number"
                    name="maxPregnentPercent"
                    size="small"
                    inputProps={{ pattern: "[0-9]{1,2}" }}
                    value={values.maxPregnentPercent || ""}
                    onChange={(e) => {
                      let val = parseInt(e.target.value);
                      // if (val >= 100) val = 100;
                      // if (val < 0) val = 0;
                      setValues({
                        ...values,
                        [e.target.name]: val
                      });
                    }}
                    onBlur={() => handleBlur()}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="column">
                <Box>
                  <Stack direction="row" className="modal-stack">
                    <Box sx={{ flex: 4 }}>
                      <Typography sx={{ wordBreak: "break-all" }}>No. of</Typography>
                      <Typography sx={{ wordBreak: "break-all" }}>Cycles*</Typography>
                    </Box>

                    <TextField
                      sx={{ flex: 8 }}
                      required
                      type="number"
                      name="cycleCount"
                      size="small"
                      value={values.cycleCount || ""}
                      onChange={(e) => {
                        let val = parseInt(e.target.value);
                        if (val >= 10) val = 10;
                        if (val < 0) val = 0;
                        setValues({
                          ...values,
                          [e.target.name]: val
                        });
                      }}
                      onBlur={() => handleBlur()}
                    />
                  </Stack>
                  <Stack direction="row" className="modal-stack">
                    <Box sx={{ flex: 4 }}>
                      <Typography sx={{ wordBreak: "break-all" }}>Max % for Non</Typography>
                      <Typography sx={{ wordBreak: "break-all" }}>Pregnant*</Typography>
                    </Box>
                    <TextField
                      sx={{ flex: 8 }}
                      required
                      type="number"
                      name="maxNonPregnentPercent"
                      size="small"
                      value={values.maxNonPregnentPercent || ""}
                      onChange={(e) => {
                        let val = parseInt(e.target.value);
                        // if (val >= 100) val = 100;
                        // if (val < 0) val = 0;
                        setValues({
                          ...values,
                          [e.target.name]: val
                        });
                      }}
                      onBlur={() => handleBlur()}
                    />
                  </Stack>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Stack direction="row" className="modal-stack">
                <Box sx={{ flex: 4 }}></Box>
                <Box sx={{ flex: 4 }}>Pregnant</Box>
                <Box sx={{ flex: 4 }}>Non Pregnant</Box>
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 1
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle1"
                  size="small"
                  value={values?.cycle_info[0]?.p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 0, "p_cycle_percent")}
                  disabled={values?.cycle_info[0]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle1"
                  size="small"
                  value={values.cycle_info[0].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 0, "np_cycle_percent")}
                  disabled={values?.cycle_info[0]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 2
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle2"
                  size="small"
                  value={values.cycle_info[1].p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 1, "p_cycle_percent")}
                  disabled={values?.cycle_info[1]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle2"
                  size="small"
                  value={values.cycle_info[1].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 1, "np_cycle_percent")}
                  disabled={values?.cycle_info[1]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 3
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle3"
                  size="small"
                  value={values.cycle_info[2].p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 2, "p_cycle_percent")}
                  disabled={values?.cycle_info[2]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle3"
                  size="small"
                  value={values.cycle_info[2].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 2, "np_cycle_percent")}
                  disabled={values?.cycle_info[2]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 4
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle4"
                  size="small"
                  value={values.cycle_info[3].p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 3, "p_cycle_percent")}
                  disabled={values?.cycle_info[3]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle4"
                  size="small"
                  value={values.cycle_info[3].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 3, "np_cycle_percent")}
                  disabled={values?.cycle_info[3]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 5
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle5"
                  size="small"
                  value={values.cycle_info[4].p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 4, "p_cycle_percent")}
                  disabled={values?.cycle_info[4]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle5"
                  size="small"
                  value={values.cycle_info[4].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 4, "np_cycle_percent")}
                  disabled={values?.cycle_info[4]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" className="modal-stack">
                <Box sx={{ flex: 4 }}></Box>
                <Box sx={{ flex: 4 }}>Pregnant</Box>
                <Box sx={{ flex: 4 }}>Non Pregnant</Box>
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 6
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle6"
                  size="small"
                  value={values.cycle_info[5].p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 5, "p_cycle_percent")}
                  disabled={values?.cycle_info[5]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle6"
                  size="small"
                  value={values.cycle_info[5].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 5, "np_cycle_percent")}
                  disabled={values?.cycle_info[5]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 7
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle7"
                  size="small"
                  value={values.cycle_info[6].p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 6, "p_cycle_percent")}
                  disabled={values?.cycle_info[6]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle7"
                  size="small"
                  value={values.cycle_info[6].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 6, "np_cycle_percent")}
                  disabled={values?.cycle_info[6]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 8
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle8"
                  size="small"
                  value={values.cycle_info[7].p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 7, "p_cycle_percent")}
                  disabled={values?.cycle_info[7]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle8"
                  size="small"
                  value={values.cycle_info[7].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 7, "np_cycle_percent")}
                  disabled={values?.cycle_info[7]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 9
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle9"
                  size="small"
                  value={values.cycle_info[8].p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 8, "p_cycle_percent")}
                  disabled={values?.cycle_info[8]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle9"
                  size="small"
                  value={values.cycle_info[8].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 8, "np_cycle_percent")}
                  disabled={values?.cycle_info[8]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
              <Stack direction="row" className="modal-stack">
                <InputLabel required sx={{ wordBreak: true, flex: 4 }}>
                  Cycle 10
                </InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  name="pregnentCycle10"
                  size="small"
                  value={values.cycle_info[9].p_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 9, "p_cycle_percent")}
                  disabled={values?.cycle_info[9]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
                <Box sx={{ flex: 0.5 }}></Box>
                <TextField
                  sx={{ flex: 4 }}
                  name="nonPregnentCycle10"
                  size="small"
                  value={values.cycle_info[9].np_cycle_percent || ""}
                  onChange={(e) => handleInputChange(e, 9, "np_cycle_percent")}
                  disabled={values?.cycle_info[9]?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentIcon color="primary" fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                />
              </Stack>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Stack direction="row" className="modal-stack">
                  <InputLabel required sx={{ wordBreak: true, flex: 1.9, paddingLeft: "8px" }}>
                    Description
                  </InputLabel>
                  <TextField
                    sx={{ flex: 10 }}
                    required
                    name="description"
                    size="small"
                    value={values.description || ""}
                    onChange={(e) => handleInputChange(e, 0, "description")}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2} className="modal-bottom-section">
            <Button variant="outlined" className="btn-primary" onClick={closeAddNewPRS}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" className="btn-usf" disabled={!enableSave}>
              Save
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default PRSModal;
