import * as actionType from "../actions/ActionTypes";

const initialState = {
  serviceItemsList: [],
  addServiceItems: null,
  editServiceItems: null,
  loading: false,
  error: null,
  success: null
};

const serviceItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SERVICEITEMS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.SERVICEITEMS_LIST_SUCCESS: {
      return {
        ...state,
        serviceItemsList: action.payload,
        loading: false
      };
    }
    case actionType.SERVICEITEMS_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_SERVICEITEMS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_SERVICEITEMS_SUCCESS: {
      return {
        ...state,
        addServiceItems: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_SERVICEITEMS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.EDIT_SERVICEITEMS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_SERVICEITEMS_SUCCESS: {
      return {
        ...state,
        editServiceItems: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_SERVICEITEMS_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_SERVICEITEMS: {
      return {
        ...state,
        addServiceItems: null,
        editServiceItems: null,
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    case actionType.RESET_SERVICEITEMS_LIST: {
      return {
        ...state,
        serviceItemsList: [],
        loading: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default serviceItemReducer;
