import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchRevenueFilterList(action) {
  const { payload } = action;
  try {
    const params = {
      transactionDateTo: payload.transactionDateTo,
      transactionDateFrom: payload.transactionDateFrom,
      practiceId: payload.practiceId,
      firstName: payload.firstName,
      medcaseId: payload.medcaseId,
      location: payload.location,
      lastName: payload.lastName
    };
    const requestBody = createRequestBody("get", `/revenues`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.REVENUE_FILTER_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.REVENUE_FILTER_LIST_FAIL, errorMsg: error.response.data });
    }
  }
}

function* getRevenueFilterList() {
  yield takeEvery(actionTypes.REVENUE_FILTER_LIST_REQUESTED, fetchRevenueFilterList);
}

function* runRevenue() {
  try {
    const requestBody = createRequestBody("post", `/calculations/revenues`, "", {});
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.RUN_REVENUE_SUCCESS,
      payload: response.data,
      successMsg: "Revenues calculated successfully"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.RUN_REVENUE_FAIL,
        error: error.response.data
      });
    }
  }
}

function* runRevenueSaga() {
  yield takeEvery(actionTypes.RUN_REVENUE_REQUESTED, runRevenue);
}

export default function* revenueSaga() {
  yield all([getRevenueFilterList(), runRevenueSaga()]);
}
