import { Box, List } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import ListItemLink from "../../shared/components/ListItemLink";
import { msgConfig } from "../constants/constants";

const MessageConfiguration = () => {
  const location = useLocation();
  const selectedPath = msgConfig.findIndex((item) => item.url === location.pathname);
  const [selectedIndex, setSelectedIndex] = useState(selectedPath);
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <Box className="sidebar-container">
      <List disablePadding>
        {msgConfig.map(({ title, url, icon }, index) => (
          <ListItemLink
            key={index}
            selected={selectedIndex === index}
            onClick={() => {
              handleListItemClick(index);
              // props.getSelectedConfig(text);
            }}
            to={url}
            primary={title}
            icon={<img src={icon} alt="" />}
          />
        ))}
      </List>
    </Box>
  );
};

export default MessageConfiguration;
