import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Stack, Divider, IconButton, CircularProgress } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import WarningPopUp from "./modals/WarningPopUp";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const Document = (props) => {
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isOpen, setIsOpen] = useState(false);
  let { id } = useParams();
  const columns = [
    {
      field: "No",
      headerName: "NO.",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => row.id
    },
    {
      field: "medcase_id",
      headerName: "MEDCASE ID",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "document_type",
      headerName: "DOCUMENT",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "program_name",
      headerName: "PROGRAM",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "contract_generated",
      headerName: "RECEIVED DATE AND TIME",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) =>
        row.contract_generated ? moment(row.contract_generated).format("MM/DD/YYYY") : ""
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "expiry_date",
      headerName: "DOCUMENT EXPIRY DATE",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (row.expiry_date ? moment(row.expiry_date).format("MM/DD/YYYY") : "")
    },
    {
      field: "action",
      headerName: "DOCUMENT",
      width: 80,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <IconButton
            onClick={() => {
              handleDownload(row);
            }}>
            <TextSnippetIcon />
          </IconButton>
        );
      }
    }
  ];

  const columns1 = [
    {
      field: "no",
      headerName: "NO.",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => row.id
    },
    {
      field: "medcase_id",
      headerName: "MEDCASE No.",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "document_type",
      headerName: "DOCUMENT",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "program_name",
      headerName: "PROGRAM",
      width: 100,
      flex: 1
    },
    {
      field: "contract_generated",
      headerName: "RECEIVED/SIGNED DATE AND TIME",
      width: 100,
      flex: 1,
      renderCell: ({ row }) =>
        row.contract_generated ? moment(row.contract_generated).format("MM/DD/YYYY") : ""
    },
    {
      field: "action",
      headerName: "DOCUMENT",
      width: 50,
      editable: true,
      flex: 0.5,
      renderCell: ({ row }) => {
        return (
          <IconButton
            onClick={() => {
              handleDownload(row);
            }}>
            <TextSnippetIcon />
          </IconButton>
        );
      }
    }
  ];

  const {
    documentList = [],
    actions,
    addDocument,
    editDocument,
    errorMsg,
    successMsg,
    loading,
    docloading,
    success,
    error
  } = props;

  const initFetch = useCallback(() => {
    actions.getDocument({ medcaseId: id });
  }, [addDocument, editDocument]);

  useEffect(() => {
    if (documentList.length == 0 || addDocument || editDocument) {
      initFetch();
    }
    return () => {
      actions.resetDocumentList();
    };
  }, [addDocument, editDocument, errorMsg, successMsg]);

  let rowsTopTable = documentList.unsigned;
  let rowsBottomTable = documentList.signed;
  rowsTopTable =
    documentList && documentList.unsigned && documentList.unsigned.length > 0
      ? documentList.unsigned.map((item, index) => {
          item.id = index + 1;
          return item;
        })
      : [];
  rowsBottomTable =
    documentList && documentList.signed && documentList.signed.length > 0
      ? documentList.signed.map((item, index) => {
          item.id = index + 1;
          return item;
        })
      : [];

  //   const document = documentConfig({
  //     values,
  //     onChange: handleInputChange,
  //     onClick: closeModal,
  //     isAdd,
  //     disabled: values.program && values.type && values.relativeValueNumber ? false : true
  //   });

  const handleDownload = (item) => {
    if (item?.contract_id) {
      const payload = { contractId: item.contract_id };
      actions.contractDocumentDownload(payload);
    }
  };

  useEffect(() => {
    if (success?.documentUrl) {
      var a = document.createElement("a");
      a.href = success.documentUrl;
      a.download = "document.pdf";
      a.click();
      actions.resetDocumentDownload();
    }
    if (error) {
      setIsOpen(true);
    }
  }, [success, error]);

  return (
    <>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Typography variant="h2" sx={{ fontWeight: "bold" }}>
          Document Management
        </Typography>
      </Box>
      <Divider
        sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA", margin: "8px" }}
      />
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="grid-header">
          <Typography>Please review and sign the contract.</Typography>
        </Stack>
        <Box className="grid-style" sx={{ marginBottom: 8, height: "660px" }}>
          <h5 style={{ marginTop: "5px" }}>Awaiting Signature</h5>
          {docloading ? (
            <Stack justifyContent="center" p={5} alignItems="center">
              <CircularProgress size={50} />
            </Stack>
          ) : (
            <DataGridPremiumComp
              rows={rowsTopTable}
              rowCount={rowsTopTable?.length}
              columns={columns}
              loading={loading}
              pageSizeOptions={[10, 25, 50, 100]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
            />
          )}
        </Box>
      </Box>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="grid-header">
          <Typography> You can view received /signed document here</Typography>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <h5 style={{ marginTop: "5px" }}>Received/Signed Documents</h5>
          {docloading ? (
            <Stack justifyContent="center" p={5} alignItems="center">
              <CircularProgress size={50} />
            </Stack>
          ) : (
            <DataGridPremiumComp
              rows={rowsBottomTable}
              rowCount={rowsBottomTable?.length}
              columns={columns1}
              loading={loading}
              pageSizeOptions={[10, 25, 50, 100]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
            />
          )}
        </Box>
      </Box>
      <WarningPopUp
        open={isOpen}
        closeModal={() => {
          setIsOpen(false);
          actions.resetDocumentDownload();
        }}
        error={error}
      />
    </>
  );
};
const mapStateToProps = ({ documentReducer, contractReducer }) => {
  return {
    // loading: documentReducer.loading
    documentList: documentReducer.documentList,
    addDocument: documentReducer.addDocument,
    editDocument: documentReducer.editDocument,
    errorMsg: documentReducer.error,
    successMsg: documentReducer.success,
    loading: documentReducer.loading,
    docloading: contractReducer.loading,
    success: contractReducer.successMsg,
    error: contractReducer.errorDownload
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getDocument: (id) => {
      dispatch(actionType.getDocument(id));
    },
    addNewDocument: (payload) => {
      dispatch(actionType.addNewDocument(payload));
    },
    editDocument: (payload) => {
      dispatch(actionType.editDocument(payload));
    },
    resetDocumentList: () => {
      dispatch(actionType.resetDocumentList());
    },
    contractDocumentDownload: (payload) => {
      dispatch(actionType.contractDocumentDownload(payload));
    },
    resetDocumentDownload: () => {
      dispatch(actionType.resetContractDocumentDownload());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Document);
