import { Box, Grid, Typography } from "@mui/material";

const StageServiceItemList = ({ data }) => {
  const renderGrossPrice = (serviceItems, index) => {
    if (serviceItems.length > 1) {
      return (
        <Typography component="span" key={index}>
          ${}
          {serviceItems
            .sort((first, second) => {
              return first.grossPrice > second.grossPrice ? 1 : -1;
            })
            .map((item) => item)[0]
            .grossPrice.toLocaleString()}
          {" - "}$
          {serviceItems
            .sort((first, second) => {
              return first.grossPrice > second.grossPrice ? 1 : -1;
            })
            .map((item) => item)
            [serviceItems.length - 1].grossPrice.toLocaleString()}
        </Typography>
      );
    } else {
      return (
        <Typography component="span" key={index}>
          ${}
          {serviceItems
            .sort((first, second) => {
              return first.grossPrice > second.grossPrice ? 1 : -1;
            })
            .map((item) => item)[0]
            .grossPrice.toLocaleString()}
        </Typography>
      );
    }
  };
  return (
    <>
      {data.map(({ tpStage, serviceItems }, index) => {
        if (tpStage.range) {
          return (
            <Box key={index} py={0.5}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography>{tpStage.stageName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{renderGrossPrice(serviceItems, index)}</Typography>
                </Grid>
              </Grid>
            </Box>
          );
        } else {
          return (
            <Box key={index} py={0.5}>
              <Typography>{tpStage.stageName}</Typography>
              {serviceItems.map((services, i) => {
                return (
                  <Grid container key={i}>
                    <Grid item xs={6}>
                      <Typography ml={1}>{services.serviceDesc}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>${services.grossPrice.toLocaleString()}</Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          );
        }
      })}
    </>
  );
};

export default StageServiceItemList;
