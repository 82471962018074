import { Box, Divider, Grid, Stack } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Breadcrumb from "../../shared/components/Breadcrumb";
import CaseLookupCard from "./CaseLookupCard";
import MedcaseCard from "./MedcaseCard";
import CaseLookupConfiguration from "./CaseLookupConfig";

const CaseLookupContext = () => {
  const getSelectedConfig = (selected) => {
    console.log(selected);
  };

  return (
    <Box>
      <Grid container className="">
        <Grid item xs={12}>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}>
            <Divider sx={{ height: 26, m: 0.5 }} orientation="vertical" />
            {/* breadcrumb start here */}
            <Breadcrumb />
            {/* breadcrumb end here */}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <CaseLookupCard />
          <MedcaseCard />
        </Grid>

        <Grid container item xs={12}>
          <Grid item xs={1.6} sx={{ background: "#818294", minHeight: "110vh" }}>
            <Grid container>
              <CaseLookupConfiguration
                locationState={location.state}
                getSelectedConfig={getSelectedConfig}
              />
            </Grid>
          </Grid>
          <Grid item xs={10.4}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseLookupContext;
