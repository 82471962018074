import * as actionType from "./ActionTypes";

// get partner Data
export const getPartnerData = (payload) => {
  return {
    type: actionType.PARTNER_DATA_REQUESTED,
    payload
  };
};
export const resetPartnerData = () => {
  return {
    type: actionType.RESET_PARTNER_DATA
  };
};

//get Task list

export const getTaskData = () => {
  return {
    type: actionType.TASK_DATA_REQUESTED
  };
};

export const getCheckedTaskData = (payload) => {
  return {
    type: actionType.TASK_CHECKED_DATA_REQUESTED,
    payload
  };
};

export const UpdateCheckedTaskData = (payload) => {
  return {
    type: actionType.UPDATE_CHECKED_DATA_REQUESTED,
    payload
  };
};
export const getPersonalList = (payload) => {
  return {
    type: actionType.PERSONAL_DATA_REQUESTED,
    payload: payload
  };
};
export const resetPersonalList = () => {
  return {
    type: actionType.RESET_PERSONAL_LIST
  };
};
// Add Patient Details
export const addPatientDetails = (payload) => {
  return {
    type: actionType.ADD_PATIENT_DETAILS_REQUESTED,
    payload
  };
};
export const resetPatientDetailsMsg = () => {
  return {
    type: actionType.RESET_PATIENT_DETAILS_MSG
  };
};
export const getPersonDataById = (payload) => {
  return {
    type: actionType.PERSON_DATA_REQUESTED,
    payload: payload
  };
};
export const updatePatientDetails = (payload) => {
  return {
    type: actionType.UPDATE_PATIENT_DETAILS_REQUESTED,
    payload
  };
};
export const resetUpdatePatientDetails = () => {
  return {
    type: actionType.RESET_UPDATE_PATIENT_DETAILS_MSG
  };
};
export const getPatientPartnerList = (payload) => {
  return {
    type: actionType.GET_PATIENT_PARTNER_REQUESTED,
    payload
  };
};
export const resetPatientPartnerList = () => {
  return {
    type: actionType.RESET_PATIENT_PARTNER_LIST
  };
};

export const getFAPDetails = (payload) => {
  return {
    type: actionType.FAP_DATA_REQUESTED,
    payload
  };
};

export const resetFAPDetails = () => {
  return {
    type: actionType.RESET_FAP_DATA
  };
};

export const getPrimaryMdList = (payload) => {
  return {
    type: actionType.GET_PRIMARY_MD_REQUESTED,
    payload
  };
};

export const resetPrimaryMd = () => {
  return {
    type: actionType.RESET_PRIMARY_MD_LIST
  };
};
