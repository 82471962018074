import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Typography, Stack } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { phoneConfig } from "../../shared/constants/renderConfigs";
import Radio from "@mui/material/Radio";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const Phone = (props) => {
  let phoneFormData = {
    type: "",
    cellPhone: "",
    workPhone: "",
    homePhone: ""
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState("");
  const [changeType, setChangeType] = useState("");
  const {
    phoneList = [],
    actions,
    addPhone,
    editPhone,
    errorMsg,
    successMsg,
    phoneInfo,
    loading,
    participantList
  } = props;

  const columns = [
    {
      field: "default",
      headerName: "DEFAULT",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <Radio
          checked={participantList.defaultPhone == row.type.toLowerCase()}
          value={participantList.defaultPhone}
        />
      )
    },
    {
      field: "type",
      headerName: "TYPE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "number",
      headerName: "NUMBER",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.number == null ? "--" : row.number;
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.5,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton>
          <EditIcon
            onClick={() => {
              handleEditPhone(row);
            }}
            color="primary"></EditIcon>
        </IconButton>
      )
    }
  ];

  const rows = phoneInfo.map((item, index) => {
    item.id = index;
    return item;
  });
  const initFetch = useCallback(() => {
    actions.getPhone();
  }, [addPhone, editPhone]);

  useEffect(() => {
    actions.getPhone();
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (phoneList.length == 0 || addPhone || editPhone) {
      initFetch();
    }
    return () => {
      actions.resetPhoneList();
    };
  }, [addPhone, editPhone, errorMsg, successMsg]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    rows.forEach((element) => {
      if (element.type == "Home") {
        values["homePhone"] = element.number;
      } else if (element.type == "Work") {
        values["workPhone"] = element.number;
      } else if (element.type == "Cell") {
        values["cellPhone"] = element.number;
      } else if (element.type == "Additonal") {
        values["additionalPhone"] = element.number;
      }
    });

    setValues({
      ...values,
      ...props.participantList?.patient,
      [name]: value
    });
    setChangeType(name.toLowerCase());
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const savePhone = (event) => {
    event.preventDefault();
    const defaultType =
      values.Homedefault == "on" ||
      values.Celldefault == "on" ||
      values.Workdefault == "on" ||
      values.Additionaldefault == "on"
        ? changeType.replace("default", "")
        : props.participantList.patient.defaultPhone;
    const payload = { practiceId: props.practiceId, value: values, defaultType: defaultType };
    actions.editParticipant(payload);
    closeModal();
  };

  const handleEditPhone = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    const defaultChecked = row.type;
    phoneFormData = {
      type: row.type,
      number: row.number,
      personId: participantList.personId,
      defaultPhone: participantList.defaultPhone,
      defaultChecked: defaultChecked.toLowerCase()
    };
    setValues(phoneFormData);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const phone = phoneConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.type ? false : true,
    defaultType: values.type + "default"
  });
  return (
    <>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="grid-header" sx={{ marginBottom: "10px" }}>
          <Typography>Phone</Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // height: "100%"
            minHeight: "650px"
          }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal open={isOpen} config={phone} isAdd={isAdd} handleSubmit={savePhone} />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};
const mapStateToProps = ({ phoneReducer, participantReducer }) => {
  return {
    loading: phoneReducer.loading,
    phoneList: phoneReducer.phoneList,
    addPhone: phoneReducer.addPhone,
    editPhone: phoneReducer.editPhone,
    deletePhone: phoneReducer.deletePhone,
    errorMsg: phoneReducer.error,
    successMsg: phoneReducer.success,
    participantList: participantReducer.participantList
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPhone: () => {
      dispatch(actionType.getPhone());
    },
    addNewPhone: (payload) => {
      dispatch(actionType.addNewPhone(payload));
    },
    editPhone: (payload) => {
      dispatch(actionType.editPhone(payload));
    },
    resetPhoneList: () => {
      dispatch(actionType.resetPhoneList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    deletePhone: (payload) => {
      dispatch(actionType.deletePhone(payload));
    },
    editParticipant: (payload) => {
      dispatch(actionType.editParticipant(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Phone);
