import { Box, Button, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";

const SaveModal = ({ open, close, handleSave }) => {
  return (
    <Modal open={open}>
      <Box className="modal-style-delete">
        <Stack direction="row" className="modal-stack">
          <Typography>Add new fee item</Typography>
          <IconButton onClick={close}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Stack>
        <Divider className="modal-divider" />
        <Stack direction="column" className="modal-stack pad-5">
          <Typography variant="body1">New fee applied to program</Typography>
        </Stack>
        <Stack className="align-center">
          <Button onClick={handleSave} variant="contained">
            Ok
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default SaveModal;
