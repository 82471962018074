import { Box, Button, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";
// import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const ConfirmationModal = ({
  open,
  close,
  handleOpeartion,
  message = "Are you sure?",
  confirmText = "Yes",
  declineText = "No"
}) => {
  return (
    <Modal open={open}>
      <Box className="modal-style-delete">
        <Stack direction="row" className="modal-stack">
          <Typography>Confirmation</Typography>
          <IconButton onClick={close}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Stack>
        <Divider className="modal-divider" />
        <Stack direction="column" className="modal-stack">
          {/* <WarningAmberIcon fontSize="large" color="error" /> */}
          <Typography variant="body1">{message}</Typography>
        </Stack>
        <Stack direction="row" className="modal-stack">
          <Button variant="outlined" onClick={close}>
            {declineText}
          </Button>
          <Button onClick={handleOpeartion} variant="contained">
            {confirmText}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default ConfirmationModal;
