import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useCallback, useEffect, useState } from "react";
import DeleteModal from "../../shared/components/DeleteModal";
import { Stack } from "@mui/system";
import { connect, useSelector } from "react-redux";
import * as actionType from "../actions";
import MessageModal from "../../shared/components/MessageModal";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const Multiplier = (props) => {
  const columns = [
    {
      field: "programName",
      headerName: "PROGRAM",
      width: 50,
      flex: 0.8
    },
    {
      field: "treatmentPlanName",
      headerName: "TREATMENT PLAN",
      width: 100,
      editable: false,
      flex: 1
      // renderCell: ({ row }) => {
      //   return row?.treatmentPlanCustomName != null
      //     ? row.treatmentPlanCustomName
      //     : row.treatmentPlanName
      // }
    },
    {
      field: "globalPrice",
      headerName: "GLOBAL PRICE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "assistPrice",
      headerName: "ASSIST PRICE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "minimumAge",
      headerName: "AGE MIN",
      width: 50,
      editable: false,
      flex: 0.8,
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <div>{row.minimumAge}</div>
          </Stack>
        );
      }
    },
    {
      field: "maximumAge",
      headerName: "AGE MAX",
      width: 50,
      editable: false,
      flex: 0.8,
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <div>{row.maximumAge}</div>
          </Stack>
        );
      }
    },
    {
      field: "multiplier",
      headerName: "MULTIPLIER",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "programPriceGlobal",
      headerName: "PROGRAM PRICE (GLOBAL)",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return Math.round((row.globalPrice * row.multiplier) / 100) * 100;
      }
    },
    {
      field: "programPriceAssist",
      headerName: "PROGRAM PRICE (ASSIST)",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return Math.round((row.assistPrice * row.multiplier) / 100) * 100;
      }
    },
    {
      field: "refundPercentage",
      headerName: "REFUND %",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 1,
      filterable: false,
      hide: true,
      disableExport: true,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              editMultipler(row);
            }}>
            <EditIcon color={disabled ? "disabled" : "primary"}></EditIcon>
          </IconButton>
          <IconButton
            disabled={disabled}
            onClick={() => {
              setDeleteModal(true);
              setProgramMultiplierId(row.programMultiplierId);
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"}></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];

  let multiplierFormData = {
    programName: "",
    treatmentPlanName: "",
    globalPrice: "",
    assistPrice: "",
    minimumAge: "",
    maximumAge: "",
    multiplier: "",
    programPriceGlobal: "",
    programPriceAssist: "",
    refundPercentage: "",
    treatmentPlanId: "",
    programId: ""
  };

  const [values, setValues] = useState(multiplierFormData);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [programMultiplierId, setProgramMultiplierId] = useState("");
  const programList = useSelector((state) => state.programReducer.programList);
  const treatmentPlanList = useSelector((state) => state.treatmentPlanReducer.treatmentPlanList);
  const practiceId = sessionStorage.getItem("practiceId");
  const gdmId = sessionStorage.getItem("gdmId");
  const {
    toggleModal,
    multiplier,
    multiplierList,
    addMultiplier,
    editMultiplier,
    deleteMultiplier,
    loading,
    errorMsg,
    successMsg,
    actions
  } = props;
  const disabled = localStorage.getItem("status") !== "In-progress" ? true : false;

  useEffect(() => {
    if (toggleModal) {
      setValues(multiplierFormData);
      setIsAdd(true);
      setIsOpen(true);
    }
  }, [toggleModal]);

  useEffect(() => {
    if (programList.length == 0) {
      let payload = { practiceId, gdmId };
      actions.getPrograms(payload);
    }
    if (treatmentPlanList.length == 0) {
      const payload = { practiceId, gdmId };
      actions.getTreatmentPlan(payload);
    }
  }, []);

  const initFetch = useCallback(() => {
    let payload = { practiceId, gdmId };
    actions.getMultipliers(payload);
  }, [addMultiplier, editMultiplier, deleteMultiplier]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (multiplierList.length === 0 || addMultiplier || editMultiplier || deleteMultiplier) {
      initFetch();
    }
    return () => {
      actions.resetMultipliers();
    };
  }, [addMultiplier, editMultiplier, deleteMultiplier, errorMsg, successMsg]);

  const rows = multiplierList.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate).toLocaleDateString();
    item.globalPrice = Math.round(item.globalPrice);
    item.assistPrice = Math.round(item.assistPrice);
    item.programPriceGlobal = Math.round((item?.globalPrice * item?.multiplier) / 100) * 100;
    item.programPriceAssist = Math.round((item?.assistPrice * item?.multiplier) / 100) * 100;
    item.id = index;
    return item;
  });

  const editMultipler = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    multiplierFormData = {
      programName: row.programName,
      treatmentPlanName: row.treatmentPlanName,
      globalPrice: row.globalPrice,
      assistPrice: row.assistPrice,
      minimumAge: row?.minimumAge,
      maximumAge: row?.maximumAge,
      multiplier: row?.multiplier,
      programPriceGlobal: row.globalPrice * row?.multiplier,
      programPriceAssist: row.assistPrice * row?.multiplier,
      refundPercentage: row?.refundPercentage,
      practiceId: practiceId,
      gdmId: gdmId,
      programMultiplierId: row.programMultiplierId
    };
    setValues(multiplierFormData);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "treatmentPlanName") {
      let treatmentPlan = treatmentPlanList.filter((item) => {
        return item.treatmentPlanName === value;
      });

      values.globalPrice = treatmentPlan[0].globalPrice;
      values.assistPrice = treatmentPlan[0].assistPrice;
      values.treatmentPlanId = treatmentPlan[0].treatmentPlanId;
      setValues({
        ...values,
        [name]: value
      });
    } else if (name === "minimumAge" || name === "maximumAge") {
      if (value < 0 || value > 99) {
        return;
      }
      setValues({
        ...values,
        [name]: value
      });
    } else if (name === "multiplier") {
      if (value <= 0) {
        return;
      }
      values.programPriceGlobal = Math.round(values.globalPrice * value);
      values.programPriceAssist = values.assistPrice * value;
      setValues({
        ...values,
        [name]: value
      });
    } else if (name === "refundPercentage") {
      if (value < 0) {
        return;
      }
      setValues({
        ...values,
        [name]: value
      });
    } else {
      setValues({
        ...values,
        [name]: value
      });
    }
  };
  const closeModal = () => {
    multiplier(false);
    setValues(multiplierFormData);
    setIsOpen(false);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const saveMultiplier = (e) => {
    e.preventDefault();
    let program = programList.filter((item) => {
      return item.programName === values.programName;
    });

    let treatmentPlan = treatmentPlanList.filter((item) => {
      return item.treatmentPlanName === values.treatmentPlanName;
    });

    let payload = {
      ...values,
      practiceId,
      gdmId,
      treatmentPlanId: treatmentPlan[0].treatmentPlanId,
      programId: program[0].programId
    };
    if (isAdd) {
      actions.addMultiplier(payload);
    } else {
      actions.editMultiplier(payload);
    }
    closeModal();
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const handleDelete = (e) => {
    e.preventDefault();
    const payload = { practiceId, gdmId, programMultiplierId };
    actions.deleteMultiplier(payload);
    closeDeleteModal();
  };

  return (
    <>
      <Box
        className={`${loading ? "grid-style disable-section" : "grid-style"}`}
        sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          localeText={"Export Button"}
          headerText={"Multiplier"}
          isExport={true}
        />
      </Box>
      <Modal open={isOpen} backdrop="static" aria-labelledby="modal-add-title">
        <Box component="form" onSubmit={saveMultiplier} className="modal-style">
          <Stack direction="row" className="modal-stack">
            <Typography>{isAdd ? "New Multiplier" : "Edit Multiplier"}</Typography>
            <IconButton onClick={closeModal}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Box className="multiplier-container">
            <Stack className="multipler-items">
              <Grid container className="modal-stack">
                <Grid item xs={3.5}>
                  <InputLabel required={true}>Program</InputLabel>
                </Grid>
                <Grid item xs={8.5}>
                  <Select
                    size="small"
                    value={values.programName}
                    autoComplete="off"
                    sx={{ minWidth: "100%" }}
                    onChange={handleInputChange}
                    name="programName">
                    {programList.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.programName}>
                          {item.programName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              <Grid container className="modal-stack">
                <Grid item xs={3.5}>
                  <InputLabel required={true} className="word-break">
                    Treatment Plan
                  </InputLabel>
                </Grid>
                <Grid item xs={8.5}>
                  <Select
                    size="small"
                    value={values.treatmentPlanName}
                    autoComplete="off"
                    sx={{ minWidth: "100%" }}
                    onChange={handleInputChange}
                    name="treatmentPlanName">
                    {treatmentPlanList.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.treatmentPlanName}>
                          {item.treatmentPlanName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              <Grid container className="modal-stack">
                <Grid item xs={3.5}>
                  <InputLabel>Global Price</InputLabel>
                </Grid>
                <Grid item xs={8.5}>
                  <TextField
                    disabled={true}
                    size="small"
                    value={values.globalPrice}
                    className="min-width-100 textfield-background"
                    name="globalPrice"
                  />
                </Grid>
              </Grid>
              <Grid container className="modal-stack">
                <Grid item xs={3.5}>
                  <InputLabel>Assist Price</InputLabel>
                </Grid>
                <Grid item xs={8.5}>
                  <TextField
                    disabled={true}
                    size="small"
                    value={values.assistPrice}
                    className="min-width-100 textfield-background"
                    name="assistPrice"
                  />
                </Grid>
              </Grid>
              <Grid container className="modal-stack">
                <Grid item xs={3.5}>
                  <InputLabel required={true}>Age Min</InputLabel>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    autoComplete="off"
                    onChange={handleInputChange}
                    value={values.minimumAge}
                    type="number"
                    InputProps={{ inputProps: { min: 1, max: 99 } }}
                    sx={{ minWidth: "100%" }}
                    name="minimumAge"
                  />
                </Grid>
                <Grid item xs={2.4}>
                  <InputLabel required={true}>Age Max</InputLabel>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    size="small"
                    autoComplete="off"
                    type="number"
                    InputProps={{ inputProps: { min: 1, max: 99 } }}
                    onChange={handleInputChange}
                    value={values.maximumAge}
                    sx={{ minWidth: "100%" }}
                    name="maximumAge"
                  />
                </Grid>
              </Grid>
              <Grid container className="modal-stack">
                <Grid item xs={3.5}>
                  <InputLabel required={true}>Multiplier</InputLabel>
                </Grid>
                <Grid item xs={8.5}>
                  <TextField
                    size="small"
                    autoComplete="off"
                    type="number"
                    value={values.multiplier}
                    onChange={handleInputChange}
                    InputProps={{ inputProps: { min: 1, step: "any" } }}
                    sx={{ minWidth: "100%" }}
                    name="multiplier"
                  />
                </Grid>
              </Grid>
              <Grid container className="modal-stack">
                <Grid item xs={3.5}>
                  <InputLabel className="word-break">Program Price (Global)</InputLabel>
                </Grid>
                <Grid item xs={8.5}>
                  <TextField
                    size="small"
                    disabled={true}
                    value={Math.round(values.programPriceGlobal)}
                    className="min-width-100 textfield-background"
                    name="programPriceGlobal"
                  />
                </Grid>
              </Grid>
              <Grid container className="modal-stack">
                <Grid item xs={3.5}>
                  <InputLabel className="word-break">Program Price (Assist)</InputLabel>
                </Grid>
                <Grid item xs={8.5}>
                  <TextField
                    size="small"
                    disabled={true}
                    value={values.programPriceAssist}
                    className="min-width-100 textfield-background"
                    name="programPriceAssist"
                  />
                </Grid>
              </Grid>
              <Grid container className="modal-stack">
                <Grid item xs={3.5}>
                  <InputLabel className="word-break" required={true}>
                    Refund %
                  </InputLabel>
                </Grid>
                <Grid item xs={8.5}>
                  <TextField
                    size="small"
                    id="email-name"
                    autoComplete="off"
                    value={values.refundPercentage}
                    onChange={handleInputChange}
                    InputProps={{ inputProps: { min: 0, step: "any" } }}
                    sx={{ minWidth: "100%" }}
                    name="refundPercentage"
                    type="number"
                  />
                </Grid>
              </Grid>
            </Stack>
          </Box>
          <Stack direction="row" spacing={2} className="modal-bottom-section">
            <Button variant="contained" className="btn-primary" onClick={closeModal}>
              Close
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={
                values.programName &&
                values.treatmentPlanName &&
                values.multiplier &&
                values.minimumAge &&
                values.maximumAge &&
                values.refundPercentage
                  ? false
                  : true
              }
              className="btn-usf">
              {isAdd ? "Create" : "Save"}
            </Button>
          </Stack>
        </Box>
      </Modal>
      <DeleteModal
        open={deleteModal}
        id={programMultiplierId}
        handleDelete={handleDelete}
        close={closeDeleteModal}
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};

const mapStateToProps = ({ multiplier }) => {
  return {
    multiplierList: multiplier.multiplierList,
    addMultiplier: multiplier.addMultiplier,
    editMultiplier: multiplier.editMultiplier,
    deleteMultiplier: multiplier.deleteMultiplier,
    loading: multiplier.loading,
    errorMsg: multiplier.error,
    successMsg: multiplier.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPrograms: (payload) => {
      dispatch(actionType.getPrograms(payload));
    },
    getTreatmentPlan: (payload) => {
      dispatch(actionType.getTreatmentPlan(payload));
    },
    getMultipliers: (payload) => {
      dispatch(actionType.getMultipliers(payload));
    },
    addMultiplier: (payload) => {
      dispatch(actionType.addMultiplier(payload));
    },
    editMultiplier: (payload) => {
      dispatch(actionType.editMultiplier(payload));
    },
    deleteMultiplier: (payload) => {
      dispatch(actionType.deleteMultiplier(payload));
    },
    resetMultipliers: () => {
      dispatch(actionType.resetMultipliers());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Multiplier);
