import React, { useState, useEffect } from "react";
import { Box, Stack, TextField, Grid, Typography, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as actionType from "../actions";
import { connect } from "react-redux";
//import moment from "moment";
import moment from "moment-timezone";
// import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import { GRID_AGGREGATION_FUNCTIONS } from "@mui/x-data-grid-premium";

const PaymentRegister = (props) => {
  const formData = {
    from: null,
    to: null,
    recordedOnStart: null,
    recordedOnEnd: null
  };
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [values, setValues] = useState(formData);
  const [csvData, setCsvData] = useState([]);
  const { paymentRegisterReportList = [], actions, loading } = props;
  const callAPI = () => {
    actions.getPaymentRegisterReport({
      startDate: values.from,
      endDate: values.to,
      recordedOnStart: values.recordedOnStart,
      recordedOnEnd: values.recordedOnEnd,
      reportType: "Payment Register"
    });
  };

  useEffect(() => {
    if (paymentRegisterReportList.length != 0) {
      const rows = paymentRegisterReportList.map((item, index) => {
        item.id = index;
        return item;
      });
      const csvDataMode = prepareCsvData(rows);
      console.log("csvDataMode: ", csvDataMode);
      setCsvData(csvDataMode);
    }
  }, [paymentRegisterReportList]);

  useEffect(() => {
    return actions.resetMessage();
  }, []);

  const formateCommasepratedNumber = (actualAmount) => {
    let amt = new Intl.NumberFormat().format(actualAmount);
    let convertedAmt = amt.toLocaleString();
    return convertedAmt;
  };

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      patientNameAndKey:
        row?.patientName && row?.personNumber ? `${row.patientName}  ${row.personNumber}` : "",
      receipts: row?.receipts ? parseFloat(row.receipts) : "",
      programRefunds: row?.programRefunds ? parseFloat(row.programRefunds) : "",
      pregnancyRefund: row?.pregnancyRefund ? parseFloat(row.pregnancyRefund) : "",
      miscellaneousRefund: row?.miscellaneousRefund ? parseFloat(row.miscellaneousRefund) : ""
    }));
  };

  const CustomToolbar = () => (
    <Grid container className="align-center">
      <Grid item xs={0.75}>
        <Typography style={{ marginLeft: "10px" }}>Transaction Start Date*</Typography>
      </Grid>
      <Grid item xs={1.25}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            openTo="day"
            views={["day", "month", "year"]}
            disableFuture
            value={values.from}
            onChange={(newValue) => {
              const format2 = "YYYY-MM-DD";
              newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
              setValues({ ...values, from: newValue });
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" className="patient-dropdown" fullWidth />
            )}></DatePicker>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={0.25}></Grid>
      <Grid item xs={0.75}>
        <Typography style={{ marginLeft: "10px" }}>Transaction End Date*</Typography>
      </Grid>
      <Grid item xs={1.25}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            openTo="day"
            views={["day", "month", "year"]}
            disableFuture
            value={values.to}
            onChange={(newValue) => {
              const format2 = "YYYY-MM-DD";
              newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
              setValues({ ...values, to: newValue });
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" className="patient-dropdown" fullWidth />
            )}></DatePicker>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={0.5}></Grid>

      <Grid item xs={0.75}>
        <Typography style={{ marginLeft: "10px" }}>Recorded Start Date*</Typography>
      </Grid>
      <Grid item xs={1.25}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            openTo="day"
            views={["day", "month", "year"]}
            disableFuture
            value={values.recordedOnStart}
            onChange={(newValue) => {
              const format2 = "YYYY-MM-DD";
              newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
              setValues({ ...values, recordedOnStart: newValue });
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" className="patient-dropdown" fullWidth />
            )}></DatePicker>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={0.25}></Grid>
      <Grid item xs={0.75}>
        <Typography style={{ marginLeft: "10px" }}>Recorded End Date*</Typography>
      </Grid>
      <Grid item xs={1.25}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            openTo="day"
            views={["day", "month", "year"]}
            disableFuture
            value={values.recordedOnEnd}
            onChange={(newValue) => {
              const format2 = "YYYY-MM-DD";
              newValue = moment(newValue).isValid() ? moment(newValue).format(format2) : null;
              setValues({ ...values, recordedOnEnd: newValue });
            }}
            renderInput={(params) => (
              <TextField {...params} size="small" className="patient-dropdown" fullWidth />
            )}></DatePicker>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={1}>
        <Button variant="contained" className="btn-usf" onClick={callAPI}>
          Run Report
        </Button>
      </Grid>
    </Grid>
  );

  // const currencyFormatter = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  //   minimumFractionDigits: 0,
  //   maximumFractionDigits: 0
  // });

  const columns = [
    {
      field: "patientNameAndKey",
      headerName: "PATIENT",
      width: 100,
      editable: false,
      availableAggregationFunctions: ["heading"],
      renderCell: (params) => {
        if (params.aggregation) {
          return `Total: `;
        }
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "10px"
            }}>
            <Typography> {params?.row?.patientName}</Typography>
            <Typography> {params?.row?.personNumber}</Typography>
          </div>
        );
      }
    },

    {
      field: "medCaseId",
      headerName: "MEDCASE ID",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <Typography style={{ textAlign: "center", padding: "10px" }}>
          {row?.medCaseId ? row.medCaseId : ""}
        </Typography>
      )
    },
    {
      field: "medcaseTransactionId",
      headerName: "TRANSACTION ID",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <Typography style={{ textAlign: "center", padding: "10px" }}>
          {row?.medcaseTransactionId ? row.medcaseTransactionId : ""}
        </Typography>
      )
    },
    {
      field: "transactionDate",
      headerName: "TRANSACTION DATE",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <Typography style={{ textAlign: "center", padding: "10px" }}>
          {row && row.transactionDate && moment(row.transactionDate).format("MM/DD/YYYY")}
        </Typography>
      )
    },
    {
      field: "recordedDate",
      headerName: "RECORDED ON DATE",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <Typography style={{ textAlign: "center", padding: "10px" }}>
          {row && row.recordedDate && moment(row.recordedDate).format("MM/DD/YYYY")}
        </Typography>
      )
    },
    {
      field: "practice",
      headerName: "PRACTICE",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <Typography style={{ textAlign: "center", padding: "10px" }}>
          {row?.practice ? row.practice : ""}
        </Typography>
      )
    },
    {
      field: "receipts",
      headerName: "RECEIPTS",
      width: 100,
      availableAggregationFunctions: ["totalSum"],
      valueParser: (e) => Number(e),
      valueFormatter: (e) => formateCommasepratedNumber(e),
      type: "number"
      // flex: 1
    },
    {
      field: "programRefunds",
      headerName: "PROGRAM REFUNDS",
      width: 100,
      availableAggregationFunctions: ["totalSum"],
      valueParser: (e) => Number(e),
      valueFormatter: (e) => formateCommasepratedNumber(e),
      type: "number"
      // flex: 1
    },
    {
      field: "pregnancyRefund",
      headerName: "PREGNANCY REFUNDS",
      width: 100,
      availableAggregationFunctions: ["totalSum"],
      valueParser: (e) => Number(e),
      valueFormatter: (e) => formateCommasepratedNumber(e),
      type: "number"
      // flex: 1
    },
    {
      field: "miscellaneousRefund",
      headerName: "MISC REFUNDS",
      width: 100,
      availableAggregationFunctions: ["totalSum"],
      valueParser: (e) => Number(e),
      valueFormatter: (e) => formateCommasepratedNumber(e),
      type: "number"
      // flex: 1
    },
    {
      field: "paymentType",
      headerName: "PAYMENT TYPE",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <Typography style={{ textAlign: "center", padding: "10px" }}>
          {row?.paymentType ? row.paymentType : ""}
        </Typography>
      )
    },
    {
      field: "approvalCode",
      headerName: "APPROVAL CODE",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <Typography style={{ textAlign: "center", padding: "10px" }}>
          {row?.approvalCode ? row.approvalCode : ""}
        </Typography>
      )
    },
    {
      field: "progShortName",
      headerName: "PROGRAM SHORT NAME",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <Typography style={{ textAlign: "center", padding: "10px" }}>
          {row?.progShortName ? row.progShortName : ""}
        </Typography>
      )
    },
    {
      field: "treatmentPlan",
      headerName: "TREATMENT PLAN",
      width: 100,
      editable: false,
      renderCell: ({ row }) => (
        <Typography style={{ textAlign: "center", padding: "10px" }}>
          {row?.treatmentPlan ? row.treatmentPlan : ""}
        </Typography>
      )
    }
  ];

  const totalSumAggregation = {
    apply: (params) => {
      if (params.values.length === 0) {
        return null;
      }
      let sum = 0;

      params.values.forEach((value) => {
        //let cur = value.split(" ");
        //console.log("cur: ", cur);
        sum += Number(value);
      });

      let updatedValu = formateCommasepratedNumber(sum);

      return updatedValu;
    },
    label: "",
    valueFormatter: (value) => `${value}`,
    columnTypes: ["number"]
  };

  const aggregationHeading = {
    apply: () => {},
    label: "",
    valueFormatter: () => `Total: `,
    columnTypes: ["string"]
  };

  return (
    <>
      <Stack direction="row" className="pad-5 m-t-8"></Stack>
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={csvData}
            rowCount={csvData.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => {
              console.log("newPageSize: ", newPageSize);
              // if (newPageSize.pageSize > 100) {
              //   return;
              // }
              setPageSize(newPageSize);
            }}
            pagination
            localeText={"Export button"}
            components={<CustomToolbar />}
            isExport={true}
            aggregationFunctions={{
              ...GRID_AGGREGATION_FUNCTIONS,
              totalSum: totalSumAggregation,
              heading: aggregationHeading
            }}
            initialState={{
              aggregation: {
                model: {
                  patientNameAndKey: "heading",
                  receipts: "totalSum",
                  programRefunds: "totalSum",
                  pregnancyRefund: "totalSum",
                  miscellaneousRefund: "totalSum"
                }
              }
            }}
            headerText={`Payment Register Transaction Start Date: ${values.from} Transaction End Date: ${values.to} Recorded Start Date: ${values.recordedOnStart} Recorded End Date: ${values.recordedOnEnd}`}
            disableRowSelectionOnClick={true}
            customAggModel={{
              patientNameAndKey: "heading",
              receipts: "totalSum",
              programRefunds: "totalSum",
              pregnancyRefund: "totalSum",
              miscellaneousRefund: "totalSum"
            }}
          />
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = ({ paymentRegReducer }) => {
  return {
    loading: paymentRegReducer.loading,
    paymentRegisterReportList: paymentRegReducer.paymentRegisterReportList,
    errorMsg: paymentRegReducer.error,
    successMsg: paymentRegReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPaymentRegisterReport: (payload) => {
      dispatch(actionType.getPaymentRegisterReport(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRegister);
