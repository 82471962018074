import { Checkbox, InputLabel } from "@mui/material";
import Stack from "@mui/material/Stack";

const CheckBoxField = ({ label, value, ...props }) => {
  return (
    <>
      <Stack flex={1} direction="row" className="modal-stack">
        <InputLabel className="flex-2">{label}</InputLabel>
        <Checkbox
          name="isrange"
          className="flex-6"
          color="primary"
          value={value}
          checked={value}
          {...props}
          sx={{ justifyContent: "flex-start", paddingLeft: 0 }}
        />
        {/* <FormControlLabel
          value="start"
          control={
            <Checkbox
              color="primary"
              sx={{ justifyContent: "flex-start", paddingLeft: 0 }}
              value={value}
              checked={value}
              {...props}
            />
          }
          label={label}
          labelPlacement="start"
        /> */}
      </Stack>
    </>
  );
};

export default CheckBoxField;
