import * as actionType from "../actions/ActionTypes";

const initialState = {
  contactList: [],
  addContact: null,
  editContact: null,
  loading: false,
  error: null,
  success: false
};

const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CONTACT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CONTACT_LIST_SUCCESS: {
      return {
        ...state,
        contactList: action.payload,
        loading: false
      };
    }
    case actionType.CONTACT_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.ADD_CONTACT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_CONTACT_LIST_SUCCESS: {
      return {
        ...state,
        addContact: action.payload,
        loading: false,
        success: action.success,
        error: action.message
      };
    }
    case actionType.ADD_CONTACT_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false,
        success: false
      };
    }
    case actionType.EDIT_CONTACT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_CONTACT_LIST_SUCCESS: {
      return {
        ...state,
        editContact: action.payload,
        loading: false,
        success: action.success
      };
    }
    case actionType.EDIT_CONTACT_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false,
        success: false
      };
    }

    case actionType.RESET_CONTACT_LIST: {
      return {
        ...state,
        addContact: null,
        editContact: null,
        loading: false
      };
    }

    case actionType.RESET_CONTACT_ROWS: {
      return {
        ...state,
        contactList: [],
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default contactReducer;
