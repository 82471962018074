import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Button, Typography, Stack } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import DeleteModal from "../../shared/components/DeleteModal";
import { relativeValueConfig } from "../../shared/constants/renderConfigs";
import moment from "moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const RelativeValue = (props) => {
  const cycleNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let relativeValueFormData = {
    program: "",
    type: "",
    relativeValueNumber: "",
    donor: false,
    effectiveDate: moment(),
    endDate: moment(),
    cycleNumber: ""
  };

  let initialData = {};
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [relativeId, setRelativeId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [values, setValues] = useState("");
  const columns = [
    {
      field: "program",
      headerName: "PROGRAM",
      width: 100,
      flex: 1
    },
    {
      field: "type",
      headerName: "TYPE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "donor",
      headerName: "DONOR",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => <span>{row.donor ? "Y" : "N"}</span>
    },
    {
      field: "cycleNumber",
      headerName: "CYCLE NO",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "relativeValueNumber",
      headerName: "RELATIVE VALUE %",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "endDate",
      headerName: "END DATE",
      width: 50,
      editable: false,
      flex: 1
    },

    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.5,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            onClick={() => {
              handleEditRelativeValue(row);
            }}>
            <EditIcon color="primary"></EditIcon>
          </IconButton>
          <IconButton
            onClick={() => {
              handleDeleteProgram(row);
            }}>
            <DeleteIcon color="primary"></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];

  const {
    relativeValueList = [],
    actions,
    addRelativeValue,
    editRelativeValue,
    loading,
    errorMsg,
    successMsg,
    metaData = [],
    relativeTypeList = []
  } = props;
  const rows = relativeValueList.map((item, index) => {
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    actions.getRelativeValue();
  }, [addRelativeValue, editRelativeValue]);

  useEffect(() => {
    actions.getRelativeValue();
    actions.getMetadata();
    actions.getRelativeTypes();
  }, []);
  useEffect(() => {
    relativeValueList.map((item) => {
      item.updatedDate = moment(item.updatedDate).format("L");
      item.effectiveDate = (item.effectiveDate && moment(item.effectiveDate).format("L")) || "";
      item.endDate = (item.endDate && moment(item.endDate).format("L")) || "";
      return item;
    });
  }, [relativeValueList]);
  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (relativeValueList.length == 0 || addRelativeValue || editRelativeValue) {
      initFetch();
    }
    return () => {
      actions.resetRelativeValueList();
    };
  }, [addRelativeValue, editRelativeValue, errorMsg, successMsg]);

  const handleInputChange = (e) => {
    if (isNaN(e.target.value) && e.target.name == "relativeValueNumber") {
      return;
    }
    let { name, value } = e.target;
    if (name == "donor") {
      value = e.target.checked;
    }
    setValues({
      ...values,
      [name]: value
    });
  };
  const addNewRelativeValue = () => {
    setValues(relativeValueFormData);
    setIsAdd(true);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const saveRelativeValue = (event) => {
    event.preventDefault();
    console.log("values", values);
    values.effectiveDate = moment(values.effectiveDate).format("YYYY-MM-DD");
    values.endDate = moment(values.endDate).format("YYYY-MM-DD");
    if (isAdd) {
      actions.addNewRelativeValue(values);
    } else {
      actions.editRelativeValue(values);
    }
    closeModal();
  };

  const handleEditRelativeValue = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    relativeValueFormData = {
      relativeId: row.relativeId,
      program: row.program,
      type: row.type,
      relativeValue: row.relativeValue,
      relativeValueNumber: row.relativeValueNumber,
      donor: row.donor,
      effectiveDate: row.effectiveDate,
      endDate: row.endDate,
      cycleNumber: row.cycleNumber
    };
    setValues(relativeValueFormData);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    initialData = {
      relativeId: relativeId
    };
    actions.deleteRelativeValue(initialData);
    closeDeleteModal();
  };

  const handleDeleteProgram = (row) => {
    setDeleteModal(true);
    setRelativeId(row.relativeId);
  };

  const setEffectiveDate = (e) => {
    setValues({
      ...values,
      ["effectiveDate"]: e
    });
  };
  const setEndDate = (e) => {
    setValues({
      ...values,
      ["endDate"]: e
    });
  };

  const relativeValue = relativeValueConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    setEffectiveDate: setEffectiveDate,
    setEndDate: setEndDate,
    isAdd,
    options: metaData.programName && metaData.programName.length > 0 ? metaData.programName : [],
    disabled:
      values.program &&
      values.type &&
      values.relativeValueNumber &&
      values.effectiveDate &&
      values.endDate &&
      !(values.type === "PGT-A Analysis" && !values.cycleNumber)
        ? false
        : true,
    relativeTypeList:
      relativeTypeList && relativeTypeList.length
        ? relativeTypeList.map((v) => v.treatmentTypeName)
        : [],
    cycleNumbers: cycleNumbers
  });
  return (
    <>
      <Box
        sx={{ ml: 1 }}
        className={`${loading ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>Relative Value List</Typography>
          <Button variant="contained" className="btn-usf" onClick={addNewRelativeValue}>
            New Relative Value
          </Button>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal
        open={isOpen}
        config={relativeValue}
        isAdd={isAdd}
        handleSubmit={saveRelativeValue}
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <DeleteModal
        open={deleteModal}
        id={relativeId}
        close={closeDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};
const mapStateToProps = ({ relativeValueReducer, programReducer }) => {
  return {
    loading: relativeValueReducer.loading,
    relativeValueList: relativeValueReducer.relativeValueList,
    addRelativeValue: relativeValueReducer.addRelativeValue,
    editRelativeValue: relativeValueReducer.editRelativeValue,
    deleteRelativeValue: relativeValueReducer.deleteRelativeValue,
    errorMsg: relativeValueReducer.error,
    successMsg: relativeValueReducer.success,
    programList: programReducer.programList,
    metaData: relativeValueReducer.metaData,
    relativeTypeList: relativeValueReducer.relativeTypeList
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    getRelativeValue: () => {
      dispatch(actionType.getRelativeValue());
    },
    addNewRelativeValue: (payload) => {
      dispatch(actionType.addNewRelativeValue(payload));
    },
    editRelativeValue: (payload) => {
      dispatch(actionType.editRelativeValue(payload));
    },
    resetRelativeValueList: () => {
      dispatch(actionType.resetRelativeValueList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getPrograms: (payload) => {
      dispatch(actionType.getPrograms(payload));
    },
    deleteRelativeValue: (payload) => {
      dispatch(actionType.deleteRelativeValue(payload));
    },
    getMetadata: () => {
      dispatch(actionType.getMetadata());
    },
    getRelativeTypes: () => {
      dispatch(actionType.getRelativeTypes());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(RelativeValue);
