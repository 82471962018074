import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import * as actionType from "../actions";
import { emailConfig } from "../../shared/constants/renderConfigs";
import { Button } from "@mui/material";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { isEmailValid } from "../../shared/constants/constants";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const Email = (props) => {
  const columns = [
    {
      field: "emailId",
      headerName: "ID",
      width: 50,
      flex: 0.5
    },
    {
      field: "emailName",
      headerName: "EMAIL NAME",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "emailAddress",
      headerName: "EMAIL ADDRESS",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },

    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleEditEmail(row);
          }}>
          <EditIcon color="primary"></EditIcon>
        </IconButton>
      )
    }
  ];

  const { practiceId } = useParams();
  let emailFormData = { practiceId: practiceId, emailName: "", emailAddress: "", status: "Active" };
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState(emailFormData);
  const [isValid, setIsValid] = useState(true);
  const [validationMsg, setValidationMsg] = useState("");
  const { emailList = [], addEmail, editEmail, errorMsg, successMsg, loading, actions } = props;

  const rows = emailList.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    item.id = index;
    return item;
  });

  const initFetch = useCallback(
    () => {
      actions.getEmails(practiceId);
    },
    [
      /* addEmail, editEmail */
    ]
  );

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (emailList.length === 0 || addEmail || editEmail) {
      initFetch();
    }

    return () => {
      actions.resetEmails();
    };
  }, [addEmail, editEmail, successMsg, errorMsg]);

  const addNewMail = () => {
    setValues(emailFormData);
    setIsAdd(true);
    setIsOpen(true);
  };

  const saveEmail = (event) => {
    event.preventDefault();
    if (isAdd) {
      actions.addEmail(values);
    } else {
      actions.editEmail(values);
    }

    closeModal();
  };

  const handleEditEmail = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    emailFormData = {
      emailId: row.emailId,
      practiceId: practiceId,
      emailName: row.emailName,
      emailAddress: row.emailAddress,
      status: row.status
    };
    setValues(emailFormData);
  };

  const closeModal = () => {
    setValues(emailFormData);
    setIsOpen(false);
    setIsValid(true);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name == "emailAddress" && value) {
      let res = isEmailValid(value);
      setIsValid(res.status);
      setValidationMsg(res.msg);
    }
    setValues({
      ...values,
      [name]: value
    });
  };

  const email = emailConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.emailName && values.emailAddress && isValid ? false : true
  });

  return (
    <>
      <Box>
        <Button variant="contained" onClick={addNewMail} className="btn-primary tab-panel-btn">
          New Email
        </Button>
      </Box>
      <Box
        className="grid-style"
        sx={{
          "& .green": {
            color: "green"
          },
          "& .red": {
            color: "red"
          },
          height: "660px"
        }}>
        <DataGridPremiumComp
          columns={columns}
          rows={rows}
          rowCount={rows.length}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          getCellClassName={(params) => {
            if (params.field === "status") {
              return params.value === "Active" ? "green" : "red";
            }
          }}
        />
      </Box>
      {/* Add/edit email modal*/}
      <CommonModal
        open={isOpen}
        config={email}
        isAdd={isAdd}
        handleSubmit={saveEmail}
        isValid={isValid}
        validationMsg={validationMsg}
      />
      {/* Duplicate entry modal*/}
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};

const mapStateToProps = ({ emailReducer }) => {
  return {
    emailList: emailReducer.emailList,
    addEmail: emailReducer.addEmail,
    addEmailError: emailReducer.error,
    editEmail: emailReducer.editEmail,
    loading: emailReducer.loading,
    errorMsg: emailReducer.error,
    successMsg: emailReducer.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEmails: (practiceId) => {
      dispatch(actionType.getEmails(practiceId));
    },
    addEmail: (payload) => {
      dispatch(actionType.addEmail(payload));
    },
    editEmail: (payload) => {
      dispatch(actionType.editEmail(payload));
    },
    resetEmails: () => {
      dispatch(actionType.resetEmails());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Email);
