import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Stack,
  // MenuItem,
  // Select,
  Grid,
  IconButton,
  Button,
  TextField,
  Autocomplete
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import * as actionType from "../actions";
import { Link, useLocation } from "react-router-dom";
import GlobalDataModalPopup from "./modals/GlobalDataModalPopup";
import ExistingGlobalDataModal from "./modals/ExistingGlobalDataModal";
import "./Admin.scss";
import MessageModal from "../../shared/components/MessageModal";
import WarnningMessageModal from "../../shared/components/WarnningMessageModal";
import moment from "moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const GlobalDataModel = (props) => {
  // const practices = useSelector((state) => state.practices.practices);
  const pathnames = useLocation().pathname.split("/");
  if (pathnames[pathnames.length - 1] == "gdm") {
    sessionStorage.removeItem("practiceId");
    sessionStorage.removeItem("gdmId");
    sessionStorage.removeItem("practiceName");
  }
  const columns = [
    {
      field: "gdmId",
      headerName: "ID",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "gdmVersion",
      headerName: "GLOBAL DATA MODEL VERSION",
      width: 50,
      editable: false,
      renderCell: ({ row }) => (
        <Link
          to={`serviceItems`}
          onClick={() => {
            if (gdmId !== row.gdmId) {
              actions.resetServiceItemsList();
              actions.resetProgramList();
              actions.resetStages();
              actions.resetTreatmentPlan();
              actions.resetTreatmentPlanList();
            }
            sessionStorage.setItem("practiceId", practiceId);
            sessionStorage.setItem("gdmId", row.gdmId);
            sessionStorage.setItem("practiceName", practiceName);
            localStorage.setItem("status", row.status);
            localStorage.setItem("practiceId", practiceId);
            localStorage.setItem("gdmId", row.gdmId);
            localStorage.setItem("practiceName", practiceName);
            actions.updateGdmRow(row);
          }}>
          {row.gdmVersion}
        </Link>
      ),
      flex: 1
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row?.effectiveDate).format("L");
      }
    },
    {
      field: "endDate",
      headerName: "END DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (row?.endDate && moment(row?.endDate).format("L")) || "";
      }
    },
    {
      field: "assistPricePercentage",
      headerName: "ASSIST PRICE PERCENTAGE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 100,
      editable: false,
      flex: 0.5,
      renderCell: ({ row }) => (
        <span
          className="txt-capitalize"
          style={{
            color:
              row.status == "Active"
                ? "#08AA5C"
                : row.status == "In-progress"
                ? "#1BA3AD"
                : "#A6315C"
          }}>
          {row.status}
        </span>
      )
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      flex: 0.8,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleEditGlobalData(row);
          }}
          // disabled={row.status == "Active" || row.status == "Inactive"}
        >
          <EditIcon color="primary"></EditIcon>
        </IconButton>
      )
    }
  ];

  const [practiceId, setPracticeId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isChildOpen, setIsChildModal] = useState(false);
  const [isOpenExisting, setExistingOpen] = useState(false);
  const [existingGdm, setExistingGdm] = useState([]);
  const [isExisting, setIsExistig] = useState(false);
  const [existingGdmCreate, setCreateExistingGdm] = useState(false);
  const [isCreateExisting, setCreatenewFromExisting] = useState(true);
  const [gdmId, setGdmId] = useState("");
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const {
    globalData = [],
    addGlobalData = [],
    editGlobalData = [],
    loading,
    loadingPractice,
    actions,
    success,
    error,
    pId = [],
    practices = []
  } = props;

  const [rowData, setRowData] = useState({});
  // const dateForamte = (nDate) => {
  //   const today = new Date(nDate);
  //   const dd = String(today.getDate() + 1).padStart(2, "0");
  //   const mm = String(today.getMonth() + 1).padStart(2, "0");
  //   const yyyy = today.getFullYear();
  //   const currDate = yyyy + "-" + mm + "-" + dd;
  //   return currDate;
  // };

  const rows = globalData.map((item, index) => {
    //item.effectiveDate = dateForamte(item?.effectiveDate);
    //item.endDate = item?.endDate;
    item.id = index;
    return item;
  });
  console.log("row", rows);
  const initFetch = useCallback(() => {
    if (practiceId) {
      actions.getGlobalData(practiceId);
    }
  }, [practiceId, addGlobalData, editGlobalData]);

  useEffect(() => {
    if (globalData.length === 0) {
      setCreatenewFromExisting(true);
    } else setCreatenewFromExisting(false);
  });
  useEffect(() => {
    if (success || error) {
      setIsChildModal(true);
    }
    if (globalData.length == 0 || addGlobalData || editGlobalData) {
      initFetch();
    }
    return () => {
      actions.resetGlobalData();
    };
  }, [practiceId, addGlobalData, editGlobalData, success, error]);

  useEffect(() => {
    if (!practices.length) {
      actions.getPractices();
    }
  }, []);
  useEffect(() => {
    if (pId) {
      setPracticeId(pId);
      let selectedPractice = practices.filter((pr) => pr.practiceId == pId);
      setPracticeName(selectedPractice[0]?.name);
    } else if (practices.length > 0) {
      setPracticeId(practices[0]?.practiceId);
      setPracticeName(practices[0]?.name);
    }
  }, [loadingPractice]);

  const handlePracticeChange = (value) => {
    if (value) {
      actions.resetGlobalList();
      setPracticeId(value.value);
      let selectedPractice = practices.filter((pr) => pr.practiceId == value.value);
      setPracticeName(selectedPractice[0].name);
      actions.getGlobalData(value.value);
    }
  };

  const handleEditGlobalData = (row) => {
    const gdmEditData = {
      effectiveDate: row.effectiveDate,
      endDate: row.endDate,
      assistPricePercentage: row.assistPricePercentage
    };
    setGdmId(row.gdmId);
    setRowData(gdmEditData);
    setIsEdit(true);
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsEdit(false);
    setIsOpen(false);
    setIsExistig(false);
    setExistingOpen(false);
  };
  const closeChildModal = () => {
    setIsChildModal(false);
    setCreateExistingGdm(false);
    actions.resetMessage();
  };
  const closeWarningModal = () => {
    setIsWarningOpen(false);
    setWarningMessage("");
  };
  const handleCreateGdm = (createData) => {
    let payload = { practiceId, gdmData: createData };
    if (!isEdit || existingGdmCreate) {
      payload.cloneFrom = rowData?.gdmId || "";
      actions.createNewGdm(payload);
      setCreateExistingGdm(false);
    } else {
      payload.gdmId = gdmId;
      actions.updateNewGdm(payload);
    }
    setIsOpen(false);
  };
  const handleCreateEGdm = (createData) => {
    setRowData(createData);
    setCreateExistingGdm(true);
    setExistingOpen(false);
    setIsOpen(true);
  };
  const handleNewGlobalData = () => {
    if (globalData.filter((ip) => ip.status === "In-progress").length > 0) {
      setIsWarningOpen(true);
      setWarningMessage(
        "New GDM cannot be created as already one GDM in “In-progress” status exists"
      );
    } else {
      setRowData({});
      setIsOpen(true);
    }
  };
  const handleCreateExistingGdm = () => {
    if (globalData.filter((ip) => ip.status === "In-progress").length > 0) {
      setIsWarningOpen(true);
      setWarningMessage(
        "New GDM cannot be created as already one GDM in “In-progress” status exists"
      );
    } else {
      let data = rows ? rows : [];
      setIsExistig(true);
      setExistingGdm(data);
      setExistingOpen(true);
    }
  };

  return (
    <>
      <Box
        className={`${
          loading || loadingPractice
            ? "stage-details-section m-10 disable-section"
            : "stage-details-section m-10"
        }`}>
        <Stack direction="row" className="modal-stack pad-10">
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={1}>
              <Typography>Practice</Typography>
            </Grid>
            <Grid item xs={4}>
              {/* <Select
                fullWidth
                name="practiceId"
                value={practiceId}
                onChange={handlePracticeChange}
                className="stage-practice-dropdown">
                {practices.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.name} -{item.practiceId}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Autocomplete
                disablePortal
                fullWidth
                name="practiceId"
                value={
                  practices.find((item) => item.practiceId === practiceId)
                    ? `${
                        practices.find((item) => item.practiceId === practiceId).name
                      } - ${practiceId}`
                    : ""
                }
                options={practices.map((item, index) => {
                  return {
                    id: index,
                    label: `${item.name} - ${item.practiceId}`,
                    name: "practiceId",
                    value: item.practiceId
                  };
                })}
                onChange={(e, value) => {
                  handlePracticeChange(value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." />}
                className="stage-practice-dropdown"
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Box
        sx={{ ml: 1 }}
        className={`${
          loading || loadingPractice ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"
        }`}>
        <Stack direction="row" className="grid-header">
          <Typography> Global Data Model List</Typography>
          <Stack spacing={2} direction="row">
            <Button variant="contained" onClick={handleNewGlobalData}>
              New Global Data Model
            </Button>
            <Button
              variant="contained"
              disabled={isCreateExisting}
              onClick={handleCreateExistingGdm}>
              Create New From Existing
            </Button>
          </Stack>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading || loadingPractice}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <GlobalDataModalPopup
        open={isOpen}
        edit={isEdit}
        isExixtingGdm={isExisting}
        data={rowData}
        handleCreateGdm={handleCreateGdm}
        handleClose={handleClose}
      />
      <ExistingGlobalDataModal
        open={isOpenExisting}
        edit={isEdit}
        gdmData={existingGdm}
        handleCreateEGdm={handleCreateEGdm}
        handleClose={handleClose}
      />
      {/* Duplicate entry modal*/}
      <MessageModal
        open={isChildOpen}
        error={error}
        message={success}
        closeModal={closeChildModal}
      />
      {/* Warning Message Modal*/}
      <WarnningMessageModal
        open={isWarningOpen}
        message={warningMessage}
        closeModal={closeWarningModal}
      />
    </>
  );
};

const mapStateToProps = ({ practices, globalDataModelReducer }) => {
  return {
    loading: globalDataModelReducer.loading,
    globalData: globalDataModelReducer.globalData,
    addGlobalData: globalDataModelReducer.addGlobalData,
    editGlobalData: globalDataModelReducer.editGlobalData,
    pId: globalDataModelReducer.pId,
    success: globalDataModelReducer.success,
    error: globalDataModelReducer.error,
    loadingPractice: practices.loading,
    practices: practices.practices
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    getGlobalData: (practiceId) => {
      dispatch(actionType.getGlobalData(practiceId));
    },
    resetGlobalData: () => {
      dispatch(actionType.resetGlobalData());
    },
    resetGlobalList: () => {
      dispatch(actionType.resetGlobalList());
    },
    createNewGdm: (payload) => {
      dispatch(actionType.createNewGdm(payload));
    },
    updateNewGdm: (payload) => {
      dispatch(actionType.updateNewGdm(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    updateGdmRow: (payload) => {
      dispatch(actionType.updateGdmRow(payload));
    },
    resetServiceItemsList: () => {
      dispatch(actionType.resetServiceItemsList());
    },
    resetProgramList: () => {
      dispatch(actionType.resetProgramList());
    },
    resetStages: () => {
      dispatch(actionType.resetStages());
    },
    resetTreatmentPlanList: () => {
      dispatch(actionType.resetTreatmentPlanList());
    },
    resetTreatmentPlan: () => {
      dispatch(actionType.resetTreatmentPlan());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(GlobalDataModel);
