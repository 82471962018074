import React, { useEffect, useState } from "react";
import SearchHeader from "./SearchHeader";
import { Box, Stack, Typography, Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { caseListColumns } from "../constants";
import * as actionType from "../actions";
import { connect } from "react-redux";
import LinkMedcaseModal from "./modals/LinkMedcaseModal";
import MessageModal from "../../shared/components/MessageModal";
import { useNavigate } from "react-router-dom";
import LoadingModal from "./modals/loadingModal";
import WarningPopUp from "./modals/WarningPopUp";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import PopupModal from "../../shared/components/PopupModal";

const CaseList = (props) => {
  const { caseList = [], loading, successMsg, loadingLink, actions, errorLink, success } = props;
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [csvData, setCsvData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [medcaseData, setMedcaseData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [isOpenComment, setIsOpenComment] = useState(false);
  const [fullComment, setFullComment] = useState("");

  const medcaseLink = sessionStorage.getItem("Medcase_Link");
  const navigate = useNavigate();

  useEffect(() => {
    if (caseList.length === 0 && success === true) {
      setCsvData([]);
      actions.resetMessage();
    }
    setMedcaseData({});
    setSelectedRow(null);
  }, [caseList]);

  useEffect(() => {
    if (successMsg) {
      setIsMessageModal(true);
    }
    if (errorLink) {
      setIsOpenError(true);
    }
  }, [successMsg, errorLink]);

  const handleRadio = (e, row) => {
    setSelectedRow(row.medcaseId);
    setMedcaseData(row);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  const linkMedcase = () => {
    setIsOpen(true);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    navigate(`/trak/caselookup/caselist/${sessionStorage.getItem("link_medcase_id")}/status`);
    sessionStorage.setItem("Medcase_Link", "");
    actions.resetLinkMedcase();
  };

  const handleCancel = () => {
    setSelectedRow(null);
    setMedcaseData({});
  };

  const handleCloseError = () => {
    setIsOpenError(false);
  };

  useEffect(() => {
    if (caseList.length > 0) {
      const csvDataMode = prepareCsvData(caseList);
      console.log("csvDataMode: ", csvDataMode);
      setCsvData(csvDataMode);
    }
  }, [caseList]);

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      statusDate: row.statusDate ? new Date(row.statusDate) : ""
    }));
  };

  function CustomToolbar() {
    return (
      <>
        <Stack direction="row" className="grid-header">
          <Typography>Case List</Typography>
        </Stack>
        <Stack direction="row" sx={{ my: 1 }}>
          <Button
            variant="contained"
            className="btn-usf "
            //sx={{ marginRight: "10px" }}
            disabled={medcaseData.medcaseId != null ? false : true}
            style={{
              marginRight: "10px",
              display: medcaseLink == "Link New Medcase" ? "block" : "none"
            }}
            onClick={linkMedcase}>
            Link Medcase
          </Button>
          <Button
            variant="contained"
            className="btn-usf "
            // sx={{ marginRight: "10px" }}
            disabled={medcaseData.medcaseId != null ? false : true}
            style={{
              marginRight: "10px",
              display: medcaseLink == "Link New Medcase" ? "block" : "none"
            }}
            onClick={handleCancel}>
            Cancel
          </Button>
        </Stack>
      </>
    );
  }
  return (
    <div>
      <SearchHeader />
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={csvData}
            rowCount={csvData.length}
            columns={caseListColumns(handleRadio, selectedRow, setIsOpenComment, setFullComment)}
            columnVisibilityModel={{ select: medcaseLink == "Link New Medcase" ? true : false }}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            getRowId={() => uuidv4()}
            localeText={"Export Button"}
            components={<CustomToolbar />}
            isExport={true}
          />
        </Box>
      </Box>
      <LinkMedcaseModal open={isOpen} close={handleClose} medcaseId={medcaseData.medcaseId} />
      <MessageModal open={isMessageModal} message={successMsg} closeModal={closeMessageModal} />
      <LoadingModal open={loadingLink} />
      <WarningPopUp open={isOpenError} closeModal={handleCloseError} error={errorLink} />

      {/* Show popup on checkbox click */}
      <PopupModal
        open={isOpenComment}
        title="Full Comment"
        content={<Typography>{fullComment}</Typography>}
        handleClose={() => {
          setIsOpenComment(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ caseListReducer, medcaseReducer }) => {
  return {
    caseList: caseListReducer.caseList,
    success: caseListReducer.success,
    loading: caseListReducer.loading,
    errorMsg: caseListReducer.error,
    successMsg: medcaseReducer.successLink,
    loadingLink: medcaseReducer.loadingLink,
    errorLink: medcaseReducer.errorLink
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getCaseList: (payload) => {
      dispatch(actionType.getCaseList(payload));
    },
    resetLinkMedcase: () => {
      dispatch(actionType.resetLinkMedcase());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseList);
