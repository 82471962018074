import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchStages(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/stages`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.STAGE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.STAGE_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getStageSaga() {
  yield takeEvery(actionTypes.STAGE_LIST_REQUESTED, fetchStages);
}

function* createNewStage(action) {
  const { payload } = action;
  let params = {
    stageCode: payload.stageCode,
    stageName: payload.stageName,
    stageGroup: payload.stageGroup,
    stageCategory: payload.stageCategory,
    isrange: payload.isrange,
    stagePassthrough: payload.stagePassthrough,
    sortStage: payload.sortStage
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/stages`,
      "",
      params
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_NEW_STAGE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_STAGE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewStageSaga() {
  yield takeEvery(actionTypes.ADD_NEW_STAGE_REQUESTED, createNewStage);
}

function* editStage(action) {
  const { payload } = action;
  let params = {
    stageCode: payload.stageCode,
    stageName: payload.stageName,
    stageGroup: payload.stageGroup,
    stageCategory: payload.stageCategory,
    isrange: payload.isrange,
    globalPrice: payload.globalPrice,
    sortStage: payload.sortStage
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/stages/${payload.stageId}`,
      "",
      params
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_STAGE_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_STAGE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editStageSaga() {
  yield takeEvery(actionTypes.EDIT_STAGE_REQUESTED, editStage);
}

function* fetchStagesNotMapped(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000",
      type: "not_mapped"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/stages`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.STAGE_LIST_NOT_MAPPED_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.STAGE_LIST_NOT_MAPPED_FAIL, errorMsg: error.message });
    }
  }
}

function* getStageNotMappedSaga() {
  yield takeEvery(actionTypes.STAGE_LIST_REQUESTED_NOT_MAPPED, fetchStagesNotMapped);
}

export default function* stageSaga() {
  yield all([getStageSaga(), addNewStageSaga(), editStageSaga(), getStageNotMappedSaga()]);
}
