import { Box, Divider, FormControl, Grid, MenuItem, Select, Stack } from "@mui/material";
import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import Breadcrumb from "../../shared/components/Breadcrumb";
import GlobalDataModelCard from "./GlobalDataModelCard";
import GlobalDataModelConfiguration from "./GlobalDataModelConfigurations";
import { useSelector } from "react-redux";

const GlobalDataModelContext = () => {
  const { stageId, treatmentPlanId } = useParams();
  const [practiceData, setPracticeData] = useState();
  const gdmRowData = useSelector((state) => state.globalDataModelReducer.gdmRowData);
  const status = localStorage.getItem("status");
  const [config, setConfig] = useState(1);
  const handleChange = (event) => {
    setConfig(event.target.value);
  };

  const getSelectedConfig = (selected) => {
    setPracticeData(selected);
    console.log(practiceData);
  };

  return (
    <Box>
      <Grid container className="">
        <Grid item xs={12}>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}>
            <FormControl sx={{ width: 244, minWidth: 200, border: "unset" }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={config}
                IconComponent={ExpandMoreSharpIcon}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { border: "unset" },
                  color: "#00889F",
                  "& .MuiSvgIcon-root": {
                    color: "#00889F"
                  }
                }}
                onChange={handleChange}>
                <MenuItem value={0}>Felix Administration</MenuItem>
                <MenuItem value={1}>Practice Configuration</MenuItem>
              </Select>
            </FormControl>
            <Divider sx={{ height: 26, m: 0.5 }} orientation="vertical" />
            {/* breadcrumb start here */}
            <Breadcrumb />
            {/* breadcrumb end here */}
          </Stack>
        </Grid>

        {!stageId && !treatmentPlanId && (
          <Grid item xs={12}>
            <GlobalDataModelCard
              cardData={gdmRowData}
              disabled={status !== "In-progress" ? true : false}
            />
          </Grid>
        )}
        <Grid container item xs={12}>
          {!stageId && !treatmentPlanId && (
            <Grid item xs={1.6} sx={{ background: "#818294", minHeight: "90vh" }}>
              <Grid container>
                <GlobalDataModelConfiguration
                  locationState={location.state}
                  getSelectedConfig={getSelectedConfig}
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={stageId || treatmentPlanId ? 12 : 10.4}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GlobalDataModelContext;
