import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchClinicalOutcomeList(action) {
  const { payload } = action;
  try {
    if (payload.medcaseId) {
      const requestBody = createRequestBody(
        "get",
        `/medcases/${payload.medcaseId}/clinicalTreatmentOutcomes`,
        "",
        ""
      );
      const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
      const response = yield call(() => apis(request));

      console.log("response.data: tof", response.data);

      yield put({
        type: actionTypes.CLINICAL_TREATMENT_OUTCOMES_SUCCESS,
        payload: response.data
      });
    }
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.CLINICAL_TREATMENT_OUTCOMES_FAILED, errorMsg: error.message });
    }
  }
}

function* getClinicalOutcomeSaga() {
  yield takeEvery(actionTypes.CLINICAL_TREATMENT_OUTCOMES_REQUESTED, fetchClinicalOutcomeList);
}

function* updateClinicalOutcomeList(action) {
  const { payload } = action;

  console.log("payload.data:", payload.data);

  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${payload.medcaseId}/clinicalTreatmentOutcomes/${payload.clinicalTreatmentId}`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_CLINICAL_TREATMENT_OUTCOMES_SUCCESS,
      successMsg: MSG.SAVED_SUCCESSFULLY,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_CLINICAL_TREATMENT_OUTCOMES_FAILED,
        errorMsg: error.message,
        payload: error.response.data
      });
    }
  }
}

function* updateClinicalOutcomeListSaga() {
  yield takeEvery(
    actionTypes.EDIT_CLINICAL_TREATMENT_OUTCOMES_REQUESTED,
    updateClinicalOutcomeList
  );
}
function* createSendAuthorization(action) {
  const payload = action.payload;
  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${payload.medcaseId}/clinicalTreatmentOutcomes/${payload.clinicalTreatmentId}/sendAuthorization`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CREATE_SEND_AUTHORIZATION_SUCCESS,
      payload: response.data,
      successMsg: "Authorization has sent successfully"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CREATE_SEND_AUTHORIZATION_FAIL,
        errorMsg: error.response.data
      });
    }
  }
}

function* createSendAuthorizationSaga() {
  yield takeEvery(actionTypes.CREATE_SEND_AUTHORIZATION_REQUESTED, createSendAuthorization);
}

export default function* trackOutcomeFormSaga() {
  yield all([
    getClinicalOutcomeSaga(),
    updateClinicalOutcomeListSaga(),
    createSendAuthorizationSaga()
  ]);
}
