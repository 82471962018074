import React, { useEffect, useState, useCallback } from "react";
import { Box, Button, Typography, Divider, Stack } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import MessageModal from "../../shared/components/MessageModal";
import WarnningMessageModal from "../../shared/components/WarnningMessageModal";
import { prsWarning } from "../../shared/constants/constants";
import PRSModal from "./modals/PRSModal";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
var prsFormData = {
  type: "",
  description: "",
  cycleCount: 0,
  maxPregnentPercent: 0,
  maxNonPregnentPercent: 0,
  cycle_info: [
    {
      cycle_no: 1,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 2,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 3,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 4,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 5,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 6,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 7,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 8,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 9,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    },
    {
      cycle_no: 10,
      p_cycle_percent: 0,
      np_cycle_percent: 0,
      disabled: true
    }
  ]
};

const PhysicianReimburesmentScale = (props) => {
  const columns = [
    {
      field: "pr_id",
      headerName: "ID",
      width: 85
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      width: 150,
      editable: false,
      renderCell: ({ row }) => <Typography className="word-break">{row.description}</Typography>
    },
    {
      field: "status",
      headerName: "OUTCOME",
      width: 100,
      editable: false,
      renderCell: () => (
        <Stack direction="column" width="100%" style={{ padding: "0px" }}>
          <Typography>Pregnant</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>Non Pregnant</Typography>
        </Stack>
      )
    },
    {
      field: "status1",
      headerName: "CYCLE 1",
      width: 60,
      editable: false,
      cellClassName: "test",
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle1_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle1_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "status2",
      headerName: "CYCLE 2",
      width: 60,
      editable: false,
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle2_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle2_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "status3",
      headerName: "CYCLE 3",
      width: 60,
      editable: false,
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle3_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle3_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "status4",
      headerName: "CYCLE 4",
      width: 60,
      editable: false,
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle4_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle4_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "status5",
      headerName: "CYCLE 5",
      width: 60,
      editable: false,
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle5_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle5_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "status6",
      headerName: "CYCLE 6",
      width: 60,
      editable: false,
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle6_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle6_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "status7",
      headerName: "CYCLE 7",
      width: 60,
      editable: false,
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle7_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle7_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "status8",
      headerName: "CYCLE 8",
      width: 60,
      editable: false,
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle8_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle8_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "status9",
      headerName: "CYCLE 9",
      width: 60,
      editable: false,
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle9_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle9_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "status10",
      headerName: "CYCLE 10",
      width: 70,
      editable: false,
      renderCell: (row) => (
        <Stack direction="column" width="100%">
          <Typography>{row.row.cycle_info?.p_cycle10_percent}</Typography>
          <Divider sx={{ borderColor: "#EFEFEF" }} />
          <Typography>{row.row.cycle_info?.np_cycle10_percent}</Typography>
        </Stack>
      )
    },
    {
      field: "effective_date",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effective_date).format("L");
      }
    }
  ];
  const [values, setValues] = useState(prsFormData);
  const [isOpen, setNewPRS] = useState(false);
  const { prsList = [], actions, newPRS, success, error, loadingPRS } = props;
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [type, setType] = useState("Fixed");
  const changeFormat = (actualResponse) => {
    let expectedFormat = [];
    let idSet = new Set();
    actualResponse.map((item) => idSet.add(item.pr_id));
    for (let element of idSet) {
      let requiredItems = actualResponse.filter((item) => {
        return item.pr_id === element;
      });
      var dataObj = {
        pr_id: requiredItems[0].pr_id,
        description: requiredItems[0].description,
        effective_date: requiredItems[0].effective_date,
        cycle_info: {}
      };
      for (let arrayElement of requiredItems) {
        let pPercentText = "p_cycle" + arrayElement.cycle_no + "_percent";
        let npPercentText = "np_cycle" + arrayElement.cycle_no + "_percent";
        if (arrayElement.p_cycle_percent == "") {
          dataObj.cycle_info[pPercentText] = "-";
        } else {
          dataObj.cycle_info[pPercentText] = arrayElement.p_cycle_percent;
        }
        if (arrayElement.np_cycle_percent == "") {
          dataObj.cycle_info[npPercentText] = "-";
        } else {
          dataObj.cycle_info[npPercentText] = arrayElement.np_cycle_percent;
        }
      }
      expectedFormat.push(dataObj);
    }
    return expectedFormat;
  };

  const updateDescription = () => {
    let description = values.description;
    setValues({ ...values, description: description });
  };

  useEffect(() => {
    updateDescription();
  }, [type, values.maxPregnentPercent, values.maxNonPregnentPercent, values.cycleCount]);

  const initFetch = useCallback(() => {
    actions.getPRSList();
  }, [newPRS]);

  useEffect(() => {
    if (success || error) {
      setIsMessageModal(true);
    }
    if (prsList.length === 0 || newPRS) {
      if (!isMessageModal) {
        initFetch();
      }
    }
    return () => {
      actions.resetPrs();
    };
  }, [newPRS, error, success]);

  const addNewPRS = () => {
    setNewPRS(true);
  };

  const closeAddNewPRS = () => {
    let data = values.cycle_info;
    for (let i = 0; i < 10; i++) {
      data[i].p_cycle_percent = 0;
      data[i].np_cycle_percent = 0;
      data[i].disabled = true;
    }
    setValues({
      ...values,
      description: "",
      cycleCount: 0,
      maxPregnentPercent: 0,
      maxNonPregnentPercent: 0,
      cycle_info: data
    });
    setType("Fixed");
    setNewPRS(false);
  };

  const savePrs = (data) => {
    setValues(data);
    event.preventDefault();
    setIsWarningOpen(true);
  };

  const closeWarningSaveModal = () => {
    setIsWarningOpen(false);
    let data = values;
    data.cycle_info = data.cycle_info.map((cycle) => {
      delete cycle.disabled;
      return cycle;
    });
    actions.addPRS({ values: values });
    closeAddNewPRS();
  };
  const closeWarningModal = () => {
    setIsWarningOpen(false);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  return (
    <>
      <Box sx={{ ml: 1 }} className={`${loadingPRS ? "m-r-8 disable-section" : "m-r-8"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>Physician Reimbursement Scale</Typography>
          <Button variant="contained" className="btn-usf" onClick={addNewPRS}>
            New Physician Reimbursement Scale
          </Button>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={changeFormat(prsList)}
            rowCount={changeFormat(prsList).length}
            columns={columns}
            getRowId={() => uuidv4()}
            loading={props.loadingPRS}
            sx={{
              "& .MuiDataGrid-cell": {
                padding: "0px"
              }
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "effective_date", sort: "desc" }]
              }
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <PRSModal savePrs={savePrs} closeAddNewPRS={closeAddNewPRS} isOpen={isOpen} />
      <MessageModal
        open={isMessageModal}
        message={success}
        error={error}
        closeModal={closeMessageModal}
      />
      <WarnningMessageModal
        open={isWarningOpen}
        message={prsWarning}
        closeModal={closeWarningModal}
        closeSaveModal={closeWarningSaveModal}
      />
    </>
  );
};
const mapStateToProps = ({ prs }) => {
  return {
    prsList: prs.prsList,
    newPRS: prs.newPRS,
    loadingPRS: prs.loading,
    success: prs.success,
    error: prs.error
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPRSList: () => {
      dispatch(actionType.getPRSList());
    },
    addPRS: (payload) => {
      dispatch(actionType.addPRS(payload));
    },
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    resetPrs: () => {
      dispatch(actionType.resetPrs());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PhysicianReimburesmentScale);
