import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Stack,
  TextareaAutosize,
  Typography
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { connect } from "react-redux";
import * as actionType from "../actions";
import CostCoverage from "./modals/CostCoverage";
import StyledMenu from "../../shared/components/styledMenu";
import EstimateCards from "./EstimateCards";
import EstimateDetails from "./modals/EstimateDetails";
import EstimateView from "./EstimateView";
import MessageModal from "../../shared/components/MessageModal";
import { useParams } from "react-router";
import { APPLY_LOAN, publishToPatientConstants } from "../../shared/constants/constants";
import FinancialEstimateDetailsPreview from "./FinancialEstimateDetailsPreview";
import FinancialEstimatePDF from "./FinancialEstimatePDF";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { getLocaleDateTime } from "../../shared/common";

const FinancialEstimateDetails = (props) => {
  const practiceId = localStorage.getItem("practiceId");
  const {
    actions,
    estimateViewList = [],
    estimateViewLoading,
    estimateCards,
    estimateCardLoading,
    addTreatment,
    publishLoading,
    publishSuccess,
    pulishError,
    noteLoading,
    // success,
    successNote,
    notes,
    // stages,
    // necessary,
    // optional,
    systemConfiguration,
    // adjustmentList,
    // fapList,
    fapLoading,
    efapLoading,
    estimatePdfData,
    isPdfLoading
  } = props;
  const [estimates, setEstimates] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [openCostCoverage, setOpenCostCoverage] = useState(false);
  const [headerText, setHeadertext] = useState("");
  const open = Boolean(anchorEl);
  const [estimateNotes, setEstimateNotes] = useState("");
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [noEstimateModal, setNoEstimateModal] = useState(false);
  const [refundMissingModal, setRefundMissingModal] = useState(false);
  const [priceChangeModal, setPriceChangeModal] = useState(false);
  const [publishToPatientModal, setPublishToPatientModal] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [isPreviewFinancialEstimateModal, previewFinancialEstimateDetailsModal] = useState(false);
  const [isNewInserted, setIsNewInserted] = useState(false);
  const { personId } = useParams();
  const serachParm = new URLSearchParams(window.location.search);
  const Urlterm = serachParm.get("PT");
  //const [activeEstimateCard, setActiveEstimateCard] = useState({});

  const initFetch = useCallback(() => {
    let payload = {
      practiceId,
      personId
    };
    actions.getFinancialEstimateViewList(payload);
    actions.getSystemConfigurationList(payload);
  }, []);

  useEffect(() => {
    setNoEstimateModal(false);
    if (Urlterm && Urlterm.toLowerCase() === "pd") {
      actions.resetEstimateViewList();
      initFetch();
    }
  }, [Urlterm]);

  useEffect(() => {
    if (estimateViewList == null && !estimateViewLoading) {
      initFetch();
    }
    if (!estimateViewLoading && estimateViewList?.length == 0) {
      setNoEstimateModal(true);
    }
  }, [estimateViewList, estimateViewLoading]);

  useEffect(() => {
    if (estimates.estimateId) {
      let payload = {
        practiceId,
        estimateId: estimates.estimateId
      };
      actions.getEstimateCard(payload);
      actions.getNotes(payload);
    }
  }, [estimates, addTreatment]);
  useEffect(() => {
    if (successNote.length > 0) {
      setMessageModal(true);
    }
    setEstimateNotes(notes);
  }, [noteLoading]);
  useEffect(() => {
    if (pulishError === publishToPatientConstants.REFUND_MISSING) {
      setRefundMissingModal(true);
    }
    if (pulishError === publishToPatientConstants.PRICE_CHANGE) {
      setPriceChangeModal(true);
    }

    if (publishSuccess == "Published Successfully") {
      setIsMessageModal(true);
    }
  }, [publishLoading]);

  const createDetailedEstimate = (e) => {
    e.preventDefault();
    setHeadertext("Create Detailed Estimate");
    setAnchorEl(null);
    setIsOpen(true);
    setIsNewInserted(false);
    actions.resetGetQstns();
    actions.resetAddTreatment();
    // actions.resetEditTreatment();
  };

  const closeNoEstimateModal = () => {
    actions.resetMessage();
    setNoEstimateModal(false);
  };

  const createPreliminaryEstimate = (e) => {
    e.preventDefault();
    setHeadertext("Create Preliminary Estimate");
    setAnchorEl(null);
    setIsOpen(true);
    setIsNewInserted(false);
    actions.resetGetQstns();
    actions.resetAddTreatmentEstimate();
    // actions.resetEditTreatment();
  };

  const addTreatmentPlan = (e) => {
    e.preventDefault();
    setAnchorEl(null);
    setIsOpen(true);
    setIsNewInserted(false);
    setHeadertext("Add Treatment Plan");
    actions.resetGetQstns();
    // actions.resetEditTreatment();
  };

  const closeModal = () => {
    setHeadertext("");
    setIsOpen(false);
  };

  const openCostCoverageModal = () => {
    setOpenCostCoverage(true);
    actions.resetMessageModal();
  };

  const closeCostCoverageModal = () => {
    setOpenCostCoverage(false);
    setIsNewInserted(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openNotes = () => {
    setNotesModal(true);
  };

  const closeNoteModal = () => {
    setNotesModal(false);
  };

  const saveNoteModal = () => {
    let payload = {
      practiceId,
      estimateId: estimates.estimateId,
      estimateNotes
    };
    actions.updateNotes(payload);
    closeNoteModal();
  };

  const publishToPatientPortal = () => {
    setPublishToPatientModal(true);
  };

  const handlePublish = () => {
    const payload = {
      estimateId: estimates.estimateId,
      practiceId,
      actions: "publish"
    };

    actions.publishToPatientPortal(payload);
  };

  const handleReview = () => {
    let payload = {
      practiceId,
      estimateId: estimates.estimateId
    };
    actions.getEstimateCard(payload);
    setPriceChangeModal(false);
    setPublishToPatientModal(false);
    closePriceChangeModal();
    const payload1 = {
      estimateId: estimates.estimateId,
      practiceId,
      actions: "review"
    };

    actions.publishToPatientPortal(payload1);
  };

  const closeRefundMissingModal = () => {
    actions.resetPublishMessage();
    setRefundMissingModal(false);
    closePublishModal();
  };

  const closePriceChangeModal = () => {
    setPriceChangeModal(false);
    actions.resetMessage();
    actions.resetPublishMessage();
  };

  const closePublishModal = () => {
    setPublishToPatientModal(false);
  };
  const closeNoteSuccess = () => {
    setMessageModal(false);
    let payload = {
      practiceId,
      estimateId: estimates.estimateId
    };
    actions.getNotes(payload);
    actions.resetNoteMessage();
  };
  const closeMessageModal = () => {
    initFetch();
    setIsMessageModal(false);
    closePublishModal();
    actions.resetMessage();
    actions.resetPublishMessage();
  };

  const previewEstimateDetails = () => {
    previewFinancialEstimateDetailsModal(true);
  };

  const closePreviewEstimateModal = () => {
    previewFinancialEstimateDetailsModal(false);
  };

  const setEstimateCard = (card) => {
    //setActiveEstimateCard(card);
    return card;
  };

  useEffect(() => {
    if (estimateCards && estimateCards.length > 0) {
      downloadPDFEstimates();
    }
  }, [estimateCards, estimates]);

  const downloadPDFEstimates = async () => {
    let gdmID = estimateCards && estimateCards[0] && estimateCards[0].gdmId;
    let dashboardArr = [];

    estimateCards &&
      estimateCards.length > 0 &&
      estimateCards.map((eCard) => {
        let newEsObj = {
          practiceId: practiceId,
          gdmId: gdmID,
          treatmentPlanId: eCard.treatmentPlanId,
          estimateId: estimates.estimateId,
          estimateTpId: eCard.finEstimateTpId
        };
        dashboardArr.push(newEsObj);

        if (eCard.linkedEstimateTreatmentPlan !== null) {
          let eCardLink = eCard.linkedEstimateTreatmentPlan;
          let newLinkEsObj = {
            practiceId: practiceId,
            gdmId: gdmID,
            treatmentPlanId: eCardLink.treatmentPlanId,
            estimateId: estimates.estimateId,
            estimateTpId: eCardLink.finEstimateTpId
          };
          dashboardArr.push(newLinkEsObj);
        }

        return eCard;
      });

    let payload = {
      practiceId: practiceId,
      gdmId: gdmID,
      dashboard: dashboardArr
    };
    if (dashboardArr && dashboardArr.length > 0) {
      actions.getEstimatePDFData(payload);
    }

    return;
  };

  return (
    <Box>
      <Box className="p-8 estimate-details">
        <Grid container className="mx-8 ">
          <Grid item xs={6}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography>View</Typography>
              <EstimateView
                data={estimateViewList}
                loading={estimateViewLoading}
                selectedView={(data) => setEstimates(data)}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" justifyContent="flex-end">
              <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={(event) => setAnchorEl(event.currentTarget)}
                endIcon={<KeyboardArrowDownIcon />}>
                Actions
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  "aria-labelledby": "demo-customized-button"
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={createPreliminaryEstimate}>
                  Create Preliminary Estimates
                </MenuItem>
                <MenuItem onClick={createDetailedEstimate}>Create Detailed Estimates</MenuItem>
                <MenuItem
                  disabled={
                    (estimateViewList != null && estimateViewList.length == 0) ||
                    estimateCards.length > 2 ||
                    estimates.estimateStatus === "Expired" ||
                    estimates.estimateStatus === "Published"
                      ? true
                      : false
                  }
                  onClick={addTreatmentPlan}>
                  Add Treatment Plan
                </MenuItem>
              </StyledMenu>
              <Button className="add-btn" onClick={openNotes}>
                <AddCircleOutlineIcon className="add-circle" /> {notes ? "View Note" : "Add Note"}
              </Button>
            </Stack>
          </Grid>
        </Grid>

        {/* estimate detail Modal */}
        {isOpen && (
          <EstimateDetails
            open={isOpen}
            patientId={personId}
            estimateId={estimates.estimateId}
            openCost={openCostCoverageModal}
            header={headerText}
            close={closeModal}
          />
        )}

        {/* cost coverage modal */}
        {openCostCoverage && (
          <CostCoverage
            estimateId={estimates.estimateId}
            open={openCostCoverage}
            close={closeCostCoverageModal}
            isEdit={false}
          />
        )}
      </Box>
      {estimates ? (
        <Box>
          <Divider className="p-4" />
          <Box className="estimate-statusbar">
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" py="6px" pl={1}>
                <Typography>
                  {estimates.estimateType == "D" ? "Detailed Estimate" : "Preliminary Estimate"}
                </Typography>
                <Divider orientation="vertical" sx={{ px: 1 }} />
                <Typography px={1}>Status : {estimates.estimateStatus}</Typography>
                <Divider orientation="vertical" />
                {estimates.publishedBy ? (
                  <Typography px={1} className="publish-create-ellipsis">
                    published By : {estimates.publishedBy}
                  </Typography>
                ) : (
                  <Typography px={1} className="publish-create-ellipsis">
                    Created By : {estimates.createdBy}
                  </Typography>
                )}

                <Divider orientation="vertical" />
                {estimates.publishedDate ? (
                  <Typography px={1}>
                    Published Date :{" "}
                    {
                      getLocaleDateTime(
                        estimates.publishedDate,
                        "l"
                      ) /* new Date(estimates.publishedDate).toLocaleDateString() */
                    }
                  </Typography>
                ) : (
                  <Typography px={1}>
                    Created Date :{" "}
                    {
                      getLocaleDateTime(
                        estimates.estimateCreatedDate,
                        "l"
                      ) /* new Date(estimates.estimateCreatedDate).toLocaleDateString() */
                    }
                  </Typography>
                )}
              </Stack>

              <Stack direction="row">
                <Button className="add-btn" onClick={previewEstimateDetails}>
                  Preview
                </Button>
                <Divider sx={{ height: 18, my: 0.5 }} orientation="vertical" />

                {/* <Button className="add-btn" onClick={() => downloadPDFEstimates()}>
                  Download PDF
                </Button> */}

                {/* <img
                  src={`data:image/svg+xml;base64,${estimates?.practiceLogo}`}
                  alt="Practice Logo"
                  width="50px"
                  height="50px"
                  style={{ backgroundColor: "black" }}
                /> */}

                {estimatePdfData && estimatePdfData.length > 0 && (
                  <PDFDownloadLink
                    document={
                      <FinancialEstimatePDF
                        estimates={estimates}
                        estimateCards={estimateCards}
                        //card={activeEstimateCard}
                        // stages={stages}
                        // necessary={necessary}
                        // optional={optional}
                        notes={notes}
                        // adjustments={adjustmentList}
                        // fapList={fapList}
                        estimatePdfData={estimatePdfData}
                        // isPdfLoading={isPdfLoading}
                      />
                    }
                    fileName={Date.now() + ".pdf"}>
                    {({
                      loading,
                      adjustmentLoading,
                      necessaryLoading,
                      stageLoading,
                      noteLoading
                    }) => (
                      <Button className="add-btn">
                        {loading ||
                        adjustmentLoading ||
                        necessaryLoading ||
                        stageLoading ||
                        noteLoading ||
                        fapLoading ||
                        efapLoading ||
                        isPdfLoading
                          ? "Loading Document..."
                          : "Download PDF"}
                      </Button>
                    )}
                  </PDFDownloadLink>
                )}

                <Divider sx={{ height: 18, my: 0.5 }} orientation="vertical" />
                <Button
                  className="add-btn"
                  onClick={publishToPatientPortal}
                  disabled={estimates.estimateStatus !== "In-Progress" ? true : false}>
                  Publish to Patient Portal
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Box className="loan-section">
            <Typography>
              Interested in applying for a loan?{" "}
              <Link
                underline="none"
                target="_blank"
                href={
                  (systemConfiguration &&
                    systemConfiguration.length > 0 &&
                    systemConfiguration[0] &&
                    systemConfiguration[0].metaValue) ||
                  APPLY_LOAN
                }>
                Apply Now
              </Link>
            </Typography>
          </Box>
          {estimateViewLoading || estimateCardLoading ? (
            <Stack justifyContent="center" p={5} alignItems="center">
              <CircularProgress size={50} />
            </Stack>
          ) : estimateCards.length ? (
            <EstimateCards
              data={estimateCards}
              estimate={estimates}
              openCost={openCostCoverageModal}
              isNewInserted={isNewInserted}
              patientDetails={estimateViewList}
              setEstimateCard={setEstimateCard}
            />
          ) : null}
        </Box>
      ) : null}

      <Modal open={notesModal} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="modal-style">
          <Stack direction="row" className="modal-stack">
            <Typography>{notes ? "View Note" : "Add Note"}</Typography>
            <IconButton onClick={closeNoteModal}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="column" className="flex-center m-8">
            <Typography>
              Create a note for the patient to read when reviewing their estimate at home.
            </Typography>
            <TextareaAutosize
              className="width-100 m-8"
              value={estimateNotes}
              onChange={(e) => setEstimateNotes(e.target.value)}
              minRows={10}
            />
          </Stack>
          <Stack direction="row" className="flex-between">
            <Button variant="outlined" onClick={closeNoteModal}>
              Cancel
            </Button>
            <Button
              disabled={estimateNotes == "" ? true : false}
              variant="contained"
              onClick={saveNoteModal}>
              Add Note
            </Button>
          </Stack>
        </Box>
      </Modal>
      {isMessageModal && (
        <MessageModal
          open={isMessageModal}
          message={publishSuccess}
          // error={errorMsg}
          closeModal={closeMessageModal}
        />
      )}

      {messageModal && (
        <MessageModal open={messageModal} message={successNote} closeModal={closeNoteSuccess} />
      )}

      {noEstimateModal ? (
        <Modal open={noEstimateModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="modal-style">
            <Stack direction="row" className="modal-stack">
              <Typography>Add Estimates</Typography>
              <IconButton onClick={closeNoEstimateModal}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Stack direction="column" className="flex-center m-8">
              <Typography>
                There are no financial estimates for this patient. To create a new financial
                estimate, click Create new Preliminary Estimate / Create new detailed Estimate under
                the action section
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={closeNoEstimateModal}>
                Ok
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}

      {refundMissingModal ? (
        <Modal open={refundMissingModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="modal-style">
            <Stack direction="row" className="modal-stack">
              <Typography>Refund Application Missing</Typography>
              <IconButton onClick={closeRefundMissingModal}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Box>
              <Stack direction="column" className="flex-center m-8">
                <Typography my={1}>
                  Atleast 1 treatment plan option in this Detailed estimate maps to a refund program
                  that can help your patient save money by pre-paying for a bundle of IVF cycles.
                </Typography>
                <Typography my={1}>
                  To initiate a refund application, please go back and select the appropriate
                  treatment plan.
                </Typography>
                <Typography my={1}>
                  If you are not going to submit a refund application, please hide programs from the
                  payment options sections for all treatment options in the estimate.
                </Typography>
                <Typography my={1}>
                  Once the estimate is published without a refund application, there is no way to
                  change this without creating and publishing a new estimate.
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Button variant="contained" onClick={closeRefundMissingModal}>
                  Ok
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      ) : null}

      {priceChangeModal ? (
        <Modal open={priceChangeModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="modal-style">
            <Stack direction="row" className="modal-stack">
              <Typography>Price Change Alert !</Typography>
              <IconButton onClick={closePriceChangeModal}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Box>
              <Stack direction="column" className="flex-center m-8">
                <Typography my={1}>
                  Prices have changed in the database after you have created this estimate. Please
                  review the new price before publishing this estimate.
                </Typography>
              </Stack>
              <Stack direction="row" justifyContent="center" alignItems="center">
                <Button variant="contained" onClick={handleReview}>
                  Review
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      ) : null}

      {publishToPatientModal ? (
        <Modal open={publishToPatientModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="modal-style">
            <Stack direction="row" className="modal-stack">
              <Typography>Publish to Patient Portal</Typography>
              <IconButton onClick={closePublishModal}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Box>
              <Stack direction="column" className="flex-left m-8">
                <Typography my={1}>Are you sure you want to publish this estimate?</Typography>
                <Typography my={1}>
                  Once an estimate is published, it will be accessible by the patient via the
                  patient portal until another estimate is published or the existing estimate
                  expires.
                </Typography>
                <Typography my={1}>Do you wish to proceed and publish this estimate?</Typography>
              </Stack>
              <Stack direction="row" justifyContent="center" gap={1} pt={2}>
                <Button variant="outlined" onClick={closePublishModal}>
                  No
                </Button>
                <Button variant="contained" onClick={handlePublish}>
                  YES
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      ) : null}
      {publishLoading ? (
        <Modal open={publishLoading} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal" width="100px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Modal>
      ) : null}

      {isPreviewFinancialEstimateModal ? (
        <Modal
          // fullWidth={true}
          open={isPreviewFinancialEstimateModal}
          sx={{ backgroundColor: "#eee" }}
          aria-labelledby="modal-add-title">
          <Box
            className=" modal-style modalBox"
            sx={{ overflowY: "scroll", maxHeight: "90%", width: "90%" }}>
            <Stack direction="row" className="modal-stack">
              <Typography>Estimate Preview</Typography>
              <IconButton onClick={closePreviewEstimateModal}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <FinancialEstimateDetailsPreview
              exitPreview={closePreviewEstimateModal}
              isNewInserted={isNewInserted}
            />
          </Box>
        </Modal>
      ) : null}
    </Box>
  );
};

const mapStateToProps = ({ estimateReducer, costCoverageReducer, fapReducer }) => {
  return {
    questionsList: estimateReducer.questions,
    loading: estimateReducer.loading,
    estimateViewList: estimateReducer.estimateViewList,
    estimateCards: estimateReducer.estimateCards,
    estimateCardLoading: estimateReducer.estimateCardLoading,
    error: estimateReducer.error,
    success: estimateReducer.success,
    successNote: estimateReducer.successNote,
    estimateViewLoading: estimateReducer.estimateViewLoading,
    publishLoading: estimateReducer.publishLoading,
    publishSuccess: estimateReducer.publishSuccess,
    pulishError: estimateReducer.pulishError,
    addTreatment: estimateReducer.addTreatment,
    editTreatment: estimateReducer.editTreatment,
    noteLoading: estimateReducer.noteLoading,
    notes: estimateReducer.notes,
    stages: costCoverageReducer.stages,
    necessary: costCoverageReducer.necessary,
    optional: costCoverageReducer.optional,
    adjustmentList: costCoverageReducer.adjustmentList,
    adjustmentLoading: costCoverageReducer.adjustmentLoading,
    necessaryLoading: costCoverageReducer.necessaryLoading,
    stageLoading: costCoverageReducer.stageLoading,
    systemConfiguration: estimateReducer.systemConfiguration,
    fapList: estimateReducer.fapList,
    fapLoading: fapReducer.loading,
    efapLoading: estimateReducer.fapLoading,
    estimatePdfData: estimateReducer.estimatePdfData,
    isPdfLoading: estimateReducer.isPdfLoading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getFinancialEstimateQuestions: (practiceId) => {
      dispatch(actionType.getFinancialEstimateQuestions(practiceId));
    },
    createEstimate: (payload) => {
      dispatch(actionType.createEstimate(payload));
    },
    getNotes: (payload) => {
      dispatch(actionType.getNotes(payload));
    },
    updateNotes: (payload) => {
      dispatch(actionType.updateNotes(payload));
    },
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    },
    getSystemConfigurationList: (payload) => {
      dispatch(actionType.getSystemConfigurationList(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    resetNoteMessage: () => {
      dispatch(actionType.resetNoteMessage());
    },
    getFinancialEstimateViewList: (payload) => {
      dispatch(actionType.getFinancialEstimateViewList(payload));
    },
    publishToPatientPortal: (payload) => {
      dispatch(actionType.publishToPatientPortal(payload));
    },
    resetPublishMessage: () => {
      dispatch(actionType.resetPublishMessage());
    },
    resetMessageModal: () => {
      dispatch(actionType.resetMessageModal());
    },
    resetGetQstns: () => {
      dispatch(actionType.resetGetQstns());
    },
    resetEditTreatment: () => {
      dispatch(actionType.resetEditTreatment());
    },
    resetEstimateViewList: () => {
      dispatch(actionType.resetEstimateViewList());
    },
    setEstimateId: (payload) => {
      dispatch(actionType.setEstimateId(payload));
    },
    inputValues: (payload) => {
      dispatch(actionType.inputValues(payload));
    },
    getEstimatePDFData: (payload) => {
      dispatch(actionType.getEstimatePDFData(payload));
    },
    resetAddTreatment: (payload) => {
      dispatch(actionType.resetAddTreatment(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(FinancialEstimateDetails);
