import * as React from "react";
import { useEffect } from "react";
import { Box, Divider, FormControl, Grid, MenuItem, Select, Stack } from "@mui/material";
import PracticeConfiguration from "./components/PracticeConfiguration";
import FelixAdminConfiguration from "./components/FelixAdminConfiguration";
import "./components/Admin.scss";
import { Outlet, useNavigate } from "react-router-dom";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import Breadcrumb from "../shared/components/Breadcrumb";

const Admin = () => {
  const [practiceData, setPracticeData] = React.useState();
  const navigate = useNavigate();
  const [config, setConfig] = React.useState(
    location.pathname.split("/")[2] === "staff-admin" ? "felix" : "patient-portal-admin"
  );
  useEffect(() => {
    if (
      location.pathname.split("/")[2] === "patient-portal-admin" ||
      location.pathname.split("/")[2] === "staff-admin"
    ) {
      setConfig("felix");
    } else {
      setConfig("practice");
    }
  }, []);
  const handleChange = (event) => {
    setConfig(event.target.value);
    if (event.target.value === "felix") {
      navigate("/admin/patient-portal-admin");
    } else {
      navigate("/admin/practice-payment");
    }
  };

  const getSelectedConfig = (selected) => {
    setPracticeData(selected);
    console.log(practiceData);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}>
            <FormControl sx={{ width: 244, minWidth: 200, border: "unset" }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={config}
                IconComponent={ExpandMoreSharpIcon}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { border: "unset" },
                  color: "#00889F",
                  "& .MuiSvgIcon-root": {
                    color: "#00889F"
                  }
                }}
                onChange={handleChange}>
                <MenuItem value={"felix"}>User Administration</MenuItem>
                <MenuItem value={"practice"}>Practice Configuration</MenuItem>
              </Select>
            </FormControl>
            <Divider sx={{ height: 26, m: 0.5 }} orientation="vertical" />
            {/* breadcrumb start here */}
            <Breadcrumb />
            {/* breadcrumb end here */}
          </Stack>
        </Grid>

        <Grid container item xs={12}>
          <Grid container item xs={1.6} sx={{ background: "#818294", minHeight: "100vh" }}>
            {config === "practice" ? (
              <PracticeConfiguration getSelectedConfig={getSelectedConfig} />
            ) : (
              <FelixAdminConfiguration getSelectedConfig={getSelectedConfig} />
            )}
          </Grid>
          <Grid item xs={10.4}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Admin;
