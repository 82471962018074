import * as actionType from "../actions/ActionTypes";

const initialState = {
  loading: false,
  error: null,
  success: null,
  selectedUser: "",
  enableEdit: false,
  getUserDetails: null,
  getUserLoading_loading: false,
  saveedit_loading: false,
  saveedit_error: null,
  saveedit_success: null,
  saveedit_success_flag: false,
  createuser_success_flag: false
};

const addNewUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CREATE_NEW_USER: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_NEW_USER_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.success,
        createuser_success_flag: true
      };
    }
    case actionType.CREATE_NEW_USER_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false,
        createuser_success_flag: false
      };
    }
    case actionType.RESET_CREATE_NEW_USER: {
      return {
        ...state,
        success: null,
        loading: false,
        error: null,
        createuser_success_flag: false
      };
    }
    case actionType.GET_SELECTED_USER_DETAILS: {
      return {
        ...state,
        selectedUser: action.payload
      };
    }
    case actionType.SET_EDIT_FLAG: {
      return {
        ...state,
        enableEdit: action.payload
      };
    }
    case actionType.GET_EDIT_USER_DETAILS: {
      return {
        ...state,
        loading: false
      };
    }
    // case actionType.GET_EDIT_USER_DETAILS: {
    //   return {
    //     ...state,
    //     loading: false
    //   };
    // }
    case actionType.EDIT_USER_SUCCESS: {
      return {
        ...state,
        getUserLoading_loading: false,
        getUserDetails: action.payload
      };
    }
    case actionType.SAVE_EDIT_USER_DETAILS: {
      return {
        ...state,
        saveedit_loading: true
      };
    }
    case actionType.SAVE_EDIT_USER_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload,
        saveedit_loading: false,
        saveedit_success: action.success,
        saveedit_success_flag: true
      };
    }
    case actionType.SAVE_EDIT_USER_FAIL: {
      return {
        ...state,
        saveedit_error: action.message,
        saveedit_loading: false,
        saveedit_success_flag: false
      };
    }
    case actionType.RESET_SAVE_EDIT_NEW_USER: {
      return {
        ...state,
        saveedit_success: null,
        saveedit_loading: false,
        saveedit_error: null,
        saveedit_success_flag: false
      };
    }
    case actionType.RESET_GET_EDIT_USER_DETAILS: {
      return {
        ...state,
        getUserLoading_loading: false,
        getUserDetails: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default addNewUserReducer;
