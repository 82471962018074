import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Typography, Stack, Grid, MenuItem, Select, IconButton } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import MessageModal from "../../shared/components/MessageModal";
import StatusModal from "./modals/StatusModal";
import moment from "moment";
import LoadingModal from "./modals/loadingModal";
import ConfirmationModal from "../../shared/components/ConfirmationModal";
import DeleteModal from "../../shared/components/DeleteModal";
import isAccess from "../../shared/components/CheckUserRights";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const Status = (props) => {
  let statusFormData = {
    // type: "",
    // comment: ""
  };

  const [isOpen, setIsOpen] = useState(false);
  const [deleteStatusModel, setDeleteStatusModel] = useState(false);
  const [medcaseStatusDeleteId, setMedcaseStatusDeleteId] = useState("");
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState("");
  const [medcaseStatus, setMedcaseStatus] = useState("");
  const [prevMedcaseStatus, setPrevMedcaseStatus] = useState("");
  const [statusChangeModelMsg, setStatusChangeModelMsg] = useState("");
  const [pgtaAnalysisStatus, setPgtaAnalysisStatus] = useState("");
  const [notEnrolledReason, setNotEnrolledReason] = useState("");
  const [contractStatus, setContractStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [caseStatus, setCaseStatus] = useState("");
  const [greyStatusData, setGreyStatusData] = useState([]);
  const [isNotEnrolledReasonEmpty, setIsNotEnrolledReasonEmpty] = useState(false);

  let { id } = useParams();

  const columns = [
    {
      field: "caseStatus",
      headerName: "STATUS",
      width: 100,
      flex: 1
    },
    {
      field: "statusType",
      headerName: "STATUS TYPE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "program",
      headerName: "PROGRAM",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "statusDate",
      headerName: "STATUS DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (row.statusDate ? moment(row.statusDate).format("MM/DD/YYYY") : "")
    },
    {
      field: "user",
      headerName: "USER",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "reason",
      headerName: "REASON",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {isAccess("std") && (
            <IconButton
              onClick={() => {
                console.log("Row: ", row);
                setDeleteStatusModel(true);
                setMedcaseStatusDeleteId(row.medcaseStatusHistoryId);
              }}>
              <DeleteIcon color="primary"></DeleteIcon>
            </IconButton>
          )}
        </>
      )
    }
  ];

  const {
    statusList = [],
    actions,
    addStatus,
    errorMsg,
    successMsg,
    metaData,
    loading,
    status = {},
    medcaseHeader = {},
    loadingStatusDropdown
  } = props;

  useEffect(() => {
    actions.getStatus({ medcaseId: id });
    actions.getMedcaseStatus({ medcaseId: id });
  }, [id]);

  const initFetch = useCallback(
    () => {
      actions.getMetaData();
      // actions.getStatus({ medcaseId: id });
      // actions.getMedcaseStatus({ medcaseId: id });
      // actions.getMedcaseHeader({ medcaseId: id });
    },
    [
      /* addStatus */
    ]
  );
  useEffect(() => {
    let arr = [];
    for (let i = 0; i < statusList.length; i++) {
      arr.push(statusList[i].caseStatus);
    }
    setGreyStatusData(arr);
  }, [statusList]);
  useEffect(() => {
    if (Object.entries(status).length > 0) {
      setMedcaseStatus(status.medcaseStatus);
      setPgtaAnalysisStatus(status.pgtaAnalysisStatus);
      setPaymentStatus(status.paymentStatus);
      setContractStatus(status.contractStatus);
      setNotEnrolledReason(status?.notEnrolledReason);
    }
  }, [status]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      console.log("successMsg: ", successMsg);
      setIsMessageModal(true);
    }

    if (statusList.length == 0 || addStatus || successMsg || errorMsg) {
      initFetch();
    }
    return () => {
      // actions.resetStatusList();
    };
  }, [addStatus, errorMsg, successMsg]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  const addNewStatus = (status) => {
    setValues(statusFormData);
    setCaseStatus(status);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const saveStatus = (event) => {
    event.preventDefault();
    const payload = {
      medcaseId: id,
      caseStatus: caseStatus,
      reason: values.reason
    };
    actions.addNewStatus(payload);
    closeModal();
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    setIsNotEnrolledReasonEmpty(false);
  };

  const closeConfirmationModel = () => {
    if (statusChangeModelMsg) {
      setMedcaseStatus(prevMedcaseStatus);
      setStatusChangeModelMsg("");
    }
    actions.resetAPIMessage();
    actions.resetAddStatus();
  };

  function handleCancleDeleteStatus() {
    setDeleteStatusModel(false);
    setMedcaseStatusDeleteId("");
  }

  function updateMedcaseStatus() {
    if (medcaseStatus === "Not Enrolled") {
      actions.updateMedcaseStatusDdl({
        medcaseId: id,
        caseStatus: medcaseStatus,
        statusType: "MEDCASE",
        neReason: notEnrolledReason
      });
    } else {
      actions.updateMedcaseStatusDdl({
        medcaseId: id,
        caseStatus: medcaseStatus,
        statusType: "MEDCASE",
        neReason: null
      });
      setStatusChangeModelMsg("");
    }
  }

  const handleChangeMedcaseStatus = (event) => {
    const status = event.target.value;
    if (status === "Not Enrolled" && !notEnrolledReason) {
      setIsNotEnrolledReasonEmpty(true);
    } else {
      setMedcaseStatus(status);
      setPrevMedcaseStatus(medcaseStatus);
      setStatusChangeModelMsg(`Are you sure you want to change the medcase status to ${status}?`);
      setIsMessageModal(true);
    }
  };

  const handleChangePgtaAnalysisStatus = (event) => {
    const status = event.target.value;
    console.log("status:", status);
    if (status === "Not Enrolled" && !notEnrolledReason) {
      setIsNotEnrolledReasonEmpty(true);
    } else {
      if (status === "Not Enrolled") {
        setPgtaAnalysisStatus(status);
        actions.updateMedcaseStatusDdl({
          medcaseId: id,
          caseStatus: status,
          statusType: "PGTA",
          neReason: notEnrolledReason
        });
      } else {
        setPgtaAnalysisStatus(status);
        actions.updateMedcaseStatusDdl({
          medcaseId: id,
          caseStatus: status,
          statusType: "PGTA",
          neReason: null
        });
      }
    }
  };

  const handleChangeNotEnrolledReason = (event) => {
    setNotEnrolledReason(event.target.value);
  };

  const handleChangeContractStatus = (event) => {
    setContractStatus(event.target.value);
    actions.updateMedcaseStatusDdl({
      medcaseId: id,
      caseStatus: event.target.value,
      statusType: "CONTRACT"
    });
  };

  const handleChangePaymentStatus = (event) => {
    setPaymentStatus(event.target.value);
    actions.updateMedcaseStatusDdl({
      medcaseId: id,
      caseStatus: event.target.value,
      statusType: "PAYMENT"
    });
  };

  const medcaseStatusListDisable = [
    "Program Complete Successful",
    "Program Complete Unsuccessful",
    "Closed",
    "Application Withdrawn",
    "Patient Withdrew",
    "Fertility Access Withdrew"
  ];

  const handleStatusDelete = () => {
    setDeleteStatusModel(false);
    let payload = {
      medcaseId: id,
      statusId: medcaseStatusDeleteId
    };
    actions.deleteMedcaseStatus(payload);
  };

  const refreshStatus = () => {
    let payload = {
      medcaseId: id
    };
    actions.getRefreshedStatusHistory(payload);
  };
  console.log("isStatusRefreshed", props.isStatusRefreshed);

  useEffect(() => {
    if (props.isStatusRefreshed) {
      actions.getStatus({ medcaseId: id });
      actions.getMedcaseStatus({ medcaseId: id });
      actions.resetRefreshedStatusCode();
    }
  }, [props.isStatusRefreshed]);
  return (
    <>
      <Stack className="pad-5" direction="row">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>IVF Medcase Status</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <Select
              name="medcaseStatus"
              value={medcaseStatus}
              className="patient-dropdown"
              disabled={
                (medcaseHeader.medcaseActive == "Inactive" ? true : false) ||
                (medcaseHeader.medcaseType == "PGTA" ? true : false)
              }
              onChange={handleChangeMedcaseStatus}>
              {Object.entries(metaData).length > 0 &&
                metaData.medcaseStatus.map((item, index) => {
                  return (
                    <MenuItem
                      value={item}
                      key={index}
                      // disabled={greyStatusData.indexOf(item) > -1 ? true : false}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography>PGTA Medcase Status</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <Select
              name="pgtaAnalysisStatus"
              value={pgtaAnalysisStatus || ""}
              fullWidth
              sx={{ flex: 6, height: 35 }}
              disabled={
                (medcaseHeader.medcaseType == "PGTA" ? false : true) ||
                (medcaseHeader.medcaseActive == "Inactive" ? true : false)
              }
              onChange={handleChangePgtaAnalysisStatus}>
              {Object.entries(metaData).length > 0 &&
                metaData.pgtaAnalysisStatus.map((item, index) => {
                  return (
                    <MenuItem
                      value={item}
                      key={index}
                      disabled={greyStatusData.indexOf(item) > -1 ? true : false}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
        </Grid>
      </Stack>
      <Stack className="pad-5" direction="row">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Contract Status</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <Select
              name="contractStatus"
              value={contractStatus}
              disabled={
                (medcaseStatusListDisable.indexOf(medcaseStatus) > -1 ? true : false) ||
                (medcaseHeader.medcaseActive == "Inactive" ? true : false)
              }
              className="patient-dropdown"
              onChange={handleChangeContractStatus}>
              {Object.entries(metaData).length > 0 &&
                metaData.contractStatus.map((item, index) => {
                  return (
                    <MenuItem
                      value={item}
                      key={index}
                      disabled={greyStatusData.indexOf(item) > -1 ? true : false}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography>Payment Status</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <Select
              name="paymentStatus"
              value={paymentStatus}
              disabled={
                (medcaseStatusListDisable.indexOf(medcaseStatus) > -1 ? true : false) ||
                (medcaseHeader.medcaseActive == "Inactive" ? true : false)
              }
              fullWidth
              sx={{ flex: 6, height: 35 }}
              onChange={handleChangePaymentStatus}>
              {Object.entries(metaData).length > 0 &&
                metaData.paymentStatus.map((item, index) => {
                  return (
                    <MenuItem
                      value={item}
                      key={index}
                      disabled={greyStatusData.indexOf(item) > -1 ? true : false}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
        </Grid>
      </Stack>
      <Stack className="pad-5" direction="row">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Not Enrolled Reason</Typography>
          </Grid>
          <Grid item xs={3.8}>
            <Select
              name="notEnrolledReason"
              value={notEnrolledReason}
              fullWidth
              sx={{ flex: 6, height: 35 }}
              onChange={handleChangeNotEnrolledReason}>
              {metaData && metaData.notEnrolledReason && metaData.notEnrolledReason.length > 0
                ? metaData.notEnrolledReason.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })
                : []}
            </Select>
          </Grid>
        </Grid>
      </Stack>

      <Box sx={{ ml: 1, marginTop: "30px", marginBottom: "30px" }} className="m-r-8">
        <Stack
          direction="row"
          className="grid-header"
          sx={{ alignItems: "center", justifyContent: "flex-end" }}>
          <Typography sx={{ marginBottom: "10px" }}>Medcase Active/InActive</Typography>
          {medcaseHeader.medcaseActive == "Active" ? (
            <Typography
              variant="outlined"
              sx={{
                marginRight: "479px",
                marginLeft: "32px",
                color: "black",
                border: "1.1px solid #228B22",
                padding: "7px",
                paddingLeft: "9px",
                paddingRight: "9px",
                borderRadius: "4px",
                backgroundColor: "#98FB98"
              }}>
              Active
            </Typography>
          ) : (
            <Typography
              variant="outlined"
              sx={{
                marginRight: "480px",
                marginLeft: "20px",
                color: "black",
                border: "1px solid red",
                padding: "7px",
                paddingLeft: "9px",
                paddingRight: "9px",
                borderRadius: "4px",
                paddingBottom: "4px",
                backgroundColor: "#FFB2B2"
              }}>
              Inactive
            </Typography>
          )}

          <Button
            variant="contained"
            className="btn-primary"
            onClick={() => addNewStatus("Active")}
            disabled={medcaseHeader.medcaseActive == "Active"}
            sx={{ mr: 1 }}>
            Active Medcase
          </Button>
          <Button
            variant="contained"
            className="btn-usf"
            onClick={() => addNewStatus("Inactive")}
            disabled={medcaseHeader.medcaseActive !== "Active"}>
            Inactive Medcase
          </Button>
        </Stack>
      </Box>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10"></Box>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="grid-header">
          <Typography>Status History</Typography>
          {isAccess("assy") && (
            <Button
              variant="contained"
              className="btn-primary"
              onClick={() => refreshStatus()}
              sx={{ mr: 1 }}>
              Sync All Status
            </Button>
          )}
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={statusList}
            rowCount={statusList.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            getRowId={() => uuidv4()}
          />
        </Box>
      </Box>
      {successMsg && isMessageModal && (
        <MessageModal open={isMessageModal} message={successMsg} closeModal={closeMessageModal} />
      )}

      {errorMsg && isMessageModal && (
        <MessageModal open={isMessageModal} error={errorMsg} closeModal={closeMessageModal} />
      )}
      <StatusModal
        open={isOpen}
        handleInputChange={handleInputChange}
        close={closeModal}
        isActive={medcaseHeader.medcaseActive}
        handleSubmit={saveStatus}
      />
      {<LoadingModal open={loading} />}
      {<LoadingModal open={loadingStatusDropdown} />}

      {/* IVF Medcase Status Message modal */}
      {statusChangeModelMsg && isMessageModal && (
        <ConfirmationModal
          open={isMessageModal}
          message={statusChangeModelMsg}
          close={closeConfirmationModel}
          handleOpeartion={updateMedcaseStatus}
          confirmText="Yes"
          declineText="No"
        />
      )}

      {/* Medcase Status Delete Modal */}
      <DeleteModal
        open={deleteStatusModel}
        id={medcaseStatusDeleteId}
        close={handleCancleDeleteStatus}
        handleDelete={handleStatusDelete}
      />

      {/* if medcase status not enrolled and not enrolled reason empty */}
      {isNotEnrolledReasonEmpty && (
        <MessageModal
          open={isNotEnrolledReasonEmpty}
          message="Not Enrolled Reason must be added prior to changing Medcase Status to Not Enrolled"
          closeModal={closeMessageModal}
        />
      )}
    </>
  );
};
const mapStateToProps = ({ statusReducer, caseUpdateReducer, medcaseHeaderReducer }) => {
  return {
    metaData: caseUpdateReducer.metaData,
    loading: statusReducer.loading,
    statusList: statusReducer.statusList,
    addStatus: statusReducer.addStatus,
    errorMsg: statusReducer.error,
    successMsg: statusReducer.success,
    status: statusReducer.medcaseStatus[0],
    // medcaseHeader: statusReducer.medcaseHeader,
    loadingStatusDropdown: caseUpdateReducer.loading,
    medcaseHeader: medcaseHeaderReducer.medcaseDetails,
    isStatusRefreshed: statusReducer.isStatusRefreshed
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    },
    getStatus: (payload) => {
      dispatch(actionType.getStatus(payload));
    },
    addNewStatus: (payload) => {
      dispatch(actionType.addNewStatus(payload));
    },
    resetStatusList: () => {
      dispatch(actionType.resetStatusList());
    },
    resetAddStatus: () => {
      dispatch(actionType.resetAddStatus());
    },
    getMedcaseStatus: (payload) => {
      dispatch(actionType.getMedcaseStatus(payload));
    },
    // getMedcaseHeader: (payload) => {
    //   dispatch(actionType.getMedcaseHeader(payload));
    // },
    updateMedcaseStatusDdl: (payload) => {
      dispatch(actionType.updateMedcaseStatusDdl(payload));
    },
    resetAPIMessage: () => {
      dispatch(actionType.resetAPIMessage());
    },
    getMedcaseDetailsHeader: (payload) => {
      dispatch(actionType.getMedcaseDetailsHeader(payload));
    },
    deleteMedcaseStatus: (payload) => {
      dispatch(actionType.deleteMedcaseStatus(payload));
    },
    getRefreshedStatusHistory: (payload) => {
      dispatch(actionType.getRefreshedStatusHistory(payload));
    },
    resetRefreshedStatusCode: () => {
      dispatch(actionType.resetRefreshedStatusCode());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Status);
