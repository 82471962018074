import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPractices() {
  try {
    // query params
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("get", `/practices`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // console.log("fetch response: " + JSON.stringify(response));
    yield put({
      type: actionTypes.PRACTICE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PRACTICE_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getPracticeSaga() {
  yield takeEvery(actionTypes.PRACTICE_LIST_REQUESTED, fetchPractices);
}

function* createNewPractice(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("post", `/practices`, params, payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // console.log("createNewPractice response: ", response);
    yield put({
      type: actionTypes.ADD_NEW_PRACTICE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_PRACTICE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewPracticeSaga() {
  yield takeEvery(actionTypes.ADD_NEW_PRACTICE_REQUESTED, createNewPractice);
}

function* editPractice(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}`,
      params,
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // console.log("edit practice:", response);
    yield put({
      type: actionTypes.EDIT_PRACTICE_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_PRACTICE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editPracticeSaga() {
  yield takeEvery(actionTypes.EDIT_PRACTICE_REQUESTED, editPractice);
}

export default function* practiceSaga() {
  yield all([getPracticeSaga(), addNewPracticeSaga(), editPracticeSaga()]);
}
