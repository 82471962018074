import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Typography, Link } from "@mui/material";
import { useLocation } from "react-router-dom";
import * as actionType from "../actions";
import { connect } from "react-redux";
import MessageModal from "../../shared/components/MessageModal";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const LoadFromExcel = (props) => {
  const { validatedFileData, uploadedFileData, loading, errorMessage } = props;
  const { actions } = props;
  const [browsedFile, setBrowsedFile] = useState(null);
  const [isValidBtnDisabled, setIsValidBtnDisabled] = useState(true);
  const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState(true);
  const practiceId = localStorage.getItem("practiceId");
  const gdmId = localStorage.getItem("gdmId");
  const [isMessageModal, setIsMessageModal] = useState(false);
  const location = useLocation();
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const columns = [
    {
      field: "processStatus",
      headerName: "PROCESS STATUS",
      width: 50,
      flex: 1
    },
    {
      field: "errorStatus",
      headerName: "ERROR STATUS",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "filePath",
      headerName: "FILE PATH",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Link
            href={row.filePath}
            variant="body2"
            underline="none"
            target="_blank"
            download
            sx={{ width: "90%", overflow: "hidden" }}>
            {row.filePath}
          </Link>
        );
      }
    }
  ];

  const rows = [
    {
      id: 1,
      processStatus: Object.keys(validatedFileData).length
        ? `Validation - Completed`
        : `Validation - Not Completed`,
      errorStatus:
        Object.keys(validatedFileData).length && validatedFileData.status === "ERROR"
          ? `Error`
          : `No Error`,
      filePath: Object.keys(validatedFileData).length ? validatedFileData.responseFileUrl : "-"
    },
    {
      id: 2,
      processStatus:
        errorMessage || Object.keys(uploadedFileData).length
          ? `Upload - Completed`
          : `Upload - Not Completed`,
      errorStatus: errorMessage && errorMessage.length ? `Error` : `No Error`,
      filePath: "-"
    }
  ];

  const params = new URLSearchParams(window.location.search);
  let gdm_id_from_contract_template_mapping_page = params.get("gdm_id");

  const handleBrowseFile = (e) => {
    setBrowsedFile(e.target.files[0]);
    setIsValidBtnDisabled(false);
  };

  useEffect(() => {
    if (errorMessage && errorMessage.length) {
      setIsMessageModal(true);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (validatedFileData.status === "SUCCESS") {
      setIsUploadBtnDisabled(false);
    }
  }, [validatedFileData]);

  useEffect(() => {
    console.log("location", location);

    return () => {
      actions.emptyExcelStates();
    };
  }, []);

  const validateFile = () => {
    if (!Object.keys(browsedFile).length) {
      let pathArray = location.pathname.split("/");
      let componentPath =
        pathArray[3] && pathArray[3] == "loadByExcel" ? pathArray[2] : pathArray[3];
      if (
        componentPath == "Contract-Template-Mapping" &&
        !gdm_id_from_contract_template_mapping_page
      ) {
        alert("Not a valid GDM");
        return;
      }
      let fileReader = new FileReader();
      fileReader.readAsDataURL(browsedFile);
      fileReader.onload = function () {
        let payload = {
          practiceId,
          gdmId:
            componentPath == "Contract-Template-Mapping"
              ? gdm_id_from_contract_template_mapping_page
              : gdmId,
          formdata: fileReader.result?.split(",")[1],
          component: getComponentName(componentPath),
          fileType: browsedFile.type
        };
        actions.validateExcel(payload);
      };
    }
  };

  const uploadFile = () => {
    if (Object.keys(validatedFileData).length) {
      let pathArray = location.pathname.split("/");
      let componentPath =
        pathArray[3] && pathArray[3] == "loadByExcel" ? pathArray[2] : pathArray[3];
      if (
        componentPath == "Contract-Template-Mapping" &&
        !gdm_id_from_contract_template_mapping_page
      ) {
        alert("Not a valid GDM");
        return;
      }
      let payload = {
        practiceId,
        gdmId:
          componentPath == "Contract-Template-Mapping"
            ? gdm_id_from_contract_template_mapping_page
            : gdmId,
        validatedFileData,
        component: getComponentName(componentPath)
      };
      actions.uploadExcel(payload);
    }
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    let payload = {
      errorMessage: null
    };
    actions.emptyErrorMsg(payload);
  };

  const getComponentName = (component) => {
    switch (component) {
      case "serviceItems": {
        return "service-items";
      }
      case "stages": {
        return "stages";
      }
      case "treatmentPlan": {
        return "treatment-plans";
      }
      case "program-multiplier": {
        return "program-multipliers";
      }
      case "Contract-Template-Mapping": {
        return "program-template";
      }
      default:
        return "";
    }
  };

  return (
    <>
      <Box
        sx={{ ml: 1 }}
        className={`${loading ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>Load From Excel</Typography>
        </Stack>
        <Stack direction="row" className="grid-header" mt={1}>
          <Stack>
            <Button variant="contained" component="label" className="btn-primary excel-btn">
              <input hidden accept=".xlsx,.xls" type="file" onChange={handleBrowseFile} />
              Browse
            </Button>
          </Stack>
          <Stack
            direction="row"
            className="grid-header"
            sx={{ width: "85%", justifyContent: "center", background: "#0000001f" }}>
            <Typography variant="body1" pt={1.2} pb={1.2}>
              {browsedFile ? browsedFile.name : "No File Selected"}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" className="grid-header" mt={1} sx={{ justifyContent: "end" }}>
          <Button
            variant="outlined"
            className="btn-primary excel-btn "
            sx={{ mr: "8px" }}
            disabled={isValidBtnDisabled}
            onClick={validateFile}>
            Validate
          </Button>
          <Button
            variant="contained"
            className="btn-usf excel-btn"
            disabled={isUploadBtnDisabled}
            onClick={uploadFile}>
            Upload
          </Button>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            columns={columns}
            rows={rows}
            rowCount={rows.length}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <MessageModal open={isMessageModal} message={errorMessage} closeModal={closeMessageModal} />
    </>
  );
};

const mapStateToProps = ({ loadFromExcelReducer }) => {
  return {
    validatedFileData: loadFromExcelReducer.validatedFileData,
    uploadedFileData: loadFromExcelReducer.uploadedFileData,
    loading: loadFromExcelReducer.loading,
    errorMessage: loadFromExcelReducer.errorMessage
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    validateExcel: (payload) => {
      dispatch(actionType.validateExcel(payload));
    },
    uploadExcel: (payload) => {
      dispatch(actionType.uploadExcel(payload));
    },
    emptyErrorMsg: (payload) => {
      dispatch(actionType.emptyErrorMsg(payload));
    },
    emptyExcelStates: () => {
      dispatch(actionType.emptyExcelStates());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadFromExcel);
