import * as actionType from "../actions/ActionTypes";

const initialState = {
  statusList: [],
  medcaseStatus: [],
  medcaseHeader: [],
  addStatus: null,
  loading: false,
  error: null,
  success: null,
  isStatusRefreshed: false
};

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.STATUS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.STATUS_LIST_SUCCESS: {
      return {
        ...state,
        statusList: action.payload,
        loading: false
      };
    }
    case actionType.STATUS_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_STATUS_SUCCESS: {
      return {
        ...state,
        addStatus: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_STATUS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.RESET_STATUS: {
      return {
        ...state,
        addComment: null,
        loading: null
      };
    }
    // case actionType.RESET_MESSAGE: {
    //   return {
    //     ...state,
    //     error: null,
    //     success: false
    //   };
    // }
    case actionType.RESET_API_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    case actionType.RESET_STATUS_LIST: {
      return {
        ...state,
        statusList: [],
        addStatus: null,
        loading: null
      };
    }
    case actionType.RESET_ADD_STATUS: {
      return {
        ...state,
        addStatus: null
      };
    }
    case actionType.MEDCASE_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.MEDCASE_STATUS_SUCCESS: {
      return {
        ...state,
        medcaseStatus: action.payload
      };
    }
    case actionType.MEDCASE_HEADER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.MEDCASE_HEADER_SUCCESS: {
      return {
        ...state,
        medcaseHeader: action.payload
      };
    }
    case actionType.MEDCASE_DDL_STATUS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.MEDCASE_DDL_STATUS_SUCCESS: {
      return {
        ...state,
        addStatus: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.MEDCASE_DDL_STATUS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.DELETE_STATUS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_STATUS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.REFRESH_STATUS_HISTORY_REQUESTED: {
      return {
        ...state,
        loading: true,
        isStatusRefreshed: false
      };
    }

    case actionType.REFRESH_STATUS_HISTORY_SUCCESS: {
      return {
        ...state,
        isStatusRefreshed: action.payload == 200 ? true : false,
        loading: false
      };
    }
    case actionType.REFRESH_STATUS_HISTORY_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        isStatusRefreshed: false,
        loading: false
      };
    }
    case actionType.RESET_REFRESH_STATUS_HISTORY: {
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
        isStatusRefreshed: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default statusReducer;
