import React, { useEffect, useState } from "react";
import QuickAdd from "../../shared/components/QuickAdd";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { contractColumns } from "../constants";
import { Box, Button, Typography, Stack, Grid } from "@mui/material";
import WarningPopUp from "./modals/WarningPopUp";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const Contract = (props) => {
  const { actions, contractList, loading, success, error } = props;
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [searchTxt, setSearchTxt] = useState("");
  const [disableBtns, setDisableBtns] = useState(true);
  // const [url, setUrl] = useState("");
  const [selectedContractId, setselectedContractId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);

  useEffect(() => {
    let payload = {};
    payload.searchTxt = "";
    actions.getContractList(payload);
  }, []);
  useEffect(() => {
    if (searchTxt !== "") {
      let payload = {};
      payload.searchTxt = searchTxt;
      actions.getContractList(payload);
    }
  }, [searchTxt]);

  useEffect(() => {
    if (success?.documentUrl) {
      // console.log(success, "Success");
      var a = document.createElement("a");
      a.href = success.documentUrl;
      a.download = "document.pdf";
      a.click();
      actions.resetDocumentDownload();
    }
    if (error) {
      setIsOpen(true);
    }
  }, [success, error]);

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      reminderDate: row.reminderDate ? new Date(row.reminderDate) : ""
    }));
  };

  useEffect(() => {
    const rows =
      contractList.length !== 0
        ? contractList.map((item, index) => {
            item.id = index;
            return item;
          })
        : [];

    const csvDataMode = prepareCsvData(rows);
    console.log("csvDataMode: ", csvDataMode);
    setCsvData(csvDataMode);
  }, [contractList]);

  const handleSearch = (e, search) => {
    setSearchTxt(search);
  };
  const selectRow = (params) => {
    setDisableBtns(false);
    setselectedContractId(params.row.contractId);
    // setUrl(`/contract/${params.row.contractId}/documentUrl?limit=100&offset=`);
  };
  const handleDownloadUrl = () => {
    // handleDownload(url, "document.pdf");
    const payload = { contractId: selectedContractId };
    // console.log(payload, "ContractId");
    actions.contractDocumentDownload(payload);
  };
  // const handleDownload = (url, filename) => {
  //   fetch(url)
  //     .then((res) => {
  //       return res.blob();
  //     })
  //     .then((data) => {
  //       var a = document.createElement("a");
  //       a.href = window.URL.createObjectURL(data);
  //       a.download = filename;
  //       a.click();
  //     });
  // };
  return (
    <>
      <Box className="align-center pad-10 teal-bg-color m-l-10">
        <Grid container>
          <Grid item xs={10}>
            <QuickAdd text={1} search={7} handleSearchClick={handleSearch} />
          </Grid>
        </Grid>
      </Box>
      <Box className="m-r-8 m-l-8">
        <Stack direction="row" className="grid-header">
          <Typography>Contract List</Typography>
          <Stack direction="row" className="modal-bottom-section" spacing={2}>
            {/* <Button variant="contained" className="btn-usf" disabled={disableBtns}>
              Send Remainder
            </Button>
            <Button variant="contained" className="btn-usf" disabled={disableBtns}>
              Counter Sign
            </Button>
            <Button variant="contained" className="btn-usf" disabled={disableBtns}>
              Resend
            </Button> */}
            <Button
              variant="contained"
              className="btn-usf"
              disabled={disableBtns}
              onClick={handleDownloadUrl}>
              View Contract
            </Button>
          </Stack>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={csvData}
            rowCount={csvData.length}
            columns={contractColumns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            onRowClick={selectRow}
          />
        </Box>
      </Box>
      <WarningPopUp
        open={isOpen}
        closeModal={() => {
          setIsOpen(false);
          actions.resetDocumentDownload();
        }}
        error={error}
      />
    </>
  );
};

const mapStateToProps = ({ contractReducer }) => {
  return {
    contractList: contractReducer.contractList,
    loading: contractReducer.loading,
    success: contractReducer.successMsg,
    error: contractReducer.errorDownload
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getContractList: (payload) => {
      dispatch(actionType.getContractList(payload));
    },
    contractDocumentDownload: (payload) => {
      dispatch(actionType.contractDocumentDownload(payload));
    },
    resetDocumentDownload: () => {
      dispatch(actionType.resetContractDocumentDownload());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Contract);
