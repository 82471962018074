import * as actionType from "../actions/ActionTypes";

const initialState = {
  phoneList: [],
  addPhone: null,
  editPhone: null,
  loading: false,
  error: null,
  success: null
};

const phoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PHONE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PHONE_LIST_SUCCESS: {
      return {
        ...state,
        phoneList: action.payload,
        loading: false
      };
    }
    case actionType.PHONE_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_PHONE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_PHONE_SUCCESS: {
      return {
        ...state,
        addPhone: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_PHONE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.RESET_PHONE: {
      return {
        ...state,
        addPhone: null,
        loading: null
      };
    }
    // case actionType.RESET_MESSAGE: {
    //   return {
    //     ...state,
    //     error: null,
    //     success: false
    //   };
    // }
    case actionType.RESET_PHONE_LIST: {
      return {
        ...state,
        phoneList: [],
        loading: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default phoneReducer;
