import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { PropTypes } from "prop-types";

import { Stack } from "@mui/material";

const ListItemLink = (props) => {
  const { icon, primary, onClick, selected, to, locationState } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return (
          <RouterLink to={to} ref={ref} {...itemProps} state={{ locationState }} role={undefined} />
        );
      }),
    [to]
  );

  return (
    <ListItem
      selected={selected}
      button
      component={renderLink}
      disablePadding
      sx={{
        minHeight: "70px"
      }}
      onClick={onClick}>
      <Stack direction="row">
        {icon ? <ListItemIcon sx={{ ml: 2, minWidth: "0" }}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </Stack>
    </ListItem>
  );
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onclick: PropTypes.func,
  selected: PropTypes.bool.isRequired
};

const GlobalDataModelConfiguration = ({ getSelectedConfig, locationState }) => {
  const location = useLocation();

  const practiceConfig = [
    {
      title: "SERVICE ITEMS",
      url: "/admin/gdm/serviceItems",
      text: "Service Items",
      icon: "/Assets/service.png"
    },
    { title: "STAGES", url: "/admin/gdm/stages", text: "Stages", icon: "/Assets/payment.png" },

    {
      title: "QUESTIONS",
      url: "/admin/gdm/questions",
      text: "Questions",
      icon: "/Assets/questions.png"
    },
    {
      title: "TREATMENT PLAN",
      url: "/admin/gdm/treatmentPlan",
      text: "Treatment Plan",
      icon: "/Assets/plans.png"
    },
    {
      title: "PROGRAM & MULTIPLIER",
      url: "/admin/gdm/program-multiplier",
      text: "Program",
      icon: "/Assets/program.png"
    },
    {
      title: "FEES SCHEDULE",
      url: "/admin/gdm/fees-schedule",
      text: "Fee Schedule",
      icon: "/Assets/program.png"
    },
    {
      title: "LOCATION PROGRAM DEFINITION",
      url: "/admin/gdm/location-program-definition",
      text: "Location Program Definition",
      icon: "/Assets/program.png"
    }
    // {
    //   title: "TREATMENT PLAN DETAILS",
    //   url: "/admin/gdm/treatmentPlanDetails",
    //   text: "Treatment Plan DETAILS",
    //   icon: "/Assets/plans.png"
    // }
  ];

  let selectedPath =
    practiceConfig.findIndex((item) => item.url === location.pathname) < 0
      ? 0
      : practiceConfig.findIndex((item) => item.url === location.pathname);
  const [selectedIndex, setSelectedIndex] = useState(selectedPath);
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };
  return (
    <Box>
      <List disablePadding>
        {practiceConfig.map(({ title, text, url, icon }, index) => (
          <ListItemLink
            key={index}
            locationState={locationState}
            selected={selectedIndex === index}
            onClick={() => {
              handleListItemClick(index);
              getSelectedConfig(text);
            }}
            to={url}
            primary={title}
            icon={<img src={icon} alt="" />}
          />
        ))}
      </List>
    </Box>
  );
};

export default GlobalDataModelConfiguration;
