import React, { useEffect, useState } from "react";
import { Button, Grid, Divider, Modal, Typography, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import EmailDocumentModal from "./modals/EmailDocumentModal";
import { Box, Stack } from "@mui/system";
import MessageModal from "../../shared/components/MessageModal";
import LoadingModal from "./modals/loadingModal";

const MedcaseEnroll = (props) => {
  const { actions, error, loading, success } = props;
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [isEmailDocumentOpen, setIsEmailDocumentOpen] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);

  useEffect(() => {
    if (error) {
      setIsMessageModal(true);
    }
    if (success) {
      setOpen(true);
    }
  }, [success, error]);

  useEffect(() => {
    actions.getProgramCycle({ medcaseId: props?.medcaseId });
  }, []);

  const closeModal = () => {
    props.close();
  };
  const saveEnroll = () => {
    let payload = {
      medcaseId: props.medcaseId
    };
    actions.editEnrollment({ payload });
    closeModal();
  };
  const handleClose = () => {
    setOpen(false);
    props.close();
  };
  const handleEmailDocumentOpen = () => {
    setOpen(false);
    actions.resetMessage();

    if (props.emailType === "PGTA_ENROLLMENT") {
      // setIsEmailDocumentOpen(true);

      props.close();
    } else {
      props.close();
    }

    //
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  return (
    <>
      <Modal
        open={props.openDialog}
        onClose={handleClose}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box className="modal-style-contact" style={{ maxHeight: "50vh" }} sx={{ width: "430px" }}>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography variant="h2">Enrollment</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ backgroundColor: "#177483" }} orientation="horizontal" />
          <Stack sx={{ marginTop: "15px", marginBottom: "5px" }}>
            <Grid xs={12} container className="modal-stackMedCase">
              <Grid item xs={5}>
                <Typography>Payment Status</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField disabled value={props.paymentStatus}></TextField>
              </Grid>
            </Grid>
          </Stack>
          <Stack sx={{ marginBottom: "5px" }}>
            <Grid container className="modal-stackMedCase">
              <Grid item xs={5}>
                <Typography>Contract Status</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField disabled value={props.contractStatus}></TextField>
              </Grid>
            </Grid>
          </Stack>
          <Stack sx={{ marginTop: "5px", marginBottom: "10px" }}>
            <Grid container className="modal-stackMedCase">
              <Grid item xs={5}>
                <Typography>
                  {props.medcaseStatus != null ? "Medcase Status" : "PGTA Status"}
                </Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField
                  disabled
                  value={
                    props.medcaseStatus != null ? props.medcaseStatus : props.pgtaStatus
                  }></TextField>
              </Grid>
            </Grid>
          </Stack>
          <Typography sx={{ marginTop: "15px", marginLeft: "7px" }}>
            Do you want to continue?
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant="contained" className="btn-primary" onClick={saveEnroll}>
              YES
            </Button>
            <Button variant="contained" className="btn-primary" onClick={handleClose}>
              NO
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box className="modal-style-contact" style={{ maxHeight: "30vh" }} sx={{ width: "430px" }}>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography variant="h2">Enrollment success</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ backgroundColor: "#177483" }} orientation="horizontal" />
          <Grid container className="modal-stackMedCase">
            <Stack>
              <Typography sx={{ margin: "10px", marginLeft: "0px" }}>
                Successful enrollment for medcase no:-{id}
              </Typography>
            </Stack>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant="contained" className="btn-primary" onClick={handleEmailDocumentOpen}>
              OK
            </Button>
          </Stack>
        </Box>
      </Modal>
      <MessageModal
        open={isMessageModal}
        message={error}
        error={error}
        closeModal={closeMessageModal}
      />

      {isEmailDocumentOpen ? (
        <EmailDocumentModal
          openDialog={isEmailDocumentOpen}
          close={() => setIsEmailDocumentOpen(false)}
          clinicalTreatmentNo={
            props.programCycle &&
            props.programCycle[props.programCycle.length - 1] &&
            props.programCycle[props.programCycle.length - 1].clinicalTreatmentNo
          }
          authorizedTreatment={
            props.programCycle &&
            props.programCycle[props.programCycle.length - 1] &&
            props.programCycle[props.programCycle.length - 1].authorizedTreatment
          }
          emailType={props.emailType}
        />
      ) : null}
      <LoadingModal open={loading} />
    </>
  );
};
const mapStateToProps = ({ enrollReducer, programCycleReducer }) => {
  return {
    success: enrollReducer.success,
    error: enrollReducer.error,
    loading: enrollReducer.loading,
    programCycle: programCycleReducer.programCycle
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    editEnrollment: (payload) => {
      dispatch(actionType.editEnrollment(payload));
    },
    getProgramCycle: (payload) => {
      dispatch(actionType.getProgramCycle(payload));
    },
    resetMessage: (payload) => {
      dispatch(actionType.resetMessage(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(MedcaseEnroll);
