import * as actionType from "../actions/ActionTypes";

const initialState = {
  // ListOfMessages: {}
  enableNavFlg: false
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LIST_OF_MESSAGES_REQUESTED: {
      return {
        ...state,
        ListOfMessagesLoading: true
      };
    }
    case actionType.LIST_OF_MESSAGES_SUCCESS: {
      if (action.payload.messageStatus === "Inbox") {
        return {
          ...state,
          ListOfMessages: action.payload.list,
          passedQueryParamters: action.payload.passedQueryParamters,
          ListOfMessagesLoading: false
        };
      } else if (action.payload.messageStatus === "Draft") {
        return {
          ...state,
          ListOfDraftMessages: action.payload.list,
          passedQueryParamters: action.payload.passedQueryParamters,
          ListOfMessagesLoading: false
        };
      } else if (action.payload.messageStatus === "Sent") {
        return {
          ...state,
          ListOfSentMessages: action.payload.list,
          passedQueryParamters: action.payload.passedQueryParamters,
          ListOfMessagesLoading: false
        };
      } else if (action.payload.messageStatus === "Deleted") {
        return {
          ...state,
          ListOfDeletedMessages: action.payload.list,
          passedQueryParamters: action.payload.passedQueryParamters,
          ListOfMessagesLoading: false
        };
      }
      break;
    }
    case actionType.LIST_OF_MESSAGES_FAILED: {
      return {
        ...state,
        error: action.message,
        ListOfMessagesLoading: false
      };
    }
    case actionType.CREATE_NEW_MESSAGE_REQUESTED: {
      return {
        ...state,
        sentMessageLoading: true
      };
    }
    case actionType.CREATE_NEW_MESSAGES_SUCCESS: {
      return {
        ...state,
        createMessage: action.payload,
        sentMessageLoading: false,
        successCreateMessage: action.successMsg
      };
    }
    case actionType.CREATE_NEW_MESSAGES_FAILED: {
      return {
        ...state,
        errorCreateMessage: action.errorMsg,
        sentMessageLoading: false
      };
    }
    case actionType.TOGGLE_MESSAGE_ISREAD_STATUS_REQUESTED: {
      return {
        ...state,
        toggleLoading: true
      };
    }
    case actionType.TOGGLE_MESSAGE_ISREAD_STATUS_SUCCESS: {
      return {
        ...state,
        toggleLoading: false,
        toggleSuccess: action.successMsg
      };
    }
    case actionType.TOGGLE_MESSAGE_ISREAD_STATUS_FAILED: {
      return {
        ...state,
        toggleError: action.errorMsg,
        toggleLoading: false
      };
    }
    case actionType.DELETE_MESSAGE_REQUESTED: {
      return {
        ...state,
        deletedMessageLoading: true
      };
    }
    case actionType.DELETE_MESSAGE_SUCCESS: {
      return {
        ...state,
        deletedMessage: action.payload,
        deletedMessageLoading: false,
        discardSuccess: action.successMsg
      };
    }
    case actionType.DELETE_MESSAGE_FAIL: {
      return {
        ...state,
        discardError: action.errorMsg,
        deletedMessageLoading: false
      };
    }

    case actionType.UPDATE_MULTIPLE_MESSAGES_REQUESTED: {
      return {
        ...state,
        updateMessagesLoading: true
      };
    }
    case actionType.UPDATE_MULTIPLE_MESSAGES_SUCCESS: {
      return {
        ...state,
        updateMessagesLoading: false,
        updatedSuccessMsg: action.updateSuccessMsg
      };
    }
    case actionType.UPDATE_MULTIPLE_MESSAGES_FAILED: {
      return {
        ...state,
        updatedErrorMsg: action.updateErrorMsg,
        updateMessagesLoading: false
      };
    }
    case actionType.USERS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.USERS_LIST_SUCCESS: {
      if (action.payload.primaryRole != "") {
        return {
          ...state,
          allUsersList: action.payload.usersList,
          loading: false
        };
      } else {
        return {
          ...state,
          assignToUsersList: action.payload.usersList,
          loading: false
        };
      }
    }
    case actionType.USERS_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.GET_FILE_REQUESTED: {
      return {
        ...state,
        fileLoading: true
      };
    }
    case actionType.GET_FILE_SUCCESS: {
      return {
        ...state,
        fileLoading: false,
        downloadedFileUrl: action.payload
      };
    }
    case actionType.GET_FILE_FAILED: {
      return {
        ...state,
        fileLoading: false
      };
    }
    case actionType.POST_FILE_REQUESTED: {
      return {
        ...state,
        postFileLoading: true
      };
    }
    case actionType.POST_FILE_SUCCESS: {
      return {
        ...state,
        postFileLoading: false,
        postedFilePath: action.payload
      };
    }
    case actionType.POST_FILE_FAILED: {
      return {
        ...state,
        postFileLoading: false
      };
    }
    case actionType.RESET_MESSAGES_EXECUTE_STATUS: {
      return {
        ...state,
        updatedSuccessMsg: null,
        errorCreateMessage: "",
        updatedErrorMsg: "",
        loading: false,
        postedFilePath: "",
        downloadedFileUrl: ""
      };
    }
    case actionType.ENABLE_FLAG_NAVBAR: {
      return {
        ...state,
        enableNavFlg: true
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default messageReducer;
