// payment timing list
export const PAYMENT_TIMING_LIST_REQUESTED = "PAYMENT_TIMING_LIST_REQUESTED";
export const PAYMENT_TIMING_LIST_SUCCESS = "PAYMENT_TIMING_LIST_SUCCESS";
export const PAYMENT_TIMING_LIST_FAILED = "PAYMENT_TIMING_LIST_FAILED";
export const RESET_PAYMENT_TIMING_LIST = "RESET_PAYMENT_TIMING_LIST";

// Add payment timing
export const ADD_PAYMENT_TIMING_REQUESTED = "ADD_PAYMENT_TIMING_REQUESTED";
export const ADD_PAYMENT_TIMING_SUCCESS = "ADD_PAYMENT_TIMING_SUCCESS";
export const ADD_PAYMENT_TIMING_FAILED = "ADD_PAYMENT_TIMING_FAILED";

// Edit payment timing
export const EDIT_PAYMENT_TIMING_REQUESTED = "EDIT_PAYMENT_TIMING_REQUESTED";
export const EDIT_PAYMENT_TIMING_SUCCESS = "EDIT_PAYMENT_TIMING_SUCCESS";
export const EDIT_PAYMENT_TIMING_FAILED = "EDIT_PAYMENT_TIMING_FAILED";

//Practices
export const PRACTICE_LIST_REQUESTED = "PRACTICE_LIST_REQUESTED";
export const PRACTICE_LIST_SUCCESS = "PRACTICE_LIST_SUCCESS";
export const PRACTICE_LIST_FAIL = "PRACTICE_LIST_FAIL";
export const RESET_PRACTICE_LIST = "RESET_PRACTICE_LIST";
export const SET_PRACTICE_ID = "SET_PRACTICE_ID";

//Add Practice
export const ADD_NEW_PRACTICE_REQUESTED = "ADD_NEW_PRACTICE_REQUESTED";
export const ADD_NEW_PRACTICE_SUCCESS = "ADD_NEW_PRACTICE_SUCCESS";
export const ADD_NEW_PRACTICE_FAILED = "ADD_NEW_PRACTICE_FAILED";

//Edit practice
export const EDIT_PRACTICE_REQUESTED = "EDIT_PRACTICE_REQUESTED";
export const EDIT_PRACTICE_SUCCESS = "EDIT_PRACTICE_SUCCESS";
export const EDIT_PRACTICE_FAILED = "EDIT_PRACTICE_FAILED";

//Locations
export const LOCATION_LIST_REQUESTED = "LOCATION_LIST_REQUESTED";
export const LOCATION_LIST_SUCCESS = "LOCATION_LIST_SUCCESS";
export const LOCATION_LIST_FAIL = "LOCATION_LIST_FAIL";
export const RESET_LOCATION_LIST = "RESET_LOCATION_LIST";
export const RESET_LOCATION_ROW = "RESET_LOCATION_ROW";

//Add Location
export const ADD_NEW_LOCATION_REQUESTED = "ADD_NEW_LOCATION_REQUESTED";
export const ADD_NEW_LOCATION_SUCCESS = "ADD_NEW_LOCATION_SUCCESS";
export const ADD_NEW_LOCATION_FAILED = "ADD_NEW_LOCATION_FAILED";

//Edit Location
export const EDIT_LOCATION_REQUESTED = "EDIT_LOCATION_REQUESTED";
export const EDIT_LOCATION_SUCCESS = "EDIT_LOCATION_SUCCESS";
export const EDIT_LOCATION_FAILED = "EDIT_LOCATION_FAILED";

// Location address list
export const ADDRESS_LIST_REQUESTED = "LOCATION_ADDRESS_LIST_REQUESTED";
export const ADDRESS_LIST_SUCCESS = "LOCATION_ADDRESS_LIST_SUCCESS";
export const ADDRESS_LIST_FAILED = "LOCATION_ADDRESS_LIST_FAILED";
export const LOCATION_ADDRESS_TIMING_LIST = "LOCATION_ADDRESS_TIMING_LIST";
export const RESET_ADDRESS_LIST = "RESET_ADDRESS_LIST";

//Add Location Address
export const ADD_ADDRESS_REQUESTED = "ADD_ADDRESS_REQUESTED";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILED = "ADD_ADDRESS_FAILED";

//Edit Location Address
export const EDIT_ADDRESS_REQUESTED = "EDIT_ADDRESS_REQUESTED";
export const EDIT_ADDRESS_SUCCESS = "EDIT_ADDRESS_SUCCESS";
export const EDIT_ADDRESS_FAILED = "EDIT_ADDRESS_FAILED";

//Physicians
export const PHYSICIAN_LIST_REQUESTED = "PHYSICIAN_LIST_REQUESTED";
export const PHYSICIAN_LIST_SUCCESS = "PHYSICIAN_LIST_SUCCESS";
export const PHYSICIAN_LIST_FAIL = "PHYSICIAN_LIST_FAIL";
export const RESET_PHYSICIAN_LIST = "RESET_PHYSICIAN_LIST";
export const RESET_PHYSICIAN_ROW = "RESET_PHYSICIAN_ROW";

//Add Physician
export const ADD_NEW_PHYSICIAN_REQUESTED = "ADD_NEW_PHYSICIAN_REQUESTED";
export const ADD_NEW_PHYSICIAN_SUCCESS = "ADD_NEW_PHYSICIAN_SUCCESS";
export const ADD_NEW_PHYSICIAN_FAILED = "ADD_NEW_PHYSICIAN_FAILED";

//Edit PHYSICIAN
export const EDIT_PHYSICIAN_REQUESTED = "EDIT_PHYSICIAN_REQUESTED";
export const EDIT_PHYSICIAN_SUCCESS = "EDIT_PHYSICIAN_SUCCESS";
export const EDIT_PHYSICIAN_FAILED = "EDIT_PHYSICIAN_FAILED";

//Email
export const EMAIL_LIST_REQUESTED = "EMAIL_LIST_REQUESTED";
export const EMAIL_LIST_SUCCESS = "EMAIL_LIST_SUCCESS";
export const EMAIL_LIST_FAIL = "EMAIL_LIST_FAIL";
export const RESET_EMAIL_LIST = "RESET_EMAIL_LIST";

//Add Email
export const ADD_EMAIL_REQUESTED = "ADD_EMAIL_REQUESTED";
export const ADD_EMAIL_SUCCESS = "ADD_EMAIL_SUCCESS";
export const ADD_EMAIL_FAILED = "ADD_EMAIL_FAILED";

//Edit Email
export const EDIT_EMAIL_REQUESTED = "EDIT_EMAIL_REQUESTED";
export const EDIT_EMAIL_SUCCESS = "EDIT_EMAIL_SUCCESS";
export const EDIT_EMAIL_FAILED = "EDIT_EMAIL_FAILED";
export const RESET_EMAIL = "RESET_EMAIL";

//ServiceItems
export const SERVICEITEMS_LIST_REQUESTED = "SERVICEITEMS_LIST_REQUESTED";
export const SERVICEITEMS_LIST_SUCCESS = "SERVICEITEMS_LIST_SUCCESS";
export const SERVICEITEMS_LIST_FAIL = "SERVICEITEMS_LIST_FAIL";
export const RESET_SERVICEITEMS_LIST = "RESET_SERVICEITEMS_LIST";
export const RESET_SERVICEITEMS = "RESET_SERVICEITEMS";

//Add ServiceItems
export const ADD_NEW_SERVICEITEMS_REQUESTED = "ADD_NEW_SERVICEITEMS_REQUESTED";
export const ADD_NEW_SERVICEITEMS_SUCCESS = "ADD_NEW_SERVICEITEMS_SUCCESS";
export const ADD_NEW_SERVICEITEMS_FAILED = "ADD_NEW_SERVICEITEMS_FAILED";

//Edit ServiceItems
export const EDIT_SERVICEITEMS_REQUESTED = "EDIT_SERVICEITEMS_REQUESTED";
export const EDIT_SERVICEITEMS_SUCCESS = "EDIT_SERVICEITEMS_SUCCESS";
export const EDIT_SERVICEITEMS_FAILED = "EDIT_SERVICEITEMS_FAILED";

//EXTERNAL SYSTEM DETAIL
export const EXTERNAL_SYSTEM_DETAIL_REQUESTED = "EXTERNAL_SYSTEM_DETAIL_REQUESTED";
export const EXTERNAL_SYSTEM_DETAIL_SUCCESS = "EXTERNAL_SYSTEM_DETAIL_SUCCESS";
export const EXTERNAL_SYSTEM_DETAIL_FAILED = "EXTERNAL_SYSTEM_DETAIL_FAILED";
export const EXTERNAL_SYSTEM_DETAIL_RESET = "EXTERNAL_SYSTEM_DETAIL_RESET";

//EXTERNAL ID
export const EXTERNAL_ID_LIST_REQUESTED = "EXTERNAL_ID_LIST_REQUESTED";
export const EXTERNAL_ID_LIST_SUCCESS = "EXTERNAL_ID_LIST_SUCCESS";
export const EXTERNAL_ID_LIST_FAILED = "EXTERNAL_ID_LIST_FAILED";

//ADD EXTERNAL ID
export const ADD_EXTERNAL_ID_LIST_REQUESTED = "ADD_EXTERNAL_ID_LIST_REQUESTED";
export const ADD_EXTERNAL_ID_LIST_SUCCESS = "ADD_EXTERNAL_ID_LIST_SUCCESS";
export const ADD_EXTERNAL_ID_LIST_FAILED = "ADD_EXTERNAL_ID_LIST_FAILED";

//EDIT EXTERNAL ID
export const EDIT_EXTERNAL_ID_LIST_REQUESTED = "EDIT_EXTERNAL_ID_LIST_REQUESTED";
export const EDIT_EXTERNAL_ID_LIST_SUCCESS = "EDIT_EXTERNAL_ID_LIST_SUCCESS";
export const EDIT_EXTERNAL_ID_LIST_FAILED = "EDIT_EXTERNAL_ID_LIST_FAILED";
export const RESET_EXTERNAL_ID_LIST = "RESET_EXTERNAL_ID_LIST";

//Email Doc
export const EMAIL_DOC_LIST_REQUESTED = "EMAIL_DOC_LIST_REQUESTED";
export const EMAIL_DOC_LIST_SUCCESS = "EMAIL_DOC_LIST_SUCCESS";
export const EMAIL_DOC_LIST_FAIL = "EMAIL_DOC_LIST_FAIL";
export const RESET_EMAIL_DOC = "RESET_EMAIL_DOC";
export const RESET_EMAIL_DOC_LIST = "RESET_EMAIL_DOC_LIST";

//Add EMAIL_DOC
export const ADD_EMAIL_DOC_REQUESTED = "ADD_EMAIL_DOC_REQUESTED";
export const ADD_EMAIL_DOC_SUCCESS = "ADD_EMAIL_DOC_SUCCESS";
export const ADD_EMAIL_DOC_FAILED = "ADD_EMAIL_DOC_FAILED";

//Edit EMAIL_DOC
export const EDIT_EMAIL_DOC_REQUESTED = "EDIT_EMAIL_DOC_REQUESTED";
export const EDIT_EMAIL_DOC_SUCCESS = "EDIT_EMAIL_DOC_SUCCESS";
export const EDIT_EMAIL_DOC_FAILED = "EDIT_EMAIL_DOC_FAILED";

//CONTACT
export const CONTACT_LIST_REQUESTED = "CONTACT_LIST_REQUESTED";
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";
export const CONTACT_LIST_FAILED = "CONTACT_LIST_FAILED";

//TreatmentPlan
export const TREATMENTPLAN_LIST_REQUESTED = "TREATMENTPLAN_LIST_REQUESTED";
export const TREATMENTPLAN_LIST_SUCCESS = "TREATMENTPLAN_LIST_SUCCESS";
export const TREATMENTPLAN_LIST_FAIL = "TREATMENTPLAN_LIST_FAIL";
export const RESET_TREATMENTPLAN_LIST = "RESET_TREATMENTPLAN_LIST";
export const RESET_TREATMENTPLAN = "RESET_TREATMENTPLAN";

//TreatmentPlanQuestions
export const TREATMENTPLANQUESTIONS_LIST_REQUESTED = "TREATMENTPLANQUESTIONS_LIST_REQUESTED";
export const TREATMENTPLANQUESTIONS_LIST_SUCCESS = "TREATMENTPLANQUESTIONS_LIST_SUCCESS";
export const TREATMENTPLANQUESTIONS_LIST_REFRESH = "TREATMENTPLANQUESTIONS_LIST_REFRESH";
export const TREATMENTPLANQUESTIONS_LIST_FAIL = "TREATMENTPLANQUESTIONS_LIST_FAIL";
export const RESET_TREATMENTPLANQUESTIONS_LIST = "RESET_TREATMENTPLANQUESTIONS_LIST";

//TreatmentPlanRoot
export const TREATMENTPLANROOT_LIST_REQUESTED = "TREATMENTPLANROOT_LIST_REQUESTED";
export const TREATMENTPLANROOT_LIST_SUCCESS = "TREATMENTPLANROOT_LIST_SUCCESS";
export const TREATMENTPLANROOT_LIST_FAIL = "TREATMENTPLANROOT_LIST_FAIL";
export const RESET_TREATMENTPLANROOT_LIST = "RESET_TREATMENTPLANROOT_LIST";

//TreatmentPlanAddQuestionOption
export const TREATMENTPLANADDQUESTIONOPTION_LIST_REQUESTED =
  "TREATMENTPLANADDQUESTIONOPTION_LIST_REQUESTED";
export const TREATMENTPLANADDQUESTIONOPTION_LIST_SUCCESS =
  "TREATMENTPLANADDQUESTIONOPTION_LIST_SUCCESS";
export const TREATMENTPLANADDQUESTIONOPTION_LIST_FAIL = "TREATMENTPLANADDQUESTIONOPTION_LIST_FAIL";
export const RESET_TREATMENTPLANADDQUESTIONOPTION_LIST =
  "RESET_TREATMENTPLANADDQUESTIONOPTION_LIST";

//TreatmentPlanQuestionSelection
export const TREATMENTPLANQUESTIONSELECTION_LIST_REQUESTED =
  "TREATMENTPLANQUESTIONSELECTION_LIST_REQUESTED";
export const TREATMENTPLANQUESTIONSELECTION_LIST_SUCCESS =
  "TREATMENTPLANQUESTIONSELECTION_LIST_SUCCESS";
export const TREATMENTPLANQUESTIONSELECTION_LIST_REFRESH =
  "TREATMENTPLANQUESTIONSELECTION_LIST_REFRESH";
export const TREATMENTPLANQUESTIONSELECTION_LIST_FAIL = "TREATMENTPLANQUESTIONSELECTION_LIST_FAIL";
export const RESET_TREATMENTPLANQUESTIONSELECTION_LIST = "TREATMENTPLANQUESTIONSELECTION_LIST";

//TreatmentPlanQuestionSelection
export const TREATMENTPLAN_ADD_ON_REQUESTED = "TREATMENTPLAN_ADD_ON_REQUESTED";
export const TREATMENTPLAN_ADD_ON_SUCCESS = "TREATMENTPLAN_ADD_ON_SUCCESS";
export const TREATMENTPLAN_ADD_ON_REFRESH = "TREATMENTPLAN_ADD_ON_REFRESH";
export const TREATMENTPLAN_ADD_ON_FAIL = "TREATMENTPLAN_ADD_ON_FAIL";
export const RESET_TREATMENTPLAN_ADD_ON_LIST = "TREATMENTPLAN_ADD_ON_LIST";

//TreatmentPlanQuestionSelection
export const TREATMENTPLAN_MODIFIER_REQUESTED = "TREATMENTPLAN_MODIFIER_REQUESTED";
export const TREATMENTPLAN_MODIFIER_SUCCESS = "TREATMENTPLAN_MODIFIER_SUCCESS";
export const TREATMENTPLAN_MODIFIER_REFRESH = "TREATMENTPLAN_MODIFIER_REFRESH";
export const TREATMENTPLAN_MODIFIER_FAIL = "TREATMENTPLAN_MODIFIER_FAIL";
export const RESET_TREATMENTPLAN_MODIFIER_LIST = "TREATMENTPLAN_MODIFIER_LIST";

//TreatmentPlanQuestionSelection
export const TREATMENTPLAN_MONITORING_REQUESTED = "TREATMENTPLAN_MONITORING_REQUESTED";
export const TREATMENTPLAN_MONITORING_SUCCESS = "TREATMENTPLAN_MONITORING_SUCCESS";
export const TREATMENTPLAN_MONITORING_REFRESH = "TREATMENTPLAN_MONITORING_REFRESH";
export const TREATMENTPLAN_MONITORING_FAIL = "TREATMENTPLAN_MONITORING_FAIL";
export const RESET_TREATMENTPLAN_MONITORING_LIST = "TREATMENTPLAN_MONITORING_LIST";

//TreatmentPlanQuestionSelection
export const TREATMENTPLAN_ASSIST_REQUESTED = "TREATMENTPLAN_ASSIST_REQUESTED";
export const TREATMENTPLAN_ASSIST_SUCCESS = "TREATMENTPLAN_ASSIST_SUCCESS";
export const TREATMENTPLAN_ASSIST_REFRESH = "TREATMENTPLAN_ASSIST_REFRESH";
export const TREATMENTPLAN_ASSIST_FAIL = "TREATMENTPLAN_ASSIST_FAIL";
export const RESET_TREATMENTPLAN_ASSIST_LIST = "TREATMENTPLAN_ASSIST_LIST";

//TreatmentPlanQuestionSelection
export const TREATMENTPLAN_AHM_REQUESTED = "TREATMENTPLAN_AHM_REQUESTED";
export const TREATMENTPLAN_AHM_SUCCESS = "TREATMENTPLAN_AHM_SUCCESS";
export const TREATMENTPLAN_AHM_REFRESH = "TREATMENTPLAN_AHM_REFRESH";
export const TREATMENTPLAN_AHM_FAIL = "TREATMENTPLAN_AHM_FAIL";
export const RESET_TREATMENTPLAN_AHM_LIST = "TREATMENTPLAN_AHM_LIST";

//deleteTreatmentPlan Questions
export const DELETE_TREATMENTPLANQUESIONS_REQUESTED = "DELETE_TREATMENTPLANQUESIONS_REQUESTED";
export const DELETE_TREATMENTPLANQUESIONS_SUCCESS = "DELETE_TREATMENTPLANQUESIONS_SUCCESS";
export const DELETE_TREATMENTPLANQUESIONS_FAILED = "DELETE_TREATMENTPLANQUESIONS_FAILED";

//Add TreatmentPlan
export const ADD_NEW_TREATMENTPLAN_REQUESTED = "ADD_NEW_TREATMENTPLAN_REQUESTED";
export const ADD_NEW_TREATMENTPLAN_SUCCESS = "ADD_NEW_TREATMENTPLAN_SUCCESS";
export const ADD_NEW_TREATMENTPLAN_FAILED = "ADD_NEW_TREATMENTPLAN_FAILED";

//Edit TreatmentPlan
export const EDIT_TREATMENTPLAN_REQUESTED = "EDIT_TREATMENTPLAN_REQUESTED";
export const EDIT_TREATMENTPLAN_SUCCESS = "EDIT_TREATMENTPLAN_SUCCESS";
export const EDIT_TREATMENTPLAN_FAILED = "EDIT_TREATMENTPLAN_FAILED";

//Edit TreatmentPlan
export const UPSERT_TREATMENTPLAN_REQUESTED = "UPSERT_TREATMENTPLAN_REQUESTED";
export const UPSERT_TREATMENTPLAN_SUCCESS = "UPSERT_TREATMENTPLAN_SUCCESS";
export const UPSERT_TREATMENTPLAN_FAILED = "UPSERT_TREATMENTPLAN_FAILED";

//Delete TreatmentPlan
export const DELETE_TREATMENTPLAN_REQUESTED = "DELETE_TREATMENTPLAN_REQUESTED";
export const DELETE_TREATMENTPLAN_SUCCESS = "DELETE_TREATMENTPLAN_SUCCESS";
export const DELETE_TREATMENTPLAN_FAILED = "DELETE_TREATMENTPLAN_FAILED";

//ADD Contact
export const ADD_CONTACT_LIST_REQUESTED = "ADD_CONTACT_LIST_REQUESTED";
export const ADD_CONTACT_LIST_SUCCESS = "ADD_CONTACT_LIST_SUCCESS";
export const ADD_CONTACT_LIST_FAILED = "ADD_CONTACT_LIST_FAILED";

//EDIT Contact
export const EDIT_CONTACT_LIST_REQUESTED = "EDIT_CONTACT_LIST_REQUESTED";
export const EDIT_CONTACT_LIST_SUCCESS = "EDIT_CONTACT_LIST_SUCCESS";
export const EDIT_CONTACT_LIST_FAILED = "EDIT_CONTACT_LIST_FAILED";
export const RESET_CONTACT_LIST = "RESET_CONTACT_LIST";
export const RESET_CONTACT_ROWS = "RESET_CONTACT_ROWS";

//CONTACT PHONE DATA
export const CONTACT_PHONE_DATA_REQUESTED = "CONTACT_PHONE_DATA_REQUESTED";
export const CONTACT_PHONE_DATA_SUCCESS = "CONTACT_PHONE_DATA_SUCCESS";
export const CONTACT_PHONE_DATA_FAIL = "CONTACT_PHONE_DATA_FAIL";

//CONTACT PHONE DATA
export const CONTACT_EMAIL_DATA_REQUESTED = "CONTACT_EMAIL_DATA_REQUESTED";
export const CONTACT_EMAIL_DATA_SUCCESS = "CONTACT_EMAIL_DATA_SUCCESS";
export const CONTACT_EMAIL_DATA_FAIL = "CONTACT_EMAIL_DATA_FAIL";
export const RESET_PHONE_DATA = "RESET_PHONE_DATA";

//Stages
export const STAGE_LIST_REQUESTED = "STAGE_LIST_REQUESTED";
export const STAGE_LIST_SUCCESS = "STAGE_LIST_SUCCESS";
export const STAGE_LIST_FAIL = "STAGE_LIST_FAIL";
export const RESET_STAGE_LIST = "RESET_STAGE_LIST";
export const RESET_STAGES = "RESET_STAGES";

export const STAGE_LIST_REQUESTED_NOT_MAPPED = "STAGE_LIST_REQUESTED_NOT_MAPPED";
export const STAGE_LIST_NOT_MAPPED_SUCCESS = "STAGE_LIST_NOT_MAPPED_SUCCESS";
export const STAGE_LIST_NOT_MAPPED_FAIL = "STAGE_LIST_NOT_MAPPED_FAIL";
export const RESET_STAGE_NOT_MAPPED_LIST = "RESET_STAGE_NOT_MAPPED_LIST";

//Add Stage
export const ADD_NEW_STAGE_REQUESTED = "ADD_NEW_STAGE_REQUESTED";
export const ADD_NEW_STAGE_SUCCESS = "ADD_NEW_STAGE_SUCCESS";
export const ADD_NEW_STAGE_FAILED = "ADD_NEW_STAGE_FAILED";

//Edit Stage
export const EDIT_STAGE_REQUESTED = "EDIT_STAGE_REQUESTED";
export const EDIT_STAGE_SUCCESS = "EDIT_STAGE_SUCCESS";
export const EDIT_STAGE_FAILED = "EDIT_STAGE_FAILED";

//Program List
export const PROGRAM_LIST_REQUESTED = "PROGRAM_LIST_REQUESTED";
export const PROGRAM_LIST_SUCCESS = "PROGRAM_LIST_SUCCESS";
export const PROGRAM_LIST_FAIL = "PROGRAM_LIST_FAIL";

//Add Program
export const ADD_PROGRAM_REQUESTED = "ADD_PROGRAM_REQUESTED";
export const ADD_PROGRAM_SUCCESS = "ADD_PROGRAM_SUCCESS";
export const ADD_PROGRAM_FAIL = "ADD_PROGRAM_FAIL";
export const RESET_PROGRAM = "RESET_PROGRAM";
export const RESET_PROGRAM_LIST = "RESET_PROGRAM_LIST";

//Edit Program
export const EDIT_PROGRAM_REQUESTED = "EDIT_PROGRAM_REQUESTED";
export const EDIT_PROGRAM_SUCCESS = "EDIT_PROGRAM_SUCCESS";
export const EDIT_PROGRAM_FAIL = "EDIT_PROGRAM_FAIL";

//Delete Program
export const DELETE_PROGRAM_REQUESTED = "DELETE_PROGRAM_REQUESTED";
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS";
export const DELETE_PROGRAM_FAIL = "DELETE_PROGRAM_FAIL";

//Estimate questions List
export const ESTIMATE_QUESTIONS_LIST_REQUESTED = "ESTIMATE_QUESTIONS_LIST_REQUESTED";
export const ESTIMATE_QUESTIONS_LIST_SUCCESS = "ESTIMATE_QUESTIONS_LIST_SUCCESS";
export const ESTIMATE_QUESTIONS_LIST_FAIL = "ESTIMATE_QUESTIONS_LIST_FAIL";
export const RESET_ESTIMATE_QUESTIONS = "RESET_ESTIMATE_QUESTIONS";

//Estimate questions List
export const SAVE_ESTIMATE_QUESTIONS_LIST_REQUESTED = "SAVE_ESTIMATE_QUESTIONS_LIST_REQUESTED";
export const SAVE_ESTIMATE_QUESTIONS_LIST_SUCCESS = "SAVE_ESTIMATE_QUESTIONS_LIST_SUCCESS";
export const SAVE_ESTIMATE_QUESTIONS_LIST_FAIL = "SAVE_ESTIMATE_QUESTIONS_LIST_FAIL";

export const RESET_MESSAGE = "RESET_MESSAGE";

//Global Data model
export const GDM_LIST_REQUESTED = "GDM_LIST_REQUESTED";
export const GDM_LIST_SUCCESS = "GDM_LIST_SUCCESS";
export const GDM_LIST_FAIL = "GDM_LIST_FAIL";
export const RESET_GDM_LIST = "RESET_GDM_LIST";
export const GDM_ROW = "GDM_ROW";
export const RESET_GDM_DATA = "RESET_GDM_DATA";

//Add Global Data model
export const ADD_NEW_GDM_REQUESTED = "ADD_NEW_GDM_REQUESTED";
export const ADD_NEW_GDM_SUCCESS = "ADD_NEW_GDM_SUCCESS";
export const ADD_NEW_GDM_FAILED = "ADD_NEW_GDM_FAILED";

//Edit Global Data model
export const EDIT_GDM_REQUESTED = "EDIT_GDM_REQUESTED";
export const EDIT_GDM_SUCCESS = "EDIT_GDM_SUCCESS";
export const EDIT_GDM_FAILED = "EDIT_GDM_FAILED";

//Add EXISTING Global Data model
export const ADD_NEW_EGDM_REQUESTED = "ADD_NEW_EGDM_REQUESTED";
export const ADD_NEW_EGDM_SUCCESS = "ADD_NEW_EGDM_SUCCESS";
export const ADD_NEW_EGDM_FAILED = "ADD_NEW_EGDM_FAILED";

//Physician Reimbursement Scale
export const PRS_LIST_REQUESTED = "PRS_LIST_REQUESTED";
export const PRS_LIST_SUCCESS = "PRS_LIST_SUCCESS";
export const PRS_LIST_FAIL = "PRS_LIST_FAIL";
export const RESET_PRS_LIST = "RESET_PRS_LIST";

//Add New Physician Reimbursement Scale
export const ADD_NEW_PRS_REQUESTED = "ADD_NEW_PRS_REQUESTED";
export const ADD_NEW_PRS_SUCCESS = "ADD_NEW_PRS_SUCCESS";
export const ADD_NEW_PRS_FAILED = "ADD_NEW_PRS_FAILED";

// stages list
export const STAGE_DETAIL_LIST_REQUESTED = "STAGE_DETAIL_LIST_REQUESTED";
export const STAGE_DETAIL_LIST_SUCCESS = "STAGE_DETAIL_LIST_SUCCESS";
export const STAGE_DETAIL_LIST_FAIL = "STAGE_DETAIL_LIST_FAIL";
export const RESET_SERVICE_ITEM_STAGES = "RESET_SERVICE_ITEM_STAGES";
export const RESET_SERVICE_ITEM_STAGES_LIST = "RESET_SERVICE_ITEM_STAGES_LIST";

// add
export const ADD_SERVICE_ITEM_STAGES_REQUESTED = "ADD_SERVICE_ITEM_STAGES_REQUESTED";
export const ADD_SERVICE_ITEM_STAGES_SUCCESS = "ADD_SERVICE_ITEM_STAGES_SUCCESS";
export const ADD_SERVICE_ITEM_STAGES_FAILED = "ADD_SERVICE_ITEM_STAGES_FAILED";

// edit
export const EDIT_SERVICE_ITEM_STAGES_REQUESTED = "EDIT_SERVICE_ITEM_STAGES_REQUESTED";
export const EDIT_SERVICE_ITEM_STAGES_SUCCESS = "EDIT_SERVICE_ITEM_STAGES_SUCCESS";
export const EDIT_SERVICE_ITEM_STAGES_FAILED = "EDIT_SERVICE_ITEM_STAGES_FAILED";

// delete
export const DELETE_SERVICE_ITEM_STAGES_REQUESTED = "DELETE_SERVICE_ITEM_STAGES_REQUESTED";
export const DELETE_SERVICE_ITEM_STAGES_SUCCESS = "DELETE_SERVICE_ITEM_STAGES_SUCCESS";
export const DELETE_SERVICE_ITEM_STAGES_FAILED = "DELETE_SERVICE_ITEM_STAGES_FAILED";

// Fee Schedule
export const FEE_SCHEDULE_LIST_REQUESTED = "FEE_SCHEDULE_LIST_REQUESTED";
export const FEE_SCHEDULE_LIST_SUCCESS = "FEE_SCHEDULE_LIST_SUCCESS";
export const FEE_SCHEDULE_LIST_FAIL = "FEE_SCHEDULE_LIST_FAIL";
export const RESET_FEE_SCHEDULE_LIST = "RESET_FEE_SCHEDULE_LIST";

export const FEE_SCHEDULE_DETAIL_REQUESTED = "FEE_SCHEDULE_LIST_REQUESTED";
export const FEE_SCHEDULE_DETAIL_SUCCESS = "FEE_SCHEDULE_LIST_SUCCESS";
export const FEE_SCHEDULE_DETAIL_FAIL = "FEE_SCHEDULE_LIST_FAIL";

export const FEE_SCHEDULE_UPDATE_REQUESTED = "FEE_SCHEDULE_UPDATE_REQUESTED";
export const FEE_SCHEDULE_UPDATE_SUCCESS = "FEE_SCHEDULE_UPDATE_SUCCESS";
export const FEE_SCHEDULE_UPDATE_FAIL = "FEE_SCHEDULE_UPDATE_FAIL";
//  get Stages Treatment plan
export const STAGE_INCLUDED_TREATMENTPLAN_REQUESTED = "STAGE_INCLUDED_TREATMENTPLAN_REQUESTED";
export const STAGE_INCLUDED_TREATMENTPLAN_SUCCESS = "STAGE_INCLUDED_TREATMENTPLAN_SUCCESS";
export const STAGE_INCLUDED_TREATMENTPLAN_FAILED = "STAGE_INCLUDED_TREATMENTPLAN_FAILED";

export const STAGE_NECESSARY_TREATMENTPLAN_REQUESTED = "STAGE_NECESSARY_TREATMENTPLAN_REQUESTED";
export const STAGE_NECESSARY_TREATMENTPLAN_SUCCESS = "STAGE_NECESSARY_TREATMENTPLAN_SUCCESS";
export const STAGE_NECESSARY_TREATMENTPLAN_FAILED = "STAGE_NECESSARY_TREATMENTPLAN_FAILED";

export const STAGE_OPTIONAL_TREATMENTPLAN_REQUESTED = "STAGE_OPTIONAL_TREATMENTPLAN_REQUESTED";
export const STAGE_OPTIONAL_TREATMENTPLAN_SUCCESS = "STAGE_OPTIONAL_TREATMENTPLAN_SUCCESS";
export const STAGE_OPTIONAL_TREATMENTPLAN_FAILED = "STAGE_OPTIONAL_TREATMENTPLAN_FAILED";

// Add Stages treatment plan
export const ADD_STAGES_TREATMENTPLAN_REQUESTED = "ADD_STAGES_TREATMENTPLAN_REQUESTED";
export const ADD_STAGES_TREATMENTPLAN_SUCCESS = "ADD_STAGES_TREATMENTPLAN_SUCCESS";
export const ADD_STAGES_TREATMENTPLAN_FAILED = "ADD_STAGES_TREATMENTPLAN_FAILED";

// Edit Stages treatment plan
export const EDIT_STAGES_TREATMENTPLAN_REQUESTED = "EDIT_STAGES_TREATMENTPLAN_REQUESTED";
export const EDIT_STAGES_TREATMENTPLAN_SUCCESS = "EDIT_STAGES_TREATMENTPLAN_SUCCESS";
export const EDIT_STAGES_TREATMENTPLAN_FAILED = "EDIT_STAGES_TREATMENTPLAN_FAILED";

// Edit Stages treatment plan
export const DELETE_STAGES_TREATMENTPLAN_REQUESTED = "DELETE_STAGES_TREATMENTPLAN_REQUESTED";
export const DELETE_STAGES_TREATMENTPLAN_SUCCESS = "DELETE_STAGES_TREATMENTPLAN_SUCCESS";
export const DELETE_STAGES_TREATMENTPLAN_FAILED = "DELETE_STAGES_TREATMENTPLAN_FAILED";

//Location Program Definition
export const LOCATION_PROGRAM_LIST_REQUESTED = "LOCATION_PROGRAM_LIST_REQUESTED";
export const LOCATION_PROGRAM_LIST_SUCCESS = "LOCATION_PROGRAM_LIST_SUCCESS";
export const LOCATION_PROGRAM_LIST_FAIL = "LOCATION_PROGRAM_LIST_FAIL";
export const RESET_LOCATION_PROGRAM_DEF = "RESET_LOCATION_PROGRAM_DEF";

//Multiplier List
export const MULTIPLIER_LIST_REQUESTED = "MULTIPLIER_LIST_REQUESTED";
export const MULTIPLIER_LIST_SUCCESS = "MULTIPLIER_LIST_SUCCESS";
export const MULTIPLIER_LIST_FAIL = "MULTIPLIER_LIST_FAIL";

//Add Multiplier
export const ADD_MULTIPLIER_REQUESTED = "ADD_MULTIPLIER_REQUESTED";
export const ADD_MULTIPLIER_SUCCESS = "ADD_MULTIPLIER_SUCCESS";
export const ADD_MULTIPLIER_FAIL = "ADD_MULTIPLIER_FAIL";
export const RESET_MULTIPLIER = "RESET_MULTIPLIER";
export const RESET_MULTIPLIER_LIST = "RESET_MULTIPLIER_LIST";

//Edit Multiplier
export const EDIT_MULTIPLIER_REQUESTED = "EDIT_MULTIPLIER_REQUESTED";
export const EDIT_MULTIPLIER_SUCCESS = "EDIT_MULTIPLIER_SUCCESS";
export const EDIT_MULTIPLIER_FAIL = "EDIT_MULTIPLIER_FAIL";

//Delete Multiplier
export const DELETE_MULTIPLIER_REQUESTED = "DELETE_MULTIPLIER_REQUESTED";
export const DELETE_MULTIPLIER_SUCCESS = "DELETE_MULTIPLIER_SUCCESS";
export const DELETE_MULTIPLIER_FAIL = "DELETE_MULTIPLIER_FAIL";

// add question treatment plan
export const ADD_QUESTION_TREATMENT_PLAN_REQUESTED = "ADD_QUESTION_TREATMENT_PLAN";
export const ADD_QUESTION_TREATMENT_PLAN_SUCCESS = "ADD_QUESTION_TREATMENT_PLAN_SUCCESS";
export const ADD_QUESTION_TREATMENT_PLAN_FAILED = "ADD_QUESTION_TREATMENT_PLAN_FAILED";

//RelativeValue
export const RELATIVEVALUE_LIST_REQUESTED = "RELATIVEVALUE_LIST_REQUESTED";
export const RELATIVEVALUE_LIST_SUCCESS = "RELATIVEVALUE_LIST_SUCCESS";
export const RELATIVEVALUE_LIST_FAIL = "RELATIVEVALUE_LIST_FAIL";
export const RESET_RELATIVEVALUE_LIST = "RESET_RELATIVEVALUE_LIST";
export const RESET_RELATIVEVALUE = "RESET_RELATIVEVALUE";

//Add RelativeValue
export const ADD_NEW_RELATIVEVALUE_REQUESTED = "ADD_NEW_RELATIVEVALUE_REQUESTED";
export const ADD_NEW_RELATIVEVALUE_SUCCESS = "ADD_NEW_RELATIVEVALUE_SUCCESS";
export const ADD_NEW_RELATIVEVALUE_FAILED = "ADD_NEW_RELATIVEVALUE_FAILED";

//Edit RelativeValue
export const EDIT_RELATIVEVALUE_REQUESTED = "EDIT_RELATIVEVALUE_REQUESTED";
export const EDIT_RELATIVEVALUE_SUCCESS = "EDIT_RELATIVEVALUE_SUCCESS";
export const EDIT_RELATIVEVALUE_FAILED = "EDIT_RELATIVEVALUE_FAILED";

//Delete RelativeValue
export const DELETE_RELATIVEVALUE_REQUESTED = "DELETE_RELATIVEVALUE_REQUESTED";
export const DELETE_RELATIVEVALUE_SUCCESS = "DELETE_RELATIVEVALUE_SUCCESS";
export const DELETE_RELATIVEVALUE_FAIL = "DELETE_RELATIVEVALUE_FAIL";

export const LOCATION_PROGRAM_UPDATE_REQUESTED = "LOCATION_PROGRAM_UPDATE_REQUESTED";
export const LOCATION_PROGRAM_UPDATE_SUCCESS = "LOCATION_PROGRAM_UPDATE_SUCCESS";
export const LOCATION_PROGRAM_UPDATE_FAIL = "LOCATION_PROGRAM_UPDATE_FAIL";

export const METADATA_REQUESTED = "METADATA_REQUESTED";
export const METADATA_SUCCESS = "METADATA_SUCCESS";
export const METADATA_FAIL = "METADATA_FAIL";

export const METADATA_RELATIVE_REQUESTED = "METADATA_RELATIVE_REQUESTED";
export const METADATA_RELATIVE_SUCCESS = "METADATA_RELATIVE_SUCCESS";
export const METADATA_RELATIVE_FAIL = "METADATA_RELATIVE_FAIL";

export const RELATIVE_TYPE_REQUESTED = "RELATIVE_TYPE_REQUESTED";
export const RELATIVE_TYPE_SUCCESS = "RELATIVE_TYPE_SUCCESS";
export const RELATIVE_TYPE_FAIL = "RELATIVE_TYPE_FAIL";

//PGTA Analysis
export const PGTA_LIST_REQUESTED = "PGTA_LIST_REQUESTED";
export const PGTA_LIST_SUCCESS = "PGTA_LIST_SUCCESS";
export const PGTA_LIST_FAIL = "PGTA_LIST_FAIL";
export const PGTA_LIST_RESET = "PGTA_LIST_RESET";

//Edit RelativeValue
export const EDIT_PGTA_REQUESTED = "EDIT_PGTA_REQUESTED";
export const EDIT_PGTA_SUCCESS = "EDIT_PGTA_SUCCESS";
export const EDIT_PGTA_FAILED = "EDIT_PGTA_FAILED";

//get boxlist
export const BOX_LIST_REQUESTED = "BOX_LIST_REQUESTED";
export const BOX_LIST_SUCCESS = "BOX_LIST_SUCCESS";
export const BOX_LIST_FAIL = "BOX_LIST_FAIL";
export const RESET_BOX_LIST = "RESET_BOX_LIST";
export const BOX_ROW = "BOX_ROW";
export const RESET_BOX_DATA = "RESET_BOX_DATA";

//create box
export const CREATE_BOX_REQUESTED = "CREATE_BOX_REQUESTED";
export const CREATE_BOX_SUCCESS = "CREATE_BOX_SUCCESS";
export const CREATE_BOX_FAILED = "CREATE_BOX_FAILED";

//delete box
export const DELETE_BOX_REQUESTED = "DELETE_BOX_REQUESTED";
export const DELETE_BOX_SUCCESS = "DELETE_BOX_SUCCESS";
export const DELETE_BOX_FAILED = "DELETE_BOX_FAILED";

export const PROGRAM_LIST_VISUALIZATION_REQUESTED = "PROGRAM_LIST_VISUALIZATION_REQUESTED";
export const PROGRAM_LIST_VISUALIZATION_SUCCESS = "PROGRAM_LIST_VISUALIZATION_SUCCESS";
export const PROGRAM_LIST_VISUALIZATION_FAIL = "PROGRAM_LIST_VISUALIZATION_FAIL";

//create program
export const CREATE_PROGRAM_REQUESTED = "CREATE_PROGRAM_REQUESTED";
export const CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS";
export const CREATE_PROGRAM_FAILED = "CREATE_PROGRAM_FAILED";

//save Refund age
export const SAVE_AGE_REQUESTED = "SAVE_AGE_REQUESTED";
export const SAVE_AGE_SUCCESS = "SAVE_AGE_SUCCESS";
export const SAVE_AGE_FAIL = "SAVE_AGE_FAIL";
export const SAVE_AGE_RESET = "SAVE_AGE_RESET";

//Delete program in box
export const DELETE_PROGRAM_IN_BOX_REQUESTED = "DELETE_PROGRAM_IN_BOX_REQUESTED";
export const DELETE_PROGRAM_IN_BOX_SUCCESS = "DELETE_PROGRAM_IN_BOX_SUCCESS";
export const DELETE_PROGRAM_IN_BOX_FAIL = "DELETE_PROGRAM_IN_BOX_SUCCESS";

//get refund age
export const GET_REFUNDAGE_REQUESTED = "GET_REFUNDAGE_REQUESTED";
export const GET_REFUNDAGE_SUCCESS = "GET_REFUNDAGE_SUCCESS";
export const GET_REFUNDAGE_FAIL = "GET_REFUNDAGE_FAIL";

//edit refund age
export const EDIT_REFUNDAGE_REQUESTED = "EDIT_REFUNDAGE_REQUESTED";
export const EDIT_REFUNDAGE_SUCCESS = "EDIT_REFUNDAGE_SUCCESS";
export const EDIT_REFUNDAGE_FAIL = "EDIT_REFUNDAGE_FAIL";

export const GET_PROGRAM_MULTIPLIER_REQUESTED = "GET_PROGRAM_MULTIPLIER_REQUESTED";
export const GET_PROGRAM_MULTIPLIER_SUCCESS = "GET_PROGRAM_MULTIPLIER_SUCCESS";
export const GET_PROGRAM_MULTIPLIER_FAIL = "GET_PROGRAM_MULTIPLIER_FAIL";

export const STAFFADMIN_LIST_REQUESTED = "STAFFADMIN_LIST_REQUESTED";
export const STAFFADMIN_LIST_SUCCESS = "STAFFADMIN_LIST_SUCCESS";
export const STAFFADMIN_LIST_FAIL = "STAFFADMIN_LIST_FAIL";
export const RESET_STAFFADMIN_LIST = "RESET_STAFFADMIN_LIST";

//User Tab Filters
export const USER_FILTER_DATA_REQUESTED = "USER_FILTER_DATA_REQUESTED";
export const USER_FILTER_DATA_SUCCESS = "USER_FILTER_DATA_SUCCESS";
export const USER_FILTER_DATA_FAIL = "USER_FILTER_DATA_FAIL";
export const RESET_USER_FILTER = "RESET_USER_FILTER";

export const PATIENT_PORTAL_ADMIN_LIST_REQUESTED = "PATIENT_PORTAL_ADMIN_LIST_REQUESTED";
export const PATIENT_PORTAL_ADMIN_LIST_SUCCESS = "PATIENT_PORTAL_ADMIN_LIST_SUCCESS";
export const PATIENT_PORTAL_ADMIN_LIST_FAIL = "PATIENT_PORTAL_ADMIN_LIST_FAIL";
export const RESET_PATIENT_PORTAL_ADMIN_LIST = "RESET_PATIENT_PORTAL_ADMIN_LIST";

//ContractMapping
export const CTM_LIST_REQUESTED = "CTM_LIST_REQUESTED";
export const CTM_LIST_SUCCESS = "CTM_LIST_SUCCESS";
export const CTM_LIST_FAIL = "CTM_LIST_FAIL";
export const RESET_CTM_LIST = "RESET_CTM_LIST";
export const RESET_CTM = "RESET_CTM";

//Add CTM
export const ADD_NEW_CTM_REQUESTED = "ADD_NEW_CTM_REQUESTED";
export const ADD_NEW_CTM_SUCCESS = "ADD_NEW_CTM_SUCCESS";
export const ADD_NEW_CTM_FAILED = "ADD_NEW_CTM_FAILED";

//Edit CTM
export const EDIT_CTM_REQUESTED = "EDIT_CTM_REQUESTED";
export const EDIT_CTM_SUCCESS = "EDIT_CTM_SUCCESS";
export const EDIT_CTM_FAILED = "EDIT_CTM_FAILED";

//Delete CTM
export const DELETE_CTM_REQUESTED = "DELETE_CTM_REQUESTED";
export const DELETE_CTM_SUCCESS = "DELETE_CTM_SUCCESS";
export const DELETE_CTM_FAIL = "DELETE_CTM_FAIL";
//Create new user
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const CREATE_NEW_USER_SUCCESS = "CREATE_NEW_USER_SUCCESS";
export const CREATE_NEW_USER_FAIL = "CREATE_NEW_USER_FAIL";
export const RESET_CREATE_NEW_USER = "RESET_CREATE_NEW_USER";

export const STAFF_ADMIN_USER_DETAILS_REQUESTED = "STAFF_ADMIN_USER_DETAILS_REQUESTED";
export const STAFF_ADMIN_USER_DETAILS_SUCCESS = "STAFF_ADMIN_USER_DETAILS_SUCCESS";
export const STAFF_ADMIN_USER_DETAILS_FAIL = "STAFF_ADMIN_USER_DETAILS_FAIL";

export const GET_SELECTED_USER_DETAILS = "GET_SELECTED_USER_DETAILS";
export const SET_EDIT_FLAG = "SET_EDIT_FLAG";

//Get Edit new user
export const GET_EDIT_USER_DETAILS = "GET_EDIT_USER_DETAILS";
export const RESET_GET_EDIT_USER_DETAILS = "RESET_GET_EDIT_USER_DETAILS";
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS";
export const EDIT_USER_FAIL = "EDIT_USER_FAIL";
export const RESET_EDIT_NEW_USER = "RESET_EDIT_NEW_USER";

//Saving Edit user details
export const SAVE_EDIT_USER_DETAILS = "SAVE_EDIT_USER_DETAILS";
export const SAVE_EDIT_USER_SUCCESS = "SAVE_EDIT_USER_SUCCESS";
export const SAVE_EDIT_USER_FAIL = "SAVE_EDIT_USER_FAIL";
export const RESET_SAVE_EDIT_NEW_USER = "RESET_SAVE_EDIT_NEW_USER";
export const RESET_STAFF_ADMIN_USER_DETAILS_LIST = "RESET_STAFF_ADMIN_USER_DETAILS_LIST";

export const SET_COGNITO_ID = "SET_COGNITO_ID";

export const AUDIT_LOGS_REQUESTED = "AUDIT_LOGS_REQUESTED";
export const AUDIT_LOGS_SUCCESS = "AUDIT_LOGS_SUCCESS";
export const AUDIT_LOGS_FAIL = "AUDIT_LOGS_FAIL";
export const RESET_AUDIT_LOGS_LIST = "RESET_AUDIT_LOGS_LIST";
export const ADD_IMPERSONATE_LOGS_REQUESTED = "ADD_IMPERSONATE_LOGS_REQUESTED";
export const ADD_IMPERSONATE_LOGS_SUCCESS = "ADD_IMPERSONATE_LOGS_SUCCESS";
export const ADD_IMPERSONATE_LOGS_FAIL = "ADD_IMPERSONATE_LOGS_FAIL";
export const IMPERSONATE_LOGS_SUCCESS = "IMPERSONATE_LOGS_SUCCESS";

//practice/id/programs
export const PROGRAMSDATA_LIST_REQUESTED = "PROGRAMSDATA_LIST_REQUESTED";
export const PROGRAMSDATA_LIST_SUCCESS = "PROGRAMSDATA_LIST_SUCCESS";
export const PROGRAMSDATA_LIST_FAIL = "PROGRAMSDATA_LIST_FAIL";

export const SET_EDIT_PERSON_DISABLE = "SET_EDIT_PERSON_DISABLE";
export const SET_EDIT_PERSON_DISABLE_SUCCESS = "SET_EDIT_PERSON_DISABLE_SUCCESS";
export const SET_EDIT_PERSON_DISABLE_FAIL = "SET_EDIT_PERSON_DISABLE_FAIL";
export const RESET_SET_EDIT_PERSON_DISABLE = "RESET_SET_EDIT_PERSON_DISABLE";
//templates
export const TEMPLATE_LIST_REQUESTED = "TEMPLATE_LIST_REQUESTED";
export const TEMPLATE_LIST_SUCCESS = "TEMPLATE_LIST_SUCCESS";
export const TEMPLATE_LIST_FAIL = "TEMPLATE_LIST_FAIL";
// Load from Excel
export const VALIDATE_EXCEL_REQUESTED = "VALIDATE_EXCEL_REQUESTED";
export const VALIDATE_EXCEL_SUCCESS = "VALIDATE_EXCEL_SUCCESS";
export const VALIDATE_EXCEL_FAIL = "VALIDATE_EXCEL_FAIL";
export const UPLOAD_EXCEL_REQUESTED = "UPLOAD_EXCEL_REQUESTED";
export const UPLOAD_EXCEL_SUCCESS = "UPLOAD_EXCEL_SUCCESS";
export const UPLOAD_EXCEL_FAIL = "UPLOAD_EXCEL_FAIL";
export const EMPTY_ERRORMSG_SUCCESS = "EMPTY_ERRORMSG_SUCCESS";
export const EMPTY_ERRORMSG_FAIL = "EMPTY_ERRORMSG_FAIL";
export const EMPTY_EXCEL_STATES_REQUESTED = "EMPTY_EXCEL_STATES_REQUESTED";
export const EMPTY_EXCEL_STATES_SUCCESS = "EMPTY_EXCEL_STATES_SUCCESS";
export const EMPTY_EXCEL_STATES_FAILED = "EMPTY_EXCEL_STATES_FAILED";

export const ENABLE_DISABLE_REQUESTED = "ENABLE_DISABLE_REQUESTED";
export const ENABLE_DISABLE_SUCCESS = "ENABLE_DISABLE_SUCCESS";
export const ENABLE_DISABLE_FAIL = "ENABLE_DISABLE_FAIL";

export const RESET_EMAIL_REQUESTED = "RESET_EMAIL_REQUESTED";
export const RESET_EMAIL_SUCCESS = "RESET_EMAIL_SUCCESS";
export const RESET_EMAIL_FAIL = "RESET_EMAIL_FAIL";
export const EMPTY_ERRORMSG_REQUESTED = "EMPTY_ERRORMSG_REQUESTED";

//Email Template
export const EMAIL_TEMPLATE_LIST_REQUESTED = "EMAIL_TEMPLATE_LIST_REQUESTED";
export const EMAIL_TEMPLATE_LIST_SUCCESS = "EMAIL_TEMPLATE_LIST_SUCCESS";
export const EMAIL_TEMPLATE_LIST_FAIL = "EMAIL_TEMPLATE_LIST_FAIL";
export const RESET_EMAIL_TEMPLATE_LIST = "RESET_EMAIL_TEMPLATE_LIST";
export const RESET_EMAIL_TEMPLATE = "RESET_EMAIL_TEMPLATE";
export const RESET_EMAIL_TEMPLATE_MESSAGE = "RESET_EMAIL_TEMPLATE_MESSAGE";

//Add Email Template
export const ADD_EMAIL_TEMPLATE_REQUESTED = "ADD_EMAIL_TEMPLATE_REQUESTED";
export const ADD_EMAIL_TEMPLATE_SUCCESS = "ADD_EMAIL_TEMPLATE_SUCCESS";
export const ADD_EMAIL_TEMPLATE_FAILED = "ADD_EMAIL_TEMPLATE_FAILED";

//Edit Email Template
export const EDIT_EMAIL_TEMPLATE_REQUESTED = "EDIT_EMAIL_TEMPLATE_REQUESTED";
export const EDIT_EMAIL_TEMPLATE_SUCCESS = "EDIT_EMAIL_TEMPLATE_SUCCESS";
export const EDIT_EMAIL_TEMPLATE_FAILED = "EDIT_EMAIL_TEMPLATE_FAILED";

//Delete Email Template
export const DELETE_EMAIL_TEMPLATE_REQUESTED = "DELETE_EMAIL_TEMPLATE_REQUESTED";
export const DELETE_EMAIL_TEMPLATE_SUCCESS = "DELETE_EMAIL_TEMPLATE_SUCCESS";
export const DELETE_EMAIL_TEMPLATE_FAIL = "DELETE_EMAIL_TEMPLATE_FAIL";

// Email Template Settings
export const EMAIL_TEMPLATE_SETTING_REQUESTED = "EMAIL_TEMPLATE_SETTING_REQUESTED";
export const EMAIL_TEMPLATE_SETTING_SUCCESS = "EMAIL_TEMPLATE_SETTING_SUCCESS";
export const EMAIL_TEMPLATE_SETTING_FAIL = "EMAIL_TEMPLATE_SETTING_FAIL";

//Email
export const CUST_AUTH_REQUESTED = "CUST_AUTH_REQUESTED";
export const CUST_AUTH_SUCCESS = "CUST_AUTH_SUCCESS";
export const CUST_AUTH_FAIL = "CUST_AUTH_FAIL";
export const RESET_CUST_AUTH = "RESET_CUST_AUTH";

// PGTA LABS
export const PGTA_LABS_LIST_REQUESTED = "PGTA_LABS_LIST_REQUESTED";
export const PGTA_LABS_LIST_SUCCESS = "PGTA_LABS_LIST_SUCCESS";
export const PGTA_LABS_LIST_FAIL = "PGTA_LABS_LIST_FAIL";
export const RESET_PGTA_LABS_LIST = "RESET_PGTA_LABS_LIST";
export const RESET_PGTA_LABS = "RESET_PGTA_LABS";
export const RESET_PGTA_LABS_MESSAGE = "RESET_PGTA_LABS_MESSAGE";

//Add PGTA LABS
export const ADD_PGTA_LABS_REQUESTED = "ADD_PGTA_LABS_REQUESTED";
export const ADD_PGTA_LABS_SUCCESS = "ADD_PGTA_LABS_SUCCESS";
export const ADD_PGTA_LABS_FAILED = "ADD_PGTA_LABS_FAILED";

//Edit PGTA LABS
export const EDIT_PGTA_LABS_REQUESTED = "EDIT_PGTA_LABS_REQUESTED";
export const EDIT_PGTA_LABS_SUCCESS = "EDIT_PGTA_LABS_SUCCESS";
export const EDIT_PGTA_LABS_FAILED = "EDIT_PGTA_LABS_FAILED";

//delete Users
export const DELETE_USERS_REQUESTED = "DELETE_USERS_REQUESTED";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_FAILED = "DELETE_USERS_FAILED";
export const DELETE_USER_RESET_MESSAGE = "DELETE_USER_RESET_MESSAGE";
