import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
// import * as token from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* genLabPayout(action) {
  const { payload } = action;
  const body = {
    labId: payload.labId,
    numberOfEmbryo: payload.numberOfEmbryo,
    amount: payload.amount,
    testSummary: payload.testSummary,
    testingEvent: payload.testingEvent,
    transactionDate: payload.transactionDate,
    invoiceDate: payload.invoiceDate,
    transactionType: payload.transactionType
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${payload.medcaseId}/LabPayouts`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_LAB_PAYOUT_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.ADD_LAB_PAYOUT_FAIL, errorMsg: error.message });
    }
  }
}

function* addLabPayoutSaga() {
  yield takeEvery(actionTypes.ADD_LAB_PAYOUT_REQUESTED, genLabPayout);
}

function* getManageLab() {
  try {
    const requestBody = createRequestBody("get", `/labs`, "", "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GET_MANAGE_LAB_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_MANAGE_LAB_FAIL, errorMsg: error.message });
    }
  }
}

function* getManageLabSaga() {
  yield takeEvery(actionTypes.GET_MANAGE_LAB_REQUESTED, getManageLab);
}

function* editManageLab(action) {
  const { payload } = action;
  const body = {
    labName: payload.labName,
    labAddress: payload.labAddress,
    city: payload.city,
    zip: payload.zip,
    state: payload.state,
    labPhone: payload.labPhone,
    labEmail: payload.labEmail,
    contactFName: payload.contactFName,
    contactLName: payload.contactLName,
    isActive: payload.isActive
  };
  try {
    const requestBody = createRequestBody("put", `/labs/${payload.labId}`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_MANAGE_LAB_SUCCESS,
      successMsg: MSG.SAVED_SUCCESSFULLY,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.EDIT_MANAGE_LAB_FAIL, error: error.message });
    }
  }
}

function* editManageLabSaga() {
  yield takeEvery(actionTypes.EDIT_MANAGE_LAB_REQUESTED, editManageLab);
}

function* addNewManageLab(action) {
  const { payload } = action;
  const body = {
    labName: payload.labName,
    labAddress: payload.labAddress,
    city: payload.city,
    zip: payload.zip,
    state: payload.state,
    labPhone: payload.labPhone,
    labEmail: payload.labEmail,
    contactFName: payload.contactFName,
    contactLName: payload.contactLName,
    isActive: payload.isActive
  };
  try {
    const requestBody = createRequestBody("post", `/labs`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_MANAGE_LAB_SUCCESS,
      payload: response.data,
      successMsg: "New lab information added successfully"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.ADD_MANAGE_LAB_FAIL, errorMsg: error.message });
    }
  }
}

function* addNewManageLabSaga() {
  yield takeEvery(actionTypes.ADD_MANAGE_LAB_REQUESTED, addNewManageLab);
}

export default function* genTestLabPayoutSaga() {
  yield all([addLabPayoutSaga(), getManageLabSaga(), editManageLabSaga(), addNewManageLabSaga()]);
}
