import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
// import * as token from "../../shared/constants/constants";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchfinancialSnapshotPaymentList(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/financialSnapshot/payments`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.FINANCIAL_SNAPSHOT_PAYMENT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.FINANCIAL_SNAPSHOT_PAYMENT_FAILED, errorMsg: error.message });
    }
  }
}

function* getfinancialSnapshotPaymentSaga() {
  yield takeEvery(
    actionTypes.FINANCIAL_SNAPSHOT_PAYMENT_REQUESTED,
    fetchfinancialSnapshotPaymentList
  );
}

function* updateManualReview(action) {
  const payload = action.payload;
  try {
    const params = {
      isManualReview: payload.isChecked
    };
    const requestBody = createRequestBody(
      "put",
      `/medcases/${payload.medcaseId}/financialSnapshot`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.UPDATE_MANUAL_REVIEW_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.UPDATE_MANUAL_REVIEW_FAILURE,
        message: error.message
      });
    }
  }
}

function* updateManualReviewSaga() {
  yield takeEvery(actionTypes.UPDATE_MANUAL_REVIEW_REQUESTED, updateManualReview);
}

function* updateClinicalReview(action) {
  const payload = action.payload;
  try {
    const params = {
      isClinicalReview: payload.isClinicalChecked
    };
    const requestBody = createRequestBody(
      "put",
      `/medcases/${payload.medcaseId}/financialSnapshot/clinical`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.UPDATE_CLINICAL_REVIEW_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.UPDATE_CLINICAL_REVIEW_FAILURE,
        message: error.message
      });
    }
  }
}

function* updateClinicalReviewSaga() {
  yield takeEvery(actionTypes.UPDATE_CLINICAL_REVIEW_REQUESTED, updateClinicalReview);
}
export default function* financialSnapshotPaymentSaga() {
  yield all([
    getfinancialSnapshotPaymentSaga(),
    updateManualReviewSaga(),
    updateClinicalReviewSaga()
  ]);
}
