import { Modal, Box, Stack, Typography, IconButton, Divider, Button } from "@mui/material";

const WarningPopUp = (props) => {
  const { open, closeModal, error } = props;
  return (
    <Modal open={open} backdrop="static" aria-labelledby="modal-add-title">
      <Box className="alert-modal">
        <Stack direction="row" className="modal-stack">
          <Typography>Alert</Typography>
          <IconButton onClick={closeModal}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Stack>
        <Divider className="modal-divider" />
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", py: 2 }}>
          {error && (
            <Typography variant="body2" color="#A6315C">
              {error}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
          <Button variant="contained" className="btn-primary" onClick={closeModal}>
            OK
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default WarningPopUp;
