import React, { useState } from "react";
import { Box, Stack, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Participant from "./Participant";
import Partner from "./Partner";

const PatientInfo = () => {
  const [tabValue, setTabValue] = useState("1");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      <Box flexDirection="row">
        <TabContext value={tabValue}>
          <Box>
            <Stack
              direction="row"
              sx={{ marginLeft: "8px", alignItems: "center", justifyContent: "space-between" }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Participant" value="1" />
                <Tab label="Partner" value="2" />
              </TabList>
            </Stack>
          </Box>
          <TabPanel value="1">
            <Participant />
          </TabPanel>
          <TabPanel value="2">{<Partner />}</TabPanel>
        </TabContext>
      </Box>
    </>
  );
};
export default PatientInfo;
