import { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import * as actionType from "../actions";
import CommonModal from "../../shared/components/CommonModal";
import { programConfig } from "../../shared/constants/renderConfigs";
import MessageModal from "../../shared/components/MessageModal";
import DeleteModal from "../../shared/components/DeleteModal";
import moment from "moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const Program = (props) => {
  let initialData = {};
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [programId, setProgramId] = useState("");
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const practiceId = sessionStorage.getItem("practiceId");
  const gdmId = sessionStorage.getItem("gdmId");
  const gdmRowData = useSelector((state) => state.globalDataModelReducer.gdmRowData);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const disabled = localStorage.getItem("status") !== "In-progress" ? true : false;

  const [values, setValues] = useState({
    programName: "",
    progShortName: "",
    noOfCycle: "",
    refund: "Refund",
    deptId: ""
  });
  const {
    actions,
    programList = [],
    addProgram,
    editProgram,
    deleteProgram,
    loading,
    toggleModal,
    program,
    successMsg,
    errorMsg
  } = props;

  const columns = [
    {
      field: "programId",
      headerName: "PROGRAM ID",
      width: 50,
      flex: 1
    },
    {
      field: "programName",
      headerName: "PROGRAM NAME",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "noOfCycle",
      headerName: "NO OF CYCLES",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "refund",
      headerName: "REFUND TYPE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => <>{row.refund ? "Refund" : "Non-Refund"}</>
    },
    { field: "deptId", headerName: "DEPT ID", width: 50, editable: false, flex: 1 },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row?.effectiveDate).format("L");
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              handleEditProgram(row);
            }}>
            <EditIcon color={disabled ? "disabled" : "primary"}></EditIcon>
          </IconButton>
          <IconButton
            disabled={disabled}
            onClick={() => {
              handleDeleteProgram(row);
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"}></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];

  useEffect(() => {
    if (toggleModal) {
      setValues((prevState) => ({
        ...prevState,
        ["programName"]: "",
        ["progShortName"]: "",
        ["noOfCycle"]: "",
        ["deptId"]: "",
        ["refund"]: "Refund"
      }));
      setIsAddModal(true);
      setIsOpenModal(true);
    }
  }, [toggleModal]);

  const initFetch = useCallback(() => {
    if (practiceId) {
      let payload = { practiceId, gdmId };
      actions.getPrograms(payload);
    }
  }, [addProgram, editProgram, deleteProgram]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (programList.length === 0 || addProgram || editProgram || deleteProgram) {
      initFetch();
    }

    return () => {
      actions.resetPrograms();
    };
  }, [addProgram, editProgram, deleteProgram, successMsg, errorMsg]);

  const rows = programList.map((item, index) => {
    item.effectiveDate = new Date(gdmRowData?.effectiveDate);
    item.id = index;
    return item;
  });

  const handleInputChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const closeModal = () => {
    program(false);
    setIsOpenModal(false);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const programs = programConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAddModal,
    disabled: values.deptId == "" || values.deptId == null ? true : false
  });

  const handleSaveProgram = (event) => {
    event.preventDefault();
    const { programName, progShortName, noOfCycle, refund, deptId } = values;

    if (isAddModal) {
      initialData = {
        programName: programName,
        progShortName: progShortName,
        noOfCycle: noOfCycle,
        refund: refund === "Refund" ? true : false,
        practiceId,
        gdmId,
        deptId
      };

      actions.addProgram(initialData);
    } else {
      initialData = {
        programName: programName,
        progShortName: progShortName,
        noOfCycle: noOfCycle,
        refund: refund === "Refund" ? true : false,
        practiceId: practiceId,
        programId: programId,
        gdmId,
        deptId
      };
      actions.editProgram(initialData);
    }

    closeModal();
  };

  const handleEditProgram = (row) => {
    setIsAddModal(false);
    setIsOpenModal(true);
    setValues((prevState) => ({
      ...prevState,
      ["programName"]: row.programName,
      ["progShortName"]: row.progShortName,
      ["noOfCycle"]: row.noOfCycle,
      ["deptId"]: row.deptId,
      ["refund"]: row.refund ? "Refund" : "Non-Refund"
    }));
    setProgramId(row.programId);
  };

  const handleDeleteProgram = (row) => {
    setDeleteModal(true);
    setProgramId(row.programId);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    initialData = {
      practiceId: practiceId,
      programId: programId,
      gdmId
    };
    actions.deleteProgram(initialData);
    closeDeleteModal();
  };

  return (
    <>
      <Box
        className={`${loading ? "grid-style disable-section" : "grid-style"}`}
        sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
        />
      </Box>
      <CommonModal open={isOpenModal} config={programs} handleSubmit={handleSaveProgram} />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <DeleteModal
        open={deleteModal}
        id={programId}
        close={closeDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};

const mapStateToProps = ({ programReducer }) => {
  return {
    programList: programReducer.programList,
    loading: programReducer.loading,
    addProgram: programReducer.addProgram,
    editProgram: programReducer.editProgram,
    deleteProgram: programReducer.deleteProgram,
    errorMsg: programReducer.error,
    successMsg: programReducer.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPrograms: (payload) => {
      dispatch(actionType.getPrograms(payload));
    },
    addProgram: (payload) => {
      dispatch(actionType.addProgram(payload));
    },
    editProgram: (payload) => {
      dispatch(actionType.editProgram(payload));
    },
    deleteProgram: (payload) => {
      dispatch(actionType.deleteProgram(payload));
    },
    resetPrograms: () => {
      dispatch(actionType.resetPrograms());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Program);
