import * as actionType from "../actions/ActionTypes";

const initialState = {
  emailList: [],
  addEmail: null,
  editEmail: null,
  loading: false,
  error: null,
  success: null
};

const emailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.EMAIL_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EMAIL_LIST_SUCCESS: {
      return {
        ...state,
        statusList: action.payload,
        loading: false
      };
    }
    case actionType.EMAIL_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_EMAIL_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_EMAIL_SUCCESS: {
      return {
        ...state,
        addEmail: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_EMAIL_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.RESET_EMAIL: {
      return {
        ...state,
        addAddress: null,
        loading: null
      };
    }
    // case actionType.RESET_MESSAGE: {
    //   return {
    //     ...state,
    //     error: null,
    //     success: false
    //   };
    // }
    case actionType.RESET_EMAIL_LIST: {
      return {
        ...state,
        emailList: [],
        loading: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default emailReducer;
