import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchBoxList(action) {
  const payload = action.payload;
  const { practiceId } = payload;

  try {
    // query params
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/${practiceId}/boxes`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.BOX_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.BOX_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getBoxesSaga() {
  yield takeEvery(actionTypes.BOX_LIST_REQUESTED, fetchBoxList);
}

function* createNewBox(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/boxes`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.CREATE_BOX_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.CREATE_BOX_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewBoxSaga() {
  yield takeEvery(actionTypes.CREATE_BOX_REQUESTED, createNewBox);
}

function* deleteBox(action) {
  const { payload } = action;
  const { practiceId, boxNumber } = payload;
  try {
    const requestBody = createRequestBody(
      "delete",
      `/practices/${practiceId}/boxes/${boxNumber}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.DELETE_BOX_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_BOX_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteBoxSaga() {
  yield takeEvery(actionTypes.DELETE_BOX_REQUESTED, deleteBox);
}

function* createNewProgram(action) {
  const { payload } = action;
  const { practiceId, programId, boxNumber } = payload;
  let t = new Date();
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/boxes/${boxNumber}/programs/${programId}`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.CREATE_PROGRAM_SUCCESS,
      payload: response.data + t,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.CREATE_PROGRAM_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewProgramSaga() {
  yield takeEvery(actionTypes.CREATE_PROGRAM_REQUESTED, createNewProgram);
}

function* fetchProgramsVisualization(action) {
  const { payload } = action;
  const { practiceId } = payload;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("get", `/practices/${practiceId}/programs`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.PROGRAM_LIST_VISUALIZATION_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PROGRAM_LIST_VISUALIZATION_FAIL, errorMsg: error.message });
    }
  }
}

function* getProgramsVisSaga() {
  yield takeEvery(actionTypes.PROGRAM_LIST_VISUALIZATION_REQUESTED, fetchProgramsVisualization);
}

function* deleteProgramInBox(action) {
  const { payload } = action;
  const { practiceId, boxNumber, programId } = payload;
  let t = new Date();
  try {
    const requestBody = createRequestBody(
      "delete",
      `/practices/${practiceId}/boxes/${boxNumber}/programs/${programId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.DELETE_PROGRAM_IN_BOX_SUCCESS,
      payload: response.data + t,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_PROGRAM_IN_BOX_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteProgramInBoxSaga() {
  yield takeEvery(actionTypes.DELETE_PROGRAM_IN_BOX_REQUESTED, deleteProgramInBox);
}
export default function* programVisualizationSaga() {
  yield all([
    getBoxesSaga(),
    addNewBoxSaga(),
    deleteBoxSaga(),
    addNewProgramSaga(),
    getProgramsVisSaga(),
    deleteProgramInBoxSaga()
  ]);
}
