import React, { useState } from "react";
import { Box, Tab, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MedcaseRevenue605 from "./MedcaseRevenue605";
import FinSnapshot606 from "./FinSnapshot606";
import PaymentRegister from "./PaymentRegister";
import FinSnapshot605All from "./FinSnapshot605All";
import ActiveParticipant from "./ActiveParticipant";
import InactiveParticipant from "./InactiveParticipant";
import RevenueValidation from "./RevenueValidation";
import OutcomeReport from "./OutcomeReport";
import EnrollmentReport from "./EnrollmentReport";

const Report = () => {
  const [tabValue, setTabValue] = useState("1");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box flexDirection="row">
      <TabContext value={tabValue}>
        <Box>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              sx={{ width: "100%" }}>
              <Tab sx={{ width: "11.1%" }} label="Medcase Revenue 605" value="1" />
              <Tab sx={{ width: "11.1%" }} label="Financial Snapshot 605 All" value="2" />
              <Tab sx={{ width: "11.1%" }} label="Financial Snapshot 606" value="3" />
              <Tab sx={{ width: "11.1%" }} label="Payment Register" value="4" />
              <Tab sx={{ width: "11.1%" }} label="Inactive Participants" value="5" />
              <Tab sx={{ width: "11.1%" }} label="Active Participants" value="6" />
              <Tab sx={{ width: "11.1%" }} label="Revenue Validation" value="7" />
              <Tab sx={{ width: "11.1%" }} label="Outcome" value="8" />
              <Tab sx={{ width: "11.10%" }} label="Enrollments" value="9" />
            </TabList>
          </Stack>
        </Box>
        <TabPanel value="1">{<MedcaseRevenue605 />}</TabPanel>
        <TabPanel value="2">{<FinSnapshot605All />}</TabPanel>
        <TabPanel value="3">{<FinSnapshot606 />}</TabPanel>
        <TabPanel value="4">{<PaymentRegister />}</TabPanel>
        <TabPanel value="5">{<InactiveParticipant />}</TabPanel>
        <TabPanel value="6">{<ActiveParticipant />}</TabPanel>
        <TabPanel value="7">{<RevenueValidation />}</TabPanel>
        <TabPanel value="8">{<OutcomeReport />}</TabPanel>
        <TabPanel value="9">{<EnrollmentReport />}</TabPanel>
      </TabContext>
    </Box>
  );
};

export default Report;
