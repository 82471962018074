import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";

const DateInputField = ({ label, mandatory, value, setValues, fieldName, validations }) => {
  return (
    <>
      <Stack direction="row" className="modal-stack">
        {label ? (
          <InputLabel required={mandatory} className="flex-2 word-break">
            {label}
          </InputLabel>
        ) : null}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disablePast={validations.disablePast}
            disableFuture={validations.disableFuture}
            size="small"
            openTo="day"
            value={value[fieldName]}
            onChange={(newValue) => {
              setValues({ ...value, [fieldName]: newValue });
            }}
            renderInput={(params) => (
              <TextField
                sx={{ flex: 6 }}
                {...params}
                size="small"
                className="patient-dropdown"
                fullWidth
              />
            )}></DatePicker>
        </LocalizationProvider>
      </Stack>
    </>
  );
};

export default DateInputField;
