import {
  Box,
  Typography,
  Stack,
  // MenuItem,
  // Select,
  Grid,
  Paper,
  AccordionDetails,
  TableContainer,
  Button,
  IconButton,
  Autocomplete,
  TextField
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Accordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import * as actionType from "../actions";
import ProgramVisualizationModal from "./modals/ProgramVisualizationModal";
import MessageModal from "../../shared/components/MessageModal";
import DeleteModal from "../../shared/components/DeleteModal";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const ProgramVisualization = (props) => {
  const columnsVis = [
    {
      field: "programId",
      headerName: "PROGRAM ID",
      width: 100,
      editable: true,
      flex: 0.5
    },
    {
      field: "programName",
      headerName: "PROGRAM NAME",
      width: 100,
      flex: 1
    },
    {
      field: "noOfCycle",
      headerName: "NO OF CYCLES",
      width: 100,
      flex: 1
    },
    {
      field: "refund",
      headerName: "REFUND TYPE",
      width: 50,
      editable: false,
      flex: 0.6
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.6,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            onClick={() => {
              setDeleteModal(true);
              setDeleteType("delProgram");
              setDeleteProgramData({
                practiceId: practiceId,
                boxNumber: row.boxNumber,
                programId: row.programId
              });
            }}>
            <DeleteIcon color="primary"></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];

  const practices = useSelector((state) => state.practices.practices);
  const accordionSx = { bgcolor: "#ededed", m: 2 };
  const [practiceId, setPracticeId] = useState("");
  const [addBoxOpen, setAddBoxOpen] = useState(false);
  const [addType, setAddType] = useState("");
  const [deleteType, setDeleteType] = useState("");
  const [boxNum, setBoxNum] = useState("");
  const [isMessageModalOpen, setIsMessageModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [delProgramData, setDeleteProgramData] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });

  const {
    actions,
    programList = [],
    boxList = [],
    addBox,
    deleteBox,
    addProgram,
    deleteProgram,
    loadingPractice,
    loading,
    successMsg,
    errorMsg
  } = props;
  const rows = boxList?.map((item, index) => {
    item.programs.map((item1, index1) => {
      item1.effectiveDate = new Date(item1.effectiveDate);
      item1.boxNumber = item.boxNumber;
      item1.id = index1;
      return item1;
    });
    item.id = index;
    return item;
  });

  useEffect(() => {
    if (practices.length > 0) {
      let payload = {};
      if (!practiceId) {
        setPracticeId(practices[0].practiceId);

        payload.practiceId = practices[0].practiceId;
      }
      if (practiceId) {
        payload.practiceId = practiceId == "" ? practices[0].practiceId : practiceId;
      }
      actions.getBoxes(payload);
      actions.getProgramsList(payload);
    }
  }, [practices, loadingPractice, deleteBox, addBox, addProgram, deleteProgram]);

  const handlePracticeChange = (value) => {
    if (value) {
      setPracticeId(value?.value);
      let payload = {};
      payload.practiceId = value?.value;
      actions.getBoxes(payload);
      actions.getProgramsList(payload);
    }
  };

  const handleAdd = (e, addTypeEvent, boxNumber) => {
    e.preventDefault();
    e.stopPropagation();
    setAddBoxOpen(true);
    setAddType(addTypeEvent);
    setBoxNum(boxNumber);
  };
  const handleDeleteBox = (e, delType, boxNumber) => {
    setDeleteModal(true);
    setDeleteType(delType);
    if (delType === "deleteBox") setBoxNum(boxNumber);
  };
  const initFetch = useCallback(() => {
    if (!practices.length) {
      actions.getPractices();
    }
    if (practiceId) {
      let payload = { practiceId };
      actions.getProgramsList(payload);
    }
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (practices.length === 0) {
      initFetch();
    }
    return () => {};
  }, [loading, errorMsg, successMsg, addBox, deleteBox, addProgram, deleteProgram]);

  const closeModalAddBox = () => {
    setAddBoxOpen(false);
  };
  const createBox = (data) => {
    setAddBoxOpen(false);
    let payload = {};
    if (addType === "addBox") {
      payload.practiceId = practiceId;
      payload.data = data;
      actions.addNewBox(payload);
    } else if (addType === "addProgram") {
      payload.practiceId = practiceId;
      payload.programId = data.programId; //data.programId;
      payload.boxNumber = data.boxNumber;
      actions.addNewProgram(payload);
    }
    actions.resetProgramVisualization();
  };

  const handleDelete = (e) => {
    e.preventDefault();
    let payload = {};
    payload.practiceId = practiceId;
    if (deleteType === "deleteBox") {
      payload.boxNumber = boxNum;
      actions.deleteBox(payload);
    } else if (deleteType === "delProgram") {
      payload = delProgramData;
      actions.deleteProgramInBox(payload);
    }
    actions.resetProgramVisualization();
    closeDeleteModal();
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetProgramVisualization();
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1)
    }
  }));
  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? "panel" + id : false);
  };
  return (
    <>
      <Box
        className={`${
          loading ? "stage-details-section m-10 disable-section" : "stage-details-section m-10"
        }`}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={6}>
            <Stack
              direction="row"
              sx={{
                my: 3,
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <Typography sx={{ flex: 2 }}>Practice</Typography>
              {/* <Select
                sx={{ flex: 7 }}
                fullWidth
                name="practiceId"
                value={practiceId}
                onChange={handlePracticeChange}
                className="stage-practice-dropdown">
                {practices.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.name} -{item.practiceId}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Autocomplete
                disablePortal
                sx={{ flex: 7 }}
                fullWidth
                name="practiceId"
                value={
                  practices.find((item) => item.practiceId === practiceId)
                    ? `${
                        practices.find((item) => item.practiceId === practiceId).name
                      } - ${practiceId}`
                    : ""
                }
                options={practices.map((item, index) => {
                  return {
                    id: index,
                    label: `${item.name} - ${item.practiceId}`,
                    name: "practiceId",
                    value: item.practiceId
                  };
                })}
                onChange={(e, value) => {
                  handlePracticeChange(value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." />}
                className="stage-practice-dropdown"
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              className="btn-primary  btn-edit-practice"
              onClick={(e) => handleAdd(e, "addBox", "0")}>
              Add Box
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={`${
          loading ? "stage-details-section m-10 disable-section" : "stage-details-section m-10"
        }`}>
        {rows.length > 0 ? (
          rows.map((ele, index) => (
            <>
              <Accordion
                expanded={expanded === "panel" + ele.boxNumber}
                onChange={handleChange(ele.boxNumber)}
                id={index}
                sx={accordionSx}
                key={ele.boxNumber}>
                <AccordionSummary
                  sx={{ flexDirection: "row-reverse", flexGrow: 0 }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <Stack direction="row" className="grid-header">
                    <Typography>
                      {index + 1}: {ele.boxName}({ele.programs.length})
                    </Typography>
                    <Stack spacing={2} direction="row" className="tab-panel-btnn">
                      <Button
                        variant="contained"
                        onClick={(e) => handleAdd(e, "addProgram", ele.boxNumber)}
                        className="btn-primary "
                        //disabled={programList?.length == 0}
                      >
                        Add Program
                      </Button>
                      <IconButton
                        className="btn-primary "
                        onClick={(e) => handleDeleteBox(e, "deleteBox", ele.boxNumber)}>
                        <DeleteIcon color="primary"></DeleteIcon>
                      </IconButton>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Box className="grid-styles" sx={{ height: "660px" }}>
                      <DataGridPremiumComp
                        rows={ele.programs}
                        rowCount={ele.programs.length}
                        // loading={loading}
                        columns={columnsVis}
                        pageSizeOptions={[10, 25, 50, 100]}
                        pageSize={pageSize.pageSize}
                        paginationModel={pageSize}
                        onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
                        pagination
                      />
                    </Box>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            </>
          ))
        ) : (
          <Typography>No Boxes found</Typography>
        )}
      </Box>
      <ProgramVisualizationModal
        open={addBoxOpen}
        closeModal={closeModalAddBox}
        data={programList}
        type={addType}
        boxNum={boxNum}
        createBox={createBox}
      />
      <MessageModal
        open={isMessageModalOpen}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <DeleteModal
        handleDelete={handleDelete}
        open={deleteModal}
        id={boxNum}
        close={closeDeleteModal}
      />
      {(loadingPractice || loading) && <LoadingSpinner />}
    </>
  );
};

const mapStateToProps = ({ practices, programVisualizationReducer }) => {
  return {
    loadingPractice: practices.loading,
    programList: programVisualizationReducer.programList,
    boxList: programVisualizationReducer.boxList,
    addBox: programVisualizationReducer.addBox,
    addProgram: programVisualizationReducer.addProgram,
    deleteBox: programVisualizationReducer.deleteBox,
    deleteProgram: programVisualizationReducer.deleteProgram,
    loading: programVisualizationReducer.loading,
    successMsg: programVisualizationReducer.success,
    errorMsg: programVisualizationReducer.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    getProgramsList: (payload) => {
      dispatch(actionType.getProgramsList(payload));
    },
    getBoxes: (payload) => {
      dispatch(actionType.getBoxes(payload));
    },
    addNewBox: (payload) => {
      dispatch(actionType.addNewBox(payload));
    },
    deleteBox: (payload) => {
      dispatch(actionType.deleteBox(payload));
    },
    addNewProgram: (payload) => {
      dispatch(actionType.addNewProgram(payload));
    },
    resetProgramVisualization: () => {
      dispatch(actionType.resetProgramVisualization());
    },
    deleteProgramInBox: (payload) => {
      dispatch(actionType.deleteProgramInBox(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ProgramVisualization);
