import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  Stack,
  Typography,
  CircularProgress
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Fragment, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import DropdownField from "../formFields/DropdownField";
import InputField from "../formFields/InputField";
import AmountField from "../formFields/AmountField";
import { connect, useSelector } from "react-redux";
import * as actionType from "../../actions";
import StageServiceItemList from "../../../shared/components/StageServiceItemList";
import {
  SUPPLEMENT_FORM,
  GLOBAL_DATA_FORM,
  LINE_ITEM_FORM
} from "../../../shared/constants/constants";

const CostCoverage = (props) => {
  const {
    isEdit,
    actions,
    stages,
    optional,
    necessary,
    addEstimate,
    loading,
    success,
    error,
    estimateId,
    addTreatment,
    costData,
    editTrtment,
    editTreatment
  } = props;

  console.log("props", props);

  const globalDiscountData = {
    type: {
      input_type: "dropdown",
      value: "",
      options: ["Professional Courtesy", "Promotional", "Other"]
    },
    item: {
      input_type: "dropdown",
      value: "All",
      options: ["All"]
    },
    amount: {
      input_type: "amount_field",
      amount_type: "USD",
      value: "",
      options: [
        {
          value: "USD",
          label: "$"
        },
        {
          value: "PERCENTAGE",
          label: "%"
        }
      ]
    },
    comment: {
      input_type: "input",
      value: ""
    }
  };
  const lineItemDiscountData = {
    type: {
      input_type: "dropdown",
      value: "",
      options: ["Professional Courtesy", "Promotional", "Other"]
    },
    item: {
      input_type: "dropdown",
      value: "",
      options: stages.map((item) => item.stageName)
    },
    amount: {
      input_type: "amount_field",
      amount_type: "USD",
      value: "",
      options: [
        {
          value: "USD",
          label: "$"
        }
      ]
    },
    comment: {
      input_type: "input",
      value: ""
    }
  };
  const supplementCostData = {
    type: {
      input_type: "dropdown",
      value: "",
      options: ["Collecting pass through fee", "Other Cost"]
    },
    amount: {
      input_type: "amount_field",
      amount_type: "USD",
      value: "",
      options: [
        {
          value: "USD",
          label: "$"
        }
      ]
    },
    comment: {
      input_type: "input",
      value: ""
    }
  };
  const practiceId = localStorage.getItem("practiceId");
  const [globalDiscount, setGlobalDiscount] = useState([]);
  const [lineItemDiscount, setLineItemDiscount] = useState([]);
  const [supplementCost, setSupplementCost] = useState([]);
  const [globalValues, setGlobalValues] = useState([]);
  const [lineItemValues, setLineItemValues] = useState([]);
  const [supplementValues, setSupplementValues] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const addAdjustments = useSelector((state) => state.costCoverageReducer.adjustmentList);
  const [loaderModal, setLoaderModal] = useState(false);
  const [estimateValues, setEstimateValues] = useState(addEstimate);
  const [globalAmountType, setGlobalAmountType] = useState("");
  const [deposit, setDeposit] = useState({
    type: (costData && costData[0] && costData[0].depositeType) || "Full Cycle Cost",
    amount: (costData && costData[0] && costData[0].depositeAmount) || 0,
    comment: (costData && costData[0] && costData[0].depositeComment) || ""
  });
  useEffect(() => {
    if (addTreatment.length) {
      setEstimateValues(addTreatment);
    }
    if (editTreatment.length) {
      setEstimateValues(editTreatment);
    }
  }, [addTreatment, editTreatment]);

  useEffect(() => {
    if (success || error) {
      setSuccessModal(true);
    }
  }, [error, success]);

  useEffect(() => {
    let payload;
    if (isEdit) {
      setEstimateValues(costData);
      if (addAdjustments.length) {
        let globalResponse = addAdjustments.filter((item) => item.category == "G");
        let liResponse = addAdjustments.filter((item) => item.category == "LI");
        let supplemntResponse = addAdjustments.filter((item) => item.category == null);

        let editedGlobal = [];
        let editedLineItem = [];
        let editedSupplement = [];
        let editedGlobalValues = [];
        let editedLineValues = [];
        let editedSupplementValues = [];

        // global  discount
        if (globalResponse.length > 0) {
          for (let i = 0; i < globalResponse.length; i++) {
            let { type, amount, comment, item } = globalDiscountData;
            let updateDiscount = {
              ...globalDiscountData,
              type: { ...type, value: globalResponse[i].type },
              item: { ...item, value: globalResponse[i].item },
              amount: {
                ...amount,
                value: globalResponse[i].amount,
                amount_type: globalResponse[i].amountType == "A" ? "USD" : "PERCENTAGE"
              },
              comment: { ...comment, value: globalResponse[i].comment }
            };

            editedGlobal.push(updateDiscount);

            let data = {
              type: globalResponse[i].type,
              item: globalResponse[i].item,
              amountType: globalResponse[i].amount_type == "USD" ? "A" : "P",
              amount: globalResponse[i].amount,
              comment: globalResponse[i].comment,
              id: globalResponse[i].id,
              category: "G"
            };
            editedGlobalValues.push(data);
          }
          setGlobalDiscount(editedGlobal);
          setGlobalValues(editedGlobalValues);
        }

        // line item discount
        if (liResponse.length > 0) {
          for (let i = 0; i < liResponse.length; i++) {
            let { type, amount, comment, item } = lineItemDiscountData;
            let updateDiscount = {
              ...lineItemDiscountData,
              type: { ...type, value: liResponse[i].type },
              item: { ...item, value: liResponse[i].item },
              amount: {
                ...amount,
                value: liResponse[i].amount,
                amount_type: "USD"
              },
              comment: { ...comment, value: liResponse[i].comment }
            };
            editedLineItem.push(updateDiscount);

            let data = {
              type: liResponse[i].type,
              item: liResponse[i].item,
              amountType: "A",
              amount: liResponse[i].amount,
              comment: liResponse[i].comment,
              id: liResponse[i].id,
              category: "LI"
            };
            editedLineValues.push(data);
          }
          setLineItemDiscount(editedLineItem);
          setLineItemValues(editedLineValues);
        }

        // supplement cost
        if (supplemntResponse.length > 0) {
          for (let i = 0; i < supplemntResponse.length; i++) {
            let { type, amount, comment } = supplementCostData;
            let updateDiscount = {
              ...supplementCostData,
              type: { ...type, value: supplemntResponse[i].type },
              amount: {
                ...amount,
                value: supplemntResponse[i].amount,
                amount_type: "USD"
              },
              comment: { ...comment, value: supplemntResponse[i].comment }
            };
            editedSupplement.push(updateDiscount);
            let data = {
              type: supplemntResponse[i].type,
              amountType: "A",
              amount: supplemntResponse[i].amount,
              comment: supplemntResponse[i].comment,
              id: supplemntResponse[i].id,
              category: "S"
            };
            editedSupplementValues.push(data);
          }
          setSupplementCost(editedSupplement);
          setSupplementValues(editedSupplementValues);
        }
      }
    } else {
      if (editTrtment) {
        payload = {
          practiceId,
          gdmId: editTreatment[0].gdmId,
          treatmentPlanId: editTreatment[0].treatmentPlanId
        };
      } else {
        console.log("addEstimate", addEstimate);
        console.log("addTreatment", addTreatment);
        let treatmentPlanId = "";
        if (addEstimate.length > 0) {
          treatmentPlanId = addEstimate[0].treatmentPlanId;
        }
        if (addEstimate.length > 0 && addTreatment.length > 0) {
          treatmentPlanId = addTreatment[0].treatmentPlanId;
        }
        console.log("treatmentPlanId", treatmentPlanId);
        payload = {
          practiceId,
          gdmId: addEstimate.length > 0 ? addEstimate[0].gdmId : addTreatment[0].gdmId,
          treatmentPlanId: treatmentPlanId
        };
      }

      actions.getStages(payload);
      actions.getNecessary(payload);
      actions.getOptional(payload);
    }
  }, []);

  useEffect(() => {
    if (loading) {
      setLoaderModal(true);
    } else {
      setLoaderModal(false);
    }
  }, [loading]);

  const globalDiscountChange = (e, index, i) => {
    let newFormValues = [...globalDiscount];
    Object.entries(newFormValues[i])[index][1].value = e.target.value;
    newFormValues[i].amount.amount_type =
      globalAmountType == "" ? newFormValues[i].amount.amount_type : globalAmountType;
    setGlobalDiscount(newFormValues);
    setGlobalValues((prevRows) =>
      prevRows.map((row, ind) =>
        ind == i
          ? {
              ...row,
              type: newFormValues[i].type.value,
              item: newFormValues[i].item.value,
              amountType: newFormValues[i].amount.amount_type == "USD" ? "A" : "P",
              amount: newFormValues[i].amount.value,
              comment: newFormValues[i].comment.value
            }
          : row
      )
    );
  };
  const lineItemChange = (e, index, i) => {
    let newFormValues = [...lineItemDiscount];
    Object.entries(newFormValues[i])[index][1].value = e.target.value.toString();
    setLineItemDiscount(newFormValues);
    setLineItemValues((prevRows) =>
      prevRows.map((row, ind) =>
        ind == i
          ? {
              ...row,
              type: newFormValues[i].type.value,
              item: newFormValues[i].item.value,
              amountType: "A",
              amount: newFormValues[i].amount.value,
              comment: newFormValues[i].comment.value
            }
          : row
      )
    );
  };
  const supplementCostChange = (e, index, i) => {
    let newFormValues = [...supplementCost];
    Object.entries(newFormValues[i])[index][1].value = e.target.value.toString();
    setSupplementCost(newFormValues);
    setSupplementValues((prevRows) =>
      prevRows.map((row, ind) =>
        ind == i
          ? {
              ...row,
              type: newFormValues[i].type.value,
              amountType: "A",
              amount: newFormValues[i].amount.value,
              comment: newFormValues[i].comment.value
            }
          : row
      )
    );
  };

  let removeGlobalDiscounts = (i) => {
    let newFormValues = [...globalDiscount];
    newFormValues.splice(i, 1);
    setGlobalDiscount(newFormValues);
    let removedGlobal = [...globalValues];
    removedGlobal.splice(i, 1);
    setGlobalValues(removedGlobal);
  };

  let removeLineItemDiscounts = (i) => {
    let newFormValues = [...lineItemDiscount];
    newFormValues.splice(i, 1);
    setLineItemDiscount(newFormValues);
    let removedLineItem = [...lineItemValues];
    removedLineItem.splice(i, 1);
    setLineItemValues(removedLineItem);
  };

  let removesupplementCost = (i) => {
    let newFormValues = [...supplementCost];
    newFormValues.splice(i, 1);
    setSupplementCost(newFormValues);
    let removedSupplmnt = [...supplementValues];
    removedSupplmnt.splice(i, 1);
    setSupplementValues(removedSupplmnt);
  };

  const addGlobalDiscount = () => {
    setGlobalDiscount([...globalDiscount, globalDiscountData]);
    let addGlobal = {
      ...GLOBAL_DATA_FORM
    };
    setGlobalValues([...globalValues, addGlobal]);
  };

  const addLineItemDiscount = () => {
    setLineItemDiscount([...lineItemDiscount, lineItemDiscountData]);
    let addLineItem = {
      ...LINE_ITEM_FORM
    };
    setLineItemValues([...lineItemValues, addLineItem]);
  };

  const addSupplementCost = () => {
    setSupplementCost([...supplementCost, supplementCostData]);
    let addSupplement = {
      ...SUPPLEMENT_FORM
    };
    setSupplementValues([...supplementValues, addSupplement]);
  };
  const handleClose = () => {
    setGlobalDiscount([]);
    setLineItemDiscount([]);
    setSupplementCost([]);
    props.close();
    let payload = {
      practiceId,
      estimateId
    };
    actions.getEstimateCard(payload);
    actions.resetCardClick();
  };

  const closeSuccessModal = () => {
    if (success) {
      handleClose();
    }
    setSuccessModal(false);
    actions.resetMessage();
    setGlobalAmountType("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("costData", costData);
    console.log("addEstimate", addEstimate);
    console.log("addTreatment", addTreatment);
    console.log("editTreatment", editTreatment);
    console.log("isEdit", isEdit);

    let estimateTpId = null;
    if (isEdit) {
      estimateTpId = costData[0].finEstimateTpId;
    }
    if (!isEdit && addEstimate && addEstimate.length > 0) {
      estimateTpId = addEstimate[0].finEstimateTpId;
    }
    if (!isEdit && addTreatment && addTreatment.length > 0) {
      estimateTpId = addTreatment[0].finEstimateTpId;
    }
    if (!isEdit && editTreatment && editTreatment.length > 0) {
      estimateTpId = editTreatment[0].finEstimateTpId;
    }

    let payload = {
      estimateId,
      // estimateTpId: isEdit
      //   ? costData[0].finEstimateTpId
      //   : addEstimate.length > 0
      //   ? addEstimate[0].finEstimateTpId
      //   : addTreatment.length > 0
      //   ? addTreatment[0].finEstimateTpId
      //   : editTreatment[0].finEstimateTpId,
      estimateTpId: estimateTpId,
      // values: globalValues.concat(lineItemValues, supplementValues),
      adjustmentValue: globalValues.concat(lineItemValues, supplementValues),
      deposit: {
        depositType: deposit.type,
        depositAmount: deposit.type === "Partial In Advance" ? deposit.amount : 0,
        depositComment: deposit.type === "Partial In Advance" ? deposit.comment : ""
      }
    };

    if (isEdit) {
      actions.updateAdjustment(payload);
    } else {
      actions.addAdjustments(payload);
    }
  };
  const fieldValue = (type, i) => {
    setGlobalAmountType(type);
    let newFormValues = [...globalDiscount];
    setGlobalValues((prevRows) =>
      prevRows.map((row, ind) =>
        ind == i
          ? {
              ...row,
              type: newFormValues[i].type.value,
              item: newFormValues[i].item.value,
              amountType: type == "USD" ? "A" : "P",
              amount: newFormValues[i].amount.value,
              comment: newFormValues[i].comment.value
            }
          : row
      )
    );
  };
  const renderGlobalDiscount = globalDiscount.map((value, i) => {
    return Object.entries(value).map(([key, item], index) => {
      if (item.input_type === "dropdown") {
        return (
          <Grid item xs={3} key={index} pr={1} py={1}>
            <Stack direction="column">
              <DropdownField
                key={key}
                value={item.value}
                onChange={(e) => globalDiscountChange(e, index, i)}
                options={item.options}
              />
            </Stack>
          </Grid>
        );
      }
      if (item.input_type === "amount_field") {
        return (
          <Grid item xs={3} key={index} py={1}>
            <Stack direction="column">
              <AmountField
                key={key}
                options={item.options}
                amountType={item.amount_type}
                fieldvalue={(data) => fieldValue(data, i)}
                value={item.value}
                onChange={(e) => globalDiscountChange(e, index, i)}
              />
            </Stack>
          </Grid>
        );
      }
      if (item.input_type === "input") {
        return (
          <Grid item xs={3} key={index} p={1}>
            <Stack direction="row">
              <InputField
                name={key}
                value={item.value}
                onChange={(e) => globalDiscountChange(e, index, i)}
              />
              <IconButton onClick={() => removeGlobalDiscounts(i)}>
                <DeleteIcon color="primary" />
              </IconButton>
            </Stack>
          </Grid>
        );
      }
    });
  });

  const renderLineItemDiscount = lineItemDiscount.map((value, i) => {
    return Object.entries(value).map(([key, item], index) => {
      if (item.input_type === "dropdown") {
        return (
          <Grid item xs={3} key={index} pr={1} py={1}>
            <Stack direction="column">
              <DropdownField
                required
                key={key}
                value={item.value}
                onChange={(e) => lineItemChange(e, index, i)}
                options={item.options}
              />
            </Stack>
          </Grid>
        );
      }
      if (item.input_type === "amount_field") {
        return (
          <Grid item xs={3} key={index} py={1}>
            <Stack direction="column">
              <AmountField
                required
                key={key}
                options={item.options}
                value={item.value}
                amountType={item.amount_type}
                onChange={(e) => lineItemChange(e, index, i)}
              />
            </Stack>
          </Grid>
        );
      }
      if (item.input_type === "input") {
        return (
          <Grid item xs={3} key={index} p={1}>
            <Stack direction="row">
              <InputField
                name={key}
                value={item.value}
                onChange={(e) => lineItemChange(e, index, i)}
              />
              <IconButton onClick={() => removeLineItemDiscounts(i)}>
                <DeleteIcon color="primary" />
              </IconButton>
            </Stack>
          </Grid>
        );
      }
    });
  });

  const renderSupplementCost = supplementCost.map((value, i) => {
    return Object.entries(value).map(([key, item], index) => {
      if (item.input_type === "dropdown") {
        return (
          <Grid item xs={4} key={index} pr={1} py={1}>
            <Stack direction="column">
              <DropdownField
                key={key}
                value={item.value}
                onChange={(e) => supplementCostChange(e, index, i)}
                options={item.options}
              />
            </Stack>
          </Grid>
        );
      }
      if (item.input_type === "amount_field") {
        return (
          <Grid item xs={4} key={index} p={1}>
            <Stack direction="column">
              <AmountField
                key={key}
                options={item.options}
                value={item.value}
                amountType={item.amount_type}
                onChange={(e) => supplementCostChange(e, index, i)}
              />
            </Stack>
          </Grid>
        );
      }
      if (item.input_type === "input") {
        return (
          <Grid item xs={4} key={index} p={1}>
            <Stack direction="row">
              <InputField
                name={key}
                value={item.value}
                onChange={(e) => supplementCostChange(e, index, i)}
              />
              <IconButton onClick={() => removesupplementCost(i)}>
                <DeleteIcon color="primary" />
              </IconButton>
            </Stack>
          </Grid>
        );
      }
    });
  });

  const headerGrid = (
    <Fragment>
      <Grid container>
        <Grid item xs={3}>
          <Stack direction="row" className="align-start">
            <InputLabel required> Type</InputLabel>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" className="align-start">
            <InputLabel required> Item</InputLabel>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" className="align-start">
            <InputLabel required> Amount</InputLabel>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="row" className="align-start">
            <InputLabel> Comment</InputLabel>
          </Stack>
        </Grid>
      </Grid>
    </Fragment>
  );

  const renderTreatmentPlanText = (data, item) => {
    if (isEdit) {
      if (item.tpLinkId === null) {
        if (item.assist) {
          return (
            <Typography component="span" variant="body2">
              Treatment Plan: {item.treatmentPlanName} + with Assist
            </Typography>
          );
        } else {
          return (
            <Typography component="span" variant="body2">
              Treatment Plan:{item.treatmentPlanName}
            </Typography>
          );
        }
      } else {
        if (item.assist) {
          return (
            <Typography component="span" variant="body2">
              Treatment Plan: {item.treatmentPlanName} + with Assist + PGTA Analysis
            </Typography>
          );
        } else {
          return (
            <Typography component="span" variant="body2">
              Treatment Plan: {item.treatmentPlanName} + PGTA Analysis
            </Typography>
          );
        }
      }
    } else {
      if (data.length !== 0) {
        if (data.length === 2 && data.map((item) => item.queResponseSum === 107)) {
          if (item.assist) {
            return (
              <Typography component="span" variant="body2">
                Treatment Plan: {data[0].treatmentPlanName} + with Assist + PGTA Analysis
              </Typography>
            );
          } else {
            return (
              <Typography component="span" variant="body2">
                Treatment Plan:{data[0].treatmentPlanName} + PGTA Analysis
              </Typography>
            );
          }
        } else {
          if (item.queResponseSum === 107) {
            if (item.assist) {
              return (
                <Typography component="span" variant="body2">
                  Treatment Plan: {item.treatmentPlanName} + with Assist
                </Typography>
              );
            } else {
              return (
                <Typography component="span" variant="body2">
                  Treatment Plan:{item.treatmentPlanName}
                </Typography>
              );
            }
          }
          if (item.queResponseSum !== 107) {
            if (item.assist) {
              return (
                <Typography component="span" variant="body2">
                  Treatment Plan: {item.treatmentPlanName} + with Assist
                </Typography>
              );
            } else {
              return (
                <Typography component="span" variant="body2">
                  Treatment Plan:{item.treatmentPlanName}
                </Typography>
              );
            }
          }
        }
      }
    }
  };

  const renderDeposit = () => {
    return (
      <>
        <Divider sx={{ color: "grey" }} />
        <Typography my={1} variant="body2">
          Deposits
        </Typography>
        <Grid container mb={1}>
          <Grid item xs={4} pl={1}>
            <Stack direction="column" className="align-start">
              <Typography mb={1}>Type </Typography>
              <DropdownField
                name="type"
                value={deposit.type}
                onChange={(e) => handleDepositInput(e)}
                options={["Full Cycle Cost", "Partial In Advance", "None"]}
              />
            </Stack>
          </Grid>
          {deposit.type === "Partial In Advance" ? (
            <>
              <Grid item xs={4} pl={1}>
                <Stack direction="column" className="align-start">
                  <Typography mb={1}>Amount </Typography>
                  <AmountField
                    name="amount"
                    value={deposit.amount}
                    onChange={(e) => handleDepositInput(e)}
                    options={[{ value: "USD", label: "$" }]}
                    amountType="USD"
                  />
                </Stack>
              </Grid>
              <Grid item xs={4} pl={1} pr={1}>
                <Stack direction="column" className="align-start">
                  <Typography mb={1}>Comment </Typography>
                  <InputField
                    name="comment"
                    value={deposit.comment}
                    onChange={(e) => handleDepositInput(e)}
                  />
                </Stack>
              </Grid>
            </>
          ) : null}
        </Grid>
      </>
    );
  };

  const renderTreatmentInformation = estimateValues.map((item, i) => {
    return (
      <Fragment key={i}>
        {/* treatment plan */}
        {i == 0 ? (
          <>
            <Stack my={1}>{renderTreatmentPlanText(estimateValues, item)}</Stack>
            <Grid container>
              <Grid item xs={6}>
                <Stack direction="column">
                  <Typography>Service </Typography>
                  <Typography>{item.treatmentPlanName}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="column">
                  <Typography>Out-of-pocket</Typography>
                  <Typography>
                    $ {item && item.tpPrice && item.tpPrice.toLocaleString("en")}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </>
        ) : null}
        {i == 0 ? (
          <Stack direction="column" py={1}>
            {stages.map((item, index) => {
              return <Typography key={index}>{item.stageName}</Typography>;
            })}
          </Stack>
        ) : null}

        {/* Adjustments */}
        {i == 0 ? (
          <>
            <Divider sx={{ color: "grey" }} />
            <Typography my={1} variant="body2">
              Adjustments
            </Typography>
            <Stack direction="column">
              <Stack>
                <Typography>Global Discounts</Typography>
              </Stack>
              {headerGrid}
              <Grid container>{renderGlobalDiscount}</Grid>

              <Stack direction="row" justifyContent="space-between">
                <Button
                  className="add-btn"
                  py={1}
                  onClick={addGlobalDiscount}
                  disabled={
                    item.treatmentPlanName.indexOf("PGTA Analysis") != -1 ||
                    globalDiscount.length >= 1
                  }>
                  <AddCircleOutlineIcon />
                  Add Global Discounts
                </Button>
              </Stack>
            </Stack>
            <Stack direction="column">
              <Stack py={1}>
                <Typography>Line Item Discounts</Typography>
              </Stack>
              {headerGrid}
              <Grid container>{renderLineItemDiscount}</Grid>
              <Stack direction="row" justifyContent="space-between">
                <Button
                  className="add-btn"
                  py={1}
                  onClick={addLineItemDiscount}
                  disabled={item.treatmentPlanName.indexOf("PGTA Analysis") != -1}>
                  <AddCircleOutlineIcon />
                  Add Line Item Discounts
                </Button>
              </Stack>
            </Stack>
            <Stack direction="column">
              <Stack py={1}>
                <Typography>Supplemental Cost</Typography>
              </Stack>
              <Grid container>
                <Grid item xs={4} pl={1}>
                  <Stack direction="row" className="align-start">
                    <InputLabel> Type</InputLabel>
                  </Stack>
                </Grid>

                <Grid item xs={4} pl={1}>
                  <Stack direction="row" className="align-start">
                    <InputLabel> Amount</InputLabel>
                  </Stack>
                </Grid>
                <Grid item xs={4} pl={1}>
                  <Stack direction="row" className="align-start">
                    <InputLabel>Comment</InputLabel>
                  </Stack>
                </Grid>
                {renderSupplementCost}
              </Grid>
              <Stack direction="row" justifyContent="space-between">
                <Button
                  className="add-btn"
                  onClick={addSupplementCost}
                  disabled={item.treatmentPlanName.indexOf("PGTA Analysis") != -1}>
                  <AddCircleOutlineIcon />
                  Add Supplemental Cost
                </Button>
              </Stack>
            </Stack>
            <Divider sx={{ color: "grey" }} />
            {/* Necessary */}
            <Typography my={1} variant="body2">
              Not Included But Necessary
            </Typography>
            {necessary.length ? <StageServiceItemList data={necessary} /> : null}

            <Divider sx={{ color: "grey" }} />
            {/* Optional */}
            <Typography my={1} variant="body2">
              Not Included But May Become Necessary
            </Typography>
            {optional.length ? <StageServiceItemList data={optional} /> : null}
          </>
        ) : null}

        {i == 1 ? (
          <>
            <Divider sx={{ color: "grey" }} />
            <Typography component="span" variant="body2">
              Additional Program: {item.treatmentPlanName}
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Stack direction="column">
                  <Typography>Service </Typography>
                  <Typography>PGTA Analysis</Typography>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack direction="column">
                  <Typography>Out-of-pocket</Typography>
                  <Typography>$ {item.tpPrice}</Typography>
                </Stack>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Fragment>
    );
  });

  const renderTreatmentInformationEdit =
    costData &&
    costData.map((item, i) => {
      return (
        <Fragment key={i}>
          {/* treatment plan */}
          <Stack my={1}>{renderTreatmentPlanText(costData, item)}</Stack>
          <Grid container>
            <Grid item xs={6}>
              <Stack direction="column">
                <Typography>Service </Typography>
                <Typography>{item.treatmentPlanName}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="column">
                <Typography>Out-of-pocket</Typography>
                <Typography>
                  $ {item && item.tpPrice && item.tpPrice.toLocaleString("en")}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Stack direction="column" py={1}>
            {stages.map((item, index) => {
              return <Typography key={index}>{item.stageName}</Typography>;
            })}
          </Stack>
          <Divider sx={{ color: "grey" }} />
          {/* Adjustments */}
          <>
            <Typography my={1} variant="body2">
              Adjustments
            </Typography>
            <Stack direction="column">
              <Stack>
                <Typography>Global Discounts</Typography>
              </Stack>
              {headerGrid}
              <Grid container>{renderGlobalDiscount}</Grid>

              <Stack direction="row" justifyContent="space-between">
                <Button
                  className="add-btn"
                  py={1}
                  onClick={addGlobalDiscount}
                  disabled={
                    item.treatmentPlanName.indexOf("PGTA Analysis") != -1 ||
                    globalDiscount.length >= 1
                  }>
                  <AddCircleOutlineIcon />
                  Add Global Discounts
                </Button>
              </Stack>
            </Stack>
            <Stack direction="column">
              <Stack py={1}>
                <Typography>Line Item Discounts</Typography>
              </Stack>
              {headerGrid}
              <Grid container>{renderLineItemDiscount}</Grid>
              <Stack direction="row" justifyContent="space-between">
                <Button
                  className="add-btn"
                  py={1}
                  onClick={addLineItemDiscount}
                  disabled={item.treatmentPlanName.indexOf("PGTA Analysis") != -1}>
                  <AddCircleOutlineIcon />
                  Add Line Item Discounts
                </Button>
              </Stack>
            </Stack>
            <Stack direction="column">
              <Stack py={1}>
                <Typography>Supplemental Cost</Typography>
              </Stack>
              <Grid container>
                <Grid item xs={4} pl={1}>
                  <Stack direction="row" className="align-start">
                    <InputLabel> Type</InputLabel>
                  </Stack>
                </Grid>

                <Grid item xs={4} pl={1}>
                  <Stack direction="row" className="align-start">
                    <InputLabel> Amount</InputLabel>
                  </Stack>
                </Grid>
                <Grid item xs={4} pl={1}>
                  <Stack direction="row" className="align-start">
                    <InputLabel>Comment</InputLabel>
                  </Stack>
                </Grid>
                {renderSupplementCost}
              </Grid>
              <Stack direction="row" justifyContent="space-between">
                <Button
                  className="add-btn"
                  onClick={addSupplementCost}
                  disabled={item.treatmentPlanName.indexOf("PGTA Analysis") != -1}>
                  <AddCircleOutlineIcon />
                  Add Supplemental Cost
                </Button>
              </Stack>
            </Stack>
            <Divider sx={{ color: "grey" }} />
            {/* Necessary */}
            <Typography my={1} variant="body2">
              Not Included But Necessary
            </Typography>
            {necessary.length ? <StageServiceItemList data={necessary} /> : null}

            <Divider sx={{ color: "grey" }} />
            {/* Optional */}
            <Typography my={1} variant="body2">
              Not Included But May Become Necessary
            </Typography>
            {optional.length ? <StageServiceItemList data={optional} /> : null}
          </>
          {isEdit ? <Divider sx={{ color: "grey" }} /> : null}
          {item.tpLinkId !== null ? (
            <>
              <Typography component="span" variant="body2">
                Additional Program: {item.linkedEstimateTreatmentPlan.treatmentPlanName}
              </Typography>
              <Grid container>
                <Grid item xs={6}>
                  <Stack direction="column">
                    <Typography>Service </Typography>
                    <Typography>PGTA Analysis</Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Stack direction="column">
                    <Typography>Out-of-pocket</Typography>
                    <Typography>$ {item.linkedEstimateTreatmentPlan.tpPrice}</Typography>
                  </Stack>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Fragment>
      );
    });

  const handleDepositInput = (e) => {
    const { name, value } = e.target;
    setDeposit({
      ...deposit,
      [name]: value
    });
  };

  return (
    <>
      <Modal open={props.open} backdrop="static" aria-labelledby="modal-cost-coverage">
        <Box component="form" onSubmit={handleSubmit} className="cost-coverage">
          <Stack direction="row" className="modal-stack">
            <Typography variant="body2">Edit Cost and Coverage</Typography>
            <IconButton onClick={handleClose}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />

          <Box className="treatment-type-container">
            {/* <Box className="treatment-type">
              {isEdit ? renderTreatmentInformationEdit : renderTreatmentInformation}
            </Box> */}
            {isEdit && costData ? (
              <Box className="treatment-type">
                {renderTreatmentInformationEdit}
                {renderDeposit()}
              </Box>
            ) : (
              <Box className="treatment-type">
                {renderTreatmentInformation}
                {renderDeposit()}
              </Box>
            )}
            <Stack direction="row" justifyContent="space-between" pt={2}>
              <Button variant="outlined" className="btn-primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" disabled={loading} className="btn-primary" type="submit">
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>

      {loaderModal ? (
        <Modal open={loaderModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal" width="100px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Modal>
      ) : null}
      {successModal ? (
        <Modal open={successModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal">
            <Stack direction="row" className="modal-stack">
              <Typography>Alert</Typography>
              <IconButton onClick={closeSuccessModal}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", py: 2 }}>
              {success && (
                <Typography variant="body2" color="#42ba96">
                  {success}
                </Typography>
              )}
              {error && (
                <Typography variant="body2" color="#A6315C">
                  {error}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
              <Button variant="contained" className="btn-primary" onClick={closeSuccessModal}>
                OK
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ estimateReducer, costCoverageReducer }) => {
  return {
    stages: costCoverageReducer.stages,
    necessary: costCoverageReducer.necessary,
    optional: costCoverageReducer.optional,
    loading: costCoverageReducer.loading,
    success: costCoverageReducer.success,
    error: costCoverageReducer.error,
    addEstimate: estimateReducer.addEstimate,
    estimateCards: estimateReducer.estimateCards,
    addTreatment: estimateReducer.addTreatment,
    editTrtment: estimateReducer.editTrtment,
    editTreatment: estimateReducer.editTreatment
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStages: (payload) => {
      dispatch(actionType.getStages(payload));
    },
    getNecessary: (payload) => {
      dispatch(actionType.getNecessary(payload));
    },
    getOptional: (payload) => {
      dispatch(actionType.getOptional(payload));
    },
    addAdjustments: (payload) => {
      dispatch(actionType.addAdjustments(payload));
    },
    updateAdjustment: (payload) => {
      dispatch(actionType.updateAdjustment(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    resetEditTreatment: () => {
      dispatch(actionType.resetEditTreatment());
    },
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    },
    resetCardClick: () => {
      dispatch(actionType.resetCardClick());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CostCoverage);
