import {
  Box,
  Button,
  Divider,
  IconButton,
  InputLabel,
  Modal,
  Stack,
  TextField,
  Typography
} from "@mui/material";

const StatusModal = (props) => {
  const { open, close, isActive, handleSubmit, handleInputChange } = props;

  return (
    <Modal open={open} aria-labelledby="modal-add-title" aria-describedby="modal-add-description">
      <Box
        className="modal-style-contact"
        style={{ minHeight: "32vh", maxWidth: "30vw", alignItems: "center" }}>
        <Stack direction="row" className="modal-stack">
          <Typography>Medcase Status Change</Typography>
          <IconButton onClick={close}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Stack>
        <Divider className="modal-divider" />
        <Stack direction="column" style={{ alignItems: "center" }}>
          <Typography variant="body1" sx={{ my: 2 }}>
            {isActive == "Active"
              ? "Do you want to Inactivate Medcase Status?"
              : "Do you want to Activate Medcase Status?"}
          </Typography>
          <Stack direction="row" sx={{ justifyContent: "flex-start", alignItems: "center" }}>
            <InputLabel sx={{ marginRight: "30px" }}>Reason*</InputLabel>
            <TextField
              name="reason"
              sx={{ maxWidth: "400px" }}
              onChange={handleInputChange}></TextField>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          className="modal-stack"
          sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <Button variant="outlined" sx={{ marginRight: "10px" }} onClick={close}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            {isActive == "Active" ? "Inactive" : "Active"}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default StatusModal;
