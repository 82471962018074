const data = [
  {
    financial_counselor: {
      displayName: "Financial Counselor",
      isDisplay: true,
      groupname_grouprights: [
        {
          group_name_display: "Felix",
          group_name_attr: "felix",
          group_rights: {
            pt: "Felix - Patients",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        },
        {
          group_name_display: "Admin portal (Felix)",
          group_name_attr: "adm_p_felix",
          group_rights: {
            ptpla: "Patient portal admin",
            //rtimpt: "Right to impersonate the patient portal of selected patient",
            sfa: "Staff admin"
            //dclb: "Document Library"
          }
        }
      ]
    },

    financial_counselor_lite: {
      displayName: "Financial Counselor(lite)",
      isDisplay: true,
      groupname_grouprights: [
        {
          group_name_display: "Felix(lite)",
          group_name_attr: "felix_light",
          group_rights: {
            pt: "Felix - Patients",
            adpt: "Add Patient manually",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        },
        {
          group_name_display: "Admin portal (Felix)",
          group_name_attr: "adm_p_felix",
          group_rights: {
            ptpla: "Patient portal admin",
            //rtimpt: "Right to impersonate the patient portal of selected patient",
            sfa: "Staff admin"
            //dclb: "Document Library"
          }
        }
      ]
    },
    felix_super_admin: {
      displayName: "Felix super admin",
      isDisplay: true,
      groupname_grouprights: [
        {
          group_name_display: "Felix",
          group_name_attr: "felix",
          group_rights: {
            pt: "Felix - Patients",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        },
        {
          group_name_display: "Admin portal (Felix)",
          group_name_attr: "adm_p_felix",
          group_rights: {
            ptpla: "Patient portal admin",
            //rtimpt: "Right to impersonate the patient portal of selected patient",
            sfa: "Staff admin"
            //dclb: "Document Library"
          }
        },
        {
          group_name_display: "Patient Portal",
          group_name_attr: "patient_p",
          group_rights: {
            srgptp: "Self registration for patient portal",
            fe: "Financial Estimate",
            pyb: "Pay your bill",
            dc: "Documents",
            me: "Messages",
            pf: "Profile"
          }
        }
      ]
    },
    trak_ops_manager: {
      displayName: "FA Ops Manager",
      isDisplay: true,
      groupname_grouprights: [
        {
          group_name_display: "Felix",
          group_name_attr: "felix",
          group_rights: {
            pt: "Felix - Patients",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        },
        {
          group_name_display: "Felix (light)",
          group_name_attr: "felix_light",
          group_rights: {
            pt: "Felix - Patients",
            adpt: "Add Patient manually",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        },
        {
          group_name_display: "Trak",
          group_name_attr: "trak",
          group_rights: {
            cl: "Case lookup",
            ct: "Contract",
            rfap: "Refund application",
            pvrm: "Physician Reimbursement",
            rn: "Revenue",
            rf: "Refund",
            rp: "Report",
            rqc: "Requested Cycles"
          }
        },
        {
          group_name_display: "Admin portal (Felix)",
          group_name_attr: "adm_p_felix",
          group_rights: {
            ptpla: "Patient portal admin",
            //rtimpt: "Right to impersonate the patient portal of selected patient",
            sfa: "Staff admin"
            //dclb: "Document Library"
          }
        }
      ]
    },
    trak_admin: {
      displayName: "FA OPERATIONS",
      isDisplay: true,
      groupname_grouprights: [
        {
          group_name_display: "Felix",
          group_name_attr: "felix",
          group_rights: {
            pt: "Felix - Patients",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        },
        {
          group_name_display: "Felix (light)",
          group_name_attr: "felix_light",
          group_rights: {
            pt: "Felix - Patients",
            adpt: "Add Patient manually",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        },
        {
          group_name_display: "Admin portal (Practice Configuration)",
          group_name_attr: "adm_p_pr_cfg",
          group_rights: {
            prpm: "Practice payment",
            pr: "Practice",
            gdm: "Global Data Model",
            pgv: "Program Visualization",
            rfagc: "Refund age configuration",
            phrmsc: "Physician Reimbursement scale",
            rlvltl: "Relative value table",
            pgtalc: "PGTA analysis configuration",
            contract: "Contract Template Mapping"
          }
        },
        {
          group_name_display: "Trak",
          group_name_attr: "trak",
          group_rights: {
            cl: "Case lookup",
            ct: "Contract",
            rfap: "Refund application",
            pvrm: "Physician Reimbursement",
            rn: "Revenue",
            rf: "Refund",
            rp: "Report",
            rqc: "Requested Cycles"
          }
        },
        {
          group_name_display: "Admin portal (Felix)",
          group_name_attr: "adm_p_felix",
          group_rights: {
            ptpla: "Patient portal admin",
            //rtimpt: "Right to impersonate the patient portal of selected patient",
            sfa: "Staff admin"
            //dclb: "Document Library"
          }
        }
      ]
    },
    practice_clinical_user: {
      displayName: "Practice/clinical user",
      isDisplay: true,
      groupname_grouprights: [
        {
          group_name_display: "Felix",
          group_name_attr: "felix",
          group_rights: {
            pt: "Felix - Patients",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        }
      ]
    },
    patient: {
      displayName: "Patient",
      isDisplay: true,
      groupname_grouprights: [
        {
          group_name_display: "Patient Portal",
          group_name_attr: "patient_p",
          group_rights: {
            srgptp: "Self registration for patient portal",
            fe: "Financial Estimate",
            pyb: "Pay your bill",
            dc: "Documents",
            me: "Messages",
            pf: "Profile"
          }
        }
      ]
    },
    super_user: {
      displayName: "Super User",
      isDisplay: true,
      groupname_grouprights: [
        {
          group_name_display: "Felix",
          group_name_attr: "felix",
          group_rights: {
            pt: "Felix - Patients",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        },
        {
          group_name_display: "Felix (light)",
          group_name_attr: "felix_light",
          group_rights: {
            pt: "Felix - Patients",
            adpt: "Add Patient manually",
            fe: "Felix - Workspace - Financial estimates",
            ftacpg: "Felix - Workspace - Fertility access program",
            spt: "Felix - Utility - Search patient",
            me: "Felix - Utility - Messages",
            pf: "Felix - Utility - Profile",
            nf: "Felix - Utility - Notification"
          }
        },
        {
          group_name_display: "Admin portal (Felix)",
          group_name_attr: "adm_p_felix",
          group_rights: {
            ptpla: "Patient portal admin",
            //rtimpt: "Right to impersonate the patient portal of selected patient",
            sfa: "Staff admin",
            //dclb: "Document Library"
            ptdle: "Delete Patient"
          }
        },
        {
          group_name_display: "Admin portal (Practice Configuration)",
          group_name_attr: "adm_p_pr_cfg",
          group_rights: {
            prpm: "Practice payment",
            pr: "Practice",
            gdm: "Global Data Model",
            pgv: "Program Visualization",
            rfagc: "Refund age configuration",
            phrmsc: "Physician Reimbursement scale",
            rlvltl: "Relative value table",
            pgtalc: "PGTA analysis configuration",
            contract: "Contract Template Mapping"
          }
        },
        {
          group_name_display: "Trak",
          group_name_attr: "trak",
          group_rights: {
            cl: "Case lookup",
            ct: "Contract",
            rfap: "Refund application",
            pvrm: "Physician Reimbursement",
            rn: "Revenue",
            rf: "Refund",
            rp: "Report",
            rqc: "Requested Cycles",
            std: "Status Delete",
            erep: "Enroll Email Permission",
            assy: "All Status Sync",
            eft: "Edit Financial Transaction",
            pnft: "Post Negative Financial Transaction"
          }
        },
        {
          group_name_display: "Patient Portal",
          group_name_attr: "patient_p",
          group_rights: {
            srgptp: "Self registration for patient portal",
            fe: "Financial Estimate",
            pyb: "Pay your bill",
            dc: "Documents",
            me: "Messages",
            pf: "Profile"
          }
        },
        {
          group_name_display: "Users",
          group_name_attr: "users",
          group_rights: {
            ucron: "User creation and onboarding"
          }
        },
        {
          group_name_display: "Admin portal (Felix)",
          group_name_attr: "adm_p_felix",
          group_rights: {
            ptpla: "Patient portal admin",
            //rtimpt: "Right to impersonate the patient portal of selected patient",
            sfa: "Staff admin"
            //dclb: "Document Library"
          }
        }
      ]
    }
  }
];

const userGroupFormat = [
  "custom:felix",
  "custom:felix_light",
  "custom:adm_p_felix",
  "custom:adm_p_pr_cfg",
  "custom:trak",
  "custom:patient_p",
  "custom:users"
];

export { userGroupFormat };
export default data;
