import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Box, IconButton, Button, Typography, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import * as actionType from "../actions";
import { practiceConfig } from "../../shared/constants/renderConfigs";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { isPhoneNumberValid } from "../../shared/constants/constants";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const Practice = (props) => {
  const columns = [
    {
      field: "practiceId",
      headerName: "ID",
      width: 50,
      flex: 0.5,
      sortable: false
    },
    {
      field: "name",
      headerName: "NAME",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Link
          to={row.practiceId}
          onClick={() => {
            if (practiceId !== row.practiceId) {
              actions.resetLocationRows();
              actions.resetPhysicianRows();
              actions.resetContactRows();
              actions.resetEmailList();
              actions.resetEmailDocumentRows();
            }
            actions.setPracticeId(row.practiceId);
          }}>
          {row.name}
        </Link>
      )
    },
    {
      field: "longName",
      headerName: "LONG NAME",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 100,
      editable: false,
      flex: 0.5,
      renderCell: ({ row }) => (
        <span style={{ color: row.status == "Active" ? "green" : "red" }}>{row.status}</span>
      )
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },
    {
      field: "vendorId",
      headerName: "VENDOR ID",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "accountNumber",
      headerName: "ACCOUNT NUMBER",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "projectId",
      headerName: "PROJECT ID",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "locationId",
      headerName: "LOCATION ID",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.5,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleEditPractice(row);
          }}>
          <EditIcon color="primary"></EditIcon>
        </IconButton>
      )
    }
  ];
  let practiceFormData = {
    name: "",
    longName: "",
    status: "Active",
    phoneNumber: "",
    website: "",
    externalFertPracticeId: "",
    externalNextgenPracticeId: "",
    fertLink: "",
    payYourBillLink: "",
    practiceLogo: "",
    practicePdfLogo: "",
    vendorId: "",
    accountNumber: "",
    projectId: "",
    locationId: ""
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isMessModal, setIsMessModal] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isValid, setIsValid] = useState(true);
  const [validationMsg, setValidationMsg] = useState("");
  const [values, setValues] = useState(practiceFormData);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const {
    practices = [],
    actions,
    addPractice,
    editPractice,
    errorMsg,
    successMsg,
    practiceId,
    loading
  } = props;
  const practiceList = practices;
  const rows = practiceList.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    actions.getPractices();
  }, [addPractice, editPractice]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (practices.length === 0 || addPractice || editPractice) {
      initFetch();
    }
    return () => {
      actions.resetPracticeList();
    };
  }, [addPractice, editPractice, errorMsg, successMsg]);

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (name == "phoneNumber" && !value.length) {
      setIsValid(true);
    }
    if (name == "phoneNumber" && value) {
      let res = isPhoneNumberValid(value);
      setIsValid(res.status);
      setValidationMsg(res.msg);
    }
    console.log("name", name);
    // Check if files are present
    if (files && files.length > 0) {
      const reader = new FileReader();

      const file = files[0];
      const fileType = file.type;
      const allowedTypes = ["image/png", "image/jpeg"];

      if (fileType !== "image/svg+xml" && name === "practiceLogo") {
        setSuccMsg("");
        setErrMsg("Please select an SVG file.");
        setIsMessModal(true);
        setIsOpen(false);
        return;
      } else if (!allowedTypes.includes(fileType) && name === "practicePdfLogo") {
        setSuccMsg("");
        setErrMsg("Please select an png/jpeg file.");
        setIsMessModal(true);
        setIsOpen(false);
        return;
      }

      let longName = name === "practiceLogo" ? "practiceLogo" : "practicePdfLogo";

      reader.onload = () => {
        let base64File = reader.result;

        base64File = base64File.split(",")[1];
        setValues({
          ...values,
          [longName]: base64File
        });
      };

      reader.readAsDataURL(files[0]);
    } else {
      setValues({
        ...values,
        [name]: value
      });
    }
  };

  const closeMessModal = () => {
    setIsMessModal(false);
    setSuccMsg("");
    setErrMsg("");
  };

  const addNewPractice = () => {
    setValues(practiceFormData);
    setIsAdd(true);
    setIsOpen(true);
  };

  const closeModal = () => {
    setValues(practiceFormData);
    setIsOpen(false);
    setIsValid(true);
  };

  const savePractice = (event) => {
    event.preventDefault();
    console.log("Event:", event);
    if (!isValid) {
      return;
    }
    values.phoneNumber = values.phoneNumber.length ? values.phoneNumber : null;
    values.website = values.website.length ? values.website : null;

    let externalArr = [];
    if (values.externalFertPracticeId) {
      let neObjFert = {
        externalPracticeId: values.externalFertPracticeId,
        externalSystemId: "Fert",
        op: isAdd ? "add" : "update"
      };
      externalArr.push(neObjFert);
    }
    if (values.externalNextgenPracticeId) {
      let neObjNext = {
        externalPracticeId: values.externalNextgenPracticeId,
        externalSystemId: "Nextgen",
        op: isAdd ? "add" : "update"
      };
      externalArr.push(neObjNext);
    }
    values.externalPractice = externalArr;

    const finalObj = {
      name: values.name,
      longName: values.longName,
      status: values.status,
      phoneNumber: values.phoneNumber,
      website: values.website,
      externalPractice: externalArr,
      fertLink: values.fertLink,
      payYourBillLink: values.payYourBillLink,
      practiceLogo: values.practiceLogo,
      practicePdfLogo: values.practicePdfLogo,
      vendorId: values.vendorId,
      accountNumber: values.accountNumber,
      locationId: values.locationId,
      projectId: values.projectId
    };

    // return;
    if (isAdd) {
      console.log("Add New Practice: ", finalObj);
      actions.addNewPractice(finalObj);
    } else {
      finalObj.practiceId = values.practiceId;
      console.log("Edit Practice: ", finalObj);

      actions.editPractice(finalObj);
    }
    closeModal();
  };
  const handleEditPractice = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    let exterFertData =
      row.externalPracticeData &&
      row.externalPracticeData.filter((rdata) => rdata.externalSystemId == "Fert");
    let exterNextData =
      row.externalPracticeData &&
      row.externalPracticeData.filter((rdata) => rdata.externalSystemId == "Nextgen");
    practiceFormData = {
      practiceId: row.practiceId,
      name: row.name,
      longName: row.longName,
      status: row.status,
      phoneNumber: row?.phoneNumber || "",
      website: row?.website || "",
      externalFertPracticeId:
        (exterFertData && exterFertData[0] && exterFertData[0].externalPracticeId) || "",
      externalNextgenPracticeId:
        (exterNextData && exterNextData[0] && exterNextData[0].externalPracticeId) || "",
      fertLink: row?.fertLink || "",
      payYourBillLink: row?.payYourBillLink || "",
      practiceLogo: row?.practiceLogo || "",
      practicePdfLogo: row?.practicePdfLogo || "",
      vendorId: row?.vendorId || "",
      accountNumber: row?.accountNumber || "",
      projectId: row?.projectId || "",
      locationId: row?.locationId || ""
    };
    setValues(practiceFormData);
  };
  const practice = practiceConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled:
      values.name &&
      values.longName &&
      values.externalFertPracticeId &&
      values.externalNextgenPracticeId &&
      values.vendorId &&
      values.accountNumber &&
      values.projectId &&
      values.locationId
        ? false
        : true
  });

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  return (
    <>
      <Box className={`${loading ? "m-r-8  m-l-8 disable-section" : "m-r-8  m-l-8"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>Practice List</Typography>
          <Button variant="contained" className="btn-usf" onClick={addNewPractice}>
            New Practice
          </Button>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      {/* Add/edit practice modal start*/}
      <CommonModal
        open={isOpen}
        config={practice}
        isAdd={isAdd}
        handleSubmit={savePractice}
        isValid={isValid}
        validationMsg={validationMsg}
      />
      {/* Add/edit practice modal end*/}
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />

      {/* SVG Image only */}
      <MessageModal
        open={isMessModal}
        message={succMsg}
        error={errMsg}
        closeModal={closeMessModal}
      />
    </>
  );
};
const mapStateToProps = ({ practices }) => {
  return {
    practices: practices.practices,
    addPractice: practices.addPractice,
    editPractice: practices.editPractice,
    loading: practices.loading,
    errorMsg: practices.error,
    successMsg: practices.success,
    practiceId: practices.practiceId
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    addNewPractice: (payload) => {
      dispatch(actionType.addNewPractice(payload));
    },
    editPractice: (payload) => {
      dispatch(actionType.editPractice(payload));
    },
    resetPracticeList: () => {
      dispatch(actionType.resetPracticeList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    resetLocationRows: () => {
      dispatch(actionType.resetLocationRows());
    },
    resetPhysicianRows: () => {
      dispatch(actionType.resetPhysicianRows());
    },
    setPracticeId: (practiceId) => {
      dispatch(actionType.setPracticeId(practiceId));
    },

    resetContactRows: () => {
      dispatch(actionType.resetContactRows());
    },

    resetEmailList: () => {
      dispatch(actionType.resetEmailList());
    },
    resetEmailDocumentRows: () => {
      dispatch(actionType.resetEmailDocumentRows());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Practice);
