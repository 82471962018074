import { Box, Typography, Stack, Grid, TextField, Button, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const StaffAdmin = (props) => {
  const [searchText, setSearchText] = useState("");
  const [r, setR] = useState("default");
  const { actions, staffAdminList = [], loading } = props;
  const [filteredRowData, setFilteredRowData] = useState([]);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });

  useEffect(() => {
    if (staffAdminList.length) {
      const rows = staffAdminList.map((item, index) => {
        item.id = index;
        return item;
      });
      setFilteredRowData(rows);
    }
  }, [loading]);
  useEffect(() => {
    const name = "";
    const primaryRole = "";
    actions.getStaffAdminList({ name, primaryRole });
  }, []);
  const columns = [
    {
      field: "firstName",
      headerName: "USER",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Link
            to={`/admin/staff-admin/user-details`}
            onClick={() => {
              actions.setCognitoId(row.cognitoUserId);
            }}>
            <Typography>{row.firstName + " " + row.lastName}</Typography>
          </Link>
        );
      }
    },
    {
      field: "email",
      headerName: "USER EMAIL",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.email != null ? row.email : "-";
      }
    },
    {
      field: "primaryRole",
      headerName: "ROLE ",
      width: 100,
      editable: false,
      flex: 1
    }
  ];
  const handleSearchClick = (e, searchText) => {
    actions.resetStaffAdminList();
    if (searchText != "") {
      setFilteredRowData([]);
      const name = searchText;
      const email = searchText;
      const primaryRole = r;
      let payload = searchText.includes("@") ? { email, primaryRole } : { name, primaryRole };
      actions.getStaffAdminList(payload);
    }
  };
  const clearSearchClick = () => {
    setFilteredRowData([]);
    setSearchText("");
  };

  const resetFilters = () => {
    setSearchText("");
    setR("default");
  };
  return (
    <>
      <Box className="stage-details-section m-10 ">
        <Grid item xs={10}>
          <Stack direction="row" className="pad-5">
            <Grid item container className="align-center">
              <Grid item xs={1.3}>
                <Typography>Find a User *</Typography>
              </Grid>
              <Grid item xs={8}>
                <Stack direction="row">
                  <TextField
                    fullWidth
                    size="small"
                    value={searchText}
                    placeholder="Search by First Name or Last Name or Email ID"
                    onChange={(e) => setSearchText(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <>
                          {searchText && (
                            <IconButton
                              className="m-r-m10"
                              onClick={() => {
                                setSearchText("");
                                clearSearchClick();
                              }}>
                              <Close sx={{ fontSize: 12 }} />
                            </IconButton>
                          )}
                        </>
                      )
                    }}
                  />
                  <Button
                    variant="outlined"
                    className="search-pat-btn"
                    onClick={(e) => handleSearchClick(e, searchText)}>
                    <SearchIcon />
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
          <Stack direction="row" className="pad-5">
            <Grid item container className="align-center">
              <Grid item xs={1.3}>
                <Typography>Role *</Typography>
              </Grid>
              <Grid item xs={3}>
                <Select
                  name="primaryMD"
                  value={r}
                  className="patient-dropdown"
                  onChange={(e) => setR(e.target.value)}>
                  <MenuItem value="default">All</MenuItem>
                  <MenuItem value="Financial Counselor">{`Financial Counselor`}</MenuItem>
                  <MenuItem value="Financial Counselor(lite)">{`Financial Counselor(lite)`}</MenuItem>
                  <MenuItem value="Felix Super Admin">{`Felix Super Admin`}</MenuItem>
                  <MenuItem value="Trak Ops Manager">{`Trak Ops Manager`}</MenuItem>
                  <MenuItem value="Trak admin">{`Trak Admin`}</MenuItem>
                  <MenuItem value="Practice/Clinical User">{`Practice/Clinical User`}</MenuItem>
                  <MenuItem value="Super User">{`Super User`}</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row" className="m-l-40 align-center">
                  <Typography
                    className="cursor-pointer"
                    color={r == "default" && searchText == "" ? "lightGrey" : "primary"}
                    disable={r == "default" || searchText == "" ? true : false}
                    variant="subtitle1"
                    onClick={resetFilters}>
                    RESET ALL
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={filteredRowData}
          rowCount={filteredRowData?.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
        />
      </Box>
    </>
  );
};
const mapStateToProps = ({ staffAdminReducer }) => {
  return {
    staffAdminList: staffAdminReducer.staffAdminList,
    loading: staffAdminReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStaffAdminList: (payload) => {
      dispatch(actionType.getStaffAdminList(payload));
    },
    resetStaffAdminList: () => {
      dispatch(actionType.resetStaffAdminList());
    },
    setCognitoId: (payload) => {
      dispatch(actionType.setCognitoId(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(StaffAdmin);
