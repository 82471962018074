import { Checkbox, Typography, TextField } from "@mui/material";
import { Stack, Box } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionType from "../actions";
import { v4 as uuidv4 } from "uuid";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const columns = [
  {
    field: "stageName",
    headerName: "STAGE",
    width: 50,
    flex: 0.5,
    sortable: false
  },
  {
    field: "stageCompleted",
    headerName: "STAGE COMPLETE",
    width: 100,
    editable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Checkbox disabled checked={row.stageCompleted === true ? true : false} />
    )
  },
  {
    field: "stageCancelled",
    headerName: "STAGE CANCELLED AFTER",
    width: 100,
    editable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Checkbox disabled checked={row.stageCancelled == true ? true : false} />
    )
  },
  {
    field: "dateRecorded",
    headerName: "DATE RECORDED",
    width: 100,
    editable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          disabled
          openTo="day"
          views={["day", "year", "month"]}
          name="dateRecorded"
          value={row?.dateRecorded}
          renderInput={(params) => <TextField {...params} size="small" sx={{ flex: 8 }} />}
        />
      </LocalizationProvider>
    )
  }
];

function StageCompletion(props) {
  const { actions, clinicalTreatmentNo, loading, clinicalList } = props;
  const [clinicalTreatmentStage, setClinicalTreatmentStage] = useState([]);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  useEffect(() => {
    if (clinicalList && clinicalList != null && clinicalList?.length > 0) {
      const rows = clinicalList.filter((item, index) => {
        if (item.clinicalTreatmentNo === clinicalTreatmentNo) {
          item.id = index;
          return item;
        }
      });
      rows.length > 0 && setClinicalTreatmentStage(rows[0]?.clinicalTreatmentStages);
    }
  }, [clinicalList]);
  useEffect(() => {
    actions.getClinicalTreatmentOutcomes({ medcaseId: props.medcaseId });
  }, [props.medcaseId]);
  return (
    <>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="modal-stack">
          <Typography>Stage Completion</Typography>
        </Stack>
        <Box className="grid-style" sx={{ maxHeight: "650px" }}>
          <DataGridPremiumComp
            columns={columns}
            rows={clinicalTreatmentStage}
            rowCount={clinicalTreatmentStage.length}
            getRowId={() => uuidv4()}
            disabled
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </Box>
      </Box>
    </>
  );
}
const mapStateToProps = ({ trackOutComeFormReducer }) => {
  return {
    clinicalList: trackOutComeFormReducer.clinicalTreatmentOutcomesList,
    loading: trackOutComeFormReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getClinicalTreatmentOutcomes: (payload) => {
      dispatch(actionType.getClinicalTreatmentOutcomes(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(StageCompletion);
