import * as actionType from "../actions/ActionTypes";

const initialState = {
  treatmentPlanList: [],
  treatmentPlanDetailsList: [],
  deleteTreatmentPlanQuestion: [],
  treatmentPlanAddQuestionOption: [],
  treatmentPlanRoot: [],
  treatmentPlanQuestionSelection: [],
  treatmentAddOn: [],
  treatmentModifier: [],
  treatmentMonitoring: [],
  treatmentAssist: [],
  treatmentAHM: [],
  addTreatmentPlan: null,
  editTreatmentPlan: null,
  deleteTreatmentPlan: null,
  stagesIncluded: [],
  stagesNecessary: [],
  stagesOptional: [],
  addStagesTreatmentPlan: null,
  editStagesTP: null,
  deleteStagesTp: null,
  loading: false,
  upsertLoading: false,
  error: null,
  success: false,
  isTreatmentPlanQuestionFetch: false,
  isRootQuestionFetch: false,
  isTreatmentPlanQuestionSelectionFetch: false,
  isAddOnQuestionFetch: false,
  isModifierQuestionFetch: false,
  isMonitoringQuestionFetch: false,
  isAssistQuestionFetch: false,
  isAHMQuestionFetch: false
};

const treatmentPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TREATMENTPLAN_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.TREATMENTPLAN_LIST_SUCCESS: {
      return {
        ...state,
        treatmentPlanList: action.payload,
        loading: false
      };
    }
    case actionType.TREATMENTPLAN_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        addTreatmentPlan: action.payload,
        loading: false
        // success: action.successMsg
      };
    }
    case actionType.ADD_NEW_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.EDIT_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        editTreatmentPlan: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.UPSERT_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        upsertLoading: true
      };
    }
    case actionType.UPSERT_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        upsertTreatmentPlan: action.payload,
        upsertLoading: false,
        success: action.successMsg
      };
    }
    case actionType.UPSERT_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        upsertLoading: false
      };
    }
    case actionType.DELETE_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        editTreatmentPlan: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_TREATMENTPLAN_LIST: {
      return {
        ...state,
        addTreatmentPlan: null,
        editTreatmentPlan: null,
        treatmentPlanQuestionSelection: [],
        treatmentPlanRoot: [],
        treatmentAddOn: [],
        treatmentModifier: [],
        treatmentMonitoring: [],
        treatmentAssist: [],
        addStagesTreatmentPlan: null,
        loading: null,
        stagesIncluded: []
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    case actionType.RESET_TREATMENTPLAN: {
      return {
        ...state,
        addTreatmentPlan: null,
        editTreatmentPlan: null,
        treatmentPlanList: [],
        loading: null
      };
    }
    case actionType.STAGE_INCLUDED_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.STAGE_INCLUDED_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        stagesIncluded: action.payload,
        loading: false
      };
    }
    case actionType.STAGE_INCLUDED_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.STAGE_NECESSARY_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.STAGE_NECESSARY_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        stagesNecessary: action.payload,
        loading: false
      };
    }
    case actionType.STAGE_NECESSARY_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.STAGE_OPTIONAL_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.STAGE_OPTIONAL_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        stagesOptional: action.payload,
        loading: false
      };
    }
    case actionType.STAGE_OPTIONAL_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.TREATMENTPLANQUESTIONS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        isTreatmentPlanQuestionFetch: false
      };
    }
    case actionType.TREATMENTPLANQUESTIONS_LIST_SUCCESS: {
      return {
        ...state,
        treatmentPlanDetailsList: action.payload,
        loading: false,
        isTreatmentPlanQuestionFetch: true
      };
    }
    case actionType.TREATMENTPLANQUESTIONS_LIST_REFRESH: {
      return {
        ...state,
        treatmentPlanDetailsList: action.payload,
        loading: false,
        isTreatmentPlanQuestionFetch: true
      };
    }
    case actionType.TREATMENTPLANQUESTIONS_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.TREATMENTPLANADDQUESTIONOPTION_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.TREATMENTPLANADDQUESTIONOPTION_LIST_SUCCESS: {
      return {
        ...state,
        treatmentPlanAddQuestionOption: action.payload,
        loading: false
      };
    }
    case actionType.TREATMENTPLANADDQUESTIONOPTION_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.TREATMENTPLANQUESTIONSELECTION_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        isTreatmentPlanQuestionSelectionFetch: false
      };
    }
    case actionType.TREATMENTPLANQUESTIONSELECTION_LIST_SUCCESS: {
      return {
        ...state,
        treatmentPlanQuestionSelection: action.payload,
        loading: false,
        isTreatmentPlanQuestionSelectionFetch: true
      };
    }
    case actionType.TREATMENTPLANQUESTIONSELECTION_LIST_REFRESH: {
      return {
        ...state,
        treatmentPlanQuestionSelection: action.payload,
        loading: false,
        isTreatmentPlanQuestionSelectionFetch: true
      };
    }
    case actionType.TREATMENTPLAN_ADD_ON_REFRESH: {
      return {
        ...state,
        treatmentAddOn: action.payload,
        loading: false
      };
    }
    case actionType.TREATMENTPLAN_MODIFIER_REFRESH: {
      return {
        ...state,
        treatmentModifier: action.payload,
        loading: false
      };
    }
    case actionType.TREATMENTPLAN_MONITORING_REFRESH: {
      return {
        ...state,
        treatmentMonitoring: action.payload,
        loading: false
      };
    }
    case actionType.TREATMENTPLAN_ASSIST_REFRESH: {
      return {
        ...state,
        treatmentAssist: action.payload,
        loading: false
      };
    }
    case actionType.TREATMENTPLAN_AHM_REFRESH: {
      return {
        ...state,
        treatmentAHM: action.payload,
        loading: false
      };
    }
    case actionType.TREATMENTPLANQUESTIONSELECTION_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.TREATMENTPLANROOT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        isRootQuestionFetch: false
      };
    }
    case actionType.TREATMENTPLANROOT_LIST_SUCCESS: {
      return {
        ...state,
        treatmentPlanRoot: action.payload,
        loading: false,
        isRootQuestionFetch: true
      };
    }
    case actionType.TREATMENTPLANROOT_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        isRootQuestionFetch: false
      };
    }

    case actionType.TREATMENTPLAN_ADD_ON_REQUESTED: {
      return {
        ...state,
        loading: true,
        isAddOnQuestionFetch: false
      };
    }
    case actionType.TREATMENTPLAN_ADD_ON_SUCCESS: {
      return {
        ...state,
        treatmentAddOn: action.payload,
        loading: false,
        isAddOnQuestionFetch: true
      };
    }
    case actionType.TREATMENTPLAN_ADD_ON_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        isAddOnQuestionFetch: false
      };
    }
    case actionType.TREATMENTPLAN_MODIFIER_REQUESTED: {
      return {
        ...state,
        loading: true,
        isModifierQuestionFetch: false
      };
    }
    case actionType.TREATMENTPLAN_MODIFIER_SUCCESS: {
      return {
        ...state,
        treatmentModifier: action.payload,
        loading: false,
        isModifierQuestionFetch: true
      };
    }
    case actionType.TREATMENTPLAN_MODIFIER_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        isModifierQuestionFetch: false
      };
    }

    case actionType.TREATMENTPLAN_MONITORING_REQUESTED: {
      return {
        ...state,
        loading: true,
        isMonitoringQuestionFetch: false
      };
    }
    case actionType.TREATMENTPLAN_MONITORING_SUCCESS: {
      return {
        ...state,
        treatmentMonitoring: action.payload,
        loading: false,
        isMonitoringQuestionFetch: true
      };
    }
    case actionType.TREATMENTPLAN_MONITORING_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        isMonitoringQuestionFetch: false
      };
    }

    case actionType.TREATMENTPLAN_ASSIST_REQUESTED: {
      return {
        ...state,
        loading: true,
        isAssistQuestionFetch: false
      };
    }
    case actionType.TREATMENTPLAN_ASSIST_SUCCESS: {
      return {
        ...state,
        treatmentAssist: action.payload,
        loading: false,
        isAssistQuestionFetch: true
      };
    }
    case actionType.TREATMENTPLAN_ASSIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        isAssistQuestionFetch: false
      };
    }

    case actionType.TREATMENTPLAN_AHM_REQUESTED: {
      return {
        ...state,
        loading: true,
        isAHMQuestionFetch: false
      };
    }
    case actionType.TREATMENTPLAN_AHM_SUCCESS: {
      return {
        ...state,
        treatmentAHM: action.payload,
        loading: false,
        isAHMQuestionFetch: true
      };
    }
    case actionType.TREATMENTPLAN_AHM_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        isAHMQuestionFetch: false
      };
    }
    case actionType.ADD_STAGES_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_STAGES_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        addStagesTreatmentPlan: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_STAGES_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_STAGES_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        editStagesTP: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_STAGES_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_STAGES_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_STAGES_TREATMENTPLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_STAGES_TREATMENTPLAN_SUCCESS: {
      return {
        ...state,
        deleteStagesTp: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_STAGES_TREATMENTPLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_TREATMENTPLANQUESIONS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_TREATMENTPLANQUESIONS_SUCCESS: {
      return {
        ...state,
        deleteMultiplier: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_TREATMENTPLANQUESIONS_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default treatmentPlanReducer;
