import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* postFees(action) {
  const { payload } = action;
  const body = {
    feeType: payload.feeType || "",
    transactionType: payload.transactionType || "",
    amount: payload.amount || 0,
    recordedDate: payload.recordedDate || null,
    transactionDate: payload.transactionDate || "",
    comment: payload.comment || "",
    isImportant: payload.isImportant || payload.markImp === "on" || false,
    medcaseFeeId: payload.medcaseFeeId || "",
    medcaseTransactionId: payload.medcaseTransactionId || ""
  };

  try {
    const requestBody = createRequestBody("post", `/medcases/${payload.medcaseId}/fees`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.POST_FEES_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.POST_FEES_FAILED, errorMsg: error.response.data });
    }
  }
}

function* postFeesSaga() {
  yield takeEvery(actionTypes.POST_FEES_REQUESTED, postFees);
}

function* deleteTransactionLogRow(action) {
  const { payload } = action;
  let url = "";
  if (payload.type == "fee") {
    url = `/medcases/fees/${payload.transactionId}`;
  } else if (payload.type == "Lab Payout") {
    url = `/medcases/${payload.medcaseId}/LabPayouts/${payload.transactionId}`;
  } else {
    url = `/medcases/payments/${payload.transactionId}`;
  }

  try {
    const requestBody = createRequestBody("delete", url, "", "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.DELETE_TRANSACTION_SUCCESS,
      payload: response.data,
      successMsg: "Transaction " + payload.transactionId + " was deleted"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_TRANSACTION_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}
function* deleteTransactionLog() {
  yield takeEvery(actionTypes.DELETE_TRANSACTION_REQUESTED, deleteTransactionLogRow);
}

function* editTransactionLogRow(action) {
  console.log("zhr -- inside edit");
  const { payload } = action;
  let url = "";
  let body = {};

  if (payload.type == "fee") {
    url = `/medcases/${payload.medcaseId}/fees/${payload.transactionId}`;

    body = {
      feeType: payload.feeType,
      amount: payload.amount,
      recordedDate: payload.recordedDate,
      transactionDate: payload.transactionDate,
      comment: payload.comment,
      isImportant: payload.isImportant,
      transactionType: payload.transactionType || "",
      medcaseFeeId: payload.medcaseFeeId || "",
      medcaseTransactionId: payload.medcaseTransactionId || "",
      isPNFT: payload.isPNFT,
      isEFT: payload.isEFT
    };
  } else if (payload.type == "payment") {
    url = `/medcases/${payload.medcaseId}/transactions/refunds/${payload.transactionId}`;

    body = {
      transactionType: payload.refundType,
      medcaseId: payload.medcaseId,
      amount: payload.amount,
      recordedDate: payload.recordedDate,
      transactionDate: payload.transactionDate,
      comment: payload.comment,
      isImportant: payload.isImportant,
      isPNFT: payload.isPNFT,
      isEFT: payload.isEFT
    };
  } else if (payload.type == "revenue") {
    url = `/medcases/${payload?.medcaseId}/transactions/revenues/${payload.transactionId}`;
    body = {
      transactionType: payload.refundType,
      amount: payload.amount,
      recordedDate: payload.recordedDate,
      transactionDate: payload.transactionDate,
      comment: payload.comment,
      isImportant: payload.isImportant || payload.markImp === "on" || false,
      cycle: payload.cycle,
      isPNFT: payload.isPNFT,
      isEFT: payload.isEFT
    };
  }

  console.log("zhr body:", body);

  try {
    const requestBody = createRequestBody("put", url, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_TRANSACTION_SUCCESS,
      payload: response.data,
      successMsg: "Transaction " + payload.transactionId + " was edited"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_TRANSACTION_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}
function* editTransactionLog() {
  yield takeEvery(actionTypes.EDIT_TRANSACTION_REQUESTED, editTransactionLogRow);
}

function* getRevenue(action) {
  const { payload } = action;

  try {
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/financialSnapshot/revenue`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GET_REVENUE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_REVENUE_FAIL, errorMsg: error.message });
    }
  }
}

function* getRevenueSaga() {
  yield takeEvery(actionTypes.GET_REVENUE_REQUESTED, getRevenue);
}

function* postRevenue(action) {
  const { payload } = action;

  const body = {
    transactionType: payload.transactionType,
    amount: payload.amount,
    recordedDate: payload.recordedDate,
    transactionDate: payload.transactionDate,
    comment: payload.comment,
    isImportant: payload.isImportant || payload.markImp === "on" || false,
    cycle: payload.cycle
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${payload.medcaseId}/transactions/revenues`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.POST_REVENUE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.POST_REVENUE_FAILED, errorMsg: error.message });
    }
  }
}

function* postRevenueSaga() {
  yield takeEvery(actionTypes.POST_REVENUE_REQUESTED, postRevenue);
}

function* fetchEmailOption(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/emaildocument`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_EMAIL_OPTION_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_EMAIL_OPTION_FAIL, errorMsg: error.message });
    }
  }
}

function* getEmailOptionSaga() {
  yield takeEvery(actionTypes.GET_EMAIL_OPTION_REQUESTED, fetchEmailOption);
}

function* postEmailOption(action) {
  const { payload } = action;
  console.log("zhr payload: ", payload);
  const body = {
    toAddresses: payload.toAddresses,
    moduleName: "outcome",
    cycle: payload.cycle,
    emailType: payload.emailType,
    requestedService: payload.requestedService,
    sendDefaultTo: "outcome",
    outcomeRequestedService: payload.outcomeRequestedService
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${payload.medcaseId}/sendemail`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.POST_EMAIL_OPTION_SUCCESS,
      payload: response.data,
      successMsg: "Email sent successfully"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.POST_EMAIL_OPTION_FAIL, errorMsg: error.response.data });
    }
  }
}

function* postEmailOptionSaga() {
  yield takeEvery(actionTypes.POST_EMAIL_OPTION_REQUESTED, postEmailOption);
}

export default function* finSnapshotSaga() {
  yield all([
    postFeesSaga(),
    deleteTransactionLog(),
    postRevenueSaga(),
    getEmailOptionSaga(),
    postEmailOptionSaga(),
    getRevenueSaga(),
    editTransactionLog()
  ]);
}
