import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { prsWarning } from "../constants/constants";
import { patientPaymentWarning } from "../../trak/constants";

const WarnningMessageModal = ({
  open,
  message,
  closeModal,
  closeSaveModal,
  cancelModal,
  patientPayment
}) => {
  return (
    <Modal open={open} backdrop="static" aria-labelledby="modal-add-title">
      <Box className="alert-modal">
        <Stack direction="row" className="modal-stack">
          <Typography>Alert</Typography>
          <IconButton onClick={closeModal}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Stack>
        <Divider className="modal-divider" />
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", py: 2 }}>
          {message && message.includes("Amount Exc") ? (
            <Typography sx={{ padding: "2px", color: "red" }}>{patientPaymentWarning}</Typography>
          ) : (
            <Typography sx={{ padding: "2px", color: "red" }}>{message}</Typography>
          )}
        </Stack>

        {patientPayment == patientPaymentWarning ? (
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Button variant="outlined" className="btn-primary" onClick={cancelModal}>
              Cancel
            </Button>
            <Button variant="contained" className="btn-primary" onClick={closeModal}>
              OK
            </Button>
          </Stack>
        ) : message !== prsWarning ? (
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
            <Button variant="contained" className="btn-primary" onClick={closeModal}>
              OK
            </Button>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant="outlined" className="btn-primary" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="contained" className="btn-primary" onClick={closeSaveModal}>
              Save
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default WarnningMessageModal;
