import * as actionType from "../actions/ActionTypes";

const initialState = {
  feeScheduleList: [],
  loading: false,
  error: null,
  success: null
};

const feeScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FEE_SCHEDULE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.FEE_SCHEDULE_LIST_SUCCESS: {
      return {
        ...state,
        feeScheduleList: action.payload,
        loading: false
      };
    }
    case actionType.FEE_SCHEDULE_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.FEE_SCHEDULE_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true
      };
    }
    case actionType.FEE_SCHEDULE_UPDATE_FAIL: {
      return {
        ...state,
        loading: false,
        error: true
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default feeScheduleReducer;
