import reducer from "./reducer";
import createSagaMiddleware from "@redux-saga/core";
import { configureStore } from "@reduxjs/toolkit";
import rootSaga from "./sagas";

const saga = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: [saga],
  devTools: process.env.NODE_ENV !== "production"
});

saga.run(rootSaga);

export default store;
