import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* addCreditCardPayment(action) {
  const payload = action.payload;
  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${payload.medcaseId}/payments/CC`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CREATE_CREDIT_CARD_PAYMENT_SUCCESS,
      payload: response.data,
      successMsg: "Patients Payment Applied"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CREATE_CREDIT_CARD_PAYMENT_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}

function* addCreditCardPaymentSaga() {
  yield takeEvery(actionTypes.CREATE_CREDIT_CARD_PAYMENT_REQUESTED, addCreditCardPayment);
}
function* addCheckPayment(action) {
  const payload = action.payload;

  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${payload.medcaseId}/payments/CH`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CREATE_CHECK_PAYMENT_SUCCESS,
      payload: response.data,
      successMsg: "Patients Payment Applied"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CREATE_CHECK_PAYMENT_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}
function* addCheckPaymentSaga() {
  yield takeEvery(actionTypes.CREATE_CHECK_PAYMENT_REQUESTED, addCheckPayment);
}
function* addLoanPayment(action) {
  const payload = action.payload;
  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${payload.medcaseId}/payments/loan`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CREATE_LOAN_PAYMENT_SUCCESS,
      payload: response.data,
      successMsg: "Patients Payment Applied"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CREATE_LOAN_PAYMENT_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}
function* addLoanPaymentSaga() {
  yield takeEvery(actionTypes.CREATE_LOAN_PAYMENT_REQUESTED, addLoanPayment);
}
function* addWireTransferPayment(action) {
  const payload = action.payload;

  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${payload.medcaseId}/payments/wiretranfer`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CREATE_WIRE_TRANSFER_PAYMENT_SUCCESS,
      payload: response.data,
      successMsg: "Patients Payment Applied"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CREATE_WIRE_TRANSFER_PAYMENT_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}
function* addWireTransferPaymentSaga() {
  yield takeEvery(actionTypes.CREATE_WIRE_TRANSFER_PAYMENT_REQUESTED, addWireTransferPayment);
}

function* getPaymentDetails(action) {
  const payload = action.payload;

  let url;
  if (payload.paymentType.toUpperCase() == "CREDIT CARD") {
    url = `/medcases/${payload.medcaseId}/payments/CC/${payload.transactionId}`;
  } else if (payload.paymentType.toUpperCase() == "CHECK") {
    url = `/medcases/${payload.medcaseId}/payments/CH/${payload.transactionId}`;
  } else if (payload.paymentType.toUpperCase() == "LOAN") {
    url = `/medcases/${payload.medcaseId}/payments/loan/${payload.transactionId}`;
  } else if (payload.paymentType.toUpperCase() == "WIRE TRANSFER") {
    url = `/medcases/${payload.medcaseId}/payments/wiretranfer/${payload.transactionId}`;
  }

  try {
    const requestBody = createRequestBody("get", url, "", "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_PATIENT_PAYMENT_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_PATIENT_PAYMENT_FAIL,
        errorMsg: error.response.data
      });
    }
  }
}

function* getPaymentDetailsSaga() {
  yield takeEvery(actionTypes.GET_PATIENT_PAYMENT_REQUESTED, getPaymentDetails);
}

function* editWireTransferPayment(action) {
  const payload = action.payload;

  const { medcaseId, transactionId } = payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${medcaseId}/payments/wiretranfer/${transactionId}`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_WIRE_TRANSFER_PAYMENT_SUCCESS,
      payload: response.data,
      successMsg: "Patients Payment Applied"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_WIRE_TRANSFER_PAYMENT_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}
function* editWireTransferPaymentSaga() {
  yield takeEvery(actionTypes.EDIT_WIRE_TRANSFER_PAYMENT_REQUESTED, editWireTransferPayment);
}

function* editCreditcardTransferPayment(action) {
  const payload = action.payload;

  const { medcaseId, transactionId } = payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${medcaseId}/payments/CC/${transactionId}`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_CREDIT_CARD_PAYMENT_SUCCESS,
      payload: response.data,
      successMsg: "Patients Payment Applied"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_CREDIT_CARD_PAYMENT_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}
function* editCreditCardTransferPaymentSaga() {
  yield takeEvery(actionTypes.EDIT_CREDIT_CARD_PAYMENT_REQUESTED, editCreditcardTransferPayment);
}

function* editCheckTransferPayment(action) {
  const payload = action.payload;

  const { medcaseId, transactionId } = payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${medcaseId}/payments/CH/${transactionId}`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_CHECK_PAYMENT_SUCCESS,
      payload: response.data,
      successMsg: "Patients Payment Applied"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_CHECK_PAYMENT_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}
function* editCheckTransferPaymentSaga() {
  yield takeEvery(actionTypes.EDIT_CHECK_PAYMENT_REQUESTED, editCheckTransferPayment);
}

function* editLoanTransferPayment(action) {
  const payload = action.payload;

  const { medcaseId, transactionId } = payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${medcaseId}/payments/loan/${transactionId}`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_LOAN_PAYMENT_SUCCESS,
      payload: response.data,
      successMsg: "Patients Payment Applied"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_LOAN_PAYMENT_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}
function* editLoanTransferPaymentSaga() {
  yield takeEvery(actionTypes.EDIT_LOAN_PAYMENT_REQUESTED, editLoanTransferPayment);
}

export default function* patientPaymentSaga() {
  yield all([
    addCreditCardPaymentSaga(),
    addCheckPaymentSaga(),
    addLoanPaymentSaga(),
    addWireTransferPaymentSaga(),
    getPaymentDetailsSaga(),
    editWireTransferPaymentSaga(),
    editLoanTransferPaymentSaga(),
    editCheckTransferPaymentSaga(),
    editCreditCardTransferPaymentSaga()
  ]);
}
