import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography
} from "@mui/material";
import { Fragment } from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import * as actionType from "../../actions";

const EstimateDetails = (props) => {
  const {
    open,
    estimateId,
    estimateTpId,
    isEdit,
    close,
    header,
    actions,
    questions,
    loading,
    errorAddEstimate,
    successAddEstimate,
    treatmentQstnsLoading,
    treatmentQstns,
    error,
    estimateQuestionFailed
  } = props;
  const { personId } = useParams();
  const practiceId = localStorage.getItem("practiceId");
  const [treatmentOptions, setTreatmentOptions] = useState([]);
  const [values, setValues] = useState([]);
  const [nextQuestions, setNextQuestions] = useState([]);
  const [addedQstns, setAddedQstns] = useState([]);
  const [successModal, setSuccessModal] = useState(false);
  const [treatmentType, setTreatmentType] = useState("");
  // const [loadingModal, setLoadingModal] = useState(false);
  // const [nextQuestionOp, setNextQuestionOp] = useState(false);

  useEffect(() => {
    if (isEdit) {
      let payload = { estimateTpId, estimateId };
      actions.getTreatmentPlanQuestions(payload);
    } else {
      actions.getFinancialEstimateQuestions(practiceId);
    }
  }, []);

  useEffect(() => {
    if (questions.responses) {
      setTreatmentOptions(questions.responses);
    }
  }, [loading]);

  useEffect(() => {
    if (errorAddEstimate || successAddEstimate) {
      setSuccessModal(true);
    }
  }, [successAddEstimate, errorAddEstimate]);

  useEffect(() => {
    if (treatmentQstns.length && questions.responses != undefined) {
      setTreatmentType(treatmentQstns[0].responseNo);
      const nextQuestionsToRender = questions.responses.filter(
        (item) => item.responseNo == treatmentQstns[0].responseNo
      );

      setValues([
        {
          questionId: treatmentQstns[0].questionId,
          responseNo: treatmentQstns[0].responseNo,
          questionCategory: treatmentQstns[0].questionCategory
        }
      ]);

      setNextQuestions(nextQuestionsToRender[0].nextQuestions);

      setNextQuestions((prevRows) =>
        prevRows.map((row) =>
          treatmentQstns.some((b) => row.questionId === b.questionId)
            ? {
                ...row,
                checkedValue: row.responses.filter((a) =>
                  treatmentQstns.some(
                    (b) => a.responseNo === b.responseNo && b.questionId == row.questionId
                  )
                )[0].responsetext,
                responseNo: row.responses.filter((a) =>
                  treatmentQstns.some(
                    (b) => a.responseNo === b.responseNo && b.questionId == row.questionId
                  )
                )[0].responseNo
              }
            : row
        )
      );

      setNextQuestions((prevRows) => {
        let newArr = [];
        prevRows.forEach((qes) => {
          newArr.push(qes);

          if (qes.checkedValue && qes.responseNo) {
            if (qes.responses && qes.responses.length) {
              qes.responses.forEach((res) => {
                if (res.responseNo === qes.responseNo) {
                  if (res.nextQuestions && res.nextQuestions.length) {
                    res.nextQuestions.forEach((nQes) => {
                      let alreadyPresent = newArr
                        .map((item) => item.questionId)
                        .includes(Number(nQes.questionId));
                      if (!alreadyPresent) {
                        nQes.checkedValue = nQes.responses.filter((a) =>
                          treatmentQstns.some(
                            (b) => a.responseNo === b.responseNo && b.questionId == nQes.questionId
                          )
                        )[0].responsetext;
                        nQes.responseNo = nQes.responses.filter((a) =>
                          treatmentQstns.some(
                            (b) => a.responseNo === b.responseNo && b.questionId == nQes.questionId
                          )
                        )[0].responseNo;
                        newArr.push(nQes);
                      }
                    });
                  }
                }
              });
            }
          }
        });
        return newArr;
      });
    }
  }, [treatmentQstnsLoading, loading]);

  const closeSuccessModal = () => {
    if (successAddEstimate) {
      if (header == "Create Detailed Estimate" || header == "Create Preliminary Estimate") {
        let payload = {
          practiceId,
          personId
        };
        actions.getFinancialEstimateViewList(payload);
      }
      if (header === "Add Treatment Plan" || header === "Edit Treatment Plan") {
        actions.resetCardClick();
      }
      setSuccessModal(false);
      close();
      props.openCost();
    }
    if (errorAddEstimate) {
      setSuccessModal(false);
    }

    actions.resetMessageModal();
    actions.resetCostCoverage();
  };

  const saveEstimates = (e) => {
    e.preventDefault();
    // if (!isEdit) {
    //   actions.resetCardClick();
    // }

    let updatedValues = nextQuestions
      .map((item) => ({
        questionId: item.questionId,
        responseNo: item.defaultResponse,
        questionCategory: item.questionCategory
      }))
      .filter((item) => item.responseNo);

    let updatedAr = values.concat(updatedValues);

    if (header === "Add Treatment Plan") {
      let payload = {
        practiceId,
        patientId: personId,
        questionResponseList: updatedAr,
        estimateId: estimateId
      };
      actions.addTreatmentPlan(payload);
    }
    if (header == "Create Detailed Estimate" || header == "Create Preliminary Estimate") {
      let payload = {
        practiceId,
        patientId: personId,
        questionResponseList: updatedAr,
        query: header === "Create Detailed Estimate" ? "D" : "P"
      };
      actions.createEstimate(payload);
    }
    if (header === "Edit Treatment Plan") {
      let payload = {
        practiceId,
        patientId: personId,
        questionResponseList: updatedAr,
        estimateId,
        estimateTpId
      };
      actions.editTreatmentPlan(payload);
    }
  };

  let reorderedQuestions = [];

  // Function to recursively add questions based on responses
  function addQuestions(question) {
    reorderedQuestions.push(question);
    question.responses.forEach((response) => {
      if (response.responseNo == question.defaultResponse && response.nextQuestions.length > 0) {
        response.nextQuestions.forEach((nextQuestion) => {
          addQuestions(nextQuestion);
        });
      }
    });
  }

  const handleChange = (e) => {
    e.preventDefault();
    let { value } = e.target;
    setValues([
      {
        questionId: questions.questionId,
        responseNo: value,
        questionCategory: questions.questionCategory
      }
    ]);
    setTreatmentType(value);
    let newQuestions = questions.responses.filter((item) => item.responseNo == value)[0]
      .nextQuestions;

    // let nextQuestionIds = newQuestions.responses.filter((item_e) => {
    //   if (item_e.responseNo == newQuestions.defaultResponse && item_e.nextQuestions.length > 0) {
    //     return item_e;
    //   }
    //   return null;
    // });
    // let nextQuestionIds = [];
    // console.log("esd newQuestions: ", newQuestions);
    // newQuestions.map((item) => {
    //   let myObj = item.responses.filter((item_e) => {
    //     if (item_e.responseNo == item.defaultResponse && item_e.nextQuestions.length > 0) {
    //       nextQuestionIds.push(item_e.nextQuestions);
    //       return null;
    //     }
    //     return item_e;
    //   });

    //   return myObj;
    // });

    // console.log("esd nextQuestionIds:", nextQuestionIds);

    // let finalArrList = newQuestions;
    // if (nextQuestionIds.length > 0) {
    //   finalArrList = newQuestions.concat(nextQuestionIds[0]);
    // }

    // console.log("esd finalArrList:", finalArrList);
    // //console.log("nextQuestionIds123", newQuestions, nextQuestionIds);
    // setNextQuestions(finalArrList);

    console.log("esd newQuestions: ", newQuestions);

    // Start with the first question in newQuestions
    newQuestions.forEach((question) => {
      addQuestions(question);
    });

    console.log("esd reorderedQuestions:", reorderedQuestions);
    setNextQuestions(reorderedQuestions);
  };

  const handleChangeRadio = (e, item, i) => {
    const { value } = e.target;

    let parsedValue = JSON.parse(value);

    console.log("esd item: ", item);
    console.log("esd parsedValue: ", parsedValue);

    // if next question presents
    if (parsedValue.nextQuestionIds) {
      let alreadyPresent = nextQuestions
        .map((item) => item.questionId)
        .includes(Number(parsedValue.nextQuestionIds));

      console.log("esd alreadyPresent: ", alreadyPresent);

      if (alreadyPresent) {
        setNextQuestions((prevRows) =>
          prevRows.map((row, index) =>
            index === i
              ? {
                  ...row,
                  checkedValue: parsedValue.responsetext,
                  responseNo: parsedValue.responseNo,
                  defaultResponse: parsedValue.responseNo
                }
              : row
          )
        );
      } else {
        let currentQstnIds = item.responses.map((item) => Number(item.nextQuestionIds));
        console.log("esd currentQstnIds: ", currentQstnIds);
        setNextQuestions((prev) =>
          prev.filter((items) => !currentQstnIds.includes(items.questionId))
        );
        console.log("esd addedQstns: ", addedQstns);
        if (addedQstns.some((item) => currentQstnIds.includes(Number(item.questionId)))) {
          setAddedQstns((prev) => prev.splice(i, 1));
        }

        setAddedQstns((prev) => [...prev, parsedValue.nextQuestions[0]]);
        // setNextQuestions((prev) => [...prev, parsedValue.nextQuestions[0]]);

        setNextQuestions((prev) => {
          const orderedQuestion = [];
          prev.forEach((res) => {
            orderedQuestion.push(res);
            if (res.questionId === item.questionId && parsedValue.nextQuestionIds) {
              orderedQuestion.push(parsedValue.nextQuestions[0]);
            }
          });
          return orderedQuestion;
        });

        // setNextQuestionOp(parsedValue.nextQuestions[0] ? true : false);

        if (treatmentType !== 2 && treatmentType !== 7 && treatmentType !== 3) {
          // NOT FET And PGT
          console.log("esd Inside if treatmentType: ", treatmentType);
          setNextQuestions((prev) =>
            prev.sort((a, b) => {
              return a.questionId - b.questionId;
            })
          );
        }
        console.log("esd i", i);
        setNextQuestions((prevRows) =>
          prevRows.map((row, index) =>
            index === i
              ? {
                  ...row,
                  checkedValue: parsedValue.responsetext,
                  responseNo: parsedValue.responseNo,
                  defaultResponse: parsedValue.responseNo
                }
              : row
          )
        );

        console.log("esd nextQuestions: ", nextQuestions);
      }
    } else {
      // if next question not presents
      if (!item.responses.some((item) => item.nextQuestionIds)) {
        setNextQuestions((prevRows) =>
          prevRows.map((row, index) =>
            index === i
              ? {
                  ...row,
                  checkedValue: parsedValue.responsetext,
                  responseNo: parsedValue.responseNo,
                  defaultResponse: parsedValue.responseNo
                }
              : row
          )
        );
      } else {
        let currentQstnIds = item.responses.map((item) => Number(item.nextQuestionIds));
        setNextQuestions((prev) =>
          prev.filter((items) => !currentQstnIds.includes(items.questionId))
        );
        setNextQuestions((prevRows) =>
          prevRows.map((row, index) =>
            index === i
              ? {
                  ...row,
                  checkedValue: parsedValue.responsetext,
                  responseNo: parsedValue.responseNo,
                  defaultResponse: parsedValue.responseNo
                }
              : row
          )
        );
        setAddedQstns((prev) => prev.splice(i, prev.length));
      }
    }
  };

  console.log("esd nextQuestions --- outside: ", nextQuestions);

  // useEffect(() => {
  //   if (nextQuestionOp) {
  //     reorderedQuestions = [];
  //     nextQuestions.forEach((question) => {
  //       addQuestions(question);
  //     });

  //     console.log("esd reorderedQuestions:", reorderedQuestions);
  //     setNextQuestions(reorderedQuestions);
  //     setNextQuestionOp(false);
  //   }
  // }, [nextQuestions]);

  const handleChangeCheckBox = (e, item) => {
    const { value } = e.target;
    let parsedValue = JSON.parse(value);

    if (e.target.checked) {
      if (
        parsedValue &&
        parsedValue.responsetext &&
        (parsedValue.responsetext.includes("PGT-M") || parsedValue.responsetext.includes("PGT-A"))
      ) {
        let freezRes = null;
        let isFreezRes = false;
        let newValues = [
          ...values,
          {
            questionId: item.questionId,
            responseNo: parsedValue.responseNo,
            questionCategory: item.questionCategory
          }
        ];

        nextQuestions.forEach((val) => {
          if (val && val.responses) {
            val.responses.forEach((resp) => {
              if (resp && resp.responsetext == "Freeze-All") {
                freezRes = {
                  questionId: val.questionId,
                  responseNo: resp.responseNo,
                  questionCategory: val.questionCategory
                };
                isFreezRes = true;
              }
            });
          }
        });

        values.forEach((itm) => {
          if (itm.questionId === freezRes.questionId && itm.responseNo === freezRes.responseNo) {
            isFreezRes = false;
          }
        });

        if (isFreezRes) {
          newValues = [...newValues, freezRes];
        }

        setValues(newValues);
      } else {
        setValues([
          ...values,
          {
            questionId: item.questionId,
            responseNo: parsedValue.responseNo,
            questionCategory: item.questionCategory
          }
        ]);
      }
    } else {
      if (
        parsedValue &&
        parsedValue.responsetext &&
        (parsedValue.responsetext.includes("PGT-M") || parsedValue.responsetext.includes("PGT-A"))
      ) {
        let freezRes = null;
        let pgtList = [];
        let isUncheck = false;

        nextQuestions.forEach((val) => {
          if (val && val.responses) {
            val.responses.forEach((resp) => {
              if (resp && resp.responsetext == "Freeze-All") {
                freezRes = {
                  questionId: val.questionId,
                  responseNo: resp.responseNo,
                  questionCategory: val.questionCategory
                };
                isUncheck = true;
              } else if (
                resp &&
                (resp.responsetext.includes("PGT-M") || resp.responsetext.includes("PGT-A"))
              ) {
                pgtList.push({
                  questionId: val.questionId,
                  responseNo: resp.responseNo,
                  questionCategory: val.questionCategory
                });
              }
            });
          }
        });

        let filteredValues = values.filter((items) => {
          if (items.questionId === item.questionId && items.responseNo === parsedValue.responseNo) {
            return null;
          } else {
            return items;
          }
        });

        filteredValues.forEach((itm) => {
          pgtList.forEach((tm) => {
            if (itm.questionId === tm.questionId && itm.responseNo === tm.responseNo) {
              isUncheck = false;
            }
          });
        });

        if (isUncheck) {
          filteredValues = filteredValues.filter((items) => {
            if (
              items.questionId === freezRes.questionId &&
              items.responseNo === freezRes.responseNo
            ) {
              return null;
            } else {
              return items;
            }
          });
        }

        setValues(filteredValues);
      } else {
        let filteredValues = values.filter((items) => {
          if (items.questionId === item.questionId && items.responseNo === parsedValue.responseNo) {
            return null;
          } else {
            return items;
          }
        });

        setValues(filteredValues);
      }
    }
  };

  const renderNextQuestions = nextQuestions.map((item, index) => {
    if (item.questionType === "R") {
      return (
        <>
          <FormControl key={index}>
            <FormLabel required={item.isRequired} id="demo-controlled-radio-buttons-group">
              {item.questionText}
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name={item.questionLabel}
              onChange={(e) => handleChangeRadio(e, item, index)}>
              {item.responses.map((subItem, i) => {
                let checkedItem = false;

                if (item.responseNo && item.responseNo === subItem.responseNo) {
                  checkedItem = true;
                } else if (!item.responseNo && subItem.responseNo == item.defaultResponse) {
                  checkedItem = true;
                }

                return (
                  <FormControlLabel
                    key={i}
                    checked={checkedItem}
                    value={JSON.stringify(subItem)}
                    control={<Radio required={item.isRequired} />}
                    label={subItem.responsetext}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </>
      );
    }
    if (item.questionType === "C") {
      return (
        <Box key={index} sx={{ display: "flex" }}>
          <FormControl component="fieldset" variant="standard">
            <FormLabel required={item.isRequired} id="demo-controlled-radio-buttons-group">
              {item.questionText}
            </FormLabel>
            <FormGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name={item.questionLabel}
              onChange={(e) => handleChangeCheckBox(e, item)}>
              {item.responses.map((subItem, i) => {
                var isChecked = false;
                values.map((vItem) => {
                  if (
                    vItem.questionId === item.questionId &&
                    vItem.responseNo === subItem.responseNo
                  ) {
                    isChecked = true;
                  }
                  return vItem;
                });

                return (
                  <FormControlLabel
                    key={i}
                    checked={isChecked}
                    value={JSON.stringify(subItem)}
                    control={<Checkbox />}
                    label={subItem.responsetext}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </Box>
      );
    }
    if (item.questionType === "D") {
      return (
        <Stack key={index} sx={{ display: "flex" }}>
          <InputLabel required={item.isRequired}>{item.questionText}</InputLabel>
          <Select
            size="small"
            name={item.questionLabel}
            defaultValue=""
            sx={{ minWidth: 300 }}
            onChange={(e) => handleChangeRadio(e, item, index)}>
            {item.responses.map((subItem, index) => {
              return (
                <MenuItem key={index} value={JSON.stringify(subItem)}>
                  {subItem.responsetext}
                </MenuItem>
              );
            })}
          </Select>
        </Stack>
      );
    }
  });
  console.log("error", error);
  return (
    <Fragment>
      <Modal open={open} backdrop="static" aria-labelledby="modal-add-title">
        <Box component="form" onSubmit={saveEstimates} className="modal-style">
          <Stack direction="row" className="modal-stack">
            <Typography>{header}</Typography>
            <IconButton onClick={close}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack className="addition-text">
            {!estimateQuestionFailed ? <InputLabel>Additional Text</InputLabel> : ""}
          </Stack>

          <Box className="treatment-type-container">
            {loading ? (
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : estimateQuestionFailed && estimateQuestionFailed === "Root Question Not Found" ? (
              <Typography variant="body2" color="#A6315C">
                Active Global Data Model does not exist. An Active Global Data Model must exist in
                order to create an estimate.
              </Typography>
            ) : (
              <Stack className="treatment-type">
                <InputLabel className="overflow-visible" required={questions.isRequired}>
                  {questions.questionText}
                </InputLabel>
                <Select
                  size="small"
                  name="treatmentType"
                  value={treatmentType}
                  defaultValue=""
                  sx={{ minWidth: 300 }}
                  required
                  onChange={handleChange}>
                  {treatmentOptions.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.responseNo}>
                        {item.responsetext}
                      </MenuItem>
                    );
                  })}
                </Select>
                {renderNextQuestions}
              </Stack>
            )}
          </Box>
          {!estimateQuestionFailed ? (
            <Stack direction="row" spacing={2} className="modal-bottom-section">
              <Button variant="outlined" onClick={close}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Save
              </Button>
            </Stack>
          ) : null}
        </Box>
      </Modal>

      {/* Success Modal */}
      <Modal open={successModal} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="alert-modal">
          <Stack direction="row" className="modal-stack">
            <Typography>Alert</Typography>
            <IconButton onClick={closeSuccessModal}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", py: 2 }}>
            {successAddEstimate && (
              <Typography variant="body2" color="#42ba96">
                {successAddEstimate}
              </Typography>
            )}
            {errorAddEstimate && (
              <Typography variant="body2" color="#A6315C">
                Unable to save - Pricing has not been established for the specific treatment plan,
                Please verify that all questions have been answered correctly.
              </Typography>
            )}
          </Stack>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
            <Button variant="contained" className="btn-primary" onClick={closeSuccessModal}>
              OK
            </Button>
          </Stack>
        </Box>
      </Modal>

      {loading ? (
        <Modal open={loading} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal" width="100px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Modal>
      ) : null}
    </Fragment>
  );
};

const mapStateToProps = ({ estimateReducer }) => {
  return {
    questions: estimateReducer.questions,
    loading: estimateReducer.loading,
    success: estimateReducer.success,
    error: estimateReducer.error,
    successAddEstimate: estimateReducer.successAddEstimate,
    errorAddEstimate: estimateReducer.errorAddEstimate,
    treatmentQstns: estimateReducer.treatmentQstns,
    treatmentQstnsLoading: estimateReducer.treatmentQstnsLoading,
    estimateQuestionFailed: estimateReducer.estimateQuestionFailed
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getFinancialEstimateQuestions: (practiceId) => {
      dispatch(actionType.getFinancialEstimateQuestions(practiceId));
    },
    getTreatmentPlanQuestions: (payload) => {
      dispatch(actionType.getTreatmentPlanQuestions(payload));
    },
    createEstimate: (payload) => {
      dispatch(actionType.createEstimate(payload));
    },
    resetCostCoverage: () => {
      dispatch(actionType.resetCostCoverage());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    resetMessageModal: () => {
      dispatch(actionType.resetMessageModal());
    },
    addTreatmentPlan: (payload) => {
      dispatch(actionType.addTreatmentPlan(payload));
    },
    resetCardClick: () => {
      dispatch(actionType.resetCardClick());
    },
    resetEstimateViewList: () => {
      dispatch(actionType.resetEstimateViewList());
    },
    editTreatmentPlan: (payload) => {
      dispatch(actionType.editTreatmentPlan(payload));
    },
    getFinancialEstimateViewList: (payload) => {
      dispatch(actionType.getFinancialEstimateViewList(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EstimateDetails);
