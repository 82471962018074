import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
// import * as token from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchComments(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };

    if (payload.commentType) {
      params.commentType = payload?.commentType;
    }
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/comments`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    if (payload.commentType) {
      if (payload.commentType === "Clinical") {
        yield put({
          type: actionTypes.CLINICAL_LIST_SUCCESS,
          payload: response.data
        });
      } else if (payload.commentType === "Financial") {
        yield put({
          type: actionTypes.FINANCIAL_LIST_SUCCESS,
          payload: response.data
        });
      }
    } else {
      yield put({
        type: actionTypes.COMMENT_LIST_SUCCESS,
        payload: response.data
      });
    }
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.COMMENT_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getCommentsSaga() {
  yield takeEvery(actionTypes.COMMENT_LIST_REQUESTED, fetchComments);
}

function* createNewComment(action) {
  const { payload } = action;
  const body = {
    comment: payload.comment,
    important: payload.markImp,
    commentType: payload?.commentType
  };

  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${payload.medCaseId}/comments`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_NEW_COMMENT_SUCCESS,
      successMsg: MSG.SAVED_SUCCESSFULLY,
      payload: response.data
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_COMMENT_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewComment() {
  yield takeEvery(actionTypes.ADD_NEW_COMMENT_REQUESTED, createNewComment);
}

export default function* commentsSaga() {
  yield all([getCommentsSaga(), addNewComment()]);
}
