import * as actionType from "../actions/ActionTypes";

const initialState = {
  staffAdminUserDetails: [],
  loading: false,
  error: null,
  success: null
};

const staffAdminUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.STAFF_ADMIN_USER_DETAILS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.STAFF_ADMIN_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        staffAdminUserDetails: action.payload,
        loading: false
      };
    }
    case actionType.STAFF_ADMIN_USER_DETAILS_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_STAFF_ADMIN_USER_DETAILS_LIST: {
      return {
        ...state,
        staffAdminUserDetails: []
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default staffAdminUserReducer;
