import React, { useCallback, useEffect, useState } from "react";
import { Typography, Stack, Grid, InputAdornment, Box } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import EditIcon from "@mui/icons-material/Edit";
import { personNumberConfig } from "../../shared/constants/renderConfigs";
import PatientInfoAddress from "./PatientInfoAddress";
import PatientInfoEmail from "./PatientInfoEmail";
import Phone from "./Phone";
import LoadingModal from "./modals/loadingModal";
import moment from "moment";

const Participant = (props) => {
  const [isOpen] = useState(false);
  const [isAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAddPopup, setIsAddPopup] = useState(false);
  const [billingAddress, setBillingAddress] = useState([]);
  const [phoneInfo, setPhoneInfo] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [pRId, setPRId] = useState(sessionStorage.getItem("practiceId"));
  const [perId, setPerId] = useState(sessionStorage.getItem("person_id"));

  const {
    participantList = [],
    actions,
    errorMsg,
    successMsg,
    loading,
    editParticipant,
    patientDetails,
    medcaseSuccess
  } = props;

  useEffect(() => {
    sessionStorage.setItem("person_id", patientDetails.personId);
    sessionStorage.setItem("practiceId", patientDetails.practiceId);
    sessionStorage.setItem("partnerId", patientDetails.partnerId);
    const practice_Id = sessionStorage.getItem("practiceId");
    const person_id = sessionStorage.getItem("person_id");
    setPerId(person_id);
    setPRId(practice_Id);
  }, [medcaseSuccess]);

  const initFetch = useCallback(() => {
    const payload = {
      practiceId: patientDetails.practiceId || pRId,
      personId: patientDetails.personId || perId
    };
    actions.getPraticipant(payload);
  }, []);

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(participantList, "patient")) {
      participantList["patient"] = { ...participantList };
    }
    const arr = [];
    const billingAdd = {
      type: "billing",
      billingAddrLine1: participantList?.patient?.billingAddrLine1,
      billingAddrLine2: participantList?.patient?.billingAddrLine2,
      billingCity: participantList?.patient?.billingCity,
      billingState: participantList?.patient?.billingState,
      billingZip: participantList?.patient?.billingZip
    };
    const mailingAdd = {
      type: "mailing",
      billingAddrLine1: participantList?.patient?.mailingAddrLine1,
      billingAddrLine2: participantList?.patient?.mailingAddrLine2,
      billingCity: participantList?.patient?.mailingCity,
      billingState: participantList?.patient?.mailingState,
      billingZip: participantList?.patient?.mailingZip
    };
    const telephoneData = [
      {
        type: "Home",
        number: participantList?.patient?.homePhone
      },
      {
        type: "Work",
        number: participantList?.patient?.workPhone
      },
      {
        type: "Cell",
        number: participantList?.patient?.cellPhone
      },
      {
        type: "Additional",
        number: participantList?.patient?.additionalPhone
      }
    ];
    const emailDatas = [
      {
        email: participantList?.patient?.email
      }
    ];
    arr.push(billingAdd);
    arr.push(mailingAdd);
    setBillingAddress(arr);
    setPhoneInfo(telephoneData);
    setEmailData(emailDatas);
    sessionStorage.setItem("person_id", patientDetails.personId);
    sessionStorage.setItem("practiceId", patientDetails.practiceId);
  }, [participantList]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    const participantListKeys = !Array.isArray(participantList)
      ? Object.keys(participantList)?.length
      : participantList;

    if (medcaseSuccess) {
      if (participantList.length == 0 || editParticipant || participantListKeys >= 0) {
        initFetch();
      }
    }
    return () => {};
  }, [errorMsg, successMsg, medcaseSuccess]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      [name]: value
    });
  };

  const closeModal = () => {
    setIsPopupOpen(false);
  };

  const phone = personNumberConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAddPopup
  });

  const handleChange = () => {
    if (participantList?.personKey == null || participantList?.personKey == "") {
      setIsPopupOpen(true);
      setIsAddPopup(false);
    }
  };

  const IconTextField = ({ iconEnd, ...props }) => {
    return (
      <TextField
        {...props}
        InputProps={{
          endAdornment: iconEnd ? (
            <InputAdornment size="small" position="end">
              {iconEnd}
            </InputAdornment>
          ) : null
        }}
      />
    );
  };

  const savePersonNumber = (event) => {
    event.preventDefault();
    if (!isAddPopup) {
      actions.updatePersonNumber(values);
    }
    closeModal();
  };

  return (
    <>
      <Stack className="pad-5" direction="row">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography sx={{ ml: 0.4 }}>First Name</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              sx={{ flex: 6 }}
              size="small"
              fullWidth
              name="dob"
              id="filled-basic"
              value={participantList?.firstName}
              className="add-description"
              disabled
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Last Name</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              sx={{ flex: 6 }}
              size="small"
              name="dob"
              fullWidth
              id="filled-basic"
              value={participantList?.lastName}
              className="add-description"
              disabled
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack className="pad-5" direction="row">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography sx={{ ml: 0.4 }}>DOB</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              sx={{ flex: 6 }}
              size="small"
              fullWidth
              name="dob"
              id="filled-basic"
              value={moment(new Date(participantList.birthDate)).format("L")}
              className="add-description"
              disabled
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}> Person Number</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <IconTextField
              sx={{ flex: 6 }}
              size="small"
              fullWidth
              name="personkey"
              id="filled-basic"
              value={participantList?.personKey}
              className="add-description"
              iconEnd={<EditIcon onClick={handleChange} />}
              disabled={
                participantList.personKey == null || participantList.personKey == "" ? false : true
              }
            />
          </Grid>
        </Grid>
      </Stack>
      <Box flexDirection="row">
        <PatientInfoAddress
          defaultCheckRadio={participantList.defaultAddress}
          billingAddress={billingAddress}
          tabValue="1"
          practiceId={patientDetails.practiceId}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Phone
            defaultCheckRadio={participantList.defaultPhone}
            phoneInfo={phoneInfo}
            practiceId={patientDetails.practiceId}
          />
        </Grid>
        <Grid item sm={6}>
          <PatientInfoEmail emailData={emailData} practiceId={patientDetails.practiceId} />
        </Grid>
      </Grid>
      <CommonModal open={isOpen} isAdd={isAdd} />
      <CommonModal
        open={isPopupOpen}
        config={phone}
        isAdd={isAddPopup}
        handleSubmit={savePersonNumber}
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <LoadingModal open={loading} />
    </>
  );
};
const mapStateToProps = ({ participantReducer, medcaseHeaderReducer }) => {
  return {
    loading: participantReducer.loading,
    participantList: participantReducer.participantList,
    editParticipant: participantReducer.editParticipant,
    errorMsg: participantReducer.error,
    successMsg: participantReducer.success,
    patientDetails: medcaseHeaderReducer.patientDetails,
    medcaseSuccess: medcaseHeaderReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPraticipant: (payload) => {
      dispatch(actionType.getParticiPant(payload));
    },
    editParticipant: (payload) => {
      dispatch(actionType.editParticipant(payload));
    },
    updatePersonNumber: (payload) => {
      dispatch(actionType.updatePersonNumber(payload));
    },
    resetParticipantList: (payload) => {
      dispatch(actionType.resetParticipantList(payload));
    },
    getPatientDetailsHeader: (payload) => {
      dispatch(actionType.getPatientDetailsHeader(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Participant);
