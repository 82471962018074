import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { ListSubheader, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const SelectField = ({
  label,
  required,
  mandatory,
  onChange,
  value,
  options,
  subHeader,
  tooltip,
  ...props
}) => {
  return (
    <>
      <Stack direction="row" className="modal-stack">
        {label ? (
          <InputLabel required={mandatory} className="flex-2 word-break">
            {label}
            {tooltip ? (
              <Tooltip title={tooltip} arrow followCursor={true}>
                <ErrorOutlineIcon fontSize="small" />
              </Tooltip>
            ) : null}
          </InputLabel>
        ) : null}
        <Select
          size="small"
          onChange={onChange}
          required={required}
          value={value}
          sx={{ flex: 6 }}
          {...props}>
          {options.map((item, index) => {
            return (
              <MenuItem key={index} value={typeof item == "object" ? item.id : item}>
                {typeof item == "object" ? item.name : item}
              </MenuItem>
            );
          })}
          {subHeader ? <ListSubheader className="background-grey">Other</ListSubheader> : null}
          {subHeader
            ? subHeader.map((item, key) => {
                return (
                  <MenuItem key={key} value={item}>
                    {item}
                  </MenuItem>
                );
              })
            : null}
        </Select>
      </Stack>
    </>
  );
};

export default SelectField;
