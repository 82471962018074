import * as actionType from "./ActionTypes";

export const getQuickAddList = (payload) => {
  return {
    type: actionType.QUICK_ADD_LIST_REQUESTED,
    payload: payload
  };
};
export const addNewQuickAdd = (payload) => {
  return {
    type: actionType.ADD_NEW_QUICK_ADD_REQUESTED,
    payload: payload
  };
};
export const editQuickAdd = (payload) => {
  return {
    type: actionType.EDIT_QUICK_ADD_REQUESTED,
    payload: payload
  };
};
export const deleteQuickAdd = (payload) => {
  return {
    type: actionType.DELETE_QUICK_ADD_REQUESTED,
    payload: payload
  };
};
export const resetQuickAddList = () => {
  return {
    type: actionType.RESET_QUICK_ADD_LIST
  };
};
export const resetMessage = () => {
  return {
    type: actionType.RESET_MESSAGE,
    success: false,
    error: false
  };
};

export const getUtilitySearchList = (payload) => {
  return {
    type: actionType.UTILITYSEARCH_LIST_REQUESTED,
    payload: payload
  };
};
export const resetUtilitySearchList = () => {
  return {
    type: actionType.RESET_UTILITYSEARCH_LIST
  };
};

export const getUserProfileDetails = (payload) => {
  return {
    type: actionType.USER_PROFILE_DETAILS_REQUESTED,
    payload: payload
  };
};

//get inbox,draft, sent and delete messages list
export const getMessagesList = (payload) => {
  return {
    type: actionType.LIST_OF_MESSAGES_REQUESTED,
    payload
  };
};

//for sending the message
export const createNewMessage = (payload) => {
  return {
    type: actionType.CREATE_NEW_MESSAGE_REQUESTED,
    payload
  };
};

//toggling message read status
export const toggleMessageIsReadStatus = (payload) => {
  return {
    type: actionType.TOGGLE_MESSAGE_ISREAD_STATUS_REQUESTED,
    payload
  };
};

//delete messages from list
export const deleteMessageFromList = (payload) => {
  return {
    type: actionType.DELETE_MESSAGE_REQUESTED,
    payload: payload
  };
};

//update messages
export const updateMultipleMessages = (payload) => {
  return {
    type: actionType.UPDATE_MULTIPLE_MESSAGES_REQUESTED,
    payload
  };
};
//getlistof all users
export const getAllUsersList = (payload) => {
  return {
    type: actionType.USERS_LIST_REQUESTED,
    payload: payload
  };
};

//reset messages status
export const resetMessageExecuteStatus = () => {
  return {
    type: actionType.RESET_MESSAGES_EXECUTE_STATUS
  };
};

export const enablePracticeNavFlag = () => {
  return {
    type: actionType.ENABLE_FLAG_NAVBAR
  };
};
// Quick add for admin patient portal
export const userIdGetQuickAddList = (payload) => {
  return {
    type: actionType.USER_ID_QUICK_ADD_LIST_REQUESTED,
    payload
  };
};
//fetch file
export const getFile = (payload) => {
  return {
    type: actionType.GET_FILE_REQUESTED,
    payload
  };
};

export const userIdAddNewQuickAdd = (payload) => {
  return {
    type: actionType.USER_ID_ADD_NEW_QUICK_ADD_REQUESTED,
    payload
  };
};

export const userIdEditQuickAdd = (payload) => {
  return {
    type: actionType.USER_ID_EDIT_QUICK_ADD_REQUESTED,
    payload
  };
};

export const userIdDeleteQuickAdd = (payload) => {
  return {
    type: actionType.USER_ID_DELETE_QUICK_ADD_REQUESTED,
    payload
  };
};
//send file
export const sendFile = (payload) => {
  return {
    type: actionType.POST_FILE_REQUESTED,
    payload
  };
};
