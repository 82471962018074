import React, { useState } from "react";
import { Box, Stack, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Unapproved from "./Unapproved";
import ManualReview from "./ManualReview";
import Approved from "./Approved";
import Exported from "./Exported";
import Rejected from "./Rejected";
//import ReimbursementDetails from "./ReimbursementDetails";

const ProviderReimbursement = () => {
  const [tabValue, setTabValue] = useState("1");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <Box flexDirection="row" className="m-8">
      <TabContext value={tabValue}>
        <Box>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
              <Tab label="Unapproved" value="1" />
              <Tab label="Manual Review" value="2" />
              <Tab label="Approved" value="3" />
              <Tab label="Exported" value="4" />
              <Tab label="Rejected" value="5" />
            </TabList>
          </Stack>
        </Box>
        <TabPanel value="1">
          <Unapproved />
        </TabPanel>
        <TabPanel value="2">{<ManualReview />}</TabPanel>
        <TabPanel value="3">{<Approved />}</TabPanel>
        <TabPanel value="4">{<Exported />}</TabPanel>
        <TabPanel value="5">{<Rejected />}</TabPanel>
      </TabContext>
      {/* //<ReimbursementDetails /> */}
    </Box>
  );
};

export default ProviderReimbursement;
