import * as actionType from "../actions/ActionTypes";

const initialState = {
  paymentTimingList: [],
  loading: false,
  addPaymentTiming: null,
  editPaymentTiming: null,
  error: null,
  success: false
};

const paymentTimingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PAYMENT_TIMING_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PAYMENT_TIMING_LIST_SUCCESS: {
      return {
        ...state,
        paymentTimingList: action.payload,
        loading: false
      };
    }
    case actionType.PAYMENT_TIMING_LIST_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.ADD_PAYMENT_TIMING_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_PAYMENT_TIMING_SUCCESS: {
      return {
        ...state,
        addPaymentTiming: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_PAYMENT_TIMING_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_PAYMENT_TIMING_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PAYMENT_TIMING_SUCCESS: {
      return {
        ...state,
        editPaymentTiming: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_PAYMENT_TIMING_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_PAYMENT_TIMING_LIST: {
      return {
        ...state,
        addPaymentTiming: null,
        editPaymentTiming: null,
        loading: null
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default paymentTimingReducer;
