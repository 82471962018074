import React, { useState, useEffect } from "react";
import { Box, Stack, TextField, Grid, Typography, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as actionType from "../actions";
import { connect } from "react-redux";
import moment from "moment";
import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const FinSnapshot605All = (props) => {
  const formData = {
    from: null,
    to: null
  };
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [values, setValues] = useState(formData);
  const { finSnapshot605AllList = [], actions, loading } = props;
  const callAPI = () => {
    actions.getFinSnapshot605All({
      startDate: values.from,
      reportType: "Financial Snapshot 605 All",
      endDate: values.to
    });
  };
  const rows = finSnapshot605AllList.map((item, index) => {
    item.id = index;
    return item;
  });
  useEffect(() => {
    return actions.resetMessage();
  }, []);
  function CustomToolbar() {
    return (
      <Grid container className="align-center">
        <Grid item xs={0.75}>
          <Typography style={{ marginLeft: "10px" }}>Start Date*</Typography>
        </Grid>
        <Grid item xs={2.25}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              openTo="day"
              views={["day", "year", "month"]}
              disableFuture
              value={values.from}
              onChange={(newValue) => {
                const format2 = "YYYY-MM-DD";
                newValue = moment(newValue).format(format2);
                setValues({ ...values, from: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" className="patient-dropdown" fullWidth />
              )}></DatePicker>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={0.25}></Grid>
        <Grid item xs={0.75}>
          <Typography style={{ marginLeft: "10px" }}>End Date*</Typography>
        </Grid>
        <Grid item xs={2.25}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              openTo="day"
              views={["day", "year", "month"]}
              disableFuture
              value={values.to}
              onChange={(newValue) => {
                const format2 = "YYYY-MM-DD";
                newValue = moment(newValue).format(format2);
                setValues({ ...values, to: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" className="patient-dropdown" fullWidth />
              )}></DatePicker>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2.75}></Grid>
        <Grid item xs={1.5}>
          <Button variant="contained" className="btn-usf" onClick={callAPI}>
            Run Report
          </Button>
        </Grid>
      </Grid>
    );
  }
  const columns = [
    {
      field: "medCaseId",
      headerName: "MEDCASE ID",
      width: 140,
      editable: false
    },
    {
      field: "practiceName",
      headerName: "PRACTICE NAME",
      width: 140,
      editable: false
    },
    {
      field: "priorReceipts",
      headerName: "PRIOR RECEIPTS",
      width: 140,
      editable: false,
      renderCell: ({ row }) => (row.priorReceipts === null ? "--" : row.priorReceipts)
    },
    {
      field: "practicePayment",
      headerName: "PRACTICE PAYMENT",
      width: 140,
      editable: false,
      renderCell: ({ row }) => (row.practicePayment === null ? "--" : row.practicePayment)
    },
    {
      field: "receipts",
      headerName: "RECEIPTS",
      width: 140,
      editable: false,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.receipts);
      }
    },
    {
      field: "programRefunds",
      headerName: "PROGRAM REFUNDS",
      width: 140,
      editable: false,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.programRefunds);
      }
    },
    {
      field: "pregnancyRefund",
      headerName: "PREGNANCY REFUNDS",
      width: 140,
      editable: false,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.pregnancyRefund);
      }
    },
    {
      field: "miscellaneousRefund",
      headerName: "MISC REFUNDS",
      width: 140,
      editable: false,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.miscellaneousRefund);
      }
    },
    {
      field: "medicalCostReserve",
      headerName: "MEDICAL COST RESERVE",
      width: 140,
      editable: false,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.medicalCostReserve);
      }
    },
    {
      field: "recognizedRevenue",
      headerName: "RECOGNIZED REVENUE",
      width: 140,
      editable: false,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.recognizedRevenue);
      }
    }
  ];
  return (
    <>
      <Stack direction="row" className="pad-5 m-t-8"></Stack>
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            localeText={"Export Button"}
            components={<CustomToolbar />}
            isExport={true}
            headerText={`Finanacial Snapshot 605 All Start Date: ${values.from} End Date: ${values.to}`}
            disableRowSelectionOnClick={true}
          />
        </Box>
      </Box>
    </>
  );
};
const mapStateToProps = ({ finSnapshot605AllReducer }) => {
  return {
    loading: finSnapshot605AllReducer.loading,
    finSnapshot605AllList: finSnapshot605AllReducer.finSnapshot605AllList,
    errorMsg: finSnapshot605AllReducer.error,
    successMsg: finSnapshot605AllReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getFinSnapshot605All: (payload) => {
      dispatch(actionType.getFinSnapshot605All(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FinSnapshot605All);
