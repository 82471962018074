// import Close from "@mui/icons-material/Close";
import { Divider, IconButton, Stack, Typography } from "@mui/material";

const HeaderField = ({ title, onClose }) => {
  return (
    <>
      <Stack direction="row" className="modal-stack">
        <Typography>{title}</Typography>
        <IconButton onClick={onClose}>
          <img alt="" src="/Assets/close.png" />
        </IconButton>
      </Stack>
      <Divider className="m-t-10" />
    </>
  );
};

export default HeaderField;
