import * as actionType from "../actions/ActionTypes";

const initialState = {
  physicians: [],
  addPhysician: null,
  editPhysician: null,
  loading: false,
  error: null,
  success: false
};

const physiciansReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PHYSICIAN_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PHYSICIAN_LIST_SUCCESS: {
      return {
        ...state,
        physicians: action.payload,
        loading: false
      };
    }
    case actionType.PHYSICIAN_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_PHYSICIAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_PHYSICIAN_SUCCESS: {
      return {
        ...state,
        addPhysician: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_PHYSICIAN_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.EDIT_PHYSICIAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PHYSICIAN_SUCCESS: {
      return {
        ...state,
        editPhysician: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_PHYSICIAN_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.RESET_PHYSICIAN_LIST: {
      return {
        ...state,
        addPhysician: null,
        editPhysician: null,
        loading: null
      };
    }
    case actionType.RESET_PHYSICIAN_ROW: {
      return {
        ...state,
        physicians: [],
        loading: null
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default physiciansReducer;
