import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
// import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* getRevenueValidation(action) {
  const { payload } = action;
  try {
    const params = {
      program: payload.enrolledProgram,
      treatmentPlan: payload.enrolledTreatmentPlan,
      practice: payload.practiceId,
      medcaseStatus: payload.medcaseStatus
    };
    const requestBody = createRequestBody(
      "get",
      `/financial_snapshots/revenue-validation`,
      params,
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    console.log("zhr response", response);
    yield put({
      type: actionTypes.GET_REVENUEVALIDATION_REPORT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_REVENUEVALIDATION_REPORT_FAILED,
        errorMsg: error.message
      });
    }
  }
}

function* getRevenueValidationSaga() {
  yield takeEvery(actionTypes.GET_REVENUE_VALIDATION, getRevenueValidation);
}

function* getAllTreatmentPlan() {
  try {
    const params = {
      limit: "1000",
      offset: ""
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/ALL/gdms/ALL/treatment-plans`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ALL_TREATMENT_PLAN_DATA,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ALL_TREATMENT_PLAN_FAILED,
        errorMsg: error.message
      });
    }
  }
}

function* getAllTreatmentPlanSaga() {
  yield takeEvery(actionTypes.GET_ALL_TREATMENT_PLAN, getAllTreatmentPlan);
}

export default function* revenueValidationSaga() {
  yield all([getRevenueValidationSaga(), getAllTreatmentPlanSaga()]);
}
