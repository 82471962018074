import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Program from "./Program";
import Multiplier from "./Multiplier";
import { useDispatch, useSelector } from "react-redux";
import * as actionType from "../actions";
import { useNavigate } from "react-router-dom";

const ProgramMultiplier = () => {
  const [value, setValue] = useState("1");
  const [btnText, setBtnText] = useState("New Program");
  const disabled = localStorage.getItem("status") !== "In-progress" ? true : false;
  const [programModal, setProgramMadal] = useState(false);
  const [multiplierModal, setMultiplierModal] = useState(false);
  const disaptch = useDispatch();
  const navigate = useNavigate();
  const globalData = useSelector((state) => state.globalDataModelReducer.globalData);
  const gdmId = sessionStorage.getItem("gdmId");

  const handleChange = (event, newValue) => {
    let text = event.target.innerText.split(" ");
    let res = text[0][0].toUpperCase() + text[0].slice(1).toLowerCase() + " ";
    res += text[1] ? text[1][0].toUpperCase() + text[1].slice(1).toLowerCase() : "";
    setBtnText("New " + res);
    setValue(newValue);
    if (newValue === "2") {
      disaptch(actionType.resetMultiplierList());
    }
  };

  const handleclick = (e) => {
    e.preventDefault();
    if (value === "1") {
      setProgramMadal(true);
    }
    if (value === "2") {
      setMultiplierModal(true);
    }
  };

  const program = (value) => {
    setProgramMadal(value);
  };

  const multiplier = (value) => {
    setMultiplierModal(value);
  };

  const handleFileUpload = () => {
    navigate(`loadByExcel`);
  };

  const isDisabled =
    globalData && globalData.filter((row) => row.gdmId === gdmId && row.status === "In-progress");

  return (
    <Box>
      <Box flexDirection="row" className="m-r-10 m-l-10">
        <TabContext value={value}>
          <Box>
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <TabList aria-label="lab API tabs example" onChange={handleChange}>
                <Tab label="Program" value="1" />
                <Tab label="Multiplier" value="2" />
              </TabList>
              <Stack direction="row">
                {value === "2" && (
                  <Button
                    variant="outlined"
                    className="btn-primary m-r-10"
                    disabled={isDisabled.length > 0 ? false : true}
                    onClick={handleFileUpload}>
                    Load Multiplier by Excel
                  </Button>
                )}
                <Button
                  disabled={disabled}
                  variant="contained"
                  className="btn-primary"
                  onClick={handleclick}>
                  {btnText}
                </Button>
              </Stack>
            </Stack>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            <Program toggleModal={programModal} program={program} />
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            <Multiplier toggleModal={multiplierModal} multiplier={multiplier} />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default ProgramMultiplier;
