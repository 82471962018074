import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

// get All estimate questions list
function* fetchEstimateQuestionsList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload}/financialEstimateQuestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.FINANCIAL_ESTIMATE_QUESTIONS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.FINANCIAL_ESTIMATE_QUESTIONS_FAILED,
        message: (error && error.response && error.response.data) || error.message
      });
    }
  }
}

function* fetchEstimateQuestionsSaga() {
  yield takeEvery(actionTypes.FINANCIAL_ESTIMATE_QUESTIONS_REQUESTED, fetchEstimateQuestionsList);
}

//create estimates
function* createEstimates(action) {
  const { payload } = action;
  const { practiceId, patientId, questionResponseList, query } = payload;

  try {
    const params = {
      estimateType: query
    };
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/patients/${patientId}/estimates`,
      params,
      questionResponseList
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.CREATE_FINANCIAL_ESTIMATE_SUCCESS,
      payload: response.data,
      successMsg: "Treatment option added successfully"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.CREATE_FINANCIAL_ESTIMATE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* createEstimateSaga() {
  yield takeEvery(actionTypes.CREATE_FINANCIAL_ESTIMATE_REQUESTED, createEstimates);
}

//create treatment plan
function* createTreatmentPlan(action) {
  const { payload } = action;
  const { practiceId, patientId, estimateId, questionResponseList } = payload;

  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/patients/${patientId}/estimates/${estimateId}/treatment-plans`,
      "",
      questionResponseList
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CREATE_TREATMENT_PLAN_SUCCESS,
      payload: response.data,
      successMsg: "Treatment option added successfully"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.CREATE_TREATMENT_PLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* createTreatmentPlanSaga() {
  yield takeEvery(actionTypes.CREATE_TREATMENT_PLAN_REQUESTED, createTreatmentPlan);
}

// get estimate list
function* fetchFinancialEstimateList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000",
      ...payload.values
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/estimates`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.FINANCIAL_ESTIMATE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.FINANCIAL_ESTIMATE_LIST_FAILED,
        message: error.message
      });
    }
  }
}

function* fetchFinancialEstimateListSaga() {
  yield takeEvery(actionTypes.FINANCIAL_ESTIMATE_LIST_REQUESTED, fetchFinancialEstimateList);
}

// get estimate view list
function* getFinancialEstimateViewList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/patients/${payload.personId}/estimates`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.FINANCIAL_ESTIMATE_VIEW_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.FINANCIAL_ESTIMATE_VIEW_LIST_FAILED,
        message: error.message
      });
    }
  }
}

function* getFinancialEstimateViewListSaga() {
  yield takeEvery(actionTypes.FINANCIAL_ESTIMATE_VIEW_LIST_REQUESTED, getFinancialEstimateViewList);
}

// get estimate view list
function* getSystemConfigurationList(/* action */) {
  // const { payload } = action;
  // console.log("payload", payload);
  try {
    const params = {
      offset: "",
      limit: "10000",
      key: "APPLY_LOAN_URL"
    };
    const requestBody = createRequestBody("get", `/practices/system-configuration`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // console.log("response", response);
    yield put({
      type: actionTypes.SYSTEM_CONFIGURATION_VIEW_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.SYSTEM_CONFIGURATION_VIEW_LIST_FAILED,
        message: error.message
      });
    }
  }
}

function* getSystemConfigurationListSaga() {
  yield takeEvery(actionTypes.SYSTEM_CONFIGURATION_VIEW_LIST_REQUESTED, getSystemConfigurationList);
}

// get estimate notes
function* getFinancialEstimateNote(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/estimates/${payload.estimateId}/notes`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.FINANCIAL_ESTIMATE_NOTES_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.FINANCIAL_ESTIMATE_NOTES_FAILED,
        message: error.message
      });
    }
  }
}

function* getFinancialEstimateNoteSaga() {
  yield takeEvery(actionTypes.FINANCIAL_ESTIMATE_NOTES_REQUESTED, getFinancialEstimateNote);
}

//create estimate note
function* createEstimateNotes(action) {
  const { payload } = action;
  const { practiceId, estimateId, estimateNotes } = payload;
  const body = {
    estimateNotes
  };

  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/estimates/${estimateId}/notes`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.FINANCIAL_ESTIMATE_ADD_NOTES_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.FINANCIAL_ESTIMATE_ADD_NOTES_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* createEstimateNoteSaga() {
  yield takeEvery(actionTypes.FINANCIAL_ESTIMATE_ADD_NOTES_REQUESTED, createEstimateNotes);
}

//get estimate cards
function* getEstimateCards(action) {
  const { payload } = action;
  const { practiceId, estimateId } = payload;

  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/estimates/${estimateId}/treatment-plans`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    if (response && response.data) {
      response.data.sort(function (a, b) {
        if (a.finEstimateTpId < b.finEstimateTpId) {
          return -1;
        }
        if (a.finEstimateTpId > b.finEstimateTpId) {
          return 1;
        }
        return 0;
      });
    }

    yield put({
      type: actionTypes.FINANCIAL_ESTIMATE_CARDS_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.FINANCIAL_ESTIMATE_CARDS_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* getEstimateCardSaga() {
  yield takeEvery(actionTypes.FINANCIAL_ESTIMATE_CARDS_REQUESTED, getEstimateCards);
}
// get estimate list
function* fetchFertilityAccessPrograms(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/estimates/${payload.estimateId}/estimateTreatmentPlans/${payload.estimateTpId}/fertilityAccessPrograms`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.FERTILTY_ACCESS_PROGRAMS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.FERTILTY_ACCESS_PROGRAMS_FAILED,
        message: error.response.data
      });
    }
  }
}

function* fetchFertilityAccessProgramsSaga() {
  yield takeEvery(actionTypes.FERTILTY_ACCESS_PROGRAMS_REQUESTED, fetchFertilityAccessPrograms);
}

//publish to patient portal
function* publishToPatientPortal(action) {
  const { payload } = action;
  const { practiceId, estimateId, actions } = payload;

  try {
    const params = {
      action: actions
    };
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/estimates/${estimateId}/`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PUBLISH_SUCCESS,
      payload: response.data,
      successMsg: actions == "publish" ? "Published Successfully" : "Reviewed Successfully"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.PUBLISH_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* publishToPatientPortalSaga() {
  yield takeEvery(actionTypes.PUBLISH_REQUESTED, publishToPatientPortal);
}

// get treatment qstns
function* getTreatmentPlanQuestions(action) {
  const { payload } = action;
  const { estimateId, estimateTpId } = payload;
  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/treatmentQuestionResponses`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_TREATMENT_QUESTIONS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_TREATMENT_QUESTIONS_FAILED,
        message: error.message
      });
    }
  }
}

function* getTreatmentPlanQuestionsSaga() {
  yield takeEvery(actionTypes.GET_TREATMENT_QUESTIONS_REQUESTED, getTreatmentPlanQuestions);
}

//edit treatment plan
function* editTreatmentPlan(action) {
  const { payload } = action;
  const { practiceId, patientId, estimateId, estimateTpId, questionResponseList } = payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/patients/${patientId}/estimates/${estimateId}/treatment-plans/${estimateTpId}`,
      "",
      questionResponseList
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_TREATMENT_PLAN_SUCCESS,
      payload: response.data,
      successMsg: "Treatment option edited successfully"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_TREATMENT_PLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editTreatmentPlanSaga() {
  yield takeEvery(actionTypes.EDIT_TREATMENT_PLAN_REQUESTED, editTreatmentPlan);
}

function* deleteEstimateTreatmentPlan(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "delete",
      `/estimates/${payload.estimateId}/estimateTreatmentPlans/${payload.estimateTpId}/removeEstimateTP`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.DELETE_ESTIMATE_TREATMENT_PLAN_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_ESTIMATE_TREATMENT_PLAN_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteEstimateTreatmentPlanSaga() {
  yield takeEvery(
    actionTypes.DELETE_ESTIMATE_TREATMENT_PLAN_REQUESTED,
    deleteEstimateTreatmentPlan
  );
}

// get estimate view list
function* getEstimatePdfList(action) {
  const { payload } = action;
  try {
    // const params = {
    //   offset: "",
    //   limit: "10000"
    // };
    let payloadNew = {
      dashboard: payload.dashboard
    };
    const requestBody = createRequestBody(
      "put",
      // `/practices/${payload.practiceId}/gdms/${payload.gdmId}/stages/getAllTreatmentPlanData`,
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/stages/null/allTreatmentPlanData`,
      "",
      payloadNew
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ESTIMATED_LIST_PDF_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    // console.log("error", error);
    // console.log("error", error.response);
    // if (appAuthencationCheck(error) === true) {
    //   yield put({
    //     type: actionTypes.ESTIMATED_LIST_PDF_FAILED,
    //     message: error.message
    //   });
    // }
  }
}

function* getEstimatePdfListSaga() {
  yield takeEvery(actionTypes.ESTIMATED_LIST_PDF_REQUESTED, getEstimatePdfList);
}

export default function* estimateSaga() {
  yield all([
    fetchEstimateQuestionsSaga(),
    createEstimateSaga(),
    fetchFinancialEstimateListSaga(),
    getFinancialEstimateViewListSaga(),
    getFinancialEstimateNoteSaga(),
    createEstimateNoteSaga(),
    getEstimateCardSaga(),
    fetchFertilityAccessProgramsSaga(),
    publishToPatientPortalSaga(),
    getTreatmentPlanQuestionsSaga(),
    createTreatmentPlanSaga(),
    editTreatmentPlanSaga(),
    deleteEstimateTreatmentPlanSaga(),
    getSystemConfigurationListSaga(),
    getEstimatePdfListSaga()
  ]);
}
