import * as actionType from "../actions/ActionTypes";
const initialState = {
  emailDocList: [],
  loading: false,
  addEmailDoc: null,
  editEmailDoc: null,
  error: null,
  success: false
};
const emailDocumentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.EMAIL_DOC_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EMAIL_DOC_LIST_SUCCESS: {
      return {
        ...state,
        emailDocList: action.payload,
        loading: false
      };
    }
    case actionType.EMAIL_DOC_LIST_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.ADD_EMAIL_DOC_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_EMAIL_DOC_SUCCESS: {
      return {
        ...state,
        addEmailDoc: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_EMAIL_DOC_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.RESET_EMAIL_DOC: {
      return {
        ...state,
        addEmailDoc: null,
        editEmailDoc: null,
        loading: null
      };
    }
    case actionType.EDIT_EMAIL_DOC_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_EMAIL_DOC_SUCCESS: {
      return {
        ...state,
        editEmailDoc: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_EMAIL_DOC_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    case actionType.RESET_EMAIL_DOC_LIST: {
      return {
        ...state,
        emailDocList: [],
        loading: null
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default emailDocumentReducer;
