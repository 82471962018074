import React, { useState, useEffect } from "react";
import { Box, Stack, Grid, Button, TextField, Typography, Autocomplete } from "@mui/material";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import * as actionType from "../actions";
import { connect } from "react-redux";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import { getPractices } from "../../admin/actions";

const OutcomeReport = (props) => {
  const { outcomeReportList = [], practices, success, loading, actions } = props;
  const defaultValues = {
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
    practiceId: null
  };

  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [rows, setRows] = useState([]);
  const [values, setValues] = useState(defaultValues);

  useEffect(() => {
    if (outcomeReportList) {
      const rows = outcomeReportList.map((res, indx) => {
        return { ...res, id: indx };
      });
      setRows(rows);
    }
  }, [outcomeReportList, success]);

  const handleRunReport = () => {
    const payload = {
      outcomeDateFrom: values.from,
      outcomeDateTo: values.to,
      practiceId: values.practiceId
    };
    console.log("zhr payload", payload);
    actions.getOutcomeReport(payload);
  };
  useEffect(() => {
    actions.getPractices();
  }, []);
  const handleValuesChange = (e, name, value) => {
    console.log("name", name, value);
    if (value) {
      setValues({ ...values, [name]: value.value });
    } else {
      setValues({ ...values, [name]: "" });
    }
  };

  function CustomToolbar() {
    return (
      <Grid container className="align-center">
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={1.5}>
              <Typography>Outcome Date From*</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  views={["day", "year", "month"]}
                  disableFuture
                  value={values.from}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).format(format2);
                    setValues({ ...values, from: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} sm={1.5}>
              <Typography>Outcome Date To*</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  views={["day", "year", "month"]}
                  disableFuture
                  value={values.to}
                  onChange={(newValue) => {
                    const format2 = "YYYY-MM-DD";
                    newValue = moment(newValue).format(format2);
                    setValues({ ...values, to: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center" mt={1}>
            {/* Practice */}
            <Grid item xs={12} sm={1.5}>
              <Typography>Practice</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="practiceId"
                value={
                  practices.find((practice) => practice.practiceId === values?.practiceId)?.name
                }
                options={practices.map((item, index) => ({
                  id: index,
                  label: item.name,
                  value: item.practiceId
                }))}
                onChange={(e, value) => {
                  handleValuesChange(e, "practiceId", value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." size="small" />}
                fullWidth
              />
            </Grid>
            <Grid item xs={1.5}>
              <Button variant="contained" className="btn-usf" onClick={handleRunReport}>
                Run Report
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  const columns = [
    {
      field: "patientName",
      headerName: "PATIEN FULL NAME",
      flex: 1,
      editable: false
    },
    {
      field: "medcaseId",
      headerName: "MEDCASE ID",
      flex: 1,
      editable: false
    },
    {
      field: "practiceName",
      headerName: "PRACTICE SHORT NAME",
      flex: 1,
      editable: false
    },
    {
      field: "progShortName",
      headerName: "PROGRAM",
      flex: 1,
      editable: false
    },
    {
      field: "treatmentPlan",
      headerName: "TREATMENT PLAN",
      flex: 1,
      editable: false,
      renderCell: ({ row }) =>
        row?.treatmentPlanCustomName != null ? row?.treatmentPlanCustomName : row?.treatmentPlan
    },
    {
      field: "cycleNo",
      headerName: "CYCLE NO",
      flex: 1,
      editable: false
    },
    {
      field: "outcome",
      headerName: "OUTCOME",
      flex: 1,
      editable: false
    },
    {
      field: "outcomeDate",
      headerName: "OUTCOME DATE",
      flex: 1,
      editable: false
    },
    {
      field: "normalEmbryos",
      headerName: "NORMAL EMBRYOS REMAINING",
      flex: 1,
      editable: false
    },
    {
      field: "payment",
      headerName: "PAYMENT",
      flex: 1,
      editable: false
    },
    {
      field: "paymentDate",
      headerName: "PAYMENT DATE",
      flex: 1,
      editable: false
    },
    {
      field: "serviceRevenue",
      headerName: "SERVICE REVENUE",
      flex: 1,
      editable: false
    },
    {
      field: "revenueDate",
      headerName: "REVENUE DATE",
      flex: 1,
      editable: false
    }
  ];

  return (
    <>
      <Stack direction="row" className="pad-5 m-t-8"></Stack>
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 20, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            localeText={"Export Button"}
            components={<CustomToolbar />}
            isExport={true}
            headerText={`Outcome`}
            disableRowSelectionOnClick={true}
          />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({ outcomeReportReducer, practices }) => {
  return {
    outcomeReportList: outcomeReportReducer.outcomeReportList,
    loading: outcomeReportReducer.loading,
    error: outcomeReportReducer.error,
    success: outcomeReportReducer.success,
    practices: practices.practices
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getOutcomeReport: (payload) => {
      dispatch(actionType.getOutcomeReport(payload));
    },
    outcomeReportResetMessage: () => {
      dispatch(actionType.outcomeReportResetMessage());
    },
    getPractices: () => {
      dispatch(getPractices());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(OutcomeReport);
