export const assertDefined = (object, name) => {
  if (object === undefined) {
    throw name + " must be defined";
  } else {
    return object;
  }
};

export const assertParametersDefined = (params, keys, ignore) => {
  if (keys === undefined) {
    return;
  }
  if (keys.length > 0 && params === undefined) {
    params = {};
  }
  for (var i = 0; i < keys.length; i++) {
    if (!contains(ignore, keys[i])) {
      assertDefined(params[keys[i]], keys[i]);
    }
  }
};

export const parseParametersToObject = (params, keys) => {
  if (params === undefined) {
    return {};
  }
  var object = {};
  for (var i = 0; i < keys.length; i++) {
    object[keys[i]] = params[keys[i]];
  }
  return object;
};

export const contains = (a, obj) => {
  if (a === undefined) {
    return false;
  }
  var i = a.length;
  while (i--) {
    if (a[i] === obj) {
      return true;
    }
  }
  return false;
};

export const copy = (obj) => {
  if (null == obj || "object" != typeof obj) return obj;
  var copy = obj.constructor();
  for (var attr in obj) {
    // eslint-disable-next-line
    if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
  }
  return copy;
};

export const mergeInto = (baseObj, additionalProps) => {
  if (null == baseObj || "object" != typeof baseObj) return baseObj;
  var merged = baseObj.constructor();
  for (var attr in baseObj) {
    // eslint-disable-next-line
    if (baseObj.hasOwnProperty(attr)) merged[attr] = baseObj[attr];
  }
  if (null == additionalProps || "object" != typeof additionalProps) return baseObj;
  for (attr in additionalProps) {
    // eslint-disable-next-line
    if (additionalProps.hasOwnProperty(attr)) merged[attr] = additionalProps[attr];
  }
  return merged;
};
