import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store/store";
import { ThemeProvider } from "@mui/material";
import { felixTheme } from "./felixTheme";

import { LicenseInfo } from "@mui/x-license";

LicenseInfo.setLicenseKey(
  "39e03f3a4d4a7959948b694762d47018Tz05MjA0OCxFPTE3NDkzMTQ3ODUwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y"
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <ThemeProvider theme={felixTheme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.Fragment>
);

reportWebVitals();
