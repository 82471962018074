import "./messages.scss";
import {
  Box,
  Button,
  Stack,
  Typography,
  IconButton,
  Grid,
  TextField,
  MenuItem,
  Select,
  Divider,
  Modal,
  InputLabel,
  FormControl,
  CircularProgress
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import MessageConfiguration from "./MessageConfiguration";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import QuickAdd from "../components/QuickAdd";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { connect } from "react-redux";
import * as actionType from "../actions";
import { InitialValuesForMessage, validateFileSize } from "../constants/constants";
import { getQueryString } from "../constants/constants";
import LoadingModal from "../../trak/components/modals/loadingModal";

const Messages = (props) => {
  const {
    actions,
    allUsersList,
    assignToUsersList,
    sentMessageLoading,
    updateMessagesLoading,
    successCreateMessage,
    updatedSuccessMsg,
    errorCreateMessage,
    updatedErrorMsg,
    deletedMessageLoading,
    postedFilePath,
    postFileLoading
  } = props;
  const [urlPart, setUrlPart] = useState("");
  const location = useLocation();

  const defaultValue = {
    patientNameOrId: "",
    assignedToId: "All",
    type: "All",
    fromDate: null,
    toDate: null,
    messageStatus: ""
  };
  useEffect(() => {
    let splitTheUrl = window.location.pathname.split("/").pop();
    splitTheUrl = splitTheUrl.charAt(0).toUpperCase() + splitTheUrl.slice(1);
    if (splitTheUrl == "Drafts") {
      splitTheUrl = splitTheUrl.slice(0, -1);
      setUrlPart(splitTheUrl);
    } else if (splitTheUrl == "Inbox" || splitTheUrl == "Sent" || splitTheUrl == "Deleted") {
      setUrlPart(splitTheUrl);
    }
  }, [window.location.pathname]);
  useEffect(() => {
    filterParameters.messageStatus = urlPart;
    setFilterParameters({
      ...filterParameters
    });
  }, [urlPart]);
  const [filterParameters, setFilterParameters] = useState(defaultValue);
  const [values, setValues] = useState(InitialValuesForMessage);
  const [composeModalOpen, setComposeModalOpen] = useState(false);
  const [alertMessageModalOpen, setAlertMessageModalOpen] = useState(false);
  const [openNavigationModal, setOpenNavigationModal] = useState(false);
  const [openSendMessagePopUp, setOpenSendMessagePopUp] = useState(false);
  const [composeMessage, setComposeMessage] = useState("");
  const [composeMessageType, setComposeMessageType] = useState("");
  const [helpRequested, setHelpRequested] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [assignToList, setAssignToList] = useState([]);
  const [closeErrorModal, setCloseErrorModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [prevIndex, setPrevIndex] = useState(0);
  const [invalidFileMessage, showInvalidFileMessage] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    let payload = {
      practiceId,
      isActive: true,
      primaryRole: ""
    };
    actions.getAllUsersList(payload);
    if (composeModalOpen == true) {
      let payload = {
        practiceId,
        isActive: true,
        primaryRole: "Patient"
      };
      actions.getAllUsersList(payload);
    }
  }, [composeModalOpen]);
  useEffect(() => {
    setUsersList(allUsersList);
    setAssignToList(assignToUsersList);
  }, [allUsersList, assignToUsersList]);

  useEffect(() => {
    if (helpRequested == "Financial Estimate Query") {
      setComposeMessageType("Financial Estimate");
    } else if (helpRequested == "IVF Refund or Multi-Cycle Program Query") {
      setComposeMessageType("Multicycle Program");
    } else if (helpRequested == "PGT-A Analysis Unlimited Program Query") {
      setComposeMessageType("PGTA Analysis Unlimited Program");
    } else if (helpRequested == "Financing options Query") {
      setComposeMessageType("Financing Option");
    }
  }, [helpRequested]);

  const MESSAGE_TYPE = [
    "Financial Estimate",
    "Multicycle Program",
    "PGTA Analysis Unlimited Program",
    "Financing Option"
  ];
  const composeClickHandler = () => {
    setComposeModalOpen(true);
  };
  const closeComposeModal = () => {
    setOpenNavigationModal(true);
  };
  const userId = localStorage.getItem("userId");
  const practiceId = localStorage.getItem("practiceId");

  const handleHelpRequested = (e) => {
    setHelpRequested(e.target.value);
  };
  let settingValues = (messageParams) => {
    (values.status = messageParams),
      (values.subject = helpRequested),
      (values.body = composeMessage),
      (values.fromUserId = userId),
      (values.type = composeMessageType);
    values.assignedTo;
    setValues({ ...values });
  };
  const saveDraftHandler = () => {
    settingValues("Draft");
    let payload = {
      userId: userId,
      updatedMessages: values
    };
    actions.createNewMessage(payload);
    setAlertMessageModalOpen(true);
  };
  const sendButtonClickHandler = () => {
    settingValues("Sent");
    let payload = {
      userId: userId,
      updatedMessages: values
    };
    actions.createNewMessage(payload);
    setOpenSendMessagePopUp(true);
  };

  const refreshButtonClickHandler = () => {
    navigate(location.pathname);
  };
  const navigateToInboxHandler = () => {
    actions.resetMessageExecuteStatus();
    setFiles([]);
    showInvalidFileMessage(false);
    setHelpRequested("");
    setComposeMessageType("");
    values.messageRecipient = [];
    setComposeMessage("");
    setCloseErrorModal(false);
    setOpenSendMessagePopUp(false);
    navigate("/messages/inbox");
    setComposeModalOpen(false);
    setAlertMessageModalOpen(false);
    setOpenNavigationModal(false);
    actions.resetMessageExecuteStatus();
  };
  const navigationBoxCloseHandler = () => {
    setOpenNavigationModal(false);
    setAlertMessageModalOpen(false);
  };
  const handleClick = (e) => {
    const { target = {} } = e || {};
    target.value = "";
  };
  const fileHandler = (e) => {
    let filesArray = [...e.target.files];
    setFiles(filesArray);
    if (filesArray[0]) {
      if (
        validateFileSize(filesArray[0])[1] === true
        // || validateFileType(filesArray[0])[1] === true
      ) {
        let fileReader = new FileReader();
        fileReader.readAsDataURL(filesArray[0]);
        fileReader.onload = function () {
          actions.sendFile({
            fileEncoded: fileReader.result?.split(",")[1],
            practiceId: practiceId,
            fileType: filesArray[0]?.type,
            fileName: filesArray[0]?.name
          });
        };
      } else {
        showInvalidFileMessage(true);
      }
    }
  };
  useEffect(() => {
    if (postedFilePath) {
      prevIndex == 0
        ? values.messageAttachment.push({
            attachFileName: files[prevIndex]?.name,
            attachmentUrl: postedFilePath
          })
        : values.messageAttachment.push({
            attachFileName: files[prevIndex - 1]?.name,
            attachmentUrl: postedFilePath
          });
      values.hasAttachment = true;
    }
  }, [postedFilePath]);
  useEffect(() => {
    if (postedFilePath != "" && files.length !== prevIndex && files.length > prevIndex) {
      prevIndex !== 0 && actions.resetMessageExecuteStatus();

      const fileUploader = () => {
        let formData = new FormData();
        formData.append("file", files[prevIndex]);
        actions.sendFile({
          file: formData,
          practiceId: practiceId
        });
      };
      if (files.length !== prevIndex && files.length > prevIndex) {
        prevIndex !== 0 && fileUploader();
        setPrevIndex(prevIndex + 1);
      }
    }
  }, [files, postedFilePath, prevIndex]);

  useEffect(() => {
    if (errorCreateMessage || updatedErrorMsg) {
      setCloseErrorModal(true);
    }
  }, [errorCreateMessage, updatedErrorMsg]);
  const handleToDateChange = (e) => {
    if (e?._d) {
      setFilterParameters({
        ...filterParameters,
        toDate: moment(e._d).format("YYYY-MM-DD")
      });
    }
  };
  const handleInputChange = (e) => {
    if (e?._d) {
      setFilterParameters({
        ...filterParameters,
        fromDate: moment(e._d).format("YYYY-MM-DD")
      });
    } else {
      const { name, value } = e.target;
      setFilterParameters({
        ...filterParameters,
        [name]: value
      });
    }
  };
  useEffect(() => {
    if (filterParameters.messageStatus !== "") {
      getList();
    }
  }, [filterParameters, deletedMessageLoading]);
  const handleSearchClick = (e, searchText) => {
    if (searchText) {
      filterParameters.patientNameOrId = searchText;
    }
    setFilterParameters({ ...filterParameters });
  };
  const clearSearchClick = () => {
    filterParameters.patientNameOrId = "";
    setFilterParameters({ ...filterParameters });
  };
  const getList = () => {
    let params = {
      userId,
      messageStatus: filterParameters.messageStatus,
      queryParams: getQueryString(filterParameters)
    };
    params.messageStatus !== undefined && actions.getMessagesList(params);
  };
  const resetFilter = () => {
    setFilterParameters({
      ...defaultValue,
      messageStatus: filterParameters.messageStatus
    });
    getList();
  };
  const modules = {
    toolbar: [["bold", "italic", "underline"]]
  };
  useEffect(() => {
    if (values.assignedTo !== null) {
      values.messageRecipient.push({ toUser: values.assignedTo, isRead: false });
    }
  }, [values.assignedTo]);
  const handleAssignToChange = (e) => {
    values.assignedTo = e.target.value;
  };
  const handleMessageRecipient = (e) => {
    let messages_recipients = [];
    messages_recipients.push({ toUser: e.target.value, isRead: false });

    setValues({
      ...values,
      messageRecipient: messages_recipients
    });
  };
  const setNotesHandler = (e) => {
    setValues({ ...values, notes: e.target.value });
  };
  return (
    <>
      <Box className="message-header">
        <Stack direction="row" className="flex-between">
          <Typography variant="body1" className="font-24">
            MESSAGES
          </Typography>
          <Stack direction="row" gap={1}>
            <Button variant="outlined" onClick={composeClickHandler}>
              CREATE NEW
            </Button>
            <IconButton onClick={refreshButtonClickHandler}>
              <RefreshIcon />
            </IconButton>
          </Stack>
        </Stack>
      </Box>
      <Grid container>
        <Grid item xs={1.6} sx={{ background: "#818294", minHeight: "100vh" }}>
          <MessageConfiguration />
        </Grid>
        <Grid item xs={10.4}>
          <Box className="align-center pad-5 teal-bg-color m-l-8 m-r-8">
            <QuickAdd
              text={1}
              search={7}
              handleSearchClick={handleSearchClick}
              clearSearchClick={clearSearchClick}
            />
            <Grid container className="align-center pad-5">
              <Grid item xs={1}>
                <Typography>Assign to</Typography>
              </Grid>
              <Grid item xs={3}>
                <Select
                  size="small"
                  flex={3}
                  name="assignedToId"
                  defaultValue=""
                  className="patient-dropdown"
                  value={filterParameters?.assignedToId}
                  onChange={handleInputChange}>
                  <MenuItem value={userId}>Me</MenuItem>
                  <MenuItem value="All">All</MenuItem>
                  {assignToList &&
                    assignToList.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.userId}>
                          {item.firstName + " " + item.lastName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <Grid item xs={1}>
                <Typography>Type</Typography>
              </Grid>
              <Grid item xs={3}>
                <Select
                  sx={{ width: "100%" }}
                  size="small"
                  name="type"
                  className="patient-dropdown"
                  defaultValue=""
                  value={filterParameters?.type}
                  onChange={handleInputChange}>
                  <MenuItem value="All">All</MenuItem>
                  {MESSAGE_TYPE.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" justifyContent="center">
                  <Button
                    variant="text"
                    disabled={
                      filterParameters.assignedToId == defaultValue.assignedToId &&
                      filterParameters.type == defaultValue.type &&
                      filterParameters.fromDate == defaultValue.fromDate &&
                      filterParameters.toDate == defaultValue.toDate
                        ? true
                        : false
                    }
                    color="primary"
                    onClick={resetFilter}>
                    <Typography variant="subtitle1">RESET ALL</Typography>
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={1} sx={{ mt: 1.5 }}>
                <Typography>From</Typography>
              </Grid>
              <Grid item xs={3} sx={{ mt: 1.5 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    openTo="day"
                    name="fromDate"
                    views={["day", "year", "month"]}
                    value={filterParameters.fromDate}
                    onChange={handleInputChange}
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <TextField {...params} size="small" className="patient-dropdown" />
                    )}></DatePicker>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1} sx={{ mt: 1.5 }}>
                <Typography>To</Typography>
              </Grid>
              <Grid item xs={3.33} sx={{ mt: 1.5 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    openTo="day"
                    name="toDate"
                    views={["day", "year", "month"]}
                    value={filterParameters.toDate}
                    onChange={handleToDateChange}
                    maxDate={new Date()}
                    renderInput={(params) => (
                      <TextField {...params} size="small" className="patient-dropdown" />
                    )}></DatePicker>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Box>

          <Modal open={composeModalOpen} backdrop="static" aria-labelledby="modal-add-title">
            <Box className="modal_style_newMessage">
              <Stack direction="row" className="modal-stack">
                <Typography className="font-w-500">CREATE NEW MESSAGE</Typography>
                <IconButton onClick={closeComposeModal}>
                  <img alt="" src="/Assets/close.png" />
                </IconButton>
              </Stack>
              <Divider className="modal-divider" />
              <InputLabel sx={{ ml: 58 }} required>
                Required fields
              </InputLabel>
              <Stack
                direction="column"
                sx={{ alignItems: "center", justifyContent: "center", py: 3 }}>
                <Grid container gap={1}>
                  <Grid item xs={2}>
                    <InputLabel required>To</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <FormControl>
                      <InputLabel id="test-select-label">Search by patient name or Id</InputLabel>
                      <Select
                        size="small"
                        sx={{ minWidth: 400 }}
                        value={values.messageRecipient.toUser}
                        required
                        defaultValue=""
                        labelId="test-select-label"
                        label="Search by patient name or Id"
                        onChange={handleMessageRecipient}>
                        {usersList &&
                          usersList.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item.userId}>
                                {item.firstName + " " + item.lastName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel required>Type:</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <Select
                      size="small"
                      sx={{ minWidth: 400 }}
                      value={helpRequested}
                      required
                      defaultValue=""
                      onChange={handleHelpRequested}>
                      <MenuItem value="Financial Estimate Query">Financial estimate</MenuItem>
                      <MenuItem value="IVF Refund or Multi-Cycle Program Query">
                        IVF Refund or Multi-Cycle Program.
                      </MenuItem>
                      <MenuItem value="PGT-A Analysis Unlimited Program Query">
                        Multicycle program PGTA analysis Unlimited program
                      </MenuItem>
                      <MenuItem value="Financing options Query">Financing options.</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel required>Subject:</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField sx={{ width: 400 }} value={helpRequested} />
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel>Assigned To:</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <Select
                      sx={{ minWidth: 400 }}
                      required
                      onChange={handleAssignToChange}
                      defaultValue="">
                      <MenuItem value={userId}>Me</MenuItem>
                      {assignToList &&
                        assignToList.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.userId}>
                              {item.firstName + " " + item.lastName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel>Attachments:</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <Stack direction="row">
                      <form>
                        <Button component="label">
                          <input type="file" hidden onChange={fileHandler} onClick={handleClick} />
                          ATTACH FILE
                        </Button>
                      </form>
                      <Stack direction="column" sx={{ display: "flex" }}>
                        <Typography sx={{ mt: 1.4 }}>
                          You can upload up to 10MB per attachment or upload a maximum of 10
                          attachments.
                        </Typography>
                        {invalidFileMessage ? (
                          <Typography sx={{ color: "red", fontSize: 10 }}>
                            File size should not exceed 10MB
                          </Typography>
                        ) : (
                          <></>
                        )}
                        {postFileLoading ? (
                          <Stack direction="row" marginTop={1} gap={1}>
                            <CircularProgress size={22} />
                            <Typography sx={{ color: "#00889f", fontSize: 18 }}>
                              Uploading...
                            </Typography>
                          </Stack>
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Stack>
                    {files?.length !== 0 &&
                      files.map((item, index) => {
                        return <Typography key={index}>{item.name}</Typography>;
                      })}
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel>Notes</InputLabel>
                    <InputLabel>(Not visible to patient)</InputLabel>
                  </Grid>
                  <Grid item xs={9}>
                    <TextField sx={{ width: 480 }} onChange={setNotesHandler} />
                  </Grid>
                  <Grid item xs={12}>
                    <Box className="ql-editor">
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        value={composeMessage}
                        onChange={setComposeMessage}
                        placeholder="Write your message here..."
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button
                  onClick={closeComposeModal}
                  disabled={
                    composeMessage.replace(/<(.|\n)*?>/g, "").trim().length === 0 &&
                    helpRequested == "" &&
                    !values.messageRecipient?.length
                  }>
                  CANCEL
                </Button>
                <Button
                  variant="outlined"
                  onClick={saveDraftHandler}
                  disabled={!values.messageRecipient?.length || postFileLoading}>
                  SAVE AS DRAFT
                </Button>
                <Button
                  variant="outlined"
                  onClick={sendButtonClickHandler}
                  disabled={
                    composeMessage.replace(/<(.|\n)*?>/g, "").trim().length === 0 ||
                    helpRequested == "" ||
                    !values.messageRecipient?.length ||
                    postFileLoading
                  }>
                  SEND
                </Button>
              </Stack>
            </Box>
          </Modal>
          {sentMessageLoading || updateMessagesLoading ? (
            <LoadingModal />
          ) : successCreateMessage || updatedSuccessMsg ? (
            <Modal open={alertMessageModalOpen} backdrop="static" aria-labelledby="modal-add-title">
              <Box className="alert-modal">
                <Box className="modal-stack">
                  <Stack direction="row" justifyContent="space-between">
                    <Typography>SAVE AS DRAFT</Typography>
                    <IconButton onClick={navigationBoxCloseHandler}>
                      <img alt="" src="/Assets/close.png" />
                    </IconButton>
                  </Stack>
                </Box>
                <Divider className="modal-divider" />
                <Box sx={{ py: 1 }}>
                  <Typography>Message saved successfully.</Typography>
                </Box>
                <Stack direction="row" className="modal-bottom-section" gap={1}>
                  <Button onClick={navigateToInboxHandler}>BACK TO INBOX</Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setAlertMessageModalOpen(false);
                    }}>
                    CONTINUE EDITING
                  </Button>
                </Stack>
              </Box>
            </Modal>
          ) : (
            <Modal open={closeErrorModal} backdrop="static" aria-labelledby="modal-add-title">
              <Box className="alert-modal">
                <Stack direction="row" className="modal-stack">
                  <Typography className="font-w-500">Alert</Typography>
                  <IconButton onClick={navigateToInboxHandler}>
                    <img alt="" src="/Assets/close.png" />
                  </IconButton>
                </Stack>
                <Divider className="modal-divider" />
                <Box sx={{ py: 1 }}>
                  <Typography>{errorCreateMessage}</Typography>
                </Box>
              </Box>
            </Modal>
          )}
          <Modal open={openNavigationModal} backdrop="static" aria-labelledby="modal-add-title">
            <Box className="alert-modal">
              <Box className="modal-stack">
                <Stack direction="row" justifyContent="space-between">
                  <Typography>CONFIRM NAVIGATION</Typography>
                  <IconButton onClick={navigationBoxCloseHandler}>
                    <img alt="" src="/Assets/close.png" />
                  </IconButton>
                </Stack>
              </Box>
              <Divider className="modal-divider" />
              <Box sx={{ py: 1 }}>
                <Typography>
                  Are you sure you want to leave this page? Any changes made since the last save
                  will be lost.
                </Typography>
              </Box>
              <Stack direction="row" className="modal-bottom-section" gap={1}>
                <Button onClick={navigateToInboxHandler}>LEAVE</Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenNavigationModal(false);
                  }}>
                  CONTINUE EDITING
                </Button>
              </Stack>
            </Box>
          </Modal>
          {sentMessageLoading || updateMessagesLoading ? (
            <LoadingModal />
          ) : successCreateMessage || updatedSuccessMsg ? (
            <Modal open={openSendMessagePopUp} backdrop="static" aria-labelledby="modal-add-title">
              <Box className="alert-modal">
                <Stack direction="row" className="modal-stack">
                  <Typography className="font-w-500">Send</Typography>
                  <IconButton onClick={navigateToInboxHandler}>
                    <img alt="" src="/Assets/close.png" />
                  </IconButton>
                </Stack>
                <Divider className="modal-divider" />
                <Stack direction="column" sx={{ alignItems: "center", justifyContent: "center" }}>
                  <Typography sx={{ mt: 1 }}>Message send successfully</Typography>
                  <Button
                    variant="contained"
                    className="btn-primary"
                    onClick={navigateToInboxHandler}
                    sx={{ marginTop: 1 }}>
                    Ok
                  </Button>
                </Stack>
              </Box>
            </Modal>
          ) : (
            <Modal open={closeErrorModal} backdrop="static" aria-labelledby="modal-add-title">
              <Box className="alert-modal">
                <Stack direction="row" className="modal-stack">
                  <Typography className="font-w-500">Alert</Typography>
                  <IconButton onClick={navigateToInboxHandler}>
                    <img alt="" src="/Assets/close.png" />
                  </IconButton>
                </Stack>
                <Divider className="modal-divider" />
                <Box sx={{ py: 1 }}>
                  <Typography>{errorCreateMessage}</Typography>
                </Box>
              </Box>
            </Modal>
          )}
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};
const mapStateToProps = ({ messageReducer }) => {
  return {
    sentMessageLoading: messageReducer.sentMessageLoading,
    allUsersList: messageReducer.allUsersList,
    assignToUsersList: messageReducer.assignToUsersList,
    updateMessagesLoading: messageReducer.updateMessagesLoading,
    successCreateMessage: messageReducer.successCreateMessage,
    errorCreateMessage: messageReducer.errorCreateMessage,
    updatedSuccessMsg: messageReducer.updatedSuccessMsg,
    updatedErrorMsg: messageReducer.updatedErrorMsg,
    deletedMessageLoading: messageReducer.deletedMessageLoading,
    postedFilePath: messageReducer.postedFilePath,
    postFileLoading: messageReducer.postFileLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMessagesList: (payload) => {
      dispatch(actionType.getMessagesList(payload));
    },
    createNewMessage: (payload) => {
      dispatch(actionType.createNewMessage(payload));
    },
    getAllUsersList: (payload) => {
      dispatch(actionType.getAllUsersList(payload));
    },
    resetMessageExecuteStatus: () => {
      dispatch(actionType.resetMessageExecuteStatus());
    },
    sendFile: (payload) => {
      dispatch(actionType.sendFile(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
