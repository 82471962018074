import React, { useEffect, useCallback, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Grid,
  Select,
  Button,
  Modal,
  IconButton,
  TextField,
  MenuItem
} from "@mui/material";
import { Divider } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { connect } from "react-redux";
import * as actionType from "../actions";
import _ from "lodash";

const AddLabPayoutTransaction = (props) => {
  const { actions, metaData, open, handleClose, saveLabPayout, pgtaStatus, transactionLog } = props;

  const labPayoutData = {
    labId: "",
    numberOfEmbryo: "",
    amount: "",
    testSummary: "",
    testingEvent: "",
    transactionDate: null,
    invoiceDate: null,
    labName: "",
    transactionType:
      pgtaStatus == "Enrolled"
        ? "Lab Payment"
        : pgtaStatus == "Re-Enrolled"
        ? "Medical Cost Reserve Lab Payment"
        : ""
  };
  const [values, setValues] = useState(labPayoutData);

  const cycleNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const initFetch = useCallback(() => {
    actions.getMetaData();
    setValues(labPayoutData);
  }, []);
  useEffect(() => {
    initFetch();
  }, []);

  const validate =
    values?.amount &&
    values?.labName &&
    values?.numberOfEmbryo &&
    values?.testingEvent &&
    values?.transactionDate &&
    values?.testSummary
      ? true
      : false;

  const handleSave = () => {
    const id = metaData.labNameArray.filter((item) => item.labName == values.labName);
    saveLabPayout({ ...values, labId: id[0].labId });
    handleClose();
    setValues(labPayoutData);
  };

  const testEventData =
    transactionLog && transactionLog.length
      ? _.uniq(
          _.compact(
            transactionLog.map((item) => {
              return item.testingEvent;
            })
          )
        )
      : [];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-add-title"
      aria-describedby="modal-add-description">
      <Box className="modal-style-contact" style={{ maxHeight: "80vh", overflow: "auto" }}>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Typography>Add Lab Payout (Fields marked with * are manditory)</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
        <Stack className="pad-5" direction="row" style={{ marginTop: "10px" }}>
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography>Lab*</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Select
                name="labName"
                fullWidth
                value={values.labName}
                className="patient-dropdown"
                onChange={(e) =>
                  setValues({
                    ...values,
                    labName: e.target.value
                  })
                }>
                {Object.entries(metaData).length > 0 &&
                  metaData.labNameArray.map((item, index) => {
                    return (
                      <MenuItem value={item.labName} key={index}>
                        {item.labName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Transaction Type</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Select
                name="transactionType"
                fullWidth
                value={values.transactionType}
                className="patient-dropdown"
                onChange={(e) =>
                  setValues({
                    ...values,
                    transactionType: e.target.value
                  })
                }>
                <MenuItem value={"Lab Payment"}>{"Lab Payment"}</MenuItem>
                <MenuItem value={"Medical Cost Reserve Lab Payment"}>
                  {"Medical Cost Reserve Lab Payment"}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Payment Amount*</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                name="amount"
                type="number"
                className="add-description"
                onChange={(e) => setValues({ ...values, amount: e.target.value })}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack className="pad-5" direction="row" style={{ marginTop: "10px" }}>
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography>Transaction Date*</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  views={["day"]}
                  name="transactionDate"
                  value={values.transactionDate}
                  onChange={(newValue) => {
                    setValues({
                      ...values,
                      transactionDate: newValue
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" sx={{ flex: 8 }} />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Lab Invoice Date</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  views={["day"]}
                  name="invoiceDate"
                  value={values.invoiceDate}
                  onChange={(newValue) => {
                    setValues({
                      ...values,
                      invoiceDate: newValue
                    });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" sx={{ flex: 8 }} />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Stack>
        <Stack
          className="pad-5"
          direction="row"
          style={{ marginTop: "10px", marginBottom: "20px" }}>
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography>No.Of Embryos*</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                name="numberOfEmbryo"
                type="number"
                fullWidth
                onChange={(e) => setValues({ ...values, numberOfEmbryo: e.target.value })}
              />
            </Grid>
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Cycle Number*</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Select
                size="small"
                name="testingEvent"
                type="number"
                onChange={(e) => setValues({ ...values, testingEvent: e.target.value })}
                className="add-description"
                fullWidth>
                <MenuItem value="0" disabled={pgtaStatus == "Pre-Enrolled" ? false : true}>
                  0
                </MenuItem>
                {cycleNumbers.map((num, index) => {
                  return (
                    <MenuItem
                      value={num}
                      key={"cn_" + index}
                      disabled={
                        (pgtaStatus == "Enrolled" ? false : true) ||
                        testEventData.indexOf(String(num)) >= 0
                      }>
                      {num}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Test Summary*</Typography>
            </Grid>
            <Grid item xs={2.5}>
              <TextField
                name="testSummary"
                className="add-description"
                onChange={(e) => setValues({ ...values, testSummary: e.target.value })}
              />
            </Grid>
          </Grid>
        </Stack>
        <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
        <Stack
          direction="row"
          spacing={2}
          sx={{ my: 2, alignItems: "center", justifyContent: "flex-end" }}>
          <Button variant="outlined" className="btn-primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            className="btn-usf"
            disabled={!validate}
            onClick={handleSave}>
            Save Payout
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const mapStateToProps = ({ caseUpdateReducer, geneticTestingReducer }) => {
  return {
    metaData: caseUpdateReducer.metaData,
    transactionLog: geneticTestingReducer.transactionDetails
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLabPayoutTransaction);
