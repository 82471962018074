import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchFeeScheduleList(action) {
  let payload = action.payload;
  try {
    const params = {
      offset: payload.offset,
      limit: payload.limit,
      feesSch: payload.feesSch,
      category: payload.category
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId}/stages`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.FEE_SCHEDULE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.FEE_SCHEDULE_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* updateFeeSchedule(action) {
  const { practiceId, gdmId, tpStageId, treatmentPlan } = action.payload;
  try {
    const params = {
      feesSch: true
    };
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plan-stages/${tpStageId}`,
      params,
      {
        srNo: treatmentPlan.srNo,
        treatmentPlanStageId: treatmentPlan.treatmentPlanStageId,
        treatmentPlanId: treatmentPlan.treatmentPlanId,
        stageName: treatmentPlan.stageName,
        stageId: treatmentPlan.stageId,
        stageCode: treatmentPlan.stageCode,
        stageOrder: treatmentPlan.stageOrder,
        passthroughAmount: treatmentPlan.passthroughAmount,
        stagePassthrough: treatmentPlan.stagePassthrough,
        globalPassthroughAmount: treatmentPlan.globalPassthroughAmount,
        cancelGlobalDiscount: treatmentPlan.cancelGlobalDiscount,
        assistPassthroughAmount: treatmentPlan.assistPassthroughAmount,
        cancelAssistDiscount: treatmentPlan.cancelAssistDiscount,
        stageCategory: treatmentPlan.stageCategory,
        totalPrice: treatmentPlan.totalPrice,
        globalPrice: treatmentPlan.globalPrice,
        assistPrice: treatmentPlan.assistPrice,
        range: treatmentPlan.range
      }
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.FEE_SCHEDULE_UPDATE_SUCCESS,
      payload: response.data,
      successMsg: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.FEE_SCHEDULE_UPDATE_FAIL,
        message: error.message
      });
    }
  }
}

function* getFeeScheduleSaga() {
  yield takeEvery(actionTypes.FEE_SCHEDULE_LIST_REQUESTED, fetchFeeScheduleList);
}

function* updateFeeScheduleSaga() {
  yield takeEvery(actionTypes.FEE_SCHEDULE_UPDATE_REQUESTED, updateFeeSchedule);
}

export default function* feeScheduleSaga() {
  yield all([getFeeScheduleSaga(), updateFeeScheduleSaga()]);
}
