import * as actionType from "../actions/ActionTypes";
const initialState = {
  transactionLog: [],
  feesList: [],
  deleteTransaction: null,
  editTransaction: null,
  loading: false,
  error: null,
  success: null,
  errorFee: null,
  successFees: null
};
const transactionLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TRANSACTION_LOG_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.TRANSACTION_LOG_SUCCESS: {
      return {
        ...state,
        transactionLog: action.payload,
        loading: false
      };
    }
    case actionType.TRANSACTION_LOG_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.POST_FEES_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.POST_FEES_SUCCESS: {
      return {
        ...state,
        feesList: action.payload,
        loading: false,
        successFees: action.successMsg
      };
    }
    case actionType.POST_FEES_FAILED: {
      return {
        ...state,
        errorFee: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_TRANSACTION_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_TRANSACTION_SUCCESS: {
      return {
        ...state,
        deleteTransaction: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_TRANSACTION_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        deleteTransaction: action.payload,
        loading: false
      };
    }

    case actionType.EDIT_TRANSACTION_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_TRANSACTION_SUCCESS: {
      return {
        ...state,
        editTransaction: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_TRANSACTION_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        editTransaction: action.payload,
        loading: false
      };
    }
    case actionType.RESET_TRANSACTION_LOG: {
      return {
        ...state,
        deleteTransaction: null,
        feesList: [],
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false,
        errorFee: null,
        successFees: false
      };
    }
    case actionType.RESET_TRANSACTION_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false,
        errorFee: null,
        successFees: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default transactionLogReducer;
