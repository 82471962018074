import * as actionType from "../actions/ActionTypes";
const initialState = {
  newPrList: [],
  newPrPostList: [],
  loading: false,
  error: null,
  success: null,
  successPR: null,
  errorPR: null
};
const newPrReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.NEW_PR_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.NEW_PR_SUCCESS: {
      return {
        ...state,
        newPrList: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.NEW_PR_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.NEW_PR_POST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.NEW_PR_POST_SUCCESS: {
      return {
        ...state,
        newPrPostList: action.payload,
        successPR: action.successMsg,
        loading: false
      };
    }
    case actionType.NEW_PR_POST_FAILED: {
      return {
        ...state,
        errorPR: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        errorPR: null,
        successPR: null
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default newPrReducer;
