import * as actionType from "../actions/ActionTypes";

const initialState = {
  utilitySearchList: [],
  loading: false,
  error: null,
  success: null
};

const utilitySearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.UTILITYSEARCH_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.UTILITYSEARCH_LIST_SUCCESS: {
      return {
        ...state,
        utilitySearchList: action.payload,
        loading: false
      };
    }
    case actionType.UTILITYSEARCH_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_UTILITYSEARCH_LIST: {
      return {
        ...state,
        utilitySearchList: null,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default utilitySearchReducer;
