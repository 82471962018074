import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { commentConfig } from "../../shared/constants/renderConfigs";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import PopupModal from "../../shared/components/PopupModal";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const Comment = (props) => {
  let commentFormData = {
    type: "",
    comment: "",
    markImp: false,
    commentType: ""
  };
  // let initialData = {};
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState(commentFormData);
  const [fullComment, setFullComment] = useState("");
  const [isViewMoreModal, setIsViewMoreModal] = useState(false);

  const columns = [
    {
      field: "updatedDate",
      headerName: "DATE",
      // width: 100,
      flex: 0.5,
      renderCell: ({ row }) => {
        return moment(row.updatedDate).format("L");
      }
    },
    // {
    //   field: "important",
    //   headerName: "MARK IMP",
    //   // width: 50,
    //   editable: false,
    //   flex: 0.5
    // },
    {
      field: "updatedBy",
      headerName: "USER",
      // width: 100,
      editable: false,
      flex: 0.5
    },
    {
      field: "commentType",
      headerName: "COMMENT TYPE",
      // width: 100,
      editable: false,
      flex: 0.5
    },
    {
      field: "comment",
      headerName: "COMMENT",
      // width: 50,
      editable: false,
      flex: 4,
      renderCell: ({ row }) => (
        <div className="textarea-wrap">
          <Typography className="textarea-wrap" color={row.important === true ? "red" : ""}>
            {row.comment.length > 180 ? (
              <>
                {row.comment.substring(0, 180)}...
                <Button onClick={() => handleViewMore(row.comment)}>View More</Button>
              </>
            ) : (
              row.comment
            )}
          </Typography>
        </div>
      )
    }
  ];

  const { commentList = [], actions, addComment, errorMsg, successMsg, loading } = props;
  const rowsData = commentList.map((item, index) => {
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    actions.getComment({ medcaseId: id });
  }, [addComment]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (commentList.length == 0 || addComment) {
      initFetch();
    }
    return () => {
      actions.resetCommentList();
    };
  }, [addComment, errorMsg, successMsg]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    if (name == "markImp") {
      value = e.target.checked;
    }
    setValues({
      ...values,
      [name]: value
    });
  };
  const addNewComment = () => {
    setValues(commentFormData);
    setIsAdd(true);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const saveComment = (event) => {
    event.preventDefault();
    if (isAdd) {
      values.markImp = values.markImp ? values.markImp : false;
      values.medCaseId = id;
      actions.addNewComment(values);
    }
    closeModal();
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const handleViewMore = (comment) => {
    setFullComment(comment);
    setIsViewMoreModal(true);
  };

  const closeViewMoreModal = () => {
    setIsViewMoreModal(false);
  };

  const comment = commentConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.comment || values.commentType ? false : true
  });
  return (
    <>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="grid-header">
          <Typography>Medcase</Typography>
          <Button variant="contained" className="btn-primary" onClick={addNewComment}>
            Add New
          </Button>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rowsData}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            getRowClassName={(params) => {
              return params.row.important == true ? "imp-trans" : "";
            }}
          />
        </Box>
      </Box>
      <CommonModal open={isOpen} config={comment} isAdd={isAdd} handleSubmit={saveComment} />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <PopupModal
        open={isViewMoreModal}
        title="Full Comment"
        content={<Typography>{fullComment}</Typography>}
        handleClose={closeViewMoreModal}
      />
    </>
  );
};
const mapStateToProps = ({ commentReducer }) => {
  return {
    loading: commentReducer.loading,
    commentList: commentReducer.commentList,
    addComment: commentReducer.addComment,
    errorMsg: commentReducer.error,
    successMsg: commentReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getComment: (medCaseId) => {
      dispatch(actionType.getComment(medCaseId));
    },
    addNewComment: (payload) => {
      dispatch(actionType.addNewComment(payload));
    },
    resetCommentList: () => {
      dispatch(actionType.resetCommentList());
    },
    resetComment: () => {
      dispatch(actionType.resetComment());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Comment);
