import * as actionType from "../actions/ActionTypes";
const initialState = {
  transactionDetails: [],
  pgtaSummary: {},
  labPayoutList: [],
  manageLabsList: [],
  manageLabLoading: false,
  loading: false,
  error: null,
  errorPayout: null,
  success: null,
  errorMsg: null,
  successManageLab: null
};
const geneticTestingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GENETICTESTING_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GENETICTESTING_LIST_SUCCESS: {
      return {
        ...state,
        transactionDetails: action.payload,
        loading: false
      };
    }
    case actionType.GENETICTESTING_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_GENETICTESTING_LIST: {
      return {
        ...state,
        loading: null
      };
    }
    case actionType.PGTA_SUMMARY_ANALYSIS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PGTA_SUMMARY_ANALYSIS_SUCCESS: {
      return {
        ...state,
        pgtaSummary: action.payload,
        loading: false
      };
    }
    case actionType.PGTA_SUMMARY_ANALYSIS_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_LAB_PAYOUT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_LAB_PAYOUT_SUCCESS: {
      return {
        ...state,
        labPayoutList: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.ADD_LAB_PAYOUT_FAIL: {
      return {
        ...state,
        errorPayout: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_LAB_PAYOUT: {
      return {
        ...state,
        success: null
      };
    }
    case actionType.GET_MANAGE_LAB_REQUESTED: {
      return {
        ...state,
        manageLabLoading: true
      };
    }
    case actionType.GET_MANAGE_LAB_SUCCESS: {
      return {
        ...state,
        manageLabsList: action.payload,
        manageLabLoading: false
      };
    }
    case actionType.GET_MANAGE_LAB_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        manageLabLoading: false
      };
    }
    case actionType.EDIT_MANAGE_LAB_REQUESTED: {
      return {
        ...state,
        manageLabLoading: true
      };
    }
    case actionType.EDIT_MANAGE_LAB_SUCCESS: {
      return {
        ...state,
        successManageLab: action.successMsg,
        manageLabLoading: false
      };
    }
    case actionType.EDIT_MANAGE_LAB_FAIL: {
      return {
        ...state,
        errorMsg: action.errorMsg,
        manageLabLoading: false
      };
    }
    case actionType.ADD_MANAGE_LAB_REQUESTED: {
      return {
        ...state,
        manageLabLoading: true
      };
    }
    case actionType.ADD_MANAGE_LAB_SUCCESS: {
      return {
        ...state,
        manageLabLoading: false,
        successManageLab: action.successMsg
      };
    }
    case actionType.ADD_MANAGE_LAB_FAIL: {
      return {
        ...state,
        manageLabLoading: false,
        errorMsg: action.errorMsg
      };
    }
    case actionType.RESET_GENETICTESTING: {
      return {
        ...state,
        successManageLab: null,
        errorMsg: null,
        success: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default geneticTestingReducer;
