import * as actionType from "../actions/ActionTypes";

const initialState = {
  userList: [],
  loading: false,
  error: null,
  success: false,
  userdisablestatus: null,
  userdisable_loading: false,
  userdisable_success: false,
  userdisable_error: null,
  userdisable_data: [],
  custAuth: null,
  caLoading: false
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.USER_FILTER_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.USER_FILTER_DATA_SUCCESS: {
      return {
        ...state,
        userList: action.payload,
        loading: false
      };
    }
    case actionType.USER_FILTER_DATA_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.RESET_USER_FILTER: {
      return {
        ...state,
        success: null,
        loading: false,
        userList: []
      };
    }
    case actionType.SET_EDIT_PERSON_DISABLE: {
      return {
        ...state,
        userdisable_loading: true
      };
    }
    case actionType.SET_EDIT_PERSON_DISABLE_SUCCESS: {
      return {
        ...state,
        userdisable_data: action.success,
        userdisable_loading: false,
        userdisable_success: true
      };
    }
    case actionType.SET_EDIT_PERSON_DISABLE_FAIL: {
      return {
        ...state,
        userdisable_error: action.message,
        userdisable_loading: false,
        userdisable_success: false
      };
    }
    case actionType.RESET_SET_EDIT_PERSON_DISABLE: {
      return {
        ...state,
        userdisable_success: null,
        userdisable_loading: false,
        userdisable_error: null,
        userdisable_data: null
      };
    }
    case actionType.CUST_AUTH_REQUESTED: {
      return {
        ...state,
        caLoading: true
      };
    }
    case actionType.CUST_AUTH_SUCCESS: {
      return {
        ...state,
        custAuth: action.payload,
        caLoading: false
      };
    }
    case actionType.CUST_AUTH_FAIL: {
      return {
        ...state,
        error: action.message,
        caLoading: false
      };
    }
    case actionType.RESET_CUST_AUTH: {
      return {
        ...state,
        custAuth: null
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default userReducer;
