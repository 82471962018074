import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchStatus(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/status/history`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.STATUS_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    console.log("err=>", error);
    if (appAuthencationCheck(error) === true) {
      //let errMsg = (error && error.response && error.response.data) || error.message;
      yield put({ type: actionTypes.STATUS_LIST_FAIL, errorMsg: "" });
    }
  }
}
function* updateMedcaseStatus(action) {
  const { payload } = action;
  const body = {
    caseStatus: payload.caseStatus,
    reason: payload.reason,
    statusType: "ACTIVE/INACTIVE"
  };

  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${payload.medcaseId}/status/activestatus`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_NEW_STATUS_SUCCESS,
      successMsg: MSG.SAVED_SUCCESSFULLY,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.ADD_NEW_STATUS_FAILED, errorMsg: error.message });
    }
  }
}

function* updateMedcaseDDLStatus(action) {
  const { payload } = action;
  const body = {
    caseStatus: payload.caseStatus,
    statusType: payload.statusType,
    neReason: payload.neReason ? payload.neReason : null
  };

  try {
    const requestBody = createRequestBody("put", `/medcases/${payload.medcaseId}/status`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.MEDCASE_DDL_STATUS_SUCCESS,
      successMsg: MSG.SAVED_SUCCESSFULLY,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.MEDCASE_DDL_STATUS_FAIL,
        errorMsg:
          (error?.response?.data &&
            error?.response?.data.slice(0, error?.response?.data.lastIndexOf("("))) ||
          error.message
      });
    }
  }
}

function* fetchMedcaseHeader(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/medcaseDetailsHeader`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.MEDCASE_HEADER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionTypes.MEDCASE_HEADER_FAIL, errorMsg: error.message });
  }
}
function* fetchMedcaseStatus(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/status`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.MEDCASE_STATUS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionTypes.MEDCASE_STATUS_FAIL, errorMsg: error.message });
  }
}

function* deleteStatus(action) {
  const { payload } = action;
  console.log("payload", payload);
  try {
    const requestBody = createRequestBody(
      "delete",
      `/medcases/${payload.medcaseId}/status/${payload.statusId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    console.log("Delete response: ", response.data);
    yield put({
      type: actionTypes.DELETE_STATUS_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (error) {
    console.error("Delete error: ", error);

    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.DELETE_STATUS_FAILED, errorMsg: error.message });
    }
  }
}

function* deleteStatusSaga() {
  yield takeEvery(actionTypes.DELETE_STATUS_REQUESTED, deleteStatus);
}

function* getStatusSaga() {
  yield takeEvery(actionTypes.STATUS_LIST_REQUESTED, fetchStatus);
}

function* getMedcaseStatusSaga() {
  yield takeEvery(actionTypes.MEDCASE_STATUS_REQUESTED, fetchMedcaseStatus);
}

function* getMedcaseHeaderSaga() {
  yield takeEvery(actionTypes.MEDCASE_HEADER_REQUESTED, fetchMedcaseHeader);
}

function* updateMedcaseStatusSaga() {
  yield takeEvery(actionTypes.ADD_NEW_STATUS_REQUESTED, updateMedcaseStatus);
}

function* updateMedcaseDDLStatusSaga() {
  yield takeEvery(actionTypes.MEDCASE_DDL_STATUS_REQUESTED, updateMedcaseDDLStatus);
}

function* fetchRefreshedStatus(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };

    const requestBody = createRequestBody(
      "put",
      `/medcases/${payload.medcaseId}/status/syncstatus`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    console.log("==>res", response);
    yield put({
      type: actionTypes.REFRESH_STATUS_HISTORY_SUCCESS,
      payload: response.status
    });
  } catch (error) {
    console.log("err=>", error);
    if (appAuthencationCheck(error) === true) {
      //let errMsg = (error && error.response && error.response.data) || error.message;
      yield put({ type: actionTypes.REFRESH_STATUS_HISTORY_FAILED, errorMsg: "" });
    }
  }
}

function* getRefreshedStatusSaga() {
  yield takeEvery(actionTypes.REFRESH_STATUS_HISTORY_REQUESTED, fetchRefreshedStatus);
}

export default function* statusSaga() {
  yield all([
    getStatusSaga(),
    getMedcaseStatusSaga(),
    getMedcaseHeaderSaga(),
    updateMedcaseStatusSaga(),
    updateMedcaseDDLStatusSaga(),
    deleteStatusSaga(),
    getRefreshedStatusSaga()
  ]);
}
