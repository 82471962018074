import * as actionType from "../actions/ActionTypes";

const initialState = {
  locations: [],
  loading: false,
  error: null
};

const locationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOCATION_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.LOCATION_LIST_SUCCESS: {
      return {
        ...state,
        locations: action.payload,
        loading: false
      };
    }
    case actionType.LOCATION_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_LOCATION_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_LOCATION_SUCCESS: {
      return {
        ...state,
        addLocation: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_LOCATION_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.EDIT_LOCATION_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_LOCATION_SUCCESS: {
      return {
        ...state,
        editLocation: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_LOCATION_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_LOCATION_LIST: {
      return {
        ...state,
        addLocation: null,
        editLocation: null,
        loading: null
      };
    }
    case actionType.RESET_LOCATION_ROW: {
      return {
        ...state,
        locations: [],
        loading: null
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default locationReducer;
