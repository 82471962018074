import { combineReducers } from "redux";
import * as actionType from "../../modules/admin/actions/ActionTypes";
import addFeesReducer from "../../modules/trak/reducers/addFeesReducer";
import address from "../../modules/admin/reducers/addressReducer";
import addressReducer from "../../modules/trak/reducers/addressReducer";
import auditLogsReducer from "../../modules/admin/reducers/auditLogsReducer";
import caseListReducer from "../../modules/trak/reducers/caseListReducer";
import caseUpdateReducer from "../../modules/trak/reducers/caseUpdateReducer";
// import clinicalReducer from "../../modules/patients/reducers/clinicalReducer";
import commentReducer from "../../modules/trak/reducers/commentReducer";
import contactReducer from "../../modules/admin/reducers/contactReducer";
// import contextualBarReducer from "../../modules/patients/reducers/contextualBarReducer";
import contractReducer from "../../modules/trak/reducers/contractReducer";
import costCoverageReducer from "../../modules/workspace/reducers/costCoverageReducer";
import documentReducer from "../../modules/trak/reducers/documentReducer";
import email from "../../modules/trak/reducers/emailReducer";
import emailDocumentReducer from "../../modules/admin/reducers/emailDocumentReducer";
import emailReducer from "../../modules/admin/reducers/emailReducer";
import enableDisableReducer from "../../modules/admin/reducers/enableDisableReducer";
import estimateReducer from "../../modules/workspace/reducers/estimateReducer";
import externalDetailsReducer from "../../modules/admin/reducers/externalDetailsReducer";
import fapReducer from "../../modules/workspace/reducers/fapReducer";
import feeSchedule from "../../modules/admin/reducers/feeScheduleReducer";
import feeScheduleReducer from "../../modules/admin/reducers/feeScheduleReducer";
// import fertilityAccessProgramReducer from "../../modules/patients/reducers/fertilityAccessProgramReducer";
import financialSnapshotPaymentReducer from "../../modules/trak/reducers/financialSnapshotPaymentReducer";
import finSnapshot605AllReducer from "../../modules/trak/reducers/finsnapshot605allreducer";
import geneticTestingReducer from "../../modules/trak/reducers/geneticTestingReducer";
import globalDataModelReducer from "../../modules/admin/reducers/globalDataModelReducer";
import locationProgram from "../../modules/admin/reducers/locationProgramReducer";
import locationReducer from "../../modules/admin/reducers/locationReducer";
import manualRefundReducer from "../../modules/trak/reducers/manualRefundReducer";
import medcaseHeaderReducer from "../../modules/trak/reducers/medcaseHeaderReducer";
import medcaseReducer from "../../modules/trak/reducers/medcaseReducer";
import medcaseRevenue605Reducer from "../../modules/trak/reducers/medcaseRevenue605Reducer";
import multiplier from "../../modules/admin/reducers/multiplierReducer";
import participantReducer from "../../modules/trak/reducers/participantReducer";
import partnerReducer from "../../modules/trak/reducers/partnerReducer";
import patientPaymentReducer from "../../modules/trak/reducers/patientPaymentReducer";
import patientPortalAdminReducer from "../../modules/admin/reducers/patientPortalAdminReducer";
import paymentTiming from "../../modules/admin/reducers/paymentTimingReducer";
import patientReducer from "../../modules/patients/reducers/patientReducer";
import pgta from "../../modules/admin/reducers/pgtaReducer";
import phone from "../../modules/trak/reducers/phoneReducer";
import phoneReducer from "../../modules/admin/reducers/phoneNumberReducer";
import physiciansReducer from "../../modules/admin/reducers/physiciansReducer";
import practices from "../../modules/admin/reducers/practiceReducer";
import programCycleReducer from "../../modules/trak/reducers/programCycleReducer";
import programNameReducer from "../../modules/trak/reducers/programNameReducer";
import programReducer from "../../modules/admin/reducers/programReducer";
import programVisualizationReducer from "../../modules/admin/reducers/programVisualizationReducer";
import providerReimbursementReducer from "../../modules/trak/reducers/providerReimbursementReducer";
import prs from "../../modules/admin/reducers/prsReducer";
import questionsReducer from "../../modules/admin/reducers/questionsReducer";
import quickAddReducer from "../../modules/shared/reducers/quickAddReducer";
import refundAgeReducer from "../../modules/admin/reducers/refundAgeReducer";
import refundApplicationReducer from "../../modules/workspace/reducers/refundApplicationReducer";
import refundFilterListReducer from "../../modules/trak/reducers/refundFilterReducer";
import relativeValueReducer from "../../modules/admin/reducers/relativeValueReducer";
import emailTemplateReducer from "../../modules/admin/reducers/emailTemplateReducer";
import pgtaLabReducer from "../../modules/admin/reducers/pgtaLabReducer";
import resetEmailReducer from "../../modules/admin/reducers/resetEmailReducer";
import serviceItemReducer from "../../modules/admin/reducers/serviceItemReducer";
import stageDetailReducer from "../../modules/admin/reducers/stageDetailReducer";
import stageReducer from "../../modules/admin/reducers/stageReducer";
import statusReducer from "../../modules/trak/reducers/statusReducer";
import trackOutComeFormReducer from "../../modules/trak/reducers/trackOutComeFormReducer";
import transactionLogReducer from "../../modules/trak/reducers/transactionLogReducer";
import treatmentPlanQuestionReducer from "../../modules/admin/reducers/treatmentPlanQuestionReducer";
import treatmentPlanReducer from "../../modules/admin/reducers/treatmentPlanReducer";
import newPrReducer from "../../modules/trak/reducers/newPrReducer";
import revenueReducer from "../../modules/trak/reducers/revenueReducer";
import addRevenueReducer from "../../modules/trak/reducers/addRevenueReducer";
import refundApplicationTrakReducer from "../../modules/trak/reducers/refundApplicationTrakReducer";
import reportReducer from "../../modules/trak/reducers/reportReducer";
import paymentRegReducer from "../../modules/trak/reducers/paymentRegReducer";
import revenueValidationReducer from "../../modules/trak/reducers/revenueValidationReducer";
import enrollReducer from "../../modules/trak/reducers/enrollReducer";
import activeParticipantReducer from "../../modules/trak/reducers/activeParticipantReportReducer";
import inactiveParticipantReducer from "../../modules/trak/reducers/inactiveParticipantReportReducer";
import staffAdminReducer from "../../modules/admin/reducers/staffAdminReducer";
import utilitySearchReducer from "../../modules/shared/reducers/utilitySearchReducer";
import userReducer from "../../modules/admin/reducers/userReducer";
import notificationViewReducer from "../../modules/utilities/reducers/notificationViewReducer";
import ctmReducer from "../../modules/admin/reducers/ctmReducer";
import addNewUserReducer from "../../modules/admin/reducers/addNewUserReducer";
import profileDrawerReducer from "../../modules/shared/reducers/profileDrawerReducer";
import staffAdminUserReducer from "../../modules/admin/reducers/staffAdminUserReducer";
import messageReducer from "../../modules/shared/reducers/messageReducer";
import loadFromExcelReducer from "../../modules/admin/reducers/loadFromExcelReducer";
import pricingModalReducer from "../../modules/trak/reducers/pricingModalReducer";
import trakFinancialEstimatesReducer from "../../modules/trak/reducers/trakFinancialEstimatesReducer";
import outcomeReportReducer from "../../modules/trak/reducers/outcomeReportReducer";
import enrollReportReducer from "../../modules/trak/reducers/enrollReportReducer";
import historyPricingModalReducer from "../../modules/trak/reducers/historyPricingModalReducer";

const allReducers = combineReducers({
  addFeesReducer,
  address,
  addressReducer,
  auditLogsReducer,
  caseListReducer,
  caseUpdateReducer,
  commentReducer,
  contactReducer,
  contractReducer,
  costCoverageReducer,
  documentReducer,
  email,
  emailDocumentReducer,
  emailReducer,
  estimateReducer,
  enableDisableReducer,
  externalDetailsReducer,
  fapReducer,
  feeSchedule,
  feeScheduleReducer,
  financialSnapshotPaymentReducer,
  finSnapshot605AllReducer,
  geneticTestingReducer,
  globalDataModelReducer,
  locationProgram,
  locationReducer,
  manualRefundReducer,
  medcaseHeaderReducer,
  medcaseReducer,
  medcaseRevenue605Reducer,
  multiplier,
  participantReducer,
  partnerReducer,
  patientPaymentReducer,
  patientPortalAdminReducer,
  paymentTiming,
  patientReducer,
  pgta,
  phone,
  phoneReducer,
  physiciansReducer,
  practices,
  profileDrawerReducer,
  programCycleReducer,
  pricingModalReducer,
  programNameReducer,
  programReducer,
  programVisualizationReducer,
  providerReimbursementReducer,
  prs,
  questionsReducer,
  quickAddReducer,
  refundAgeReducer,
  refundApplicationReducer,
  refundFilterListReducer,
  relativeValueReducer,
  emailTemplateReducer,
  pgtaLabReducer,
  resetEmailReducer,
  serviceItemReducer,
  staffAdminReducer,
  staffAdminUserReducer,
  stageDetailReducer,
  stageReducer,
  statusReducer,
  trackOutComeFormReducer,
  transactionLogReducer,
  treatmentPlanQuestionReducer,
  treatmentPlanReducer,
  utilitySearchReducer,
  newPrReducer,
  revenueReducer,
  addRevenueReducer,
  refundApplicationTrakReducer,
  reportReducer,
  paymentRegReducer,
  enrollReducer,
  activeParticipantReducer,
  inactiveParticipantReducer,
  revenueValidationReducer,
  outcomeReportReducer,
  userReducer,
  notificationViewReducer,
  ctmReducer,
  addNewUserReducer,
  messageReducer,
  loadFromExcelReducer,
  trakFinancialEstimatesReducer,
  enrollReportReducer,
  historyPricingModalReducer
});

const rootReducer = (state, action) => {
  if (action.type === actionType.RESET_CONTACT_LIST) {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
