import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Select,
  MenuItem,
  Grid,
  Radio,
  Checkbox,
  FormControlLabel,
  InputLabel,
  CircularProgress
} from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import * as actionType from "../../actions";
import { AddCircle } from "@mui/icons-material";
import { isEmailValid, isPhoneNumberValid } from "../../../shared/constants/constants";
import { useParams } from "react-router-dom";

const AddContactModal = (props) => {
  const {
    actions,
    open,
    add,
    edit,
    close,
    contactPhoneData,
    contactEmailData,
    phoneLoading,
    emailLoading,
    phoneSuccess,
    emailSuccess,
    rowData
  } = props;

  const phone = {
    defaultStatus: true,
    type: "",
    number: ""
  };
  const email = {
    defaultStatus: true,
    emailAddress: "",
    status: ""
  };

  let contactForm = {
    firstName: "",
    lastName: "",
    showInMedcase: false,
    status: "",
    type: ""
  };

  const [phoneData, setPhoneData] = useState([phone]);
  const [emailData, setEmailData] = useState([email]);
  const [values, setValues] = useState(contactForm);
  const [isValid, setIsValid] = useState(true);
  const [validationMsg, setValidationMsg] = useState("");
  const { practiceId } = useParams();

  useEffect(() => {
    if (edit && phoneSuccess && emailSuccess) {
      setValues(rowData);
      setPhoneData(contactPhoneData);
      setEmailData(contactEmailData);
    }
  }, [phoneSuccess, emailSuccess]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.type === "checkbox") {
      setValues({ ...values, [name]: e.target.checked });
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const handleChangePhone = (e, index) => {
    const { name, value } = e.target;
    if (name == "number" && value) {
      let res = isPhoneNumberValid(value);
      setIsValid(res.status);
      setValidationMsg(res.msg);
    }
    if (e.target.type === "radio") {
      setPhoneData((prev) => prev.map((item) => ({ ...item, defaultStatus: false })));
      setPhoneData((prevRows) =>
        prevRows.map((row, i) => (index == i ? { ...row, [name]: e.target.checked } : row))
      );
    } else {
      setPhoneData((prevRows) =>
        prevRows.map((row, i) => (index == i ? { ...row, [name]: value } : row))
      );
    }
  };

  const handleChangeEmail = (e, index) => {
    const { name, value } = e.target;
    if (name == "emailAddress" && value) {
      let res = isEmailValid(value);
      setIsValid(res.status);
      setValidationMsg(res.msg);
    }
    if (e.target.type === "radio") {
      setEmailData((prev) => prev.map((item) => ({ ...item, defaultStatus: false })));
      setEmailData((prevRows) =>
        prevRows.map((row, i) => (index == i ? { ...row, [name]: e.target.checked } : row))
      );
    } else {
      setEmailData((prevRows) =>
        prevRows.map((row, i) => (index == i ? { ...row, [name]: value } : row))
      );
    }
  };

  const addPhoneNumber = () => {
    let addPhone = { ...phone, defaultStatus: false, op: "add" };
    setPhoneData([...phoneData, addPhone]);
  };

  const addEmail = () => {
    let addEmail = { ...email, defaultStatus: false, op: "add" };
    setEmailData([...emailData, addEmail]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {
      practiceId,
      ...values,
      emails: emailData,
      phoneNumbers: phoneData
    };
    if (add) {
      actions.addContact(payload);
    } else {
      let updatedEmailData =
        emailData &&
        emailData.map((item) => {
          if (!item.op) {
            item.op = "update";
          }
          return item;
        });
      let updatedphoneData =
        phoneData &&
        phoneData.map((item) => {
          if (!item.op) {
            item.op = "update";
          }
          return item;
        });

      let editPayload = {
        ...payload,
        emails: updatedEmailData,
        phoneNumbers: updatedphoneData,
        contactId: rowData.contactId
      };
      console.log("editPayload", editPayload);
      actions.editContact(editPayload);
    }

    handleClose();
  };

  const handleClose = () => {
    setValues(contactForm);
    setEmailData([email]);
    setPhoneData([phone]);
    actions.resetPhoneData();
    close();
  };

  const renderPhoneNumber = phoneData.map((item, index) => {
    return (
      <Grid container key={index} spacing={1}>
        <Grid item xs={2}>
          <Radio
            name="defaultStatus"
            checked={item.defaultStatus}
            value={item.defaultStatus}
            onChange={(e) => handleChangePhone(e, index)}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            size="small"
            name="type"
            fullWidth
            value={item.type}
            onChange={(e) => handleChangePhone(e, index)}>
            <MenuItem value="Cell">Cell</MenuItem>
            <MenuItem value="Home">Home</MenuItem>
            <MenuItem value="Work">Work</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row">
            <TextField
              size="small"
              fullWidth
              name="number"
              value={item.number}
              type="number"
              onChange={(e) => handleChangePhone(e, index)}
            />
          </Stack>
        </Grid>
        {index == 0 ? (
          <Grid item xs={2}>
            <IconButton
              onClick={() => addPhoneNumber()}
              disabled={item.number && item.type ? false : true}>
              <AddCircle color={item.number && item.type ? "primary" : "#8C8C8C"} />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    );
  });

  const renderEmail = emailData.map((item, index) => {
    return (
      <Grid container key={index} spacing={1}>
        <Grid item xs={2}>
          <Radio
            checked={item.defaultStatus}
            name="defaultStatus"
            onChange={(e) => handleChangeEmail(e, index)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            size="small"
            fullWidth
            autoComplete="off"
            name="emailAddress"
            value={item.emailAddress}
            type="text"
            onChange={(e) => handleChangeEmail(e, index)}
          />
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row">
            <Select
              size="small"
              fullWidth
              name="status"
              value={item.status}
              onChange={(e) => handleChangeEmail(e, index)}>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </Stack>
        </Grid>
        {index == 0 ? (
          <Grid item xs={2}>
            <IconButton
              onClick={() => addEmail()}
              disabled={item.emailAddress && item.status ? false : true}>
              <AddCircle color={item.emailAddress && item.status ? "primary" : "#8C8C8C"} />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    );
  });

  let validate;
  let phoneType = phoneData.filter(
    (ph) => ph.type !== "" && ph.number !== "" && isPhoneNumberValid(ph.number).status
  );
  let emailType = emailData.filter(
    (email) =>
      email.status !== "" && email.emailAddress !== "" && isEmailValid(email.emailAddress).status
  );

  if (
    values.firstName &&
    values.lastName &&
    values.status &&
    values.type &&
    phoneType.length == phoneData.length &&
    emailType.length == emailData.length
  ) {
    validate = true;
  } else {
    validate = false;
  }

  return (
    <Modal open={open} backdrop="static" aria-labelledby="modal-contacts">
      <Box component="form" onSubmit={handleSubmit} className="modal-style-contact">
        <Stack direction="row" className="modal-stack">
          <Typography variant="body2">{add ? "Add Contact" : "Edit Contact"}</Typography>
          <IconButton onClick={handleClose}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Stack>
        <Divider className="modal-divider" />
        {!isValid && <Box className="alert mt-8">{validationMsg}</Box>}
        {phoneLoading && emailLoading ? (
          <Stack justifyContent="center" alignItems="center" m={2}>
            <CircularProgress />
          </Stack>
        ) : (
          <Box className="contact-container">
            <Box className="mt-8  contact-form">
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Stack>
                    <Grid container className="modal-stack">
                      <Grid item xs={4}>
                        <InputLabel required>Type of Contact</InputLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <Select
                          size="small"
                          name="type"
                          fullWidth
                          value={values.type}
                          onChange={handleInputChange}>
                          <MenuItem value="Nurse">Nurse</MenuItem>
                          <MenuItem value="Office">Office</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container className="modal-stack">
                      <Grid item xs={4}>
                        <InputLabel required>First Name</InputLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          size="small"
                          fullWidth
                          autoComplete="off"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container className="modal-stack">
                      <Grid item xs={4}>
                        <InputLabel required>Last Name</InputLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          size="small"
                          fullWidth
                          autoComplete="off"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleInputChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container className="modal-stack">
                      <Grid item xs={4}>
                        <InputLabel required>Status</InputLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <Select
                          size="small"
                          fullWidth
                          name="status"
                          value={values.status}
                          onChange={handleInputChange}>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                    <Grid container className="modal-stack">
                      <Grid item xs={4}>
                        <InputLabel>Show In Medcase</InputLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <FormControlLabel
                          value="start"
                          control={
                            <Checkbox
                              name="showInMedcase"
                              checked={values.showInMedcase}
                              value={values.showInMedcase}
                              onChange={handleInputChange}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Grid container className="mt-8">
                    <Grid item xs={2}>
                      <InputLabel>Default</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <InputLabel required>Type</InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel required>Phone Number</InputLabel>
                    </Grid>
                  </Grid>
                  {renderPhoneNumber}
                  <Grid container className="mt-8">
                    <Grid item xs={2}>
                      <InputLabel>Default</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <InputLabel required>Email Address</InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel required>Status</InputLabel>
                    </Grid>
                  </Grid>
                  {renderEmail}
                </Grid>
              </Grid>
            </Box>

            <Stack direction="row" spacing={2} className="modal-bottom-section">
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" disabled={!validate} className="btn-usf">
                {add ? "Create" : "Save"}
              </Button>
            </Stack>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
const mapStateToProps = ({ phoneReducer }) => {
  return {
    contactPhoneData: phoneReducer.phoneData,
    contactEmailData: phoneReducer.emailData,
    phoneLoading: phoneReducer.phoneLoading,
    emailLoading: phoneReducer.phoneLoading,
    phoneSuccess: phoneReducer.phoneSuccess,
    emailSuccess: phoneReducer.emailSuccess
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    addContact: (payload) => {
      dispatch(actionType.addContact(payload));
    },
    editContact: (payload) => {
      dispatch(actionType.editContact(payload));
    },
    getPhoneData: (payload) => {
      dispatch(actionType.getContactPhoneData(payload));
    },
    getEmailData: (payload) => {
      dispatch(actionType.getContactEmailData(payload));
    },
    resetPhoneData: () => {
      dispatch(actionType.resetPhoneData());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AddContactModal);
