import { Box, Grid, Stack, Typography, Button, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./Admin.scss";
import * as actionType from "../actions";
import GlobalDataModalPopup from "./modals/GlobalDataModalPopup";
import MessageModal from "../../shared/components/MessageModal";
import { connect } from "react-redux";
import moment from "moment";

const GlobalDataModelCard = (props) => {
  const { gdmRowData, gdmList, editGlobalData, error, success, actions, loading } = props;
  const practiceId = sessionStorage.getItem("practiceId");
  const practiceName = sessionStorage.getItem("practiceName");
  const gdmId = sessionStorage.getItem("gdmId");
  const [cardData, setCardData] = useState(gdmRowData);
  const [isOpen, setIsOpen] = useState(false);
  const [isExisting, setIsExistig] = useState(false);
  const [isChildOpen, setIsChildOpen] = useState(false);

  useEffect(() => {
    if (success || error) {
      setIsChildOpen(true);
    }
    if (gdmList.length == 0 || editGlobalData) {
      actions.getGlobalData(practiceId);
    }
    return () => {
      actions.resetGlobalData();
    };
  }, [practiceId, editGlobalData, success, error]);
  useEffect(() => {
    if (gdmList.length) {
      gdmList.forEach((item) => {
        if (item.gdmId == gdmId) {
          const today = new Date(item?.effectiveDate);
          const dd = String(today.getDate() + 1).padStart(2, "0");
          const mm = String(today.getMonth() + 1).padStart(2, "0");
          const yyyy = today.getFullYear();
          const currDate = yyyy + "-" + mm + "-" + dd;
          setCardData({
            ...cardData,
            gdmId: item?.gdmId,
            gdmVersion: item?.gdmVersion,
            effectiveDate: currDate,
            status: item?.status,
            assistPricePercentage: item?.assistPricePercentage
          });
          actions.updateGdmRow(cardData);
        }
      });
    }
  }, [gdmList]);
  const handleCreateGdm = (createData) => {
    let payload = { practiceId, gdmData: createData };
    payload.gdmId = gdmId;
    actions.updateNewGdm(payload);
    setIsOpen(false);
  };
  const handleClose = () => {
    setIsOpen(false);
    setIsExistig(false);
  };
  const closeChildModal = () => {
    setIsChildOpen(false);
    actions.resetMessage();
  };

  return (
    <Box sx={{ background: "#F9F9F9" }} className={`${loading ? "m-10 disable-section" : "m-10"}`}>
      <Stack direction="row" className="pad-10 flex-between">
        <Stack direction="column">
          <Typography variant="body1">
            Practice:{practiceName}-{practiceId}
          </Typography>
          <Typography variant="body2">{cardData?.gdmVersion}</Typography>
        </Stack>
        <Stack direction="row" gap={1}>
          <Button variant="outlined" disabled={props.disabled} onClick={() => setIsOpen(true)}>
            <img alt="" src="/Assets/Vector.png" />
          </Button>

          {/* <Button variant="contained" className="btn-usf" disabled={props.disabled}>
            Save
          </Button> */}
        </Stack>
      </Stack>
      <Grid container className="pad-10">
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>EffectiveDate</InputLabel>
            <Typography>
              {cardData?.effectiveDate ? moment(cardData?.effectiveDate).format("L") : "--"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Assist price %</InputLabel>
            <Typography variant="body1">{cardData?.assistPricePercentage} %</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack direction="column">
            <InputLabel>Status</InputLabel>
            <Typography variant="body1" className="txt-capitalize">
              {cardData?.status}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <GlobalDataModalPopup
        open={isOpen}
        edit={true}
        isExixtingGdm={isExisting}
        data={gdmRowData}
        handleCreateGdm={handleCreateGdm}
        handleClose={handleClose}
      />
      <MessageModal
        open={isChildOpen}
        error={error}
        message={success}
        closeModal={closeChildModal}
      />
    </Box>
  );
};

const mapStateToProps = ({ globalDataModelReducer }) => {
  return {
    gdmRowData: globalDataModelReducer.gdmRowData,
    gdmList: globalDataModelReducer.globalData,
    editGlobalData: globalDataModelReducer.editGlobalData,
    error: globalDataModelReducer.error,
    success: globalDataModelReducer.success,
    loading: globalDataModelReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getGlobalData: (practiceId) => {
      dispatch(actionType.getGlobalData(practiceId));
    },
    updateGdmRow: (payload) => {
      dispatch(actionType.updateGdmRow(payload));
    },
    updateNewGdm: (payload) => {
      dispatch(actionType.updateNewGdm(payload));
    },
    resetGlobalData: () => {
      dispatch(actionType.resetGlobalData());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDataModelCard);
