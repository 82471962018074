import { Box, CircularProgress, Modal } from "@mui/material";

const LoadingModal = (props) => {
  return (
    <Modal open={props.open} backdrop="static" aria-labelledby="modal-add-title">
      <Box className="alert-modal" width="100px">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
          <CircularProgress size={50} />
        </Box>
      </Box>
    </Modal>
  );
};

export default LoadingModal;
