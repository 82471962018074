import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* createNewTreatmentPlanQuestion(action) {
  const { payload } = action;
  let arr = [];
  let x = {};
  x.responseNo = payload.responseNo;
  arr.push(x);
  const requestbody = {
    questionId: payload.questionId,
    responses: arr
  };
  try {
    try {
      if (payload.questionId == 1) {
        const requestBody = createRequestBody(
          "delete",
          `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId}/EstimateQuestionSubquestions/${payload.questionId}`,
          "",
          ""
        );
        const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
        yield call(() => apis(request));
      }
    } catch (ex) {
      //exception test
    }
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId}/EstimateQuestionSubquestions`,
      "",
      requestbody
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_QUESTION_TREATMENT_PLAN_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_QUESTION_TREATMENT_PLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewTreatmentPlanQuestionSaga() {
  yield takeEvery(
    actionTypes.ADD_QUESTION_TREATMENT_PLAN_REQUESTED,
    createNewTreatmentPlanQuestion
  );
}

export default function* treatmentPlanQuestionSaga() {
  yield all([addNewTreatmentPlanQuestionSaga()]);
}
