import * as actionType from "../actions/ActionTypes";

const initialState = {
  refundApplicationList: [],
  loading: false,
  error: null,
  requestedCycle: []
};

const refundApplicationTrakReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.REFUND_APPLICATION_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.REFUND_APPLICATION_LIST_SUCCESS: {
      return {
        ...state,
        refundApplicationList: action.payload,
        loading: false
      };
    }
    case actionType.REFUND_APPLICATION_LIST_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_REFUND_APPLICATION_LIST: {
      return {
        ...state,
        refundApplicationList: []
      };
    }
    case actionType.REQUESTED_CYCLE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }

    case actionType.REQUESTED_CYCLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        requestedCycleList: action.payload
      };
    }

    case actionType.REQUESTED_CYCLE_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default refundApplicationTrakReducer;
