import { Button, Box } from "@mui/material";

const ActionButton = ({
  varient,
  label,
  disabled,
  onClose,
  isEdit = false,
  onEditClick = () => {},
  ...props
}) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 12 }}>
        <Button variant="outlined" onClick={onClose} {...props}>
          Close
        </Button>
        <Box>
          <Button variant={varient} type="submit" {...props} disabled={disabled}>
            {label}
          </Button>
          {isEdit && (
            <Button
              variant={varient}
              {...props}
              disabled={disabled}
              style={{ marginLeft: 8 }}
              onClick={onEditClick}>
              Edit
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ActionButton;
