import { Box, Modal, Stack } from "@mui/material";
import { RenderChild } from "./RenderChilds";

const CommonModal = ({ config, open, handleSubmit, isValid = true, validationMsg, modalClass }) => {
  return (
    <Modal open={open}>
      <Box
        className={"modal-style" + (modalClass ? " " + modalClass : "")}
        component="form"
        onSubmit={handleSubmit}
        style={{ width: 580 }}>
        {!isValid && <Box className="alert">{validationMsg}</Box>}
        <Box style={{ maxHeight: "90vh", overflow: "auto" }}>
          <Stack style={{ paddingRight: "20px" }}>
            <RenderChild config={config} />
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default CommonModal;
