import * as actionType from "../actions/ActionTypes";

const initialState = {
  outcomeReportList: [],
  loading: false,
  error: null,
  success: null
};
const outcomeReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_OUTCOME_REPORT: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_OUTCOME_REPORT_SUCCESS: {
      return {
        ...state,
        outcomeReportList: action.payload,
        loading: false,
        success: true
      };
    }
    case actionType.GET_OUTCOME_REPORT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.OUTCOME_REPORT_RESET_MESSAGE: {
      return {
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default outcomeReportReducer;
