import { Box, IconButton, Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PlaceIcon from "@mui/icons-material/Place";
import React, { useEffect, useState, useCallback } from "react";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useParams } from "react-router-dom";
import MessageModal from "../../shared/components/MessageModal";
import { physicianConfig } from "../../shared/constants/renderConfigs";
import CommonModal from "../../shared/components/CommonModal";
import { isEmailValid } from "../../shared/constants/constants";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 75
  }
}));

const Physicians = (props) => {
  const locationListStore = useSelector((state) => state.locationReducer.locations);
  const locationOptionData = [];
  locationListStore.map((item) => {
    locationOptionData.push({ id: item.locationId, name: item.name });
  });
  let physicianFormData = {
    firstName: "",
    lastName: "",
    emailAddress: "",
    npiNo: "",
    externalId: "",
    fertExternalId: "",
    locations: [],
    status: "Active"
  };
  const columns = [
    { field: "physicianId", headerName: "ID", width: 50, flex: 0.5, sortable: false },
    {
      field: "firstName",
      headerName: "FIRST NAME",
      width: 100,
      flex: 1
    },
    {
      field: "lastName",
      headerName: "LAST NAME",
      width: 100,
      flex: 1
    },
    {
      field: "emailAddress",
      headerName: "EMAIL",
      width: 100,
      flex: 1
    },
    {
      field: "npiNo",
      headerName: "NPI NO",
      width: 100,
      flex: 1
    },
    {
      field: "externalId",
      headerName: "EXTERNAL ID",
      width: 100,
      flex: 1
    },
    {
      field: "locationIds",
      headerName: "LOCATION",
      width: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <LightTooltip
            title={getLocationName(params.row.locationIds).toString()}
            placement="left-start">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}>
              <PlaceIcon />
            </div>
          </LightTooltip>
        );
      }
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => (
        <span style={{ color: row.status === "Active" ? "green" : "red" }}>{row.status}</span>
      )
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },

    {
      field: "action",
      headerName: "ACTION",
      width: 100,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleEditPhysician(row);
          }}>
          <EditIcon color="primary"></EditIcon>
        </IconButton>
      )
    }
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const {
    physicians = [],
    loading,
    errorMsg,
    successMsg,
    actions,
    addPhysician,
    editPhysician
  } = props;
  const { practiceId } = useParams();
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isValid, setIsValid] = useState(true);
  const [validationMsg, setValidationMsg] = useState("");
  const [values, setValues] = useState(physicianFormData);
  const initFetch = useCallback(() => {
    actions.getPhysicians(practiceId);
  }, [addPhysician, editPhysician]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (physicians.length === 0 || addPhysician || editPhysician) {
      initFetch();
    }
    return () => {
      actions.resetPhysicianList();
    };
  }, [addPhysician, editPhysician, successMsg, errorMsg]);

  const closeModal = () => {
    setValues(physicianFormData);
    setIsOpen(false);
  };

  const getLocationIds = () => {
    let locationIds = [];
    locationOptionData.forEach((item) => {
      values.locations.forEach((name) => {
        if (item.name == name) {
          locationIds.push({ locationId: item.id });
        }
      });
    });
    return locationIds;
  };

  const getLocationName = (locationIdArr) => {
    let locationName = [];
    locationIdArr.map((item) => {
      locationName.push(item.name.trim());
    });
    return locationName;
  };
  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name == "emailAddress" && value) {
      let res = isEmailValid(value);
      setIsValid(res.status);
      setValidationMsg(res.msg);
    }
    setValues({
      ...values,
      [name]: value
    });
  };
  const rows = physicians.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    item.id = index;
    return item;
  });

  const addNewPhysicians = () => {
    setIsOpen(true);
    setIsAdd(true);
    setValues(physicianFormData);
  };

  const handleEditPhysician = (row) => {
    let locations = [];
    if (row.locationIds.length) {
      locations = getLocationName(row.locationIds);
    }
    setIsAdd(false);
    physicianFormData = {
      practiceId: practiceId,
      physicianId: row.physicianId,
      firstName: row.firstName,
      lastName: row.lastName,
      emailAddress: row.emailAddress,
      npiNo: row?.npiNo,
      externalId: row?.externalId,
      fertExternalId: row?.fertExternalId,
      status: row.status,
      locations: locations
    };
    setValues(physicianFormData);
    setIsOpen(true);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const savePhysician = (event) => {
    event.preventDefault();
    let locationIds = getLocationIds();
    values.locations = locationIds;
    values.practiceId = practiceId;
    if (isAdd) {
      values.npiNo = values.npiNo.length ? values.npiNo : null;
      values.externalId = values.externalId.length ? values.externalId : null;
      values.fertExternalId = values.fertExternalId.length ? values.fertExternalId : null;
      actions.addNewPhysician(values);
    } else {
      actions.editPhysician(values);
    }
    closeModal();
  };
  const physician = physicianConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd: isAdd,
    optionData: locationOptionData,
    disabled:
      values.firstName && values.lastName && values.emailAddress && values.status && isValid
        ? false
        : true
  });
  return (
    <Box>
      <Box>
        <Button
          variant="contained"
          className="btn-primary tab-panel-btn"
          onClick={addNewPhysicians}>
          New Physician
        </Button>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal
        open={isOpen}
        config={physician}
        isAdd={isAdd}
        handleSubmit={savePhysician}
        onClick={closeModal}
        isValid={isValid}
        validationMsg={validationMsg}
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <Button
        variant="contained"
        sx={{
          border: "2px solid #1BA3AD",
          borderRadius: "unset",
          marginRight: 120,
          color: "primary"
        }}>
        <b>Back</b>
      </Button>
    </Box>
  );
};

const mapStateToProps = ({ physiciansReducer, locationReducer }) => {
  return {
    physicians: physiciansReducer.physicians,
    addPhysician: physiciansReducer.addPhysician,
    editPhysician: physiciansReducer.editPhysician,
    loading: physiciansReducer.loading,
    locations: locationReducer.locations,
    errorMsg: physiciansReducer.error,
    successMsg: physiciansReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPhysicians: (practiceId) => {
      dispatch(actionType.getPhysicians(practiceId));
    },
    addNewPhysician: (payload) => {
      dispatch(actionType.addNewPhysician(payload));
    },
    editPhysician: (payload) => {
      dispatch(actionType.editPhysician(payload));
    },
    resetPhysicianList: (payload) => {
      dispatch(actionType.resetPhysicianList(payload));
    },
    getLocations: (practiceId) => {
      dispatch(actionType.getLocations(practiceId));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Physicians);
