import * as actionType from "../actions/ActionTypes";
const initialState = {
  programCycle: [],
  loading: false,
  error: null,
  success: null
};

const programCycleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PROGRAM_CYCLE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PROGRAM_CYCLE_SUCCESS: {
      return {
        ...state,
        programCycle: action.payload,
        loading: false
      };
    }
    case actionType.PROGRAM_CYCLE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default programCycleReducer;
