import * as actionType from "../actions/ActionTypes";

const initialState = {
  enableResponse: null,
  loading: false,
  error: null,
  success: null
};

const enableDisableReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ENABLE_DISABLE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ENABLE_DISABLE_SUCCESS: {
      return {
        ...state,
        enableResponse: action.payload,
        loading: false
      };
    }
    case actionType.ENABLE_DISABLE_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default enableDisableReducer;
