//Quick Add
export const QUICK_ADD_LIST_REQUESTED = "QUICK_ADD_LIST_REQUESTED";
export const QUICK_ADD_LIST_SUCCESS = "QUICK_ADD_LIST_SUCCESS";
export const QUICK_ADD_LIST_FAIL = "QUICK_ADD_LIST_FAIL";
export const RESET_QUICK_ADD_LIST = "RESET_QUICK_ADD_LIST";

//Add Quick Add
export const ADD_NEW_QUICK_ADD_REQUESTED = "ADD_NEW_QUICK_ADD_REQUESTED";
export const ADD_NEW_QUICK_ADD_SUCCESS = "ADD_NEW_QUICK_ADD_SUCCESS";
export const ADD_NEW_QUICK_ADD_FAILED = "ADD_NEW_QUICK_ADD_FAILED";

//Edit Quick Add
export const EDIT_QUICK_ADD_REQUESTED = "EDIT_QUICK_ADD_REQUESTED";
export const EDIT_QUICK_ADD_SUCCESS = "EDIT_QUICK_ADD_SUCCESS";
export const EDIT_QUICK_ADD_FAILED = "EDIT_QUICK_ADD_FAILED";

//Delete quick add
export const DELETE_QUICK_ADD_REQUESTED = "DELETE_QUICK_ADD_REQUESTED";
export const DELETE_QUICK_ADD_SUCCESS = "DELETE_QUICK_ADD_SUCCESS";
export const DELETE_QUICK_ADD_FAIL = "DELETE_QUICK_ADD_FAIL";

//Reset MSG
export const RESET_MESSAGE = "RESET_MESSAGE";

//UtilitySearch
export const UTILITYSEARCH_LIST_REQUESTED = "UTILITYSEARCH_LIST_REQUESTED";
export const UTILITYSEARCH_LIST_SUCCESS = "UTILITYSEARCH_LIST_SUCCESS";
export const UTILITYSEARCH_LIST_FAIL = "UTILITYSEARCH_LIST_FAIL";
export const RESET_UTILITYSEARCH_LIST = "RESET_UTILITYSEARCH_LIST";

//ProfileDrawer

export const USER_PROFILE_DETAILS_REQUESTED = "USER_PROFILE_DETAILS_REQUESTED";
export const USER_PROFILE_DETAILS_SUCCESS = "USER_PROFILE_DETAILS_SUCCESS";
export const USER_PROFILE_DETAILS_FAIL = "USER_PROFILE_DETAILS_FAIL";

//list of all messages
export const LIST_OF_MESSAGES_REQUESTED = "LIST_OF_MESSAGES_REQUESTED";
export const LIST_OF_MESSAGES_SUCCESS = "LIST_OF_MESSAGES_SUCCESS";
export const LIST_OF_MESSAGES_FAILED = "LIST_OF_MESSAGES_FAILED";

//for sending the message
export const CREATE_NEW_MESSAGE_REQUESTED = "CREATE_NEW_MESSAGE_REQUESTED";
export const CREATE_NEW_MESSAGES_SUCCESS = "CREATE_NEW_MESSAGES_SUCCESS";
export const CREATE_NEW_MESSAGES_FAILED = "CREATE_NEW_MESSAGES_FAILED";

//for toggling the message read status
export const TOGGLE_MESSAGE_ISREAD_STATUS_REQUESTED = "TOGGLE_MESSAGE_ISREAD_STATUS_REQUESTED";
export const TOGGLE_MESSAGE_ISREAD_STATUS_SUCCESS = "TOGGLE_MESSAGE_ISREAD_STATUS_SUCCESS";
export const TOGGLE_MESSAGE_ISREAD_STATUS_FAILED = "TOGGLE_MESSAGE_ISREAD_STATUS_FAILED";

//Delete messages
export const DELETE_MESSAGE_REQUESTED = "DELETE_MESSAGE_REQUESTED";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAIL = "DELETE_MESSAGE_FAIL";

//Update messages
export const UPDATE_MULTIPLE_MESSAGES_REQUESTED = "UPDATE_MULTIPLE_MESSAGES_REQUESTED";
export const UPDATE_MULTIPLE_MESSAGES_SUCCESS = "UPDATE_MULTIPLE_MESSAGES_SUCCESS";
export const UPDATE_MULTIPLE_MESSAGES_FAILED = "UPDATE_MULTIPLE_MESSAGES_FAILED";

//get all the users list
export const USERS_LIST_REQUESTED = "USERS_LIST_REQUESTED";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_FAIL = "USERS_LIST_FAIL";

//fetch file
export const GET_FILE_REQUESTED = "GET_FILE_REQUESTED";
export const GET_FILE_SUCCESS = "GET_FILE_SUCCESS";
export const GET_FILE_FAILED = "GET_FILE_FAILED";

//send file
export const POST_FILE_REQUESTED = "POST_FILE_REQUESTED";
export const POST_FILE_SUCCESS = "POST_FILE_SUCCESS";
export const POST_FILE_FAILED = "POST_FILE_FAILED";

export const RESET_MESSAGES_EXECUTE_STATUS = "RESET_MESSAGES_EXECUTE_STATUS";

export const ENABLE_FLAG_NAVBAR = "ENABLE_FLAG_NAVBAR";

// Quick add for patient portal admin
// Quick Add Get
export const USER_ID_QUICK_ADD_LIST_REQUESTED = "USER_ID_QUICK_ADD_LIST_REQUESTED";
export const USER_ID_QUICK_ADD_LIST_SUCCESS = "USER_ID_QUICK_ADD_LIST_SUCCESS";
export const USER_ID_QUICK_ADD_LIST_FAILED = "USER_ID_QUICK_ADD_LIST_FAILED";
export const USER_ID_RESET_QUICK_ADD_LIST = "USER_ID_RESET_QUICK_ADD_LIST";

// Quick add Post
export const USER_ID_ADD_NEW_QUICK_ADD_REQUESTED = "USER_ID_ADD_NEW_QUICK_ADD_REQUESTED";
export const USER_ID_ADD_NEW_QUICK_ADD_SUCCESS = "USER_ID_ADD_NEW_QUICK_ADD_SUCCESS";
export const USER_ID_ADD_NEW_QUICK_ADD_FAILED = "USER_ID_ADD_NEW_QUICK_ADD_FAILED";

// Quick add edit
export const USER_ID_EDIT_QUICK_ADD_REQUESTED = "USER_ID_EDIT_QUICK_ADD_REQUESTED";
export const USER_ID_EDIT_QUICK_ADD_SUCCESS = "USER_ID_EDIT_QUICK_ADD_SUCCESS";
export const USER_ID_EDIT_QUICK_ADD_FAILED = "USER_ID_EDIT_QUICK_ADD_FAILED";

// Quick add delete
export const USER_ID_DELETE_QUICK_ADD_REQUESTED = "USER_ID_DELETE_QUICK_ADD_REQUESTED";
export const USER_ID_DELETE_QUICK_ADD_SUCCESS = "USER_ID_DELETE_QUICK_ADD_SUCCESS";
export const USER_ID_DELETE_QUICK_ADD_FAILED = "USER_ID_DELETE_QUICK_ADD_FAILED";
