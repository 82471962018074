import * as actionType from "../actions/ActionTypes";

const initialState = {
  partnerList: [],
  patientDetails: {},
  addPartner: null,
  editPartner: null,
  loading: false,
  error: null,
  success: null
};

const parterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PARTNER_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PARTNER_LIST_SUCCESS: {
      return {
        ...state,
        partnerList: action.payload,
        loading: false
      };
    }
    case actionType.PARTNER_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_PARTNER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_PARTNER_SUCCESS: {
      return {
        ...state,
        addPartner: action.payload,
        partnerList: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_PARTNER_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.EDIT_PARTNER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PARTNER_SUCCESS: {
      return {
        ...state,
        editPartner: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_PARTNER_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_PARTNER: {
      return {
        ...state,
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    case actionType.RESET_PARTNER_LIST: {
      return {
        ...state,
        partnerList: [],
        loading: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default parterReducer;
