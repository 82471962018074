import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Divider,
  Modal,
  Stack,
  Button,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  TextField
  // FormLabel
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Close from "@mui/icons-material/Close";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MessageModal from "../../../shared/components/MessageModal";
import * as actionType from "../../actions";
// import * as WorkspaceActionType from "../../../workspace/actions";
import { connect, useSelector } from "react-redux";
import SendAuthorizationModal from "./SendAuthorizationModal";
import EmailDocumentModal from "./EmailDocumentModal";
import { getGlobalData, getTreatmentPlan } from "../../../admin/actions";
import { v4 as uuidv4 } from "uuid";
import {
  handleValidateSaveOutcome,
  removeObjParameters,
  handleEnableTransferDate
  // ACKNOWLEDGE_TEXT
} from "../../../shared/constants/constants";
import moment from "moment";
import WarnningMessageModal from "../../../shared/components/WarnningMessageModal";
import WarningPopUp from "./WarningPopUp";
import DataGridPremiumComp from "../../../shared/components/DataGridPremiumComp";

const TrakOutcomeFormModal = (props) => {
  const {
    actions,
    clinicalList,
    editClinical,
    successMsg,
    errorMsg,
    errorResponse,
    sendAuth,
    cycleList,
    rowData,
    patientDetails,
    errorEmail,
    successMsgEmail
  } = props;
  //console.log("props", props);
  const stagesColumn = [
    {
      field: "stageName",
      headerName: "STAGE",
      width: 50,
      flex: 0.5,
      sortable: false
    },
    {
      field: "stageCompleted",
      headerName: "STAGE COMPLETE",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        let thisEle = null;

        values.forEach((ele) => {
          if (ele.clinicalTreatmentId === selectedList) {
            thisEle = ele;
          }
        });

        return (
          <Checkbox
            checked={row.stageCompleted}
            disabled={row.disabled || (thisEle && isResetCycle(thisEle.clinicalTreatmentNo))}
            onChange={(e) => handleStageCheckCompleted(e, row)}
          />
        );
      }
    },
    {
      field: "stageCancelled",
      headerName: "STAGE CANCELED AFTER",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        let thisEle = null;

        values.forEach((ele) => {
          if (ele.clinicalTreatmentId === selectedList) {
            thisEle = ele;
          }
        });
        return (
          <Checkbox
            checked={row.stageCancelled}
            disabled={row.disabled || (thisEle && isResetCycle(thisEle.clinicalTreatmentNo))}
            onChange={(e) => handleStageCheckCancelled(e, row)}
          />
        );
      }
    },
    {
      field: "dateRecorded",
      headerName: "DATE RECORDED",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        let thisEle = null;

        values.forEach((ele) => {
          if (ele.clinicalTreatmentId === selectedList) {
            thisEle = ele;
          }
        });

        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              openTo="day"
              name="dateRecorded"
              disabled={row.disabled || (thisEle && isResetCycle(thisEle.clinicalTreatmentNo))}
              value={row?.dateRecorded}
              onChange={(newValue) => {
                setValues((values) =>
                  values.map((ele) => {
                    if (ele.clinicalTreatmentId === selectedList) {
                      let edcDate = null;
                      let ctStages = ele.clinicalTreatmentStages.map((el) => {
                        if (el.stageId === row.stageId) {
                          if (row.stageName == "Retrieval" && ele.outcomeCategory == "Pregnant") {
                            let newDate = new Date(newValue);
                            newDate.setDate(newDate.getDate() + 266);
                            edcDate = newDate;
                          }
                          return {
                            stageId: el.stageId,
                            stageName: el.stageName,
                            stageCompleted: el.stageCompleted,
                            stageCancelled: el.stageCancelled,
                            dateRecorded: newValue
                          };
                        }
                        return el;
                      });
                      return {
                        ...ele,
                        edcDate: edcDate || ele.edcDate,
                        clinicalTreatmentStages: ctStages
                      };
                    }
                    return ele;
                  })
                );
              }}
              renderInput={(params) => <TextField {...params} size="small" sx={{ flex: 8 }} />}
            />
          </LocalizationProvider>
        );
      }
    }
  ];

  const outcomeColumns = [
    {
      field: "clinicalTreatmentNo",
      headerName: " CYCLE NO",
      width: 30,
      flex: 1,
      sortable: false
    },
    {
      field: "authDate",
      headerName: "AUTHORIZED DATE",
      width: 90,
      flex: 1,
      sortable: false
    },
    {
      field: "outcomeDate",
      headerName: "OUTCOME DATE",
      width: 90,
      editable: false,
      flex: 1
    },
    {
      field: "outcomeDescription",
      headerName: "OUTCOME",
      width: 90,
      editable: false,
      flex: 1.5,
      renderCell: ({ row }) => {
        if (row.outcomeCategory && row.outcomeDescription)
          return row.outcomeCategory + " - " + row.outcomeDescription;
        else return "";
      }
    },
    {
      field: "requestedBy",
      headerName: "USER",
      width: 90,
      editable: false,
      flex: 1
    },
    {
      field: "requestedService",
      headerName: "REQUESTED SERVICE",
      width: 90,
      editable: false,
      flex: 1
    },
    {
      field: "remainingEmbryo",
      headerName: "EMBRYOS REMAIN",
      width: 90,
      editable: false,
      flex: 1,
      valueGetter: (params) => params?.row?.clinicalTreatmentAdditionalField?.remainingEmbryo
    },
    {
      field: "normalFrozenEmbroysRemaining",
      headerName: "NORMAL EMBRYOS REMAIN",
      width: 90,
      editable: false,
      flex: 1,
      valueGetter: (params) =>
        params?.row?.clinicalTreatmentAdditionalField?.normalFrozenEmbroysRemaining
    }
  ];

  const outcomeCategories = [
    "No Status",
    "Cycle Reset",
    "Cycle Canceled",
    "Not Pregnant",
    "Not Pregnant - Miscarried",
    "Not Pregnant - Biochemical",
    "Not Pregnant - Ectopic",
    "Not Pregnant - Freeze All",
    "Not Pregnant - No Transfer",
    "Not Pregnant - Still born",
    "Not Pregnant - Loss of Newborn",
    "Pregnant",
    "Miscarried"
  ];
  const clinicalDecisionList = [
    "Approved IVF",
    "Approved FET",
    "Approved IVF/FET",
    "Approved FET/Freeze All",
    "Pending",
    "Pending Termination",
    "Terminated"
  ];
  const forecastEventList = [
    "Authorization",
    "Cycle Start",
    "Retrieval",
    "Transfer",
    "Positive Beta"
  ];

  const accordionSx = { bgcolor: "#ededed", m: 2, flexGrow: 1 };
  const [values, setValues] = useState();
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isAuthOpen, setIsAuthOpen] = useState();
  const [openEmailDocument, setOpenEmailDocument] = useState(false);
  const [selectedList, setSelectedList] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [isErrorResponseModal, setIsErrorResponseModal] = useState(false);
  const [isOutcomeErrorModal, setOutcomeErrorModal] = useState(false);
  const [outcomeErrorMsg, setOutcomeErrorMsg] = useState("");
  const [outComeRowData, setOutcomeRowData] = useState([]);
  const [isOpenWarningOutcome, setIsOpenWarningOutcome] = useState(false);
  const [savedOutcome, setSavedOutcome] = useState();
  const [ctId, setCTId] = useState("");
  const [isOutcomeEmail, setIsOutcomeEmail] = useState(false);
  const [isOutcomeClose, setIsOutcomeClose] = useState(false);
  const [isMessageModalEmail, setIsMessageModalEmail] = useState(false);
  // const [validateTransfer, setIsValidateTransfer] = useState(false);
  // console.log("validateTransfer", validateTransfer);
  // const [gid, setGid] = useState(sessionStorage.getItem("gdmId"));
  // const [pid, setPid] = useState(sessionStorage.getItem("practiceId"));
  // const gdmList = useSelector((state) => state.globalDataModelReducer.globalData);
  // const gdmLoading = useSelector((state) => state.globalDataModelReducer.loading);
  const planList = useSelector((state) => state.treatmentPlanReducer.treatmentPlanList);
  // const [patientDetailsData] = useState(patientDetails);

  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [pageSizeOut, setPageSizeOut] = useState({
    pageSize: 100,
    page: 0
  });

  const [isOpenForExpDate, setIsOpenForExpDate] = useState(false);

  console.log("patientDetails:", patientDetails);

  const authData = clinicalList
    ? clinicalList[clinicalList?.length - 1]?.authDate
      ? clinicalList[clinicalList?.length - 1]
      : clinicalList[clinicalList?.length - 2]
    : [];
  const requestData = clinicalList
    ? clinicalList[clinicalList?.length - 1]?.authDate
      ? []
      : clinicalList[clinicalList?.length - 1]
    : [];

  let requestCycle =
    cycleList && cycleList[cycleList.length - 1]?.requestedCycle
      ? cycleList[cycleList.length - 1]
      : null;

  // const [commentText, setCommentText] = useState({});
  // const [isAcknowledge, setIsAcknowledge] = useState(false);
  // const [additionalField, setAdditionalField] = useState({});
  // const [submittedBy] = useState(localStorage.getItem("userProfile") || "");

  useEffect(() => {
    actions.getClinicalTreatmentOutcomes({ medcaseId: props?.medcaseId });
    actions.getGlobalData(props.practiceId);
    actions.getProgramCycle({ medcaseId: props?.medcaseId });
    // let payload = {
    //   practiceId: props.practiceId,
    //   personId: ""
    // };
    // actions.getPersonalData(payload);
    actions.getPatientDetailsHeader({ medcaseId: props?.medcaseId });
  }, []);
  useEffect(() => {
    actions.getClinicalTreatmentOutcomes({ medcaseId: props?.medcaseId });
    actions.getGlobalData(props.practiceId);
    actions.getProgramCycle({ medcaseId: props?.medcaseId });
    // let payload = {
    //   practiceId: props.practiceId,
    //   personId: ""
    // };
    // actions.getPersonalData(payload);
    actions.getPatientDetailsHeader({ medcaseId: props?.medcaseId });
  }, [editClinical]);
  useEffect(() => {
    if (clinicalList) {
      console.log("clinicalList: ", clinicalList);
      console.log("clinicalList?.gdmId:", clinicalList[0]?.gdmId);
      const gdm_id = clinicalList[0]?.gdmId;
      let payload = {
        practiceId: props.practiceId,
        gdmId: gdm_id
      };
      if (gdm_id) {
        actions.getTreatmentPlan(payload);
      }
    }
  }, [clinicalList]);
  useEffect(() => {
    if (clinicalList) {
      setValues(
        clinicalList.map((item) => {
          item.clinicalTreatmentAdditionalField.frozenEmbryo =
            item.clinicalTreatmentAdditionalField.frozenEmbryo == 0
              ? 0
              : item.clinicalTreatmentAdditionalField.frozenEmbryo;
          item.clinicalTreatmentAdditionalField.transferedEmbryo =
            item.clinicalTreatmentAdditionalField.transferedEmbryo == 0
              ? 0
              : item.clinicalTreatmentAdditionalField.transferedEmbryo;
          item.clinicalTreatmentAdditionalField.remainingEmbryo =
            item.clinicalTreatmentAdditionalField.remainingEmbryo == 0
              ? 0
              : item.clinicalTreatmentAdditionalField.remainingEmbryo;
          item.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining =
            item.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining == 0
              ? 0
              : item.clinicalTreatmentAdditionalField.normalFrozenEmbroysRemaining;
          item.clinicalTreatmentAdditionalField.noOfFrozonEgg =
            item.clinicalTreatmentAdditionalField.noOfFrozonEgg == 0
              ? 0
              : item.clinicalTreatmentAdditionalField.noOfFrozonEgg;

          item.cycleDetail = JSON.parse(item.cycleDetailStr);
          return item;
        })
      );
      setExpanded(clinicalList[clinicalList.length - 1]?.clinicalTreatmentId);
      setSelectedList(clinicalList[clinicalList.length - 1]?.clinicalTreatmentId);
      setOutcomeRowData(
        clinicalList.map((item, index) => {
          item.authDate = item.authDate && moment(item.authDate).format("L");
          item.outcomeDate = item.outcomeDate && moment(item.outcomeDate).format("L");
          item.requestedService = item.clinicalTreatmentAdditionalField.requestedService;
          item.id = index;
          return item;
        })
      );
    }
    // console.log("clinicalList:", clinicalList);
    // let comment = {};
    // clinicalList.forEach((item, index) => {
    //   comment[index] = item?.clinicalTreatmentAdditionalField?.comment;
    // });
    // console.log("comment:", comment);
    // setCommentText(comment);

    // if (
    //   clinicalList &&
    //   clinicalList[0] &&
    //   clinicalList[0].clinicalTreatmentAdditionalField &&
    //   clinicalList[0].clinicalTreatmentAdditionalField.comment
    // ) {
    //   setCommentText(clinicalList[0]?.clinicalTreatmentAdditionalField?.comment);
    // }
  }, [clinicalList]);
  useEffect(() => {
    if (errorResponse == "Can not Edit as next cycle is Authorised") {
      setIsErrorResponseModal(true);
    } else if (successMsg == "Saved Successfully" && !isMessageModal) {
      console.log("1", successMsg);
      setIsMessageModal(true);

      if (
        savedOutcome &&
        authData &&
        authData.outcomeCategory &&
        authData.outcomeCategory != savedOutcome
      ) {
        //setOpenEmailDocument(true);
        setIsOutcomeEmail(true);
        //sendEmail();
      }
    }
    if (successMsg == "Authorization has sent successfully" && !isMessageModal) {
      console.log("2", successMsg);
      setIsMessageModal(true);
      //setOpenEmailDocument(true);
      sendEmail();
    }
    if (errorResponse) {
      setIsOpenWarningOutcome(true);
    }
    if (errorMsg && !errorResponse && !isMessageModal) {
      setIsMessageModal(true);
    }
    actions.getClinicalTreatmentOutcomes({ medcaseId: props?.medcaseId });
    setValues((values) =>
      values?.map((ele) => {
        if (ele.clinicalTreatmentId === editClinical?.clinicalTreatmentId) {
          return {
            ...ele,
            editClinical
          };
        }
        return ele;
      })
    );
  }, [editClinical, errorMsg, sendAuth, errorResponse, successMsg]);

  const sendEmail = () => {
    let clinicalTreatmentNo =
      props.programCycle &&
      props.programCycle[props.programCycle.length - 1] &&
      props.programCycle[props.programCycle.length - 1].clinicalTreatmentNo;
    let authorizedTreatment =
      props.programCycle &&
      props.programCycle[props.programCycle.length - 1] &&
      props.programCycle[props.programCycle.length - 1].authorizedTreatment;
    //let isOutcomeEmail = false;
    let clinicalTreatmentId = ctId;

    actions.postEmailOption({
      medcaseId: isOutcomeEmail ? clinicalTreatmentId : props?.medcaseId,
      toAddresses: [],
      cycle: clinicalTreatmentNo,
      emailType: isOutcomeEmail
        ? "OUTCOME_SUBMITTED"
        : props.emailType
        ? props.emailType
        : "AUTHORIZATION",
      requestedService: authorizedTreatment
    });

    return "emailsend";
  };

  function valideAuthExpDateFromValues() {
    console.log("zzz values: ", values);

    const authExpDate = values.length > 0 ? values[0].authExpDate : "";

    if (authExpDate) {
      const currentDate = new Date();
      const expiryDate = new Date(authExpDate);
      const isExpired = expiryDate < currentDate;
      console.log("z Check", isExpired);
      return isExpired;
    }

    return false;
  }

  // function valideAuthExpDateFromClinicalData() {
  //   const authExpDate = clinicalList.length > 0 ? clinicalList[0].authExpDate : "";

  //   if (authExpDate) {
  //     const currentDate = new Date();
  //     const expiryDate = new Date(authExpDate);
  //     const isExpired = expiryDate < currentDate;
  //     console.log("z Check", isExpired);
  //     return isExpired;
  //   }

  //   return false;
  // }

  const handleChangeValues = (e, index) => {
    const { name, value } = e.target;
    let drDate;
    if (name == "treatmentFieldAdditionalPGT") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  pgt: e.target.checked
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionalIcsi") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  icsi: e.target.checked
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionalComment") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  comment: value
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionaleggFreezing") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  eggFreezing: e.target.checked
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionalnoOfFrozonEgg") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  noOfFrozonEgg: value
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionalfrozenEmbryo") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  frozenEmbryo: value
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionaltransferedEmbryo") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  transferedEmbryo: value
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionalremainingEmbryo") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  remainingEmbryo: value
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionalnormalFrozenEmbroysRemaining") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  normalFrozenEmbroysRemaining: value
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionalclinicalDecision") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  clinicalDecision: value
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionalRequestedServices") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  requestedService: e.target.value
                }
              }
            : row
        )
      );
    } else if (name == "performedServiceICSI") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                cycleDetail: {
                  ...row.cycleDetail,
                  icsi: e.target.checked
                }
              }
            : row
        )
      );
    } else if (name == "performedServicePGTA") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                cycleDetail: {
                  ...row.cycleDetail,
                  pgta: e.target.checked
                }
              }
            : row
        )
      );
    } else if (name == "performedServicePGTM") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                cycleDetail: {
                  ...row.cycleDetail,
                  pgtm: e.target.checked
                }
              }
            : row
        )
      );
    } else if (name == "performedServicePGTSR") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                cycleDetail: {
                  ...row.cycleDetail,
                  pgtsr: e.target.checked
                }
              }
            : row
        )
      );
    } else if (name == "outcomeCategory") {
      console.log("zzz", clinicalList);
      const prevOutcome = clinicalList[0].outcomeCategory
        ? clinicalList[0].outcomeCategory
        : outcomeCategories[0];
      if (valideAuthExpDateFromValues() && prevOutcome === "No Status") {
        setIsOpenForExpDate(true);
      } else {
        let isChange = true;
        if (
          value === "No Status" &&
          rowData &&
          rowData.outcome &&
          !rowData.outcome.toString().toLowerCase().includes("no status")
        ) {
          const nextCycles =
            cycleList && cycleList.length
              ? cycleList.filter((val) => {
                  return Number(val.clinicalCycleNo) > Number(rowData.clinicalCycleNo);
                })
              : [];
          isChange =
            nextCycles &&
            nextCycles[0] &&
            nextCycles[0].outcome &&
            nextCycles[0].outcome.toString().toLowerCase().includes("cycle reset")
              ? true
              : false;
        }

        if (isChange) {
          setValues((values) =>
            values.map((row, i) => (index == i ? { ...row, [name]: value } : row))
          );
          setValues((values) =>
            values.map((row, i) => (index == i ? { ...row, outcomeDescription: "" } : row))
          );
          if (value === "Pregnant") {
            let drDate_ = (values && values[0] && values[0].retrievalDate) || null;
            if (drDate_) {
              let newDate = new Date(drDate_);
              drDate = newDate.setDate(newDate.getDate() + 266);
              setEDCDate(drDate);
            } else {
              setValues((values) =>
                values.map((row, i) => (index == i ? { ...row, edcDate: null } : row))
              );
            }
          } else {
            setValues((values) =>
              values.map((row, i) => (index == i ? { ...row, edcDate: null } : row))
            );
          }
        } else {
          setOutcomeErrorMsg('Can not set "No Status" until new cycle have "Cycle Reset" status!');
          setOutcomeErrorModal(true);
        }
      }
    } else if (name == "treatmentFieldAdditionalRetievalCycle") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  retrievalCycle: e.target.value
                }
              }
            : row
        )
      );
    } else if (name == "treatmentFieldAdditionalFetCycle") {
      setValues((values) =>
        values.map((row, i) =>
          index == i
            ? {
                ...row,
                clinicalTreatmentAdditionalField: {
                  ...row.clinicalTreatmentAdditionalField,
                  fetCycle: e.target.value
                }
              }
            : row
        )
      );
    } else
      setValues((values) => values.map((row, i) => (index == i ? { ...row, [name]: value } : row)));
  };

  const setEDCDate = (edcDate) => {
    if (edcDate) {
      setValues((values) =>
        values.map((ele) =>
          ele.clinicalTreatmentId == selectedList
            ? {
                ...ele,
                edcDate: edcDate
              }
            : ele
        )
      );
    }
  };

  const populateRecordedDate = (e, row, type) => {
    if (e.target.checked) {
      let currentStage = false;

      values.map((ele) => {
        if (ele.clinicalTreatmentId === selectedList) {
          let ctStages = ele.clinicalTreatmentStages.map((el) => {
            if (!currentStage) {
              if (type == "completed") {
                el.stageCompleted = true;

                if (!el.dateRecorded) el.dateRecorded = new Date();
              }
            }

            // if (!currentStage && !el.dateRecorded) {
            //   el.dateRecorded = new Date();
            // }

            // if (!currentStage && type == "completed") {
            //   el.stageCompleted = true;
            // }
            if (el.stageId === row.stageId) {
              currentStage = true;
              if (!el.dateRecorded) {
                el.dateRecorded = new Date();
              }
              // return (el.stageCompleted = e.target.checked);
            }
            return el;
          });

          return {
            ...ele,
            clinicalTreatmentStages: ctStages
          };
        }
        return ele;
      });
    }
  };

  const handleStageCheckCompleted = (e, row) => {
    const newChecked = e.target.checked;
    let currentIndex = null;

    setValues((prevValue) =>
      prevValue.map((ele) => {
        if (ele.clinicalTreatmentId === selectedList) {
          let ctStages = ele.clinicalTreatmentStages.map((el, index) => {
            if (el.stageId === row.stageId) {
              el.stageCompleted = newChecked;
              el.dateRecorded = newChecked ? new Date() : null;
              currentIndex = index;
            }

            return el;
          });

          ctStages &&
            ctStages.length > 0 &&
            ctStages.forEach((el, index) => {
              if (currentIndex !== null && index < currentIndex) {
                el.stageCompleted = newChecked;
                el.dateRecorded = newChecked ? new Date() : null;
              }

              return el;
            });

          return {
            ...ele,
            clinicalTreatmentStages: ctStages
          };
        }
        return ele;
      })
    );
    handleValidateSaveOutcome(values, selectedList);
    handleEnableTransferDate(values, selectedList);
    // setIsValidateTransfer(handleEnableTransferDate(values, selectedList));
    populateRecordedDate(e, row, "completed");
  };

  const handleStageCheckCancelled = (e, row) => {
    setValues((prevValues) =>
      prevValues.map((ele) => {
        if (ele.clinicalTreatmentId === selectedList) {
          let currentCheckboxVal = e.target.checked;
          let currentIndex = null;

          let ctStages = ele.clinicalTreatmentStages.map((el, index) => {
            if (el.stageId === row.stageId) {
              el.stageCancelled = currentCheckboxVal;
              el.dateRecorded = currentCheckboxVal ? new Date() : null;
              currentIndex = index;
            }

            return el;
          });

          ctStages &&
            ctStages.length > 0 &&
            ctStages.forEach((el, index) => {
              if (currentIndex !== null && index < currentIndex) {
                el.disabled = currentCheckboxVal ? true : false;
                el.stageCompleted = currentCheckboxVal;
                el.dateRecorded = currentCheckboxVal ? new Date() : null;
              }

              return el;
            });

          return {
            ...ele,
            clinicalTreatmentStages: ctStages
          };
        }
        return ele;
      })
    );

    handleValidateSaveOutcome(values, selectedList);
    handleEnableTransferDate(values, selectedList);
    populateRecordedDate(e, row, "cancelled");
    //setEDCDate(edcDate);
  };

  const SaveAuthorization = (obj) => {
    const payload = {
      data: { authorizationDate: obj.authorizationDate },
      medcaseId: props?.medcaseId,
      clinicalTreatmentId: obj.clinicalTreatmentId
    };
    actions.saveAuthorization(payload);
    setIsAuthOpen(false);
  };
  const handleCloseModal = () => {
    props.close();
  };
  const closeSendAuth = () => {
    setIsAuthOpen(false);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    if (isOutcomeClose) {
      props.close();
    }
    actions.resetOutcomeList();
    actions.resetRes();
    //sendEmail();
  };
  const closeMessageModalEmail = () => {
    console.log("isOutcomeClose", isOutcomeClose);
    setIsMessageModalEmail(false);
    if (isOutcomeClose) {
      props.close();
    }
    // actions.resetOutcomeList();
    // actions.resetRes();
    //actions.resetMessage();
    actions.resetPostEmail();
    actions.resetEmail();
    // setIsOutcomeEmail(false);
    // setIsMessageModal(false);
    // setIsAuthOpen(false);
  };
  useEffect(() => {
    console.log("successMsgEmail", successMsgEmail);
    if (successMsgEmail && successMsgEmail !== "") {
      console.log("successMsgEmail1", successMsgEmail);
      setIsMessageModalEmail(true);
    }
  }, [successMsgEmail]);
  useEffect(() => {
    // console.log("errorEmail", errorEmail);
    if (errorEmail && errorEmail !== "") {
      //setIsWarningOpen(true);
    }
  }, [errorEmail]);
  const closeErrorResponseModal = () => {
    setIsErrorResponseModal(false);
    actions.resetOutcomeList();
    actions.resetRes();
  };
  const closeOutcomeErrorModal = () => {
    setOutcomeErrorModal(false);
  };
  const closeEmailDocument = () => {
    if (isOutcomeClose) {
      handleCloseModal();
    }
    setOpenEmailDocument(false);
    setIsOutcomeEmail(false);
    actions.resetOutcomeList();
  };
  const handleSendAuthorization = () => {
    // if (!(requestData && requestData.requestedService)) {
    if (!(requestCycle && requestCycle.requestedService)) {
      setOutcomeErrorMsg(
        "must complete Requested Cycle question prior to Sending an Authorization"
      );
      setOutcomeErrorModal(true);
    } else {
      setIsAuthOpen(true);
    }
    console.log(requestData, "requestDatarequestDatarequestDatarequestData");
  };

  const handleSaveCloseTreatment = (e, clinicalTreatmentId, isClose) => {
    // console.log("submit form", values);
    setIsOutcomeClose(isClose);
    let data = values.filter((item) => {
      return item.clinicalTreatmentId === clinicalTreatmentId;
    })[0];
    // data.commentText = commentText;
    let finalData = removeObjParameters(data);
    finalData.outcomeCategory =
      (finalData.outcomeCategory && finalData.outcomeCategory) || "No Status";
    setSavedOutcome(finalData.outcomeCategory);
    setCTId(clinicalTreatmentId);
    // const loggedInUser = localStorage.getItem("userProfile");
    // const userName = JSON.parse(loggedInUser);
    // finalData.user = (userName?.firstName || "") + " " + (userName?.lastName || "");
    console.log("finalData:", finalData);
    actions.editTreatmentPlanOutComeList({
      medcaseId: props?.medcaseId,
      clinicalTreatmentId: clinicalTreatmentId,
      data: finalData
    });

    actions.getProgramCycle({ medcaseId: props?.medcaseId });
  };

  const handleChangeAccordion = (id) => (event, isExpanded) => {
    setSelectedList(id);
    setExpanded(isExpanded ? id : false);
  };

  const handleCloseWarningOutcome = () => {
    setIsOpenWarningOutcome(false);
    actions.resetRes();
  };

  const disableFormData = (data) => {
    if (
      (data?.clinicalTreatmentAdditionalField?.clinicalDecision == "Approved" ||
        data?.clinicalTreatmentAdditionalField?.clinicalDecision == "Pending") &&
      data.clinicalTreatmentId != values[values.length - 1].clinicalTreatmentId
    ) {
      return true;
    } else {
      return false;
    }
  };

  const disableSendAuth = () => {
    let isDisable = false;

    if (requestData?.clinicalTreatmentNo && requestData?.requestedDate) {
      isDisable = false;
    }

    if (
      authData &&
      (!authData.outcomeCategory ||
        authData.outcomeCategory.toString().toLowerCase().includes("no status") ||
        authData.outcomeCategory.toString().toLowerCase() === "pregnant")
    ) {
      isDisable = true;
    }

    if (
      authData &&
      authData.clinicalTreatmentAdditionalField &&
      authData.clinicalTreatmentAdditionalField.clinicalDecision &&
      ["Pending", "Pending Termination", "Terminated"].indexOf(
        authData.clinicalTreatmentAdditionalField.clinicalDecision
      ) > -1
    ) {
      isDisable = true;
    }

    if (
      authData &&
      authData.clinicalTreatmentAdditionalField &&
      authData.clinicalTreatmentAdditionalField.clinicalDecision === null
    ) {
      isDisable = true;
    }

    return isDisable;
  };

  const textareaStyle = {
    width: "100%",
    height: "180px",
    padding: "10px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    backgroundColor: "background.paper",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "&:focus": {
      outline: "none",
      borderColor: "primary.main"
    },
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)"
    },
    outline: "none"
  };

  console.log("===>isOpenWarningOutcome", isOpenWarningOutcome);

  console.log("===>isOutcomeErrorModal", isOutcomeErrorModal);

  console.log("===>openEmailDocument", openEmailDocument);
  console.log("===>isAuthOpen", isAuthOpen);
  console.log("===>isErrorResponseModal", isErrorResponseModal);
  console.log("===>isMessageModalEmail", isMessageModalEmail);
  console.log("===>isMessageModal", isMessageModal);
  console.log("===>successMsgEmail", successMsgEmail);

  const isResetCycle = (treatmentNo) => {
    return treatmentNo && String(treatmentNo) === "0.0";
    // ele.clinicalTreatmentNo
  };

  const [isSaveDisabled, setIsSaveDisabled] = useState(false);

  useEffect(() => {
    if (values) {
      values.forEach((value) => {
        if (!value?.cycleStartDate || !value?.retrievalDate) {
          console.log("check zhr", !value?.cycleStartDate || !value?.retrievalDate);
          setIsSaveDisabled(true);
        } else {
          setIsSaveDisabled(false);
        }
      });
    }
  }, [values]);

  console.log("isSaveDisabled zhr", isSaveDisabled);

  return (
    <Box>
      <Modal open={props.isOpenTrack} backdrop="static" aria-labelledby="modal-add-title">
        <Box
          className="modal-style"
          sx={{ width: "80%", maxHeight: "85%" /* 700 */, overflowY: "auto" }}>
          <Stack direction="row" className="modal-stack">
            <Typography variant="h2">Cycle Outcome</Typography>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          {/* Patient Details */}
          <Box className="pad-5">
            <Grid container item xs={12} spacing={2} className="m-t-5">
              <Grid item xs={9}>
                <Typography>Patient Details</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} className="m-t-0" marginBottom="15px">
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>Patient Name</InputLabel>
                  <Typography>
                    {patientDetails?.patientFirstName
                      ? patientDetails?.patientFirstName + " " + patientDetails.patientLastName
                      : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>Person No</InputLabel>
                  <Typography>
                    {patientDetails?.personKey ? patientDetails.personKey : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>Medcase ID</InputLabel>
                  <Typography>
                    {patientDetails?.medcaseId ? patientDetails.medcaseId : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>DOB</InputLabel>
                  <Typography>{patientDetails.patientDob}</Typography>
                </Stack>
              </Grid>
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>Practice</InputLabel>
                  <Typography>
                    {patientDetails?.practiceId ? patientDetails.practiceId : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>Location</InputLabel>
                  <Typography>
                    {patientDetails?.locationName ? patientDetails.locationName : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>Physician</InputLabel>
                  <Typography>
                    {patientDetails?.physicianFirstName && patientDetails?.physicianLastName
                      ? patientDetails?.physicianFirstName + " " + patientDetails?.physicianLastName
                      : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>Location Contact</InputLabel>
                  <Typography>
                    {patientDetails?.locationContactFname && patientDetails?.locationContactLname
                      ? patientDetails.locationContactFname +
                        " " +
                        patientDetails.locationContactLname
                      : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>Enrolled Program</InputLabel>
                  <Typography>
                    {clinicalList && clinicalList[0] && clinicalList[0]?.program
                      ? clinicalList[0].program
                      : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12 / 5}>
                <Stack direction="column">
                  <InputLabel>Enrolled Treatment Plan</InputLabel>

                  <Typography>
                    {clinicalList?.[0]?.authorizedTpNameCustom ??
                      clinicalList?.[0]?.authorizedTpName ??
                      "--"}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Box className="pad-5">
            <Grid container item xs={12} spacing={2} className="m-t-5">
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "16px" }}>Authorization Details</Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} className="m-t-0">
              <Grid item xs={3}>
                <Stack direction="column">
                  <InputLabel>Current Authorized Cycle</InputLabel>
                  <Typography>
                    {authData?.clinicalTreatmentNo}{" "}
                    {authData?.clinicalTreatmentNo &&
                    authData?.clinicalTreatmentNo?.split(".")[1] > 0
                      ? "(FET)"
                      : "(IVF)"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="column">
                  <InputLabel>Authorized Date</InputLabel>
                  <Typography>
                    {authData?.authDate ? moment(authData?.authDate).format("L") : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="column">
                  <InputLabel>Authorization Status</InputLabel>
                  <Typography>{authData?.authDate ? "Authorized" : "Not Authorized"}</Typography>
                </Stack>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} className="m-t-5">
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "16px" }}>Requested Details </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} className="m-t-0">
              <Grid item xs={3}>
                <Stack direction="column">
                  <InputLabel>Requested Cycle</InputLabel>
                  <Typography>
                    {requestData?.clinicalTreatmentNo}{" "}
                    {requestData?.clinicalTreatmentNo
                      ? requestData?.clinicalTreatmentNo?.split(".")[1] > 0
                        ? "(FET)"
                        : "(IVF)"
                      : requestCycle?.requestedCycle
                      ? `${requestCycle?.requestedCycle} (${requestCycle?.requestedService})`
                      : "--"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="column">
                  <InputLabel>Requested Date</InputLabel>
                  <Typography>
                    {requestData?.requestedDate
                      ? moment(requestData?.requestedDate).format("L")
                      : requestCycle?.requestedCycle
                      ? moment(requestCycle?.requestedDate).format("L")
                      : "--"}
                  </Typography>
                </Stack>
              </Grid>
              {/* {console.log("requestData", requestData)} */}
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  onClick={handleSendAuthorization}
                  disabled={disableSendAuth()}>
                  Send Authorization
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box className="pad-5 m-t-10">
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={9}>
                <Typography sx={{ fontSize: "16px" }}> Authorized Cycle </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={2} className="m-t-5">
              {console.log("values==>", values)}
              {values?.length > 0 &&
                values.map((ele, index) => (
                  <>
                    {ele.authDate !== null ? (
                      <Accordion
                        expanded={
                          (values?.length === index + 1 && expanded == ele.clinicalTreatmentId) ||
                          expanded == ele.clinicalTreatmentId
                        }
                        onChange={handleChangeAccordion(ele.clinicalTreatmentId)}
                        sx={accordionSx}
                        key={index}>
                        <AccordionSummary
                          sx={{ flexDirection: "row-reverse", flexGrow: 0 }}
                          expandIcon={<ExpandMoreIcon />}>
                          <Typography>
                            {ele.clinicalTreatmentNo}{" "}
                            {ele.clinicalTreatmentNo && ele.clinicalTreatmentNo.split(".")[1] > 0
                              ? "(FET)"
                              : "(IVF)"}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box className="bg-white pad-10">
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={9}>
                                <Typography sx={{ fontSize: "16px" }}>Cycle Information</Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2} className="m-t-2">
                              <Grid item xs={3}>
                                <Stack direction="column">
                                  <InputLabel>Authorized Treatment Plan</InputLabel>
                                  <Typography>{ele?.authorizedTpName}</Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={3}>
                                <Stack direction="column">
                                  <InputLabel>
                                    Performed Treatment Plan{" "}
                                    {(ele.outcomeCategory == "Pregnant" ||
                                      ele.outcomeCategory == "Not Pregnant" ||
                                      ele.outcomeCategory == "Miscarried" ||
                                      ele.outcomeCategory == "Cycle Canceled" ||
                                      ele.outcomeCategory == "Not Pregnant - Miscarried" ||
                                      ele.outcomeCategory == "Not Pregnant - Biochemical" ||
                                      ele.outcomeCategory == "Not Pregnant - Ectopic" ||
                                      ele.outcomeCategory == "Not Pregnant - Freeze All" ||
                                      ele.outcomeCategory == "Not Pregnant - No Transfer" ||
                                      ele.outcomeCategory == "Not Pregnant - Still born" ||
                                      ele.outcomeCategory == "Not Pregnant - Loss of Newborn") &&
                                      "**"}
                                  </InputLabel>
                                  <Select
                                    name="planList"
                                    value={ele?.performedTp}
                                    className="patient-dropdown"
                                    disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                    onChange={(newTp) => {
                                      setValues((values) =>
                                        values.map((row, i) =>
                                          index == i
                                            ? {
                                                ...row,
                                                performedTp: newTp.target.value
                                              }
                                            : row
                                        )
                                      );
                                    }}>
                                    {planList?.map((item, index) => {
                                      return (
                                        <MenuItem value={item.treatmentPlanId} key={index}>
                                          {item.treatmentPlanName}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </Stack>
                              </Grid>
                              <Grid item xs={3}>
                                <Stack direction="column">
                                  <InputLabel>Program</InputLabel>
                                  <Typography>{ele?.program}</Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={3}>
                                <Stack direction="column">
                                  <InputLabel>Enrollment Date</InputLabel>
                                  <Typography>
                                    {ele?.enrollmentDate
                                      ? moment(ele?.enrollmentDate).format("L")
                                      : "--"}
                                  </Typography>
                                </Stack>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={2} className="m-t-2">
                              <Grid item xs={3}>
                                <Stack direction="column">
                                  <InputLabel>Authorization Date</InputLabel>
                                  <Typography>
                                    {ele?.authDate ? moment(ele?.authDate).format("L") : "--"}
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={3}>
                                <Stack direction="column">
                                  <InputLabel>Auth exp Date</InputLabel>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      name="authExpDate"
                                      value={ele?.authExpDate}
                                      // disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  authExpDate: newValue
                                                }
                                              : row
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  {/* <Typography>
                                    {ele?.authExpDate ? moment(ele?.authExpDate).format("L") : "--"}
                                  </Typography> */}
                                </Stack>
                              </Grid>
                              <Grid item xs={3}>
                                <Stack direction="column">
                                  <InputLabel>Forecast Date</InputLabel>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      name="forecastDate"
                                      value={ele?.forecastDate}
                                      disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  forecastDate: newValue
                                                }
                                              : row
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Stack>
                              </Grid>
                              <Grid item xs={3}>
                                <Stack direction="column">
                                  <InputLabel>Forecast Event</InputLabel>
                                  <Select
                                    name="forecastEvent"
                                    value={ele?.forecastEvent}
                                    disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                    className="patient-dropdown"
                                    onChange={(newTp) => {
                                      setValues((values) =>
                                        values.map((row, i) =>
                                          index == i
                                            ? {
                                                ...row,
                                                forecastEvent: newTp.target.value
                                              }
                                            : row
                                        )
                                      );
                                    }}>
                                    {forecastEventList?.map((item, index) => {
                                      return (
                                        <MenuItem value={item} key={"FE-" + index}>
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </Stack>
                              </Grid>
                            </Grid>
                            <Stack direction="row" className="modal-stack">
                              <Typography sx={{ fontSize: "16px", marginBottom: "5px" }}>
                                Outcome*&nbsp;
                                {/* {ele.outcomeCategory && (
                                  <span>
                                    {ele.outcomeCategory +
                                      (ele.outcomeDescription ? "-" + ele.outcomeDescription : "")}
                                  </span>
                                )}{" "} */}
                              </Typography>
                            </Stack>
                            <Divider className="modal-divider" />
                            <Box>
                              <FormControl>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  spacing={2}
                                  style={{ paddingTop: "8px" }}>
                                  {/* <Grid item xs={3}>
                                    <Typography>Category</Typography>
                                  </Grid> */}
                                  <Grid item xs={8}>
                                    <Select
                                      name="outcomeCategory"
                                      sx={{ flex: 6, height: 35, width: 350 }}
                                      value={ele.outcomeCategory || outcomeCategories[0]}
                                      disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                      onChange={(e) => handleChangeValues(e, index)}>
                                      {outcomeCategories.map((category, index) => {
                                        return (
                                          <MenuItem value={category} key={"cat" + index}>
                                            {category}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </Grid>
                                </Grid>
                              </FormControl>
                              <Divider className="modal-divider m-t-10" /* marginTop="15px" */ />
                              {ele.outcomeCategory === "Cycle Canceled" ? (
                                <>
                                  {ele?.clinicalCycleType == "IVF" ? (
                                    <Grid container item xs={12} className="item-center">
                                      <Grid item xs={2}>
                                        <Typography>Retrieval Cycle:</Typography>
                                      </Grid>
                                      <RadioGroup
                                        row
                                        name="treatmentFieldAdditionalRetievalCycle"
                                        value={
                                          ele?.clinicalTreatmentAdditionalField?.retrievalCycle
                                        }
                                        onClick={(e) => handleChangeValues(e, index)}>
                                        <FormControlLabel
                                          value="Patient Paid for Monitoring/IUI"
                                          control={<Radio />}
                                          label="Patient Paid for Monitoring/IUI"
                                        />
                                        <FormControlLabel
                                          value="Fertility Access to pay for Monitoring/IUI*"
                                          control={<Radio />}
                                          label="Fertility Access to pay for Monitoring/IUI*"
                                        />
                                        <FormControlLabel
                                          value="Cycle never started"
                                          control={<Radio />}
                                          label="Cycle never started"
                                        />
                                      </RadioGroup>
                                    </Grid>
                                  ) : null}

                                  {ele?.clinicalCycleType == "FET" ? (
                                    <Grid container item xs={12} className="item-center">
                                      <Grid item xs={2}>
                                        <Typography>FET Cycle:</Typography>
                                      </Grid>

                                      <RadioGroup
                                        row
                                        name="treatmentFieldAdditionalFetCycle"
                                        value={ele?.clinicalTreatmentAdditionalField?.fetCycle}
                                        onClick={(e) => handleChangeValues(e, index)}>
                                        <FormControlLabel
                                          value="Services Performed"
                                          control={<Radio />}
                                          label="Services Performed"
                                        />
                                        <FormControlLabel
                                          value="No Services Performed"
                                          control={<Radio />}
                                          label="No Services Performed"
                                        />
                                        <FormControlLabel
                                          value="Cycle never started"
                                          control={<Radio />}
                                          label="Cycle never started"
                                        />
                                      </RadioGroup>
                                    </Grid>
                                  ) : null}

                                  {ele?.clinicalCycleType == "IVF" ? (
                                    <Grid container item xs={12}>
                                      <Typography>
                                        *If Fertility Access is paying for the services, this will
                                        count as a retrieval cycle in the Program.
                                      </Typography>
                                    </Grid>
                                  ) : null}
                                  <Divider className="modal-divider m-t-10" />
                                </>
                              ) : null}
                              <Grid container item xs={12} className="item-center">
                                <Grid item xs={2}>
                                  <Typography>Additional Service</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="treatmentFieldAdditionalPGT"
                                        onClick={(e) => handleChangeValues(e, index)}
                                        checked={ele?.clinicalTreatmentAdditionalField?.pgt}
                                        disabled={
                                          disableFormData(ele) ||
                                          isResetCycle(ele.clinicalTreatmentNo)
                                        }
                                      />
                                    }
                                    label="PGT"
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="treatmentFieldAdditionalIcsi"
                                        onClick={(e) => handleChangeValues(e, index)}
                                        checked={ele?.clinicalTreatmentAdditionalField?.icsi}
                                        disabled={
                                          disableFormData(ele) ||
                                          isResetCycle(ele.clinicalTreatmentNo)
                                        }
                                      />
                                    }
                                    label="ICSI"
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                            <Box className="m-t-10">
                              <Grid container item xs={12} spacing={2} className="item-center">
                                <Grid item xs={3}>
                                  <Typography>
                                    Outcome Date
                                    {ele.outcomeCategory &&
                                      ele.outcomeCategory !== "No Status" &&
                                      "**"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      name="outcomeDate"
                                      // disabled={disableFormData(ele)}
                                      value={ele?.outcomeDate}
                                      disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  outcomeDate: newValue
                                                }
                                              : row
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography>
                                    EDC Date {ele.outcomeCategory == "Pregnant" && "**"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      name="edcDate"
                                      value={ele?.edcDate}
                                      disabled={
                                        ele.outcomeCategory == "Not Pregnant - Miscarried" ||
                                        isResetCycle(ele.clinicalTreatmentNo) /* ||
                                        disableFormData(ele) */
                                      }
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  edcDate: newValue
                                                }
                                              : row
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography>
                                    Miscarriage Date {ele.outcomeCategory == "Miscarried" && "**"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      name="miscarrigeDate"
                                      value={ele?.miscarrigeDate}
                                      disabled={
                                        !(ele.outcomeCategory == "Miscarried") ||
                                        isResetCycle(ele.clinicalTreatmentNo) /* ||
                                        disableFormData(ele) */
                                      }
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  miscarrigeDate: newValue
                                                }
                                              : row
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Grid>
                            </Box>
                            <Stack direction="row" className="modal-stack">
                              <Typography sx={{ fontSize: "16px" }}>Stages Performed*</Typography>
                            </Stack>
                            <Box
                              className={
                                disableFormData(ele) ? "grid-style2 disable-section" : "grid-style2"
                              }>
                              <DataGridPremiumComp
                                autoHeight={true}
                                columns={stagesColumn}
                                rows={ele?.clinicalTreatmentStages}
                                rowCount={ele?.clinicalTreatmentStages?.length}
                                getRowId={(row) => row.stageId + row.stageName + index}
                                pageSizeOptions={[10, 25, 50, 100]}
                                pageSize={pageSize.pageSize}
                                paginationModel={pageSize}
                                onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
                                pagination
                              />
                            </Box>

                            {/* Comment */}
                            <Stack direction="row" className="modal-stack m-t-15">
                              <Typography sx={{ fontSize: "16px", marginBottom: "5px" }}>
                                Comment
                              </Typography>
                            </Stack>
                            <Divider className="modal-divider" />
                            <Box sx={{ mt: 2, p: 2 }}>
                              <textarea
                                value={ele?.clinicalTreatmentAdditionalField?.comment || ""}
                                onChange={(e) => {
                                  setValues((values) =>
                                    values.map((row, i) =>
                                      index == i
                                        ? {
                                            ...row,
                                            clinicalTreatmentAdditionalField: {
                                              ...row.clinicalTreatmentAdditionalField,
                                              comment: e.target.value
                                            }
                                          }
                                        : row
                                    )
                                  );
                                }}
                                disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                style={textareaStyle}
                              />
                            </Box>

                            <Stack direction="row" className="modal-stack m-t-15">
                              <Typography sx={{ fontSize: "16px", marginBottom: "5px" }}>
                                Cycle Details
                              </Typography>
                            </Stack>
                            <Divider className="modal-divider" />
                            <Box className="m-t-5">
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                className="item-center m-t-5">
                                <Grid item xs={2}>
                                  <Typography>
                                    Cycle Start Date
                                    {(ele.outcomeCategory == "Pregnant" ||
                                      ele.outcomeCategory == "Not Pregnant" ||
                                      ele.outcomeCategory == "Miscarried" ||
                                      ele.outcomeCategory == "Cycle Canceled" ||
                                      ele.outcomeCategory == "Not Pregnant - Miscarried" ||
                                      ele.outcomeCategory == "Not Pregnant - Biochemical" ||
                                      ele.outcomeCategory == "Not Pregnant - Ectopic" ||
                                      ele.outcomeCategory == "Not Pregnant - Freeze All" ||
                                      ele.outcomeCategory == "Not Pregnant - No Transfer" ||
                                      ele.outcomeCategory == "Not Pregnant - Still born" ||
                                      ele.outcomeCategory == "Not Pregnant - Loss of Newborn") &&
                                      "**"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      name="cycleStartDate"
                                      disabled={disableFormData(ele)}
                                      value={ele?.cycleStartDate}
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  cycleStartDate: newValue
                                                }
                                              : row
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>

                                <Grid item xs={2}>
                                  <Typography>
                                    Retrieval Date
                                    {(ele.outcomeCategory == "Pregnant" ||
                                      ele.outcomeCategory == "Not Pregnant" ||
                                      ele.outcomeCategory == "Miscarried" ||
                                      ele.outcomeCategory == "Not Pregnant - Miscarried" ||
                                      ele.outcomeCategory == "Not Pregnant - Biochemical" ||
                                      ele.outcomeCategory == "Not Pregnant - Ectopic" ||
                                      ele.outcomeCategory == "Not Pregnant - Freeze All" ||
                                      ele.outcomeCategory == "Not Pregnant - No Transfer" ||
                                      ele.outcomeCategory == "Not Pregnant - Still born" ||
                                      ele.outcomeCategory == "Not Pregnant - Loss of Newborn") &&
                                      ele?.authorizedTpName != "FET" &&
                                      "**"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      name="retrievalDate"
                                      disabled={
                                        disableFormData(ele) ||
                                        isResetCycle(ele.clinicalTreatmentNo)
                                      }
                                      value={ele?.retrievalDate}
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  retrievalDate: newValue
                                                }
                                              : row
                                          )
                                        );

                                        if (ele.outcomeCategory == "Pregnant") {
                                          let edcDate_ = null;
                                          if (newValue) {
                                            let newDate_ = new Date(newValue);
                                            edcDate_ = newDate_.setDate(newDate_.getDate() + 266);
                                          }
                                          setValues((values) =>
                                            values.map((row, i) =>
                                              index == i
                                                ? {
                                                    ...row,
                                                    edcDate: edcDate_
                                                  }
                                                : row
                                            )
                                          );
                                          //edcDate
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                className="item-center m-t-10">
                                <Grid item xs={2}>
                                  <Typography>
                                    Transfer Date
                                    {(ele.outcomeCategory == "Pregnant" ||
                                      ele.outcomeCategory == "Not Pregnant" ||
                                      ele.outcomeCategory == "Miscarried" ||
                                      ele.outcomeCategory == "Not Pregnant - Miscarried" ||
                                      ele.outcomeCategory == "Not Pregnant - Biochemical" ||
                                      ele.outcomeCategory == "Not Pregnant - Ectopic" ||
                                      ele.outcomeCategory == "Not Pregnant - Still born" ||
                                      ele.outcomeCategory == "Not Pregnant - Loss of Newborn") &&
                                      "**"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        openTo="day"
                                        name="transferDate"
                                        value={ele?.clinicalTreatmentAdditionalField?.transferDate}
                                        disabled={validateTransfer}
                                        onChange={(newValue) => {
                                          setValues((values) =>
                                            values.map((row, i) =>
                                              index == i
                                                ? {
                                                    ...row,
                                                    clinicalTreatmentAdditionalField: {
                                                      ...row.clinicalTreatmentAdditionalField,
                                                      transferDate: newValue
                                                    }
                                                  }
                                                : row
                                            )
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} size="small" sx={{ flex: 8 }} />
                                        )}
                                      />
                                    </LocalizationProvider> */}
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      name="transferDate"
                                      disabled={
                                        disableFormData(ele) ||
                                        isResetCycle(ele.clinicalTreatmentNo)
                                      }
                                      value={ele?.transferDate}
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  transferDate: newValue
                                                }
                                              : row
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>

                                {/* <Grid item xs={2}>
                                  <Typography>
                                    Miscarried Date{ele.outcomeCategory == "Miscarried" && "**"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      name="cycleMiscarriedDate"
                                      disabled={disableFormData(ele)}
                                      value={ele?.cycleMiscarriedDate}
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  cycleMiscarriedDate: newValue
                                                }
                                              : row
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid> */}
                              </Grid>

                              <Grid
                                container
                                item
                                xs={12}
                                className="item-center"
                                style={{ marginTop: "10px" }}>
                                <Grid item xs={2}>
                                  <Typography>Performed Service</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performedServiceICSI"
                                        onClick={(e) => handleChangeValues(e, index)}
                                        checked={ele?.cycleDetail?.icsi ? true : false}
                                        disabled={
                                          disableFormData(ele) ||
                                          isResetCycle(ele.clinicalTreatmentNo)
                                        }
                                      />
                                    }
                                    label="ICSI"
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performedServicePGTA"
                                        onClick={(e) => handleChangeValues(e, index)}
                                        checked={ele?.cycleDetail?.pgta}
                                        disabled={
                                          disableFormData(ele) ||
                                          isResetCycle(ele.clinicalTreatmentNo)
                                        }
                                      />
                                    }
                                    label="PGT-A"
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performedServicePGTM"
                                        onClick={(e) => handleChangeValues(e, index)}
                                        checked={ele?.cycleDetail?.pgtm}
                                        disabled={
                                          disableFormData(ele) ||
                                          isResetCycle(ele.clinicalTreatmentNo)
                                        }
                                      />
                                    }
                                    label="PGT-M"
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="performedServicePGTSR"
                                        onClick={(e) => handleChangeValues(e, index)}
                                        checked={ele?.cycleDetail?.pgtsr}
                                        disabled={
                                          disableFormData(ele) ||
                                          isResetCycle(ele.clinicalTreatmentNo)
                                        }
                                      />
                                    }
                                    label="PGT-SR"
                                  />
                                </Grid>
                              </Grid>

                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                className="item-center"
                                style={{ marginTop: "5px" }}>
                                <Grid item xs={3}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="treatmentFieldAdditionaleggFreezing"
                                        checked={ele?.clinicalTreatmentAdditionalField?.eggFreezing}
                                        onClick={(e) => handleChangeValues(e, index)}
                                        disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                      />
                                    }
                                    label="Egg Freezing"
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography>Number of Frozen Eggs</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField
                                    name="treatmentFieldAdditionalnoOfFrozonEgg"
                                    placeholder="Number of Frozen Eggs"
                                    disabled={
                                      ele?.clinicalTreatmentAdditionalField?.eggFreezing == false ||
                                      isResetCycle(ele.clinicalTreatmentNo)
                                    }
                                    value={ele?.clinicalTreatmentAdditionalField?.noOfFrozonEgg}
                                    onChange={(e) => handleChangeValues(e, index)}
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                            <Box>
                              <Grid container item xs={12} spacing={2} style={{ marginTop: "5px" }}>
                                <Grid item xs={6}>
                                  <Stack direction="column">
                                    <InputLabel>
                                      Embryos Transferred
                                      {(ele.outcomeCategory == "Pregnant" ||
                                        ele.outcomeCategory == "Not Pregnant" ||
                                        ele.outcomeCategory == "Miscarried" ||
                                        ele.outcomeCategory == "Not Pregnant - Miscarried" ||
                                        ele.outcomeCategory == "Not Pregnant - Biochemical" ||
                                        ele.outcomeCategory == "Not Pregnant - Ectopic" ||
                                        ele.outcomeCategory == "Not Pregnant - Still born" ||
                                        ele.outcomeCategory == "Not Pregnant - Loss of Newborn") &&
                                        "**"}
                                    </InputLabel>
                                    <TextField
                                      name="treatmentFieldAdditionaltransferedEmbryo"
                                      // placeholder="Transffered"
                                      value={
                                        ele?.clinicalTreatmentAdditionalField?.transferedEmbryo
                                      }
                                      //disabled={validateTransfer}
                                      onChange={(e) => handleChangeValues(e, index)}
                                      disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                    />
                                    {/* <InputLabel required>Transfer Date </InputLabel>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                      <DatePicker
                                        openTo="day"
                                        name="transferDate"
                                        value={ele?.clinicalTreatmentAdditionalField?.transferDate}
                                        disabled={validateTransfer}
                                        onChange={(newValue) => {
                                          setValues((values) =>
                                            values.map((row, i) =>
                                              index == i
                                                ? {
                                                    ...row,
                                                    clinicalTreatmentAdditionalField: {
                                                      ...row.clinicalTreatmentAdditionalField,
                                                      transferDate: newValue
                                                    }
                                                  }
                                                : row
                                            )
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} size="small" sx={{ flex: 8 }} />
                                        )}
                                      />
                                    </LocalizationProvider> */}
                                  </Stack>
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={12}
                                spacing={2}
                                style={{ marginTop: "10px" }}>
                                <Grid item xs={4}>
                                  <Stack direction="column">
                                    <InputLabel>
                                      Embryos Frozen
                                      {(ele.outcomeCategory == "Pregnant" ||
                                        ele.outcomeCategory == "Not Pregnant" ||
                                        ele.outcomeCategory == "Miscarried" ||
                                        ele.outcomeCategory == "Not Pregnant - Miscarried" ||
                                        ele.outcomeCategory == "Not Pregnant - Biochemical" ||
                                        ele.outcomeCategory == "Not Pregnant - Ectopic" ||
                                        ele.outcomeCategory == "Not Pregnant - Still born" ||
                                        ele.outcomeCategory == "Not Pregnant - Loss of Newborn" ||
                                        ele.outcomeCategory == "Not Pregnant - Freeze All" ||
                                        ele.outcomeCategory == "Not Pregnant - No Transfer") &&
                                        "**"}
                                    </InputLabel>
                                    {console.log("==>", ele?.clinicalTreatmentAdditionalField)}
                                    <TextField
                                      name="treatmentFieldAdditionalfrozenEmbryo"
                                      value={ele?.clinicalTreatmentAdditionalField?.frozenEmbryo}
                                      //disabled={validateTransfer}
                                      onChange={(e) => handleChangeValues(e, index)}
                                      disabled={
                                        isResetCycle(ele.clinicalTreatmentNo) ||
                                        isResetCycle(ele.clinicalTreatmentNo)
                                      }
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                  <Stack direction="column">
                                    <InputLabel>
                                      Frozen Embryos Remaining
                                      {ele.outcomeCategory &&
                                        ele.outcomeCategory !== "No Status" &&
                                        "**"}
                                    </InputLabel>
                                    <TextField
                                      name="treatmentFieldAdditionalremainingEmbryo"
                                      value={ele?.clinicalTreatmentAdditionalField?.remainingEmbryo}
                                      // disabled={validateTransfer}
                                      onChange={(e) => handleChangeValues(e, index)}
                                      disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                  <Stack direction="column">
                                    <InputLabel>
                                      Normal Frozen Embryos Remaining
                                      {ele.outcomeCategory &&
                                        ele.outcomeCategory !== "No Status" &&
                                        "**"}
                                    </InputLabel>
                                    <TextField
                                      name="treatmentFieldAdditionalnormalFrozenEmbroysRemaining"
                                      value={
                                        ele?.clinicalTreatmentAdditionalField
                                          ?.normalFrozenEmbroysRemaining
                                      }
                                      //disabled={validateTransfer}
                                      onChange={(e) => handleChangeValues(e, index)}
                                      disabled={isResetCycle(ele.clinicalTreatmentNo)}
                                    />
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Box>
                            <Divider className="modal-divider" style={{ marginTop: "10px" }} />
                            <Box className="m-t-20">
                              <Grid container item xs={12} className="item-center">
                                <Grid item xs={2}>
                                  <Typography>Requested Services </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <FormControl>
                                    <RadioGroup
                                      row
                                      name="treatmentFieldAdditionalRequestedServices"
                                      value={
                                        ele?.clinicalTreatmentAdditionalField?.requestedService
                                      }
                                      onChange={(e) => handleChangeValues(e, index)}>
                                      <FormControlLabel
                                        value="Retrieval"
                                        control={<Radio />}
                                        label="Retrieval"
                                        disabled={ele?.clinicalTreatmentNo > ele?.ivfCycleNo}
                                      />
                                      <FormControlLabel
                                        value="FET"
                                        control={<Radio />}
                                        label="FET"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>Estimated Cycle Start Date </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      openTo="day"
                                      views={["day"]}
                                      name="cycleStartDay"
                                      value={ele?.clinicalTreatmentAdditionalField?.cycleStartDay}
                                      onChange={(newValue) => {
                                        setValues((values) =>
                                          values.map((row, i) =>
                                            index == i
                                              ? {
                                                  ...row,
                                                  clinicalTreatmentAdditionalField: {
                                                    ...row.clinicalTreatmentAdditionalField,
                                                    cycleStartDay: newValue
                                                  }
                                                }
                                              : row
                                          )
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} size="small" sx={{ flex: 8 }} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Grid>
                            </Box>
                            <Stack
                              direction="row"
                              className="modal-stack"
                              style={{ marginTop: "15px" }}>
                              <Typography sx={{ fontSize: "16px" }}>Clinical Decision</Typography>
                            </Stack>
                            <Divider className="modal-divider" />
                            <Box className="m-t-20">
                              <Grid container item xs={12} className="item-center">
                                <Grid item xs={2}>
                                  <Typography
                                    style={{
                                      color: ele?.isClinicalReview == true ? "red" : "black"
                                    }}>
                                    Clinical Review Required
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Checkbox
                                    checked={ele?.isClinicalReview}
                                    disabled={true}
                                    style={{
                                      color: ele?.isClinicalReview == true ? "red" : "black"
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography>Clinical Decision </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Select
                                    name="treatmentFieldAdditionalclinicalDecision"
                                    value={ele.clinicalTreatmentAdditionalField?.clinicalDecision}
                                    sx={{ flex: 6, height: 35, width: 250 }}
                                    onChange={(e) => handleChangeValues(e, index)}
                                    disabled={
                                      !ele.outcomeCategory ||
                                      ele.outcomeCategory
                                        .toString()
                                        .toLowerCase()
                                        .includes("no status")
                                    }>
                                    {clinicalDecisionList.map((cdItem, index) => {
                                      return (
                                        <MenuItem value={cdItem} key={"cdi" + index}>
                                          {cdItem}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </Grid>
                                <Grid item xs={1}>
                                  <Typography>Submitted By </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <TextField name="submittedBy" value={ele?.requestedBy} disabled />
                                </Grid>
                              </Grid>
                              {/* {[
                                "Approved IVF",
                                "Approved FET",
                                "Approved IVF/FET",
                                "Approved FET/Freeze All"
                              ].indexOf(ele.clinicalTreatmentAdditionalField?.clinicalDecision) >
                                -1 &&
                                ele.outcomeCategory != "No Status" && (
                                   {ele.clinicalTreatmentAdditionalField?.clinicalDecision ==
                                "Approved" && (  */}

                              {/* )} */}
                              {/* <Box className="m-t-10">
                                <Stack direction="column">
                                  <Typography>{ACKNOWLEDGE_TEXT}</Typography>
                                  <FormControl required variant="standard">
                                    <FormControlLabel
                                      required
                                      control={
                                        <Checkbox
                                          required
                                          checked={isAcknowledge}
                                          onChange={() => {
                                            setIsAcknowledge(!isAcknowledge);
                                          }}
                                        />
                                      }
                                    />
                                    <FormLabel className="ack">I Acknowledge</FormLabel>
                                  </FormControl>
                                </Stack>
                              </Box> */}
                            </Box>
                          </Box>
                          <Box>
                            <Stack
                              direction="row"
                              spacing={2}
                              className="modal-bottom-section"
                              style={{ justifyContent: "flex-end" }}>
                              <Button
                                type="submit"
                                variant="contained"
                                className="btn-usf"
                                disabled={handleValidateSaveOutcome(
                                  values,
                                  ele.clinicalTreatmentId
                                )}
                                onClick={(e) =>
                                  handleSaveCloseTreatment(e, ele.clinicalTreatmentId, false)
                                }>
                                Save
                              </Button>
                              <Button
                                type="submit"
                                variant="contained"
                                className="btn-usf"
                                // disabled={handleValidateSaveOutcome(
                                //   values,
                                //   ele.clinicalTreatmentId
                                // )}
                                onClick={(e) =>
                                  handleSaveCloseTreatment(e, ele.clinicalTreatmentId, true)
                                }>
                                Save and Close
                              </Button>
                            </Stack>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      ""
                    )}
                  </>
                ))}
            </Grid>
          </Box>
          <Box className="m-t-10">
            <Typography sx={{ fontSize: "16px" }}>Outcome History</Typography>
            <Box className="grid-style m-t-5" sx={{ height: "200px" }}>
              <DataGridPremiumComp
                columns={outcomeColumns}
                rows={outComeRowData}
                rowCount={outComeRowData?.length}
                getRowId={() => uuidv4()}
                pageSizeOptions={[10, 25, 50, 100]}
                pageSize={pageSizeOut.pageSize}
                paginationModel={pageSizeOut}
                onPaginationModelChange={(newPageSize) => setPageSizeOut(newPageSize)}
                pagination
              />
            </Box>
          </Box>
        </Box>
      </Modal>

      {isMessageModal && (successMsg || errorMsg) ? (
        <MessageModal
          open={isMessageModal}
          message={successMsg}
          error={errorMsg}
          closeModal={closeMessageModal}
        />
      ) : null}

      {isMessageModalEmail && successMsgEmail ? (
        <MessageModal
          open={isMessageModalEmail}
          message={successMsgEmail}
          closeModal={closeMessageModalEmail}
        />
      ) : null}

      <MessageModal
        open={isOpenForExpDate}
        message={
          "This authorization has expired.  The Authorization Expiration Date must be updated prior to entering Outcome data"
        }
        closeModal={() => {
          setIsOpenForExpDate(false);
        }}
      />

      <WarnningMessageModal
        open={isErrorResponseModal}
        message={errorResponse}
        closeModal={closeErrorResponseModal}
      />

      <SendAuthorizationModal
        data={requestData}
        isOpen={isAuthOpen}
        closeSendAuth={closeSendAuth}
        SaveAuthorization={SaveAuthorization}></SendAuthorizationModal>
      {openEmailDocument ? (
        <EmailDocumentModal
          openDialog={openEmailDocument}
          close={closeEmailDocument}
          clinicalTreatmentNo={
            props.programCycle &&
            props.programCycle[props.programCycle.length - 1] &&
            props.programCycle[props.programCycle.length - 1].clinicalTreatmentNo
          }
          authorizedTreatment={
            props.programCycle &&
            props.programCycle[props.programCycle.length - 1] &&
            props.programCycle[props.programCycle.length - 1].authorizedTreatment
          }
          isOutcomeEmail={isOutcomeEmail}
          clinicalTreatmentId={ctId}
        />
      ) : null}
      <WarningPopUp
        open={isOpenWarningOutcome}
        closeModal={handleCloseWarningOutcome}
        error={errorResponse}
      />
      <WarnningMessageModal
        open={isOutcomeErrorModal}
        message={outcomeErrorMsg}
        closeModal={closeOutcomeErrorModal}
      />
    </Box>
  );
};

const mapStateToProps = ({
  trackOutComeFormReducer,
  programCycleReducer,
  estimateReducer,
  medcaseHeaderReducer,
  financialSnapshotPaymentReducer
}) => {
  console.log("financialSnapshotPaymentReducer", financialSnapshotPaymentReducer);
  return {
    clinicalList: trackOutComeFormReducer.clinicalTreatmentOutcomesList,
    editClinical: trackOutComeFormReducer.editClinicalTreatmentOutcomesList,
    sendAuth: trackOutComeFormReducer.sendAuth,
    errorMsg: trackOutComeFormReducer.error,
    successMsg: trackOutComeFormReducer.success,
    loading: trackOutComeFormReducer.loading,
    errorResponse: trackOutComeFormReducer.errorResponse,
    programCycle: programCycleReducer.programCycle,
    personalData: estimateReducer.personalData.data,
    patientDetails: medcaseHeaderReducer.patientDetails,
    successMsgEmail: financialSnapshotPaymentReducer.success,
    errorEmail: financialSnapshotPaymentReducer.errorEmail
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getClinicalTreatmentOutcomes: (payload) => {
      dispatch(actionType.getClinicalTreatmentOutcomes(payload));
    },
    editTreatmentPlanOutComeList: (payload) => {
      dispatch(actionType.editTreatmentPlanOutComeList(payload));
    },
    resetOutcomeList: () => {
      dispatch(actionType.resetOutcomeList());
    },
    resetRes: () => {
      dispatch(actionType.resetRes());
    },
    saveAuthorization: (payload) => {
      dispatch(actionType.saveAuthorization(payload));
    },
    getTreatmentPlan: (payload) => {
      dispatch(getTreatmentPlan(payload));
    },
    getGlobalData: (payload) => {
      dispatch(getGlobalData(payload));
    },
    getProgramCycle: (payload) => {
      dispatch(actionType.getProgramCycle(payload));
    },
    getPatientDetailsHeader: (payload) => {
      dispatch(actionType.getPatientDetailsHeader(payload));
    },
    postEmailOption: (payload) => {
      dispatch(actionType.postEmailOption(payload));
    },
    resetEmail: () => {
      dispatch(actionType.resetEmailList());
    },
    resetPostEmail: () => {
      dispatch(actionType.resetPostEmailOption());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }

    // getPersonalData: (payload) => {
    //   dispatch(WorkspaceActionType.getPersonalData(payload));
    // }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TrakOutcomeFormModal);
