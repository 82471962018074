import * as actionType from "../actions/ActionTypes";

const initialState = {
  commentList: [],
  clinicalList: [],
  financialList: [],
  addComment: [],
  loading: false,
  error: null,
  success: null
};

const commentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.COMMENT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.COMMENT_LIST_SUCCESS: {
      return {
        ...state,
        commentList: action.payload,
        loading: false
      };
    }
    case actionType.CLINICAL_LIST_SUCCESS: {
      return {
        ...state,
        clinicalList: action.payload,
        loading: false
      };
    }
    case actionType.FINANCIAL_LIST_SUCCESS: {
      return {
        ...state,
        financialList: action.payload,
        loading: false
      };
    }
    case actionType.COMMENT_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_COMMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_COMMENT_SUCCESS: {
      return {
        ...state,
        addComment: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_COMMENT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.RESET_COMMENT: {
      return {
        ...state,
        addComment: null,
        commentList: [],
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    case actionType.RESET_COMMENT_LIST: {
      return {
        ...state,
        commentList: [],
        loading: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default commentReducer;
