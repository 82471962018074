import { MenuItem, Select } from "@mui/material";

const DropdownField = ({ options, ...props }) => {
  return (
    <Select size="small" {...props}>
      {options.map((option, key) => {
        return (
          <MenuItem key={key} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
};
export default DropdownField;
