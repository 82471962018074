import * as actionType from "../actions/ActionTypes";

const initialState = {
  contractTemplateMappingList: [],
  programsData: [],
  templateList: [],
  addContreactTemplateMapping: null,
  editContreactTemplateMapping: null,
  deleteContreactTemplateMapping: null,
  loading: false,
  error: null,
  success: null
};

const ctmReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CTM_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CTM_LIST_SUCCESS: {
      return {
        ...state,
        contractTemplateMappingList: action.payload,
        loading: false
      };
    }
    case actionType.CTM_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_CTM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_CTM_SUCCESS: {
      return {
        ...state,
        addContreactTemplateMapping: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_CTM_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg.includes(
          "Combination of Template and Program for this Practice already exists."
        )
          ? "duplicate"
          : action.errorMsg
      };
    }
    case actionType.EDIT_CTM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_CTM_SUCCESS: {
      return {
        ...state,
        editContreactTemplateMapping: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_CTM_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_CTM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_CTM_SUCCESS: {
      return {
        ...state,
        deleteContreactTemplateMapping: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_CTM_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_CTM: {
      return {
        ...state,
        addContreactTemplateMapping: null,
        editContreactTemplateMapping: null,
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    case actionType.RESET_CTM_LIST: {
      return {
        ...state,
        contractTemplateMappingList: [],
        loading: null
      };
    }
    case actionType.PROGRAMSDATA_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PROGRAMSDATA_LIST_SUCCESS: {
      return {
        ...state,
        programsData: action.payload,
        loading: false
      };
    }
    case actionType.PROGRAMSDATA_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.TEMPLATE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.TEMPLATE_LIST_SUCCESS: {
      return {
        ...state,
        templateList: action.payload,
        loading: false
      };
    }
    case actionType.TEMPLATE_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default ctmReducer;
