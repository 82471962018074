import * as actionType from "./ActionTypes";

// notifications
export const getNotifications = (payload) => {
  return {
    type: actionType.GET_USER_NOTIFICATIONS_REPORT_REQUESTED,
    payload: payload
  };
};

export const getNotificationMetaData = (payload) => {
  return {
    type: actionType.GET_USER_NOTIFICATION_METADATA_REPORT_REQUESTED,
    payload: payload
  };
};

export const updateNotificationMessageStatus = (payload) => {
  return {
    type: actionType.UPDATE_USER_NOTIFICATION_REPORT_REQUESTED,
    payload: payload
  };
};
