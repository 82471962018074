import * as actionType from "../actions/ActionTypes";

const initState = {
  medcaseRevenue605List: [],
  loading: false,
  error: null,
  success: null
};
const medcaseRevenue605Reducer = (state = initState, action) => {
  switch (action.type) {
    case actionType.MEDCASE_REVENUE_605_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.MEDCASE_REVENUE_605_SUCCESS: {
      return {
        ...state,
        medcaseRevenue605List: action.payload,
        loading: false
      };
    }
    case actionType.MEDCASE_REVENUE_605_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default medcaseRevenue605Reducer;
