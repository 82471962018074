import { put, takeEvery, all, call } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPersonal(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000",
      ...payload.values
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/person`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PERSONAL_DATA_SUCCESS,
      payload: { addPatient: payload.addPatient, patientResponse: response }
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PERSONAL_DATA_FAILED, message: error.message });
    }
  }
}

function* getPersonalData() {
  yield takeEvery(actionTypes.PERSONAL_DATA_REQUESTED, fetchPersonal);
}
function* getPersonDetailById(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/person/${payload.personId}`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PERSON_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PERSON_DATA_FAILED, message: error.message });
    }
  }
}

function* getPersonDetailByIdSaga() {
  yield takeEvery(actionTypes.PERSON_DATA_REQUESTED, getPersonDetailById);
}
function* getPartnerInfo(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/person/${payload.personId}`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PARTNER_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PARTNER_DATA_FAILED, message: error.message });
    }
  }
}

function* getPartnerSaga() {
  yield takeEvery(actionTypes.PARTNER_DATA_REQUESTED, getPartnerInfo);
}

function* getTasks() {
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/patients/tasks`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TASK_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TASK_DATA_FAILED, message: error.message });
    }
  }
}

function* getTaskSaga() {
  yield takeEvery(actionTypes.TASK_DATA_REQUESTED, getTasks);
}

function* getTaskCheckList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `patients/${payload}/tasks`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TASK_CHECKED_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TASK_CHECKED_DATA_FAILED, message: error.message });
    }
  }
}

function* getTaskCheckListSaga() {
  yield takeEvery(actionTypes.TASK_CHECKED_DATA_REQUESTED, getTaskCheckList);
}

/* Edit task */
function* editTask(action) {
  const { payload } = action;
  const { personId } = payload;

  const body = {
    //  patientTaskMappingId: payload.patientTaskMappingId,
    patientTaskId: payload.patientTaskId,
    isChecked: payload.isChecked
  };

  try {
    const requestBody = createRequestBody("put", `/patients/${personId}/tasks`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.UPDATE_CHECKED_DATA_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.UPDATE_CHECKED_DATA_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* editTaskSaga() {
  yield takeEvery(actionTypes.UPDATE_CHECKED_DATA_REQUESTED, editTask);
}

function* addPatient(action) {
  const { practiceId, payload } = action.payload;

  try {
    const requestBody = createRequestBody("post", `/practices/${practiceId}/patients`, "", payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_PATIENT_DETAILS_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ADD_PATIENT_DETAILS_FAILED,
        errorMsg: error?.response?.data || error.message
      });
    }
  }
}
function* addPatientSaga() {
  yield takeEvery(actionTypes.ADD_PATIENT_DETAILS_REQUESTED, addPatient);
}
function* updatePatient(action) {
  const { practiceId, body, patientId } = action.payload;
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/patients/${patientId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.UPDATE_PATIENT_DETAILS_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.UPDATE_PATIENT_DETAILS_FAILED,
        errorMsg: error.message
      });
    }
  }
}
function* updatePatientSaga() {
  yield takeEvery(actionTypes.UPDATE_PATIENT_DETAILS_REQUESTED, updatePatient);
}
function* getPatientPartnerList(action) {
  const { practiceId, personId } = action.payload;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/persons/${personId}/partners`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GET_PATIENT_PARTNER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_PATIENT_PARTNER_FAILED, message: error.message });
    }
  }
}
function* getPatientPartnerListSaga() {
  yield takeEvery(actionTypes.GET_PATIENT_PARTNER_REQUESTED, getPatientPartnerList);
}

function* fetchFAPData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/patients/${payload.patientId}/fertilityAccessProgram`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.FAP_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.FAP_DATA_FAILED, message: error.message });
    }
  }
}

function* getFAPDataSaga() {
  yield takeEvery(actionTypes.FAP_DATA_REQUESTED, fetchFAPData);
}

function* getPrimaryMdList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/mds`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_PRIMARY_MD_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_PRIMARY_MD_FAILED, message: error.message });
    }
  }
}

function* getPrimaryMdListSaga() {
  yield takeEvery(actionTypes.GET_PRIMARY_MD_REQUESTED, getPrimaryMdList);
}

export default function* patientSaga() {
  yield all([
    getPersonalData(),
    getPartnerSaga(),
    getTaskSaga(),
    getTaskCheckListSaga(),
    editTaskSaga(),
    addPatientSaga(),
    getPersonDetailByIdSaga(),
    updatePatientSaga(),
    getPatientPartnerListSaga(),
    getFAPDataSaga(),
    getPrimaryMdListSaga()
  ]);
}
