import { connect } from "react-redux";
import * as actionType from "../actions/index";
import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/system";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
//import moment from "moment";
import moment from "moment-timezone";
import { getTimeZone } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const PatientPortalAdminLoginActivity = (props) => {
  const { auditLogsList = [], impersonateLogsList = [], loading, actions, email } = props;
  const [auditLogs, setAuditLogs] = useState([]);
  const [impersonateLogs, setImpersonateLogs] = useState([]);
  const [value, setValue] = useState("1");
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [pageSizeImp, setPageSizeImp] = useState({
    pageSize: 100,
    page: 0
  });

  useEffect(() => {
    actions.resetAuditLogs();
    // if (auditLogsList.length == 0) {
    actions.getAuditLogs({ email });
    // }
    // if (impersonateLogsList.length == 0) {
    actions.getAuditLogs({ email, impersonate: true });
    // }
  }, []);

  useEffect(() => {
    // if (auditLogsList.length > 0) {
    const rows =
      (auditLogsList &&
        auditLogsList.length &&
        auditLogsList.map((item, index) => {
          item.id = index;
          return item;
        })) ||
      [];
    setAuditLogs(rows);
    // }
  }, [auditLogsList]);

  useEffect(() => {
    // if (impersonateLogsList.length > 0) {
    const rows =
      (impersonateLogsList &&
        impersonateLogsList.length &&
        impersonateLogsList.map((item, index) => {
          item.id = index;
          return item;
        })) ||
      [];
    setImpersonateLogs(rows);
    // }
  }, [impersonateLogsList]);

  const columns = [
    {
      field: "patientName",
      headerName: "PATIENT NAME",
      flex: 1
    },
    {
      field: "eventTime",
      headerName: "EVENT TIME",
      flex: 1,
      renderCell: ({ row }) => {
        let eventTime = "";
        //console.log("==>", moment().format("MM/DD/YYYY hh:mm A"));
        if (row.eventTime) {
          eventTime = moment.utc(row.eventTime).tz(getTimeZone()).format("MM/DD/YYYY hh:mm A");
        }
        // return moment(row.eventTime, "ddd MMM DD HH:mm:ss z YYYY").format("MM/DD/YYYY HH:mm A");
        return eventTime;
        // Wed Jan 10 05:52:24 UTC 2024
        // return row.creationDate
        //   ? moment(row.creationDate).format("MM/DD/YYYY HH:mm A")
        //   : row.eventTime;
      }
    },
    {
      field: "ipAddress",
      headerName: "IP ADDRESS",
      flex: 1
    }
  ];

  const impersonateColumns = [
    {
      field: "impersonatorName",
      headerName: "IMPERSONATOR NAME",
      flex: 1
    },
    {
      field: "screenName",
      headerName: "EVENT NAME",
      flex: 1
    },
    {
      field: "creationDate",
      headerName: "EVENT TIME",
      flex: 1,
      renderCell: ({ row }) => {
        return moment.utc(row.creationDate).tz("America/New_York").format("MM/DD/YYYY hh:mm A");
      }
    },
    {
      field: "ipAddress",
      headerName: "IP ADDRESS",
      flex: 1
    }
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ /* height: "660px", */ width: "750px" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="LOGIN ACTIVITY" value="1" />
            <Tab label="IMPERSONATE ACTIVITY" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <DataGridPremiumComp
            rows={auditLogs}
            rowCount={auditLogs?.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            autoHeight={true}
          />
        </TabPanel>
        <TabPanel value="2">
          <DataGridPremiumComp
            rows={impersonateLogs}
            columns={impersonateColumns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSizeImp.pageSize}
            paginationModel={pageSizeImp}
            onPaginationModelChange={(newPageSizeImp) => setPageSizeImp(newPageSizeImp)}
            pagination
            autoHeight={true}
            components={
              <Stack style={{ height: "100%", alignItems: "center", justifyContent: "center" }}>
                There are no activity logs for this patient
              </Stack>
            }
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

const mapStateToProps = ({ auditLogsReducer }) => {
  return {
    auditLogsList: auditLogsReducer.auditLogsList,
    impersonateLogsList: auditLogsReducer.impersonateLogsList,
    loading: auditLogsReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getAuditLogs: (payload) => {
      dispatch(actionType.getAuditLogs(payload));
    },
    resetAuditLogs: () => {
      dispatch(actionType.resetAuditLogs());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientPortalAdminLoginActivity);
