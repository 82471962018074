import * as actionType from "../actions/ActionTypes";

const initialState = {
  revenueValidationList: [],
  treatmentPlanList: [],
  loading: false,
  error: null,
  success: null
};
const revenueValidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_REVENUE_VALIDATION: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_ALL_TREATMENT_PLAN: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_REVENUEVALIDATION_REPORT_SUCCESS: {
      return {
        ...state,
        revenueValidationList: action.payload,
        loading: false,
        success: true
      };
    }
    case actionType.ALL_TREATMENT_PLAN_DATA: {
      return {
        ...state,
        treatmentPlanList: action.payload,
        loading: false,
        success: true
      };
    }
    case actionType.GET_REVENUEVALIDATION_REPORT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ALL_TREATMENT_PLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.REVENUE_VALIDATION_RESET_MESSAGE: {
      return {
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default revenueValidationReducer;
