import * as React from "react";
import * as actionType from "../actions";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import { Link as RouterLink, useLocation, useParams } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { PropTypes } from "prop-types";
import { Stack } from "@mui/material";
import TourIcon from "@mui/icons-material/Tour";

const ListItemLink = (props) => {
  const { icon, primary, onClick, selected, to, flag, disabled } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItem
      selected={selected}
      button
      component={renderLink}
      disablePadding
      sx={{
        minHeight: "70px",
        display: "flex",
        justifyContent: "space-between"
      }}
      disabled={disabled}
      onClick={onClick}>
      <Stack direction="row">
        {icon ? <ListItemIcon sx={{ ml: 2, minWidth: "0" }}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </Stack>
      {flag ? <TourIcon fontSize="medium" sx={{ margin: "10px" }} /> : null}
    </ListItem>
  );
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onclick: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  flag: PropTypes.bool,
  disabled: PropTypes.bool
};

const CaseLookupConfig = (props) => {
  const { actions, getSelectedConfig, accountFlagData = [], medcaseDetails, success } = props;

  const accountData = {
    financialSnapShotFlag: false,
    commentFlag: false,
    followUpDate: "",
    wfrDate: ""
  };

  const { id } = useParams();
  const location = useLocation();
  const [accountFlagDataValues, setAccountFlagData] = React.useState(accountData);

  React.useEffect(() => {
    // actions.getAccountFlag({ medcaseId: id });
    // actions.getMedcaseDetailsHeader({ medcaseId: id });
  }, []);

  React.useEffect(() => {
    setAccountFlagData(accountFlagData);
  }, [accountFlagData]);

  React.useEffect(() => {
    actions.getAccountFlag({ medcaseId: id });
  }, [success]);

  const practiceConfig = [
    {
      title: "STATUS",
      url: "status",
      text: "status",
      icon: "/Assets/payment.png",
      flag: false,
      disabled: false
    },
    {
      title: "CASE UPDATE",
      url: "case-update",
      text: "case-update",
      icon: "/Assets/payment.png",
      flag: false,
      disabled: medcaseDetails?.medcaseActive == "Active" ? false : true
    },
    {
      title: "IVF FINANCIAL SNAPSHOT",
      url: "financial-snapshot",
      text: "financial-snapshot",
      icon: "/Assets/payment.png",
      flag: accountFlagDataValues.financialSnapShotFlag,
      disabled:
        medcaseDetails?.medcaseActive == "Active" && medcaseDetails.medcaseType != "PGTA"
          ? false
          : true
    },
    {
      title: "PGTA FINANCIAL SNAPSHOT",
      url: "genetic-testing",
      text: "genetic-testing",
      icon: "/Assets/payment.png",
      flag: false,
      disabled:
        medcaseDetails?.medcaseActive == "Active" && medcaseDetails.medcaseType == "PGTA"
          ? false
          : true
    },
    // {
    //   title: "MEDICATION RIDER",
    //   url: "medication-rider",
    //   text: "medication-rider",
    //   icon: "/Assets/payment.png",
    //   flag: false
    // },
    {
      title: "DOCUMENTS",
      url: "document",
      text: "document",
      icon: "/Assets/payment.png",
      flag: false,
      disabled: medcaseDetails?.medcaseActive == "Active" ? false : true
    },
    // {
    //   title: "CLINICAL",
    //   url: "clinical",
    //   text: "clinical",
    //   icon: "/Assets/payment.png",
    //   falg: false
    // },
    {
      title: "PATIENT INFO",
      url: "patient-info",
      text: "patient-info",
      icon: "/Assets/payment.png",
      flag: false,
      disabled: medcaseDetails?.medcaseActive == "Active" ? false : true
    },
    {
      title: "COMMENTS",
      url: "comment",
      text: "comment",
      icon: "/Assets/payment.png",
      flag: accountFlagDataValues.commentFlag,
      disabled: medcaseDetails?.medcaseActive == "Active" ? false : true
    }
    // {
    //   title: "PATIENTPAYMENT",
    //   url: "patientPaymentDetails",
    //   text: "patientPaymentDetails",
    //   icon: "/Assets/payment.png"
    // }
  ];

  const selectedPath = practiceConfig.findIndex((item) => item.url === location.pathname);

  const [selectedIndex, setSelectedIndex] = useState(selectedPath);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };
  return (
    <Box className="sidebar-container">
      <List disablePadding>
        {practiceConfig.map(({ title, text, url, icon, flag, disabled }, index) => (
          <ListItemLink
            key={index}
            selected={selectedIndex === index}
            onClick={() => {
              handleListItemClick(index);
              getSelectedConfig(text);
            }}
            to={url}
            primary={title}
            icon={<img src={icon} alt="" />}
            flag={flag}
            disabled={disabled}
          />
        ))}
      </List>
    </Box>
  );
};

const mapStateToProps = ({ caseUpdateReducer, medcaseHeaderReducer }) => {
  return {
    accountFlagData: caseUpdateReducer.accountFlagData,
    medcaseDetails: medcaseHeaderReducer.medcaseDetails,
    loading: caseUpdateReducer.loading,
    errorMsg: caseUpdateReducer.error,
    success: caseUpdateReducer.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getAccountFlag: (medCaseId) => {
      dispatch(actionType.getAccountFlag(medCaseId));
    },
    getMedcaseDetailsHeader: (payload) => {
      dispatch(actionType.getMedcaseDetailsHeader(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseLookupConfig);
