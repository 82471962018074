import React, { useEffect, useState } from "react";
import { Box, Stack, Grid, Button, Typography, TextField, Autocomplete } from "@mui/material";
// import moment from "moment";
import * as actionType from "../actions";
import { connect } from "react-redux";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import { getPractices } from "../../admin/actions";

const RevenueValidation = (props) => {
  const {
    revenueValidationList = [],
    error,
    success,
    loading,
    actions,
    metaData,
    practices,
    treatmentPlanList
  } = props;
  const defaultValues = {
    enrolledProgram: "",
    enrolledTreatmentPlan: "",
    medcaseStatus: "",
    practiceId: ""
  };

  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [rows, setRows] = useState([]);
  const [values, setValues] = useState(defaultValues);
  console.log(error, success, setValues);

  useEffect(() => {
    actions.getMetaData();
    actions.getPractices();
    actions.getTreatmentPlan();
  }, []);

  console.log("zhr treatmentPlanList", treatmentPlanList);

  useEffect(() => {
    if (revenueValidationList) {
      const rows = revenueValidationList.map((res, indx) => {
        return { ...res, id: indx };
      });
      setRows(rows);
    }
  }, [revenueValidationList]);

  const handleRunReport = () => {
    const payload = {
      enrolledProgram: values.enrolledProgram,
      enrolledTreatmentPlan: values.enrolledTreatmentPlan,
      practiceId: values.practiceId,
      medcaseStatus: values.medcaseStatus
    };
    console.log("zhr payload", payload);
    actions.getRevenueValidation(payload);
  };

  const handleValuesChange = (e, name, value) => {
    console.log("zhr onchange", name, value);
    if (value) {
      setValues({ ...values, [name]: value.value });
    } else {
      setValues({ ...values, [name]: "" });
    }
  };

  function ToolBar() {
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Grid container spacing={2} alignItems="center">
            {/* Practice */}
            <Grid item xs={12} sm={1.5}>
              <Typography>Practice</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="practiceId"
                value={
                  practices.find((practice) => practice.practiceId === values?.practiceId)?.name
                }
                options={practices.map((item, index) => ({
                  id: index,
                  label: item.name,
                  value: item.practiceId
                }))}
                onChange={(e, value) => {
                  handleValuesChange(e, "practiceId", value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." size="small" />}
                fullWidth
              />
            </Grid>

            {/* Enrolled Program */}
            <Grid item xs={12} sm={1.5}>
              <Typography>Enrolled Program</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="enrolledProgram"
                value={values?.enrolledProgram}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.programName.map((programId, index) => ({
                    id: index,
                    label: programId,
                    value: programId
                  }))
                }
                onChange={(e, value) => {
                  handleValuesChange(e, "enrolledProgram", value);
                }}
                renderInput={(params) => <TextField {...params} label="Program..." size="small" />}
                fullWidth
              />
            </Grid>

            {/* Enrolled Treatment Plan */}
            <Grid item xs={12} sm={1.5}>
              <Typography>Enrolled Treatment Plan</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="enrolledTreatmentPlan"
                value={
                  treatmentPlanList.find(
                    (treatmentPlan) =>
                      treatmentPlan.treatmentPlanId === values?.enrolledTreatmentPlan
                  )
                    ? `${
                        treatmentPlanList.find(
                          (treatmentPlan) =>
                            treatmentPlan.treatmentPlanId === values?.enrolledTreatmentPlan
                        )?.treatmentPlanName
                      } - ${values?.enrolledTreatmentPlan}`
                    : ""
                }
                options={treatmentPlanList.map((item, index) => ({
                  id: index,
                  label: `${item.treatmentPlanName} - ${item.treatmentPlanId}`,
                  value: item.treatmentPlanId
                }))}
                onChange={(e, value) => {
                  handleValuesChange(e, "enrolledTreatmentPlan", value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Treatment Plan..." size="small" />
                )}
                fullWidth
              />
            </Grid>

            {/* Medcase Status */}
            <Grid item xs={12} sm={1.5}>
              <Typography>Medcase Status</Typography>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Autocomplete
                disablePortal
                required
                name="medcaseStatus"
                value={values?.medcaseStatus}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.medcaseStatus.map((item, index) => ({
                    id: index,
                    label: item,
                    value: item
                  }))
                }
                onChange={(e, value) => {
                  handleValuesChange(e, "medcaseStatus", value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Medcase Status..." size="small" />
                )}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button variant="contained" className="btn-usf" onClick={handleRunReport}>
            Run Report
          </Button>
        </Grid>
      </Grid>
    );
  }

  const columns = [
    {
      field: "medCaseId",
      headerName: "MEDCASE ID",
      width: 130,
      editable: false
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      width: 130,
      editable: false
    },
    {
      field: "medCaseStatus",
      headerName: "Medcase Status",
      width: 130,
      editable: false
    },
    {
      field: "enrollmentDate",
      headerName: "Enrolled Date",
      width: 130,
      editable: false
    },
    {
      field: "practiceName",
      headerName: "Practice",
      width: 130,
      editable: false
    },
    {
      field: "progShortName",
      headerName: "Enrolled Program",
      width: 130,
      editable: false
    },
    {
      field: "treatmentPlan",
      headerName: "Treatment Program",
      width: 130,
      editable: false
    },
    {
      field: "normalFrozenEmbryosRemaining",
      headerName: "Normal Embryos Remain",
      width: 90,
      editable: false
    },
    {
      field: "netprogramFee",
      headerName: "Net Prog Fee",
      width: 130,
      editable: false
    },
    {
      field: "totalPatientPayment",
      headerName: "Total Patient Pymts",
      width: 130,
      editable: false
    },
    {
      field: "programRefunds",
      headerName: "Prog Refunds",
      width: 130,
      editable: false
    },
    {
      field: "pregnancyRefund",
      headerName: "Preg Loss Refunds",
      width: 130,
      editable: false
    },
    {
      field: "miscellaneousRefund",
      headerName: "Misc Refunds",
      width: 130,
      editable: false
    },
    {
      field: "netPatientPayment",
      headerName: "Net Patient Pymts",
      width: 130,
      editable: false
    },
    {
      field: "revenueRecognized",
      headerName: "Reveneue Recognized",
      width: 130,
      editable: false
    },
    {
      field: "revenueDeferred",
      headerName: "Revenue Deferred",
      width: 130,
      editable: false
    },
    {
      field: "contractExpDate",
      headerName: "Contract Expiration Date",
      width: 130,
      editable: false
    },
    {
      field: "practicePayment",
      headerName: "Practice Pymts",
      width: 130,
      editable: false
    },
    {
      field: "mcrPayment",
      headerName: "MCR Pymts",
      width: 130,
      editable: false
    },
    {
      field: "labPayment",
      headerName: "Lab Pymts",
      width: 130,
      editable: false
    },
    {
      field: "labMcrPayment",
      headerName: "Lab MCR Pymts",
      width: 130,
      editable: false
    },
    {
      field: "totalExpense",
      headerName: "Total Expenses",
      width: 130,
      editable: false
    },
    {
      field: "manualReview",
      headerName: "Manual Review Required",
      width: 130,
      editable: false
    },
    {
      field: "amountDue",
      headerName: "Amount Due",
      width: 130,
      editable: false
    }
  ];

  return (
    <>
      <Stack direction="row" className="pad-5 m-t-8"></Stack>
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 20, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            localeText={"Export Button"}
            components={<ToolBar />}
            isExport={true}
            headerText={`Revenue Validation`}
            disableRowSelectionOnClick={true}
          />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({ revenueValidationReducer, caseUpdateReducer, practices }) => {
  return {
    revenueValidationList: revenueValidationReducer.revenueValidationList,
    treatmentPlanList: revenueValidationReducer.treatmentPlanList,
    loading: revenueValidationReducer.loading,
    error: revenueValidationReducer.error,
    success: revenueValidationReducer.success,
    metaData: caseUpdateReducer.metaData,
    practices: practices.practices
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getRevenueValidation: (payload) => {
      dispatch(actionType.getRevenueValidation(payload));
    },
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    },
    getPractices: () => {
      dispatch(getPractices());
    },
    getTreatmentPlan: () => {
      dispatch(actionType.getTreatmentPlan());
    },
    revenueValidationResetMessage: () => {
      dispatch(actionType.revenueValidationResetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RevenueValidation);
