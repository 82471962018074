import React, { useState, useEffect } from "react";
import {
  Button,
  FormControlLabel,
  Typography,
  Divider,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Grid,
  Checkbox,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import UserRightsTable from "./UserRightsTable";
import rolerightsTable from "../../../shared/constants/rolerightsTable";
import "./styles.css";
import groupname_grouprights from "../../../shared/constants/SuperData";
import { getPractices } from "../../actions";
import { connect, useSelector, useDispatch } from "react-redux";
import * as actionType from "../../actions";
import MessageModal from "../../../shared/components/MessageModal";
import LoadingModal from "../../../trak/components/modals/loadingModal";
import { useNavigate } from "react-router-dom";
import { isEmailValid, isPhoneNumberValid } from "../../../shared/constants/constants";
import { MenuProps } from "../../../shared/components/Utils";

//C:\Users\ganesh_karthikayen\Projects\usf-felix-app\src\modules\shared\components\util.js

const CreateNewUser = (props) => {
  let formData = {
    firstName: "",
    lastName: "",
    domainUser: "",
    email: "",
    phoneNo: "",
    primaryRole: props.selectedUserRow.primaryRole,
    practiceId: "",
    defaultPracticeId: ""
  };
  const navigate = useNavigate();
  const [values, setValue] = useState(formData);
  //const [editValues, setEditValues] = useState(formData);
  const [tableDisplay, setTableDisplay] = useState(false);
  const [selectedValue, setSelectedValue] = useState([]);
  const [listData, setListData] = useState([]);
  const [addMoreFlag, setAddMoreFlag] = useState(false);
  const [moreData, setMoreData] = useState(groupname_grouprights);
  const [tableData, setTableData] = useState({});
  const [addMoreCopyData, setAddmoreCopyData] = useState({});
  const [roleName, setRoleName] = useState("");
  const practices = useSelector((state) => state.practices.practices);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [userRightList, setUserRightList] = useState({});
  const [cognitoId, setCognitoId] = useState();
  const [selectPractices, setSelectedPractices] = useState([]);
  const [selected, setSelected] = useState([]);
  const [defaultSelected, setDefaultSelected] = useState("");
  const [popMessageInfo, setPopMessageInfo] = useState([]);
  let groupnames = [];
  let tableHeader = [
    { lable: "Group Name", value: "group_name_display" },
    { lable: "Screen Rights", value: "group_rights" },
    { lable: "Suppress", value: "group_rights" }
  ];
  const dispatch = useDispatch();
  const {
    actions,
    addSuccess,
    loading,
    selectedUserRow,
    enableEdit,
    addError,
    getUserDetails,
    editDetailsSuccess,
    editDetailsFail,
    editDetailsLoading,
    getUserLoading
  } = props;
  useEffect(() => {
    if (!practices.length) dispatch(getPractices());
  }, []);
  useEffect(() => {
    let tempData = Object.keys(rolerightsTable[0]);
    let templistData = [];
    let temptableData = JSON.parse(JSON.stringify(rolerightsTable[0]));
    if (!enableEdit) {
      tempData.forEach((i) => {
        if (rolerightsTable[0][i]["isDisplay"]) {
          templistData.push({ label: rolerightsTable[0][i]["displayName"], value: i });
          temptableData[i]["groupname_grouprights"] = tableDataPraser(
            temptableData[i]["groupname_grouprights"]
          );
        }
      });
      setListData(templistData);
      let tempObj = {};
      let tempGroupName = JSON.parse(JSON.stringify(groupname_grouprights));
      tempGroupName.forEach((i) => {
        i["group_rights"] = objectParser(i["group_rights"]);
        tempObj[`${i.group_name_attr}`] = i;
      });

      setTableData(temptableData);
      setAddmoreCopyData(tempObj);
    } else if (enableEdit && getUserDetails) {
      tempData.forEach((i) => {
        if (rolerightsTable[0][i]["isDisplay"]) {
          templistData.push({ label: rolerightsTable[0][i]["displayName"], value: i });
          temptableData[i]["groupname_grouprights"] = tableDataPraser(
            temptableData[i]["groupname_grouprights"]
          );
        }
      });
      let tempObj = {};
      let tempGroupName = JSON.parse(JSON.stringify(groupname_grouprights));
      tempGroupName.forEach((i) => {
        i["group_rights"] = objectParser(i["group_rights"]);
        tempObj[`${i.group_name_attr}`] = i;
      });

      setTableData(temptableData);
      setListData(templistData);
      setAddmoreCopyData(tempObj);
      proccessExitingData(templistData, temptableData, tempObj, temptableData);
    }
  }, [enableEdit, getUserDetails]);
  useEffect(() => {
    if (addSuccess || addError) {
      setPopMessageInfo(addSuccess ? addSuccess : addError);
      setIsMessageModal(true);
    } else {
      setIsMessageModal(false);
      setValue(formData);
    }
  }, [addSuccess, addError]);
  useEffect(() => {
    return () => {
      setIsMessageModal(false);
      actions.resetNewUser();
      actions.resetEditUser();
      actions.resetFetchDetails();
      setSelectedValue([]);
      setValue(formData);
      setMoreData([]);
      setAddmoreCopyData({});
      setAddMoreFlag(false);
      setSelectedPractices([]);
      setSelected([]);
      setDefaultSelected("");
    };
  }, []);
  useEffect(() => {
    if (!editDetailsLoading && editDetailsSuccess) {
      setIsMessageModal(true);
      setPopMessageInfo(editDetailsSuccess);
    } else if (!editDetailsLoading && editDetailsFail) {
      setPopMessageInfo(editDetailsFail);
      setIsMessageModal(true);
    } else {
      setIsMessageModal(false);
    }
  }, [editDetailsLoading, editDetailsSuccess, editDetailsFail]);
  useEffect(() => {
    if (enableEdit && getUserDetails && Object.keys(getUserDetails).length > 0) {
      console.log("selectedUserRow:", selectedUserRow);
      setValue({
        ...values,
        firstName: selectedUserRow.firstName,
        lastName: selectedUserRow.lastName,
        domainUser: selectedUserRow.domainUser,
        email: selectedUserRow.email,
        phoneNo: selectedUserRow.phoneNo,
        primaryRole: selectedUserRow.primaryRole,
        defaultPracticeId: selectedUserRow.defaultPracticeId
        //practiceId: selectedUserRow.practiceList[0]["practiceId"]
      });
      let tempPrac = [];
      let tempDataa = [];
      selectedUserRow.practiceList?.map((i) => {
        tempPrac.push(i.practiceName);
        tempDataa.push({ practiceId: i.practiceId });

        if (i.practiceId == selectedUserRow.defaultPracticeId) {
          setDefaultSelected(i.practiceName);
        }
      });

      console.log("tempPrac", tempPrac);
      console.log("tempDataa/", tempDataa);
      setSelected(tempPrac);
      setSelectedPractices(tempDataa);
      setCognitoId(selectedUserRow.cognitoUserId);
      setTableDisplay(true);
      setRoleName(selectedUserRow.primaryRole);
    }
  }, [selectedUserRow, enableEdit, getUserDetails]);
  const proccessExitingData = (dropDownList, temptableData, addMoreCopyData, tableData) => {
    for (let i = 0; dropDownList.length > i; i++) {
      if (dropDownList[i].label === selectedUserRow.primaryRole) {
        AddmoreDataCheck(
          dropDownList[i],
          getUserDetails.userRightList,
          temptableData[`${dropDownList[i].value}`].groupname_grouprights,
          addMoreCopyData,
          tableData
        );
        break;
      } else {
        setSelectedValue();
      }
    }
  };
  const AddmoreDataCheck = (
    selectedvalue,
    existingObject,
    tableData,
    addMoreCopyData,
    MasterTableData
  ) => {
    if (existingObject !== undefined && Object.keys(existingObject).length === tableData?.length) {
      checkAddMoreDataExisting(false, [], selectedvalue, addMoreCopyData, MasterTableData);
    } else {
      // if (existingObject !== undefined) {
      let arr = Object.keys(existingObject);
      tableData.forEach((mainData) => {
        arr = arr.filter((item) => item !== mainData.group_name_attr);
      });
      checkAddMoreDataExisting(true, arr, selectedvalue, addMoreCopyData, MasterTableData);
      // }
    }
  };
  const checkAddMoreDataExisting = (
    addmoreFlag,
    arr,
    selectedval,
    addMoreCopyData,
    MasterTableData
  ) => {
    let tempGroupData = JSON.parse(JSON.stringify(groupname_grouprights));
    tempGroupData = tempGroupData.map((i) => {
      i.checked = false;
      return i;
    });
    let selectedData = tempGroupData;
    rolerightsTable[0][`${selectedval.value}`].groupname_grouprights.forEach((i) => {
      selectedData = selectedData.filter((item) => item.group_name_attr !== i.group_name_attr);
    });
    let newMoreData = selectedData;
    let temptableData = JSON.parse(JSON.stringify(MasterTableData));

    if (addmoreFlag) {
      arr.forEach((i) => {
        temptableData[`${selectedval.value}`]["groupname_grouprights"].push(
          addMoreCopyData[`${i}`]
        );
        newMoreData.forEach((j) => {
          if (j.group_name_attr === i) {
            j.checked = true;
          }
        });
      });
    }
    let seletectedUserRights = getUserDetails;
    temptableData[`${selectedval.value}`]["groupname_grouprights"].forEach((i) => {
      if (seletectedUserRights["userRightList"][`${i.group_name_attr}`] !== undefined) {
        let seletedListCheck =
          seletectedUserRights["userRightList"][`${i.group_name_attr}`].split(":");
        i.group_rights.forEach((j) => {
          if (seletedListCheck.includes(j.value)) {
            j.checked = true;
          } else {
            j.checked = false;
          }
        });
      }
    });

    setMoreData(newMoreData);
    setAddmoreCopyData(addMoreCopyData);
    setTableData(temptableData);
    setAddMoreFlag(true);
    setUserRightList(getUserDetails.userRightList);
    setSelectedValue([selectedval]);
    // setValue({
    //   ...values,
    //   primaryRole: selectedval.label
    // });
  };
  const handleRadioChange = (e) => {
    const { name } = e.target;
    setValue({
      ...values,
      [name]: e.target.value
    });
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetNewUser();
    actions.resetEditUser();
    setSelectedValue([]);
    setValue(formData);
    setMoreData([]);
    setAddmoreCopyData({});
    setAddMoreFlag(false);
    setSelectedPractices([]);
    setSelected([]);
    backBtn();
    setDefaultSelected("");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "primaryRole") {
      let tempData = Object.keys(rolerightsTable[0]);
      //let templistData;
      tempData.forEach((i) => {
        if (i === value) {
          //templistData.push({ label: rolerightsTable[0][i]["displayName"], value: i });
          setSelectedValue([{ label: rolerightsTable[0][i]["displayName"], value: i }]);
        }
      });
      setTableDisplay(true);
      addmoreListparser(value);
      let tempDataa = Object.keys(rolerightsTable[0]);
      tempDataa.forEach((i) => {
        if (i === value) {
          setRoleName(rolerightsTable[0][i]["displayName"]);
        }
      });
    }
    setValue({
      ...values,
      [name]: value
    });
  };
  const tableDataPraser = (data) => {
    return (
      data &&
      data.map((i) => {
        i["group_rights"] = objectParser(i["group_rights"]);
        return i;
      })
    );
  };
  const objectParser = (data) => {
    if (typeof data === "object") {
      let tempValue = Object.keys(data);
      let returnData = [];
      tempValue.forEach((i) => {
        returnData.push({ label: data[i], value: i, checked: i === "sfa" ? false : true });
      });
      return returnData;
    } else {
      return data;
    }
  };

  const addmoreListparser = (selectedDropDownValue) => {
    let tempGroupData = JSON.parse(JSON.stringify(groupname_grouprights));
    tempGroupData = tempGroupData.map((i) => {
      i.checked = false;
      return i;
    });
    if (selectedDropDownValue) {
      let selectedData = tempGroupData;
      rolerightsTable[0][`${selectedDropDownValue}`].groupname_grouprights.forEach((i) => {
        selectedData = selectedData.filter((item) => item.group_name_attr !== i.group_name_attr);
      });
      let newUserRights = {};

      tableData[`${selectedDropDownValue}`].groupname_grouprights.forEach((i) => {
        let tempstr = ":";
        i.group_rights.forEach((j) => {
          if (j.checked) {
            tempstr = tempstr + j.value + ":";
          }
        });
        newUserRights[i.group_name_attr] = tempstr;
      });

      setMoreData(selectedData);
      setTableDisplay(true);
      setAddMoreFlag(false);
      setUserRightList(newUserRights);
    } else {
      setMoreData(tempGroupData);
      setAddMoreFlag(false);
      setTableDisplay(false);
      setUserRightList({});
    }
  };
  const onClickGroups = () => {
    setAddMoreFlag(true);
  };
  function handleCheckboxChange(e) {
    let newMoreData = moreData;
    let temptableData = JSON.parse(JSON.stringify(tableData));
    let tempUserRightList = JSON.parse(JSON.stringify(userRightList));
    if (e.target.checked) {
      temptableData[`${selectedValue[0]["value"]}`]["groupname_grouprights"].push(
        addMoreCopyData[`${e.target.value}`]
      );
      newMoreData.forEach((i) => {
        if (i.group_name_attr === e.target.value) {
          i.checked = e.target.checked;
        }
      });
      temptableData[`${selectedValue[0]["value"]}`].groupname_grouprights.forEach((i) => {
        if (i.group_name_attr === e.target.value) {
          let tempstr = ":";
          i.group_rights.forEach((j) => {
            tempstr = tempstr + j.value + ":";
          });
          tempUserRightList[i.group_name_attr] = tempstr;
        }
      });
    } else {
      temptableData[`${selectedValue[0]["value"]}`]["groupname_grouprights"] = removeByAttr(
        temptableData[`${selectedValue[0]["value"]}`]["groupname_grouprights"],
        "group_name_attr",
        e.target.value
      );
      newMoreData.forEach((i) => {
        if (i.group_name_attr === e.target.value) {
          i.checked = false;
        }
      });
      delete tempUserRightList[`${e.target.value}`];
    }
    setTableData(temptableData);
    setUserRightList(tempUserRightList);
    setMoreData(newMoreData);
  }
  const handleTableCheckBox = (e, value) => {
    const userRightsList = Object.keys(userRightList);
    let tempUserRightList = JSON.parse(JSON.stringify(userRightList));
    let newTableData = tableData;
    if (userRightsList.includes(value)) {
      tempUserRightList[`${value}`] =
        ":" + UserRightsListParser(userRightList[value], e.target.checked, e.target.value) + ":";
      newTableData[`${selectedValue[0]["value"]}`]["groupname_grouprights"].forEach((i) => {
        if (i.group_name_attr === value) {
          i.group_rights.forEach((j) => {
            if (j.value === e.target.value) {
              j.checked = e.target.checked;
            }
          });
        }
      });
      setTableData(newTableData);
    } else {
      tempUserRightList[`${value}`] = ":" + e.target.value + ":";
      newTableData[`${selectedValue[0]["value"]}`]["groupname_grouprights"].forEach((i) => {
        if (i.group_name_attr === value) {
          i.group_rights.forEach((j) => {
            if (j.value === e.target.value) {
              j.checked = true;
            }
          });
        }
      });
      setTableData(newTableData);
    }
    setUserRightList(tempUserRightList);
  };
  const UserRightsListParser = (str, flag, selectedValue) => {
    let list = str.split(":");
    let temp = "";
    list = list.filter((item) => item !== temp);
    if (flag) {
      list.push(selectedValue);
    } else {
      list = list.filter((item) => item !== selectedValue);
    }
    return list.join(":");
  };
  let res, res2;
  res = isPhoneNumberValid(Number(values.phoneNo));
  res2 = isEmailValid(values.email);
  const handleSubmit = () => {
    let payload = {
      values: values,
      selectedpractices: selectPractices,
      roleName: roleName,
      userRightList: userRightList,
      cognitoId: cognitoId
    };

    if (!enableEdit) {
      actions.addUserNewRole(payload);
      setValue(formData);
      setTableDisplay(false);
    } else {
      actions.saveEditUserDetails(payload);
      setValue(formData);
      setTableDisplay(false);
    }
  };
  function removeByAttr(arr, attr, value) {
    const filteredPeople = arr.filter((item) => {
      return item[`${attr}`] !== value;
    });
    return filteredPeople;
  }
  const backBtn = () => {
    navigate("/admin/user-tab");
  };
  const cancelBtn = () => {
    if (!enableEdit) {
      setSelectedValue([]);
      setValue(formData);
      setMoreData(groupname_grouprights);
      setSelectedPractices([]);
      setSelected([]);
      //setAddmoreCopyData({});
      setAddMoreFlag(false);
      setTableDisplay(false);
      setDefaultSelected("");
    } else {
      let tempData = Object.keys(rolerightsTable[0]);
      let templistData = [];
      let temptableData = JSON.parse(JSON.stringify(rolerightsTable[0]));
      tempData.forEach((i) => {
        templistData.push({ label: rolerightsTable[0][i]["displayName"], value: i });
        temptableData[i]["groupname_grouprights"] = tableDataPraser(
          temptableData[i]["groupname_grouprights"]
        );
      });
      let tempObj = {};
      let tempGroupName = JSON.parse(JSON.stringify(groupname_grouprights));
      tempGroupName.forEach((i) => {
        i["group_rights"] = objectParser(i["group_rights"]);
        tempObj[`${i.group_name_attr}`] = i;
      });

      setTableData(temptableData);
      setListData(templistData);
      setAddmoreCopyData(tempObj);
      proccessExitingData(templistData, temptableData, tempObj, temptableData);
      if (enableEdit && getUserDetails && Object.keys(getUserDetails).length > 0) {
        setValue({
          ...values,
          firstName: selectedUserRow.firstName,
          lastName: selectedUserRow.lastName,
          domainUser: selectedUserRow.domainUser,
          email: selectedUserRow.email,
          phoneNo: selectedUserRow.phoneNo,
          primaryRole: selectedUserRow.primaryRole,
          defaultPracticeId: selectedUserRow.defaultPracticeId

          // practiceId: selectedUserRow.practiceList
        });

        let tempPrac = [];
        selectedUserRow.practiceList?.map((i) => {
          tempPrac.push(i.practiceName);

          if (i.practiceId == selectedUserRow.defaultPracticeId) {
            setDefaultSelected(i.practiceName);
          }
        });

        setSelected(tempPrac);
        setCognitoId(selectedUserRow.cognitoUserId);
        setTableDisplay(true);
      }
    }
  };
  let validate;
  if (!enableEdit) {
    if (
      values.firstName &&
      values.lastName &&
      values.domainUser &&
      selectPractices?.length > 0 &&
      values.primaryRole &&
      values.email &&
      values.phoneNo &&
      res?.status === true &&
      res2?.status === true &&
      Object.keys(userRightList).length > 0
    ) {
      validate = true;
    } else {
      validate = false;
    }
  } else {
    if (
      values.firstName &&
      values.lastName &&
      (values.domainUser === true || values.domainUser === false)
        ? true
        : false &&
          selected?.length > 0 &&
          values.primaryRole &&
          values.email &&
          values.phoneNo &&
          res2?.status === true &&
          Object.keys(userRightList).length > 0
    ) {
      validate = true;
    } else {
      validate = false;
    }
  }
  //const classes = useStyles();
  //const isAllSelected = practices.length > 0 && practices.length === practices.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === practices.length ? [] : practices);
      return;
    }
    let tempDataa = [];
    practices.forEach((i) => {
      value.map((val) => {
        if (i.name === val) {
          tempDataa.push({ practiceId: i.practiceId });
        }
      });
    });
    setSelectedPractices(tempDataa);
    setSelected(value);
    setValue({
      ...values,
      practiceId: value
    });
  };

  console.log("selected:", selected);
  console.log("practices:", practices);

  const handleDefaultPracticeChange = (event) => {
    const value = event.target.value;
    console.log("Default practice value:", value);

    practices.forEach((i) => {
      if (i.name === value) {
        setValue({
          ...values,
          defaultPracticeId: i.practiceId
        });
        localStorage.setItem("practiceId", i.practiceId);
      }
    });

    setDefaultSelected(value);
  };
  return (
    <div>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="grid-header">
          <Typography>{enableEdit ? "Edit User Details" : "Create New User"}</Typography>
        </Stack>
        <Divider className="modal-divider" />
        <Stack className="pad-5" direction="row">
          <Grid container className="align-center" spacing={4}>
            <Grid item xs={1.5}>
              <InputLabel required sx={{ flex: 2 }}>
                First Name
              </InputLabel>
            </Grid>
            <Grid item xs={4.5}>
              <TextField
                required
                fullWidth
                name="firstName"
                size="small"
                value={values.firstName}
                onChange={handleInputChange}
                placeholder="First name"
                disabled={enableEdit}
              />
            </Grid>
            <Grid item xs={1.5}>
              {/* </Stack>
        <Stack direction="row" className="modal-stack"> */}
              <InputLabel required className="flex-2">
                Last Name
              </InputLabel>
            </Grid>
            <Grid item xs={4.5}>
              <TextField
                required
                fullWidth
                name="lastName"
                size="small"
                value={values.lastName}
                onChange={handleInputChange}
                placeholder="Last name"
                disabled={enableEdit}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack className="pad-5" direction="row">
          <Grid container className="align-center" spacing={4}>
            <Grid item xs={3}>
              <InputLabel required className="flex-2">
                Is this USFertility.com User?
              </InputLabel>
            </Grid>
            <Grid item xs={3}>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="domainUser"
                value={values.domainUser}
                onChange={(e) => handleRadioChange(e)}>
                <FormControlLabel
                  value="true"
                  disabled={enableEdit}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value="false"
                  disabled={enableEdit}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={1.5}>
              {/* </Stack>
        <Stack direction="row" className="modal-stack"> */}
              <InputLabel required className="flex-2">
                Email ID
              </InputLabel>
            </Grid>
            <Grid item xs={4.5}>
              <TextField
                fullWidth
                name="email"
                size="small"
                value={values.email}
                onChange={handleInputChange}
                placeholder="Email Id"
                disabled={enableEdit}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack className="pad-5" direction="row">
          <Grid container className="align-center" spacing={4}>
            <Grid item xs={1.5}>
              <InputLabel id="practice" required sx={{ flex: 2 }}>
                Practice
              </InputLabel>
            </Grid>
            <Grid item xs={4.5}>
              <Select
                labelId="practiceId"
                name="practiceId"
                multiple
                fullWidth
                value={selected}
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
                MenuProps={MenuProps}>
                {practices &&
                  practices.map((option) => (
                    <MenuItem key={option.name} value={option.name}>
                      <ListItemIcon>
                        <Checkbox checked={selected.indexOf(option.name) > -1} />
                      </ListItemIcon>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
              </Select>
              {/*
  <Stack className="pad-5" direction="row">
          <Grid container className="align-center" spacing={4}>
            <Grid item xs={1.5}>
              <InputLabel required sx={{ flex: 2 }}>
                Practice
              </InputLabel>
            </Grid>
            <Grid item xs={4.5}>
              <Select
                value={values.practiceId}
                required
                fullWidth
                name="practiceId"
                sx={{ flex: 6, height: 35 }}
                onChange={(e) => handleInputChange(e)}>
                {practices &&
                  practices.map((item, index) => {
                    return (
                      <MenuItem value={item.practiceId} key={index}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid>
            
          </Grid>
        </Stack>
 */}
            </Grid>
            <Grid item xs={1.8}>
              {/* </Stack>
        <Stack direction="row" className="modal-stack"> */}
              <InputLabel required className="flex-2">
                Phone Number
              </InputLabel>
            </Grid>
            <Grid item xs={4.2}>
              <TextField
                name="phoneNo"
                fullWidth
                size="small"
                inputProps={{ pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}" }}
                value={values.phoneNo}
                onChange={handleInputChange}
                placeholder="Phone Number"
                disabled={enableEdit}
                helperText="1234567890"
              />
            </Grid>
            <Grid item xs={1.5}>
              <InputLabel id="defaultPrac" sx={{ flex: 2 }}>
                Default Practice
              </InputLabel>
            </Grid>
            <Grid item xs={4.5}>
              <Select
                fullWidth
                name="defaultPractice"
                id="defaultPractice"
                value={defaultSelected}
                onChange={(e) => handleDefaultPracticeChange(e)}>
                {selected?.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Stack>
        <Box className="create-user-role-section">
          <Stack direction="row" className="modal-stack">
            <Grid container className="align-center" spacing={4}>
              <Grid item xs={1.4}>
                <InputLabel required>Roles</InputLabel>
              </Grid>
              <Grid item xs={4.6}>
                <Select
                  value={selectedValue?.[0] ? `${selectedValue[0]["value"]}` : ""}
                  required
                  fullWidth
                  id="primaryRole"
                  name="primaryRole"
                  sx={{ height: 35, marginLeft: "5px" }}
                  onChange={(e) => handleInputChange(e)}>
                  {listData?.map((i, index) => {
                    return (
                      <MenuItem key={index} value={i.value}>
                        {i.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>
          </Stack>
          {/* <Stack direction="row" spacing={2} className="modal-bottom-section">
            <> */}
          {selectedValue !== "" && selectedValue?.length > 0 && (
            <Stack direction="row" className="modal-stack">
              <Grid container className="align-center" spacing={2}>
                <Grid item xs={6}>
                  <InputLabel>User Rights</InputLabel>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <>
                    {selectedValue !== "" && (
                      <Button
                        type="submit"
                        variant="contained"
                        className="btn-usf"
                        disabled={moreData.length > 0 ? false : true}
                        onClick={onClickGroups}>
                        Add New Rights
                      </Button>
                    )}
                  </>
                </Grid>
              </Grid>
            </Stack>
          )}
          {/* </> */}
          {/* </Stack> */}
          {addMoreFlag && moreData && (
            <Stack direction="row" spacing={2} className="modal-bottom-section">
              {addMoreFlag && moreData.length > 0 && (
                <InputLabel className="flex-2">More Rights</InputLabel>
              )}
              <div style={{ paddingTop: "10px" }}>
                {addMoreFlag &&
                  moreData.length > 0 &&
                  moreData.map((moreRights) => {
                    return (
                      <>
                        <Checkbox
                          id={moreRights.group_name_display}
                          name={moreRights.group_name_display}
                          value={moreRights.group_name_attr}
                          onClick={handleCheckboxChange}
                          checked={moreRights.checked}
                        />
                        <label>{moreRights.group_name_display}</label>
                      </>
                    );
                  })}
              </div>
            </Stack>
          )}
          <Stack direction="row" spacing={2} className="modal-bottom-section">
            <>
              {tableDisplay && (
                <UserRightsTable
                  tableHeader={tableHeader}
                  selectedValue={selectedValue}
                  data={tableData}
                  groupnames={groupnames}
                  handleTableCheckBox={handleTableCheckBox}
                />
              )}
            </>
          </Stack>
        </Box>
        <Stack direction="row" spacing={2} className="modal-stack">
          <Button variant="outlined" className="btn-primary" onClick={backBtn}>
            Back
          </Button>
          <Stack direction="row" spacing={2} className="modal-bottom-section">
            <Button variant="outlined" className="btn-primary" onClick={cancelBtn}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!validate}
              className="btn-usf"
              onClick={handleSubmit}>
              <Typography>{enableEdit ? "Update" : "Create"}</Typography>
            </Button>
          </Stack>
        </Stack>
        <MessageModal
          open={isMessageModal}
          message={popMessageInfo}
          closeModal={closeMessageModal}
        />
      </Box>
      <LoadingModal open={loading || getUserLoading} />
    </div>
  );
};
const mapStateToProps = ({ addNewUserReducer }) => {
  return {
    loading: addNewUserReducer.loading,
    addSuccess: addNewUserReducer.success,
    selectedUserRow: addNewUserReducer.selectedUser,
    enableEdit: addNewUserReducer.enableEdit,
    addError: addNewUserReducer.error,
    getUserDetails: addNewUserReducer.getUserDetails,
    editDetailsSuccess: addNewUserReducer.saveedit_success,
    editDetailsFail: addNewUserReducer.saveedit_error,
    editDetailsLoading: addNewUserReducer.saveedit_loading,
    getUserLoading: addNewUserReducer.getUserLoading_loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    addUserNewRole: (payload) => {
      dispatch(actionType.addUserNewRole(payload));
    },
    resetNewUser: () => {
      dispatch(actionType.resetNewUser());
    },
    saveEditUserDetails: (payload) => {
      dispatch(actionType.saveEditUserDetails(payload));
    },
    resetEditUser: () => {
      dispatch(actionType.resetEditUser());
    },
    resetFetchDetails: () => {
      dispatch(actionType.resetFetchDetails());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewUser);
