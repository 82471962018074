import React, { useCallback, useEffect, useState } from "react";
import * as actionType from "../actions";
import { connect, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Select,
  Divider,
  Stack
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TreatmentPlanModal from "./modals/TreatmentPlanModal";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { treatmentPlanDetailsConfig } from "../../shared/constants/renderConfigs";
import { v4 as uuidv4 } from "uuid";
import EditStagesTreatmentPlan from "./modals/EditStagesTreatmentPlan";
import DeleteModal from "../../shared/components/DeleteModal";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import WarnningMessageModal from "../../shared/components/WarnningMessageModal";
import AccordianWDataGrid from "./AccordianWDataGrid";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const TreatmentPlanDetails = (props) => {
  let treatmentPlanFormData = {
    treatmentPlanName: ""
  };
  const disabled = localStorage.getItem("status") !== "In-progress" ? true : false;
  const navigate = useNavigate();
  const disaptch = useDispatch();
  const {
    actions,
    treatmentPlanDetailList,
    editTreatmentPlan,
    treatmentPlanQuestions = [],
    treatmentPlanAddQuestionOption = [],
    treatmentPlanQuestionSelection = [],
    treatmentPlanRoot = [],
    treatmentAddOn = [],
    treatmentModifier = [],
    treatmentMonitoring = [],
    treatmentAssist = [],
    treatmentAHM = [],
    treatmentPlan = [],
    stages = [],
    stagesIncluded = [],
    stagesNecessary = [],
    stagesOptional = [],
    addStagesTreatmentPlan = [],
    editStagesTP = [],
    addTreatmentPlanQuestionList = [],
    addTreatmentPlan,
    deleteTreatmentPlanQuestion,
    loading,
    upsertLoading,
    success,
    error,
    isTreatmentPlanQuestionFetch,
    isRootQuestionFetch,
    isTreatmentPlanQuestionSelectionFetch,
    isAddOnQuestionFetch,
    isModifierQuestionFetch,
    isMonitoringQuestionFetch,
    isAssistQuestionFetch,
    isAHMQuestionFetch
  } = props;
  useEffect(() => {
    if (treatmentPlanRoot[0]?.responses[0]?.responseNo !== undefined)
      setTreatmentTypeId(treatmentPlanRoot[0]?.responses[0]?.responseNo);
    else setTreatmentTypeId("select_type");
  }, [treatmentPlanRoot]);
  const [stageDeleteId, setStageDelete] = useState("");

  let treatmentRowData;
  let { treatmentPlanId } = useParams();
  const { state } = useLocation();

  const [isSaved, setIsSaved] = useState(state?.saved);
  const [treatmentPlanId1, setTreatmentPlanId] = useState("");
  const accordionSx = { bgcolor: "#ededed", m: 2 };
  //const [response, setResponse] = useState("");
  const [openModal, setModalOpen] = useState({});
  const [modalType, setModalType] = useState("");
  const [refData, setRefData] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(treatmentPlanFormData);
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [treatmentData, setTreatmentData] = useState(treatmentRowData);
  const [isStageOpen, setStageEditOpen] = useState(false);
  const [refEditStages, setEditStages] = useState("");
  const [stagesCategory, setStageCategory] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [isWarningOpen, setWarningModal] = useState(false);
  const [warnMsg, setWarningMessage] = useState(false);

  const [questionDeleteId, setQuestionDelete] = useState("");
  const [otherData, setOtherData] = useState({});
  const [rootQuestionSelectionData, setRootSelectionData] = useState({});
  const [stagesCreateData, setCreateStagesData] = useState("");
  const practiceId = sessionStorage.getItem("practiceId");
  const gdmId = sessionStorage.getItem("gdmId");
  const enableFunc = stagesIncluded?.length > 0 ? false : true;
  treatmentPlanId = treatmentPlanId1 ? treatmentPlanId1 : treatmentPlanId;
  const [treatmentTypeId, setTreatmentTypeId] = useState("select_type");
  const [isUpsert, setIsUpsert] = useState(false);
  const [isSaveButtonDisable, setSaveButtonDisable] = useState(false);

  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });

  const initFetch = useCallback(() => {
    //if (treatmentPlanId !== "" && treatmentPlanId !== undefined) {
    if (isSaved) {
      setSaveButtonDisable(true);
      //let payload = { practiceId, gdmId, treatmentPlanId1 };
      let payloadInc = { practiceId, gdmId, treatmentPlanId, category: "Included" };
      let payloadNec = { practiceId, gdmId, treatmentPlanId, category: "Necessary" };
      let payloadOpt = { practiceId, gdmId, treatmentPlanId, category: "Optional" };

      actions.getTreatmentPlanStageIncluded(payloadInc);
      actions.getTpStagesNecessary(payloadNec);
      actions.getTpStagesOptional(payloadOpt);
      const payload = { practiceId, gdmId, treatmentPlanId };
      actions.getTreatmentPlan(payload);
      Promise.all([
        actions.getTreatmentPlanQuestions(payload),
        actions.getTreatmentPlanRoot(payload),
        actions.getTreatmentPlanQuestionSelection(payload),
        actions.getTreatmentPlanAddOn(payload),
        actions.getTreatmentPlanModifier(payload),
        actions.getTreatmentPlanMonitoring(payload),
        actions.getTreatmentPlanAssist(payload),
        actions.getTreatmentPlanAHM(payload)
      ])
        .then(() => {
          //console.log("===>", treatmentPlanQuestions, treatmentPlanRoot, c, d, e, f, g, h);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  }, [deleteTreatmentPlanQuestion, addStagesTreatmentPlan, editTreatmentPlan]);

  useEffect(() => {
    setIsAdd(true);
    if (success || error) {
      setIsMessageModal(true);
    }
    if (treatmentPlan.length == 0) {
      const payload = {
        practiceId,
        gdmId
      };
      actions.getTreatmentPlan(payload);
    }
    //}
    if (
      //stagesIncluded.length == 0 ||
      addStagesTreatmentPlan ||
      editStagesTP ||
      addTreatmentPlanQuestionList ||
      deleteTreatmentPlanQuestion ||
      editTreatmentPlan
    ) {
      //if (addStagesTreatmentPlan) {
      setIsSaved(true);
      //}

      initFetch();
    } else {
      //initFetch();
    }
    return () => {
      actions.resetTreatmentPlanList();
    };
  }, [
    addStagesTreatmentPlan,
    addTreatmentPlanQuestionList,
    deleteTreatmentPlanQuestion,
    editStagesTP,
    success,
    error,
    treatmentPlanId1
  ]);

  useEffect(() => {
    if (addTreatmentPlan != null) {
      setTreatmentPlanId(addTreatmentPlan.treatmentPlanId);

      let saveData = {
        globalPrice: stagesCreateData.stageData[0].globalPrice,
        stagePassthrough: stagesCreateData.stageData[0].stagePassthrough
      };
      let payload = {};
      payload.category = stagesCategory; ////data.stageData[0].stageCategory;
      payload.gdmId = gdmId;
      payload.treatmentPlanId = addTreatmentPlan.treatmentPlanId;
      payload.practiceId = practiceId;
      payload.stageId = stagesCreateData.stageData[0].stageId;
      payload.saveData = saveData;
      actions.addNewStagesTreatmentPlan(payload);
    }

    return () => {
      actions.resetTreatmentPlanList();
    };
  }, [addTreatmentPlan]);
  const columnsStagesNecessary = [
    {
      field: "srNo",
      headerName: "Sr.No",
      width: 100,
      editable: true,
      flex: 0.5
    },
    {
      field: "stageCode",
      headerName: "STAGE CODE/SIM GROUP",
      width: 100,
      flex: 1
    },
    {
      field: "sortStage",
      headerName: "Sort Order",
      width: 100,
      flex: 0.6
    },
    {
      field: "range",
      headerName: "IS RANGE",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.range) {
          return <CheckCircleOutlineIcon style={{ fill: "#1BA3AD" }}></CheckCircleOutlineIcon>;
        } else {
          return <CheckCircleOutlineIcon style={{ fill: "grey" }}></CheckCircleOutlineIcon>;
        }
      }
    },
    {
      field: "stageName",
      headerName: "DESCRIPTION",
      width: 50,
      editable: false,
      flex: 0.6
    },
    {
      field: "totalPrice",
      headerName: "TOTAL STAGE PRICE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "globalPrice",
      headerName: "GLOBAL STAGE PRICE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "assistPrice",
      headerName: "ASSIST STAGE PRICE",
      width: 50,
      flex: 0.5
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.6,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              setDeleteModal(true);
              setStageDelete(row.treatmentPlanStageId);
              setStageCategory("Necessary");
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"}></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];
  const columnsStagesOptional = [
    {
      field: "srNo",
      headerName: "Sr.No",
      width: 100,
      editable: true,
      flex: 0.5
    },
    {
      field: "stageCode",
      headerName: "STAGE CODE/SIM GROUP",
      width: 100,
      flex: 1
    },
    {
      field: "sortStage",
      headerName: "Sort Order",
      width: 100,
      flex: 0.6
    },
    {
      field: "range",
      headerName: "IS RANGE",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => {
        if (row.range) {
          return <CheckCircleOutlineIcon style={{ fill: "#1BA3AD" }}></CheckCircleOutlineIcon>;
        } else {
          return <CheckCircleOutlineIcon style={{ fill: "grey" }}></CheckCircleOutlineIcon>;
        }
      }
    },
    {
      field: "stageName",
      headerName: "DESCRIPTION",
      width: 50,
      editable: false,
      flex: 0.6
    },
    {
      field: "totalPrice",
      headerName: "TOTAL STAGE PRICE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "globalPrice",
      headerName: "GLOBAL STAGE PRICE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "assistPrice",
      headerName: "ASSIST STAGE PRICE",
      width: 50,
      flex: 0.5
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.6,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              setDeleteModal(true);
              setStageDelete(row.treatmentPlanStageId);
              setStageCategory("Optional");
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"}></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];
  const columnsStagesIncluded = [
    {
      field: "srNo",
      headerName: "Sr.No",
      width: 100,
      editable: true,
      flex: 0.5
    },
    {
      field: "stageCode",
      headerName: "STAGE CODE/SIM GROUP",
      width: 100,
      flex: 1
    },
    {
      field: "sortStage",
      headerName: "Sort Order",
      width: 100,
      flex: 0.6
    },
    {
      field: "stageName",
      headerName: "DESCRIPTION",
      width: 50,
      editable: false,
      flex: 0.6
    },
    {
      field: "totalPrice",
      headerName: "TOTAL STAGE PRICE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "globalPrice",
      headerName: "GLOBAL STAGE PRICE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "assistPrice",
      headerName: "ASSIST STAGE PRICE",
      width: 50,
      flex: 0.5
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.6,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              setEditStages(row);
              setIsAdd(false);
              setStageEditOpen(true);
              setStageCategory("Included");
            }}>
            <EditIcon color={disabled ? "disabled" : "primary"}></EditIcon>
          </IconButton>
          <IconButton
            disabled={disabled}
            onClick={() => {
              stagesIncluded.length > 1 ? setDeleteModal(true) : setWarningModal(true);
              setWarningMessage("Last stage cannot be deleted");
              setStageCategory("Included");
              setStageDelete(row.treatmentPlanStageId);
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"}></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];

  const columnsQuestions = [
    {
      field: "questionId",
      headerName: "#",
      width: 100,
      flex: 1
    },
    {
      field: "questionText",
      headerName: "QUESTION TEXT",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "responses",
      headerName: "RESPONSE TEXT",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            <Select fullWidth value={treatmentTypeId} onChange={(e) => handleChange(e, row)}>
              <MenuItem key={"0"} value={"select_type"}>
                Select Type
              </MenuItem>
              {row.responses.map((x) => (
                <MenuItem key={x.responseNo} value={x.responseNo}>
                  {x.responsetext}
                </MenuItem>
              ))}
            </Select>
          </>
        );
      }
    }
  ];

  const columnsPrimaryQuestions = [
    {
      field: "questionId",
      headerName: "#",
      width: 100,
      flex: 1
    },
    {
      field: "questionText",
      headerName: "QUESTION TEXT",
      width: 100,
      editable: false,
      flex: 3
    },
    {
      field: "responses",
      headerName: "RESPONSE TEXT",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => <Typography>{row.responses[0]?.responsetext}</Typography>
    },
    {
      field: "addSubQuestion",
      headerName: "ADD SUB-QUESTION",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              handleAddSubQuestion(row, "primary");
            }}>
            <AddCircleOutlineIcon color={disabled ? "disabled" : "primary"} variant="outlined" />
          </IconButton>
        </>
      )
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 100,
      editable: false,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              setDeleteModal(true);
              setQuestionDelete(row.questionId);
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"}></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];

  const columnsOtherQuestions = [
    {
      field: "questionId",
      headerName: "#",
      width: 100,
      flex: 1
    },
    {
      field: "questionText",
      headerName: "QUESTION TEXT",
      width: 100,
      editable: false,
      flex: 2
    },
    {
      field: "responses",
      headerName: "RESPONSE TEXT",
      width: 100,
      editable: false,
      flex: 2,
      renderCell: ({ row }) => (
        <Typography>{row.responses.map((item) => item.responsetext).join(", ")}</Typography>
      )
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 100,
      editable: false,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              setDeleteModal(true);
              setQuestionDelete(row.questionId);
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"}></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];

  const columnsPrimaryQuestionsPGT = [
    {
      field: "questionId",
      headerName: "#",
      width: 100,
      flex: 1
    },
    {
      field: "questionText",
      headerName: "QUESTION TEXT",
      width: 100,
      editable: false,
      flex: 3
    },
    {
      field: "responses",
      headerName: "RESPONSE TEXT",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Typography>{row.responses.map((item) => item.responsetext).join(", ")}</Typography>
      )
    },
    {
      field: "addSubQuestion",
      headerName: "ADD SUB-QUESTION",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              handleAddSubQuestion(row, "pgta");
            }}>
            <AddCircleOutlineIcon color={disabled ? "disabled" : "primary"} variant="outlined" />
          </IconButton>
        </>
      )
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 100,
      editable: false,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              setDeleteModal(true);
              setQuestionDelete(row.questionId);
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"}></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];

  useEffect(() => {
    treatmentPlan.forEach((item) => {
      if (item.treatmentPlanId == treatmentPlanId) {
        treatmentRowData = {
          treatmentId: item?.treatmentPlanId,
          treatmentName: item?.treatmentPlanName,
          treatmentInternalName: item?.treatmentPlanInternalName,
          totalTreatmentPrice: item?.totalPrice,
          globalTreatmentPrice: item?.globalPrice,
          globalAssistPrice: item?.assistPrice
        };
      }
    });
  }, [treatmentPlan]);
  useEffect(() => {
    if (treatmentPlan.length == 0) {
      actions.getTreatmentPlan(practiceId);
    }
    actions.resetTreatmentPlanList();
    if (treatmentRowData) {
      setTreatmentData(treatmentRowData);
    }
    let payload = {};
    payload.practiceId = practiceId;
    payload.gdmId = gdmId;
    actions.getStages(payload);
  }, []);

  const storeTreatmentPlanQuestionsRes = (data) => {
    let newData = JSON.parse(JSON.stringify(data.treatmentData));
    newData.responses = newData.responses.filter((v) => v.responseNo == data.responseNo);
    newData.selectedResponse = data.responseNo;

    if (
      data.refData &&
      data.refData.category &&
      ["Primary", "primary"].indexOf(data.refData.category) != -1
    ) {
      disaptch(
        actionType.refreshTreatmentPlanQuestionSelectionList([
          ...treatmentPlanQuestionSelection,
          newData
        ])
      );
    } else if (data.refData && data.refData.category && data.refData.category === "Add-on") {
      disaptch(actionType.refreshTreatmentPlanQuestionAddOnList([...treatmentAddOn, newData]));
    } else if (data.refData && data.refData.category && data.refData.category === "Modifier") {
      disaptch(
        actionType.refreshTreatmentPlanQuestionModifierList([...treatmentModifier, newData])
      );
    } else if (data.refData && data.refData.category && data.refData.category === "Monitoring") {
      disaptch(
        actionType.refreshTreatmentPlanQuestionMonitoringList([...treatmentMonitoring, newData])
      );
    } else if (data.refData && data.refData.category && data.refData.category === "Assist") {
      disaptch(actionType.refreshTreatmentPlanQuestionAssistList([...treatmentAssist, newData]));
    } else if (data.refData && data.refData.category && data.refData.category === "pgta") {
      disaptch(actionType.refreshTreatmentPlanQuestionAHMList([...treatmentAHM, newData]));
    } else {
      // newData.selectedResponse = treatmentTypeId;
      newData.responses = data.treatmentData.responses;
      disaptch(actionType.refreshTreatmentPlanQuestions([newData]));
      disaptch(actionType.refreshTreatmentPlanQuestionSelectionList([]));
      disaptch(actionType.refreshTreatmentPlanQuestionAddOnList([]));
      disaptch(actionType.refreshTreatmentPlanQuestionModifierList([]));
      disaptch(actionType.refreshTreatmentPlanQuestionMonitoringList([]));
      disaptch(actionType.refreshTreatmentPlanQuestionAssistList([]));
      disaptch(actionType.refreshTreatmentPlanQuestionAHMList([]));
    }
  };

  const deleteTreatmentPlanQuestionsRes = (data) => {
    disaptch(
      actionType.refreshTreatmentPlanQuestionSelectionList(
        treatmentPlanQuestionSelection.filter((obj) => {
          return obj.questionId !== data.questionId;
        })
      )
    );
    disaptch(
      actionType.refreshTreatmentPlanQuestionAddOnList(
        treatmentAddOn.filter((obj) => {
          return obj.questionId !== data.questionId;
        })
      )
    );
    disaptch(
      actionType.refreshTreatmentPlanQuestionModifierList(
        treatmentModifier.filter((obj) => {
          return obj.questionId !== data.questionId;
        })
      )
    );
    disaptch(
      actionType.refreshTreatmentPlanQuestionMonitoringList(
        treatmentMonitoring.filter((obj) => {
          return obj.questionId !== data.questionId;
        })
      )
    );
    disaptch(
      actionType.refreshTreatmentPlanQuestionAssistList(
        treatmentAssist.filter((obj) => {
          return obj.questionId !== data.questionId;
        })
      )
    );
    disaptch(
      actionType.refreshTreatmentPlanQuestionAHMList(
        treatmentAHM.filter((obj) => {
          return obj.questionId !== data.questionId;
        })
      )
    );
  };

  const handleChange = (e, row) => {
    let data = {
      questionId: row.questionId,
      responseNo: e.target.value
    };

    setTreatmentTypeId(e.target.value);
    setRootSelectionData(data);

    //if (treatmentPlanId1 !== "" && treatmentPlanId1 !== undefined) {
    let payload = {
      treatmentPlanId: treatmentPlanId,
      practiceId: practiceId,
      gdmId: gdmId,
      questionId: row.questionId,
      responseNo: e.target.value
    };
    // setQuestionId(row.questionId);
    if (e.target.value !== "select_type") {
      payload.treatmentData = row;
      // treatmentPlanId !== undefined && treatmentPlanId && actions.addQuestionTreatmentPlan(payload);
      treatmentPlanId !== undefined && treatmentPlanId && storeTreatmentPlanQuestionsRes(payload);
    }
    //}
  };

  const handleAddStage = (e, category, type) => {
    e.preventDefault();
    e.stopPropagation();
    let x = {
      open: true,
      type: type
    };
    setModalOpen(x);
    setRefData(stages);
    setModalType(category);
    if (category === "Stages Included") setStageCategory("Included");
    else if (category === "Necessary but not included") setStageCategory("Necessary");
    else if (category === "Optional/May become necessary") setStageCategory("Optional");
  };

  useEffect(() => {
    setRefData(treatmentPlanAddQuestionOption);
  }, [treatmentPlanAddQuestionOption]);
  const handleAddQuestion = (e, category, type) => {
    setStageCategory("Question");

    let x = {
      open: true,
      type: type,
      category: category === "pgta" ? "AHM" : category
    };
    setModalOpen(x);
    let payload = {
      treatmentPlanId: treatmentPlanId,
      practiceId: practiceId,
      gdmId: gdmId,
      category: category,
      responseNo: treatmentTypeId,
      questionId: 1
    };
    actions.getTreatmentPlanAddQuestionOption(payload);
    setOtherData(payload);
    setModalType("addQuestion");
  };
  const handleAddSubQuestion = (row, type) => {
    setStageCategory("Question");
    let x = {
      open: true,
      type: "Question",
      question: row.questionText
    };
    console.log("row", row);
    let responseNo = row.responses[0].responseNo;
    if (type === "pgta") {
      let newArr = row.responses.filter((rData) => {
        return rData.responsetext.toLowerCase() === "yes";
      });

      console.log("newArr", newArr);

      responseNo =
        (newArr && newArr.length > 0 && newArr[0].responseNo) || row.responses[0].responseNo;
    }
    setModalOpen(x);
    let payload = {
      treatmentPlanId: treatmentPlanId,
      practiceId: practiceId,
      gdmId: gdmId,
      category: type,
      responseNo: responseNo,
      questionId: row.questionId
    };
    actions.getTreatmentPlanAddQuestionOption(payload);
    setOtherData(payload);
    setModalType("addSubQuestion");
  };
  const closeModal = () => {
    setIsOpen(false);
    setModalOpen(false);
    setModalType("");
  };
  const saveTreatmentPlan = (event) => {
    event.preventDefault();
    let payload = {};
    payload.treatmentPlanId = treatmentPlanId;
    payload.practiceId = practiceId;
    payload.gdmId = gdmId;

    if (isAdd) {
      actions.addNewTreatmentPlan(payload);
    } else {
      payload.treatmentPlan = values.treatmentName;
      //treatmentPlanInternalName: values.treatmentInternalName

      actions.editTreatmentPlan(payload);
    }
    closeModal();
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1)
    }
  }));

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const treatmentPlanDetails = treatmentPlanDetailsConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isOpen
  });

  const createStages = (data) => {
    setCreateStagesData(data);
    setModalOpen(false);
    if (stagesIncluded.length == 0) {
      let payload = { practiceId: practiceId, gdmId: gdmId, treatmentPlan: treatmentPlanId };
      actions.addNewTreatmentPlan(payload);
    } else if (data.stageData?.length > 0) {
      let saveData = {
        globalPrice: data.stageData[0].globalPrice,
        stagePassthrough: data.stageData[0].stagePassthrough
      };
      let payload = {};
      payload.category = stagesCategory; ////data.stageData[0].stageCategory;
      payload.gdmId = gdmId;
      payload.treatmentPlanId = treatmentPlanId;
      payload.practiceId = practiceId;
      payload.stageId = data.stageData[0].stageId;
      payload.saveData = saveData;
      actions.addNewStagesTreatmentPlan(payload);
    }
  };

  const editStages = (data) => {
    setStageEditOpen(false);
    let saveData = {
      globalPrice: data.globalPrice,
      stagePassthrough: data.stageData.stagePassthrough
    };
    let payload = {};
    payload.category = data.category;
    payload.gdmId = gdmId;
    payload.treatmentPlanId = treatmentPlanId;
    payload.practiceId = practiceId;
    payload.tPStageId = data.stageData.treatmentPlanStageId;
    payload.saveData = saveData;

    actions.editStageTreatmentPlan(payload);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    let payload = {
      practiceId: practiceId,
      tpStageId: stageDeleteId,
      questionId: questionDeleteId,
      treatmentPlanId: treatmentPlanId,
      gdmId
    };
    if (
      stagesCategory == "Included" ||
      stagesCategory == "Optional" ||
      stagesCategory == "Necessary"
    ) {
      actions.deleteTpStage(payload);
    } else {
      deleteTreatmentPlanQuestionsRes(payload);
      // actions.deleteTreatmentPlanQuestion(payload);
    }
    closeDeleteModal();
  };
  const closeEditModal = () => {
    setStageEditOpen(false);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
    if (treatmentPlanDetailList?.length == 0) {
      navigateToTreatmentPlan();
    }
  };
  const closeWarningModal = () => {
    setWarningModal(false);
    actions.resetMessage();
  };

  const isSaveTreatmentPlanButtonDisable = () => {
    let isdisable = true;

    if (
      isTreatmentPlanQuestionFetch &&
      isRootQuestionFetch &&
      isTreatmentPlanQuestionSelectionFetch &&
      isAddOnQuestionFetch &&
      isModifierQuestionFetch &&
      isMonitoringQuestionFetch &&
      isAssistQuestionFetch &&
      isAHMQuestionFetch
    ) {
      isdisable = false;
    }

    if (isSaveButtonDisable === true && isdisable === true) {
      return true;
    } else {
      return false;
    }
  };

  const navigateToTreatmentPlan = async () => {
    let payload = {};
    payload.treatmentPlanId = treatmentPlanId;
    payload.practiceId = practiceId;
    payload.gdmId = gdmId;
    console.log("isSaved", isSaved);

    // treatmentTypeId;
    let treatmentPlanQuestions1 = treatmentPlanQuestions.map((v) => {
      v.selectedResponse = treatmentTypeId;
      return v;
    });

    payload.questions = await [
      ...treatmentPlanQuestions1,
      ...treatmentPlanQuestionSelection,
      ...treatmentAddOn,
      ...treatmentModifier,
      ...treatmentMonitoring,
      ...treatmentAssist,
      ...treatmentAHM
    ].map((val) => {
      let arr = [];

      if (val.selectedResponse && val.selectedResponse !== "select_type") {
        arr.push({ responseNo: val.selectedResponse });
      } else {
        val.responses.forEach((z) => {
          if (z.responseNo !== "select_type") {
            arr.push({ responseNo: z.responseNo });
          }
        });
      }

      let result = {
        questionId: val.questionId,
        responses: arr
      };

      return result;
    });

    setIsUpsert(true);

    actions.upsertTreatmentPlan(payload);

    // temprary stop redirect for testing
    // if (stagesIncluded.length > 0) navigate("/admin/gdm/treatmentPlan");
  };

  useEffect(() => {
    if (isUpsert && !upsertLoading && success && !error) {
      setIsUpsert(false);
      if (stagesIncluded.length > 0) navigate("/admin/gdm/treatmentPlan");
    }
  }, [upsertLoading]);

  const handleTreatmentPlanDetailsEdit = () => {
    setIsAdd(false);
    setValues({
      treatmentName: treatmentData?.treatmentName,
      treatmentInternalName: treatmentData?.treatmentInternalName,
      globalTreatmentPrice: treatmentData?.globalTreatmentPrice,
      assitTreatmentPrice: treatmentData?.globalAssistPrice,
      totalTreatmentPrice: treatmentData?.globaltotalPrice
    });
    setIsOpen(true);
  };

  useEffect(() => {
    if (props.addQuestionError) {
      setWarningMessage(props.addQuestionError);
      setWarningModal(true);
    }
  }, [props.addQuestionError]);

  return (
    <Box mx={1}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography className="m-l-10">{treatmentPlanId}</Typography>
          <Typography>{treatmentData?.treatmentPlanId}</Typography>

          <Button
            variant="contained"
            //disabled={enableFunc || disabled}
            disabled={isSaveTreatmentPlanButtonDisable() || enableFunc || disabled}
            className="m-r-10"
            onClick={navigateToTreatmentPlan}>
            Save Treatment Plan
          </Button>
        </Stack>
      </Box>
      <Box className="stage-details">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography>{treatmentData?.treatmentPlanId}</Typography>
            <Typography variant="body2">Details</Typography>
          </Stack>
          <Button
            onClick={handleTreatmentPlanDetailsEdit}
            variant="contained"
            disabled={enableFunc || disabled}
            className="btn-primary btn-edit-practice">
            Edit
          </Button>
        </Stack>

        <Grid container gap={2}>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={3} my={1}>
                <Typography variant="body1">Treatment Name:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">
                  {treatmentData?.treatmentPlanCustomName != null
                    ? treatmentData?.treatmentPlanCustomName
                    : treatmentData?.treatmentName}
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={3} my={1}>
                <Typography variant="body1">Global Treatment Price:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">$ {treatmentData?.globalTreatmentPrice}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={5} my={1}>
                <Typography variant="body1">Treatment Internal Name:</Typography>
              </Grid>
              <Grid item xs={3} my={1}>
                <Typography variant="body2">{treatmentData?.treatmentInternalName}</Typography>
              </Grid>
            </Grid>
            <Stack direction="row" className="m-t-10">
              <Grid item xs={5}>
                <Typography variant="body1">Assist Treatment Price:</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">$ {treatmentData?.globalAssistPrice}</Typography>
              </Grid>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <AccordianWDataGrid
        handleAdd={(e) => handleAddStage(e, "Stages Included", "Stages")}
        rows={stagesIncluded}
        loading={loading}
        columns={columnsStagesIncluded}
        btnName="Add Stage"
        accordianName="Stages Included"
        disabled={disabled}
      />

      <AccordianWDataGrid
        handleAdd={(e) => handleAddStage(e, "Necessary but not included", "Stages")}
        rows={stagesNecessary}
        columns={columnsStagesNecessary}
        btnName="Add Stage"
        accordianName="Necessary but not included"
        disabled={enableFunc || disabled}
      />

      <AccordianWDataGrid
        handleAdd={(e) => handleAddStage(e, "Optional/May become necessary", "Stages")}
        rows={stagesOptional}
        columns={columnsStagesOptional}
        btnName="Add Stage"
        accordianName="Optional/May become necessary"
        disabled={enableFunc || disabled}
      />

      <Typography sx={{ m: 2 }}>Questions</Typography>

      <Accordion sx={accordionSx}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header">
          <Typography>
            Treatment Type (
            {treatmentPlanRoot && treatmentPlanRoot.length > 0
              ? treatmentPlanRoot.length
              : treatmentPlanQuestions.length}
            )
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <TableContainer component={Paper}>
            <Box className="grid-styles">
              <DataGridPremiumComp
                rows={treatmentPlanQuestions}
                rowCount={treatmentPlanQuestions?.length}
                getRowId={() => uuidv4()}
                loading={loading}
                columns={columnsQuestions}
                pageSizeOptions={[10, 25, 50, 100]}
                pageSize={pageSize.pageSize}
                paginationModel={pageSize}
                onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
                pagination
              />
            </Box>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
      <AccordianWDataGrid
        handleAdd={(e) => handleAddQuestion(e, "Primary", "Question")}
        rows={treatmentPlanQuestionSelection}
        columns={columnsPrimaryQuestions}
        btnName="Add Question"
        accordianName="Primary Questions"
        disabled={enableFunc || disabled}
      />

      <AccordianWDataGrid
        handleAdd={(e) => handleAddQuestion(e, "Add-on", "Question")}
        rows={treatmentAddOn}
        columns={columnsOtherQuestions}
        btnName="Add Question"
        accordianName="Add On"
        disabled={enableFunc || disabled}
      />

      <AccordianWDataGrid
        handleAdd={(e) => handleAddQuestion(e, "Modifier", "Question")}
        rows={treatmentModifier}
        columns={columnsOtherQuestions}
        btnName="Add Question"
        accordianName="Add Modifier"
        disabled={enableFunc || disabled}
      />

      <AccordianWDataGrid
        handleAdd={(e) => handleAddQuestion(e, "Monitoring", "Question")}
        rows={treatmentMonitoring}
        columns={columnsOtherQuestions}
        btnName="Add Question"
        accordianName="Remove Monitoring"
        disabled={enableFunc || disabled}
      />

      <AccordianWDataGrid
        handleAdd={(e) => handleAddQuestion(e, "Assist", "Question")}
        rows={treatmentAssist}
        columns={columnsOtherQuestions}
        btnName="Add Question"
        accordianName="Add Assist"
        disabled={enableFunc || disabled}
      />

      <AccordianWDataGrid
        handleAdd={(e) => handleAddQuestion(e, "pgta", "Question")}
        rows={treatmentAHM}
        columns={columnsPrimaryQuestionsPGT}
        btnName="Add Question"
        accordianName="Add AMH"
        disabled={enableFunc || disabled}
      />

      <TreatmentPlanModal
        open={openModal}
        setModalOpen={setModalOpen}
        storeTreatmentPlanQuestionsRes={storeTreatmentPlanQuestionsRes}
        data={refData}
        mainQuestionData={rootQuestionSelectionData}
        otherData={otherData}
        modalType={modalType}
        category={stagesCategory}
        closeModal={closeModal}
        createStages={createStages}
      />
      <EditStagesTreatmentPlan
        open={isStageOpen}
        data={refEditStages}
        category={stagesCategory}
        closeModal={closeEditModal}
        editStages={editStages}></EditStagesTreatmentPlan>
      <MessageModal
        open={isMessageModal}
        message={success}
        error={error}
        closeModal={closeMessageModal}
      />
      <CommonModal open={isOpen} config={treatmentPlanDetails} handleSubmit={saveTreatmentPlan} />
      <DeleteModal handleDelete={handleDelete} open={deleteModal} id="1" close={closeDeleteModal} />
      <WarnningMessageModal
        open={isWarningOpen}
        message={warnMsg || props.addQuestionError}
        closeModal={closeWarningModal}></WarnningMessageModal>
      {(loading || upsertLoading) && <LoadingSpinner />}
    </Box>
  );
};

const mapStateToProps = ({ treatmentPlanReducer, stageReducer, treatmentPlanQuestionReducer }) => {
  return {
    loading: treatmentPlanReducer.loading,
    upsertLoading: treatmentPlanReducer.upsertLoading,
    treatmentPlan: treatmentPlanReducer.treatmentPlanList,
    editTreatmentPlan: treatmentPlanReducer.editTreatmentPlan,
    addTreatmentPlan: treatmentPlanReducer.addTreatmentPlan,
    treatmentPlanAddQuestionOption: treatmentPlanReducer.treatmentPlanAddQuestionOption,
    stages: stageReducer.stageList,
    stagesIncluded: treatmentPlanReducer.stagesIncluded,
    stagesNecessary: treatmentPlanReducer.stagesNecessary,
    stagesOptional: treatmentPlanReducer.stagesOptional,
    treatmentPlanQuestions: treatmentPlanReducer.treatmentPlanDetailsList,
    treatmentPlanQuestionSelection: treatmentPlanReducer.treatmentPlanQuestionSelection,
    treatmentPlanRoot: treatmentPlanReducer.treatmentPlanRoot,
    treatmentAddOn: treatmentPlanReducer.treatmentAddOn,
    treatmentModifier: treatmentPlanReducer.treatmentModifier,
    treatmentMonitoring: treatmentPlanReducer.treatmentMonitoring,
    treatmentAssist: treatmentPlanReducer.treatmentAssist,
    treatmentAHM: treatmentPlanReducer.treatmentAHM,
    addStagesTreatmentPlan: treatmentPlanReducer.addStagesTreatmentPlan,
    deleteTreatmentPlanQuestion: treatmentPlanReducer.deleteTreatmentPlanQuestion,
    editStagesTP: treatmentPlanReducer.editStagesTP,
    success: treatmentPlanReducer.success,
    error: treatmentPlanReducer.error,
    addTreatmentPlanQuestionList: treatmentPlanQuestionReducer.addTreatmentPlanQuestionList,
    addQuestionError: treatmentPlanQuestionReducer.error,
    isTreatmentPlanQuestionFetch: treatmentPlanReducer.isTreatmentPlanQuestionFetch,
    isRootQuestionFetch: treatmentPlanReducer.isRootQuestionFetch,
    isTreatmentPlanQuestionSelectionFetch:
      treatmentPlanReducer.isTreatmentPlanQuestionSelectionFetch,
    isAddOnQuestionFetch: treatmentPlanReducer.isAddOnQuestionFetch,
    isModifierQuestionFetch: treatmentPlanReducer.isModifierQuestionFetch,
    isMonitoringQuestionFetch: treatmentPlanReducer.isMonitoringQuestionFetch,
    isAssistQuestionFetch: treatmentPlanReducer.isAssistQuestionFetch,
    isAHMQuestionFetch: treatmentPlanReducer.isAHMQuestionFetch
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getTreatmentPlan: (practiceId) => {
      dispatch(actionType.getTreatmentPlan(practiceId));
    },
    getTreatmentPlanAddQuestionOption: (payload) => {
      dispatch(actionType.getTreatmentPlanAddQuestionOption(payload));
    },
    getTreatmentPlanQuestions: (payload) => {
      dispatch(actionType.getTreatmentPlanQuestions(payload));
    },
    getTreatmentPlanRoot: (payload) => {
      dispatch(actionType.getTreatmentPlanRoot(payload));
    },
    getTreatmentPlanQuestionSelection: (payload) => {
      dispatch(actionType.getTreatmentPlanQuestionSelection(payload));
    },
    getTreatmentPlanAddOn: (payload) => {
      dispatch(actionType.getTreatmentPlanAddOn(payload));
    },
    getTreatmentPlanModifier: (payload) => {
      dispatch(actionType.getTreatmentPlanModifier(payload));
    },
    getTreatmentPlanMonitoring: (payload) => {
      dispatch(actionType.getTreatmentPlanMonitoring(payload));
    },
    getTreatmentPlanAssist: (payload) => {
      dispatch(actionType.getTreatmentPlanAssist(payload));
    },
    getTreatmentPlanAHM: (payload) => {
      dispatch(actionType.getTreatmentPlanAHM(payload));
    },
    addNewTreatmentPlan: (payload) => {
      dispatch(actionType.addNewTreatmentPlan(payload));
    },
    upsertTreatmentPlan: (payload) => {
      dispatch(actionType.upsertTreatmentPlan(payload));
    },
    editTreatmentPlan: (payload) => {
      dispatch(actionType.editTreatmentPlan(payload));
    },
    resetTreatmentPlanList: () => {
      dispatch(actionType.resetTreatmentPlanList());
    },
    getStages: (payload) => {
      dispatch(actionType.getStages(payload));
    },
    getTreatmentPlanStageIncluded: (payload) => {
      dispatch(actionType.getTreatmentPlanStageIncluded(payload));
    },
    getTpStagesNecessary: (payload) => {
      dispatch(actionType.getTpStagesNecessary(payload));
    },
    getTpStagesOptional: (payload) => {
      dispatch(actionType.getTpStagesOptional(payload));
    },
    addNewStagesTreatmentPlan: (payload) => {
      dispatch(actionType.addNewStagesTreatmentPlan(payload));
    },
    editStageTreatmentPlan: (payload) => {
      dispatch(actionType.editStageTreatmentPlan(payload));
    },
    deleteTpStage: (payload) => {
      dispatch(actionType.deleteTpStage(payload));
    },
    deleteTreatmentPlanQuestion: (payload) => {
      dispatch(actionType.deleteTreatmentPlanQuestion(payload));
    },
    resetStageList: () => {
      dispatch(actionType.resetStageList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    addQuestionTreatmentPlan: (payload) => {
      dispatch(actionType.addQuestionTreatmentPlan(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanDetails);
