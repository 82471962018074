import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";
// get All program
function* fetchProgramList(action) {
  const { payload } = action;
  const { practiceId, gdmId } = payload;

  try {
    const params = {
      offset: "",
      limit: ""
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/programs`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.PROGRAM_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.PROGRAM_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getProgramSaga() {
  yield takeEvery(actionTypes.PROGRAM_LIST_REQUESTED, fetchProgramList);
}

// Add program
function* addProgram(action) {
  const { payload } = action;
  const { practiceId, gdmId } = payload;
  const body = {
    noOfCycle: payload.noOfCycle,
    programName: payload.programName,
    progShortName: payload.progShortName,
    refund: payload.refund,
    deptId: payload.deptId
  };
  console.log("bode", body);
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/gdms/${gdmId}/programs`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_PROGRAM_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_PROGRAM_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* addProgramSaga() {
  yield takeEvery(actionTypes.ADD_PROGRAM_REQUESTED, addProgram);
}

/* Edit program */
function* editProgram(action) {
  const { payload } = action;
  const { practiceId, gdmId, programId } = payload;
  const body = {
    noOfCycle: payload.noOfCycle,
    programName: payload.programName,
    progShortName: payload.progShortName,
    refund: payload.refund,
    deptId: payload.deptId
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/gdms/${gdmId}/programs/${programId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_PROGRAM_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_PROGRAM_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* editProgramSaga() {
  yield takeEvery(actionTypes.EDIT_PROGRAM_REQUESTED, editProgram);
}

/* Edit program */
function* deleteProgram(action) {
  const { payload } = action;
  const { practiceId, gdmId, programId } = payload;
  try {
    const requestBody = createRequestBody(
      "delete",
      `/practices/${practiceId}/gdms/${gdmId}/programs/${programId}`,
      "",
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.DELETE_PROGRAM_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_PROGRAM_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteProgramSaga() {
  yield takeEvery(actionTypes.DELETE_PROGRAM_REQUESTED, deleteProgram);
}
export default function* programSaga() {
  yield all([getProgramSaga(), addProgramSaga(), editProgramSaga(), deleteProgramSaga()]);
}
