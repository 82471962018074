import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

export default function* prsSaga() {
  yield all([getPRSSaga(), addPRSSaga()]);
}

function* fetchPRSList() {
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("get", `/practices/prs/scale-table`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.PRS_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.PRS_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* addPRS(action) {
  const { payload } = action;
  try {
    const body = {
      description: payload.values.description,
      cycle_info: payload.values.cycle_info
    };
    const requestBody = createRequestBody("post", `/practices/prs/scale-table`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_NEW_PRS_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_PRS_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* getPRSSaga() {
  yield takeEvery(actionTypes.PRS_LIST_REQUESTED, fetchPRSList);
}

function* addPRSSaga() {
  yield takeEvery(actionTypes.ADD_NEW_PRS_REQUESTED, addPRS);
}
