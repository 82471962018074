import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getQueryString,
  patientFormPayload,
  patientFormValues
} from "../../shared/constants/constants";
import * as actionType from "../actions";
import PatientDetailsForm from "./PatientDetailsForm";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const PatientDetails = (props) => {
  const {
    open,
    close,
    success,
    error,
    actions,
    loading,
    patientsList,
    loadingPatients,
    selectedPatient,
    isEdit,
    partnerList,
    partnerLoading,
    newPatient
  } = props;
  const [successModal, setSuccessModal] = useState(false);
  const [patientInfo, setPatientInfo] = useState({ ...patientFormValues });
  const [partnerInfo, setPartnerInfo] = useState({ ...patientFormValues });
  const [existingPartnerInfo, setExistingPartnerInfo] = useState({ ...patientFormValues });
  const [practiceId, setPracticeId] = useState("");
  const [openWarningMsg, setOpenWarningMsg] = useState(false);
  const [activePartner, setActivePartner] = useState("No");
  const [existingPatient, setExistingPatient] = useState("");
  const [patientDropdown, setPatientDropdown] = useState([]);

  const navigate = useNavigate();
  useEffect(() => {
    if (success || error) {
      setSuccessModal(true);
    }
  }, [success, error]);
  useEffect(() => {
    if (patientsList) {
      setPatientDropdown(
        patientsList.map((item) => {
          item.label = `${item.firstName} ${item.lastName} - ${item.personKey}`;
          return item;
        })
      );
    }
  }, [loadingPatients]);

  useEffect(() => {
    if (selectedPatient) {
      setPatientInfo(convertFields(selectedPatient));
      if (isEdit) {
        setPracticeId(selectedPatient.practiceId);
      }

      if (selectedPatient.activePartner != null) {
        if (partnerList.length == 0) {
          actions.getPatientPartnerList({
            practiceId: selectedPatient.practiceId,
            personId: selectedPatient.personId
          });
        }
      }
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (partnerList.length) {
      setActivePartner("Yes");
      let partner = partnerList.filter((item) => item.activePartner != null);
      setPartnerInfo(convertFields(partner[0]));
      setExistingPartnerInfo(convertFields(partner[0]));

      if (isEdit) {
        setPracticeId(partner[0].practiceId);
      }
    }
  }, [partnerLoading]);
  const handleSubmit = (event) => {
    event.preventDefault();
    const patient = patientFormPayload(patientInfo, activePartner);
    let partner = patientFormPayload(partnerInfo, "Yes");
    if (existingPatient == "Yes") {
      partner = createBody(partnerInfo);
    }
    let payload = {
      patient: { ...patient }
    };
    if (activePartner == "Yes") {
      payload = {
        patient: { ...patient },
        partners: [{ ...partner }]
      };
    }
    actions.addPatientDetails({ practiceId, payload });
  };
  const createBody = (patient) => {
    return {
      firstName: patient.firstName,
      gender: patient.gender,
      lastName: patient.lastName,
      additionalPhone: patient.additionalPhone,
      birthDate: moment(patient.birthDate).format("MM/DD/YYYY"),
      commPreference: patient.commPreference,
      createdBy: patient.createdBy,
      creationDate: patient.creationDate,
      duplicateOfPersonId: patient.duplicateOfPersonId,
      ethnicityCode: patient.ethnicityCode,
      facCode: patient.facCode,
      firstLogin: patient.firstLogin,
      ivfMD: patient.ivfMD,
      ivfMDFirstName: patient.ivfMDFirstName,
      ivfMDLastName: patient.ivfMDLastName,
      ivfMDMidInitial: patient.ivfMDMidInitial,
      maritalStatus: patient.maritalStatus,
      mdNPI: patient.mdNPI,
      middleName: patient.middleName,
      mrn: patient.mrn,
      personId: patient.personId,
      personKey: patient.personKey,
      practiceId: patient.practiceId,
      practiceLongName: patient.practiceLongName,
      practiceName: patient.practiceName,
      ssn: patient.ssn,
      updatedBy: patient.updatedBy,
      updatedDate: patient.updatedDate,
      defaultAddress: patient.defaultAddress,
      defaultPhone: patient.defaultPhone,
      email: patient.email,
      mailingAddrLine1: patient.defaultAddress == "mailing" ? patient.streetLine1 : "",
      mailingAddrLine2: patient.defaultAddress == "mailing" ? patient.streetLine2 : "",
      mailingCity: patient.defaultAddress == "mailing" ? patient.city : "",
      mailingState: patient.defaultAddress == "mailing" ? patient.state : "",
      mailingZip: patient.defaultAddress == "mailing" ? patient.zip : "",
      mailingCountry: patient.defaultAddress == "mailing" ? patient.country : "",
      billingAddrLine1: patient.defaultAddress == "billing" ? patient.streetLine1 : "",
      billingAddrLine2: patient.defaultAddress == "billing" ? patient.streetLine2 : "",
      billingCity: patient.defaultAddress == "billing" ? patient.city : "",
      billingState: patient.defaultAddress == "billing" ? patient.state : "",
      billingZip: patient.defaultAddress == "billing" ? patient.zip : "",
      billingCountry: patient.defaultAddress == "billing" ? patient.country : "",
      cellPhone: patient.defaultPhone == "cell" ? patient.phoneNumber : "",
      homePhone: patient.defaultPhone == "home" ? patient.phoneNumber : "",
      workPhone: patient.defaultPhone == "work" ? patient.phoneNumber : "",
      activePartner: { ...patient.activePartner }
    };
  };
  const handleEdit = (event) => {
    event.preventDefault();
    const body = createBody(patientInfo);
    let payload = {
      patientId: body.personId,
      practiceId: body.practiceId,
      body: { patient: { ...body } }
    };

    if (activePartner == "Yes") {
      let partner = createBody(partnerInfo);
      if (existingPatient == "No") {
        partner = patientFormPayload(partnerInfo, "Yes");
      }

      let nonActivePartner = partnerList
        .filter((item) => item.activePartner == null)
        .map((item) => {
          item.activePartner = { active: false };
          return item;
        });
      payload = {
        patientId: body.personId,
        practiceId: body.practiceId,
        body: { patient: { ...body }, partners: [{ ...partner }, ...nonActivePartner] }
      };
      console.log("payload", payload);
    }
    actions.updatePatientDetails(payload);
  };
  const handleCancel = () => {
    setOpenWarningMsg(true);
  };
  const handleContinue = () => {
    setOpenWarningMsg(false);
  };
  const handlePatientInfo = (name, value) => {
    if (name == "practiceId") {
      setPatientDropdown([]);
      actions.resetPersonalList();
      setPracticeId(value);
    }
    if (name == "primaryMd" && value != null) {
      setPatientInfo({
        ...patientInfo,
        ivfMD: value.ivfMD,
        ivfMDFirstName: value.ivfMDFirstName,
        ivfMDLastName: value.ivfMDLastName,
        ivfMDMidInitial: value.ivfMDMidInitial,
        mdNPI: value.mdNPI
      });
    } else {
      setPatientInfo({ ...patientInfo, [name]: value });
    }
  };
  const handlePartnerInfo = (name, value) => {
    if (name == "primaryMd" && value != null) {
      setPartnerInfo({
        ...partnerInfo,
        ivfMD: value.ivfMD,
        ivfMDFirstName: value.ivfMDFirstName,
        ivfMDLastName: value.ivfMDLastName,
        ivfMDMidInitial: value.ivfMDMidInitial,
        mdNPI: value.mdNPI
      });
    } else {
      setPartnerInfo({ ...partnerInfo, [name]: value });
    }
  };
  const convertFields = (value) => {
    if (value.defaultAddress == "mailing") {
      value.streetLine1 = value.mailingAddrLine1 ? value.mailingAddrLine1 : "";
      value.streetLine2 = value.mailingAddrLine2 ? value.mailingAddrLine2 : "";
      value.city = value.mailingCity ? value.mailingCity : "";
      value.state = value.mailingState ? value.mailingState : "";
      value.zip = value.mailingZip ? value.mailingZip : "";
      value.country = value.mailingCountry ? value.mailingCountry : "";
    } else if (value.defaultAddress == "billing") {
      value.streetLine1 = value.billingAddrLine1 ? value.billingAddrLine1 : "";
      value.streetLine2 = value.billingAddrLine2 ? value.billingAddrLine2 : "";
      value.city = value.billingCity ? value.billingCity : "";
      value.state = value.billingState ? value.billingState : "";
      value.zip = value.billingZip ? value.billingZip : "";
      value.country = value.billingCountry ? value.billingCountry : "";
    }
    return {
      ...value,
      birthDate: new Date(value.birthDate),
      gender: value.gender ? value.gender : "",
      email: value.email ? value.email : "",
      phoneNumber:
        value.defaultPhone == "work"
          ? value.workPhone
          : value.defaultPhone == "cell"
          ? value.cellPhone
          : value.defaultPhone == "home"
          ? value.homePhone
          : ""
    };
  };
  const handlePatientSearch = (value) => {
    if (value) {
      setPartnerInfo(convertFields(value));
    }
  };
  const handlePartnerChange = (e) => {
    setExistingPatient(e.target.value);
    let finalData = {};
    if (e.target.value === "Yes") {
      finalData = { ...patientFormValues, ...existingPartnerInfo };
    } else {
      finalData = { ...patientFormValues };
    }

    setPartnerInfo({ ...finalData });
  };
  const closeSuccessModal = () => {
    setSuccessModal(false);
    actions.resetPatientDetailsMsg();
    actions.resetUpdatePatientDetails();
    if (success) {
      handleLeave();
    }
  };
  const handleLeave = () => {
    setOpenWarningMsg(false);
    setPartnerInfo({ ...patientFormValues });
    setPatientInfo({ ...patientFormValues });
    setExistingPatient("");
    setActivePartner("No");
    actions.resetPersonalList();
    actions.resetPatientPartnerList();
    close();

    if (newPatient && newPatient.patient && newPatient.patient.personId) {
      navigate(`/patients/${newPatient.patient.personId}/personal`);
    }
  };
  return (
    <>
      <Modal open={open} backdrop="static">
        <Box
          className="add-patient-modal"
          component="form"
          onSubmit={isEdit ? handleEdit : handleSubmit}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body">{isEdit ? "Edit Patient" : "Add Patient"}</Typography>
            <IconButton onClick={handleCancel}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="divider-height" />
          <Box className="patient-form-container">
            <Box className="patient-form-content">
              <PatientDetailsForm
                disableEdit={isEdit}
                getValue={handlePatientInfo}
                values={patientInfo}
              />
              <Grid container>
                <Grid item xs={4}>
                  <Typography required className="m-revert">
                    Does Patient have active partner
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    value={activePartner}
                    name="partner"
                    onChange={(e) => {
                      setActivePartner(e.target.value);
                    }}>
                    <Stack direction="row">
                      <FormControlLabel
                        //disabled={isEdit}
                        value="Yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        //disabled={isEdit}
                        value="No"
                        control={<Radio />}
                        label="No"
                      />
                    </Stack>
                  </RadioGroup>
                </Grid>
              </Grid>
              {activePartner == "Yes" ? (
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="subtitle">Relationship Info</Typography>
                    <Divider className="divider-height" />
                  </Grid>
                  <Grid item xs={4}>
                    <Typography required className="m-revert">
                      Partner
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      value={existingPatient}
                      name="partner"
                      onChange={handlePartnerChange}>
                      <Stack direction="row">
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Existing Patient"
                        />
                        <FormControlLabel value="No" control={<Radio />} label="Add New Patient" />
                      </Stack>
                    </RadioGroup>
                  </Grid>
                </Grid>
              ) : null}
              {activePartner == "Yes" && existingPatient == "No" && !isEdit ? (
                <PatientDetailsForm
                  disabled={false}
                  getValue={handlePartnerInfo}
                  values={partnerInfo}
                />
              ) : null}

              {activePartner == "Yes" && existingPatient == "Yes" && !isEdit ? (
                <>
                  <Grid container>
                    <Grid item xs={2} className="m-t-10">
                      <InputLabel>Search Patient</InputLabel>
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        onChange={(e, value) => {
                          handlePatientSearch(value);
                        }}
                        loading={loadingPatients}
                        options={patientDropdown}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            onClick={() => {
                              if (patientsList == undefined) {
                                actions.getPersonalList({
                                  practiceId,
                                  addPatient: "Add Patient",
                                  queryParams: getQueryString("")
                                });
                              }
                            }}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <Fragment>
                                  {loadingPatients ? <CircularProgress size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </Fragment>
                              )
                            }}
                            label="Find by first name or last name"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <PatientDetailsForm
                    disabled={true}
                    getValue={handlePartnerInfo}
                    values={partnerInfo}
                  />
                </>
              ) : null}

              {isEdit && !partnerLoading && activePartner == "Yes" && (
                <>
                  <Grid item xs={12} sx={{ mb: 3 }}>
                    {/* <Typography variant="subtitle">Relationship Info</Typography> */}
                    <Divider className="divider-height" />
                  </Grid>

                  {existingPatient == "Yes" && (
                    <Grid container>
                      <Grid item xs={2} className="m-t-10" style={{ marginTop: 20 }}>
                        <InputLabel>Search Patient</InputLabel>
                      </Grid>
                      <Grid item xs={4}>
                        <Autocomplete
                          onChange={(e, value) => {
                            handlePatientSearch(value);
                          }}
                          loading={loadingPatients}
                          options={patientDropdown}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              onClick={() => {
                                if (patientsList == undefined) {
                                  actions.getPersonalList({
                                    practiceId,
                                    addPatient: "Add Patient",
                                    queryParams: getQueryString("")
                                  });
                                }
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <Fragment>
                                    {loadingPatients ? <CircularProgress size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </Fragment>
                                )
                              }}
                              label="Find by first name or last name"
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {activePartner == "Yes" && existingPatient == "No" ? (
                    <PatientDetailsForm
                      //disableEdit={isEdit}
                      getValue={handlePartnerInfo}
                      values={partnerInfo}
                    />
                  ) : (
                    <PatientDetailsForm
                      disableEdit={isEdit}
                      getValue={handlePartnerInfo}
                      values={partnerInfo}
                    />
                  )}
                </>
              )}
            </Box>
            <Stack direction="row" justifyContent="flex-end" spacing={2} pt={2}>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              {isEdit ? (
                <Button variant="contained" type="submit">
                  Save
                </Button>
              ) : (
                <Button variant="contained" type="submit">
                  Save
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
      </Modal>
      <Modal open={openWarningMsg} backdrop="static">
        <Box className="alert-modal">
          <Stack direction="row" className="modal-stack">
            <Typography>Alert</Typography>
            <IconButton onClick={handleContinue}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack spacing={1} className="m-t-5">
            <Typography>Are you sure you want to leave this page?</Typography>
            <Alert severity="warning">Are you sure you want to discard all changes made?</Alert>
          </Stack>
          <Stack direction="row" justifyContent="space-between" className="mt-8">
            <Button variant="outlined" onClick={handleLeave}>
              Leave
            </Button>
            <Button variant="contained" onClick={handleContinue}>
              Continue Editing
            </Button>
          </Stack>
        </Box>
      </Modal>
      {loading ? (
        <Modal open={loading ? true : false} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal" width="100px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Modal>
      ) : null}
      {successModal ? (
        <Modal open={successModal} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal">
            <Stack direction="row" className="modal-stack">
              <Typography>Alert</Typography>
              <IconButton onClick={closeSuccessModal}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", py: 2 }}>
              {success && (
                <Typography variant="body2" color="#42ba96">
                  {success}
                </Typography>
              )}
              {error && (
                <Typography variant="body2" color="#A6315C">
                  {error}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
              <Button variant="contained" className="btn-primary" onClick={closeSuccessModal}>
                OK
              </Button>
            </Stack>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};
const mapStateToProps = ({ patientReducer }) => {
  return {
    success: patientReducer.addPatientSuccess,
    error: patientReducer.addPatientError,
    loading: patientReducer.addPatientLoading,
    patientsList: patientReducer.patientsList.data,
    loadingPatients: patientReducer.loading,
    partnerList: patientReducer.partnerList,
    partnerLoading: patientReducer.partnerLoading,
    newPatient: patientReducer.newPatient
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    addPatientDetails: (payload) => {
      dispatch(actionType.addPatientDetails(payload));
    },
    getPersonalList: (payload) => {
      dispatch(actionType.getPersonalList(payload));
    },
    resetPatientDetailsMsg: () => {
      dispatch(actionType.resetPatientDetailsMsg());
    },
    resetPersonalList: () => {
      dispatch(actionType.resetPersonalList());
    },
    updatePatientDetails: (payload) => {
      dispatch(actionType.updatePatientDetails(payload));
    },
    resetUpdatePatientDetails: () => {
      dispatch(actionType.resetUpdatePatientDetails());
    },
    getPatientPartnerList: (payload) => {
      dispatch(actionType.getPatientPartnerList(payload));
    },
    resetPatientPartnerList: () => {
      dispatch(actionType.resetPatientPartnerList());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
