import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actionType from "../actions";
import CommonModal from "../../shared/components/CommonModal";
import { revenueDetailsConfig } from "../../shared/constants/renderConfigs";
import moment from "moment";
import WarnningMessageModal from "../../shared/components/WarnningMessageModal";

const formData = {
  cycle: "",
  amount: "",
  transactionType: "",
  transactionDate: new Date(),
  medcaseId: "",
  recordedDate: new Date(),
  comment: ""
};

const AddRevenue = (props) => {
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const { actions, clinicalList, medcaseId, isPGTA } = props;

  useEffect(() => {
    actions.getClinicalTreatmentOutcomes({ medcaseId: medcaseId });
  }, []);

  const closeWarningModal = () => {
    setIsWarningOpen(false);
    setWarningMessage("");
  };
  const [values, setValues] = useState(formData);
  const closeModal = () => {
    props.close();
    setValues(formData);
  };
  const saveRevenue = (event) => {
    event.preventDefault();
    values.recordedDate = values.recordedDate
      ? moment(values.recordedDate).format("YYYY-MM-DD")
      : null;
    values.transactionDate = values.transactionDate
      ? moment(values.transactionDate).format("YYYY-MM-DD")
      : null;
    if (values.isImportant === "on") {
      values.isImportant = true;
    } else {
      values.isImportant = false;
    }
    values.amount = /* parseInt */ Number(values.amount);
    values.medcaseId = medcaseId;
    if (props.netProgramFee < values.amount) {
      setIsWarningOpen(true);
      setWarningMessage(
        "Revenue Amount should not be more than Net Program Fee. Kindly check the Amount entered"
      );
    } else {
      actions.postRevenue(values);
    }
    closeModal();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("zhr", name, value);

    if (name === "transactionType") {
      if (value === "Correction") setIsCommentReq(true);
      else setIsCommentReq(false);
    }

    setValues({
      ...values,
      [name]: value
    });
  };

  const options = clinicalList.map((item) => {
    if (item.outcomeCategory != null) {
      return item.clinicalTreatmentNo;
    } else {
      return [];
    }
  });

  const [isCommentReq, setIsCommentReq] = useState(false);
  const [revenue, setRevenue] = useState(
    revenueDetailsConfig({
      values,
      onChange: handleInputChange,
      isAdd: false,
      onClick: closeModal,
      setValues,
      options,
      isPGTA,
      isCommentReq
    })
  );

  useEffect(() => {
    setRevenue(
      revenueDetailsConfig({
        values,
        onChange: handleInputChange,
        isAdd: false,
        onClick: closeModal,
        setValues,
        options,
        isPGTA,
        isCommentReq
      })
    );
  }, [isCommentReq, values]);

  return (
    <>
      <CommonModal
        open={props.openDialog}
        config={revenue}
        isAdd={false}
        handleSubmit={saveRevenue}
      />
      <WarnningMessageModal
        open={isWarningOpen}
        message={warningMessage}
        closeModal={closeWarningModal}
      />
    </>
  );
};

const mapStateToProps = ({ trackOutComeFormReducer }) => {
  return {
    clinicalList: trackOutComeFormReducer.clinicalTreatmentOutcomesList
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getClinicalTreatmentOutcomes: (payload) => {
      dispatch(actionType.getClinicalTreatmentOutcomes(payload));
    },
    postRevenue: (payload) => {
      dispatch(actionType.postRevenue(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(AddRevenue);
