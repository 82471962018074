import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchTreatmentPlan(action) {
  const { payload } = action;
  const { practiceId, gdmId } = payload;
  try {
    const params = {
      offset: "",
      limit: "1000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLAN_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TREATMENTPLAN_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getTreatmentPlanSaga() {
  yield takeEvery(actionTypes.TREATMENTPLAN_LIST_REQUESTED, fetchTreatmentPlan);
}

function* createNewTreatmentPlan(action) {
  const { payload } = action;
  const requestbody = {
    treatmentPlanName: payload.treatmentPlan
  };

  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans`,
      "",
      requestbody
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_NEW_TREATMENTPLAN_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_TREATMENTPLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewTreatmentPlanSaga() {
  yield takeEvery(actionTypes.ADD_NEW_TREATMENTPLAN_REQUESTED, createNewTreatmentPlan);
}

function* editTreatmentPlan(action) {
  const { payload } = action;
  const body = {
    treatmentPlanName: payload.treatmentPlan
    // effectiveDate: payload.effectiveDate
    //treatmentPlanInternalName: payload.data.treatmentPlanInternalName
  };

  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_TREATMENTPLAN_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_TREATMENTPLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editTreatmentPlanSaga() {
  yield takeEvery(actionTypes.EDIT_TREATMENTPLAN_REQUESTED, editTreatmentPlan);
}

function* upsertTreatmentPlan(action) {
  const { payload } = action;

  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId}/EstimateQuestionSubquestions/all`,
      "",
      payload.questions
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.UPSERT_TREATMENTPLAN_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.UPSERT_TREATMENTPLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* upsertTreatmentPlanSaga() {
  yield takeEvery(actionTypes.UPSERT_TREATMENTPLAN_REQUESTED, upsertTreatmentPlan);
}

function* deleteTreatmentPlan(action) {
  const { payload } = action;
  // const requestBody = {
  //   treatmentPlanName: payload.treatmentPlanId.treatmentPlanName
  // };
  try {
    const requestBody = createRequestBody(
      "delete",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId.treatmentPlanId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.DELETE_TREATMENTPLAN_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_TREATMENTPLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteTreatmentPlanSaga() {
  yield takeEvery(actionTypes.DELETE_TREATMENTPLAN_REQUESTED, deleteTreatmentPlan);
}

//STAGES INCLUDED

function* fetchStagesIncluded(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId, category } = payload;
  try {
    const params = {
      category: category
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.STAGE_INCLUDED_TREATMENTPLAN_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.STAGE_INCLUDED_TREATMENTPLAN_FAILED, errorMsg: error.message });
    }
  }
}

function* getTreatmentPlanStageIncludedSaga() {
  yield takeEvery(actionTypes.STAGE_INCLUDED_TREATMENTPLAN_REQUESTED, fetchStagesIncluded);
}

function* createNewStagesIncluded(action) {
  const { payload } = action;
  const requestbody = payload.saveData;
  try {
    const params = {
      category: payload.category
    };
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId}/stages/${payload.stageId}`,
      params,
      requestbody
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_STAGES_TREATMENTPLAN_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_STAGES_TREATMENTPLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewStagesIncludedSaga() {
  yield takeEvery(actionTypes.ADD_STAGES_TREATMENTPLAN_REQUESTED, createNewStagesIncluded);
}

function* updateStageTreatmentPlan(action) {
  const { payload } = action;
  const { practiceId, gdmId, tPStageId } = payload;
  const requestbody = payload.saveData;
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plan-stages/${tPStageId}`,
      "",
      requestbody
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_STAGES_TREATMENTPLAN_SUCCESS,
      payload: response,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_STAGES_TREATMENTPLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editStagesTreatmentPlanSaga() {
  yield takeEvery(actionTypes.EDIT_STAGES_TREATMENTPLAN_REQUESTED, updateStageTreatmentPlan);
}

function* deleteTpStage(action) {
  const { payload } = action;
  const { practiceId, tpStageId, gdmId } = payload;
  try {
    const requestBody = createRequestBody(
      "delete",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plan-stages/${tpStageId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.DELETE_STAGES_TREATMENTPLAN_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_STAGES_TREATMENTPLAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteTpStageSaga() {
  yield takeEvery(actionTypes.DELETE_STAGES_TREATMENTPLAN_REQUESTED, deleteTpStage);
}

function* fetchStagesNecessary(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId, category } = payload;
  try {
    const params = {
      category: category,
      feesSch: "False"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.STAGE_NECESSARY_TREATMENTPLAN_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.STAGE_NECESSARY_TREATMENTPLAN_FAILED, message: error.message });
    }
  }
}

function* getTpStagesNecessarySaga() {
  yield takeEvery(actionTypes.STAGE_NECESSARY_TREATMENTPLAN_REQUESTED, fetchStagesNecessary);
}

function* fetchStagesOptional(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId, category } = payload;
  try {
    const params = {
      category: category,
      feesSch: "False"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/stages`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.STAGE_OPTIONAL_TREATMENTPLAN_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.STAGE_OPTIONAL_TREATMENTPLAN_FAILED, message: error.message });
    }
  }
}

function* getTpStagesOptionalSaga() {
  yield takeEvery(actionTypes.STAGE_OPTIONAL_TREATMENTPLAN_REQUESTED, fetchStagesOptional);
}

function* fetchTreatmentPlanQuestions(action) {
  const { payload } = action;
  const { practiceId, gdmId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      questionCategory: "root"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/financialEstimateQuestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLANQUESTIONS_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TREATMENTPLANQUESTIONS_LIST_FAIL, message: error.message });
    }
  }
}

function* getTreatmentPlanQuestions() {
  yield takeEvery(actionTypes.TREATMENTPLANQUESTIONS_LIST_REQUESTED, fetchTreatmentPlanQuestions);
}

function* fetchTreatmentPlanAddQuestionOption(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000",
      questionCategory: payload.category,
      responseNo: payload.responseNo,
      questionId: payload.questionId
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/financialEstimateQuestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLANADDQUESTIONOPTION_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.TREATMENTPLANADDQUESTIONOPTION_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getTreatmentPlanAddQuestionOption() {
  yield takeEvery(
    actionTypes.TREATMENTPLANADDQUESTIONOPTION_LIST_REQUESTED,
    fetchTreatmentPlanAddQuestionOption
  );
}

function* fetchTreatmentPlanQuestionSelection(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      questionCategory: "primary"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/EstimateQuestionSubquestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLANQUESTIONSELECTION_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.TREATMENTPLANQUESTIONSELECTION_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getTreatmentPlanQuestionSelection() {
  yield takeEvery(
    actionTypes.TREATMENTPLANQUESTIONSELECTION_LIST_REQUESTED,
    fetchTreatmentPlanQuestionSelection
  );
}
function* fetchTreatmentPlanRoot(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      questionCategory: "root"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/EstimateQuestionSubquestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLANROOT_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.TREATMENTPLANROOT_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getTreatmentPlanRoot() {
  yield takeEvery(actionTypes.TREATMENTPLANROOT_LIST_REQUESTED, fetchTreatmentPlanRoot);
}

function* fetchTreatmentPlanAddOn(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      questionCategory: "add-on"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/EstimateQuestionSubquestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLAN_ADD_ON_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TREATMENTPLAN_ADD_ON_FAIL, message: error.message });
    }
  }
}

function* getTreatmentPlanAddOn() {
  yield takeEvery(actionTypes.TREATMENTPLAN_ADD_ON_REQUESTED, fetchTreatmentPlanAddOn);
}

function* fetchTreatmentPlanModifier(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      questionCategory: "modifier"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/EstimateQuestionSubquestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLAN_MODIFIER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TREATMENTPLAN_MODIFIER_FAIL, message: error.message });
    }
  }
}

function* getTreatmentPlanModifier() {
  yield takeEvery(actionTypes.TREATMENTPLAN_MODIFIER_REQUESTED, fetchTreatmentPlanModifier);
}

function* fetchTreatmentPlanMonitoring(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      questionCategory: "monitoring"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/EstimateQuestionSubquestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLAN_MONITORING_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TREATMENTPLAN_MONITORING_FAIL, message: error.message });
    }
  }
}

function* getTreatmentPlanMonitoring() {
  yield takeEvery(actionTypes.TREATMENTPLAN_MONITORING_REQUESTED, fetchTreatmentPlanMonitoring);
}

function* fetchTreatmentPlanAssist(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      questionCategory: "assist"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/EstimateQuestionSubquestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLAN_ASSIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TREATMENTPLAN_ASSIST_FAIL, message: error.message });
    }
  }
}

function* getTreatmentPlanAssist() {
  yield takeEvery(actionTypes.TREATMENTPLAN_ASSIST_REQUESTED, fetchTreatmentPlanAssist);
}

function* fetchTreatmentPlanAHM(action) {
  const { payload } = action;
  const { practiceId, gdmId, treatmentPlanId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000",
      questionCategory: "pgta"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/treatment-plans/${treatmentPlanId}/EstimateQuestionSubquestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TREATMENTPLAN_AHM_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TREATMENTPLAN_AHM_FAIL, message: error.message });
    }
  }
}

function* getTreatmentPlanAHM() {
  yield takeEvery(actionTypes.TREATMENTPLAN_AHM_REQUESTED, fetchTreatmentPlanAHM);
}

function* deleteTreatmentPlanQuestion(action) {
  const { payload } = action;

  try {
    const requestBody = createRequestBody(
      "delete",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/treatment-plans/${payload.treatmentPlanId}/EstimateQuestionSubquestions/${payload.questionId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.DELETE_TREATMENTPLANQUESIONS_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_TREATMENTPLANQUESIONS_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}
function* deleteTreatmentPlanQuestionSaga() {
  yield takeEvery(actionTypes.DELETE_TREATMENTPLANQUESIONS_REQUESTED, deleteTreatmentPlanQuestion);
}

export default function* treatmentPlanSaga() {
  yield all([
    getTreatmentPlanSaga(),
    addNewTreatmentPlanSaga(),
    editTreatmentPlanSaga(),
    upsertTreatmentPlanSaga(),
    deleteTreatmentPlanSaga(),
    getTreatmentPlanStageIncludedSaga(),
    getTpStagesNecessarySaga(),
    getTpStagesOptionalSaga(),
    getTreatmentPlanQuestions(),
    getTreatmentPlanQuestionSelection(),
    getTreatmentPlanAddOn(),
    getTreatmentPlanModifier(),
    getTreatmentPlanMonitoring(),
    getTreatmentPlanAssist(),
    getTreatmentPlanAHM(),
    addNewStagesIncludedSaga(),
    editStagesTreatmentPlanSaga(),
    deleteTpStageSaga(),
    getTreatmentPlanAddQuestionOption(),
    getTreatmentPlanRoot(),
    deleteTreatmentPlanQuestionSaga()
  ]);
}
