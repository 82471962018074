import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchEmailTemplate() {
  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/emailTemplate`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EMAIL_TEMPLATE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EMAIL_TEMPLATE_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getEmailTemplateSaga() {
  yield takeEvery(actionTypes.EMAIL_TEMPLATE_LIST_REQUESTED, fetchEmailTemplate);
}

function* createNewEmailTemplate(action) {
  const { payload } = action;
  const requestbody = {
    emailSubject: payload.emailSubject,
    emailBody: payload.emailBody,
    emailSlug: payload.emailSlug,
    toRecipient: payload.toRecipient,
    ccRecipient: payload.ccRecipient,
    bccRecipient: payload.bccRecipient,
    isActive: payload.isActive,
    templateVars: payload.emailVars
  };
  try {
    const requestBody = createRequestBody("post", `/emailTemplate`, "", requestbody);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_EMAIL_TEMPLATE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_EMAIL_TEMPLATE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addEmailTemplateSaga() {
  yield takeEvery(actionTypes.ADD_EMAIL_TEMPLATE_REQUESTED, createNewEmailTemplate);
}

function* editEmailTemplate(action) {
  const { payload } = action;
  const body = {
    emailSubject: payload.emailSubject,
    emailBody: payload.emailBody,
    emailSlug: payload.emailSlug,
    toRecipient: payload.toRecipient,
    ccRecipient: payload.ccRecipient,
    bccRecipient: payload.bccRecipient,
    isActive: payload.isActive,
    templateVars: payload.emailVars
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/emailTemplate/${payload.emailTemplateId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_EMAIL_TEMPLATE_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_EMAIL_TEMPLATE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editEmailTemplateSaga() {
  yield takeEvery(actionTypes.EDIT_EMAIL_TEMPLATE_REQUESTED, editEmailTemplate);
}

function* deleteEmailTemplate(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "delete",
      `/emailTemplate/${payload.emailTemplateId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.DELETE_EMAIL_TEMPLATE_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_EMAIL_TEMPLATE_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteEmailTemplateSaga() {
  yield takeEvery(actionTypes.DELETE_EMAIL_TEMPLATE_REQUESTED, deleteEmailTemplate);
}

function* fetchEmailTemplateSetting() {
  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/emailTemplate/settings`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EMAIL_TEMPLATE_SETTING_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EMAIL_TEMPLATE_SETTING_FAIL,
        message: error.message
      });
    }
  }
}

function* getEmailTemplateSettingSaga() {
  yield takeEvery(actionTypes.EMAIL_TEMPLATE_SETTING_REQUESTED, fetchEmailTemplateSetting);
}

export default function* relativeValueSaga() {
  yield all([
    getEmailTemplateSaga(),
    addEmailTemplateSaga(),
    editEmailTemplateSaga(),
    deleteEmailTemplateSaga(),
    getEmailTemplateSettingSaga()
  ]);
}
