import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* addNewUser(action) {
  const { payload } = action;
  //let arr = payload["finalRights"].join(":");
  const body = {
    firstName: payload["values"].firstName,
    lastName: payload["values"].lastName,
    domainUser: payload["values"].domainUser === "true" ? true : false,
    email: payload["values"]?.email.toLowerCase(),
    phoneNo: `+91${payload["values"].phoneNo}`,
    primaryRole: payload["roleName"],
    practiceList: payload.selectedpractices,
    userRightList: payload["userRightList"],
    defaultPracticeId: payload["values"].defaultPracticeId
  };

  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("post", `/users`, params, body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CREATE_NEW_USER_SUCCESS,
      payload: response.data,
      success: "New User Created Successfully..."
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CREATE_NEW_USER_FAIL,
        message: error.response.data
      });
    }
  }
}

function* addUserSaga() {
  yield takeEvery(actionTypes.CREATE_NEW_USER, addNewUser);
}

function* fetchUserDetails(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("get", `/users/${payload}`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_USER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_USER_FAIL,
        message: error.message
      });
    }
  }
}
function* getUserRolesDetails() {
  yield takeEvery(actionTypes.GET_EDIT_USER_DETAILS, fetchUserDetails);
}
function* saveEditUser(action) {
  const { payload } = action;
  //let arr = payload["finalRights"].join(":");
  const body = {
    firstName: payload["values"].firstName,
    lastName: payload["values"].lastName,
    domainUser: payload["values"].domainUser === "true" ? true : false,
    email: payload["values"]?.email?.toLowerCase(),
    phoneNo: payload["values"].phoneNo,
    primaryRole: payload["roleName"],
    practiceList: payload.selectedpractices,
    userRightList: payload["userRightList"],
    defaultPracticeId: payload["values"].defaultPracticeId
  };

  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("put", `/users/${payload.cognitoId}`, params, body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.SAVE_EDIT_USER_SUCCESS,
      payload: response.data,
      success: "Saved User Details Successfully..."
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.SAVE_EDIT_USER_FAIL,
        message: error.response.data
      });
    }
  }
}

function* saveEditUserDetails() {
  yield takeEvery(actionTypes.SAVE_EDIT_USER_DETAILS, saveEditUser);
}

export default function* createNewUserSaga() {
  yield all([addUserSaga(), getUserRolesDetails(), saveEditUserDetails()]);
}
