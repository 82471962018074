import * as actionType from "../actions/ActionTypes";

const initialState = {
  addTreatmentPlanQuestionList: [],
  error: ""
};

const treatmentPlanQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADD_QUESTION_TREATMENT_PLAN_REQUESTED: {
      return {
        ...state,
        loading: true,
        error: ""
      };
    }
    case actionType.ADD_QUESTION_TREATMENT_PLAN_SUCCESS: {
      return {
        ...state,
        addTreatmentPlanQuestionList: action.payload,
        loading: false,
        error: "",
        success: action.successMsg
      };
    }

    case actionType.ADD_QUESTION_TREATMENT_PLAN_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default treatmentPlanQuestionReducer;
