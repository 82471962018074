import React from "react";
import MedcaseHeader from "./MedcaseHeader";
import MedcaseList from "./MedcaseList";

const Medcase = () => {
  return (
    <>
      <MedcaseHeader />
      <MedcaseList />
    </>
  );
};

export default Medcase;
