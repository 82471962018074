import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchCaseList(action) {
  const { payload } = action;
  try {
    // query params
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody("post", `/caselist`, params, payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.CASE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.CASE_LIST_FAILED, errorMsg: error.response.data });
    }
  }
}

function* getCaseListSaga() {
  yield takeEvery(actionTypes.CASE_LIST_REQUESTED, fetchCaseList);
}

export default function* caseListSaga() {
  yield all([getCaseListSaga()]);
}
