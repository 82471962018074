import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Typography,
  CircularProgress
} from "@mui/material";
// import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { CheckBoxSharp, DisabledByDefaultOutlined } from "@mui/icons-material";
import {
  getBarContainerHeight,
  loanDisclaimer,
  getXsValue
} from "../../shared/constants/constants";
import {
  fapViewProgram,
  APPLY_LOAN,
  programBoxName,
  editProgramConstants
} from "../../shared/constants/constants";
import { connect, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import * as actionType from "../../../modules/workspace/actions";
import MessageModal from "../../shared/components/MessageModal";

const styles = {
  page: {
    backgroundColor: "#FFF",
    paddingHorizontal: "30px",
    paddingVertical: "50px",
    fontFamily: "Roboto"
  },

  text_regular: {
    fontSize: "14px",
    fontWeight: 400,
    width: "70%",
    textAlign: "right"
  },

  row: {
    display: "flex",
    flexDirection: "row"
  },

  fap_header: {
    backgroundColor: "#ECF7F7",
    width: "100%",
    textAlign: "center"
  },
  fap_logo: {
    marginVertical: 20
    //width: "150px",
    //alignSelf: "center"
  },
  updatedColor: {
    color: "#1BA3AD !important"
  }
};
// #1BA3AD
const FapView = (props) => {
  let programData = { mcpShowEstimate: "", mcpHideReason: "" };
  const practiceId = localStorage.getItem("practiceId");
  const {
    actions,
    estimate,
    loading,
    editProgramData = {},
    addProgramData,
    successMsg,
    errorMsg,
    fapViewError,
    refundApplicationStatus
    // fapVData
  } = props;
  const [openEditProgram, setEditProgram] = useState(false);
  const [values, setValues] = useState(programData);
  const [fapVisibilityData, setFapVisibilityData] = useState({ mcpShowEstimate: true });
  const [confirmModal, setConfirmModal] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isRefundProgram, setIsRefundProgram] = useState(false);
  const [isNonRefundProgram, setIsNonRefundProgram] = useState(false);
  const [isSingleCycle, setIsSingleCycle] = useState(false);
  const [barContainerHeight, setBarContainerHeight] = useState();
  const [xsValue, setXsValue] = useState(4);
  const fapCardsStore = useSelector((state) => state.estimateReducer.fapList);
  useEffect(() => {
    if (fapCardsStore?.programVisualization) {
      fapCardsStore.programVisualization.map((item) => {
        if (item.boxName == programBoxName.PAY_PER_CYCLE && item.programs.length > 3) {
          let height = getBarContainerHeight(item.programs.length);
          setBarContainerHeight(height);
        }
        if (item.boxName == programBoxName.REFUND_PROGRAM) {
          setIsRefundProgram(true);
        }
        if (item.boxName == programBoxName.NON_REFUND_PROGRAM) {
          setIsNonRefundProgram(true);
        }
        if (item.boxName == programBoxName.PAY_PER_CYCLE) {
          setIsSingleCycle(true);
        }
      });
    }
  }, [fapCardsStore]);

  useEffect(() => {
    if (Object.keys(addProgramData).length) {
      getEditProgramFapVisibilityData();
    }
  }, [addProgramData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (editProgramData && Object.keys(editProgramData).length) {
      setValues(editProgramData);
      setFapVisibilityData(editProgramData);
    }
    if (Object.keys(addProgramData).length) {
      setFapVisibilityData(addProgramData);
    }
    if (Object.values(fapVisibilityData)?.length && fapCardsStore?.programVisualization) {
      let count = getXsValue(
        fapCardsStore?.programVisualization.length,
        fapVisibilityData.mcpShowEstimate,
        isRefundProgram
      );
      setXsValue(count);
    }
    // fapVData(fapVisibilityData.mcpShowEstimate);
  }, [editProgramData, successMsg, errorMsg]);

  const abbreviateNumber = (value) => {
    var newValue = value;
    if (value >= 1000) {
      var suffixes = ["", "K", "m", "b", "t"];
      var suffixNum = Math.floor(Math.log10(value) / 3);
      var shortValue = newValue / Math.pow(10, 3 * suffixNum);
      // if (suffixNum > 3) {
      shortValue = shortValue.toFixed(1);
      // }
      return shortValue + suffixes[suffixNum];
    }
    return value;
  };

  const getProgramsPrice = (item) => {
    // return item.programs.length > 1 && item.boxName != programBoxName.PAY_PER_CYCLE
    //   ? "$" +
    //       abbreviateNumber(item.programs[0].programPrice) +
    //       "-" +
    //       "$" +
    //       abbreviateNumber(item.programs[item.programs.length - 1].programPrice)
    //   : "$" + abbreviateNumber(item.programs[0].programPrice);

    let programmFees =
      item &&
      item.programs &&
      item.programs[0] &&
      item.programs[0].programPrice &&
      item.programs[0].programPrice;

    let rangeFees =
      item &&
      item.programs &&
      item.programs[item.programs.length - 1] &&
      item.programs[item.programs.length - 1].programPrice &&
      item.programs[item.programs.length - 1].programPrice;
    return item.programs.length > 1 && item.boxName != programBoxName.PAY_PER_CYCLE
      ? "$" + abbreviateNumber(programmFees) + " - " + "$" + abbreviateNumber(rangeFees)
      : "$" + programmFees.toLocaleString("en");
  };

  const getRetrievals = (item) => {
    return item.boxName != programBoxName.PAY_PER_CYCLE && item.programs.length > 1
      ? item.programs[0].noOfCycle + "-" + item.programs[item.programs.length - 1].noOfCycle
      : item.programs[0].noOfCycle;
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name == "mcpShowEstimate" && value == "Yes") {
      values.mcpHideReason = "";
    }
    if (name == "mcpShowEstimate") {
      value = value == "Yes" ? true : false;
    }
    setValues({
      ...values,
      [name]: value
    });
  };
  const editProgram = () => {
    setEditProgram(true);
    setValues(editProgramData);
  };
  const saveProgram = () => {
    setEditProgram(false);
    setConfirmModal(false);
    const payload = {
      estimateTpId: estimate.finEstimateTpId,
      estimateId: estimate.estimateId,
      practiceId,
      data: values
    };
    actions.addFapVisibility(payload);
  };
  const closeMessageModal = () => {
    let payload = {
      practiceId,
      estimateId: estimate.estimateId
    };
    actions.getEstimateCard(payload);
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const getEditProgramFapVisibilityData = () => {
    let practiceId = localStorage.getItem("practiceId");
    const payload = {
      estimateTpId: estimate.finEstimateTpId,
      estimateId: estimate.estimateId,
      practiceId
    };
    actions.getEditProgramFapVisibility(payload);
  };
  return (
    <Box className="p-8">
      {fapViewError == "Programs not found" ? (
        <Typography className="text-center">
          There are no fertility access programs available/applicable
        </Typography>
      ) : (
        <Box>
          <Card elevation={0} variant="outlined">
            <div style={styles.row}>
              <div style={styles.fap_header}>
                <img alt="" style={styles.fap_logo} src="/Assets/fap-removebg.png" />

                <div style={styles.fav_devider}></div>
                <div style={{ flexDirection: "row", display: "flex", marginBottom: 10 }}>
                  <Typography sx={styles.text_regular}>
                    We partner with Fertility Access to offer Multi-Cycle Discount and Refund
                    Programs
                  </Typography>
                  {estimate.estimateType != "P" &&
                  estimate.estimateStatus == "In-Progress" &&
                  refundApplicationStatus == null ? (
                    <Link
                      underline="none"
                      component="button"
                      onClick={() => setEditProgram(true)}
                      style={{ marginLeft: 20 }}>
                      {fapVisibilityData.mcpShowEstimate ? "Edit" : "View"}
                      {fapVisibilityData.mcpShowEstimate && (
                        <img className="edit-icon" alt="" src="/Assets/edit.png" />
                      )}
                    </Link>
                  ) : null}
                </div>
              </div>
              {/* <Box>
                <img alt="" src="/Assets/fap.png" className="fap-icon" />
                <Typography>MULTICYCLE AND DISCOUNT PROGRAMS</Typography>
              </Box> */}
            </div>
            <Stack sx={styles.row}>
              <Typography
                variant="body2"
                sx={{
                  textAlign: "center",
                  fontSize: 13,
                  fontWeight: 400,
                  marginLeft: "10%",
                  marginRight: "10%",
                  marginTop: 2
                }}>
                Even with state-of-the-art technology and the best treatment available, it often
                takes women more than one IVF cycle to be successful. You may increase your
                likelihood of having a baby when you commit to multiple cycles, so we offer Refund
                and Multi-Cycle packages for a fixed, discounted fee that can make paying for
                treatment more manageable.
              </Typography>
            </Stack>

            <Box
              className="p-8 mt-8"
              sx={{
                justifyContent: "center",
                textAlign: "center"
              }}>
              <img alt="" src="/Assets/group-2.png" style={{ marginBottom: 15 }} />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center"
                }}>
                <div
                  style={{
                    height: 60,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#555",
                    fontSize: 26
                  }}>
                  <p>80%</p>
                </div>
                <div
                  style={{
                    width: 300,
                    height: 60,
                    display: "flex",
                    textAlign: "left",
                    alignItems: "center",
                    marginLeft: 8,
                    fontSize: 14
                  }}>
                  of patients who enroll in a Fertility Access Program bring home a baby*
                </div>
              </div>
              {/* <Typography>
                <span></span>
              </Typography> */}
            </Box>
            {isRefundProgram && fapVisibilityData.mcpShowEstimate && (
              <Grid container columnSpacing={1} p="4px" className="pad-b-0">
                <Grid item xs={xsValue}>
                  <Stack py={3} sx={{ background: "#1BA3AD" }}>
                    <Typography className="effective-text">
                      {"Ultimate peace of mind".toUpperCase()}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* https://secure1.capexmd.com:4443/LoanApplicationSystem/usf-prequalification-request.php */}
                  <Link
                    href={APPLY_LOAN}
                    underline="none"
                    className="link-text"
                    target="_blank"
                    style={{ paddingRight: 20, paddingTop: 24 }}>
                    Apply for a Loan
                  </Link>
                </Grid>
              </Grid>
            )}

            <Grid container columnSpacing={1} p="4px" className="pad-t-0">
              {fapCardsStore?.programVisualization &&
                fapCardsStore?.programVisualization.map((item, index) => {
                  let isShow = "block";
                  if (
                    !fapVisibilityData.mcpShowEstimate &&
                    item.boxName == programBoxName.REFUND_PROGRAM
                  ) {
                    isShow = "none";
                  }
                  let barHeight = 80;
                  let barDirection =
                    item.boxName == programBoxName.PAY_PER_CYCLE ? "bar-direction" : null;
                  let alignSelf =
                    item.boxName == programBoxName.PAY_PER_CYCLE ? "alignSelf height-50" : null;
                  if (item.boxName == programBoxName.PAY_PER_CYCLE) {
                    item.programs.sort((a, b) => {
                      return b.programPrice - a.programPrice;
                    });
                  }
                  return (
                    <Grid item xs={xsValue} key={index} sx={{ display: isShow }}>
                      <Card variant="outlined" className={"p-8 card-border bt-radius-0"}>
                        <Stack className="mt-16 min-h-103">
                          <Typography sx={{ my: 1 }}>
                            {item.boxName === "Non-Refund Program"
                              ? "Multi-Cycle Discount Program"
                              : item.boxName}
                          </Typography>
                          <Grid container>
                            <Grid item xs={9}>
                              <Typography variant="body2">
                                {item.boxName === programBoxName.PAY_PER_CYCLE
                                  ? getProgramsPrice(item) + " for 3 IVF + 3 FET cycles "
                                  : getProgramsPrice(item) + " for whole program"}
                                {/* {getProgramsPrice(item)} for whole program */}
                                {/* {getProgramsPrice(item)} for 3 IVF + 3 FET cycles */}
                              </Typography>
                              {item.boxName == programBoxName.PAY_PER_CYCLE && (
                                <Typography variant="body2">
                                  {"$" +
                                    (fapCardsStore.discountedGlobalTpPrice &&
                                      fapCardsStore.discountedGlobalTpPrice.toLocaleString("en")) +
                                    " per IVF cycle + " +
                                    "$" +
                                    (fapCardsStore.globalPriceOfFet &&
                                      fapCardsStore.globalPriceOfFet.toLocaleString("en")) +
                                    " per FET cycle"}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                          <InputLabel sx={{ my: 1 }}>
                            {fapViewProgram[item.boxName].programLabel}
                          </InputLabel>
                        </Stack>
                        <Stack
                          className="program-summary"
                          sx={{
                            backgroundColor: "#818294",
                            color: "#FFF"
                          }}>
                          <Typography variant="caption" className="font-w-500">
                            Program Summary
                          </Typography>
                          <li>
                            <Typography variant="caption" pl={1}>
                              {fapViewProgram[item.boxName].programSummary}
                            </Typography>
                          </li>
                          <li>
                            <Typography variant="caption" pl={1}>
                              {fapViewProgram[item.boxName].discountStr}
                            </Typography>
                          </li>
                        </Stack>
                        <Box
                          className={"bar-container " + barDirection}
                          sx={{
                            minHeight: barContainerHeight,
                            backgroundColor: "#EFEFEF",
                            color: "#FFF"
                          }}>
                          {/* {console.log("item.programs", item.programs)} */}
                          {item.programs.map((barItem, barIndex) => {
                            let mb10 =
                              item.boxName !== programBoxName.PAY_PER_CYCLE ? "m-b-10" : null;
                            if (item.boxName != programBoxName.PAY_PER_CYCLE && barIndex != 0) {
                              barHeight = barHeight + 20;
                            }
                            let pNames = [];

                            if (
                              barItem &&
                              barItem.programName &&
                              barItem.programName.toLowerCase() == "multi-cycle discount program 2+"
                            ) {
                              pNames = ["2 IVF Cycles", "Unlimited FETs"];
                            } else if (
                              barItem &&
                              barItem.programName &&
                              barItem.programName.toLowerCase() == "multi-cycle discount program 3+"
                            ) {
                              pNames = ["3 IVF Cycles", "Unlimited FETs"];
                            } else if (
                              barItem &&
                              barItem.programName &&
                              barItem.programName.toLowerCase() == "50% refund program 2+"
                            ) {
                              pNames = ["2 IVF Cycles", "Unlimited FETs", "50% Refund"];
                            } else if (
                              barItem &&
                              barItem.programName &&
                              barItem.programName.toLowerCase() == "100% refund program 3+"
                            ) {
                              pNames = ["3 IVF Cycles", "Unlimited FETs", "100% Refund"];
                            }

                            return (
                              <Box
                                className={"bar-box " + alignSelf + " " + mb10}
                                key={barIndex}
                                sx={{
                                  height: barHeight,
                                  backgroundColor: "#1BA3AD",
                                  color: "#FFF",
                                  display: "flex",
                                  flexDirection: "column",
                                  width: barItem.programName.length >= 32 ? 85 : 65
                                }}>
                                <div style={{ fontSize: 13 }}>
                                  {"$" + abbreviateNumber(barItem.programPrice)}
                                </div>
                                <div>
                                  {pNames && pNames.length ? (
                                    <div
                                      style={{
                                        flexDirection: "column",
                                        display: "flex",
                                        margin: 1,
                                        padding: 1,
                                        fontSize: 10,
                                        justifyContent: "space-between"
                                      }}>
                                      {pNames.map((pName, newindex) => {
                                        return <div key={newindex}>{pName}</div>;
                                      })}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        flexDirection: "column",
                                        display: "flex",
                                        margin: 1,
                                        padding: 1,
                                        fontSize: 10,
                                        justifyContent: "space-between"
                                      }}>
                                      {barItem.programName}
                                    </div>
                                  )}
                                </div>
                              </Box>
                            );
                          })}
                        </Box>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
            <Stack
              className="align-center compare-details"
              sx={{ backgroundColor: "#1BA3AD", color: "#FFF" }}>
              <Typography>Compare the Details</Typography>
            </Stack>
            <Grid container columnSpacing={2}>
              {isRefundProgram && fapVisibilityData.mcpShowEstimate && (
                <Grid className="" item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <Typography variant="body2">Refund Programs</Typography>
                  </Stack>
                </Grid>
              )}
              {isNonRefundProgram && (
                <Grid className="" item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <Typography variant="body2">Multi-Cycle Discount Programs</Typography>
                  </Stack>
                </Grid>
              )}
              {isSingleCycle && (
                <Grid className="" item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <Typography variant="body2">Pay-Per Cycle Program</Typography>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <Stack
              className="program-summary align-center p-2"
              sx={{ backgroundColor: "#818294", color: "#fff" }}>
              <Typography variant="body2">Increased Chance of Success</Typography>
            </Stack>
            <Grid container columnSpacing={2}>
              {isRefundProgram && fapVisibilityData.mcpShowEstimate && (
                <Grid item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <CheckBoxSharp color="primary" sx={styles.updatedcolor} />
                    <InputLabel sx={{ fontSize: "12px" }}>
                      80% of enrolled patients bring home a baby
                    </InputLabel>
                  </Stack>
                </Grid>
              )}
              {isNonRefundProgram && (
                <Grid item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <CheckBoxSharp color="primary" sx={styles.updatedcolor} />
                    <InputLabel sx={{ fontSize: "12px", px: 3 }}>
                      80% of enrolled patients bring home a baby
                    </InputLabel>
                  </Stack>
                </Grid>
              )}
              {isSingleCycle && (
                <Grid item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <DisabledByDefaultOutlined color="primary" sx={{ py: 1, color: "#1BA3AD" }} />
                  </Stack>
                </Grid>
              )}
            </Grid>
            <Stack
              className="program-summary align-center p-2"
              sx={{ backgroundColor: "#818294", color: "#fff" }}>
              <Typography variant="body2">Refund Available</Typography>
            </Stack>
            <Grid container columnSpacing={2}>
              {isRefundProgram && fapVisibilityData.mcpShowEstimate && (
                <Grid item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <CheckBoxSharp color="primary" sx={styles.updatedcolor} />
                    <InputLabel sx={{ fontSize: "12px" }}>
                      Refund If you don&apos;t bring home a baby
                    </InputLabel>
                  </Stack>
                </Grid>
              )}
              {isNonRefundProgram && (
                <Grid item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <DisabledByDefaultOutlined color="primary" sx={{ py: 1, color: "#1BA3AD" }} />
                  </Stack>
                </Grid>
              )}
              {isSingleCycle && (
                <Grid item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <DisabledByDefaultOutlined color="primary" sx={styles.updatedcolor} />
                    <InputLabel sx={{ fontSize: "12px" }}>Refund Programs</InputLabel>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <Stack
              className="program-summary align-center p-2"
              sx={{ backgroundColor: "#818294", color: "#fff" }}>
              <Typography variant="body2">Egg Retrievals</Typography>
            </Stack>
            <Grid container columnSpacing={2}>
              {fapCardsStore.programVisualization &&
                fapCardsStore.programVisualization.map((item, index) => {
                  if (
                    item.boxName == programBoxName.REFUND_PROGRAM &&
                    !fapVisibilityData.mcpShowEstimate
                  ) {
                    return;
                  }
                  return (
                    <Grid item xs={xsValue} key={index}>
                      <Stack className="compare-details-items align-center">
                        <CheckBoxSharp color="primary" sx={styles.updatedcolor} />
                        <InputLabel sx={{ fontSize: "12px" }}>
                          {getRetrievals(item) + " Retrieval"}
                        </InputLabel>
                      </Stack>
                    </Grid>
                  );
                })}
            </Grid>
            <Stack
              className="program-summary align-center p-2"
              sx={{ backgroundColor: "#818294", color: "#fff" }}>
              <Typography variant="body2">Unlimited Frozen Embryo Transfers</Typography>
            </Stack>
            <Grid container columnSpacing={2}>
              {isRefundProgram && fapVisibilityData.mcpShowEstimate && (
                <Grid item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <CheckBoxSharp color="primary" sx={{ py: 1, color: "#1BA3AD" }} />
                  </Stack>
                </Grid>
              )}
              {isNonRefundProgram && (
                <Grid item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <CheckBoxSharp color="primary" sx={{ py: 1, color: "#1BA3AD" }} />
                  </Stack>
                </Grid>
              )}
              {isSingleCycle && (
                <Grid item xs={xsValue}>
                  <Stack className="compare-details-items align-center">
                    <DisabledByDefaultOutlined color="primary" sx={styles.updatedcolor} />
                    <InputLabel sx={{ fontSize: "12px" }}>
                      Payment of{" "}
                      {fapCardsStore &&
                        fapCardsStore.globalPriceOfFet &&
                        "$" + fapCardsStore.globalPriceOfFet.toLocaleString("en")}{" "}
                      required for each FET
                    </InputLabel>
                  </Stack>
                </Grid>
              )}
            </Grid>
          </Card>
          <Stack>
            <Typography py={2}>
              <b className="loan-disclaimer">Loan term Disclaimer :</b> {loanDisclaimer}
            </Typography>
          </Stack>
          <Modal
            open={openEditProgram}
            onClose={() => setEditProgram(false)}
            backdrop="static"
            aria-labelledby="modal-edit-program">
            <Box className="edit-program-modal">
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="body2">Edit Program</Typography>
                <IconButton onClick={editProgram}>
                  <img alt="" src="/Assets/close.png" />
                </IconButton>
              </Stack>
              <Divider className="modal-divider" />
              <Stack>
                <FormControl>
                  <Typography className="m-t-10">{editProgramConstants.question}</Typography>
                  <RadioGroup
                    onChange={(e) => handleChange(e)}
                    value={values.mcpShowEstimate ? "Yes" : "No"}
                    name="mcpShowEstimate">
                    {editProgramConstants.radioOptions.map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={item.value}
                          control={<Radio />}
                          label={item.label}
                        />
                      );
                    })}
                    <Typography variant="subtitle1" className="note-for-no">
                      {editProgramConstants.noteForNo}
                    </Typography>
                  </RadioGroup>
                </FormControl>
                {!values.mcpShowEstimate ? (
                  <Stack sx={{ marginTop: 2 }}>
                    <Typography>{editProgramConstants.reasonLabel}</Typography>
                    <Select
                      sx={{ width: "60%" }}
                      name="mcpHideReason"
                      value={values.mcpHideReason || ""}
                      onChange={handleChange}>
                      {editProgramConstants.reasonOptions.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Stack>
                ) : null}
              </Stack>
              {loading ? (
                <Stack justifyContent="center" alignItems="center">
                  <CircularProgress size={50} />
                </Stack>
              ) : null}
              <Stack pt={4} direction="row" justifyContent="space-between">
                <Button variant="outlined" onClick={() => setEditProgram(false)}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={() => setConfirmModal(true)}>
                  Save
                </Button>
              </Stack>
            </Box>
          </Modal>
          <Modal open={confirmModal} backdrop="static" aria-labelledby="modal-add-title">
            <Box className="confirm-modal">
              <Stack direction="row" className="modal-stack">
                <Typography>Edit Confirmation</Typography>
                <IconButton onClick={() => setConfirmModal(false)}>
                  <img alt="" src="/Assets/close.png" />
                </IconButton>
              </Stack>
              <Divider className="modal-divider" />
              <Stack sx={{ py: 2 }}>
                <Typography>Are you sure you want to edit this section ?</Typography>
                <Typography>
                  Any changes you make will reset the subsequent section for this program.
                </Typography>
              </Stack>
              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Button
                  variant="outlined"
                  className="btn-primary"
                  onClick={() => setConfirmModal(false)}>
                  Cancel
                </Button>
                <Button variant="contained" className="btn-primary" onClick={saveProgram}>
                  Proceed
                </Button>
              </Stack>
            </Box>
          </Modal>
          <MessageModal
            open={isMessageModal}
            message={successMsg}
            error={errorMsg}
            closeModal={closeMessageModal}
          />
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = ({ fapReducer, estimateReducer }) => {
  return {
    editProgramData: fapReducer.editProgramData,
    addProgramData: fapReducer.addProgramData,
    loading: fapReducer.editProgramLoading,
    successMsg: fapReducer.success,
    errorMsg: fapReducer.error,
    fapViewError: estimateReducer.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEditProgramFapVisibility: (payload) => {
      dispatch(actionType.getEditProgramFapVisibility(payload));
    },
    addFapVisibility: (payload) => {
      dispatch(actionType.addFapVisibility(payload));
    },
    resetFapVisibilityData: () => {
      dispatch(actionType.resetFapVisibilityData());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getEstimateCard: (payload) => {
      dispatch(actionType.getEstimateCard(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FapView);
