import * as React from "react";
import { Box, Divider, FormControl, Grid, MenuItem, Select, Stack } from "@mui/material";
import TrakConfiguration from "./components/TrakConfiguration";
import FelixTrackConfiguration from "./components/FelixTrackConfiguration";

import { Outlet, useNavigate } from "react-router-dom";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";
import Breadcrumb from "../shared/components/Breadcrumb";
import "./components/Trak.scss";

const Admin = () => {
  const [practiceData, setPracticeData] = React.useState();
  const [config, setConfig] = React.useState("FAA");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setConfig(event.target.value);
    if (event.target.value === "FAA") {
      navigate("/trak/caselookup");
    } else {
      navigate("/trak/trak-patient-portal");
    }
  };

  const getSelectedConfig = (selected) => {
    setPracticeData(selected);
    console.log(practiceData);
  };

  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Stack
            direction="row"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}>
            <FormControl sx={{ width: 244, minWidth: 200, border: "unset" }} size="small">
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={config}
                IconComponent={ExpandMoreSharpIcon}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": { border: "unset" },
                  color: "#00889F",
                  "& .MuiSvgIcon-root": {
                    color: "#00889F"
                  }
                }}
                onChange={handleChange}>
                <MenuItem value={"FAA"}>Fertility Access Administration</MenuItem>
                <MenuItem value={"UA"}>User Administration</MenuItem>
              </Select>
            </FormControl>
            <Divider sx={{ height: 26, m: 0.5 }} orientation="vertical" />
            {/* breadcrumb start here */}
            <Breadcrumb />
            {/* breadcrumb end here */}
          </Stack>
        </Grid>

        <Grid container item xs={12}>
          <Grid container item xs={1.6} sx={{ background: "#818294", minHeight: "100vh" }}>
            {config === "UA" ? (
              <FelixTrackConfiguration getSelectedConfig={getSelectedConfig} />
            ) : (
              <TrakConfiguration getSelectedConfig={getSelectedConfig} />
            )}
          </Grid>
          <Grid item xs={10.4}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Admin;
