import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";

const DatePickerField = ({ label, mandatory, value, setValues, fieldName }) => {
  const { recordedDate, transactionDate } = value;
  return (
    <>
      <Stack direction="row" className="modal-stack">
        {label ? (
          <InputLabel required={mandatory} className="flex-2 word-break">
            {label}
          </InputLabel>
        ) : null}
        {/* <TextField
          {...props}
          size="small"
          sx={{ flex: 6}}
          value={value}
          type={type}
          // onInvalid={(e) => e.target.setCustomValidity(message)}
          placeholder={placeholder}
          onChange={onChange}
          InputProps={{
            inputProps: { min, max, pattern }
          }}
        /> */}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disableFuture
            size="small"
            openTo="day"
            value={fieldName === "recordedDate" ? recordedDate : transactionDate}
            onChange={(newValue) => {
              setValues({ ...value, [fieldName]: newValue });
            }}
            renderInput={(params) => (
              <TextField
                sx={{ flex: 6 }}
                {...params}
                size="small"
                className="patient-dropdown"
                fullWidth
              />
            )}></DatePicker>
        </LocalizationProvider>
      </Stack>
    </>
  );
};

export default DatePickerField;
