import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchMedcaseRevenue605(action) {
  const { payload } = action;
  try {
    const params = {
      endDate: payload.endDate,
      reportType: payload.reportType,
      startDate: payload.startDate
    };
    const requestBody = createRequestBody("get", `/financial_snapshots/reports`, params, payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.MEDCASE_REVENUE_605_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.MEDCASE_REVENUE_605_FAIL, errorMsg: error.message });
    }
  }
}

function* getMedcaseRevenue605Saga() {
  yield takeEvery(actionTypes.MEDCASE_REVENUE_605_REQUESTED, fetchMedcaseRevenue605);
}

export default function* medcaseRevenue605Saga() {
  yield all([getMedcaseRevenue605Saga()]);
}
