import React from "react";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import UtilitiesConfiguration from "./components/UtilitiesConfiguration";
import Breadcrumb from "../shared/components/Breadcrumb";
import "./components/Utilities.scss";
import { useSelector } from "react-redux";

const Utilities = () => {
  const getProfileDetails = useSelector((state) => state.profileDrawerReducer.profileDetails);
  return (
    <Box className="container">
      <Grid container>
        <Grid item xs={12}>
          <Stack
            direction="row"
            sx={{
              mx: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start"
            }}>
            <Stack sx={{ minWidth: 150 }} className="txt-capitalize">
              <Typography variant="body1">
                {getProfileDetails.firstName} &nbsp;
                {getProfileDetails.lastName}
              </Typography>
              <Typography variant="body1">({getProfileDetails.primaryRole})</Typography>
            </Stack>
            <Divider sx={{ height: 26, ml: 5.2 }} orientation="vertical" />
            <Breadcrumb />
          </Stack>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={1.6} sx={{ background: "#818294", minHeight: "100vh" }}>
            <UtilitiesConfiguration />
          </Grid>
          <Grid item xs={10.4}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Utilities;
