import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchMiscellaneousData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/caseUpdate/miscellaneous`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.MISCELLANEOUS_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.MISCELLANEOUS_DATA_FAILED, errorMsg: error.message });
    }
  }
}

function* getMiscellaneousData() {
  yield takeEvery(actionTypes.MISCELLANEOUS_DATA_REQUESTED, fetchMiscellaneousData);
}

function* fetchPatientContactData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/caseUpdate/patientContact`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PATIENT_CONTACT_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PATIENT_CONTACT_DATA_FAILED, errorMsg: error.message });
    }
  }
}

function* getPatientContactData() {
  yield takeEvery(actionTypes.PATIENT_CONTACT_DATA_REQUESTED, fetchPatientContactData);
}

function* fetchEnrolmentData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/caseUpdate/enrollmentData`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ENROLMENT_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.ENROLMENT_DATA_FAILED, errorMsg: error.message });
    }
  }
}

function* getEnrolmentData() {
  yield takeEvery(actionTypes.ENROLMENT_DATA_REQUESTED, fetchEnrolmentData);
}

function* fetchProgramData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceID}/programs`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PROGRAM_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PROGRAM_DATA_FAILED, errorMsg: error.message });
    }
  }
}

function* getProgramData() {
  yield takeEvery(actionTypes.PROGRAM_DATA_REQUESTED, fetchProgramData);
}

function* fetchPhysicianData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceID}/physicians`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PHYSICIAN_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PHYSICIAN_DATA_FAILED, errorMsg: error.message });
    }
  }
}

function* getPhysician() {
  yield takeEvery(actionTypes.PHYSICIAN_DATA_REQUESTED, fetchPhysicianData);
}

function* fetchMetaData() {
  try {
    const params = {
      limit: "100",
      offset: ""
    };
    const requestBody = createRequestBody("get", `/practices/metadata`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.METADATA_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.METADATA_DATA_FAILED, errorMsg: error.message });
    }
  }
}

function* getMetaData() {
  yield takeEvery(actionTypes.METADATA_DATA_REQUESTED, fetchMetaData);
}

function* fetchAccountFlagData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/caseUpdate/accountFlag`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ACCOUNT_FLAG_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.ACCOUNT_FLAG_DATA_FAILED, errorMsg: error.message });
    }
  }
}

function* getAccountFlagData() {
  yield takeEvery(actionTypes.ACCOUNT_FLAG_DATA_REQUESTED, fetchAccountFlagData);
}

function* updatedMiscellenousData() {
  yield takeEvery(actionTypes.MISCELLANEOUS_DATA_UPDATE_REQUESTED, updatedMiscellenous);
}
function* updatedMiscellenous(action) {
  const { medcaseId, data } = action.payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${medcaseId}/caseUpdate/miscellaneous`,
      "",
      data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.MISCELLANEOUS_DATA_UPDATE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.MISCELLANEOUS_DATA_UPDATE_FAILED,
        message: error.message,
        errorMsg: error.response.data
      });
    }
  }
}

function* updatedAccountFlagData() {
  yield takeEvery(actionTypes.ACCOUNT_FLAG_DATA_UPDATE_REQUESTED, updatedAccountFlag);
}
function* updatedAccountFlag(action) {
  const { medcaseId, data } = action.payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${medcaseId}/caseUpdate/accountFlag`,
      "",
      data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ACCOUNT_FLAG_DATA_UPDATE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ACCOUNT_FLAG_DATA_UPDATE_FAILED,
        message: error.message,
        errorMsg: error.response.data
      });
    }
  }
}

function* updatedPatienContactData() {
  yield takeEvery(actionTypes.PATIENT_CONTACT_DATA_UPDATE_REQUESTED, updatedPatienContact);
}
function* updatedPatienContact(action) {
  const { medcaseId, data } = action.payload;
  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${medcaseId}/caseUpdate/patientContact`,
      "",
      data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.PATIENT_CONTACT_DATA_UPDATE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.PATIENT_CONTACT_DATA_UPDATE_FAILED,
        message: error.message,
        errorMsg: error.response.data
      });
    }
  }
}

function* updatedEnrolmentData() {
  yield takeEvery(actionTypes.ENROLMENT_DATA_UPDATE_REQUESTED, updatedEnrolment);
}
function* updatedEnrolment(action) {
  const { medCaseId, data } = action.payload;

  try {
    const requestBody = createRequestBody(
      "put",
      `/medcases/${medCaseId}/caseUpdate/enrollmentData`,
      "",
      data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ENROLMENT_DATA_UPDATE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ENROLMENT_DATA_UPDATE_FAILED,
        message: error.message,
        errorMsg: error.response.data
      });
    }
  }
}

export default function* caseUpdateSaga() {
  yield all([
    getMiscellaneousData(),
    getEnrolmentData(),
    getPatientContactData(),
    getMetaData(),
    getPhysician(),
    getProgramData(),
    getAccountFlagData(),
    updatedMiscellenousData(),
    updatedPatienContactData(),
    updatedEnrolmentData(),
    updatedAccountFlagData()
  ]);
}
