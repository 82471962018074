import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* fetchUtilitySearchUsers(action) {
  const { payload } = action;
  try {
    const url =
      payload.inWspace === true
        ? `/practices/${payload.practiceId}/estimates`
        : `/practices/${payload.practiceId}/person`;
    const params = {
      limit: "100",
      ...payload.param
    };
    const requestBody = createRequestBody("get", url, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.UTILITYSEARCH_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionTypes.UTILITYSEARCH_LIST_FAIL, errorMsg: error.message });
  }
}

function* getUtilitySearchSaga() {
  yield takeEvery(actionTypes.UTILITYSEARCH_LIST_REQUESTED, fetchUtilitySearchUsers);
}

export default function* utilitySearchSaga() {
  yield all([getUtilitySearchSaga()]);
}
