import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPhoneData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/contacts/${payload.contactId}/phones`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.CONTACT_PHONE_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CONTACT_PHONE_DATA_FAIL,
        message: error.message
      });
    }
  }
}

function* getPhoneSaga() {
  yield takeEvery(actionTypes.CONTACT_PHONE_DATA_REQUESTED, fetchPhoneData);
}
function* fetchEmailData(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/contacts/${payload.contactId}/emails`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.CONTACT_EMAIL_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CONTACT_EMAIL_DATA_FAIL,
        message: error.message
      });
    }
  }
}

function* getEmailSaga() {
  yield takeEvery(actionTypes.CONTACT_EMAIL_DATA_REQUESTED, fetchEmailData);
}

export default function* phoneSaga() {
  yield all([getPhoneSaga(), getEmailSaga()]);
}
