import * as actionType from "../actions/ActionTypes";

const initialState = {
  emailTemplateList: [],
  emailTemplateSettings: [],
  addEmailTemplate: null,
  editEmailTemplate: null,
  deleteEmailTemplate: null,
  loading: false,
  error: null,
  success: null
};

const emailTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.EMAIL_TEMPLATE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EMAIL_TEMPLATE_LIST_SUCCESS: {
      return {
        ...state,
        emailTemplateList: action.payload,
        loading: false
      };
    }
    case actionType.EMAIL_TEMPLATE_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_EMAIL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        addEmailTemplate: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_EMAIL_TEMPLATE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.EDIT_EMAIL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        editEmailTemplate: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_EMAIL_TEMPLATE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_EMAIL_TEMPLATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state,
        deleteEmailTemplate: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_EMAIL_TEMPLATE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_EMAIL_TEMPLATE: {
      return {
        ...state,
        addEmailTemplate: null,
        editEmailTemplate: null,
        loading: null
      };
    }
    case actionType.RESET_EMAIL_TEMPLATE_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    case actionType.RESET_EMAIL_TEMPLATE_LIST: {
      return {
        ...state,
        emailTemplateList: [],
        loading: null
      };
    }
    case actionType.EMAIL_TEMPLATE_SETTING_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EMAIL_TEMPLATE_SETTING_SUCCESS: {
      return {
        ...state,
        emailTemplateSettings: action.payload,
        loading: false
      };
    }
    case actionType.EMAIL_TEMPLATE_SETTING_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default emailTemplateReducer;
