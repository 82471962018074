import * as actionType from "../actions/ActionTypes";
const initialState = {
  pricingModalDataList: [],
  loading: false,
  error: null,
  success: null
};

const pricingModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PRICING_MODAL_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PRICING_MODAL_DATA_SUCCESS: {
      return {
        ...state,
        pricingModalDataList: action.payload,
        loading: false
      };
    }
    case actionType.PRICING_MODAL_DATA_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default pricingModalReducer;
