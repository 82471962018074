import * as actionType from "../actions/ActionTypes";

const initialState = {
  addFeesList: [],
  addAddFees: null,
  deleteTransaction: null,
  loading: false,
  error: null,
  success: null
};

const addFeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADDFEES_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADDFEES_LIST_SUCCESS: {
      return {
        ...state,
        addFeesList: action.payload,
        loading: false
      };
    }
    case actionType.ADDFEES_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_ADDFEES_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_ADDFEES_SUCCESS: {
      return {
        ...state,
        addAddFees: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_ADDFEES_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }

    // case actionType.DELETE_TRANSACTION_REQUESTED: {
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // }
    // case actionType.DELETE_TRANSACTION_SUCCESS: {
    //   return {
    //     ...state,
    //     deleteTransaction: action.payload,
    //     loading: false,
    //     success: action.successMsg
    //   };
    // }
    // case actionType.DELETE_TRANSACTION_FAIL: {
    //   return {
    //     ...state,
    //     error: action.errorMsg,
    //     loading: false
    //   };
    // }
    case actionType.RESET_ADDFEES: {
      return {
        ...state,
        addAddFees: null,
        loading: null
      };
    }
    // case actionType.RESET_MESSAGE: {
    //   return {
    //     ...state,
    //     error: null,
    //     success: false
    //   };
    // }
    case actionType.RESET_ADDFEES_LIST: {
      return {
        ...state,
        addFeesList: [],
        loading: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default addFeesReducer;
