import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  Modal,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
// import moment from "moment/moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import CostCoverage from "./modals/CostCoverage";
import EstimateDetails from "./modals/EstimateDetails";
import * as actionType from "../actions";
import StageServiceItemList from "../../shared/components/StageServiceItemList";
import { getLocaleDateTime } from "../../shared/common";

const EstimateCardDetails = (props) => {
  const {
    actions,
    data,
    estimateId,
    stages,
    optional,
    necessary,
    adjustmentList,
    adjustmentLoading,
    necessaryLoading,
    stageLoading,
    estimate,
    openCost,
    planName,
    tpPrice,
    costData
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [headerText, setHeadertext] = useState("");
  const [openCostCoverage, setOpenCostCoverage] = useState(false);
  const [totalEstimate, setTotalEstimate] = useState(0);
  const [totalEstimateMax, setTotalEstimateMax] = useState(0);
  const [isEditTreatment, setIsEditTreatment] = useState(false);
  const [isEditCostCoverage, setIsEditCostCoverage] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const practiceId = localStorage.getItem("practiceId");

  useEffect(() => {
    // console.log("data", data);
    if (necessary.length) {
      let normalPrice = necessary.map(({ tpStage, serviceItems }) => {
        if (!tpStage.range) {
          return serviceItems.map((item) => item.grossPrice).reduce((a, b) => a + b, 0);
        }
      });
      let rangeMinPrice = necessary.map(({ tpStage, serviceItems }) => {
        if (tpStage.range) {
          return serviceItems.map((item) => item.grossPrice)[0];
        }
      });

      let rangeMaxPrice = necessary.map(({ tpStage, serviceItems }) => {
        if (tpStage.range) {
          return serviceItems.map((item) => item.grossPrice)[serviceItems.length - 1];
        }
      });
      const minPrice = rangeMinPrice.filter((item) => item).reduce((a, b) => a + b, 0);
      const maxPrice = rangeMaxPrice.filter((item) => item).reduce((a, b) => a + b, 0);

      if (data && data.treatmentType === "PGT-A Analysis") {
        let originalTpPrice = data && data.originalTpPrice;
        setTotalEstimate(
          originalTpPrice + minPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
        );

        setTotalEstimateMax(
          originalTpPrice + maxPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
        );
      } else {
        setTotalEstimate(
          tpPrice + minPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
        );

        setTotalEstimateMax(
          tpPrice + maxPrice + normalPrice.filter((item) => item).reduce((a, b) => a + b, 0)
        );
      }
    } else {
      if (data && data.treatmentType === "PGT-A Analysis") {
        let originalTpPrice = data && data.originalTpPrice;
        setTotalEstimate(originalTpPrice);
        setTotalEstimateMax(originalTpPrice);
      } else {
        setTotalEstimate(tpPrice);
        setTotalEstimateMax(tpPrice);
      }
    }
  }, [necessaryLoading]);

  const editTreatmentPlan = () => {
    setConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setConfirmModal(false);
  };

  const openEditTreatmentPlan = () => {
    closeConfirmModal();
    setIsEditTreatment(true);
    setHeadertext("Edit Treatment Plan");
    setIsOpen(true);
    actions.getFinancialEstimateQuestions(practiceId);
    actions.editTreatment();
  };

  const editCostCoverage = () => {
    setIsEditCostCoverage(true);
    setOpenCostCoverage(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const closeCostCoverageModal = () => {
    setOpenCostCoverage(false);
  };

  const extractUsername = (uname) => {
    let userName = "";
    if (uname) {
      let firstName = uname.substr(0, 1).toUpperCase();
      let lastName = uname.split(" ");
      const str2 =
        (lastName && lastName[1] && lastName[1].charAt(0).toUpperCase() + lastName[1].slice(1)) ||
        "";
      userName = firstName + ". " + str2;
    }

    return userName;
  };

  return (
    <Box className="card-section">
      {/* created by/modifed by */}
      <Grid container gap={1}>
        <Grid item xs={5}>
          <Stack>
            <InputLabel>Treatment Plan Created By</InputLabel>
            <Stack direction="row" gap={1}>
              <Typography variant="body2">
                {extractUsername(data.treatmentPlanCreatedBy)}
              </Typography>
              <Typography variant="body2">
                {getLocaleDateTime(data.treatmentPlanCreatedDate, "lll z")}
                {/* {moment(data.treatmentPlanCreatedDate).format("lll")} EST */}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={5}>
          <InputLabel>Treatment Plan Modified By</InputLabel>
          <Stack direction="row" gap={1}>
            <Typography variant="body2">{extractUsername(data.treatmentPlanCreatedBy)}</Typography>
            <Typography variant="body2">
              {getLocaleDateTime(data.treatmentPlanUpdationDate, "lll z")}
              {/* {moment(data.treatmentPlanUpdationDate).format("lll")} EST */}
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      {/* treatment plan */}
      <Card className="p-4 mt-8" variant="outlined">
        <CardContent className="remove-bottom-padding">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2">Treatment Plan</Typography>
            <Button
              className="add-btn"
              disabled={
                estimate.estimateStatus === "Expired" || estimate.estimateStatus === "Published"
              }
              onClick={editTreatmentPlan}>
              Edit
              <img
                className="edit-icon"
                alt=""
                color={
                  estimate.estimateStatus === "Expired" || estimate.estimateStatus === "Published"
                    ? "#rgba(0, 0, 0, 0.26)"
                    : ""
                }
                src="/Assets/edit.png"
              />
            </Button>
          </Stack>
          <Divider sx={{ background: "#1BA3AD", height: 1, my: 1 }} />
          <Stack>
            {/* <InputLabel>Plan Name</InputLabel> */}
            <Typography variant="body2">{planName}</Typography>
          </Stack>
        </CardContent>
      </Card>
      {/* Cost & Coverage */}
      <Card className="p-4 mt-8" variant="outlined">
        <CardContent className="remove-bottom-padding">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2">Cost</Typography>
            <Button
              className="add-btn"
              onClick={editCostCoverage}
              disabled={
                estimate.estimateStatus === "Expired" || estimate.estimateStatus === "Published"
              }>
              Edit
              <img
                className="edit-icon"
                alt=""
                color={
                  estimate.estimateStatus === "Expired" || estimate.estimateStatus === "Published"
                    ? "#rgba(0, 0, 0, 0.26)"
                    : ""
                }
                src="/Assets/edit.png"
              />
            </Button>
          </Stack>
          <Divider sx={{ background: "#1BA3AD", height: 1, my: 1 }} />
          <Grid container>
            <Grid item xs={6}>
              <Stack>
                <InputLabel>Services</InputLabel>
                <Typography variant="body2">{planName}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <InputLabel>Cost</InputLabel>
                <Typography variant="body2">
                  ${data && data.originalTpPrice && data.originalTpPrice.toLocaleString()}
                </Typography>
              </Stack>
            </Grid>
          </Grid>

          <Stack className="mt-8 ">
            {stageLoading ? (
              <Stack justifyContent="center" p={5} alignItems="center">
                <CircularProgress size={50} />
              </Stack>
            ) : (
              stages.map((item, index) => {
                return <Typography key={index}>{item.stageName}</Typography>;
              })
            )}
          </Stack>

          {adjustmentLoading ? (
            <Skeleton />
          ) : adjustmentList.length &&
            data &&
            data.treatmentType &&
            data.treatmentType !== "PGT-A Analysis" ? (
            <>
              <Stack py={1}>
                <Typography variant="body2">Adjustments</Typography>
              </Stack>
              {adjustmentList.map((item, i) => {
                return (
                  <Grid container key={i}>
                    <Grid item xs={6}>
                      <Stack>
                        <Typography>{item.type}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={6}>
                      <Stack width="100px" justifyContent="end">
                        <Typography>
                          {item.category === "G" || item.category === "LI"
                            ? item.amountType == "A"
                              ? ` - $${item && item.amount && item.amount.toLocaleString()}`
                              : ` - $${
                                  item &&
                                  item.amount &&
                                  Math.round(
                                    (item.amount / 100) * data.originalTpPrice
                                  ).toLocaleString()
                                }`
                            : `+ $${item && item.amount && item.amount.toLocaleString()}`}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : null}

          <Grid container className="add-to-practice">
            <Grid item xs={6}>
              <Typography variant="body2">
                {data && data.treatmentType && data.treatmentType === "PGT-A Analysis"
                  ? "Total Cost of Program"
                  : data && data.treatmentType && data.treatmentType === "IVF"
                  ? "Cost per Cycle"
                  : "Amount Due to Practice"}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                {data && data.treatmentType && data.treatmentType !== "PGT-A Analysis"
                  ? data && data.tpPrice && "$" + data.tpPrice.toLocaleString()
                  : data && data.originalTpPrice && "$" + data.originalTpPrice.toLocaleString()}
                {/* ${data && data.tpPrice && data.tpPrice.toLocaleString()} */}
              </Typography>
            </Grid>
          </Grid>
          <Box className="mt-8">
            <Typography variant="body2">Not Included But Necessary</Typography>
            {necessary.length ? <StageServiceItemList data={necessary} /> : null}
          </Box>
          <Divider className="mt-8" />
          <Grid container className="add-to-practice">
            <Grid item xs={6}>
              <Typography variant="body2">
                {data && data.treatmentType && data.treatmentType === "IVF"
                  ? "Total Estimated Cost per Cycle"
                  : "Total Estimated Costs"}
              </Typography>
              <Typography variant="caption">
                (Reflects only necessary services and includes costs due outside of the practice)
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">
                ${totalEstimate && totalEstimate.toLocaleString()}
                {necessary.some((item) => item.tpStage.range === true) > 0
                  ? " - $" + totalEstimateMax.toLocaleString()
                  : ""}
              </Typography>
            </Grid>
          </Grid>
          {data && data.depositeType === "Partial In Advance" ? (
            <>
              <Divider className="mt-8" />
              <Grid container className="add-to-practice">
                <Grid item xs={6}>
                  <Typography variant="body2">
                    Deposit Due to Begin Treatment
                    <Typography variant="caption" pl={1}>
                      {data.depositeComment ?? ""}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2">
                    ${data.depositeAmount && data.depositeAmount.toLocaleString()}
                  </Typography>
                </Grid>
              </Grid>
            </>
          ) : null}
          <Divider className="mt-8" />
          <Box className="mt-8">
            <Typography variant="body2">Not Included But May Become Necessary</Typography>
            {optional.length ? <StageServiceItemList data={optional} /> : null}
          </Box>
        </CardContent>
      </Card>
      {isOpen ? (
        <EstimateDetails
          open={isOpen}
          isEdit={isEditTreatment}
          estimateId={estimateId}
          estimateTpId={data.finEstimateTpId}
          header={headerText}
          close={closeModal}
          openCost={openCost}
        />
      ) : null}
      {openCostCoverage ? (
        <CostCoverage
          open={openCostCoverage}
          isEdit={isEditCostCoverage}
          estimateId={estimateId}
          close={closeCostCoverageModal}
          costData={costData}
        />
      ) : null}

      <Modal open={confirmModal} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="confirm-modal">
          <Stack direction="row" className="modal-stack">
            <Typography>Edit Confirmation</Typography>
            <IconButton onClick={closeConfirmModal}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack sx={{ py: 2 }}>
            <Typography>Are you sure you want to edit this section ?</Typography>
            <Typography>
              Any changes you make will reset the subsequent section for this treatment plans.
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Button variant="outlined" className="btn-primary" onClick={closeConfirmModal}>
              Cancel
            </Button>
            <Button variant="contained" className="btn-primary" onClick={openEditTreatmentPlan}>
              Proceed
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
};

const mapStateToProps = ({ estimateReducer, costCoverageReducer }) => {
  return {
    stages: costCoverageReducer.stages,
    necessary: costCoverageReducer.necessary,
    optional: costCoverageReducer.optional,
    loading: costCoverageReducer.loading,
    success: costCoverageReducer.success,
    error: costCoverageReducer.error,
    addEstimate: estimateReducer.addEstimate,
    adjustmentList: costCoverageReducer.adjustmentList,
    adjustmentLoading: costCoverageReducer.adjustmentLoading,
    necessaryLoading: costCoverageReducer.necessaryLoading,
    stageLoading: costCoverageReducer.stageLoading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStages: (payload) => {
      dispatch(actionType.getStages(payload));
    },
    getNecessary: (payload) => {
      dispatch(actionType.getNecessary(payload));
    },
    getOptional: (payload) => {
      dispatch(actionType.getOptional(payload));
    },
    getAdjustments: (payload) => {
      dispatch(actionType.getAdjustments(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getFinancialEstimateQuestions: (payload) => {
      dispatch(actionType.getFinancialEstimateQuestions(payload));
    },
    editTreatment: () => {
      dispatch(actionType.editTreatment());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EstimateCardDetails);
