import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
// import * as token from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchMedcaseList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100",
      nameOrId: payload.nameOrId,
      clinicalLocation: payload.clinicalLocation
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/person`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.MEDCASE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.MEDCASE_LIST_FAILED, errorMsg: error.message });
    }
  }
}

function* getMedcaseListSaga() {
  yield takeEvery(actionTypes.MEDCASE_LIST_REQUESTED, fetchMedcaseList);
}
function* createMedcaseManually(action) {
  const { payload } = action;
  const { practiceId, PatientPartnerCouple } = payload;

  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/patientPartners`,
      "",
      PatientPartnerCouple
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CREATE_MEDCASE_MANUAL_SUCCESS,
      payload: response.data,
      successMsg: "Saved successfully"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CREATE_MEDCASE_MANUAL_FAILED,
        errorMsg: "Unable to save"
      });
    }
  }
}

function* createMedcaseManuallySaga() {
  yield takeEvery(actionTypes.CREATE_MEDCASE_MANUAL_REQUESTED, createMedcaseManually);
}
function* linkMedcase(action) {
  const payload = action.payload;

  try {
    const requestBody = createRequestBody("post", `/medcases`, "", payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // const response = yield call(() => apis.post(`/medcases`, payload, options));
    yield put({
      type: actionTypes.CREATE_LINK_MEDCASE_SUCCESS,
      payload: response.data,
      successMsg: `Medcase ${response.data.medcaseId} created successfully`
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CREATE_LINK_MEDCASE_FAILED,
        errorMsg: "Unable to create medcase"
      });
    }
  }
}

function* linkMedcaseSaga() {
  yield takeEvery(actionTypes.CREATE_LINK_MEDCASE_REQUESTED, linkMedcase);
}

function* linkManualMedcase(action) {
  const payload = action.payload;
  const currentMedcase = sessionStorage.getItem("link_medcase_id");
  const body = {
    linkMedcaseId: payload.medcaseId
  };

  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${currentMedcase}/linkMedcase`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.LINK_MEDCASE_SUCCESS,
      payload: response.data,
      successMsg: "Medcase Linked Successfully"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.LINK_MEDCASE_FAIL,
        errorMsg: error.response.data ? error.response.data : error
      });
    }
  }
}

function* linkManualMedcaseSaga() {
  yield takeEvery(actionTypes.LINK_MEDCASE_REQUESTED, linkManualMedcase);
}

function* unLinkMedcase(action) {
  const { payload } = action;

  try {
    const requestBody = createRequestBody("delete", `/medcases/${payload.medcaseId}`, "", payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // const response = yield call(() => apis.delete(`/medcases/${payload.medcaseId}`, options));

    yield put({
      type: actionTypes.UNLINK_MEDCASE_SUCCESS,
      payload: response.data,
      successMsg: "Unlinked Successfully"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.UNLINK_MEDCASE_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}
function* unLinkMedcaseSaga() {
  yield takeEvery(actionTypes.UNLINK_MEDCASE_REQUESTED, unLinkMedcase);
}

function* updatePatient(action) {
  const { payload } = action;
  const { practiceId, personId, partnerInfo } = payload;

  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/persons/${personId}/partners`,
      "",
      partnerInfo
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.UPDATE_PARTNER_SUCCESS,
      payload: response.data,
      successMsg: "Updated successfully"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.UPDATE_PARTNER_FAIL,
        errorMsg: "Unable to update"
      });
    }
  }
}

function* updatePatientSaga() {
  yield takeEvery(actionTypes.UPDATE_PARTNER_REQUESTED, updatePatient);
}

export default function* medcaseSaga() {
  yield all([
    getMedcaseListSaga(),
    createMedcaseManuallySaga(),
    linkMedcaseSaga(),
    linkManualMedcaseSaga(),
    unLinkMedcaseSaga(),
    updatePatientSaga()
  ]);
}
