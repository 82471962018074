import * as React from "react";
import { PropTypes } from "prop-types";
import { List, ListItem, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

const ListItemLink = (props) => {
  const { icon, primary, onClick, selected, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItem
      selected={selected}
      button
      component={renderLink}
      disablePadding
      sx={{
        minHeight: "70px"
      }}
      onClick={onClick}>
      <Stack direction="row">
        {icon ? <ListItemIcon sx={{ ml: 2, minWidth: "0" }}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </Stack>
    </ListItem>
  );
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onclick: PropTypes.func,
  selected: PropTypes.bool.isRequired
};
const UtilitiesConfiguration = () => {
  const utilitiesConfig = [
    {
      title: "NOTIFICATIONS",
      url: "/utilities/notifications",
      text: "Notification",
      icon: "/Assets/notificationsOutline.png"
    }
  ];
  const selectedPath = utilitiesConfig.findIndex((item) => item.url === location.pathname);

  const [selectedIndex, setSelectedIndex] = React.useState(selectedPath);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };
  return (
    <>
      <List disablePadding className="sidebar-container">
        {utilitiesConfig.map(({ title, url, icon }, index) => (
          <ListItemLink
            key={index}
            selected={selectedIndex === index}
            onClick={() => {
              handleListItemClick(index);
            }}
            to={url}
            primary={title}
            icon={<img src={icon} alt="" width="15px" className="menu-item-icon-color" />}
          />
        ))}
      </List>
    </>
  );
};

export default UtilitiesConfiguration;
