import * as actionType from "../actions/ActionTypes";

const initialState = {
  prsList: [],
  loading: false,
  newPRS: null,
  success: null,
  error: null
};

const prsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PRS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PRS_LIST_SUCCESS: {
      return {
        ...state,
        prsList: action.payload,
        loading: false
      };
    }
    case actionType.ADD_NEW_PRS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_PRS_SUCCESS: {
      return {
        ...state,
        newPRS: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_PRS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.RESET_PRS_LIST: {
      return {
        ...state,
        prsList: [],
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default prsReducer;
