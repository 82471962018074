import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Button, Typography, Stack } from "@mui/material";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { serviceItemsConfig } from "../../shared/constants/renderConfigs";
// import moment from "moment";
import { useNavigate } from "react-router-dom";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import moment from "moment";

const ServiceItems = (props) => {
  let serviceItemsFormData = {
    serviceCode: "",
    serviceDesc: "",
    unitPrice: "",
    stagePassthrough: false
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const gdmId = sessionStorage.getItem("gdmId");
  const practiceId = sessionStorage.getItem("practiceId");
  const [isMessageModal, setIsMessageModal] = useState(false);
  const gdmRowData = useSelector((state) => state.globalDataModelReducer.gdmRowData);
  const [values, setValues] = useState("");
  const disabled = localStorage.getItem("status") !== "In-progress" ? true : false;
  const navigate = useNavigate();
  const globalData = useSelector((state) => state.globalDataModelReducer.globalData);

  const columns = [
    {
      field: "serviceCode",
      width: 50,
      flex: 0.5,
      renderHeader: () => (
        <span style={{ lineHeight: 1, fontSize: 12 }}>
          SERVICE ITEM
          <br />
          ID/CPT 4 CODE
        </span>
      )
    },
    {
      field: "serviceDesc",
      headerName: "DESCRIPTION",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "unitPrice",
      headerName: "CURRENT PRICE",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>$ {row.unitPrice}</div>;
      }
    },
    {
      field: "stagePassthrough",
      headerName: "PASSTHROUGH FEE",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.effectiveDate ? moment(row.effectiveDate).format("MM/DD/YY") : "-";
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.5,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              handleEditServiceItems(row);
            }}>
            <EditIcon color={disabled ? "disabled" : "primary"}></EditIcon>
          </IconButton>
        </>
      )
    }
  ];
  const {
    serviceItemsList = [],
    actions,
    addServiceItems,
    editServiceItems,
    loading,
    errorMsg,
    successMsg
  } = props;
  const rows = serviceItemsList.map((item, index) => {
    console.log("zhr gdmRowData?.effectiveDate", gdmRowData?.effectiveDate);
    if (gdmRowData?.effectiveDate) item.effectiveDate = new Date(gdmRowData?.effectiveDate);
    else item.effectiveDate = "";
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    const payload = { practiceId: practiceId, gdmId: gdmId };
    actions.getServiceItems(payload);
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (serviceItemsList.length == 0 || addServiceItems || editServiceItems) {
      initFetch();
    }
    return () => {
      actions.resetServiceItems();
    };
  }, [addServiceItems, editServiceItems, errorMsg, successMsg]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    console.log("zhr --", name, value);
    setValues({
      ...values,
      [name]: value
    });
  };
  const addNewServiceItems = () => {
    serviceItemsFormData = { practiceId: practiceId, gdmId: gdmId };
    setValues(serviceItemsFormData);
    setIsOpen(true);
    setIsAdd(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const saveServiceItems = (event) => {
    event.preventDefault();

    // values.unitPrice = values.unitPrice
    //   ? (values.unitPrice >= 50 && Math.round(values.unitPrice / 100) * 100) || values.unitPrice
    //   : values.unitPrice;
    // console.log("values", values);
    if (values.stagePassthrough === "on") {
      values.stagePassthrough = true;
    } else {
      values.stagePassthrough = false;
    }
    if (isAdd) {
      actions.addNewServiceItems(values);
    } else {
      actions.editServiceItems(values);
    }
    actions.resetStages();
    closeModal();
  };

  const handleEditServiceItems = (row) => {
    setIsAdd(false);
    serviceItemsFormData = {
      serviceId: row.serviceId,
      serviceCode: row.serviceCode,
      serviceDesc: row.serviceDesc,
      unitPrice: row.unitPrice,
      practiceId: practiceId,
      gdmId: gdmId
    };
    setValues(serviceItemsFormData);
    setIsOpen(true);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const serviceItem = serviceItemsConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.serviceCode && values.serviceDesc && values.unitPrice ? false : true
  });

  const handleFileUpload = () => {
    navigate(`loadByExcel`);
  };

  const isDisabled =
    globalData && globalData.filter((row) => row.gdmId === gdmId && row.status === "In-progress");

  return (
    <>
      <Box
        sx={{ ml: 1 }}
        className={`${loading ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>Service Items List</Typography>
          <Stack direction="row">
            <Button
              variant="outlined"
              disabled={isDisabled.length ? false : true}
              className="btn-primary m-r-10"
              onClick={handleFileUpload}>
              Load from Excel
            </Button>
            <Button
              variant="contained"
              disabled={disabled}
              className="btn-usf"
              onClick={addNewServiceItems}>
              New Service Item
            </Button>
          </Stack>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal
        open={isOpen}
        config={serviceItem}
        isAdd={isAdd}
        handleSubmit={saveServiceItems}
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};
const mapStateToProps = ({ serviceItemReducer }) => {
  return {
    loading: serviceItemReducer.loading,
    serviceItemsList: serviceItemReducer.serviceItemsList,
    addServiceItems: serviceItemReducer.addServiceItems,
    editServiceItems: serviceItemReducer.editServiceItems,
    errorMsg: serviceItemReducer.error,
    successMsg: serviceItemReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    getServiceItems: (payload) => {
      dispatch(actionType.getServiceItems(payload));
    },
    addNewServiceItems: (payload) => {
      dispatch(actionType.addNewServiceItems(payload));
    },
    editServiceItems: (payload) => {
      dispatch(actionType.editServiceItems(payload));
    },
    resetServiceItems: () => {
      dispatch(actionType.resetServiceItems());
    },
    resetStages: () => {
      dispatch(actionType.resetStages());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ServiceItems);
