import React, { useCallback, useEffect, useState } from "react";
import { Box, Typography, Stack, Select, MenuItem, Button } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import MessageModal from "../../shared/components/MessageModal";
import { pgtaOptions } from "../../shared/constants/constants";
import moment from "moment/moment";
import { v4 as uuidv4 } from "uuid";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const Pgta = (props) => {
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState([]);
  const [changedValues, setChangedValues] = useState([]);
  const ddlOptions = pgtaOptions.map((option) => (
    <MenuItem value={option} key={option}>
      {option}
    </MenuItem>
  ));
  const columns = [
    {
      field: "practiceId",
      headerName: "ID",
      width: 100,
      flex: 1
    },
    {
      field: "practiceName",
      headerName: "PRACTICE NAME",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "selectionChoice",
      headerName: "SELECTION CHOICE",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Select
            fullWidth
            name="prScale"
            value={row.selectionChoice}
            onChange={(e) => handleChange(e, row)}
            className="stage-practice-dropdown">
            {ddlOptions}
          </Select>
        );
      }
    },
    {
      field: "information",
      headerName: "INFORMATION",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1
      // renderCell: ({ row }) => {
      //   return moment(row.effectiveDate).format("L");
      // }
    }
  ];

  const { pgtaList = [], actions, editRelativeValue, loading, errorMsg, successMsg } = props;

  useEffect(() => {
    let activeList = pgtaList.filter((item) => item.status == "Active");
    activeList = activeList.map((item) => {
      item.effectiveDate = moment(item.effectiveDate).format("L");
      return item;
    });
    setValues(activeList);
  }, [pgtaList]);

  const initFetch = useCallback(() => {
    setChangedValues([]);
    actions.getPGTAList();
  }, [editRelativeValue]);

  useEffect(() => {
    let activeList = pgtaList.filter((item) => item.status == "Active");
    setValues(activeList);
  }, [pgtaList]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (pgtaList.length == 0 || editRelativeValue) {
      initFetch();
    }
    return () => {
      actions.resetPGTAList();
    };
  }, [editRelativeValue, errorMsg, successMsg]);

  const handleChange = (e, row) => {
    let data = values.map((value) => {
      if (row.practiceId == value.practiceId) {
        value.selectionChoice = e.target.value;
        setChangedValues([...changedValues, value]);
      }
      return value;
    });
    setValues(data);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const savePgtaAnalysis = (e) => {
    e.preventDefault();
    actions.editPGTAList(changedValues);
  };
  return (
    <>
      <Box
        sx={{ ml: 1 }}
        className={`${loading ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>PGTA &nbsp;&nbsp;Program Selection Choice</Typography>
          <Button variant="contained" className="btn-usf" onClick={savePgtaAnalysis}>
            Save
          </Button>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rowHeight={80}
            rows={values}
            rowCount={values.length}
            getRowId={() => uuidv4()}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>

      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};
const mapStateToProps = ({ pgta }) => {
  return {
    loading: pgta.loading,
    pgtaList: pgta.pgtaList,
    editPGTA: pgta.pgta,
    errorMsg: pgta.error,
    successMsg: pgta.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPGTAList: () => {
      dispatch(actionType.getPGTAList());
    },
    editPGTAList: (payload) => {
      dispatch(actionType.editPGTAList(payload));
    },
    resetPGTAList: () => {
      dispatch(actionType.resetPGTAList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Pgta);
