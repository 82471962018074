import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* editExternalId(action) {
  const payload = action.payload;
  try {
    const params = {
      externalSystem: payload.externalDetails.externalSystem
    };
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.externalDetails.practiceId}/locations/${payload.locationId}/externalIds`,
      params,
      {
        externalSystem: payload.externalDetails.externalSystem,
        externalPracticeId: payload.externalDetails.externalPracticeId,
        externalLocationId: payload.externalDetails.externalLocationId
      }
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_EXTERNAL_ID_LIST_SUCCESS,
      payload: response.data,
      success: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ADD_EXTERNAL_ID_LIST_FAILED,
        message: error.message
      });
    }
  }
}
function* addExternalId(action) {
  const payload = action.payload;
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.externalDetails.practiceId}/locations/${payload.locationId}/externalIds`,
      "",
      {
        externalSystem: payload.externalDetails.externalSystem,
        externalPracticeId: payload.externalDetails.externalPracticeId,
        externalLocationId: payload.externalDetails.externalLocationId
      }
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_EXTERNAL_ID_LIST_SUCCESS,
      payload: response.data,
      success: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      let errMsg = (error && error.response && error.response.data) || error.message;
      yield put({
        type: actionTypes.ADD_EXTERNAL_ID_LIST_FAILED,
        message: errMsg
      });
    }
  }
}

function* fetchExternalIdList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/locations/${payload.locationId}/externalIds`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EXTERNAL_ID_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EXTERNAL_ID_LIST_FAILED,
        message: error.message
      });
    }
  }
}

function* fetchExternalSystemDetail(action) {
  const { payload } = action;
  try {
    const params = {
      externalSystemId: payload.externalSystemId
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/locations/${payload.locationId}/externalIds/externalSystemId`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EXTERNAL_SYSTEM_DETAIL_SUCCESS,
      payload: response?.data?.externalPracticeId || ""
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EXTERNAL_SYSTEM_DETAIL_FAILED,
        message: error.message
      });
    }
  }
}

function* getExternalIdSaga() {
  yield takeEvery(actionTypes.EXTERNAL_ID_LIST_REQUESTED, fetchExternalIdList);
}

function* addExternalIdSaga() {
  yield takeEvery(actionTypes.ADD_EXTERNAL_ID_LIST_REQUESTED, addExternalId);
}

function* editExternalIdSaga() {
  yield takeEvery(actionTypes.EDIT_EXTERNAL_ID_LIST_REQUESTED, editExternalId);
}

function* getExternalSystemSaga() {
  yield takeEvery(actionTypes.EXTERNAL_SYSTEM_DETAIL_REQUESTED, fetchExternalSystemDetail);
}

export default function* externalIdSaga() {
  yield all([
    getExternalIdSaga(),
    addExternalIdSaga(),
    editExternalIdSaga(),
    getExternalSystemSaga()
  ]);
}
