import * as actionType from "../actions/ActionTypes";

const initialState = {
  patientPortalAdminList: [],
  loading: false,
  error: null,
  success: false
};

const patientPortalAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PATIENT_PORTAL_ADMIN_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PATIENT_PORTAL_ADMIN_LIST_SUCCESS: {
      return {
        ...state,
        patientPortalAdminList: action.payload,
        loading: false
      };
    }
    case actionType.PATIENT_PORTAL_ADMIN_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_USERS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_USERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_USERS_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_PATIENT_PORTAL_ADMIN_LIST: {
      return {
        ...state,
        patientPortalAdminList: []
      };
    }
    case actionType.DELETE_USER_RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default patientPortalAdminReducer;
