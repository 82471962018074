import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* validateExcel(action) {
  const payload = action.payload;
  const { practiceId, gdmId, formdata, component, fileType } = payload;
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/gdms/${gdmId}/imports/validations/${component}`,
      "",
      formdata
    );
    const request = signatureV4CreateCanonicalRequest(
      requestBody,
      getTemporaryCredentials(),
      fileType
    );
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.VALIDATE_EXCEL_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.VALIDATE_EXCEL_FAIL,
        message: error.response.data
      });
    }
  }
}

function* validateExcelSaga() {
  yield takeEvery(actionTypes.VALIDATE_EXCEL_REQUESTED, validateExcel);
}

function* uploadExcel(action) {
  const payload = action.payload;
  const { practiceId, gdmId, validatedFileData, component } = payload;
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/gdms/${gdmId}/imports/${component}`,
      "",
      validatedFileData
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.UPLOAD_EXCEL_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.UPLOAD_EXCEL_FAIL,
        message: error.response.data
      });
    }
  }
}

function* uploadExcelSaga() {
  yield takeEvery(actionTypes.UPLOAD_EXCEL_REQUESTED, uploadExcel);
}

function* emptyErrorMsg(action) {
  const payload = action.payload;
  const { errorMessage } = payload;
  try {
    yield put({
      type: actionTypes.EMPTY_ERRORMSG_SUCCESS,
      payload: { errorMessage }
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EMPTY_ERRORMSG_FAIL,
        message: "Empty failed"
      });
    }
  }
}

function* emptyErrorMsgSaga() {
  yield takeEvery(actionTypes.EMPTY_ERRORMSG_REQUESTED, emptyErrorMsg);
}

function* emptyExcelStates() {
  try {
    yield put({
      type: actionTypes.EMPTY_EXCEL_STATES_SUCCESS
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EMPTY_EXCEL_STATES_FAILED,
        message: "Empty failed"
      });
    }
  }
}

function* emptyExcelStatesSaga() {
  yield takeEvery(actionTypes.EMPTY_EXCEL_STATES_REQUESTED, emptyExcelStates);
}

export default function* loadFromExcelSaga() {
  yield all([validateExcelSaga(), uploadExcelSaga(), emptyErrorMsgSaga(), emptyExcelStatesSaga()]);
}
