import React, { useEffect } from "react";
import { Box, Stack, Divider, Grid, InputLabel, Typography } from "@mui/material";
import { useParams, Link } from "react-router-dom";
import { useState } from "react";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import { getGengerFullName } from "../../shared/constants/constants";
const Personal = (props) => {
  const personDataState = useSelector((state) => state.patientReducer.personDataById);
  const partnerDataState = useSelector((state) => state.patientReducer.partnerData);
  const { actions } = props;
  const [personData, setPersonData] = useState({});
  const [partnerData, setPartnerData] = useState({});
  const { personId } = useParams();
  useEffect(() => {
    if (Object.values(personDataState)?.length) {
      setPersonData(personDataState);
    }
    // if (Object.values(partnerDataState).length) {
    setPartnerData(partnerDataState);
    // }
  }, [personId, personDataState, partnerDataState]);

  return (
    <Box className="pad-l-10">
      <Box className="m-t-10">
        <Typography variant="h2">Contact Information</Typography>
      </Box>
      <Divider className="divider-height" />
      <Grid container item className="pad-t-15">
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Cell Phone</InputLabel>
            <Typography>{personData?.cellPhone ? personData?.cellPhone : "--"}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Billing Address</InputLabel>
            {personData?.billingAddrLine1 ? (
              <Typography className="word-break">
                {personData?.billingAddrLine1 +
                  ", " +
                  personData?.billingAddrLine2 +
                  ", " +
                  personData.billingCity +
                  ", " +
                  personData.billingState +
                  "," +
                  personData.billingZip +
                  ", " +
                  personData.billingCountry}
              </Typography>
            ) : (
              <Typography>---</Typography>
            )}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Communication Preferences</InputLabel>
            <Typography>
              {personData?.commPreference ? personData?.commPreference : "---"}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container className="pad-t-15">
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Home Phone(Preferred)</InputLabel>
            <Typography>{personData?.homePhone ? personData?.homePhone : "---"}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Mailing Address</InputLabel>
            {personData?.mailingAddrLine1 ? (
              <Typography className="word-break">
                {
                  personData?.mailingAddrLine1 +
                    " " +
                    personData?.mailingAddrLine2 +
                    " " +
                    personData?.mailingCity +
                    " " +
                    personData?.mailingState +
                    " " +
                    personData?.mailingZip /* +
                  " " +
                  personData?.mailingZip */
                }
              </Typography>
            ) : (
              <Typography>---</Typography>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid container className="pad-t-15">
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Additional Phone</InputLabel>
            <Typography>
              {personData?.additionalPhone ? personData.additionalPhone : "---"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Email Address</InputLabel>
            <Typography>{personData?.email}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Patient Comment</InputLabel>
            <Typography>---</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid container className="pad-t-15">
        <Grid item xs={4}>
          <Stack direction="column">
            <InputLabel>Work Phone</InputLabel>
            <Typography>{personData?.workPhone ? personData.workPhone : "---"}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Box className="m-t-10">
        <Typography>Clincal Location and Team</Typography>
        <Divider className="divider-height" sx={{ height: "1px" }} />
        <Grid container className="pad-t-15">
          <Grid item xs={4}>
            <Stack direction="column">
              <InputLabel>Group/Location</InputLabel>
              <Typography>{personData.practiceId}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack direction="column">
              <InputLabel>Primary MD</InputLabel>
              <Typography>
                {((personData && personData.ivfMDFirstName) || "") +
                  " " +
                  ((personData && personData.ivfMDMidInitial) || "") +
                  " " +
                  ((personData && personData.ivfMDLastName) || "")}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box className="m-t-10">
        <Typography>Couple Related Information</Typography>
        <Divider className="divider-height" />
        <Grid container className="pad-t-15">
          <Grid item xs={4}>
            <Stack direction="column">
              <InputLabel>Active Partner({getGengerFullName(partnerData.gender)})</InputLabel>
              {partnerData.firstName ? (
                <Link
                  className="link-color"
                  to={`/patients/${partnerData.personId}/personal`}
                  onClick={() => {
                    actions.resetPersonalList();
                  }}>
                  {partnerData.firstName + " " + partnerData.lastName}
                </Link>
              ) : (
                <Typography>Unknown Unknown</Typography>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
const mapStateToProps = ({ patientReducer }) => {
  return {
    loading: patientReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    resetPersonalList: () => {
      dispatch(actionType.resetPersonalList());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Personal);
