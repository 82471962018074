import React, { useEffect, useState } from "react";
import LoadingModal from "../../trak/components/modals/loadingModal";
import {
  Box,
  Stack,
  TextField,
  Grid,
  Typography,
  Button,
  MenuItem,
  Select,
  Modal,
  IconButton,
  InputLabel,
  Divider
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as actionType from "../actions";
import { connect } from "react-redux";
import moment from "moment-timezone";
// import MailIcon from "@mui/icons-material/Mail";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Close } from "@mui/icons-material";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const NotificationView = (props) => {
  const formData = {
    from: null,
    to: null,
    subject: null,
    category: null,
    userId: localStorage.getItem("userId"),
    patientName: "",
    selectedProgram: null,
    treatmentType: null
  };
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [values, setValues] = useState(formData);
  const [subjectList, setSubjectList] = useState([]);

  const UNREAD_COLOR = "#D61C60";
  const READ_COLOR = "#00889F";

  const [messageModal, setMessageModal] = useState(false);
  const [messageData, setMessageData] = useState([]);
  const { metadataList = [], notificationList = [], actions, loading } = props;

  const columns = [
    {
      field: "category",
      headerName: "CATEGORY",
      width: 150,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <span>
            <IconButton
              onClick={() => {
                row.isRead = !row.isRead;
                toggleNotificationStatus(row);
              }}>
              <FiberManualRecordIcon
                sx={{ color: row.isRead ? READ_COLOR : UNREAD_COLOR, height: 13, width: 15 }}
              />
            </IconButton>
            {row.category}
          </span>
        );
      }
    },
    {
      field: "patientName",
      headerName: "PATIENT NAME",
      width: 100,
      editable: false,
      renderCell: ({ row }) => {
        return row?.patientName;
      }
    },
    {
      field: "subject",
      headerName: "SUBJECT",
      width: 250,
      editable: false
    },
    // {
    //   field: "body",
    //   headerName: "MESSAGE",
    //   width: 300,
    //   editable: false,
    //   renderCell: ({ row }) => (
    //     <Link
    //       to="/"
    //       onClick={() => {
    //         setMessageData(row);
    //         setMessageModal(true);
    //         if (!row.isRead) {
    //           row.isRead = true;
    //           toggleNotificationStatus(row);
    //         }
    //       }}>
    //       <Typography color="#00889F">View</Typography>
    //     </Link>
    //   )
    // },

    {
      field: "program",
      headerName: "SELECTED PROGRAM",
      width: 150,
      editable: false,
      renderCell: ({ row }) => {
        return row?.program;
      }
    },
    {
      field: "treatmentPlan",
      headerName: "TREATMENT PLAN",
      width: 150,
      editable: false,
      renderCell: ({ row }) => {
        return row?.treatmentPlan;
      }
    },
    {
      field: "mdName",
      headerName: "PRIMARY MD",
      width: 200,
      editable: false,
      renderCell: ({ row }) => {
        return row?.mdName;
      }
    },
    {
      field: "authorizedCycle",
      headerName: "Auth’d Cycle",
      width: 150,
      editable: false,
      renderCell: ({ row }) => {
        return row?.authorizedCycle;
      }
    },

    {
      field: "date",
      headerName: "DATE",
      width: 100,
      editable: false,
      renderCell: ({ row }) => {
        return moment(row?.date).format("L");
      }
    },
    {
      field: "treatmentType",
      headerName: "TREATMENT TYPE",
      width: 150,
      editable: false,
      renderCell: ({ row }) => {
        return row?.treatmentType;
      }
    }
  ];

  const callAPI = () => {
    actions.getNotifications({
      startDate: values.from,
      userId: values.userId,
      subject: values.subject,
      category: values.category,
      endDate: values.to,
      patientName: values.patientName,
      selectedProgram: values.selectedProgram,
      treatmentType: values.treatmentType
    });
  };

  // const getNotificationMetaData = () => {
  //   actions.getNotificationMetaData({
  //     userId: values.userId
  //   });
  // };

  const updateNotificationMessageStatus = (item) => {
    actions.updateNotificationMessageStatus({
      userId: values.userId,
      notificationId: item.notificationId,
      isRead: item.isRead
    });
  };

  console.log("metadataList:", metadataList);

  useEffect(() => {
    actions.getNotificationMetaData({
      userId: values.userId
    });
    actions.getNotifications({
      startDate: values.from,
      userId: values.userId,
      subject: values.subject,
      category: values.category,
      endDate: values.to,
      patientName: values.patientName,
      selectedProgram: values.selectedProgram,
      treatmentType: values.treatmentType
    });
  }, []);

  const parser = (data) => {
    const parser = new DOMParser();
    const document = parser.parseFromString(data, "text/html");
    return document.body.textContent || document.body.innerHTML || "";
  };
  const rows = notificationList.map((item, index) => {
    item.id = index;
    item.body = parser(item.body);
    return item;
  });

  const toggleNotificationStatus = (row_item) => {
    updateNotificationMessageStatus(row_item.row);
  };

  const closeMessageModal = () => {
    setMessageModal(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "category") {
      setSubjectList(categoriesWithSubject.get(value));
    }

    setValues({
      ...values,
      [name]: value
    });
  };

  const handleInputBoxChange = (event) => {
    const { name, value } = event.target;

    setValues({
      ...values,
      [name]: value
    });
  };

  const dateFormatForMesage = (date) => {
    if (!date || date == null || date == "") {
      return date;
    }
    //var d = new Date(date);
    // var tdate = moment(d).format("MM/DD/YYYY");
    // var ttime = moment(d).format("h:mm A");
    //let abc = moment.utc(date).tz("America/New_York").format("MM/DD/YYYY hh:mm A") + " EST";
    let abc = moment(date).format("MM/DD/YYYY hh:mm A") + " EST";
    //return tdate + " at " + ttime + " EST";
    return abc;
  };

  // function CustomToolbar() {
  //   return (
  //     <Box sx={{ ml: 1 }} className="m-r-8 ">
  //       <Stack direction="row" className="grid-header">
  //         <Typography variant="h2" sx={{ minHeight: "30px" }}>
  //           Notifications
  //         </Typography>
  //       </Stack>
  //       <Divider className="divider-height" />
  //       <Box className="stage-details-section m-10">
  //         <Stack className="pad-5" direction="row">
  //           <Grid container className="align-center">
  //             <Grid item xs={1.5}>
  //               <Typography>Find a Patient</Typography>
  //             </Grid>
  //             <Grid item xs={3.8}>
  //               <TextField
  //                 name="patientName"
  //                 value={values.patientName}
  //                 onChange={handleInputBoxChange}
  //                 flex={2}
  //               />
  //             </Grid>
  //             <Grid item xs={0.95}></Grid>
  //           </Grid>
  //         </Stack>
  //         <Stack className="pad-5" sx={{ my: 1 }} direction="row">
  //           <Grid container className="align-center">
  //             <Grid item xs={1.5}>
  //               <Typography>Category</Typography>
  //             </Grid>
  //             <Grid item xs={4.25}>
  //               <Stack direction="row">
  //                 <Select
  //                   labelId="categoryId"
  //                   id="categoryId"
  //                   name="category"
  //                   value={values.category}
  //                   flex={2}
  //                   defaultValue="Select Category"
  //                   className="patient-dropdown"
  //                   onChange={handleInputChange}>
  //                   <MenuItem value="">Select Category</MenuItem>
  //                   {categories?.map((item, index) => {
  //                     return (
  //                       <MenuItem value={item} key={index}>
  //                         {item}
  //                       </MenuItem>
  //                     );
  //                   })}
  //                 </Select>
  //               </Stack>
  //             </Grid>
  //             <Grid xs={0.5}></Grid>
  //             <Grid item xs={1.5}>
  //               <Typography style={{ marginLeft: "10px" }}>Subject</Typography>
  //             </Grid>
  //             <Grid item xs={4.2}>
  //               <Select
  //                 labelId="subjectId"
  //                 id="subjectId"
  //                 name="subject"
  //                 fullWidth
  //                 value={values.subject}
  //                 flex={3}
  //                 defaultValue="Select Subject"
  //                 className="patient-dropdown"
  //                 onChange={handleInputChange}>
  //                 <MenuItem value="">Select Subject</MenuItem>
  //                 {subjectList?.map((item, index) => {
  //                   return (
  //                     <MenuItem value={item} key={index}>
  //                       {item}
  //                     </MenuItem>
  //                   );
  //                 })}
  //               </Select>
  //             </Grid>
  //           </Grid>
  //         </Stack>
  //         <Stack className="pad-5" direction="row">
  //           <Grid container className="align-center">
  //             <Grid item xs={1.5}>
  //               <Typography>Start Date</Typography>
  //             </Grid>
  //             <Grid item xs={3.8}>
  //               <LocalizationProvider dateAdapter={AdapterMoment}>
  //                 <DatePicker
  //                   openTo="day"
  //                   views={["day", "month", "year"]}
  //                   disableFuture
  //                   value={values.from}
  //                   onChange={(newValue) => {
  //                     const format2 = "YYYY-MM-DD";
  //                     newValue = moment(newValue).format(format2);
  //                     setValues({ ...values, from: newValue });
  //                   }}
  //                   renderInput={(params) => (
  //                     <TextField {...params} size="small" className="patient-dropdown" fullWidth />
  //                   )}></DatePicker>
  //               </LocalizationProvider>
  //             </Grid>
  //             <Grid item xs={0.95}></Grid>
  //             <Grid item xs={1.5}>
  //               <Typography style={{ marginLeft: "10px" }}>End Date</Typography>
  //             </Grid>
  //             <Grid item xs={4.2}>
  //               <LocalizationProvider dateAdapter={AdapterMoment}>
  //                 <DatePicker
  //                   openTo="day"
  //                   views={["day", "month", "year"]}
  //                   disableFuture
  //                   fullWidth
  //                   value={values.to}
  //                   onChange={(newValue) => {
  //                     const format2 = "YYYY-MM-DD";
  //                     newValue = moment(newValue).format(format2);
  //                     setValues({ ...values, to: newValue });
  //                   }}
  //                   renderInput={(params) => (
  //                     <TextField {...params} size="small" className="patient-dropdown" fullWidth />
  //                   )}></DatePicker>
  //               </LocalizationProvider>
  //             </Grid>
  //           </Grid>
  //         </Stack>
  //       </Box>
  //       <Grid item xs={1}>
  //         <Button variant="contained" className="btn-usf" onClick={callAPI}>
  //           Search
  //         </Button>
  //       </Grid>
  //       <GridToolbarContainer
  //         sx={{
  //           alignItems: "center",
  //           display: "flex",
  //           justifyContent: "space-between",
  //           marginBottom: "10px"
  //         }}>
  //         {/* <Grid item xs={0.25}></Grid>
  //         <Grid item xs={1}>
  //           <Button variant="contained" className="btn-usf" onClick={callAPI}>
  //             Search
  //           </Button>
  //         </Grid> */}
  //       </GridToolbarContainer>
  //       <Typography sx={{ minHeight: "40px" }}>Notifications</Typography>
  //     </Box>
  //   );
  // }

  const categoriesWithSubject = new Map();
  if (metadataList.categorySubjectList) {
    metadataList.categorySubjectList.forEach((item) => {
      if (categoriesWithSubject.get(item.category)) {
        categoriesWithSubject.get(item.category).push(item.subject);
      } else {
        let values = [];
        values.push(item.subject);
        categoriesWithSubject.set(item.category, values);
      }
    });
  }

  const [categories, setCategories] = useState([]);
  const [selectedProgramList, setSelectedProgramList] = useState([]);
  const [treatmentTypeList, setTreatmentTypeList] = useState([]);

  useEffect(() => {
    if (metadataList) {
      let categoriesList = [];

      if (metadataList.categorySubjectList) {
        categoriesList = Array.from(
          new Set(metadataList.categorySubjectList.map((item) => item.category))
        );

        if (categoriesList !== undefined && categoriesList.length > 0) {
          categoriesList = categoriesList.filter((item) => item.toLowerCase() !== "document");
          setCategories(categoriesList);
        }
      }

      if (metadataList.treatmentTypeList) {
        const treatmentList = metadataList.treatmentTypeList.map(
          (treatmentType) => treatmentType.treatmentType
        );
        setTreatmentTypeList(treatmentList);
      }

      if (metadataList.selectedProgramList) {
        const programList = metadataList.selectedProgramList.map(
          (program) => program.selectedProgram
        );
        setSelectedProgramList(programList);
      }
    }
  }, [metadataList]);

  return (
    <>
      <Box className="pad-5 m-t-8">
        <Box sx={{ ml: 1 }} className="m-r-8 ">
          <Stack direction="row" className="grid-header">
            <Typography variant="h2" sx={{ minHeight: "30px" }}>
              Notifications
            </Typography>
          </Stack>
          <Divider className="divider-height" />
          <Box className="stage-details-section m-10">
            <Stack className="pad-5" direction="row">
              <Grid container className="align-center">
                <Grid item xs={1.5}>
                  <Typography>Find a Patient</Typography>
                </Grid>
                <Grid item xs={3.8}>
                  <TextField
                    name="patientName"
                    value={values.patientName}
                    onChange={handleInputBoxChange}
                    flex={2}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={0.95}></Grid>
              </Grid>
            </Stack>
            <Stack className="pad-5" sx={{ my: 1 }} direction="row">
              <Grid container className="align-center">
                <Grid item xs={1.5}>
                  <Typography>Category</Typography>
                </Grid>
                <Grid item xs={4.25}>
                  <Stack direction="row">
                    <Select
                      labelId="categoryId"
                      id="categoryId"
                      name="category"
                      value={values.category}
                      flex={2}
                      defaultValue="Select Category"
                      className="patient-dropdown"
                      onChange={handleInputChange}>
                      <MenuItem value="">Select Category</MenuItem>
                      {categories?.map((item, index) => {
                        return (
                          <MenuItem value={item} key={index}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Stack>
                </Grid>
                <Grid xs={0.5}></Grid>
                <Grid item xs={1.5}>
                  <Typography style={{ marginLeft: "10px" }}>Subject</Typography>
                </Grid>
                <Grid item xs={4.2}>
                  <Select
                    labelId="subjectId"
                    id="subjectId"
                    name="subject"
                    fullWidth
                    value={values.subject}
                    flex={3}
                    defaultValue="Select Subject"
                    className="patient-dropdown"
                    onChange={handleInputChange}>
                    <MenuItem value="">Select Subject</MenuItem>
                    {subjectList?.map((item, index) => {
                      return (
                        <MenuItem value={item} key={index}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
            </Stack>
            <Stack className="pad-5" sx={{ my: 1 }} direction="row">
              <Grid container className="align-center">
                <Grid item xs={1.5}>
                  <Typography>Start Date</Typography>
                </Grid>
                <Grid item xs={3.8}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      openTo="day"
                      views={["day"]}
                      disableFuture
                      value={values.from}
                      onChange={(newValue) => {
                        const format2 = "YYYY-MM-DD";
                        newValue = moment(newValue).format(format2);
                        setValues({ ...values, from: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          className="patient-dropdown"
                          fullWidth
                        />
                      )}></DatePicker>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={0.95}></Grid>
                <Grid item xs={1.5}>
                  <Typography style={{ marginLeft: "10px" }}>End Date</Typography>
                </Grid>
                <Grid item xs={4.2}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      openTo="day"
                      views={["day"]}
                      disableFuture
                      fullWidth
                      value={values.to}
                      onChange={(newValue) => {
                        const format2 = "YYYY-MM-DD";
                        newValue = moment(newValue).format(format2);
                        setValues({ ...values, to: newValue });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          className="patient-dropdown"
                          fullWidth
                        />
                      )}></DatePicker>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Stack>
            <Stack className="pad-5" direction="row">
              <Grid container className="align-center">
                <Grid item xs={1.5}>
                  <Typography>Selected Program</Typography>
                </Grid>
                <Grid item xs={3.8}>
                  <Select
                    labelId="selectedProgramId"
                    id="selectedProgram"
                    name="selectedProgram"
                    value={values.selectedProgram}
                    defaultValue="Select Selected Program"
                    className="patient-dropdown"
                    onChange={handleInputChange}
                    fullWidth>
                    <MenuItem value="">Select Program</MenuItem>
                    {selectedProgramList?.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={0.95}></Grid>
                <Grid item xs={1.5}>
                  <Typography>Treatment Type</Typography>
                </Grid>
                <Grid item xs={4.2}>
                  <Select
                    labelId="treatmentTypeId"
                    id="treatmentTypeId"
                    name="treatmentType"
                    value={values.treatmentType}
                    defaultValue="Select Treatment Type"
                    className="patient-dropdown"
                    onChange={handleInputChange}
                    fullWidth>
                    <MenuItem value="">Select Treatment Type</MenuItem>
                    {treatmentTypeList?.map((item, index) => (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Stack>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px"
            }}>
            <Grid item xs={0.25}></Grid>
            <Grid item xs={1}>
              <Button variant="contained" className="btn-usf" onClick={callAPI}>
                Search
              </Button>
            </Grid>
          </Box>

          <Typography sx={{ minHeight: "40px" }}>Notifications</Typography>
        </Box>
        <Box className="grid-style" sx={{ height: "860px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            // components={{
            //   Toolbar: CustomToolbar
            // }}
            className="mt-8"
            onRowClick={(row) => {
              let msgData = row && row.row;
              setMessageData(msgData);
              setMessageModal(true);
              if (!msgData.isRead) {
                msgData.isRead = true;
                toggleNotificationStatus(row);
              }
            }}
            localeText={"Export Button"}
            isExport="true"
          />
        </Box>
      </Box>
      <Modal open={messageModal} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="modal-style" sx={{ minWidth: "700px" }}>
          <Stack direction="row" className="grid-header">
            <Typography>Details</Typography>
            <IconButton color="primary" onClick={closeMessageModal}>
              <Close />
            </IconButton>
          </Stack>
          <Divider className="divider-height" />
          <Box className="stage-details-section m-10">
            <Stack direction="row" className="modal-stack">
              <Typography className="font-w-500">{messageData.category}</Typography>
            </Stack>
            <Stack direction="row" className="modal-stack">
              <Typography className="font-w-400">{messageData.subject}</Typography>
            </Stack>
            <Stack direction="row" className="modal-stack">
              <InputLabel>{dateFormatForMesage(messageData.date)}</InputLabel>
            </Stack>
            <hr />
            <br />
            <Stack direction="row" className="modal-stack">
              <Typography style={{ whiteSpace: "pre-line" }} component="span" variant="body1">
                {messageData.body != ""
                  ? messageData?.body?.split("#br#").join("\n")
                  : "No messages to display!"}
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <LoadingModal open={loading} />
    </>
  );
};
const mapStateToProps = ({ notificationViewReducer }) => {
  return {
    loading: notificationViewReducer.loading,
    notificationList: notificationViewReducer.notificationList,
    metadataList: notificationViewReducer.metadataList,
    errorMsg: notificationViewReducer.error,
    successMsg: notificationViewReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getNotifications: (payload) => {
      dispatch(actionType.getNotifications(payload));
    },
    getNotificationMetaData: (payload) => {
      dispatch(actionType.getNotificationMetaData(payload));
    },
    updateNotificationMessageStatus: (payload) => {
      dispatch(actionType.updateNotificationMessageStatus(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationView);
