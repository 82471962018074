import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  InputAdornment,
  InputLabel,
  Modal,
  TextField,
  Autocomplete,
  Stack
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteModal from "../../shared/components/DeleteModal";
import { useParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import * as actionType from "../actions";
import MessageModal from "../../shared/components/MessageModal";
import SearchIcon from "@mui/icons-material/Search";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CommonModal from "../../shared/components/CommonModal";
import { editStageConfig } from "../../shared/constants/renderConfigs";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const StageDetails = (props) => {
  let serviceItemsFormData = {
    serviceId: "",
    serviceDesc: "",
    serviceCode: "",
    units: "",
    grossPrice: "",
    unitPrice: ""
  };
  const { state } = useLocation();
  const navigate = useNavigate();
  const disabled = localStorage.getItem("status") !== "In-progress" ? true : false;

  const {
    actions,
    addStageToServiceItem,
    editStageToServiceItem,
    deleteStageToServiceItem,
    editStage,
    stageDetailList,
    loading,
    successMsg,
    errorMsg,
    successMsgDetail,
    stageList,
    stageListNotMapped,
    serviceItems,
    errorMsgDetail
  } = props;

  const columns = [
    {
      field: "serviceId",
      headerName: "SERVICE ITEM ID/CPT4 CODE",
      width: 50,
      flex: 0.5
    },
    {
      field: "serviceDesc",
      headerName: "DESCRIPTION",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "unitPrice",
      headerName: "CURRENT PRICE",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <span>$ {row.unitPrice}</span>;
      }
    },
    {
      field: "units",
      headerName: "QUANTITY",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "grossPrice",
      headerName: "GROSS PRICE",
      width: 50,
      editable: true,
      flex: 1,
      renderCell: ({ row }) => {
        return <span>$ {row.grossPrice}</span>;
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              handleEditServiceItem(row);
            }}>
            <EditIcon color={disabled ? "disabled" : "primary"}></EditIcon>
          </IconButton>
          <IconButton
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              setDeleteModal(true);
              setServiceId(row.serviceId);
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"} />
          </IconButton>
        </>
      )
    }
  ];
  const rows = stageDetailList.map((item, index) => {
    item.id = index;
    return item;
  });
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [values, setValues] = useState(serviceItemsFormData);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [serviceId, setServiceId] = useState("");
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [stageData, setStageData] = useState(state);
  const practiceId = sessionStorage.getItem("practiceId");
  const gdmId = sessionStorage.getItem("gdmId");
  const [isFirstTime, setIsFirstTime] = useState(false);
  const { stageId } = useParams();
  const [searchParams] = useSearchParams();
  const [isOpenStage, setIsOpenStage] = useState(false);
  const [stageValues, setStageValues] = useState(false);

  const initFetch = useCallback(() => {
    let payload = { practiceId, gdmId, stageId };
    actions.getStagesToServiceItemList(payload);
  }, []);

  useEffect(() => {
    if (state) {
      setIsFirstTime(true);
      setIsOpen(true);
      setIsAdd(true);
    }
    if (serviceItems.length == 0) {
      const payload = {
        practiceId,
        gdmId
      };
      actions.getServiceItems(payload);
    }
  }, []);

  useEffect(() => {
    if (addStageToServiceItem || editStageToServiceItem || deleteStageToServiceItem || editStage) {
      actions.getStages({ practiceId: practiceId, gdmId: gdmId });
    }
    if (
      stageDetailList.length == 0 ||
      addStageToServiceItem ||
      editStageToServiceItem ||
      deleteStageToServiceItem
    ) {
      initFetch();
    }

    return () => {
      actions.resetServiceItemStages();
      actions.resetStageList();
    };
  }, [addStageToServiceItem, editStageToServiceItem, deleteStageToServiceItem, editStage]);

  useEffect(() => {
    if (successMsg || errorMsg || successMsgDetail || errorMsgDetail) {
      setIsMessageModal(true);
    }
  }, [successMsg, successMsgDetail, errorMsg, errorMsgDetail]);

  const [stageName] = useState(searchParams.get("value"));

  useEffect(() => {
    if (stageName == 1) {
      stageList.forEach((item) => {
        console.log("zhr check", item.stageId == stageId);
        if (item.stageId == stageId) {
          if (item.stageCategory == "Necessary") {
            item.stageCategory = "Necessary but Not Included";
          }
          if (item.stageCategory == "Optional") {
            item.stageCategory = "Optional/May Become Necessary";
          }
          let stageRowData = {
            stageId: item?.stageId,
            stageCode: item?.stageCode,
            stageGroup: item?.stageGroup,
            stageName: item?.stageName,
            stageCategory: item?.stageCategory,
            isrange: item?.isrange,
            totalPrice: item?.totalPrice,
            globalPrice: item?.globalPrice,
            assistPrice: item?.assistPrice,
            sortStage: item?.sortStage,
            stagePassthrough: item?.stagePassthrough
          };
          setStageData(stageRowData);
        }
      });
    }
  }, [stageList, stageName]);

  useEffect(() => {
    if (stageName == 2) {
      stageListNotMapped.forEach((item) => {
        console.log("zhr check", item.stageId == stageId);
        if (item.stageId == stageId) {
          if (item.stageCategory == "Necessary") {
            item.stageCategory = "Necessary but Not Included";
          }
          if (item.stageCategory == "Optional") {
            item.stageCategory = "Optional/May Become Necessary";
          }
          let stageRowData = {
            stageId: item?.stageId,
            stageCode: item?.stageCode,
            stageGroup: item?.stageGroup,
            stageName: item?.stageName,
            stageCategory: item?.stageCategory,
            isrange: item?.isrange,
            totalPrice: item?.totalPrice,
            globalPrice: item?.globalPrice,
            assistPrice: item?.assistPrice,
            sortStage: item?.sortStage,
            stagePassthrough: item?.stagePassthrough
          };
          setStageData(stageRowData);
        }
      });
    }
  }, [stageListNotMapped, stageName]);

  const addNewServiceItems = () => {
    console.log("zhr values", values);
    setIsFirstTime(false);
    setIsOpen(true);
    setIsAdd(true);
    setValues(serviceItemsFormData);
  };
  const saveServiceItem = (e) => {
    e.preventDefault();
    let payload = {
      stageId: stageId,
      practiceId: practiceId,
      gdmId: gdmId,
      serviceId: values.serviceId,
      units: values.units
    };

    if (isAdd) {
      actions.addServiceToStage(payload);
    } else {
      actions.editServiceToStage(payload);
    }
    closeServiceItemModal();
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const closeServiceItemModal = () => {
    setIsOpen(false);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
    if (stageDetailList.length == 0) {
      navigateToStages();
    }
  };
  const navigateToStages = () => {
    navigate("/admin/gdm/stages");
  };
  const handleEditServiceItem = (row) => {
    setIsFirstTime(false);
    setIsOpen(true);
    setIsAdd(false);
    serviceItemsFormData = {
      stageId: stageId,
      practiceId: practiceId,
      gdmId: gdmId,
      serviceId: row.serviceId,
      units: row.units,
      unitPrice: row.unitPrice,
      grossPrice: row.grossPrice,
      serviceDesc: row.serviceDesc
    };
    setValues(serviceItemsFormData);
  };

  const handleInputChange = (e, fieldName, newValue) => {
    var { name, value } = e.target;
    if (name == "units" && value) {
      values.grossPrice = values.unitPrice * value;
      setValues({
        ...values,
        [name]: value
      });
    }

    console.log("handleInputChange zhr", e, fieldName, newValue);

    if (fieldName === "serviceId") {
      if (newValue != null) {
        setValues((state) => {
          return {
            ...state,
            [newValue.name]: newValue.value,
            units: newValue.units,
            unitPrice: newValue.unitPrice,
            grossPrice: newValue.grossPrice
          };
        });
      } else {
        setValues({ ...values, [fieldName]: "" });
      }
    }
  };

  const handleStageInputChange = (e) => {
    var { name, value } = e.target;
    if (name == "isrange") {
      value = e.target.checked;
    }
    setStageValues({
      ...stageValues,
      [name]: value
    });
  };

  const closeStageModal = () => {
    setIsOpenStage(false);
  };
  const handleEditStage = (row) => {
    let stageCategory = "Included";
    if (row.stageCategory == "Necessary but Not Included") {
      stageCategory = "Necessary";
    }
    if (row.stageCategory == "Optional/May Become Necessary") {
      stageCategory = "Optional";
    }
    let stageFormData = {
      stageId: row.stageId,
      practiceId: practiceId,
      gdmId: gdmId,
      stageCode: row.stageCode,
      stageName: row.stageName,
      stageGroup: row?.stageGroup,
      stageCategory: stageCategory,
      isrange: row?.isrange,
      globalPrice: row?.globalPrice,
      sortStage: row?.sortStage
    };
    setIsOpenStage(true);
    setStageValues(stageFormData);
  };
  const stage = editStageConfig({
    values: stageValues,
    onChange: handleStageInputChange,
    onClick: closeStageModal,
    isAdd: false,
    disabled: stageValues.stageCode && stageValues.stageName && stageValues.sortStage ? false : true
  });
  const saveStage = () => {
    actions.editStage(stageValues);
    setIsOpenStage(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const payload = { practiceId, gdmId, stageId, serviceId };
    actions.deleteServiceToStage(payload);
    closeDeleteModal();
  };
  console.log("errorMsgDetail", errorMsgDetail);

  const [filteredOptions, setFilteredOptions] = useState([]);
  useEffect(() => {
    console.log("zhr stageData", stageData);
    console.log("zhr stageList", stageList);
    console.log("zhr stageListNotMapped", stageListNotMapped);
    const filtered = serviceItems
      .filter((item) => stageData?.stagePassthrough === item.stagePassthrough)
      .map((item, index) => ({
        id: index,
        label: item.serviceCode,
        name: "serviceId",
        value: item.serviceId,
        units: item.units,
        unitPrice: item.unitPrice,
        grossPrice: item.grossPrice
      }));
    console.log("zhr serviceItems", serviceItems);
    console.log("zhr filtered", filtered);
    setFilteredOptions(filtered);
  }, [serviceItems, stageData]);

  return (
    <Box mx={1}>
      <Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography className="m-l-10">{stageData?.stageId}</Typography>
          <Button
            variant="contained"
            className="m-r-10"
            disabled={disabled}
            onClick={navigateToStages}>
            Save Stages
          </Button>
        </Stack>
      </Box>
      <Box className="stage-details">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack>
            <Typography variant="body2">Stage Details</Typography>
          </Stack>
          <Button
            disabled={disabled}
            variant="contained"
            onClick={() => {
              handleEditStage(stageData);
            }}>
            Edit
          </Button>
        </Stack>

        <Grid container gap={2}>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={3} my={1}>
                <Typography>StageCode/SIM group:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">{stageData?.stageCode}</Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={3} my={1}>
                <Typography>Group:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">{stageData?.stageGroup}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3} my={1}>
                <Typography>Is Range:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">{stageData?.isrange ? "Yes" : "No"}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Divider
              orientation="vertical"
              sx={{ borderRightWidth: " medium", borderColor: "#E2E2E2" }}
            />
          </Grid>

          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={3} my={1}>
                <Typography>Description:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">{stageData?.stageName}</Typography>
              </Grid>
              <Grid item xs={3} my={1}>
                <Typography>Category:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">{stageData?.stageCategory}</Typography>
              </Grid>
              <Grid item xs={3} my={1}>
                <Typography>Sort Order:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">{stageData?.sortStage}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className="price-details">
        <Stack direction="row">
          <Typography variant="body2">Price Details</Typography>
        </Stack>
        <Grid container gap={2}>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={3} my={1}>
                <Typography>Total Stage Price:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">$ {stageData?.totalPrice}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={3} my={1}>
                <Typography>Global Stage Price:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">$ {stageData?.globalPrice}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Divider
              orientation="vertical"
              sx={{ borderRightWidth: " medium", borderColor: "#E2E2E2" }}
            />
          </Grid>

          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={3} my={1}>
                <Typography>Assist Stage Price:</Typography>
              </Grid>
              <Grid item xs={8} my={1}>
                <Typography variant="body2">$ {Math.round(stageData?.assistPrice)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className="m-5">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography className="m-l-5">Item List</Typography>
          <Button variant="contained" disabled={disabled} onClick={addNewServiceItems}>
            Add Service Item
          </Button>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>

      {/* Add/edit service item to stages modal start */}
      <Modal open={isOpen} backdrop="static">
        <Box component="form" onSubmit={saveServiceItem} className="modal-style-stages">
          <Stack direction="row" className="modal-stack">
            <Typography>{isAdd ? "Add Item" : "Edit Item"}</Typography>
            <IconButton onClick={closeServiceItemModal} disabled={isFirstTime}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Grid container>
            {isAdd && (
              <Grid item xs={12}>
                <Stack direction="row" className="modal-stack">
                  <InputLabel sx={{ flex: 2 }}>Service Item</InputLabel>
                  <Autocomplete
                    sx={{ flex: 10 }}
                    disablePortal
                    size="small"
                    name="serviceDesc"
                    value={
                      filteredOptions.find((item) => item.value === values.serviceId)
                        ? filteredOptions.find((item) => item.value === values.serviceId).label
                        : ""
                    }
                    fullWidth
                    options={filteredOptions}
                    onChange={(e, value) => handleInputChange(e, "serviceId", value)}
                    popupIcon={<SearchIcon />}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                </Stack>
              </Grid>
            )}
            <Grid item xs={6}>
              <Stack direction="row" className="modal-stack">
                <InputLabel sx={{ flex: 2 }}>Item Price</InputLabel>
                <TextField
                  sx={{ flex: 4 }}
                  size="small"
                  fullWidth
                  name="unitPrice"
                  value={values?.unitPrice}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon fontSize="small" />
                      </InputAdornment>
                    )
                  }}
                  disabled
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" className="modal-stack">
                <InputLabel className="m-l-10" sx={{ flex: 2 }}>
                  Item Quantity
                </InputLabel>
                <TextField
                  fullWidth
                  sx={{ flex: 4 }}
                  size="small"
                  name="units"
                  autoComplete="off"
                  type="number"
                  InputProps={{ pattern: "^d*(.d{0,2})?$" }}
                  value={values?.units}
                  onChange={handleInputChange}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row" className="modal-stack">
                <InputLabel sx={{ flex: 2 }}>Gross Price</InputLabel>
                <TextField
                  fullWidth
                  sx={{ flex: 4 }}
                  size="small"
                  name="grossPrice"
                  autoComplete="off"
                  type="number"
                  disabled
                  InputProps={{ inputProps: { min: 0 } }}
                  value={values?.grossPrice}
                  onChange={handleInputChange}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={2} className="modal-bottom-section">
            <Button
              variant="outlined"
              className="btn-primary"
              disabled={isFirstTime}
              onClick={closeServiceItemModal}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={values?.serviceId && values?.units ? false : true}
              className="btn-usf">
              {isAdd ? "Add" : "Update"}
            </Button>
          </Stack>
        </Box>
      </Modal>
      {/*Add/edit Service Item modal end here */}

      <DeleteModal
        open={deleteModal}
        id={serviceId}
        handleDelete={handleDelete}
        close={closeDeleteModal}
      />
      {/* Message modal start*/}
      <MessageModal
        open={isMessageModal}
        message={successMsg ? successMsg : successMsgDetail}
        error={errorMsg ? errorMsg : errorMsgDetail}
        closeModal={closeMessageModal}
      />
      <CommonModal open={isOpenStage} config={stage} handleSubmit={saveStage} />
    </Box>
  );
};

const mapStateToProps = ({ stageDetailReducer, stageReducer, serviceItemReducer }) => {
  return {
    editStage: stageReducer.editStage,
    stageDetailList: stageDetailReducer.stageDetailList,
    addStageToServiceItem: stageDetailReducer.addStageToServiceItem,
    editStageToServiceItem: stageDetailReducer.editStageToServiceItem,
    deleteStageToServiceItem: stageDetailReducer.deleteStageToServiceItem,
    successMsg: stageDetailReducer.success,
    errorMsg: stageDetailReducer.error,
    loading: stageDetailReducer.loading,
    successMsgDetail: stageReducer.success,
    errorMsgDetail: stageReducer.error,
    stageList: stageReducer.stageList,
    stageListNotMapped: stageReducer.stageListNotMapped,
    serviceItems: serviceItemReducer.serviceItemsList
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStages: (payload) => {
      dispatch(actionType.getStages(payload));
    },
    editStage: (payload) => {
      dispatch(actionType.editStage(payload));
    },
    addServiceToStage: (payload) => {
      dispatch(actionType.addServiceToStage(payload));
    },
    editServiceToStage: (payload) => {
      dispatch(actionType.editServiceToStage(payload));
    },
    deleteServiceToStage: (payload) => {
      dispatch(actionType.deleteServiceToStage(payload));
    },
    getStagesToServiceItemList: (payload) => {
      dispatch(actionType.getStagesToServiceItemList(payload));
    },
    resetServiceItemStages: () => {
      dispatch(actionType.resetServiceItemStages());
    },
    resetStageList: () => {
      dispatch(actionType.resetStageList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getServiceItems: (payload) => {
      dispatch(actionType.getServiceItems(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(StageDetails);
