import { Box, Button, Grid, Typography, Divider, Modal, IconButton } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actionType from "../actions";
import { Stack } from "@mui/system";
import LoadingModal from "../../trak/components/modals/loadingModal";
import DataGridPremiumComp from "../components/DataGridPremiumComp";

const Draft = (props) => {
  const userId = localStorage.getItem("userId");

  const {
    actions,
    ListOfDraftMessages,
    ListOfMessagesLoading,
    deletedMessageLoading,
    downloadedFileUrl,
    fileLoading
  } = props;
  const [rows, setRows] = useState([]);
  const [openSelectedMessage, setOpenSelectedMessage] = useState({});
  const [selectedMessages, setSelectedMessages] = useState([]);
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [selectionModel, setSelectionModel] = useState([]);
  const [deleteMessageModalOpen, setDeleteMessageModalOpen] = useState(false);

  const navigateToReply = () => {
    navigate(`/messages/${selectedMessages[0].messageId}/reply`, {
      state: {
        data: selectedMessages[0]
      }
    });
  };
  const updateSelectedMessage = ({ row }) => {
    setOpenSelectedMessage(row);
  };

  const deleteMessageHandler = () => {
    let deletedMessagesIds = selectedMessages.map(({ messageId }) => ({ messageId }));
    let payload = {
      userId,
      deletedMessagesIds
    };
    actions.deleteMessageFromList(payload);
    setDeleteMessageModalOpen(true);
    setSelectionModel([]);
  };
  const downloadHandler = () => {
    selectedMessages[0].messageAttachment.map((item) => {
      let payload = {
        filePath: item.attachmentUrl
      };
      actions.getFile(payload);
    });
    setSelectionModel([]);
  };
  useEffect(() => {
    if (downloadedFileUrl) {
      let a = document.createElement("a");
      a.href = downloadedFileUrl;
      a.download = "document.pdf";
      a.click();
      actions.resetMessageExecuteStatus();
    }
  }, [downloadedFileUrl]);

  useEffect(() => {
    if (ListOfDraftMessages) {
      setRows([]);
      let rows = ListOfDraftMessages.messageList.map((item, index) => {
        item.id = index;
        return item;
      });
      setRows(rows);
    }
  }, [ListOfDraftMessages, ListOfMessagesLoading]);
  const deleteMessageModalHandler = () => {
    setDeleteMessageModalOpen(false);
  };
  const columns = [
    {
      field: "sentDate",
      headerName: "DATE",
      type: "date",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.sentDate ? row.sentDate : "--"}</Typography>;
      }
    },
    {
      field: "type",
      headerName: "TYPE",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography className="word-wrap">{row.type}</Typography>;
      }
    },
    {
      field: "assignedToName",
      headerName: "ASSIGNED TO",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography className="word-wrap">
            {row.assignedToName ? row.assignedToName : "--"}
          </Typography>
        );
      }
    },
    {
      field: "patientName",
      headerName: "PATIENT",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography className="word-wrap">
            {row.patientFirstName ? row.patientFirstName + " " + row.patientLastName : "--"}
          </Typography>
        );
      }
    },
    {
      field: "subject",
      headerName: "SUBJECT",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography className="word-wrap">{row.subject}</Typography>;
      }
    }
  ];
  return (
    <Box className={`${fileLoading || ListOfMessagesLoading ? "disable-section" : ""}`}>
      <Grid container className="mt-15">
        <Grid item xs={1.7} ml={3}>
          <Button
            variant="outlined"
            onClick={navigateToReply}
            disabled={selectedMessages.length != 1}>
            Continue editing
          </Button>
        </Grid>
        <Grid item xs={1.05}>
          <Button
            variant="outlined"
            onClick={deleteMessageHandler}
            disabled={selectedMessages.length == 0}>
            Discard
          </Button>
        </Grid>
        <Grid item xs={8.25}>
          <Button
            variant="outlined"
            onClick={downloadHandler}
            disabled={
              selectedMessages.length != 1 || !selectedMessages[0]?.messageAttachment?.length
            }>
            Download Attachment
          </Button>
        </Grid>
      </Grid>

      <Box className="grid-style-Inbox" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          sx={{ mx: 1 }}
          loading={ListOfMessagesLoading || fileLoading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          onRowClick={(e) => {
            updateSelectedMessage(e);
          }}
          checkboxSelection={true}
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
            const selectedIds = new Set(ids);
            setSelectedMessages(rows.filter((row) => selectedIds.has(row.id)));
          }}
          selectionModel={selectionModel}
        />
      </Box>
      {Object.keys(openSelectedMessage).length != 0 && (
        <Box className="selected-message-content">
          <Stack>
            <Typography>
              Your patient has selected a {openSelectedMessage.subject} in the patient portal
            </Typography>
            <Typography>
              From:
              {openSelectedMessage.patientFirstName +
                " " +
                openSelectedMessage.patientLastName +
                " "}
              on {openSelectedMessage.sentDate}
            </Typography>
            <Divider fitted="true" sx={{ mt: 2, mb: 2 }} />
            <Box
              dangerouslySetInnerHTML={{
                __html: openSelectedMessage?.body?.split("#br#").join("\n")
              }}
            />
          </Stack>
        </Box>
      )}
      {deletedMessageLoading ? (
        <LoadingModal />
      ) : (
        <Modal open={deleteMessageModalOpen} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal">
            <Stack direction="row" className="modal-stack">
              <Typography className="font-w-500">Alert</Typography>
              <IconButton onClick={deleteMessageModalHandler}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Box sx={{ py: 1 }}>
              <Typography>Messages deleted permanently</Typography>
            </Box>
          </Box>
        </Modal>
      )}
    </Box>
  );
};
const mapStateToProps = ({ messageReducer }) => {
  return {
    ListOfDraftMessages: messageReducer.ListOfDraftMessages,
    ListOfMessagesLoading: messageReducer.ListOfMessagesLoading,
    discardSuccess: messageReducer.discardSuccess,
    deletedMessageLoading: messageReducer.deletedMessageLoading,
    downloadedFileUrl: messageReducer.downloadedFileUrl,
    fileLoading: messageReducer.fileLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMessagesList: (payload) => {
      dispatch(actionType.getMessagesList(payload));
    },
    deleteMessageFromList: (payload) => {
      dispatch(actionType.deleteMessageFromList(payload));
    },
    resetMessageExecuteStatus: () => {
      dispatch(actionType.resetMessageExecuteStatus());
    },
    getFile: (payload) => {
      dispatch(actionType.getFile(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Draft);
