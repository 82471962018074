import React, { useState } from "react";
import { Box, List, ListItem, Stack, ListItemText, ListItemIcon } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { PropTypes } from "prop-types";

const ListItemLink = (props) => {
  const { icon, primary, onClick, selected, to, locationState } = props;
  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return (
          <RouterLink to={to} ref={ref} {...itemProps} state={{ locationState }} role={undefined} />
        );
      }),
    [to]
  );

  return (
    <ListItem
      selected={selected}
      button
      component={renderLink}
      disablePadding
      sx={{
        minHeight: "70px"
      }}
      onClick={onClick}>
      <Stack direction="row">
        {icon ? <ListItemIcon sx={{ ml: 2, minWidth: "0" }}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </Stack>
    </ListItem>
  );
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onclick: PropTypes.func,
  selected: PropTypes.bool.isRequired
};

const PatientConfiguration = ({ locationState, id }) => {
  const location = useLocation();

  const patientConfig = [
    {
      title: "PERSONAL",
      url: `${id}/personal`,
      text: "Personal",
      icon: "/Assets/patient-sidenavimg.png"
    },
    {
      title: "CLINICAL",
      url: `${id}/clinical`,
      text: "clinical",
      icon: "/Assets/patient-sidenavimg.png"
    },
    {
      title: "FERTILITY ACCESS PROGRAM",
      url: `${id}/fertilityAccessProgram`,
      text: "Fertility access program",
      icon: "/Assets/patient-sidenavimg.png"
    },
    {
      title: "DOCUMENTS",
      url: `${id}/financial`,
      text: "Financial",
      icon: "/Assets/patient-sidenavimg.png"
    }
  ];

  const selectedPath = patientConfig.findIndex((item) => location.pathname.includes(item.url));
  const [selectedIndex, setSelectedIndex] = useState(selectedPath);

  const handleListItemClick = (e, index) => {
    setSelectedIndex(index);
    e.stopPropagation();
  };
  return (
    <Box>
      <List disablePadding>
        {patientConfig.map(({ title, url, icon }, index) => (
          <ListItemLink
            key={index}
            locationState={locationState}
            selected={selectedIndex === index}
            onClick={(e) => {
              handleListItemClick(e, index);
              //getSelectedConfig(text);
            }}
            to={url}
            primary={title}
            icon={<img src={icon} alt="" width="20px" />}
          />
        ))}
      </List>
    </Box>
  );
};

export default PatientConfiguration;
