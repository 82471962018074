import React, { useEffect, useState } from "react";
import * as actionType from "../actions";
import CommonModal from "../../shared/components/CommonModal";
import DeleteModal from "../../shared/components/DeleteModal";
import Button from "@mui/material/Button";
import { transactionDetailsConfig } from "../../shared/constants/renderConfigs";
import MessageModal from "../../shared/components/MessageModal";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Box, Checkbox, Typography } from "@mui/material";
import moment from "moment";
import PatientPaymentsModal from "./modals/PatientPaymentsModal";
import WarningPopUp from "./modals/WarningPopUp";
import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const GeneticTransactionLog = (props) => {
  let initialData = {};
  const {
    transactionLog,
    deleteTransaction,
    error,
    success,
    actions,
    loading,
    paymentCreated,
    patientPaymentDetails,
    successPaymentDetails,
    successFees,
    successRefund,
    addPatientPaymentCC,
    addPatientPaymentCH,
    addPatientPaymentLN,
    addPatientPaymentWT
  } = props;
  const [rows, setRows] = useState(props.transactionLog);
  const [isOpen, setIsOpen] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState("");
  const [isDeletePayment, setIsDeletePayment] = useState(false);
  const [paymentType, setPaymentType] = useState(false);
  const [isWarning, setIsWarning] = useState(false);

  useEffect(() => {
    const updateData = prepareCsvData(transactionLog);
    setRows(updateData);
  }, [props.transactionLog]);

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      recordedDate: row?.recordedDate ? new Date(row.recordedDate) : "",
      transactionDate: row.transactionDate ? new Date(row.transactionDate) : "",
      invoiceDate: row.invoiceDate ? new Date(row.invoiceDate) : ""
    }));
  };

  useEffect(() => {
    if (error || success) {
      setIsMessageModal(true);
      actions.getGeneticTesting({ medcaseId: props.medcaseId });
    }
  }, [deleteTransaction]);

  useEffect(() => {
    actions.getGeneticTesting({ medcaseId: props.medcaseId });
  }, []);

  useEffect(() => {
    if (successPaymentDetails) {
      setIsDeletePayment(true);
    }
  }, [successPaymentDetails]);

  useEffect(() => {
    if (
      paymentCreated ||
      addPatientPaymentCC.length ||
      addPatientPaymentCH.length ||
      addPatientPaymentLN.length ||
      addPatientPaymentWT.length ||
      successFees ||
      successRefund
    ) {
      actions.getGeneticTesting({ medcaseId: props.medcaseId });
    }
  }, [
    paymentCreated,
    addPatientPaymentCC,
    addPatientPaymentCH,
    addPatientPaymentLN,
    addPatientPaymentWT,
    successFees,
    successRefund
  ]);

  const transactionLogColumns = [
    {
      field: "transactionId",
      headerName: "TRANSACTION ID",
      flex: 1,
      renderCell: ({ row }) => (
        <Button
          sx={{ color: "#000000DE", fontWeight: "normal" }}
          onClick={() => {
            setTransactionId(row.transactionId);
            if (row.transactionType == "Patient Payment" || row.transactionType == "Participant") {
              actions.getPatientPaymentDetails({
                transactionId: row.transactionId,
                medcaseId: props.medcaseId,
                paymentType: row.paymentMode
              });
              setPaymentType(row.paymentMode);
            } else if (
              row.transactionType == "Pre enrolled Lab Payout" ||
              row.transactionType == "Lab Payout"
            ) {
              setPaymentType("Lab Payout");
              const obj = {
                feeType: row.transactionType,
                amount: row.amount,
                user: row.user,
                recordedDate: row.recordedDate && moment(row.recordedDate).format("L"),
                transactionDate: moment(row.transactionDate).format("L"),
                comment: row.comment
              };
              setValues(obj);
              setIsOpen(true);
            } else if (row.transactionType == "Program Fee") {
              setPaymentType("fee");
              const obj = {
                feeType: row.transactionType,
                amount: row.amount,
                user: row.user,
                recordedDate: row.recordedDate && moment(row.recordedDate).format("L"),
                transactionDate: moment(row.transactionDate).format("L"),
                comment: row.comment
              };
              setValues(obj);
              setIsOpen(true);
            } else if (row.transactionType == "Service Revenue") {
              const obj = {
                feeType: row.transactionType,
                amount: row.amount,
                user: row.user,
                recordedDate: row.recordedDate && moment(row.recordedDate).format("L"),
                transactionDate: moment(row.transactionDate).format("L"),
                comment: row.comment
              };
              setValues(obj);
              setIsOpen(true);
            } else {
              const obj = {
                feeType: row.transactionType,
                amount: row.amount,
                user: row.user,
                recordedDate: row.recordedDate && moment(row.recordedDate).format("L"),
                transactionDate: moment(row.transactionDate).format("L"),
                comment: row.comment
              };
              setValues(obj);
              setIsOpen(true);
            }
          }}>
          {row.transactionId}
        </Button>
      )
    },
    {
      field: "amount",
      headerName: "TRANS AMOUNT",
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        let amount = row && row.amount && "$" + priceFormat(row.amount);
        amount = (amount && amount.replace("$-", "-$")) || "$0.00";
        let amountSign = amount;
        if (row.negativeFlag) {
          amountSign = "(" + amount + ")";
        }
        return row.amount == null ? "0" : amountSign;
      }
    },
    {
      field: "transactionType",
      headerName: "TRANSACTION TYPE",
      flex: 1,
      editable: false
    },
    {
      field: "paymentMode",
      headerName: "PAYMENT MODE",
      flex: 1,
      editable: false
    },
    {
      field: "medcaseId",
      headerName: "MEDCASE ID",
      flex: 1,
      editable: false
    },
    {
      field: "linkedMedcaseId",
      headerName: "LINKED MEDCASE ID",
      flex: 1,
      editable: false
    },
    {
      field: "programType",
      headerName: "LINKED MEDCASE PROGRAM",
      flex: 1,
      editable: false
    },

    {
      field: "userType",
      headerName: "USER",
      flex: 1,
      editable: false
    },

    {
      field: "numberOfEmbryo",
      headerName: "NO.OF EMBRYOS",
      flex: 1,
      editable: false
    },
    {
      field: "labName",
      headerName: "LAB NAME",
      flex: 1,
      editable: false
    },
    {
      field: "invoiceDate",
      headerName: "INVOICE DATE",
      flex: 1,
      editable: false,
      type: "date",
      valueFormatter: (params) => (params ? moment(params).format("MM/DD/YYYY") : null)
    },
    {
      field: "testSummary",
      headerName: "TEST SUMMARY",
      flex: 1,
      editable: false
    },
    {
      field: "testingEvent",
      headerName: "TEST EVENT",
      flex: 1,
      editable: false
    },
    {
      field: "transactionDate",
      headerName: "TRANS DATE",
      flex: 1,
      editable: false,
      type: "date",
      valueFormatter: (params) => (params ? moment(params).format("MM/DD/YYYY") : null)
    },
    {
      field: "recordedDate",
      headerName: "RECORDED DATE",
      flex: 1,
      editable: false,
      type: "date",
      valueFormatter: (params) => (params ? moment(params).format("MM/DD/YYYY") : null)
    },
    {
      field: "comment",
      headerName: "COMMENT",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <Checkbox key={"row" + row?.comment} disabled checked={row?.comment} />;
      }
    }
  ];
  const handleDeleteTransaction = (event) => {
    event.preventDefault();
    if (values.feeType == "Service Revenue") {
      setIsWarning(true);
    } else {
      setDeleteModal(true);
    }
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    setIsDeletePayment(false);
    actions.resetTransactionMessage();
  };
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleDelete = (e) => {
    e.preventDefault();
    initialData = {
      transactionId: transactionId,
      type: paymentType,
      medcaseId: props.medcaseId
    };
    actions.deleteTransaction({ ...initialData });
    closeDeleteModal();
    setIsOpen(false);
    setIsDeletePayment(false);
  };
  const transaction = transactionDetailsConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal
  });
  const deletePatientPayment = () => {
    setDeleteModal(true);
  };

  return (
    <>
      <Box className="grid-style pad-5" sx={{ minHeight: "200px" }}>
        <Typography sx={{ fontWeight: "bold" }} style={{ marginBottom: "10px" }}>
          PGTA Transaction Log
        </Typography>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows.length}
          getRowId={() => uuidv4()}
          columns={transactionLogColumns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          getRowClassName={(params) => {
            return params.row.isImportant ? "imp-trans" : "";
          }}
          pagination
        />
      </Box>
      <CommonModal open={isOpen} config={transaction} handleSubmit={handleDeleteTransaction} />
      <DeleteModal
        open={deleteModal}
        id={transactionId}
        close={closeDeleteModal}
        handleDelete={handleDelete}
      />
      <MessageModal
        open={isMessageModal}
        message={success}
        error={error}
        closeModal={closeMessageModal}
      />
      <PatientPaymentsModal
        open={isDeletePayment}
        patientPaymentDetails={patientPaymentDetails}
        paymentType={paymentType}
        isDelete={isDeletePayment}
        handleClose={() => {
          actions.resetGetPatientPayment();
          setIsDeletePayment(false);
        }}
        deletePayment={deletePatientPayment}
      />
      <WarningPopUp
        open={isWarning}
        closeModal={() => {
          setIsWarning(false);
          setIsOpen(false);
        }}
        error={"Delete related Lab Payout.Service Revenue automatically deleted."}
      />
    </>
  );
};

const mapStateToProps = ({
  geneticTestingReducer,
  transactionLogReducer,
  patientPaymentReducer,
  manualRefundReducer
}) => {
  return {
    transactionLog: geneticTestingReducer.transactionDetails,
    loading: geneticTestingReducer.loading,
    error: transactionLogReducer.error,
    success: transactionLogReducer.success,
    deleteTransaction: transactionLogReducer.deleteTransaction,
    patientPaymentDetails: patientPaymentReducer.paymentDetails,
    successPaymentDetails: patientPaymentReducer.successPaymentDetails,

    addPatientPaymentCC: patientPaymentReducer.addPatientPaymentCC,
    addPatientPaymentLN: patientPaymentReducer.addPatientPaymentLN,
    addPatientPaymentCH: patientPaymentReducer.addPatientPaymentCH,
    addPatientPaymentWT: patientPaymentReducer.addPatientPaymentWT,
    successFees: transactionLogReducer.successFees,
    successRefund: manualRefundReducer.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getGeneticTesting: (payload) => {
      dispatch(actionType.getGeneticTesting(payload));
    },
    deleteTransaction: (payload) => {
      dispatch(actionType.deleteTransaction(payload));
    },
    getPatientPaymentDetails: (payload) => {
      dispatch(actionType.getPatientPaymentDetails(payload));
    },
    resetGetPatientPayment: () => {
      dispatch(actionType.resetGetPatientPayment());
    },
    resetTransactionMessage: () => {
      dispatch(actionType.resetTransactionMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(GeneticTransactionLog);
