import { put, takeEvery, all, call } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPersonal(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/person/${payload.personId}`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.PERSONAL_DATA_IS_SUCCESS,
      payload: response
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PERSONAL_DATA_IS_FAILED, message: error.message });
    }
  }
}

function* getPersonalData() {
  yield takeEvery(actionTypes.PERSONAL_DATA_IS_REQUESTED, fetchPersonal);
}

function* fetchPartnerDetails(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/person/${payload.partnerId}`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GET_PARTNER_DETAIL_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_PARTNER_DETAIL_FAIL, message: error.message });
    }
  }
}

function* getPartnetDetailsSaga() {
  yield takeEvery(actionTypes.GET_PARTNER_DETAIL_REQUESTED, fetchPartnerDetails);
}

export default function* demographicSaga() {
  yield all([getPersonalData(), getPartnetDetailsSaga()]);
}
