import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Stack,
  // Select,
  // MenuItem,
  Button,
  Autocomplete
} from "@mui/material";
import QuickAdd from "../../shared/components/QuickAdd";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { v4 as uuidv4 } from "uuid";
import { getLocations, getMetadata, getPractices } from "../../admin/actions";
import * as actionType from "../actions";
import moment from "moment";
import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const Refund = (props) => {
  const formData = {
    medcaseId: "",
    transactionNo: "",
    programId: "",
    practiceName: "",
    locationName: "",
    from: null,
    to: null
  };
  const {
    locations = [],
    actions,
    loading,
    practices = [],
    metaData = [],
    refundList = []
  } = props;

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0
  });

  const [practiceId, setPracticeId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [searchTxt, setSearchTxt] = useState("");
  const [values, setValues] = useState(formData);

  const refundColumns = [
    {
      field: "patientName",
      headerName: "PATIENT NAME",
      width: 100
    },
    {
      field: "medcaseId",
      headerName: "MEDCASE ID",
      width: 100
    },
    {
      field: "transactionNo",
      headerName: "TRANSACTION NO",
      width: 100
    },
    {
      field: "personNo",
      headerName: "PERSON NO",
      width: 100
    },
    {
      field: "medcaseStatus",
      headerName: "MEDCASE STATUS",
      width: 100
    },
    {
      field: "treatmentPlan",
      headerName: "TREATMENT PLAN",
      width: 100
    },
    {
      field: "progShortName",
      headerName: "PROGRAM SHORT NAME",
      width: 100
    },
    {
      field: "practice",
      headerName: "PRACTICE NAME",
      width: 100
    },
    {
      field: "location",
      headerName: "LOCATION",
      width: 100
    },
    {
      field: "refundPercentage",
      headerName: "REFUND %",
      width: 100
    },
    {
      field: "refundAmount",
      headerName: "REFUND AMOUNT",
      width: 100,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.refundAmount);
      }
    },
    {
      field: "refundGenerationDate",
      headerName: "REFUND GENERATION DATE",
      width: 100,
      type: "date",
      renderCell: ({ row }) =>
        row.refundGenerationDate ? moment(row.refundGenerationDate).format("MM/DD/YYYY") : ""
    },
    {
      field: "lastOutcomeStatus",
      headerName: "LAST OUTCOME STATUS",
      width: 100
    },
    {
      field: "user",
      headerName: "USER",
      width: 100
    }
  ];
  const rowsData = refundList.map((item, index) => {
    item.refundAmount = Number(item.refundAmount);
    item.refundGenerationDate = item.refundGenerationDate
      ? new Date(item.refundGenerationDate)
      : "";
    item.id = index;
    item.treatmentPlan =
      item?.treatmentPlanCustom != null ? item?.treatmentPlanCustom : item?.treatmentPlan;
    return item;
  });

  useEffect(() => {
    actions.getMetaData();
    actions.getPractices();
  }, []);
  useEffect(() => {
    if (practiceId) {
      actions.getLocations(practiceId);
    }
    return () => {
      actions.resetRefundFilterList();
    };
  }, [practiceId]);

  function CustomToolbar() {
    return (
      <>
        <Typography>Refund List</Typography>
        <Grid>
          <Button variant="contained" className="btn-usf" onClick={searchData}>
            Search
          </Button>
        </Grid>
      </>
    );
  }

  const handleChangePracticeId = (e, name, value) => {
    if (value != null || value != undefined) {
      setPracticeId(value?.value);
      values.practiceName = value?.name;
    }
  };

  const handleLocationChange = (e, name, value) => {
    if (value != null || value != undefined) {
      setLocationId(value?.value);
      values.locationName = value?.name;
    }
  };

  const handleSearch = (e, search) => {
    setSearchTxt(search);
    const payload = {
      patientNameOrId: search,
      medcaseId: values.medcaseId,
      program: values.programId,
      practice: values.practiceName,
      location: values.locationName,
      transactionNo: values.transactionNo,
      fromRefundGenerationDate:
        values.from == null ? "" : moment(new Date(values.from)).format("YYYY-MM-DD"),
      toRefundGenerationDate:
        values.to == null ? "" : moment(new Date(values.to)).format("YYYY-MM-DD")
    };
    let validPayload = false;
    Object.values(payload).forEach((val) => {
      if (val !== "") {
        validPayload = true;
      }
    });
    if (validPayload) actions.getRefundFilterList(payload);
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function handleAutoCompSearch(e, name, value) {
    if (value !== null || value !== undefined || value !== "")
      setValues({ ...values, [name]: value });
  }

  const searchData = (e) => {
    e.preventDefault();
    const payload = {
      patientNameOrId: searchTxt,
      medcaseId: values.medcaseId,
      program: values.programId,
      practice: values.practiceName,
      location: values.locationName,
      transactionNo: values.transactionNo,
      fromRefundGenerationDate:
        values.from == null ? "" : moment(new Date(values.from)).format("YYYY-MM-DD"),
      toRefundGenerationDate:
        values.to == null ? "" : moment(new Date(values.to)).format("YYYY-MM-DD")
    };
    let validPayload = false;
    Object.values(payload).forEach((val) => {
      if (val !== "") {
        validPayload = true;
      }
    });
    if (validPayload) actions.getRefundFilterList(payload);
  };

  return (
    <Box>
      <Box>
        <QuickAdd text={1.5} search={7.5} handleSearchClick={handleSearch} />
        <Stack direction="row" className="pad-5">
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography>Medcase ID</Typography>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row">
                <TextField fullWidth size="small" name="medcaseId" onChange={handleChange} />
              </Stack>
            </Grid>
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Transaction No</Typography>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row">
                <TextField fullWidth size="small" name="transactionNo" onChange={handleChange} />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" className="pad-5">
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography>Program</Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="programId"
                value={values.programId}
                className="patient-dropdown"
                onChange={handleChange}>
                <MenuItem value="">Select</MenuItem>
                {Object.keys(metaData).length > 0 &&
                  metaData?.programName.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="programId"
                value={values?.programId}
                options={
                  Object.keys(metaData).length > 0 &&
                  metaData?.programName.map((programId, index) => {
                    return {
                      id: index,
                      label: programId
                    };
                  })
                }
                onChange={(e, value) => {
                  handleAutoCompSearch(e, "programId", value?.label);
                }}
                renderInput={(params) => <TextField {...params} label="Program..." />}
                className="flex-4"
              />
            </Grid>
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>Practice</Typography>
            </Grid>
            <Grid item xs={3}>
              <Stack direction="row">
                {/* <Select
                  fullWidth
                  name="practiceList"
                  value={practiceId}
                  className="patient-dropdown"
                  onChange={handleChangePracticeId}>
                  {practices &&
                    practices.map((item, index) => {
                      return (
                        <MenuItem value={item.practiceId} key={index}>
                          {item.name} - {item.practiceId}
                        </MenuItem>
                      );
                    })}
                </Select> */}
                <Autocomplete
                  disablePortal
                  required
                  name="practiceList"
                  value={practiceId}
                  options={practices.map((item, index) => {
                    return {
                      id: index,
                      label: `${item.name} - ${item.practiceId}`,
                      name: item.name,
                      value: item.practiceId
                    };
                  })}
                  onChange={(e, value) => {
                    handleChangePracticeId(e, "practiceList", value);
                  }}
                  renderInput={(params) => <TextField {...params} label="Practice..." />}
                  className="flex-4"
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" className="pad-5">
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography>Location</Typography>
            </Grid>
            <Grid item xs={3}>
              {/* <Select
                fullWidth
                name="locationId"
                value={locationId}
                onChange={handleLocationChange}
                disabled={practiceId == ""}
                className="stage-practice-dropdown">
                {locations.map((item, index) => {
                  return (
                    <MenuItem value={item.locationId} key={index}>
                      {item.name} -{item.locationId}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="locationId"
                value={locationId}
                disabled={practiceId == ""}
                className="stage-practice-dropdown"
                options={locations.map((item, index) => {
                  return {
                    id: index,
                    label: `${item.name} - ${item.locationId}`,
                    name: item.name,
                    value: item.locationId
                  };
                })}
                onChange={(e, value) => {
                  handleLocationChange(e, "locationId", value);
                }}
                renderInput={(params) => <TextField {...params} label="Location..." />}
              />
            </Grid>
          </Grid>
        </Stack>
        <Stack direction="row" className="pad-5">
          <Grid container className="align-center">
            <Grid item xs={1.5}>
              <Typography>Refund Generation Date From</Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  disableFuture
                  value={values.from}
                  onChange={(newValue) => {
                    setValues({ ...values, from: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={1.5}>
              <Typography style={{ marginLeft: "10px" }}>To</Typography>
            </Grid>
            <Grid item xs={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  value={values.to}
                  disableFuture
                  onChange={(newValue) => {
                    setValues({ ...values, to: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" fullWidth />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Box>
        <Box className="grid-style pad-5" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rowsData}
            rowCount={rowsData.length}
            columns={refundColumns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
            pageSize={paginationModel.pageSize}
            paginationModel={paginationModel}
            pagination
            getRowId={() => uuidv4()}
            localeText={"Export Button"}
            components={<CustomToolbar />}
            isExport={true}
          />
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({
  caseUpdateReducer,
  locationReducer,
  practices,
  refundFilterListReducer
}) => {
  return {
    metaData: caseUpdateReducer.metaData,
    practices: practices.practices,
    locations: locationReducer.locations,
    refundList: refundFilterListReducer.refundFilterList,
    loading: refundFilterListReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMetaData: () => {
      dispatch(getMetadata());
    },
    getPractices: () => {
      dispatch(getPractices());
    },
    getLocations: (practiceId) => {
      dispatch(getLocations(practiceId));
    },
    getRefundFilterList: (payload) => {
      dispatch(actionType.getRefundFilterList(payload));
    },
    resetRefundFilterList: () => {
      dispatch(actionType.resetRefundFilterList());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Refund);
