import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import * as actionType from "../actions";
import { Checkbox, FormControlLabel, ListSubheader } from "@mui/material";
import MessageModal from "../../shared/components/MessageModal";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const EmailDocument = (props) => {
  const { practiceId } = useParams();
  const columns = [
    {
      field: "emailDocumentId",
      headerName: "ID",
      width: 50,
      flex: 0.5
    },
    {
      field: "emailName",
      headerName: "EMAIL NAME",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "outcome",
      headerName: "OUTCOMES",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton>
          <CheckCircleOutlineIcon
            color={row.outcome ? "primary" : "#8C8C8C"}></CheckCircleOutlineIcon>
        </IconButton>
      )
    },
    /* {
      field: "agreements",
      headerName: "AGREEMENTS",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton>
          <CheckCircleOutlineIcon
            color={row.agreements ? "primary" : "#8C8C8C"}></CheckCircleOutlineIcon>
        </IconButton>
      )
    }, */
    {
      field: "clearance",
      //headerName: "CLEARANCE/ REJECTION",
      headerName: "PATIENT SELECTION",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton>
          <CheckCircleOutlineIcon
            color={row.clearance ? "primary" : "#8C8C8C"}></CheckCircleOutlineIcon>
        </IconButton>
      )
    },
    {
      field: "authorization",
      headerName: "AUTHORIZATION",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton>
          <CheckCircleOutlineIcon
            color={row.authorization ? "primary" : "#8C8C8C"}></CheckCircleOutlineIcon>
        </IconButton>
      )
    },
    {
      field: "receipt",
      headerName: "RECEIPT",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton>
          <CheckCircleOutlineIcon
            color={row.receipt ? "primary" : "#8C8C8C"}></CheckCircleOutlineIcon>
        </IconButton>
      )
    },
    {
      field: "refund",
      headerName: "REFUND",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton>
          <CheckCircleOutlineIcon
            color={row.refund ? "primary" : "#8C8C8C"}></CheckCircleOutlineIcon>
        </IconButton>
      )
    },
    {
      field: "termination",
      headerName: "TERMINATION",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton>
          <CheckCircleOutlineIcon
            color={row.termination ? "primary" : "#8C8C8C"}></CheckCircleOutlineIcon>
        </IconButton>
      )
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },

    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleEditEmailDoc(row);
          }}>
          <EditIcon color="primary"></EditIcon>
        </IconButton>
      )
    }
  ];

  let emailDocumentFormData = {
    emailName: "",
    emailId: "",
    practiceId,
    // agreements: false,
    outcome: false,
    clearance: false,
    authorization: false,
    receipt: false,
    refund: false,
    termination: false
  };
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [values, setValues] = useState(emailDocumentFormData);
  const [isMessageModal, setIsMessageModal] = useState(false);

  const {
    emailDocList = [],
    emailList,
    errorMsg,
    successMsg,
    addEmailDoc,
    editEmailDoc,
    loading,
    actions
  } = props;

  const rows = emailDocList.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    actions.getEmailDocs(practiceId);
  }, [addEmailDoc, editEmailDoc]);

  useEffect(() => {
    if (!emailList.length) {
      actions.getEmails(practiceId);
    }
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (emailDocList.length === 0 || addEmailDoc || editEmailDoc) {
      initFetch();
    }

    return () => {
      actions.resetEmailDocs();
    };
  }, [addEmailDoc, editEmailDoc, errorMsg]);

  const addEmailDocument = () => {
    setValues(emailDocumentFormData);
    setIsAdd(true);
    setIsOpen(true);
  };

  const saveEmailDocument = (event) => {
    event.preventDefault();

    if (isAdd) {
      actions.addEmailDoc(values);
    } else {
      actions.editEmailDoc(values);
    }
    closeModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (event.target.type === "checkbox") {
      setValues({
        ...values,
        [name]: event.target.checked
      });
    } else {
      setValues({
        ...values,
        [name]: value
      });
    }
  };

  const handleEditEmailDoc = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    emailDocumentFormData = {
      practiceId,
      emailId: row.emailDocumentId,
      emailName: row.emailName,
      // agreements: row?.agreements,
      outcome: row?.outcome,
      clearance: row?.clearance,
      authorization: row?.authorization,
      receipt: row?.receipt,
      refund: row?.refund,
      termination: row?.termination
    };
    setValues(emailDocumentFormData);
  };

  const closeModal = () => {
    setValues(emailDocumentFormData);
    setIsOpen(false);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  return (
    <>
      <Box>
        <Button
          variant="contained"
          onClick={addEmailDocument}
          className="btn-primary tab-panel-btn">
          New Email Document
        </Button>
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
        />
      </Box>
      {/* Add/edit email Document modal*/}
      <Modal open={isOpen} backdrop="static" aria-labelledby="modal-add-title">
        <Box component="form" onSubmit={saveEmailDocument} className="modal-style">
          <Stack direction="row" className="modal-stack">
            <Typography>{isAdd ? "New Email Document" : "Edit Email Document"}</Typography>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="row" className="modal-stack">
            <InputLabel required flex={1}>
              Email Name
            </InputLabel>
            <Select
              size="small"
              id="email-name"
              name="emailName"
              sx={{ minWidth: 300 }}
              required
              value={values.emailName}
              onChange={handleInputChange}>
              <MenuItem value="Contacts">Contacts</MenuItem>
              <MenuItem value="Physician">Physician</MenuItem>
              <MenuItem value="Participant">Participant</MenuItem>
              <Divider className="mb-0" />
              <ListSubheader>Other</ListSubheader>
              <Divider />
              {emailList
                .filter((item) => {
                  return item.status === "Active";
                })
                .map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.emailName}>
                      {item.emailName}
                    </MenuItem>
                  );
                })}
            </Select>
          </Stack>

          <Stack direction="row" className="modal-stack">
            <Stack flex={1}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    name="outcome"
                    value={values.outcome}
                    checked={values.outcome}
                    onChange={handleInputChange}
                  />
                }
                label="Outcomes"
                labelPlacement="start"
              />
              {/* <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    name="agreements"
                    value={values.agreements}
                    checked={values.agreements}
                    onChange={handleInputChange}
                  />
                }
                label="Agreements"
                labelPlacement="start"
              /> */}
            </Stack>
            <Stack flex={2}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    name="clearance"
                    value={values.clearance}
                    checked={values.clearance}
                    onChange={handleInputChange}
                  />
                }
                label="Patient Selection"
                labelPlacement="start"
              />
            </Stack>
          </Stack>
          <Stack direction="row" className="modal-stack">
            <Stack flex={1}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    name="authorization"
                    value={values.authorization}
                    checked={values.authorization}
                    onChange={handleInputChange}
                  />
                }
                label="Authorization"
                labelPlacement="start"
              />
            </Stack>
            <Stack flex={2}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    name="receipt"
                    value={values.receipt}
                    checked={values.receipt}
                    onChange={handleInputChange}
                  />
                }
                label="Receipt"
                labelPlacement="start"
              />
            </Stack>
          </Stack>
          <Stack direction="row" className="modal-stack">
            <Stack flex={1} sx={{ justifyContent: "flex-start" }}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    name="refund"
                    value={values.refund}
                    checked={values.refund}
                    onChange={handleInputChange}
                  />
                }
                label="Refund"
                labelPlacement="start"
              />
            </Stack>
            <Stack flex={2}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox
                    color="primary"
                    name="termination"
                    value={values.termination}
                    checked={values.termination}
                    onChange={handleInputChange}
                  />
                }
                label="Termination"
                labelPlacement="start"
              />
            </Stack>
          </Stack>

          <Stack direction="row" spacing={2} className="modal-bottom-section">
            <Button variant="outlined" className="btn-primary" onClick={closeModal}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={values.emailName ? false : true}
              className="btn-usf">
              {isAdd ? "Create" : "Save"}
            </Button>
          </Stack>
        </Box>
      </Modal>

      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};

const mapStateToProps = ({ emailDocumentReducer, emailReducer }) => {
  return {
    emailDocList: emailDocumentReducer.emailDocList,
    loading: emailDocumentReducer.loading,
    emailList: emailReducer.emailList,
    addEmailDoc: emailDocumentReducer.addEmailDoc,
    editEmailDoc: emailDocumentReducer.editEmailDoc,
    successMsg: emailDocumentReducer.success,
    errorMsg: emailDocumentReducer.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEmails: (practiceId) => {
      dispatch(actionType.getEmails(practiceId));
    },
    getEmailDocs: (practiceId) => {
      dispatch(actionType.getEmailDocs(practiceId));
    },
    addEmailDoc: (payload) => {
      dispatch(actionType.addEmailDoc(payload));
    },
    editEmailDoc: (payload) => {
      dispatch(actionType.editEmailDoc(payload));
    },
    resetEmailDocs: () => {
      dispatch(actionType.resetEmailDoc());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailDocument);
