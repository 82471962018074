import React, { useState, useEffect } from "react";
import Location from "./Location";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  Button,
  Tab,
  CircularProgress
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Physicians from "./Physicians";
import TabPanel from "@mui/lab/TabPanel";
import Email from "./Email";
import EmailDocument from "./EmailDocument";
import Contacts from "./Contacts";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { practiceConfig } from "../../shared/constants/renderConfigs";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
function PracticeDetails(props) {
  const { practiceId } = useParams();
  const practiceStoreList = useSelector((state) => state.practices.practices);
  console.log("practiceStoreList:", practiceStoreList);
  const addPractice = useSelector((state) => state.practices.addPractice);
  console.log("addPractice: ", addPractice);
  let practiceRowData;
  practiceStoreList.forEach((item) => {
    if (item.practiceId == practiceId) {
      let exterFertData =
        item.externalPracticeData &&
        item.externalPracticeData.filter((rdata) => rdata.externalSystemId == "Fert");
      let exterNextData =
        item.externalPracticeData &&
        item.externalPracticeData.filter((rdata) => rdata.externalSystemId == "Nextgen");
      practiceRowData = {
        practiceId: item?.practiceId,
        name: item?.name || "",
        longName: item?.longName || "",
        status: item?.status || "",
        phoneNumber: item?.phoneNumber || "",
        website: item?.website || "",
        fertLink: item?.fertLink || "",
        externalFertPracticeId:
          (exterFertData && exterFertData[0] && exterFertData[0].externalPracticeId) || "",
        externalNextgenPracticeId:
          (exterNextData && exterNextData[0] && exterNextData[0].externalPracticeId) || "",
        payYourBillLink: item?.payYourBillLink || "",
        practiceLogo: item?.practiceLogo || "",
        practicePdfLogo: item?.practicePdfLogo || "",
        vendorId: item?.vendorId || "",
        accountNumber: item?.accountNumber || "",
        locationId: item?.locationId || "",
        projectId: item?.projectId || ""
      };
    }
  });

  console.log("practiceRowData:", practiceRowData);

  const [practiceData, setPracticeData] = useState(practiceRowData);
  const [tabValue, setTabValue] = useState("1");
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(practiceData);
  const { actions, editPractice, errorMsg, successMsg, loading } = props;
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isMessModal, setIsMessModal] = useState(false);
  const [succMsg, setSuccMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  console.log("errorMsg", errorMsg);
  useEffect(() => {
    console.log("editPractice:", editPractice);
    if (editPractice) {
      let exterFertData =
        editPractice.externalPracticeData &&
        editPractice.externalPracticeData.filter((rdata) => rdata.externalSystemId == "Fert");
      let exterNextData =
        editPractice.externalPracticeData &&
        editPractice.externalPracticeData.filter((rdata) => rdata.externalSystemId == "Nextgen");
      editPractice.externalFertPracticeId =
        (exterFertData && exterFertData[0] && exterFertData[0].externalPracticeId) || "";
      editPractice.externalNextgenPracticeId =
        (exterNextData && exterNextData[0] && exterNextData[0].externalPracticeId) || "";

      console.log("editPractice.practiceLogo: ", editPractice.practiceLogo);
      setPracticeData(editPractice);
    }
  }, [editPractice]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
      actions.getPractices();
    }
  }, [successMsg, errorMsg]);
  useEffect(() => {
    if (practiceStoreList.length == 0) {
      actions.getPractices();
    }
    if (practiceRowData) {
      // let exterFertData =
      //   practiceRowData.externalPracticeData &&
      //   practiceRowData.externalPracticeData.filter((rdata) => rdata.externalSystemId == "Fert");
      // let exterNextData =
      //   practiceRowData.externalPracticeData &&
      //   practiceRowData.externalPracticeData.filter((rdata) => rdata.externalSystemId == "Nextgen");
      // practiceRowData.externalFertPracticeId =
      //   (exterFertData && exterFertData[0] && exterFertData[0].externalPracticeId) || "";
      // practiceRowData.externalNextgenPracticeId =
      //   (exterNextData && exterNextData[0] && exterNextData[0].externalPracticeId) || "";
      setPracticeData(practiceRowData);
    }
  }, [practiceStoreList]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    actions.resetLocationList();
  };
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    // Check if files are present
    if (files && files.length > 0) {
      const reader = new FileReader();

      const file = files[0];
      const fileType = file.type;
      const allowedTypes = ["image/png", "image/jpeg"];

      if (fileType !== "image/svg+xml" && name === "practiceLogo") {
        setSuccMsg("");
        setErrMsg("Please select an SVG file.");
        setIsMessModal(true);
        setIsOpen(false);
        return;
      } else if (!allowedTypes.includes(fileType) && name === "practicePdfLogo") {
        setSuccMsg("");
        setErrMsg("Please select an png/jpeg file.");
        setIsMessModal(true);
        setIsOpen(false);
        return;
      }

      let longName = name === "practiceLogo" ? "practiceLogo" : "practicePdfLogo";

      reader.onload = () => {
        let base64File = reader.result;
        base64File = base64File.split(",")[1];
        setValues({
          ...values,

          [longName]: base64File
        });

        //console.log("base64File: ", base64File);
      };

      reader.readAsDataURL(files[0]);
    } else {
      setValues({
        ...values,
        [name]: value
      });
    }
  };
  const closeMessModal = () => {
    setIsMessModal(false);
    setSuccMsg("");
    setErrMsg("");
  };
  const savePractice = (event) => {
    event.preventDefault();
    let externalArr = [];
    if (values.externalFertPracticeId) {
      let neObjFert = {
        externalPracticeId: values.externalFertPracticeId,
        externalSystemId: "Fert",
        op: "update"
      };
      externalArr.push(neObjFert);
    }
    if (values.externalNextgenPracticeId) {
      let neObjNext = {
        externalPracticeId: values.externalNextgenPracticeId,
        externalSystemId: "Nextgen",
        op: "update"
      };
      externalArr.push(neObjNext);
    }
    values.externalPractice = externalArr;

    const finalObj = {
      name: values.name,
      longName: values.longName,
      status: values.status,
      phoneNumber: values.phoneNumber,
      website: values.website,
      externalPractice: externalArr,
      practiceId: values.practiceId,
      fertLink: values.fertLink,
      payYourBillLink: values.payYourBillLink,
      practiceLogo: values.practiceLogo,
      practicePdfLogo: values.practicePdfLogo,
      vendorId: values.vendorId,
      projectId: values.projectId,
      locationId: values.locationId,
      accountNumber: values.accountNumber
    };
    actions.editPractice(finalObj);
    closeModal();
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const handleEditPractice = () => {
    setIsAdd(false);
    setValues(practiceData);
    setIsOpen(true);
  };
  const practice = practiceConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled:
      values?.name &&
      values?.longName &&
      values.externalFertPracticeId &&
      values.externalNextgenPracticeId &&
      values.vendorId &&
      values.accountNumber &&
      values.locationId &&
      values.projectId
        ? false
        : true
  });

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  return (
    <Box className={`${loading ? "practice-details disable-section" : "practice-details"}`}>
      <Box className="practice-details-section">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body2">Practice Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              className="btn-primary btn-edit-practice"
              onClick={handleEditPractice}>
              Edit
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <Stack justifyContent="center" alignItems="center" m={2}>
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container>
            {/* First Column */}
            <Grid item xs={3}>
              {/* First Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Name:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.name}</Typography>
                </Grid>
              </Stack>
              {/* Second Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Status:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.status}</Typography>
                </Grid>
              </Stack>
              {/* Third Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Website:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.website}</Typography>
                </Grid>
              </Stack>
              {/* Fourth Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Next Gen External Practice ID:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.externalNextgenPracticeId}</Typography>
                </Grid>
              </Stack>

              {/* Fifth Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Fert External Practice ID:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.externalFertPracticeId}</Typography>
                </Grid>
              </Stack>
            </Grid>

            {/* Divider */}
            <Divider orientation="vertical" flexItem />

            {/* Second Column */}
            <Grid item xs={4} sx={{ marginLeft: 4 }}>
              {/* First Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Long Name:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.longName}</Typography>
                </Grid>
              </Stack>
              {/* Second Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Phone Number:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.phoneNumber}</Typography>
                </Grid>
              </Stack>

              {/* Third Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Fert Link:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.fertLink}</Typography>
                </Grid>
              </Stack>
              {/* Fourth Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Pay Your Bill Link:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.payYourBillLink}</Typography>
                </Grid>
              </Stack>
              {/* Fifth Field */}
              <Stack direction="row" className="m-t-10 m">
                <Grid item xs={3}>
                  <Typography variant="body1">Practice Logo:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <img
                    src={`data:image/svg+xml;base64,${practiceData?.practiceLogo}`}
                    alt="Practice Logo"
                    width="50px"
                    height="50px"
                    style={{ backgroundColor: "black" }}
                  />
                </Grid>
              </Stack>
            </Grid>

            {/* Divider */}
            <Divider orientation="vertical" flexItem />

            {/* Third Column */}
            <Grid item xs={4} sx={{ marginLeft: 4 }}>
              {/* First Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Vendor ID:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.vendorId}</Typography>
                </Grid>
              </Stack>
              {/* Second Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Account Number:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.accountNumber}</Typography>
                </Grid>
              </Stack>

              {/* Third Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Location ID:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.locationId}</Typography>
                </Grid>
              </Stack>
              {/* Fourth Field */}
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Project ID:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{practiceData?.projectId}</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" className="m-t-10 m">
                <Grid item xs={3}>
                  <Typography variant="body1">Practice Pdf Logo:</Typography>
                </Grid>
                <Grid item xs={3}>
                  {practiceData?.practicePdfLogo && (
                    <img
                      src={`data:image/png;base64,${practiceData?.practicePdfLogo}`}
                      alt="Practice PDF Logo"
                      width="50px"
                      height="50px"
                      style={{ backgroundColor: "black" }}
                    />
                  )}
                </Grid>
              </Stack>
              {/* Fifth Field */}
              {/* <Stack direction="row" className="m-t-10 m">
                <Grid item xs={3}>
                  <Typography variant="body1">Practice Logo:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <img
                    src={`data:image/svg+xml;base64,${practiceData?.practiceLogo}`}
                    alt="Practice Logo"
                    width="50px"
                    height="50px"
                    style={{ backgroundColor: "black" }}
                  />
                </Grid>
              </Stack> */}
            </Grid>
          </Grid>
        )}
      </Box>
      <Box flexDirection="row">
        <TabContext value={tabValue}>
          <Box>
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Locations" value="1" />
                <Tab label="Physicians" value="2" />
                <Tab label="Contacts" value="3" />
                <Tab label="Email" value="4" />
                <Tab label="Email Document" value="5" />
              </TabList>
            </Stack>
          </Box>
          <TabPanel value="1">
            <Location />
          </TabPanel>
          <TabPanel value="2">{<Physicians />}</TabPanel>
          <TabPanel value="3">{<Contacts />}</TabPanel>
          <TabPanel value="4">{<Email />}</TabPanel>
          <TabPanel value="5">{<EmailDocument />}</TabPanel>
        </TabContext>
      </Box>
      {/* Edit practice modal start*/}
      <CommonModal open={isOpen} config={practice} isAdd={isAdd} handleSubmit={savePractice} />
      {/* Edit practice modal end*/}
      {/* Message Modal */}
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      {/* SVG Image only */}
      <MessageModal
        open={isMessModal}
        message={succMsg}
        error={errMsg}
        closeModal={closeMessModal}
      />
    </Box>
  );
}
const mapStateToProps = ({ practices }) => {
  return {
    editPractice: practices.editPractice,
    loading: practices.loading,
    errorMsg: practices.error,
    successMsg: practices.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    editPractice: (payload) => {
      dispatch(actionType.editPractice(payload));
    },
    resetLocationList: () => {
      dispatch(actionType.resetLocationList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PracticeDetails);
