import * as actionType from "../actions/ActionTypes";

const initialState = {
  activeParticipantList: [],
  loading: false,
  error: null,
  success: null
};

const activeParticipantReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_ACTIVE_PARTICIPANTS_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_ACTIVE_PARTICIPANTS_REPORT_SUCCESS: {
      return {
        ...state,
        activeParticipantList: action.payload,
        loading: false
      };
    }
    case actionType.GET_ACTIVE_PARTICIPANTS_REPORT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};
export default activeParticipantReducer;
