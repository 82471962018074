import React from "react";
import { Modal, Box, Typography, Button, Stack, IconButton } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  maxWidth: 600,
  height: "80vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 4,
  borderRadius: 2,
  overflowY: "auto"
};

const PopupModal = ({ open, title, content, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description">
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography id="modal-title" variant="h6" component="h2">
            {title}
          </Typography>
          <IconButton onClick={handleClose}>
            <img alt="close icon" src="/Assets/close.png" />
          </IconButton>
        </div>
        <Box id="modal-description" sx={{ mt: 2 }}>
          {content}
        </Box>
        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button variant="contained" color="primary" onClick={handleClose}>
            Close
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default PopupModal;
