import * as actionType from "./ActionTypes";

export const getPaymentTimingList = (payload) => {
  return {
    type: actionType.PAYMENT_TIMING_LIST_REQUESTED,
    payload: payload
  };
};

export const addPaymentTimingList = (payload) => {
  return {
    type: actionType.ADD_PAYMENT_TIMING_REQUESTED,
    payload: payload
  };
};

export const editPaymentTimingList = (payload) => {
  return {
    type: actionType.EDIT_PAYMENT_TIMING_REQUESTED,
    payload: payload
  };
};

export const resetPaymentTimingList = () => {
  return {
    type: actionType.RESET_PAYMENT_TIMING_LIST
  };
};

export const resetMessage = () => {
  return {
    type: actionType.RESET_MESSAGE,
    success: false,
    error: false
  };
};

export const getPractices = () => {
  return {
    type: actionType.PRACTICE_LIST_REQUESTED
  };
};

export const addNewPractice = (payload) => {
  return {
    type: actionType.ADD_NEW_PRACTICE_REQUESTED,
    payload: payload
  };
};
export const editPractice = (payload) => {
  return {
    type: actionType.EDIT_PRACTICE_REQUESTED,
    payload: payload
  };
};
export const resetPracticeList = () => {
  return {
    type: actionType.RESET_PRACTICE_LIST
  };
};

export const setPracticeId = (payload) => {
  return {
    type: actionType.SET_PRACTICE_ID,
    payload: payload
  };
};
export const getLocations = (payload) => {
  return {
    type: actionType.LOCATION_LIST_REQUESTED,
    payload: payload
  };
};
export const addNewLocation = (payload) => {
  return {
    type: actionType.ADD_NEW_LOCATION_REQUESTED,
    payload: payload
  };
};
export const editLocation = (payload) => {
  return {
    type: actionType.EDIT_LOCATION_REQUESTED,
    payload: payload
  };
};
export const resetLocationList = () => {
  return {
    type: actionType.RESET_LOCATION_LIST
  };
};
export const resetLocationRows = () => {
  return {
    type: actionType.RESET_LOCATION_ROW
  };
};
//Location Address
export const getAddressList = (payload) => {
  return {
    type: actionType.ADDRESS_LIST_REQUESTED,
    payload
  };
};

export const addLocationAddressList = (payload) => {
  return {
    type: actionType.ADD_ADDRESS_REQUESTED,
    payload: payload
  };
};

export const addAddress = (payload) => {
  return {
    type: actionType.ADD_ADDRESS_REQUESTED,
    payload: payload
  };
};

export const editAddress = (payload) => {
  return {
    type: actionType.EDIT_ADDRESS_REQUESTED,
    payload: payload
  };
};

export const resetAddressList = () => {
  return {
    type: actionType.RESET_ADDRESS_LIST
  };
};

export const getPhysicians = (payload) => {
  return {
    type: actionType.PHYSICIAN_LIST_REQUESTED,
    payload: payload
  };
};

export const addNewPhysician = (payload) => {
  return {
    type: actionType.ADD_NEW_PHYSICIAN_REQUESTED,
    payload: payload
  };
};
export const editPhysician = (payload) => {
  return {
    type: actionType.EDIT_PHYSICIAN_REQUESTED,
    payload: payload
  };
};
export const resetPhysicianList = () => {
  return {
    type: actionType.RESET_PHYSICIAN_LIST
  };
};
export const resetPhysicianRows = () => {
  return {
    type: actionType.RESET_PHYSICIAN_ROW
  };
};

//Email section

export const getEmails = (payload) => {
  return {
    type: actionType.EMAIL_LIST_REQUESTED,
    payload: payload
  };
};
export const addEmail = (payload) => {
  return {
    type: actionType.ADD_EMAIL_REQUESTED,
    payload: payload
  };
};

export const editEmail = (payload) => {
  return {
    type: actionType.EDIT_EMAIL_REQUESTED,
    payload: payload
  };
};

export const resetEmails = () => {
  return {
    type: actionType.RESET_EMAIL
  };
};

export const resetEmailList = () => {
  return {
    type: actionType.RESET_EMAIL_LIST
  };
};

//External Details Actions

export const getExternalSystemDetail = (payload) => {
  return {
    type: actionType.EXTERNAL_SYSTEM_DETAIL_REQUESTED,
    payload: payload
  };
};
export const resetExternalSystemDetail = (payload) => {
  return {
    type: actionType.EXTERNAL_SYSTEM_DETAIL_RESET,
    payload: payload
  };
};

export const getExternalIdList = (payload) => {
  return {
    type: actionType.EXTERNAL_ID_LIST_REQUESTED,
    payload: payload
  };
};

export const addExternalId = (payload) => {
  return {
    type: actionType.ADD_EXTERNAL_ID_LIST_REQUESTED,
    payload: payload
  };
};
export const editExternalId = (payload) => {
  return {
    type: actionType.EDIT_EXTERNAL_ID_LIST_REQUESTED,
    payload: payload
  };
};

export const resetExternalIdList = () => {
  return {
    type: actionType.RESET_EXTERNAL_ID_LIST
  };
};

export const getServiceItems = (payload) => {
  return {
    type: actionType.SERVICEITEMS_LIST_REQUESTED,
    payload: payload
  };
};

export const addNewServiceItems = (payload) => {
  return {
    type: actionType.ADD_NEW_SERVICEITEMS_REQUESTED,
    payload: payload
  };
};
export const editServiceItems = (payload) => {
  return {
    type: actionType.EDIT_SERVICEITEMS_REQUESTED,
    payload: payload
  };
};
export const resetServiceItemsList = () => {
  return {
    type: actionType.RESET_SERVICEITEMS_LIST
  };
};
export const resetServiceItems = () => {
  return {
    type: actionType.RESET_SERVICEITEMS
  };
};

//Email Document section
export const getEmailDocs = (payload) => {
  return {
    type: actionType.EMAIL_DOC_LIST_REQUESTED,
    payload: payload
  };
};
export const addEmailDoc = (payload) => {
  return {
    type: actionType.ADD_EMAIL_DOC_REQUESTED,
    payload: payload
  };
};

export const editEmailDoc = (payload) => {
  return {
    type: actionType.EDIT_EMAIL_DOC_REQUESTED,
    payload: payload
  };
};

export const resetEmailDoc = () => {
  return {
    type: actionType.RESET_EMAIL_DOC
  };
};
export const resetEmailDocumentRows = () => {
  return {
    type: actionType.RESET_EMAIL_DOC_LIST
  };
};

export const getTreatmentPlan = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_LIST_REQUESTED,
    payload: payload
  };
};

export const getTreatmentPlanQuestions = (payload) => {
  return {
    type: actionType.TREATMENTPLANQUESTIONS_LIST_REQUESTED,
    payload: payload
  };
};

export const refreshTreatmentPlanQuestions = (payload) => {
  return {
    type: actionType.TREATMENTPLANQUESTIONS_LIST_REFRESH,
    payload: payload
  };
};

export const getTreatmentPlanRoot = (payload) => {
  return {
    type: actionType.TREATMENTPLANROOT_LIST_REQUESTED,
    payload: payload
  };
};

export const getTreatmentPlanAddQuestionOption = (payload) => {
  return {
    type: actionType.TREATMENTPLANADDQUESTIONOPTION_LIST_REQUESTED,
    payload: payload
  };
};

export const getTreatmentPlanQuestionSelection = (payload) => {
  return {
    type: actionType.TREATMENTPLANQUESTIONSELECTION_LIST_REQUESTED,
    payload: payload
  };
};
export const getTreatmentPlanAddOn = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_ADD_ON_REQUESTED,
    payload: payload
  };
};

export const getTreatmentPlanModifier = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_MODIFIER_REQUESTED,
    payload: payload
  };
};

export const getTreatmentPlanMonitoring = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_MONITORING_REQUESTED,
    payload: payload
  };
};

export const getTreatmentPlanAssist = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_ASSIST_REQUESTED,
    payload: payload
  };
};

export const getTreatmentPlanAHM = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_AHM_REQUESTED,
    payload: payload
  };
};

export const addNewTreatmentPlan = (payload) => {
  return {
    type: actionType.ADD_NEW_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};
export const editTreatmentPlan = (payload) => {
  return {
    type: actionType.EDIT_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};
export const upsertTreatmentPlan = (payload) => {
  return {
    type: actionType.UPSERT_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};
export const deleteTreatmentPlan = (payload) => {
  return {
    type: actionType.DELETE_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};
export const deleteTreatmentPlanQuestion = (payload) => {
  return {
    type: actionType.DELETE_TREATMENTPLANQUESIONS_REQUESTED,
    payload: payload
  };
};

export const resetTreatmentPlanList = () => {
  return {
    type: actionType.RESET_TREATMENTPLAN_LIST
  };
};

export const resetTreatmentPlan = () => {
  return {
    type: actionType.RESET_TREATMENTPLAN
  };
};
// Contact Actions

export const getcontactList = (payload) => {
  return {
    type: actionType.CONTACT_LIST_REQUESTED,
    payload
  };
};

export const addContact = (payload) => {
  return {
    type: actionType.ADD_CONTACT_LIST_REQUESTED,
    payload: payload
  };
};

export const editContact = (payload) => {
  return {
    type: actionType.EDIT_CONTACT_LIST_REQUESTED,
    payload: payload
  };
};
export const resetContactList = () => {
  return {
    type: actionType.RESET_CONTACT_LIST
  };
};

export const resetContactRows = () => {
  return {
    type: actionType.RESET_CONTACT_ROWS
  };
};

//phoneData
export const getContactPhoneData = (payload) => {
  return {
    type: actionType.CONTACT_PHONE_DATA_REQUESTED,
    payload: payload
  };
};
//emailData
export const getContactEmailData = (payload) => {
  return {
    type: actionType.CONTACT_EMAIL_DATA_REQUESTED,
    payload: payload
  };
};

export const resetPhoneData = () => {
  return {
    type: actionType.RESET_PHONE_DATA
  };
};

export const getStages = (payload) => {
  return {
    type: actionType.STAGE_LIST_REQUESTED,
    payload: payload
  };
};

export const getStagesNotMapped = (payload) => {
  return {
    type: actionType.STAGE_LIST_REQUESTED_NOT_MAPPED,
    payload: payload
  };
};

export const addNewStage = (payload) => {
  return {
    type: actionType.ADD_NEW_STAGE_REQUESTED,
    payload: payload
  };
};
export const editStage = (payload) => {
  return {
    type: actionType.EDIT_STAGE_REQUESTED,
    payload: payload
  };
};
export const resetStageList = () => {
  return {
    type: actionType.RESET_STAGE_LIST
  };
};
export const resetStages = () => {
  return {
    type: actionType.RESET_STAGES
  };
};

//program
export const getPrograms = (payload) => {
  return {
    type: actionType.PROGRAM_LIST_REQUESTED,
    payload: payload
  };
};
export const addProgram = (payload) => {
  return {
    type: actionType.ADD_PROGRAM_REQUESTED,
    payload: payload
  };
};

export const editProgram = (payload) => {
  return {
    type: actionType.EDIT_PROGRAM_REQUESTED,
    payload: payload
  };
};

export const deleteProgram = (payload) => {
  return {
    type: actionType.DELETE_PROGRAM_REQUESTED,
    payload: payload
  };
};

export const resetPrograms = () => {
  return {
    type: actionType.RESET_PROGRAM
  };
};

export const resetProgramList = () => {
  return {
    type: actionType.RESET_PROGRAM_LIST
  };
};

export const getEstimateQuestions = (payload) => {
  return {
    type: actionType.ESTIMATE_QUESTIONS_LIST_REQUESTED,
    payload: payload
  };
};

export const saveEstimateQuestions = (payload) => {
  return {
    type: actionType.SAVE_ESTIMATE_QUESTIONS_LIST_REQUESTED,
    payload: payload
  };
};

export const resetQuestions = () => {
  return {
    type: actionType.RESET_ESTIMATE_QUESTIONS
  };
};

//Global data model
export const getGlobalData = (payload) => {
  return {
    type: actionType.GDM_LIST_REQUESTED,
    payload: payload
  };
};
export const createNewGdm = (payload) => {
  return {
    type: actionType.ADD_NEW_GDM_REQUESTED,
    payload: payload
  };
};
export const updateNewGdm = (payload) => {
  return {
    type: actionType.EDIT_GDM_REQUESTED,
    payload: payload
  };
};
export const resetGlobalData = () => {
  return {
    type: actionType.RESET_GDM_DATA
  };
};

export const resetGlobalList = () => {
  return {
    type: actionType.RESET_GDM_LIST
  };
};
export const updateGdmRow = (payload) => {
  return {
    type: actionType.GDM_ROW,
    payload: payload
  };
};

// Physician Reimbursement Scale
export const getPRSList = () => {
  return {
    type: actionType.PRS_LIST_REQUESTED
  };
};

//Add Physician Reimbursement Scale
export const addPRS = (payload) => {
  return {
    type: actionType.ADD_NEW_PRS_REQUESTED,
    payload: payload
  };
};
//stage list

export const getStagesToServiceItemList = (payload) => {
  return {
    type: actionType.STAGE_DETAIL_LIST_REQUESTED,
    payload: payload
  };
};

export const addServiceToStage = (payload) => {
  return {
    type: actionType.ADD_SERVICE_ITEM_STAGES_REQUESTED,
    payload: payload
  };
};
export const editServiceToStage = (payload) => {
  return {
    type: actionType.EDIT_SERVICE_ITEM_STAGES_REQUESTED,
    payload: payload
  };
};

export const deleteServiceToStage = (payload) => {
  return {
    type: actionType.DELETE_SERVICE_ITEM_STAGES_REQUESTED,
    payload: payload
  };
};

export const resetServiceItemStages = () => {
  return {
    type: actionType.RESET_SERVICE_ITEM_STAGES
  };
};

export const resetStageDetailsList = () => {
  return {
    type: actionType.RESET_SERVICE_ITEM_STAGES_LIST
  };
};

export const updateFeeSchedule = (payload) => {
  return {
    type: actionType.FEE_SCHEDULE_UPDATE_REQUESTED,
    payload: payload
  };
};
export const getFeeSchedule = (payload) => {
  return {
    type: actionType.FEE_SCHEDULE_LIST_REQUESTED,
    payload: payload
  };
};
export const getTreatmentPlanStageIncluded = (payload) => {
  return {
    type: actionType.STAGE_INCLUDED_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};
export const getTpStagesNecessary = (payload) => {
  return {
    type: actionType.STAGE_NECESSARY_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};
export const getTpStagesOptional = (payload) => {
  return {
    type: actionType.STAGE_OPTIONAL_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};
export const addNewStagesTreatmentPlan = (payload) => {
  return {
    type: actionType.ADD_STAGES_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};

export const editStageTreatmentPlan = (payload) => {
  return {
    type: actionType.EDIT_STAGES_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};

export const deleteTpStage = (payload) => {
  return {
    type: actionType.DELETE_STAGES_TREATMENTPLAN_REQUESTED,
    payload: payload
  };
};

// Location Program Definition
export const getLocationProgramDef = (payload) => {
  return {
    type: actionType.LOCATION_PROGRAM_LIST_REQUESTED,
    payload: payload
  };
};

//multiplier
export const getMultipliers = (payload) => {
  return {
    type: actionType.MULTIPLIER_LIST_REQUESTED,
    payload: payload
  };
};
export const addMultiplier = (payload) => {
  return {
    type: actionType.ADD_MULTIPLIER_REQUESTED,
    payload: payload
  };
};

export const editMultiplier = (payload) => {
  return {
    type: actionType.EDIT_MULTIPLIER_REQUESTED,
    payload: payload
  };
};

export const deleteMultiplier = (payload) => {
  return {
    type: actionType.DELETE_MULTIPLIER_REQUESTED,
    payload: payload
  };
};

export const resetMultipliers = () => {
  return {
    type: actionType.RESET_MULTIPLIER
  };
};
export const resetMultiplierList = () => {
  return {
    type: actionType.RESET_MULTIPLIER_LIST
  };
};
export const addQuestionTreatmentPlan = (payload) => {
  return {
    type: actionType.ADD_QUESTION_TREATMENT_PLAN_REQUESTED,
    payload: payload
  };
};
export const refreshTreatmentPlanQuestionSelectionList = (payload) => {
  return {
    type: actionType.TREATMENTPLANQUESTIONSELECTION_LIST_REFRESH,
    payload: payload
  };
};
export const refreshTreatmentPlanQuestionAddOnList = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_ADD_ON_REFRESH,
    payload: payload
  };
};
export const refreshTreatmentPlanQuestionModifierList = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_MODIFIER_REFRESH,
    payload: payload
  };
};
export const refreshTreatmentPlanQuestionMonitoringList = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_MONITORING_REFRESH,
    payload: payload
  };
};
export const refreshTreatmentPlanQuestionAssistList = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_ASSIST_REFRESH,
    payload: payload
  };
};
export const refreshTreatmentPlanQuestionAHMList = (payload) => {
  return {
    type: actionType.TREATMENTPLAN_AHM_REFRESH,
    payload: payload
  };
};

// Relative Value
export const getRelativeValue = () => {
  return {
    type: actionType.RELATIVEVALUE_LIST_REQUESTED
  };
};

export const addNewRelativeValue = (payload) => {
  return {
    type: actionType.ADD_NEW_RELATIVEVALUE_REQUESTED,
    payload: payload
  };
};
export const editRelativeValue = (payload) => {
  return {
    type: actionType.EDIT_RELATIVEVALUE_REQUESTED,
    payload: payload
  };
};
export const deleteRelativeValue = (payload) => {
  return {
    type: actionType.DELETE_RELATIVEVALUE_REQUESTED,
    payload: payload
  };
};
export const resetRelativeValueList = () => {
  return {
    type: actionType.RESET_RELATIVEVALUE_LIST
  };
};
export const resetRelativeValue = () => {
  return {
    type: actionType.RESET_RELATIVEVALUE
  };
};

// Metadata request
export const getMetadata = () => {
  return {
    type: actionType.METADATA_RELATIVE_REQUESTED
  };
};

// Relative Type
export const getRelativeTypes = () => {
  return {
    type: actionType.RELATIVE_TYPE_REQUESTED
  };
};

export const updateLocationProgramList = (payload) => {
  return {
    type: actionType.LOCATION_PROGRAM_UPDATE_REQUESTED,
    payload: payload
  };
};

export const resetPrs = () => {
  return {
    type: actionType.RESET_PRS_LIST
  };
};

// PGTA Analysis
export const getPGTAList = () => {
  return {
    type: actionType.PGTA_LIST_REQUESTED
  };
};

export const editPGTAList = (payload) => {
  return {
    type: actionType.EDIT_PGTA_REQUESTED,
    payload: payload
  };
};

export const resetPGTAList = () => {
  return {
    type: actionType.PGTA_LIST_RESET
  };
};

//tProgram visualization
export const getBoxes = (payload) => {
  return {
    type: actionType.BOX_LIST_REQUESTED,
    payload: payload
  };
};

export const addNewBox = (payload) => {
  return {
    type: actionType.CREATE_BOX_REQUESTED,
    payload: payload
  };
};

export const deleteBox = (payload) => {
  return {
    type: actionType.DELETE_BOX_REQUESTED,
    payload: payload
  };
};

export const addNewProgram = (payload) => {
  return {
    type: actionType.CREATE_PROGRAM_REQUESTED,
    payload: payload
  };
};

export const saveAge = (payload) => {
  return {
    type: actionType.SAVE_AGE_REQUESTED,
    payload: payload
  };
};

export const resetMessageAge = () => {
  return {
    type: actionType.SAVE_AGE_RESET,
    success: false,
    error: false
  };
};

export const resetProgramVisualization = () => {
  return {
    type: actionType.RESET_BOX_DATA
  };
};

export const getProgramsList = (payload) => {
  return {
    type: actionType.PROGRAM_LIST_VISUALIZATION_REQUESTED,
    payload: payload
  };
};

export const deleteProgramInBox = (payload) => {
  return {
    type: actionType.DELETE_PROGRAM_IN_BOX_REQUESTED,
    payload: payload
  };
};
export const getRefundAge = (payload) => {
  return {
    type: actionType.GET_REFUNDAGE_REQUESTED,
    payload: payload
  };
};
export const editRefundAge = (payload) => {
  return {
    type: actionType.EDIT_REFUNDAGE_REQUESTED,
    payload: payload
  };
};

export const resetLocationProgramDef = () => {
  return {
    type: actionType.RESET_LOCATION_PROGRAM_DEF
  };
};

// Metadata  FET requested
export const getLPDMetadata = (payload) => {
  return {
    type: actionType.METADATA_REQUESTED,
    payload: payload
  };
};

export const getProgramMultiplier = (payload) => {
  return {
    type: actionType.GET_PROGRAM_MULTIPLIER_REQUESTED,
    payload: payload
  };
};
export const getStaffAdminList = (payload) => {
  return {
    type: actionType.STAFFADMIN_LIST_REQUESTED,
    payload: payload
  };
};
export const resetStaffAdminList = () => {
  return {
    type: actionType.RESET_STAFFADMIN_LIST
  };
};
export const resetStaffAdminUsersList = () => {
  return {
    type: actionType.RESET_STAFF_ADMIN_USER_DETAILS_LIST
  };
};

//User Tab Filters List
export const userFilterList = (payload) => {
  return {
    type: actionType.USER_FILTER_DATA_REQUESTED,
    payload: payload
  };
};
export const resetUserFilter = () => {
  return {
    type: actionType.RESET_USER_FILTER
  };
};
export const getPatientPortalAdminList = (payload) => {
  return {
    type: actionType.PATIENT_PORTAL_ADMIN_LIST_REQUESTED,
    payload: payload
  };
};
export const resetPatientPortalAdminList = (payload) => {
  return {
    type: actionType.RESET_PATIENT_PORTAL_ADMIN_LIST,
    payload: payload
  };
};

// CTM
export const getCTM = () => {
  return {
    type: actionType.CTM_LIST_REQUESTED
  };
};

export const createNewCTM = (payload) => {
  return {
    type: actionType.ADD_NEW_CTM_REQUESTED,
    payload: payload
  };
};
export const editCTM = (payload) => {
  return {
    type: actionType.EDIT_CTM_REQUESTED,
    payload: payload
  };
};
export const deleteCTM = (payload) => {
  return {
    type: actionType.DELETE_CTM_REQUESTED,
    payload: payload
  };
};
export const resetCTMList = () => {
  return {
    type: actionType.RESET_CTM_LIST
  };
};
export const resetCTM = () => {
  return {
    type: actionType.RESET_CTM
  };
};
export const addUserNewRole = (payload) => {
  return {
    type: actionType.CREATE_NEW_USER,
    payload: payload
  };
};

export const resetNewUser = () => {
  return {
    type: actionType.RESET_CREATE_NEW_USER
  };
};
export const getStaffAdminUserDetails = (payload) => {
  return {
    type: actionType.STAFF_ADMIN_USER_DETAILS_REQUESTED,
    payload: payload
  };
};
export const getSelectedRowdetails = (payload) => {
  return {
    type: actionType.GET_SELECTED_USER_DETAILS,
    payload: payload
  };
};
export const setEnableEditFlg = (payload) => {
  return {
    type: actionType.SET_EDIT_FLAG,
    payload: payload
  };
};
export const getUserRolesData = (payload) => {
  return {
    type: actionType.GET_EDIT_USER_DETAILS,
    payload: payload
  };
};
export const saveEditUserDetails = (payload) => {
  return {
    type: actionType.SAVE_EDIT_USER_DETAILS,
    payload: payload
  };
};
export const setCognitoId = (payload) => {
  return {
    type: actionType.SET_COGNITO_ID,
    payload: payload
  };
};

export const resetEditUser = () => {
  return {
    type: actionType.RESET_SAVE_EDIT_NEW_USER
  };
};
export const resetFetchDetails = () => {
  return {
    type: actionType.RESET_GET_EDIT_USER_DETAILS
  };
};
// Programs Data request
export const getProgramsdata = (payload) => {
  return {
    type: actionType.PROGRAMSDATA_LIST_REQUESTED,
    payload: payload
  };
};

// Template request
export const getTemplate = (payload) => {
  return {
    type: actionType.TEMPLATE_LIST_REQUESTED,
    payload: payload
  };
};

export const getAuditLogs = (payload) => {
  return {
    type: actionType.AUDIT_LOGS_REQUESTED,
    payload: payload
  };
};

export const setEditPersonStatus = (payload) => {
  return {
    type: actionType.SET_EDIT_PERSON_DISABLE,
    payload: payload
  };
};
export const resetPersonDiable = () => {
  return {
    type: actionType.RESET_SET_EDIT_PERSON_DISABLE
  };
};
//  Load from Excel
export const validateExcel = (payload) => {
  return {
    type: actionType.VALIDATE_EXCEL_REQUESTED,
    payload: payload
  };
};

export const uploadExcel = (payload) => {
  return {
    type: actionType.UPLOAD_EXCEL_REQUESTED,
    payload: payload
  };
};
export const emptyErrorMsg = (payload) => {
  return {
    type: actionType.EMPTY_ERRORMSG_REQUESTED,
    payload: payload
  };
};

export const emptyExcelStates = () => {
  return {
    type: actionType.EMPTY_EXCEL_STATES_REQUESTED
  };
};

export const resetAuditLogs = () => {
  return {
    type: actionType.RESET_AUDIT_LOGS_LIST
  };
};

export const getEnableDisable = (payload) => {
  return {
    type: actionType.ENABLE_DISABLE_REQUESTED,
    payload: payload
  };
};

export const resetEmail = (payload) => {
  return {
    type: actionType.RESET_EMAIL_REQUESTED,
    payload: payload
  };
};

// Email Template
export const getEmailTemplate = () => {
  return {
    type: actionType.EMAIL_TEMPLATE_LIST_REQUESTED
  };
};
export const createEmailTemplate = (payload) => {
  return {
    type: actionType.ADD_EMAIL_TEMPLATE_REQUESTED,
    payload: payload
  };
};
export const updateEmailTemplate = (payload) => {
  return {
    type: actionType.EDIT_EMAIL_TEMPLATE_REQUESTED,
    payload: payload
  };
};
export const deleteEmailTemplate = (payload) => {
  return {
    type: actionType.DELETE_EMAIL_TEMPLATE_REQUESTED,
    payload: payload
  };
};
export const resetEmailTemplateList = () => {
  return {
    type: actionType.RESET_EMAIL_TEMPLATE_LIST
  };
};
export const resetEmailTemplate = () => {
  return {
    type: actionType.RESET_EMAIL_TEMPLATE
  };
};
export const resetEmailTemplateMessage = () => {
  return {
    type: actionType.RESET_EMAIL_TEMPLATE_MESSAGE
  };
};
export const getEmailTemplateSetting = () => {
  return {
    type: actionType.EMAIL_TEMPLATE_SETTING_REQUESTED
  };
};

// Custome Auth
export const fetchCustAuth = (payload) => {
  return {
    type: actionType.CUST_AUTH_REQUESTED,
    payload: payload
  };
};

//Manage Lab
export const getPGTALab = () => {
  return {
    type: actionType.PGTA_LABS_LIST_REQUESTED
  };
};
export const createPGTALab = (payload) => {
  return {
    type: actionType.ADD_PGTA_LABS_REQUESTED,
    payload: payload
  };
};
export const updatePGTALab = (payload) => {
  return {
    type: actionType.EDIT_PGTA_LABS_REQUESTED,
    payload: payload
  };
};
export const resetPGTALabList = () => {
  return {
    type: actionType.RESET_PGTA_LABS_LIST
  };
};
export const resetPGTALab = () => {
  return {
    type: actionType.RESET_PGTA_LABS
  };
};
export const resetPGTALabMessage = () => {
  return {
    type: actionType.RESET_PGTA_LABS_MESSAGE
  };
};
export const createImpersonateLog = (payload) => {
  return {
    type: actionType.ADD_IMPERSONATE_LOGS_REQUESTED,
    payload: payload
  };
};

export const deleteUsers = (payload) => {
  return {
    type: actionType.DELETE_USERS_REQUESTED,
    payload: payload
  };
};

export const deleteUserResetMessage = () => {
  return {
    type: actionType.DELETE_USER_RESET_MESSAGE,
    success: false,
    error: false
  };
};
