import React, { useState } from "react";
import { Box } from "@mui/material";
import * as actionType from "../actions";
import CommonModal from "../../shared/components/CommonModal";
import DeleteModal from "../../shared/components/DeleteModal";
import Button from "@mui/material/Button";
import { patientPaymentDetailsConfig } from "../../shared/constants/renderConfigs";
import { connect } from "react-redux";

const PatientPaymentDetails = (props) => {
  let initialData = {};
  const { actions } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [values, setValues] = useState("");
  //   useEffect(() => {
  //     let payload = { medcaseId: props.medcaseId };
  //     actions.getTransactionLog(payload);
  //   }, []);
  //   const rows = transactionLog.map((item, index) => {
  //     item.id = index;
  //     return item;
  //   });
  const handleDeleteTransaction = (row) => {
    setDeleteModal(true);
    setTransactionId(row.transactionId);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const handleDelete = (e) => {
    e.preventDefault();
    initialData = {
      transactionId: transactionId
    };
    actions.deleteTransaction(initialData);
    closeDeleteModal();
  };
  const patientPayment = patientPaymentDetailsConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal
  });
  const patientPaymentDetails = () => {
    setValues(true);
    setIsOpen(true);
  };
  return (
    <>
      <Box className="m-8">
        <Button variant="contained" className="btn-primary" onClick={patientPaymentDetails}>
          Patient Payment Details
        </Button>
      </Box>
      <CommonModal open={isOpen} config={patientPayment} handleSubmit={handleDeleteTransaction} />
      <DeleteModal
        open={deleteModal}
        id={transactionId}
        close={closeDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};

const mapStateToProps = ({ transactionLogReducer }) => {
  return {
    transactionLog: transactionLogReducer.transactionLog,
    loading: transactionLogReducer.loading,
    errorMsg: transactionLogReducer.error
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getTransactionLog: (payload) => {
      dispatch(actionType.getTransactionLog(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientPaymentDetails);
