import { Close } from "@mui/icons-material";
import {
  Divider,
  Grid,
  IconButton,
  Modal,
  InputLabel,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
  Checkbox
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionType from "../../actions";
import { useParams } from "react-router-dom";
import MessageModal from "../../../shared/components/MessageModal";
import WarningPopUp from "./WarningPopUp";

const EmailDocumentModal = (props) => {
  const {
    actions,
    emailData,
    successMsg,
    errorEmail,
    isOutcomeEmail,
    clinicalTreatmentId,
    mdCaseId
  } = props;
  const formData = {
    emailFromDB: emailData.contactEmail ? [emailData.contactEmail] : [],
    // emailFromDBSelected: emailData.emailStringList ?? [],
    newEmail: "",
    additionalEmail: ""
  };

  const { id } = useParams();
  const [selectValue, setSelectValue] = useState("");
  const [values, setValues] = useState(formData);
  const [newEmail, setNewEmail] = useState("DB Email");
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [additionalRadio, setIsAdditionalRadio] = useState("");
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [state, setCheckBoxState] = useState({
    emailDB: false,
    // emailDBSelected: true,
    emailAdditional: false,
    emailNew: false,
    emailNo: false
  });
  const medcaseId = mdCaseId && !id ? mdCaseId : id;

  const handleCheckboxChange = (event) => {
    setCheckBoxState({
      ...state,
      [event.target.name]: event.target.checked
    });

    setSelectValue(event.target.value);
    setIsAdditionalRadio(true);
  };

  const handleClose = () => {
    props.close();
  };

  useEffect(() => {
    actions.getMedcaseHeader({ medcaseId: medcaseId });
    actions.getEmailOption({ medcaseId: medcaseId });
  }, []);

  useEffect(() => {
    if (successMsg) {
      setIsMessageModal(true);
    }
    if (errorEmail) {
      setIsWarningOpen(true);
    }
  }, [successMsg, errorEmail]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetPostEmail();
    actions.resetEmail();
  };

  // const handleChangeRadio = (e) => {
  //   console.log("e.target.checked", e.target.checked, e.target.value);
  //   setSelectValue(e.target.value);
  // };

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: Array.isArray(e.target.value) ? [...e.target.value] : e.target.value
    });
    if (selectValue == "2") {
      setNewEmail("New Email");
    } else if (selectValue == "1") {
      setNewEmail("DB Email");
    }
  };

  const handleSendEmail = () => {
    console.log("vaues", values);
    let toAddresses = [];
    if (state.emailDB) {
      toAddresses = [...toAddresses, ...values.emailFromDB];
    }
    // if (state.emailDBSelected) {
    //   toAddresses = [...toAddresses, ...values.emailFromDBSelected];
    // }
    if (state.emailAdditional) {
      toAddresses = [...toAddresses, values.additionalEmail];
    }
    if (state.emailNew) {
      toAddresses = [...toAddresses, values.newEmail];
    }
    if (state.emailNo) {
      toAddresses = [];
      handleClose();
    }
    if (toAddresses && toAddresses.length > 0) {
      actions.postEmailOption({
        medcaseId: isOutcomeEmail ? clinicalTreatmentId : medcaseId,
        toAddresses: toAddresses,
        cycle: props.clinicalTreatmentNo,
        emailType: isOutcomeEmail
          ? "OUTCOME_SUBMITTED"
          : props.emailType
          ? props.emailType
          : "AUTHORIZATION",
        requestedService: props.authorizedTreatment
      });
      handleClose();
    } else {
      handleClose();
    }
    console.log("toAddresses", toAddresses, newEmail, additionalRadio);

    // if (values.additionalEmail.includes(",")) {
    //   values.additionalEmail = values.additionalEmail.split(",");
    //   if (selectValue != 4 && newEmail == "New Email") {
    //     actions.postEmailOption({
    //       medcaseId: id,
    //       toAddresses:
    //         values.additionalEmail != "" && additionalRadio == "3"
    //           ? [...values.additionalEmail, values.newEmail]
    //           : [values.newEmail]
    //     });
    //     handleClose();
    //   } else if (selectValue != 4 && newEmail == "DB Email") {
    //     actions.postEmailOption({
    //       medcaseId: id,
    //       toAddresses:
    //         values.additionalEmail != "" && additionalRadio == "3"
    //           ? [...values.additionalEmail, values.emailFromDB]
    //           : [values.emailFromDB]
    //     });
    //     handleClose();
    //   }
    // } else if (selectValue == 4) {
    //   handleClose();
    // } else {
    //   if (selectValue != 4 && newEmail == "New Email") {
    //     actions.postEmailOption({
    //       medcaseId: id,
    //       toAddresses:
    //         values.additionalEmail != ""
    //           ? [values.newEmail, values.additionalEmail]
    //           : [values.newEmail]
    //     });
    //     handleClose();
    //   } else if (selectValue != 4 && newEmail == "DB Email") {
    //     actions.postEmailOption({
    //       medcaseId: id,
    //       toAddresses:
    //         values.additionalEmail != ""
    //           ? [values.emailFromDB, values.additionalEmail]
    //           : [values.emailFromDB]
    //     });
    //     handleClose();
    //   }
    // }
  };

  const handleCloseWarning = () => {
    setIsWarningOpen(false);
    actions.resetPostEmail();
    actions.resetEmail();
  };

  let errorMessage;
  if (errorEmail && errorEmail.includes("not verified")) {
    errorMessage = "Email is not verified";
  } else {
    errorMessage = errorEmail;
  }

  return (
    <>
      <Modal
        open={props.openDialog}
        onClose={handleClose}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box className="modal-style-medcase">
          <Stack direction="row" className="modal-stack">
            <Typography variant="h2">Document Email Option</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
          <Box className="medcase-form-container">
            <Stack className="medcase-form">
              <Grid container className="modal-stackMedCase">
                <Stack>
                  <Typography sx={{ margin: "10px", marginLeft: "0px" }}>
                    {isOutcomeEmail
                      ? "EMAIL OPTION FOR OUTCOME CHANGE"
                      : "EMAIL OPTION FOR PGTA ENROLLMENT"}
                  </Typography>
                </Stack>
              </Grid>
              <Grid container>
                <Grid item xs={4} sx={{ marginBottom: "15px" }}>
                  <Stack>
                    <Grid container className="modal-stackMedCase">
                      <Grid item xs={4}>
                        <InputLabel>Participant</InputLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          size="small"
                          fullWidth
                          autoComplete="off"
                          name="cycleId"
                          value={(emailData.patientEmail && emailData.patientEmail) || "--"}
                          disabled
                        />
                      </Grid>
                    </Grid>
                    <Grid container className="modal-stackMedCase">
                      <Grid item xs={4}>
                        <InputLabel>Physician</InputLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          size="small"
                          fullWidth
                          autoComplete="off"
                          name="cycleId"
                          value={(emailData.physicianEmail && emailData.physicianEmail) || "--"}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={4}>
                  <Stack>
                    <Grid container className="modal-stackMedCase">
                      <Grid item xs={4}>
                        <InputLabel>Contact</InputLabel>
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          size="small"
                          fullWidth
                          autoComplete="off"
                          name="cycleNo"
                          value={(emailData.contactEmail && emailData.contactEmail) || "--"}
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
              <Grid container className="modal-stackMedCase">
                <Grid>
                  <Checkbox
                    checked={state.emailDB}
                    name="emailDB"
                    value="1"
                    onChange={handleCheckboxChange}
                    //onChange={handleChangeRadio}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography>Email Address from db:</Typography>
                </Grid>
                <Grid>
                  <Select
                    fullWidth
                    name="emailFromDB"
                    disabled={state.emailNo}
                    value={values.emailFromDB}
                    className="patient-dropdown"
                    onChange={handleChange}
                    sx={{ marginLeft: "71px", width: "420px" }}
                    multiple>
                    {emailData.emailList && emailData.emailList.length > 0
                      ? emailData.emailList.map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          );
                        })
                      : []}
                  </Select>
                </Grid>
              </Grid>
              {/* <Grid container className="modal-stackMedCase">
                <Grid>
                  <Checkbox
                    checked={state.emailDBSelected}
                    name="emailDBSelected"
                    value="1"
                    onChange={handleCheckboxChange}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography>Email Address from db:</Typography>
                </Grid>
                <Grid>
                  <Select
                    fullWidth
                    name="emailFromDBSelected"
                    disabled={state.emailNo}
                    value={values.emailFromDBSelected}
                    className="patient-dropdown"
                    onChange={handleChange}
                    sx={{ marginLeft: "71px", width: "420px" }}
                    multiple>
                    {console.log(emailData, "emailDataemailDataemailData")}
                    {emailData.emailStringList && emailData.emailStringList.length > 0
                      ? emailData.emailStringList.map((item, index) => {
                          return (
                            <MenuItem value={item} key={index}>
                              {item}
                            </MenuItem>
                          );
                        })
                      : []}
                  </Select>
                </Grid>
              </Grid> */}
              <Grid container className="modal-stackMedCase">
                <Grid>
                  <Checkbox
                    checked={state.emailNew}
                    name="emailNew"
                    value="2"
                    onChange={handleCheckboxChange}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography>Email to new address:</Typography>
                </Grid>
                <Grid>
                  <TextField
                    size="small"
                    name="newEmail"
                    placeholder="Input the new email address here to replace the DB settings"
                    fullWidth
                    disabled={state.emailNo}
                    value={values.newEmail}
                    onChange={handleChange}
                    sx={{ marginLeft: "71px", width: "420px" }}
                  />
                </Grid>
              </Grid>
              <Grid container className="modal-stackMedCase">
                <Grid>
                  <Checkbox
                    checked={state.emailAdditional}
                    name="emailAdditional"
                    value="3"
                    // onChange={() => {
                    //   setIsAdditionalRadio("3");
                    // }}
                    onChange={handleCheckboxChange}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography>Additional Email Address as:</Typography>
                </Grid>
                <Grid>
                  <TextField
                    size="small"
                    placeholder="Add additional email address here to append the DB settings"
                    fullWidth
                    disabled={state.emailNo}
                    value={values.additionalEmail}
                    name="additionalEmail"
                    onChange={handleChange}
                    sx={{ marginLeft: "71px", width: "420px" }}
                  />
                </Grid>
              </Grid>
              <Grid container className="modal-stackMedCase">
                <Grid>
                  <Checkbox
                    checked={state.emailNo}
                    name="emailNo"
                    value="4"
                    onChange={handleCheckboxChange}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography>No Email</Typography>
                </Grid>
                <Grid>
                  <InputLabel sx={{ marginLeft: "71px", width: "420px" }}>
                    No email will be sent
                  </InputLabel>
                </Grid>
              </Grid>
            </Stack>
          </Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 3, mr: 7, alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant="contained" className="btn-primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" className="btn-primary" onClick={handleSendEmail}>
              OK
            </Button>
          </Stack>
        </Box>
      </Modal>
      <MessageModal open={isMessageModal} message={successMsg} closeModal={closeMessageModal} />
      <WarningPopUp open={isWarningOpen} closeModal={handleCloseWarning} error={errorMessage} />
    </>
  );
};

const mapStateToProps = ({ statusReducer, financialSnapshotPaymentReducer }) => {
  return {
    medcaseHeader: statusReducer.medcaseHeader,
    emailData: financialSnapshotPaymentReducer.emailData,
    successMsg: financialSnapshotPaymentReducer.success,
    errorEmail: financialSnapshotPaymentReducer.errorEmail
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMedcaseHeader: (payload) => {
      dispatch(actionType.getMedcaseHeader(payload));
    },
    getEmailOption: (payload) => {
      dispatch(actionType.getEmailOption(payload));
    },
    postEmailOption: (payload) => {
      dispatch(actionType.postEmailOption(payload));
    },
    resetEmail: () => {
      dispatch(actionType.resetEmailList());
    },
    resetPostEmail: () => {
      dispatch(actionType.resetPostEmailOption());
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connect(mapStateToProps, mapDispatchToProps)(EmailDocumentModal));
