import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import TextField from "@mui/material/TextField";

const DatePickerFieldCustom = ({ label, mandatory, value, setEffectiveDate }) => {
  return (
    <>
      <Stack direction="row" className="modal-stack">
        {label ? (
          <InputLabel required={mandatory} className="flex-2 word-break">
            {label}
          </InputLabel>
        ) : null}
        {/* <TextField
          {...props}
          size="small"
          sx={{ flex: 6}}
          value={value}
          type={type}
          // onInvalid={(e) => e.target.setCustomValidity(message)}
          placeholder={placeholder}
          onChange={onChange}
          InputProps={{
            inputProps: { min, max, pattern }
          }}
        /> */}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            minDate={value}
            value={value}
            onChange={(newValue) => {
              setEffectiveDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                sx={{ flex: 6 }}
                {...params}
                size="small"
                className="patient-dropdown"
                fullWidth
              />
            )}></DatePicker>
        </LocalizationProvider>
      </Stack>
    </>
  );
};

export default DatePickerFieldCustom;
