import * as actionType from "../actions/ActionTypes";

const initialState = {
  miscellaneousData: [],
  patienContactData: [],
  physicianData: [],
  programData: [],
  accountFlagData: [],
  metaData: [],
  enrolmentData: [],
  loading: false,
  error: null,
  success: null
};

const caseUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.MISCELLANEOUS_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.MISCELLANEOUS_DATA_SUCCESS: {
      return {
        ...state,
        miscellaneousData: action.payload,
        loading: false
      };
    }
    case actionType.MISCELLANEOUS_DATA_FAILED: {
      return {
        ...state,
        //error: action.errorMsg,
        loading: false
      };
    }
    case actionType.MISCELLANEOUS_DATA_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.MISCELLANEOUS_DATA_UPDATE_SUCCESS: {
      return {
        ...state,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.MISCELLANEOUS_DATA_UPDATE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.PATIENT_CONTACT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PATIENT_CONTACT_DATA_SUCCESS: {
      return {
        ...state,
        patienContactData: action.payload,
        loading: false
      };
    }
    case actionType.PATIENT_CONTACT_DATA_FAILED: {
      return {
        ...state,
        //error: action.errorMsg,
        loading: false
      };
    }
    case actionType.PATIENT_CONTACT_DATA_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PATIENT_CONTACT_DATA_UPDATE_SUCCESS: {
      return {
        ...state,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.PATIENT_CONTACT_DATA_UPDATE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.ENROLMENT_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ENROLMENT_DATA_SUCCESS: {
      return {
        ...state,
        enrolmentData: action.payload,
        loading: false
      };
    }
    case actionType.ENROLMENT_DATA_FAILED: {
      return {
        ...state,
        //error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ENROLMENT_DATA_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ENROLMENT_DATA_UPDATE_SUCCESS: {
      return {
        ...state,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.ENROLMENT_DATA_UPDATE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.PROGRAM_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PROGRAM_DATA_SUCCESS: {
      return {
        ...state,
        programData: action.payload,
        loading: false
      };
    }
    case actionType.PROGRAM_DATA_FAILED: {
      return {
        ...state,
        //error: action.errorMsg,
        loading: false
      };
    }

    case actionType.ACCOUNT_FLAG_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ACCOUNT_FLAG_DATA_SUCCESS: {
      return {
        ...state,
        accountFlagData: action.payload,
        loading: false
      };
    }
    case actionType.ACCOUNT_FLAG_DATA_FAILED: {
      return {
        ...state,
        //error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ACCOUNT_FLAG_DATA_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ACCOUNT_FLAG_DATA_UPDATE_SUCCESS: {
      return {
        ...state,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.ACCOUNT_FLAG_DATA_UPDATE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.PHYSICIAN_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PHYSICIAN_DATA_SUCCESS: {
      return {
        ...state,
        physicianData: action.payload,
        loading: false
      };
    }
    case actionType.PHYSICIAN_DATA_FAILED: {
      return {
        ...state,
        //error: action.errorMsg,
        loading: false
      };
    }

    case actionType.METADATA_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.METADATA_DATA_SUCCESS: {
      return {
        ...state,
        metaData: action.payload,
        loading: false
      };
    }
    case actionType.METADATA_DATA_FAILED: {
      return {
        ...state,
        //error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default caseUpdateReducer;
