import React, { useEffect, useState } from "react";
import {
  Stack,
  Grid,
  Divider,
  Typography,
  Box,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Button
} from "@mui/material";
import * as actionType from "../actions";
import { connect, useDispatch } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import EditIcon from "@mui/icons-material/Edit";
import { useParams } from "react-router-dom";
import MessageModal from "../../shared/components/MessageModal";
import { getPhysicians, getcontactList, getLocations } from "../../admin/actions";
import LoadingModal from "./modals/loadingModal";
import WarningPopUp from "./modals/WarningPopUp";

const CaseUpdate = (props) => {
  const {
    miscellenousData = [],
    patienContactData = [],
    enrolmentData = [],
    accountFlagData = [],
    metaData = [],
    actions,
    success,
    error,
    medcaseHeader,
    loading,
    physicians,
    contactList,
    locations,
    medcaseDetails
  } = props;

  const miscellData = {
    accountRep: "",
    contactFirstName: "",
    contactId: "",
    contactLastName: "",
    expiryDate: null,
    neReason: "",
    physicianFirstName: "",
    physicianId: "",
    physicianLastName: "",
    programName: "",
    refund: 0,
    locationId: ""
  };

  const enrollData = {
    estimatedStimStartDate: null,
    estimatedEnrollmentDate: null,
    followUpDate: null
  };

  const patienData = {
    twoWayContact: false,
    passwordProvided: false,
    onGoingDialouge: false
  };

  const accountData = {
    financialSnapShotFlag: false,
    commentFlag: false,
    followUpDate: null,
    wfrDate: null
  };

  const dispatch = useDispatch();
  const { id } = useParams();
  const [enrollDataValues, setEnrollDataValues] = useState(enrollData);
  const [patienDataValues, setPatienContactDataValues] = useState(patienData);
  const [accountDataValues, setAccountFlagDataValues] = useState(accountData);
  const [miscellaneousDataValues, setMiscellaneousData] = useState(miscellData);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);

  useEffect(() => {
    actions.getMiscelleneousData({ medcaseId: id });
    actions.getPatientContactData({ medcaseId: id });
    actions.getEnrolmentData({ medcaseId: id });
    actions.getMetaData();
    actions.getMedcaseHeader({ medcaseId: id });
    actions.getAccountFlag({ medcaseId: id });
  }, []);

  useEffect(() => {
    if (medcaseHeader && medcaseHeader.practiceId) {
      dispatch(getPhysicians(medcaseHeader.practiceId));
      dispatch(getcontactList(medcaseHeader.practiceId));
      dispatch(getLocations(medcaseHeader.practiceId));
    }
  }, [medcaseHeader]);

  useEffect(() => {
    setEnrollDataValues(enrolmentData);
    setPatienContactDataValues(patienContactData);
    setAccountFlagDataValues(accountFlagData);
    setMiscellaneousData(miscellenousData);
  }, [miscellenousData, patienContactData, enrolmentData, metaData, accountFlagData]);

  useEffect(() => {
    if (success) {
      setIsMessageModal(true);
      actions.getMiscelleneousData({ medcaseId: id });
      actions.getPatientContactData({ medcaseId: id });
      actions.getEnrolmentData({ medcaseId: id });
    }
  }, [success]);

  const handleSavePatienContactData = () => {
    actions.updatedPatienContact({
      medcaseId: id,
      data: patienDataValues
    });
  };

  const dateFormat = (data) => {
    if (data != null) {
      var d = new Date(data).toLocaleDateString().split("/");
      return d[2] + "/" + d[0] + "/" + d[1];
    } else {
      return null;
    }
  };

  const handleSaveMiscellenousData = () => {
    let contact = metaData.contactArray.filter(
      (item) => item.contactId == miscellaneousDataValues.contactId
    );
    let values = {
      ...miscellaneousDataValues,
      contactFirstName: (contact.length > 0 && contact[0].firstName) || "",
      contactLastName: (contact.length > 0 && contact[0].lastName) || "",
      expiryDate: dateFormat(miscellaneousDataValues.expiryDate)
    };
    actions.updatedMiscellenous({
      medcaseId: id,
      data: values
    });
  };

  const handleSaveEnrolmentData = () => {
    let values = {
      estimatedStimStartDate: dateFormat(enrollDataValues.estimatedStimStartDate),
      estimatedEnrollmentDate: dateFormat(enrollDataValues.estimatedEnrollmentDate),
      followUpDate: dateFormat(enrollDataValues.followUpDate)
    };
    actions.updatedEnrolment({
      medCaseId: id,
      data: values
    });
  };

  const handleSaveAccountFlagData = () => {
    let values = {
      commentFlag: accountDataValues.commentFlag,
      financialSnapShotFlag: accountDataValues.financialSnapShotFlag,
      wfrDate: dateFormat(accountDataValues.wfrDate),
      followUpDate: dateFormat(accountDataValues.followUpDate)
    };
    actions.updatedAccountFlag({
      medcaseId: id,
      data: values
    });
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  useEffect(() => {
    if (error) {
      setIsOpenError(true);
      actions.getMiscelleneousData({ medcaseId: id });
      actions.getPatientContactData({ medcaseId: id });
      actions.getEnrolmentData({ medcaseId: id });
    }
  }, [error]);

  return (
    <>
      <Box>
        <Box className="m-8">
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction="row"
                sx={{
                  my: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "3px"
                }}>
                <Typography variant="h2">Miscellaneous</Typography>
                <Button
                  variant="contained"
                  className="btn-usf"
                  onClick={handleSaveMiscellenousData}>
                  Save
                </Button>
              </Stack>
              <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
              <Stack className="pad-5" sx={{ my: 1 }} direction="row">
                <Grid container className="align-center">
                  <Grid item xs={1.5}>
                    <Typography>Refund Percentage</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <Stack direction="row">
                      <TextField
                        value={miscellaneousDataValues.refund || "0"}
                        onChange={(event) => {
                          setMiscellaneousData({
                            ...miscellaneousDataValues,
                            refund: event.target.value
                          });
                        }}
                        disabled={medcaseHeader.medcaseType == "PGTA"}
                        size="small"
                        fullWidth
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={0.5}></Grid>
                  <Grid item xs={1.5}>
                    <Typography style={{ marginLeft: "10px" }}>Not Enrolled Reason</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <Select
                      name="pgtaAnalysisStatus"
                      value={miscellaneousDataValues.neReason || ""}
                      fullWidth
                      sx={{ flex: 6, height: 35 }}
                      onChange={(event) => {
                        setMiscellaneousData({
                          ...miscellaneousDataValues,
                          neReason: event.target.value
                        });
                      }}>
                      {metaData &&
                      metaData.notEnrolledReason &&
                      metaData.notEnrolledReason.length > 0
                        ? metaData.notEnrolledReason.map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Select>
                  </Grid>
                </Grid>
              </Stack>
              <Stack className="pad-5" direction="row">
                <Grid container className="align-center">
                  <Grid item xs={1.5}>
                    <Typography>Account Rep</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <Select
                      name="medcaseStatus"
                      fullWidth
                      value={miscellaneousDataValues.accountRep || ""}
                      className="patient-dropdown"
                      onChange={(event) => {
                        setMiscellaneousData({
                          ...miscellaneousDataValues,
                          accountRep: event.target.value
                        });
                      }}>
                      {metaData && metaData.accountRepArray
                        ? metaData.accountRepArray.map((item, index) => {
                            return (
                              <MenuItem value={item} key={index}>
                                {item}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Select>
                  </Grid>
                  <Grid item xs={0.5}></Grid>
                  <Grid item xs={1.5}>
                    <Typography style={{ marginLeft: "10px" }}>Program</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <TextField fullWidth value={miscellaneousDataValues.programName} disabled />
                  </Grid>
                </Grid>
              </Stack>
              <Stack className="pad-5" direction="row">
                <Grid container className="align-center">
                  <Grid item xs={1.5}>
                    <Typography>Physician</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <Select
                      name="physician"
                      fullWidth
                      value={
                        miscellaneousDataValues?.physicianId
                          ? miscellaneousDataValues.physicianId
                          : medcaseDetails?.physicianId
                      }
                      className="patient-dropdown"
                      onChange={(event) => {
                        setMiscellaneousData({
                          ...miscellaneousDataValues,
                          //physicianFirstName: event.target.value.split(" ")[0],
                          //physicianLastName: event.target.value.split(" ")[1]
                          physicianId: event.target.value
                        });
                      }}
                      IconComponent={EditIcon}>
                      {physicians && physicians.length > 0
                        ? physicians.map((item, index) => {
                            return (
                              <MenuItem value={item.physicianId} key={index}>
                                {item.firstName + " " + item.lastName}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Select>
                  </Grid>
                  <Grid item xs={0.5}></Grid>
                  <Grid item xs={1.5}>
                    <Typography style={{ marginLeft: "10px" }}>Contact</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <Select
                      name="pgtaAnalysisStatus"
                      fullWidth
                      size="small"
                      value={miscellaneousDataValues.contactId || ""}
                      sx={{ flex: 6, height: 35 }}
                      onChange={(event) => {
                        setMiscellaneousData({
                          ...miscellaneousDataValues,
                          contactId: event.target.value
                        });
                      }}
                      IconComponent={EditIcon}>
                      {contactList && contactList.length > 0
                        ? contactList.map((item, index) => {
                            return (
                              <MenuItem value={item.contactId} key={index}>
                                {item.firstName + " " + item.lastName}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Select>
                  </Grid>
                </Grid>
              </Stack>
              <Stack className="pad-5" direction="row">
                <Grid container className="align-center">
                  <Grid item xs={1.5}>
                    <Typography>Contract Exp Date</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <LocalizationProvider dateAdapter={AdapterMoment} fullWidth>
                      <DatePicker
                        openTo="day"
                        fullWidth
                        size="small"
                        value={miscellaneousDataValues.expiryDate}
                        onChange={(newValue) => {
                          setMiscellaneousData({
                            ...miscellaneousDataValues,
                            expiryDate: newValue
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            className="patient-dropdown"
                          />
                        )}></DatePicker>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={0.5}></Grid>
                  <Grid item xs={1.5}>
                    <Typography style={{ marginLeft: "10px" }}>Location</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <Select
                      name="locationId"
                      fullWidth
                      size="small"
                      value={miscellaneousDataValues.locationId || ""}
                      sx={{ flex: 6, height: 35 }}
                      onChange={(event) => {
                        setMiscellaneousData({
                          ...miscellaneousDataValues,
                          locationId: event.target.value
                        });
                      }}>
                      {locations && locations.length > 0
                        ? locations.map((item, index) => {
                            return (
                              <MenuItem value={item.locationId} key={index}>
                                {item.longName}
                              </MenuItem>
                            );
                          })
                        : []}
                    </Select>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box className="m-8">
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction="row"
                sx={{
                  my: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "3px"
                }}>
                <Typography variant="h2">Patient Contact</Typography>
                <Button
                  variant="contained"
                  className="btn-usf"
                  onClick={handleSavePatienContactData}
                  disabled={medcaseHeader.medcaseActive == "Inactive" ? true : false}>
                  Save
                </Button>
              </Stack>
              <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
              <Stack className="pad-5" sx={{ my: 1 }} direction="row">
                <Grid container className="align-center" item xs={8}>
                  <Grid item xs={1.5}>
                    <Typography
                      sx={{ flex: 6 }}
                      id="modal-add-description-label"
                      color="text.primary">
                      2-way contact
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Stack direction="row">
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={() => {
                          setPatienContactDataValues({
                            ...patienDataValues,
                            twoWayContact: !patienDataValues.twoWayContact
                          });
                        }}
                        value={patienDataValues.twoWayContact}
                        checked={patienDataValues.twoWayContact || false}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography
                      sx={{ flex: 6 }}
                      id="modal-add-description-label"
                      color="text.primary">
                      Password Provided
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={() => {
                        setPatienContactDataValues({
                          ...patienDataValues,
                          passwordProvided: !patienDataValues.passwordProvided
                        });
                      }}
                      value={patienDataValues.passwordProvided}
                      checked={patienDataValues.passwordProvided || false}
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography
                      sx={{ flex: 6 }}
                      id="modal-add-description-label"
                      color="text.primary">
                      Ongoing Dialogue
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={() => {
                        setPatienContactDataValues({
                          ...patienDataValues,
                          onGoingDialouge: !patienDataValues.onGoingDialouge
                        });
                      }}
                      value={patienDataValues.onGoingDialouge}
                      checked={patienDataValues.onGoingDialouge || false}
                    />
                    {/* </Stack> */}
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box className="m-8">
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction="row"
                sx={{
                  my: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "3px"
                }}>
                <Typography variant="h2">Enrollment Data</Typography>
                <Button
                  variant="contained"
                  className="btn-usf"
                  onClick={handleSaveEnrolmentData}
                  disabled={medcaseHeader.medcaseActive == "Inactive" ? true : false}>
                  Save
                </Button>
              </Stack>
              <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
              <Stack className="pad-5" sx={{ my: 1 }} direction="row">
                <Grid container className="align-center">
                  <Grid item xs={1.5}>
                    <Typography>Estimated Stim Start Date</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <LocalizationProvider fullWidth dateAdapter={AdapterMoment}>
                      <DatePicker
                        openTo="day"
                        fullWidth
                        size="small"
                        value={enrollDataValues.estimatedStimStartDate}
                        onChange={(newValue) => {
                          setEnrollDataValues({
                            ...enrollDataValues,
                            estimatedStimStartDate: newValue
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            className="patient-dropdown"
                          />
                        )}></DatePicker>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={0.5}></Grid>
                  <Grid item xs={1.5}>
                    <Typography>Estimated Enrollment Date</Typography>
                  </Grid>
                  <Grid item xs={4.25}>
                    <LocalizationProvider fullWidth dateAdapter={AdapterMoment}>
                      <DatePicker
                        openTo="day"
                        fullWidth
                        size="small"
                        value={enrollDataValues.estimatedEnrollmentDate}
                        onChange={(newValue) => {
                          setEnrollDataValues({
                            ...enrollDataValues,
                            estimatedEnrollmentDate: newValue
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            fullWidth
                            className="patient-dropdown"
                          />
                        )}></DatePicker>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Stack>
              <Stack className="pad-5" sx={{ my: 1 }} direction="row">
                <Grid container className="align-center">
                  <Grid item xs={1.5}>
                    <Typography>Follow Up Date</Typography>
                  </Grid>
                  <Grid item xs={4.71}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        openTo="day"
                        value={enrollDataValues.followUpDate}
                        onChange={(newValue) => {
                          setEnrollDataValues({
                            ...enrollDataValues,
                            followUpDate: newValue
                          });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" className="patient-dropdown" />
                        )}></DatePicker>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Box>
        <Box className="m-8">
          <Grid container>
            <Grid item xs={12}>
              <Stack
                direction="row"
                sx={{
                  my: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "3px"
                }}>
                <Typography variant="h2">Account Flag</Typography>
                <Button
                  variant="contained"
                  className="btn-usf"
                  onClick={handleSaveAccountFlagData}
                  disabled={medcaseHeader.medcaseActive == "Inactive" ? true : false}>
                  Save
                </Button>
              </Stack>
              <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
              <Typography sx={{ my: 1 }}>Flag a Medcase</Typography>
              <Stack className="pad-5" direction="row">
                <Grid container className="align-center" item xs={8}>
                  <Grid item xs={1.5}>
                    <Typography
                      sx={{ flex: 6 }}
                      id="modal-add-description-label"
                      color="text.primary">
                      Financial
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Stack direction="row">
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={() => {
                          setAccountFlagDataValues({
                            ...accountDataValues,
                            financialSnapShotFlag: !accountDataValues.financialSnapShotFlag
                          });
                        }}
                        value={accountDataValues.financialSnapShotFlag}
                        checked={accountDataValues.financialSnapShotFlag || false}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography
                      sx={{ flex: 6 }}
                      id="modal-add-description-label"
                      color="text.primary">
                      Comments
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5}>
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      onChange={() => {
                        setAccountFlagDataValues({
                          ...accountDataValues,
                          commentFlag: !accountDataValues.commentFlag
                        });
                      }}
                      value={accountDataValues.commentFlag}
                      checked={accountDataValues.commentFlag || false}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
          <Box className="m-8">
            <Stack className="pad-5" direction="row">
              <Grid container className="align-center">
                <Grid item xs={1.5}>
                  <Typography>Limbo Follow up Date</Typography>
                </Grid>
                <Grid item xs={4.25}>
                  <LocalizationProvider fullWidth dateAdapter={AdapterMoment}>
                    <DatePicker
                      openTo="day"
                      fullWidth
                      size="small"
                      onChange={(newValue) => {
                        setAccountFlagDataValues({
                          ...accountDataValues,
                          followUpDate: newValue
                        });
                      }}
                      value={accountDataValues.followUpDate}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          className="patient-dropdown"
                        />
                      )}></DatePicker>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={0.5}></Grid>
                <Grid item xs={1.5}>
                  <Typography>Withdrawal Form Received</Typography>
                </Grid>
                <Grid item xs={4.25}>
                  <LocalizationProvider fullWidth dateAdapter={AdapterMoment}>
                    <DatePicker
                      openTo="day"
                      fullWidth
                      size="small"
                      value={accountDataValues.wfrDate}
                      onChange={(newValue) => {
                        setAccountFlagDataValues({
                          ...accountDataValues,
                          wfrDate: newValue
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          fullWidth
                          className="patient-dropdown"
                        />
                      )}></DatePicker>
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Box>
      </Box>
      <MessageModal
        open={isMessageModal}
        message={"Updated Sucessfully"}
        //error={error}
        closeModal={closeMessageModal}
      />
      {error && (
        <WarningPopUp
          open={isOpenError}
          closeModal={() => {
            setIsOpenError(false);
            actions.resetMessage();
          }}
          error={error}
        />
      )}

      <LoadingModal open={loading} />
    </>
  );
};

const mapStateToProps = ({
  caseUpdateReducer,
  statusReducer,
  physiciansReducer,
  contactReducer,
  locationReducer,
  medcaseHeaderReducer
}) => {
  return {
    loading: caseUpdateReducer.loading,
    miscellenousData: caseUpdateReducer.miscellaneousData,
    patienContactData: caseUpdateReducer.patienContactData,
    metaData: caseUpdateReducer.metaData,
    enrolmentData: caseUpdateReducer.enrolmentData,
    accountFlagData: caseUpdateReducer.accountFlagData,
    error: caseUpdateReducer.error,
    success: caseUpdateReducer.success,
    medcaseHeader: statusReducer.medcaseHeader,
    physicians: physiciansReducer.physicians,
    contactList: contactReducer.contactList,
    locations: locationReducer.locations,
    medcaseDetails: medcaseHeaderReducer.medcaseDetails
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMiscelleneousData: (medCaseId) => {
      dispatch(actionType.getMiscellaneousData(medCaseId));
    },
    getPatientContactData: (medCaseId) => {
      dispatch(actionType.getPatientContactData(medCaseId));
    },
    getEnrolmentData: (medCaseId) => {
      dispatch(actionType.getEnrolmentData(medCaseId));
    },
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    },
    getMedcaseHeader: (payload) => {
      dispatch(actionType.getMedcaseHeader(payload));
    },
    getAccountFlag: (medCaseId) => {
      dispatch(actionType.getAccountFlag(medCaseId));
    },
    updatedMiscellenous: (payload) => {
      dispatch(actionType.updatedMiscellenousData(payload));
    },
    updatedPatienContact: (payload) => {
      dispatch(actionType.updatedPatientContactData(payload));
    },
    updatedEnrolment: (payload) => {
      dispatch(actionType.updatedEnrolmentData(payload));
    },
    updatedAccountFlag: (payload) => {
      dispatch(actionType.updatedAccountFlagData(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseUpdate);
