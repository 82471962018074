import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* updateLocationProgramList(action) {
  const { gdmId, practiceId, data } = action.payload;
  const params = data.map((item) => {
    return {
      pmId: item.pmId,
      fetTpId: item.fetTpId,
      fetCycles: item.fetCycles,
      prScaleId: item.prScaleId
    };
  });

  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/gdms/${gdmId}/lpds`,
      "",
      params
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.LOCATION_PROGRAM_UPDATE_SUCCESS,
      successMsg: MSG.SAVED_SUCCESSFULLY,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.LOCATION_PROGRAM_UPDATE_FAIL,
        message: error.message
      });
    }
  }
}

function* fetchLocationProgramList(action) {
  const { gdmId, practiceId } = action.payload;
  try {
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/lpds`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.LOCATION_PROGRAM_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.LOCATION_PROGRAM_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* fetchMetadata(action) {
  const { gdmId, practiceId } = action.payload;
  try {
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/lpds/metadata`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.METADATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.METADATA_FAIL,
        message: error.message
      });
    }
  }
}

function* updLocationProgramSaga() {
  yield takeEvery(actionTypes.LOCATION_PROGRAM_UPDATE_REQUESTED, updateLocationProgramList);
}

function* getLocationProgramSaga() {
  yield takeEvery(actionTypes.LOCATION_PROGRAM_LIST_REQUESTED, fetchLocationProgramList);
}

function* getMetadataSaga() {
  yield takeEvery(actionTypes.METADATA_REQUESTED, fetchMetadata);
}
export default function* locationProgramSaga() {
  yield all([getLocationProgramSaga(), getMetadataSaga(), updLocationProgramSaga()]);
}
