import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as actionType from "../../workspace/actions";

const RightSideBar = (props) => {
  const id = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState("2");
  const { actions } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box flexDirection="row">
      <TabContext value={value}>
        <Box>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <TabList onChange={handleChange}>
              <Tab label="TASK" value="1" />
              <Tab label="ACTION" value="2" />
            </TabList>
          </Stack>
        </Box>
        <TabPanel value="1" className="pad-0">
          <Box sx={{ ml: 1 }}>
            <Stack flexDirection="column">
              <FormControlLabel control={<Checkbox />} label="Financial Program discussion" />
              <FormControlLabel
                control={<Checkbox />}
                label="Treatment Options under considerations"
              />
              <FormControlLabel
                control={<Checkbox />}
                label="Financial Program under considerations"
              />
            </Stack>
          </Box>
        </TabPanel>
        <TabPanel value="2" className="pad-0">
          <Box sx={{ m: 1 }}>
            <Stack flexDirection="column" gap={1}>
              <Button
                variant="contained"
                onClick={() => {
                  localStorage.setItem(
                    "patientName",
                    props.patientDetails.firstName + " " + props.patientDetails.lastName
                  );
                  actions.resetEstimateViewList();
                  navigate(`/workspace/financial-estimates/${id.personId}?PT=PD`);
                }}>
                Create a Financial Estimates
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/workspace/fertility-access-program");
                }}>
                Fertility Access Program
              </Button>
            </Stack>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

const mapStateToProps = ({ estimateReducer }) => {
  return estimateReducer;
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    resetEstimateViewList: () => {
      dispatch(actionType.resetEstimateViewList());
    },
    setEstimateId: (payload) => {
      dispatch(actionType.setEstimateId(payload));
    },
    inputValues: (payload) => {
      dispatch(actionType.inputValues(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(RightSideBar);
