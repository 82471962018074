import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPhysicians(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/${payload}/physicians`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.PHYSICIAN_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PHYSICIAN_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getPhysicianSaga() {
  yield takeEvery(actionTypes.PHYSICIAN_LIST_REQUESTED, fetchPhysicians);
}

function* createNewPhysician(action) {
  const { payload } = action;
  const req = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    emailAddress: payload.emailAddress,
    npiNo: payload.npiNo,
    externalId: payload.externalId,
    fertExternalId: payload.fertExternalId,
    status: payload.status,
    locationIds: payload.locations
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/physicians`,
      "",
      req
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_NEW_PHYSICIAN_SUCCESS,
      successMsg: MSG.SAVED_SUCCESSFULLY,
      payload: response.data
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_PHYSICIAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewPhysicianSaga() {
  yield takeEvery(actionTypes.ADD_NEW_PHYSICIAN_REQUESTED, createNewPhysician);
}

function* editPhysician(action) {
  const { payload } = action;
  const body = {
    firstName: payload.firstName,
    lastName: payload.lastName,
    emailAddress: payload.emailAddress,
    npiNo: payload.npiNo,
    locationIds: payload.locations,
    externalId: payload.externalId,
    fertExternalId: payload.fertExternalId,
    status: payload.status
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}/physicians/${payload.physicianId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_PHYSICIAN_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_PHYSICIAN_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editPhysicianSaga() {
  yield takeEvery(actionTypes.EDIT_PHYSICIAN_REQUESTED, editPhysician);
}

export default function* physcianSaga() {
  yield all([getPhysicianSaga(), addNewPhysicianSaga(), editPhysicianSaga()]);
}
