import { Box, Grid, Stack } from "@mui/material";
import React from "react";
import FinancialDirectory from "./FinancialDirectory";

const FinancialEstimates = () => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12}>
          <Stack sx={{ mx: 1 }}>
            <FinancialDirectory />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FinancialEstimates;
