import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

// get All estimate questions list
function* getRefundQstnsList() {
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/estimates/refundApplicationQuestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.REFUND_QUESTIONS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.REFUND_QUESTIONS_FAILED,
        message: error.message
      });
    }
  }
}

function* getRefundQstnsListSaga() {
  yield takeEvery(actionTypes.REFUND_QUESTIONS_REQUESTED, getRefundQstnsList);
}

// get All estimate questions list
function* getPracticeUsers(actions) {
  try {
    const params = {
      offset: "",
      limit: "10000",
      status: "active"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${actions.payload}/contacts`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PRACTICE_USERS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.PRACTICE_USERS_FAILED,
        message: error.message
      });
    }
  }
}

function* getPracticeUsersSaga() {
  yield takeEvery(actionTypes.PRACTICE_USERS_REQUESTED, getPracticeUsers);
}

//add adjustments
function* withdrawRefund(action) {
  const { payload } = action;
  const { estimateId, estimateTpId, withdrawReason, query } = payload;
  const body = { withdrawReason };

  try {
    const params = {
      action: query
    };
    const requestBody = createRequestBody(
      "put",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/refundApplications`,
      params,
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.WITHDRAW_REFUND_APP_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.WITHDRAW_REFUND_APP_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* withdrawRefundSaga() {
  yield takeEvery(actionTypes.WITHDRAW_REFUND_APP_REQUESTED, withdrawRefund);
}

//add adjustments
function* assignApplication(action) {
  const { payload } = action;
  const { estimateId, estimateTpId, assignee } = payload;
  const body = {
    assignee
  };

  try {
    const params = {
      action: "assign"
    };
    const requestBody = createRequestBody(
      "put",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/refundApplications`,
      params,
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ASIGNEE_APP_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ASIGNEE_APP_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* assignApplicationSaga() {
  yield takeEvery(actionTypes.ASIGNEE_APP_REQUESTED, assignApplication);
}

//add save refund
function* saveRefund(action) {
  const { payload } = action;
  const { estimateId, estimateTpId, values, query } = payload;
  const body = { refundResponse: values };

  try {
    const params = {
      action: query
    };
    const requestBody = createRequestBody(
      "put",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/refundApplications`,
      params,
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.SAVE_REFUND_APP_SUCCESS,
      payload: response.data,
      successMsg: response.data.refundApplicationStatus
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.SAVE_REFUND_APP_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* saveRefundSaga() {
  yield takeEvery(actionTypes.SAVE_REFUND_APP_REQUESTED, saveRefund);
}

// get refund response
function* getRefundResponse(actions) {
  const { payload } = actions;
  const { estimateId, estimateTpId } = payload;

  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/refundQuestionResponses`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.GET_REFUND_RESPONSE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_REFUND_RESPONSE_FAILED,
        message: error.response.data
      });
    }
  }
}

function* getRefundResponseSaga() {
  yield takeEvery(actionTypes.GET_REFUND_RESPONSE_REQUESTED, getRefundResponse);
}

// approve and decline refund application
function* approveDenyRefundApplication(action) {
  const { payload } = action;
  const { estimateId, estimateTpId, refundApplicationNotes, refundApplicationStatus, query } =
    payload;
  const body = { refundApplicationStatus, refundApplicationNotes };

  try {
    const params = {
      action: query
    };
    const requestBody = createRequestBody(
      "put",
      `/estimates/${estimateId}/estimateTreatmentPlans/${estimateTpId}/refundApplications`,
      params,
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.APPROVE_DENY_REFUND_APPLICATION_SUCCESS,
      payload: response.data,
      successMsg:
        refundApplicationStatus == "Submitted Approved"
          ? MSG.APPROVED_SUCCESSFULLY
          : refundApplicationStatus == "Submitted Denied"
          ? MSG.DENIED_SUCCESSFULLY
          : MSG.STATUS_SAVED
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.APPROVE_DENY_REFUND_APPLICATION_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* approveDenyRefundApplicationSaga() {
  yield takeEvery(
    actionTypes.APPROVE_DENY_REFUND_APPLICATION_REQUESTED,
    approveDenyRefundApplication
  );
}

export default function* refundSaga() {
  yield all([
    getRefundQstnsListSaga(),
    withdrawRefundSaga(),
    getPracticeUsersSaga(),
    assignApplicationSaga(),
    saveRefundSaga(),
    getRefundResponseSaga(),
    approveDenyRefundApplicationSaga()
  ]);
}
