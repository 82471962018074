import * as actionType from "../actions/ActionTypes";
const initialState = {
  patientDetails: {},
  medcaseDetails: {},
  loading: false,
  error: null,
  success: null
};
const medcaseHeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PATIENT_DETAILS_HEADER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PATIENT_DETAILS_HEADER_SUCCESS: {
      return {
        ...state,
        patientDetails: action.payload,
        loading: false,
        success: true
      };
    }
    case actionType.PATIENT_DETAILS_HEADER_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.MEDCASE_DETAILS_HEADER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.MEDCASE_DETAILS_HEADER_SUCCESS: {
      return {
        ...state,
        medcaseDetails: action.payload,
        loading: false,
        success: true
      };
    }
    case actionType.MEDCASE_DETAILS_HEADER_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default medcaseHeaderReducer;
