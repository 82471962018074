import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchCTM() {
  try {
    // const params = {
    //   offset: "0",
    //   limit: "100"
    // };
    const requestBody = createRequestBody("get", `/contract/templateProgramMapping`, "", "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CTM_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.CTM_LIST_FAIL, message: error.message });
    }
  }
}

function* getCTMSaga() {
  yield takeEvery(actionTypes.CTM_LIST_REQUESTED, fetchCTM);
}

function* createNewCTM(action) {
  const { payload } = action;
  const requestbody = {
    programId: payload.programId,
    practiceId: payload.practiceId,
    templateId: payload.templateId,
    effectiveDate: payload.effectiveDate,
    endDate: payload.endDate,
    treatmentPlanId: payload.treatmentPlanId,
    partner: payload.partner
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/contract/templateProgramMapping`,
      "",
      requestbody
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_NEW_CTM_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_CTM_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewCTMSaga() {
  yield takeEvery(actionTypes.ADD_NEW_CTM_REQUESTED, createNewCTM);
}

function* editCTM(action) {
  const { payload } = action;
  const body = {
    templateId: payload.templateId,
    practiceId: payload.practiceId,
    programId: payload.programId,
    effectiveDate: payload.effectiveDate,
    endDate: payload.endDate,
    treatmentPlanId: payload.treatmentPlanId,
    partner: payload.partner
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/contract/templateProgramMapping/${payload.templateProgramId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_CTM_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_CTM_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editCTMSaga() {
  yield takeEvery(actionTypes.EDIT_CTM_REQUESTED, editCTM);
}

function* deleteCTM(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "delete",
      `/contract/templateProgramMapping/${payload.templateProgramId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.DELETE_CTM_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_CTM_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteCTMSaga() {
  yield takeEvery(actionTypes.DELETE_CTM_REQUESTED, deleteCTM);
}

function* fetchProgramsData(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/${payload}/programs`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PROGRAMSDATA_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PROGRAMSDATA_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getProgramsData() {
  yield takeEvery(actionTypes.PROGRAMSDATA_LIST_REQUESTED, fetchProgramsData);
}

function* fetchTemplate() {
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/templates`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TEMPLATE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TEMPLATE_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getTemplate() {
  yield takeEvery(actionTypes.TEMPLATE_LIST_REQUESTED, fetchTemplate);
}

export default function* ctmSaga() {
  yield all([
    getCTMSaga(),
    addNewCTMSaga(),
    editCTMSaga(),
    deleteCTMSaga(),
    getProgramsData(),
    getTemplate()
  ]);
}
