import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PROGRAM_NAME_ARR } from "../../../shared/constants/constants";

const style = {
  position: "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};

const ProgramVisualizationModal = (props) => {
  const [programId, setProgramId] = useState("");
  const [boxName, setBoxName] = useState("");

  useEffect(() => {
    if (props) {
      setBoxName("");
      setProgramId();
    }
  }, [props]);
  const handleProgramChange = (event) => {
    setProgramId(event.target.value);
  };
  const handleCreateAddBox = () => {
    let payload = {};
    if (props.type === "addBox") {
      payload.boxName = boxName;
    } else if (props.type === "addProgram") {
      payload.programId = programId;
      payload.boxNumber = props.boxNum;
    }
    props.createBox(payload);
  };
  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box sx={style}>
          {props.type === "addBox" ? (
            <>
              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Typography id="modal-new-practice-payment" color="text.primary">
                  Add Box
                </Typography>
                <IconButton
                  onClick={() => {
                    props.closeModal();
                  }}>
                  <Close color="primary" />
                </IconButton>
              </Stack>
              <Divider sx={{ color: "primary" }} orientation="horizontal" />
              <Stack
                direction="row"
                sx={{
                  my: 3,
                  alignItems: "center",
                  justifyContent: "space-between"
                }}>
                <InputLabel sx={{ flex: 2 }} required>
                  Box Name
                </InputLabel>
                <Select
                  sx={{ flex: 7 }}
                  value={boxName}
                  fullWidth
                  onChange={(e) => setBoxName(e.target.value)}>
                  {PROGRAM_NAME_ARR.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
                {/* <TextField
                  sx={{ flex: 7 }}
                  size="small"
                  id="filled-basic"
                  value={boxName}
                  onChange={(e) => setBoxName(e.target.value)}
                  className="add-description"
                /> */}
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    props.closeModal(), setBoxName("");
                  }}
                  sx={{ border: "2px solid primary" }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCreateAddBox}
                  sx={{
                    background: "primary",
                    "&:hover": {
                      color: "primary"
                    }
                  }}>
                  Create
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <Typography id="modal-new-practice-payment" color="text.primary">
                  Add Box
                </Typography>
                <IconButton
                  onClick={() => {
                    props.closeModal();
                  }}>
                  <Close color="primary" />
                </IconButton>
              </Stack>
              <Divider sx={{ color: "primary" }} orientation="horizontal" />
              <Stack
                direction="row"
                sx={{
                  my: 3,
                  alignItems: "center",
                  justifyContent: "space-between"
                }}>
                <InputLabel required sx={{ flex: 2 }}>
                  Program
                </InputLabel>
                <Select
                  sx={{ flex: 7 }}
                  fullWidth
                  name="practiceId"
                  value={programId}
                  onChange={handleProgramChange}
                  className="stage-practice-dropdown">
                  {props.data?.map((item, index) => {
                    return (
                      <MenuItem value={item.programId} key={index}>
                        {item.programName} -{item.programId}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    props.closeModal();
                  }}
                  sx={{ border: "2px solid primary" }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCreateAddBox}
                  sx={{
                    background: "primary",
                    "&:hover": {
                      color: "primary"
                    }
                  }}>
                  Create
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};
export default ProgramVisualizationModal;
