import * as actionType from "../actions/ActionTypes";

const initialState = {
  addPatientPaymentCC: [],
  addPatientPaymentCH: [],
  addPatientPaymentLN: [],
  addPatientPaymentWT: [],
  error: null,
  success: null,
  loading: false,
  paymentDetails: [],
  successPaymentDetails: null
};

const patientPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CREATE_CREDIT_CARD_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_CREDIT_CARD_PAYMENT_SUCCESS: {
      return {
        ...state,
        addPatientPaymentCC: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.CREATE_CREDIT_CARD_PAYMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.CREATE_CHECK_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_CHECK_PAYMENT_SUCCESS: {
      return {
        ...state,
        addPatientPaymentCH: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.CREATE_CHECK_PAYMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.CREATE_LOAN_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_LOAN_PAYMENT_SUCCESS: {
      return {
        ...state,
        addPatientPaymentLN: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.CREATE_LOAN_PAYMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.CREATE_WIRE_TRANSFER_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_WIRE_TRANSFER_PAYMENT_SUCCESS: {
      return {
        ...state,
        addPatientPaymentWT: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.CREATE_WIRE_TRANSFER_PAYMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    case actionType.RESET_PATIENT_PAYMENT: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    case actionType.GET_PATIENT_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_PATIENT_PAYMENT_SUCCESS: {
      return {
        ...state,
        paymentDetails: action.payload,
        successPaymentDetails: action.successMsg,
        loading: false
      };
    }
    case actionType.GET_PATIENT_PAYMENT_FAIL: {
      return {
        ...state,
        loading: false
      };
    }
    case actionType.RESET_GET_PATIENT_PAYMENT: {
      return {
        ...state,
        successPaymentDetails: false
      };
    }

    case actionType.EDIT_WIRE_TRANSFER_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_WIRE_TRANSFER_PAYMENT_SUCCESS: {
      return {
        ...state,
        addPatientPaymentWT: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_WIRE_TRANSFER_PAYMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_LOAN_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_LOAN_PAYMENT_SUCCESS: {
      return {
        ...state,
        addPatientPaymentLN: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_LOAN_PAYMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_CHECK_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_CHECK_PAYMENT_SUCCESS: {
      return {
        ...state,
        addPatientPaymentCH: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_CHECK_PAYMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_CREDIT_CARD_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_CREDIT_CARD_PAYMENT_SUCCESS: {
      return {
        ...state,
        addPatientPaymentCC: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_CREDIT_CARD_PAYMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default patientPaymentReducer;
