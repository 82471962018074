import { MenuItem, Paper, Select, TextField } from "@mui/material";
import { useState } from "react";

const AmountField = ({ amountType, options, onChange, fieldvalue, ...props }) => {
  const [amountTypes, setAmountTypes] = useState(amountType);

  const handleChange = (event) => {
    fieldvalue(event.target.value);
    setAmountTypes(event.target.value);
  };
  return (
    <Paper elevation={0} sx={{ display: "flex", alignItems: "center" }}>
      <Select size="small" value={amountTypes} onChange={handleChange}>
        {options.map((option, key) => {
          return (
            <MenuItem key={key} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      <TextField
        size="small"
        InputProps={{ inputProps: { min: 0 } }}
        autoComplete="off"
        type="number"
        onChange={onChange}
        {...props}
      />
    </Paper>
  );
};

export default AmountField;
