import * as actionType from "../actions/ActionTypes";

const initialState = {
  externalIdsList: [],
  addExternalDetails: null,
  editExternalDetails: null,
  externalSystemPracticeId: null,
  loading: false,
  error: null,
  success: false
};

const externalDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.EXTERNAL_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EXTERNAL_ID_LIST_SUCCESS: {
      return {
        ...state,
        externalIdsList: action.payload,
        loading: false
      };
    }
    case actionType.EXTERNAL_ID_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.ADD_EXTERNAL_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_EXTERNAL_ID_LIST_SUCCESS: {
      return {
        ...state,
        addExternalDetails: action.payload,
        loading: false,
        success: action.success
      };
    }
    case actionType.ADD_EXTERNAL_ID_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.EDIT_EXTERNAL_ID_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_EXTERNAL_ID_LIST_SUCCESS: {
      return {
        ...state,
        editExternalDetails: action.payload,
        loading: false,
        success: action.success
      };
    }
    case actionType.EDIT_EXTERNAL_ID_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.RESET_EXTERNAL_ID_LIST: {
      return {
        ...state,
        error: false,
        loading: false,
        success: false
      };
    }
    case actionType.EXTERNAL_SYSTEM_DETAIL_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EXTERNAL_SYSTEM_DETAIL_SUCCESS: {
      return {
        ...state,
        externalSystemPracticeId: action.payload,
        loading: false
      };
    }
    case actionType.EXTERNAL_SYSTEM_DETAIL_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.EXTERNAL_SYSTEM_DETAIL_RESET: {
      return {
        ...state,
        externalSystemPracticeId: action.payload,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default externalDetailsReducer;
