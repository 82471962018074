import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchServiceItems(action) {
  const { payload } = action;
  const { practiceId, gdmId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/service-items`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.SERVICEITEMS_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.SERVICEITEMS_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getServiceItemsSaga() {
  yield takeEvery(actionTypes.SERVICEITEMS_LIST_REQUESTED, fetchServiceItems);
}

function* createNewServiceItem(action) {
  const { payload } = action;
  const requestbody = {
    serviceCode: payload.serviceCode,
    serviceDesc: payload.serviceDesc,
    unitPrice: parseInt(payload.unitPrice),
    stagePassthrough: payload.stagePassthrough
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/service-items`,
      "",
      requestbody
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_NEW_SERVICEITEMS_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_SERVICEITEMS_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewServiceItemSaga() {
  yield takeEvery(actionTypes.ADD_NEW_SERVICEITEMS_REQUESTED, createNewServiceItem);
}

function* editServiceItem(action) {
  const { payload } = action;
  const body = {
    serviceCode: payload.serviceCode,
    serviceDesc: payload.serviceDesc,
    unitPrice: payload.unitPrice,
    stagePassthrough: payload.stagePassthrough
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}/service-items/${payload.serviceId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_SERVICEITEMS_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_SERVICEITEMS_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editServiceItemSaga() {
  yield takeEvery(actionTypes.EDIT_SERVICEITEMS_REQUESTED, editServiceItem);
}

export default function* serviceItemSaga() {
  yield all([getServiceItemsSaga(), addNewServiceItemSaga(), editServiceItemSaga()]);
}
