import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchRelativeValue() {
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/rvs`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.RELATIVEVALUE_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.RELATIVEVALUE_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getRelativeValueSaga() {
  yield takeEvery(actionTypes.RELATIVEVALUE_LIST_REQUESTED, fetchRelativeValue);
}

function* createNewRelativeValue(action) {
  const { payload } = action;
  const requestbody = {
    program: payload.program,
    relativeValueNumber: payload.relativeValueNumber,
    type: payload.type,
    donor: payload.donor,
    effectiveDate: payload.effectiveDate,
    endDate: payload.endDate,
    cycleNumber: payload.cycleNumber
  };
  try {
    const requestBody = createRequestBody("post", `/practices/rvs`, "", requestbody);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_NEW_RELATIVEVALUE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_RELATIVEVALUE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewRelativeValueSaga() {
  yield takeEvery(actionTypes.ADD_NEW_RELATIVEVALUE_REQUESTED, createNewRelativeValue);
}

function* editRelativeValue(action) {
  const { payload } = action;
  const body = {
    program: payload.program,
    relativeValueNumber: payload.relativeValueNumber,
    type: payload.type,
    donor: payload.donor,
    effectiveDate: payload.effectiveDate,
    endDate: payload.endDate,
    cycleNumber: payload.cycleNumber
  };
  try {
    const requestBody = createRequestBody("put", `/practices/rvs/${payload.relativeId}`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_RELATIVEVALUE_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_RELATIVEVALUE_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editRelativeValueSaga() {
  yield takeEvery(actionTypes.EDIT_RELATIVEVALUE_REQUESTED, editRelativeValue);
}

function* deleteRelativeValue(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody("delete", `/practices/rvs/${payload.relativeId}`, "", "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.DELETE_RELATIVEVALUE_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.DELETE_RELATIVEVALUE_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* deleteRelativeValueSaga() {
  yield takeEvery(actionTypes.DELETE_RELATIVEVALUE_REQUESTED, deleteRelativeValue);
}

function* fetchMetaData() {
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/metadata`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.METADATA_RELATIVE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.METADATA_RELATIVE_FAIL, errorMsg: error.message });
    }
  }
}

function* getMetaData() {
  yield takeEvery(actionTypes.METADATA_RELATIVE_REQUESTED, fetchMetaData);
}

function* fetchRelativeType() {
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/treatmentType`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.RELATIVE_TYPE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.RELATIVE_TYPE_FAIL,
        message: error.message
      });
    }
  }
}

function* getRelativeTypeSaga() {
  yield takeEvery(actionTypes.RELATIVE_TYPE_REQUESTED, fetchRelativeType);
}

export default function* relativeValueSaga() {
  yield all([
    getRelativeValueSaga(),
    addNewRelativeValueSaga(),
    editRelativeValueSaga(),
    deleteRelativeValueSaga(),
    getRelativeTypeSaga(),
    getMetaData()
  ]);
}
