import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import moment from "moment";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

const personid = sessionStorage.getItem("person_id");

function* fetchPartnerList(action) {
  const { payload } = action;

  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/person/${payload.personId}`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PARTNER_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.PARTNER_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getPartnerSaga() {
  yield takeEvery(actionTypes.PARTNER_LIST_REQUESTED, fetchPartnerList);
}

function* postPartner(action) {
  const { payload } = action;
  const practiceId = sessionStorage.getItem("practiceId");
  const body = {
    personId: payload.personId || personid,
    firstName: payload.firstName || "",
    middleName: "",
    lastName: payload.lastName || "",
    mailingAddrLine1: payload.addressType == "mailing" ? payload.addressLine1 : "",
    mailingAddrLine2: payload.addressType == "mailing" ? payload.addressLine2 : "",
    mailingCity: payload.addressType == "mailing" ? payload.city : "",
    mailingState: payload.addressType == "mailing" ? payload.state : "",
    mailingZip: payload.addressType == "mailing" ? payload.zip : "",
    mailingCountry: payload.addressType == "mailing" ? payload.country : "",
    billingAddrLine1: payload.addressType == "billing" ? payload.addressLine1 : "",
    billingAddrLine2: payload.addressType == "billing" ? payload.addressLine2 : "",
    billingCity: payload.addressType == "billing" ? payload.city : "",
    billingState: payload.addressType == "billing" ? payload.state : "",
    billingZip: payload.addressType == "billing" ? payload.zip : "",
    billingCountry: payload.addressType == "billing" ? payload.country : "",
    commPreference: payload.phoneType || "",
    cellPhone: payload.phoneType == "cell" ? payload.phoneNumber : "",
    homePhone: payload.phoneType == "home" ? payload.phoneNumber : "",
    workPhone: payload.phoneType == "work" ? payload.phoneNumber : "",
    additionalPhone: "",
    gender: "",
    birthDate: moment(payload.dob).format("L"),
    email: payload.email || "",
    personKey: payload.personKey,
    practiceId: payload.practiceId || practiceId,
    maritalStatus: "M",
    defaultAddress: payload.addressType || null,
    defaultPhone: payload.phoneType || null,
    activePartner: payload.idData
  };

  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/persons/${personid}/partners`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_NEW_PARTNER_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.ADD_NEW_PARTNER_FAILED, errorMsg: error.message });
    }
  }
}

function* postPartnerSaga() {
  yield takeEvery(actionTypes.ADD_NEW_PARTNER_REQUESTED, postPartner);
}

function* editPartner(action) {
  const { payload } = action;
  const body = {
    patient: {
      ...payload.participantList,
      activePartner: {
        patientId: payload.value.patientId || personid,
        partnerId: sessionStorage.getItem("partnerId")
      }
    },
    partner: {
      personId: payload.value.personId || personid,
      firstName: payload.value.firstName,
      middleName: "",
      lastName: payload.value.lastName,
      mailingAddrLine1: payload.value.mailingAddrLine1,
      mailingAddrLine2: payload.value.mailingAddrLine2,
      mailingCity: payload.value.mailingCity,
      mailingState: payload.value["mailingStateCode"],
      mailingZip: payload.value.mailingZip,
      mailingCountry: payload.value.type == "mailing" ? payload.value.mailingCountry : "",
      billingAddrLine1: payload.value.billingAddrLine1,
      billingAddrLine2: payload.value.billingAddrLine2,
      billingCity: payload.value.billingCity,
      billingState: payload.value["billingStateCode"],
      billingZip: payload.value.billingZip,
      billingCountry: payload.value.type == "billing" ? payload.value.country : "",
      commPreference: payload.value.phoneType || "",
      cellPhone: payload.value.type == "Cell" ? payload.value.number : payload.value.cellPhone,
      homePhone: payload.value.type == "Home" ? payload.value.number : payload.value.homePhone,
      workPhone: payload.value.type == "Work" ? payload.value.number : payload.value.workPhone,
      additionalPhone:
        payload.value.type == "Additional" ? payload.value.number : payload.value.additionalPhone,
      email: payload.value.email || "",
      personKey: payload.value.personKey,
      practiceId: payload.practiceId || "",
      defaultAddress: payload.defaultAddress,
      defaultPhone: payload.defaultType,
      activePartner: payload.value.activePartner
    }
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}/patientPartners/${payload.value.personId || personid}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_PARTNER_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_PARTNER_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editPartnerSaga() {
  yield takeEvery(actionTypes.EDIT_PARTNER_REQUESTED, editPartner);
}

export default function* partnerSaga() {
  yield all([getPartnerSaga(), postPartnerSaga(), editPartnerSaga()]);
}
