import * as actionType from "../actions/ActionTypes";

const initialState = {
  phoneData: [],
  emailData: [],
  phoneLoading: false,
  emailLoading: false,
  error: null,
  phoneSuccess: false,
  emailSuccess: false
};

const phoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CONTACT_PHONE_DATA_REQUESTED: {
      return {
        ...state,
        phoneLoading: true
      };
    }
    case actionType.CONTACT_PHONE_DATA_SUCCESS: {
      return {
        ...state,
        phoneData: action.payload,
        phoneLoading: false,
        phoneSuccess: true
      };
    }
    case actionType.CONTACT_PHONE_DATA_FAIL: {
      return {
        ...state,
        error: action.message,
        phoneLoading: false
      };
    }
    case actionType.CONTACT_EMAIL_DATA_REQUESTED: {
      return {
        ...state,
        emailLoading: true
      };
    }
    case actionType.CONTACT_EMAIL_DATA_SUCCESS: {
      return {
        ...state,
        emailData: action.payload,
        emailLoading: false,
        emailSuccess: true
      };
    }
    case actionType.CONTACT_EMAIL_DATA_FAIL: {
      return {
        ...state,
        error: action.message,
        emailLoading: false
      };
    }

    case actionType.RESET_PHONE_DATA: {
      return {
        ...state,
        phoneData: [],
        emailData: [],
        phoneLoading: false,
        emailLoading: false,
        phoneSuccess: false,
        emailSuccess: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default phoneReducer;
