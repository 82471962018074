import React, { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Button,
  Typography,
  Divider,
  Modal,
  Stack,
  TextField,
  InputLabel,
  Grid
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Close } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
const SendAuthorizationModal = (props) => {
  const [values, setValues] = useState();
  const [isConfirmation, setConfirmationOpen] = useState(false);

  useEffect(() => {
    const authExpDate = new Date();
    const thedaysAdded = authExpDate.getDate() + 45;
    authExpDate.setDate(thedaysAdded);
    setValues({
      authorizationDate: new Date(),
      authorizationExpiaryDateDate: authExpDate
    });
  }, []);

  const handleChangeAuthDate = (newValue) => {
    const authExpDate = new Date(newValue);
    const thedaysAdded = authExpDate.getDate() + 45;
    authExpDate.setDate(thedaysAdded);
    setValues({
      authorizationDate: new Date(newValue),
      authorizationExpiaryDateDate: authExpDate
    });
  };

  const handleSave = () => {
    setConfirmationOpen(true);
  };
  const saveConfirmation = () => {
    props.SaveAuthorization({
      authorizationDate: moment(values.authorizationDate).format("YYYY-MM-DD"),
      clinicalTreatmentId: props.data.clinicalTreatmentId
    });
  };
  const closeConfirmation = () => {
    setConfirmationOpen(false);
  };

  return (
    <div>
      <Modal
        open={props.isOpen && props.isOpen}
        backdrop="static"
        aria-labelledby="modal-add-title">
        <>
          <Box
            sx={{ display: isConfirmation == true ? "none" : "" }}
            component="form"
            className="modal-style">
            <Stack direction="row" className="modal-stack">
              <Typography>
                <b>Send Authorization</b>
              </Typography>
              <IconButton onClick={props.closeSendAuth}>
                <Close />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Stack direction="row" className="modal-stack">
              <Grid item xs={6}>
                <InputLabel className="flex-2">Authorizing cycle</InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {props?.data?.clinicalTreatmentNo}{" "}
                  {props?.data?.clinicalTreatmentNo?.toString().split(".")[1] > 0
                    ? "(FET)"
                    : "(IVF)"}
                </Typography>
              </Grid>
            </Stack>

            <Stack direction="row" className="modal-stack">
              <Grid item xs={6}>
                <InputLabel>Authorization Date</InputLabel>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    openTo="day"
                    views={["day"]}
                    name="authorizationDate"
                    value={values?.authorizationDate == null ? null : values?.authorizationDate}
                    onChange={handleChangeAuthDate}
                    renderInput={(params) => (
                      <TextField {...params} size="small" sx={{ flex: 8 }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Stack>
            <Stack direction="row" className="modal-stack">
              <Grid item xs={6}>
                <InputLabel>Authorization Expiry date</InputLabel>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    openTo="day"
                    disabled
                    views={["day"]}
                    name="authorizationExpiaryDateDate"
                    value={
                      values?.authorizationExpiaryDateDate
                        ? values?.authorizationExpiaryDateDate
                        : null
                    }
                    onChange={handleChangeAuthDate}
                    renderInput={(params) => (
                      <TextField {...params} size="small" sx={{ flex: 8 }} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Stack>

            <Stack direction="row" spacing={2} className="modal-bottom-section">
              <Button variant="outlined" className="btn-primary" onClick={props.closeSendAuth}>
                Cancel
              </Button>
              <Button
                // type="submit"
                variant="contained"
                className="btn-usf"
                disabled={!values?.authorizationDate}
                onClick={handleSave}>
                Save
              </Button>
            </Stack>
          </Box>
          <Box
            sx={{ display: isConfirmation == true ? "" : "none" }}
            component="form"
            className="modal-style">
            <Stack direction="row" className="modal-stack">
              <Typography>
                <b>Send Authorization</b>
              </Typography>
              <IconButton onClick={props.closeSendAuth}>
                <Close />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Stack direction="row" className="modal-stack">
              <Grid item xs={12}>
                <InputLabel className="flex-2">
                  Are you sure you want to send the authorization for - (
                  {props?.data?.clinicalTreatmentNo}{" "}
                  {props?.data?.clinicalTreatmentNo?.toString().split(".")[1] > 0
                    ? "(FET)"
                    : "(IVF)"}
                  )
                </InputLabel>
              </Grid>
            </Stack>
            <Stack direction="row" spacing={2} className="modal-bottom-section">
              <Button variant="outlined" className="btn-primary" onClick={closeConfirmation}>
                Cancel
              </Button>
              <Button
                //type="submit"
                variant="contained"
                className="btn-usf"
                onClick={saveConfirmation}>
                Save
              </Button>
            </Stack>
          </Box>
        </>
      </Modal>
    </div>
  );
};
export default SendAuthorizationModal;
