import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchLab() {
  try {
    const params = {
      offset: "0",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/labs`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PGTA_LABS_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.PGTA_LABS_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getLabSaga() {
  yield takeEvery(actionTypes.PGTA_LABS_LIST_REQUESTED, fetchLab);
}

function* createNewLab(action) {
  const { payload } = action;
  const requestbody = {
    labName: payload.labName,
    labAddress: payload.labAddress,
    city: payload.city,
    zip: payload.zip,
    state: payload.state,
    labPhone: payload.labPhone,
    labEmail: payload.labEmail,
    contactFName: payload.contactFName,
    contactLName: payload.contactLName,
    isActive: payload.isActive
  };
  try {
    const requestBody = createRequestBody("post", `/labs`, "", requestbody);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_PGTA_LABS_SUCCESS,
      payload: response.data,
      successMsg: "New lab information added successfully"
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_PGTA_LABS_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addLabSaga() {
  yield takeEvery(actionTypes.ADD_PGTA_LABS_REQUESTED, createNewLab);
}

function* editLab(action) {
  const { payload } = action;
  const body = {
    labName: payload.labName,
    labAddress: payload.labAddress,
    city: payload.city,
    zip: payload.zip,
    state: payload.state,
    labPhone: payload.labPhone,
    labEmail: payload.labEmail,
    contactFName: payload.contactFName,
    contactLName: payload.contactLName,
    isActive: payload.isActive
  };
  try {
    const requestBody = createRequestBody("put", `/labs/${payload.labId}`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_PGTA_LABS_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_PGTA_LABS_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editLabSaga() {
  yield takeEvery(actionTypes.EDIT_PGTA_LABS_REQUESTED, editLab);
}

export default function* relativeValueSaga() {
  yield all([getLabSaga(), addLabSaga(), editLabSaga()]);
}
