import moment from "moment-timezone";
// import _ from "lodash";

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone || "America/New_York";
};

export const getFormattedDate = (date, format, isUTC = false) => {
  let nDate = date ? (isUTC ? moment.utc(date) : moment(date)) : moment();
  let result = nDate.tz(getTimeZone()).format(format);
  return result;
};

export const getLocaleDateTime = (date, format, isUTC = false) => {
  let nDate = date
    ? isUTC
      ? moment.utc(date)
      : moment.tz(
          moment(date).format("YYYY-MM-DD HH:mm:ss"),
          "YYYY-MM-DD HH:mm:ss",
          "America/New_York"
        )
    : moment();

  return nDate.tz(getTimeZone()).format(format);
};

export const priceFormat = (amount) => {
  let value = amount || 0;
  value = (Math.round(value * 100) / 100).toFixed(2);

  return Number(value).toLocaleString("en", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};

export const priceFormatToNumber = (price) => {
  let str = price ? String(price) : "";
  str = str.replaceAll("$", "").replaceAll(",", "");

  return str ? Number(str) : 0;
};
