import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, InputLabel, MenuItem, Select } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";
import * as actionType from "../../actions";
import { connect } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};
const TreatmentPlanModal = (props) => {
  console.log("zhr props", props);
  const [modalHeader, setModalHeader] = useState("");
  const [btnText, setCreateBtn] = useState("");
  const [selectedData, setSearched] = useState("");
  const [searchedStage, setSearchedStage] = useState(false);
  const [checkedData, setCheckedData] = useState([]);
  const [stageOptions, setStageOptions] = useState([]);
  const [checkedQuestionResponse, setCheckedQuestionResponse] = useState("");
  useEffect(() => {
    setSearchedStage(false);
    if (props) {
      if (props.modalType === "addQuestion") {
        setModalHeader();
        setCreateBtn("Add");
      } else if (props.modalType === "addSubQuestion") {
        setModalHeader(props.open.question);
        setCreateBtn("Add");
      } else {
        setModalHeader(props.modalType);
        setCreateBtn("Add");
        initStageOptions(props.data);
      }
    }
  }, [props]);
  const handleCreateAddStage = () => {
    if (props.modalType == "addQuestion" || props.modalType == "addSubQuestion") {
      let payload = {};
      payload.treatmentPlanId = props.otherData.treatmentPlanId;
      payload.practiceId = props.otherData.practiceId;
      payload.gdmId = props.otherData.gdmId;
      payload.treatmentData = checkedData;
      payload.questionId = checkedData.questionId;
      payload.refData = props.otherData;
      payload.responseNo = checkedQuestionResponse;
      setCheckedQuestionResponse("");
      setCheckedData([]);
      // props.actions.addQuestionTreatmentPlan(payload);
      props.storeTreatmentPlanQuestionsRes(payload);
      let closeModal = {
        open: false,
        type: "question"
      };
      props.setModalOpen(closeModal);
    } else if (props.open.type == "Question") {
      let payload = {
        treatmentPlanId: props.otherData.treatmentPlanId,
        practiceId: props.otherData.practiceId,
        gdmId: props.otherData.gdmId,
        questionId: checkedData.questionId,
        responseNo: checkedQuestionResponse
      };
      setCheckedQuestionResponse("");
      setCheckedData([]);
      props.actions.addQuestionTreatmentPlan(payload);
      let closeModal = {
        open: false,
        type: "question"
      };
      props.setModalOpen(closeModal);
    } else {
      let payload = {};
      payload.treatmentPlanId = props.otherData.treatmentPlanId;
      payload.practiceId = props.otherData.practiceId;
      payload.gdmId = props.otherData.gdmId;
      payload.stageData = selectedData;
      payload.category = props.category;
      payload.questionId = checkedData.questionId;
      setCheckedQuestionResponse("");
      setCheckedData([]);
      if (selectedData.length > 0) props.createStages(payload);
      //props.actions.addQuestionTreatmentPlan(payload);
      // let closeModal = {
      //   open: false,
      //   type: props.category
      // };
      // props.setModalOpen(closeModal);
    }
  };

  const initStageOptions = (options) => {
    let res;
    if (props.category === "Included") {
      res = options.filter((st) => st.stageCategory == "Included");
    } else if (props.category === "Necessary" || props.category === "Optional") {
      res = options.filter((st) => st.stageCategory != "Included");
    }

    setStageOptions(res);
  };

  const handleStageFilter = (options, { inputValue }) => {
    return (
      options &&
      options.filter(
        (st) =>
          st.stageCode.toString().toLowerCase().includes(inputValue.toString().toLowerCase()) ||
          st.stageName.toString().toLowerCase().includes(inputValue.toString().toLowerCase())
      )
    );
  };

  const handleSearch = (e, value, reason) => {
    setSearchedStage(true);

    if (["Included", "Necessary", "Optional"].indexOf(props.category) !== -1) {
      let data = stageOptions.filter((st) => {
        return st.stageCode.toString().toLowerCase() == e.target.textContent.toLowerCase();
      });
      setSearched(data.length > 0 ? data : []);
    } else if (reason === "clear") {
      confirm("Are you sure to clear?");
    } else {
      let data = props.data.filter((st) => {
        return st.questionText.toString().toLowerCase() == e.target.textContent.toLowerCase();
      });
      setSearched(data.length > 0 ? data[0].questionText : "");
      setCheckedData(data[0]);
    }
  };
  const defaultProps = {
    options: props.data ? props.data : [],
    getOptionLabel: (option) => (option.questionText ? option.questionText : "")
  };
  const stageProps = {
    options: stageOptions && stageOptions.length ? stageOptions : [],
    getOptionLabel: (option) => (option.stageCode ? option.stageCode : "")
  };
  return (
    <div>
      <Modal
        open={props.open.open}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box sx={style}>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography id="modal-new-practice-payment" color="text.primary">
              Add {props.open.type} ({props.open.category}
              {modalHeader})
            </Typography>
            <IconButton
              onClick={() => {
                props.closeModal();
              }}>
              <Close color="primary" />
            </IconButton>
          </Stack>
          <Divider sx={{ color: "primary" }} orientation="horizontal" />
          <Stack direction="row" spacing={2} sx={{ my: 2, alignItems: "center" }}>
            <Typography> {props.open.type} </Typography>
            <Paper
              component="form"
              sx={{ p: "2px 4px", display: "flex", alignItems: "center", flex: 2 }}>
              {props.open.type == "Stages" ? (
                <>
                  <Autocomplete
                    {...stageProps}
                    sx={{ flex: 5, width: 300 }}
                    disablePortal
                    filterOptions={handleStageFilter}
                    size="small"
                    name="Search Stage"
                    defaultValue={selectedData}
                    onChange={(e) => handleSearch(e)}
                    popupIcon={<SearchIcon />}
                    renderOption={(prop, option) => {
                      return (
                        <li {...prop} key={option.stageId}>
                          {option.stageCode}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Stages by Name or Code"
                        variant="outlined"
                      />
                    )}
                  />
                </>
              ) : (
                <>
                  <Autocomplete
                    {...defaultProps}
                    sx={{ flex: 5, width: 300 }}
                    disablePortal
                    size="small"
                    name="Search Saga"
                    defaultValue={selectedData}
                    onChange={(e) => handleSearch(e)}
                    popupIcon={<SearchIcon />}
                    renderInput={(params) => <TextField {...params} variant="outlined" />}
                  />
                  <Stack width={250}>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      onChange={(e) => setCheckedQuestionResponse(e.target.value)}>
                      {checkedData && checkedData.responses && checkedData.responses.length > 0
                        ? checkedData.responses.map((item) => (
                            <MenuItem key={item.responseNo} value={item.responseNo}>
                              {item.responsetext}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </Stack>
                </>
              )}
            </Paper>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 2, alignItems: "right", justifyContent: "flex-end" }}>
            {!selectedData.length > 0 && searchedStage && (
              <InputLabel error className="flex-1">
                No Stages Found
              </InputLabel>
            )}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              onClick={() => {
                setCheckedQuestionResponse("");
                setCheckedData([]);
                props.closeModal();
              }}
              sx={{ border: "2px solid primary" }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleCreateAddStage}
              disabled={!selectedData.length > 0}
              sx={{
                background: "primary",
                "&:hover": {
                  color: "primary"
                }
              }}>
              {btnText}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = ({ stageDetailReducer, stageReducer }) => {
  return {
    editStage: stageReducer.editStage,
    stageDetailList: stageDetailReducer.stageDetailList,
    addStageToServiceItem: stageDetailReducer.addStageToServiceItem,
    editStageToServiceItem: stageDetailReducer.editStageToServiceItem,
    deleteStageToServiceItem: stageDetailReducer.deleteStageToServiceItem,
    successMsg: stageDetailReducer.success,
    errorMsg: stageDetailReducer.error,
    loading: stageDetailReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    addQuestionTreatmentPlan: (payload) => {
      dispatch(actionType.addQuestionTreatmentPlan(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlanModal);
