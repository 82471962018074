import React, { useState, useEffect } from "react";
import * as actionType from "../actions";
import { Box, Stack, TextField, Grid, Typography, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { connect } from "react-redux";
import moment from "moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
// import { priceFormat } from "../../shared/common";
import { GRID_AGGREGATION_FUNCTIONS } from "@mui/x-data-grid-premium";

const MedcaseRevenue605 = (props) => {
  const formData = {
    from: null,
    to: null
  };
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [values, setValues] = useState(formData);
  const { medcaseRevenue605List = [], actions, loading } = props;
  const [csvData, setCsvData] = useState([]);
  // const [aggregationModel, setAggregationModel] = React.useState({
  //   totalRevenue: "sum"
  // });

  const callAPI = () => {
    actions.getMedcaseRevenue605({
      startDate: values.from,
      reportType: "Medcase Revenue 605",
      endDate: values.to
    });
  };

  useEffect(() => {
    if (medcaseRevenue605List.length > 0) {
      const rows = medcaseRevenue605List.map((item, index) => {
        item.id = index;
        return item;
      });

      const csvDataMode = prepareCsvData(rows);
      console.log("csvDataMode: ", csvDataMode);
      setCsvData(csvDataMode);
    }
  }, [medcaseRevenue605List]);

  // const filteredRows = rows.filter((row) => {
  //   if (row.amount >= 0) {
  //     return row;
  //   }
  // });
  useEffect(() => {
    return actions.resetMessage();
  }, []);
  function CustomToolbar() {
    return (
      <Grid container className="align-center">
        <Grid item xs={0.75}>
          <Typography style={{ marginLeft: "10px" }}>Start Date*</Typography>
        </Grid>
        <Grid item xs={2.25}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              openTo="day"
              views={["day", "year", "month"]}
              disableFuture
              value={values.from}
              onChange={(newValue) => {
                const format2 = "YYYY-MM-DD";
                newValue = moment(newValue).format(format2);
                setValues({ ...values, from: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" className="patient-dropdown" fullWidth />
              )}></DatePicker>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={0.25}></Grid>
        <Grid item xs={0.75}>
          <Typography style={{ marginLeft: "10px" }}>End Date*</Typography>
        </Grid>
        <Grid item xs={2.25}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              openTo="day"
              views={["day", "year", "month"]}
              disableFuture
              value={values.to}
              onChange={(newValue) => {
                const format2 = "YYYY-MM-DD";
                newValue = moment(newValue).format(format2);
                setValues({ ...values, to: newValue });
              }}
              renderInput={(params) => (
                <TextField {...params} size="small" className="patient-dropdown" fullWidth />
              )}></DatePicker>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={2.75}></Grid>
        <Grid item xs={1.5}>
          <Button variant="contained" className="btn-usf" onClick={callAPI}>
            Run Report
          </Button>
        </Grid>
      </Grid>
    );
  }

  const formateCommasepratedNumber = (actualAmount) => {
    let amt = new Intl.NumberFormat().format(actualAmount);
    let convertedAmt = amt.toLocaleString("en-US");
    return convertedAmt;
  };

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      transactionDate: row?.transactionDate ? moment(row.transactionDate).format("MM/DD/YYYY") : "",
      enrollmentDate: row?.enrollmentDate ? moment(row.enrollmentDate).format("MM/DD/YYYY") : "",
      totalRevenue: row?.totalRevenue ? parseFloat(row.totalRevenue) : 0,
      serviceRevenue: row?.serviceRevenue ? parseFloat(row.serviceRevenue) : 0,
      completionRevenue: row?.completionRevenue ? parseFloat(row.completionRevenue) : 0,
      successRevenue: row?.successRevenue ? parseFloat(row.successRevenue) : 0
    }));
  };

  // const currencyFormatter = new Intl.NumberFormat("en-US", {
  //   style: "currency",
  //   currency: "USD",
  //   minimumFractionDigits: 0,
  //   maximumFractionDigits: 0
  // });

  const columns = [
    {
      field: "transactionDate",
      headerName: "TRANSACTION DATE",
      width: 150,
      editable: false,
      availableAggregationFunctions: ["heading"]
    },
    {
      field: "patientName",
      headerName: "Patient Name",
      width: 130,
      editable: false
    },
    {
      field: "medCaseId",
      headerName: "MEDCASE ID",
      width: 130,
      editable: false
    },
    {
      field: "practiceName",
      headerName: "Practice",
      width: 130,
      editable: false
    },
    {
      field: "progShortName",
      headerName: "PROGRAM SHORT NAME",
      width: 130,
      editable: false
    },
    {
      field: "treatmentPlan",
      headerName: "Treatment Plan",
      width: 130,
      editable: false,
      renderCell: ({ row }) =>
        row?.treatmentPlanCustomName != null ? row?.treatmentPlanCustomName : row?.treatmentPlan
    },
    {
      field: "enrollmentDate",
      headerName: "Enrollment Date",
      width: 150,
      editable: false
    },
    {
      field: "serviceRevenue",
      headerName: "Service Revenue",
      width: 130,
      editable: false,
      availableAggregationFunctions: ["totalSum"],
      valueParser: (e) => Number(e),
      valueFormatter: (e) => formateCommasepratedNumber(e),
      type: "number"
    },
    {
      field: "completionRevenue",
      headerName: "Completion Revenue",
      width: 130,
      editable: false,
      availableAggregationFunctions: ["totalSum"],
      valueParser: (e) => Number(e),
      valueFormatter: (e) => formateCommasepratedNumber(e),
      type: "number"
    },
    {
      field: "successRevenue",
      headerName: "Success Revenue",
      width: 130,
      editable: false,
      availableAggregationFunctions: ["totalSum"],
      valueParser: (e) => Number(e),
      valueFormatter: (e) => formateCommasepratedNumber(e),
      type: "number"
    },
    {
      field: "totalRevenue",
      headerName: "Total Revenue",
      type: "number",
      width: 130,
      editable: false,
      availableAggregationFunctions: ["totalSum"],
      valueFormatter: (e) => formateCommasepratedNumber(e),
      valueParser: (e) => Number(e)
      // valueFormatter: (e) => "$" + (e || "0.00"),
      // renderCell: ({ row }) => "$" + (row.totalRevenue || "0.00")

      //generateData: () => Ia(1e3, 1e5)
    }
    // {
    //   field: "transactionType",
    //   headerName: "TRANSACTION DESC",
    //   width: 390,
    //   editable: false
    // },
    // {
    //   field: "amount",
    //   headerName: "TRANSACTION AMOUNT",
    //   width: 290,
    //   editable: false,
    //   renderCell: ({ row }) => "$" + (priceFormat(row.amount) || "0.00")
    // }
  ];

  const totalSumAggregation = {
    apply: (params) => {
      if (params.values.length === 0) {
        return null;
      }
      let sum = 0;

      params.values.forEach((value) => {
        // let cur = value.split("$");
        // console.log("cur: ", cur);
        // console.log("cur1", cur[0]);
        // console.log("cur2", cur[1]);
        sum += Number(value);
        //sum += Number(cur[1].replace(/,/g, ""));
      });

      let updatedValu = formateCommasepratedNumber(sum);

      return updatedValu;
    },
    label: "",
    valueFormatter: (value) => `${value}`,
    columnTypes: ["number"]
  };

  const aggregationHeading = {
    apply: () => {},
    label: "",
    valueFormatter: () => `Total: `,
    columnTypes: ["string"]
  };

  return (
    <>
      <Stack direction="row" className="pad-5 m-t-8"></Stack>
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={csvData}
            rowCount={csvData.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            localeText={"Export Button"}
            components={<CustomToolbar />}
            isExport={true}
            aggregationFunctions={{
              ...GRID_AGGREGATION_FUNCTIONS,
              totalSum: totalSumAggregation,
              heading: aggregationHeading
            }}
            initialState={{
              aggregation: {
                model: {
                  transactionDate: "heading",
                  totalRevenue: "totalSum",
                  successRevenue: "totalSum",
                  completionRevenue: "totalSum",
                  serviceRevenue: "totalSum"
                }
              }
            }}
            headerText={`Medcase Revenue 605 Start Date: ${values.from} End Date: ${values.to}`}
            disableRowSelectionOnClick={true}
            customAggModel={{
              transactionDate: "heading",
              totalRevenue: "totalSum",
              successRevenue: "totalSum",
              completionRevenue: "totalSum",
              serviceRevenue: "totalSum"
            }}
          />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({ medcaseRevenue605Reducer }) => {
  return {
    loading: medcaseRevenue605Reducer.loading,
    medcaseRevenue605List: medcaseRevenue605Reducer.medcaseRevenue605List,
    errorMsg: medcaseRevenue605Reducer.error,
    successMsg: medcaseRevenue605Reducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMedcaseRevenue605: (payload) => {
      dispatch(actionType.getMedcaseRevenue605(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MedcaseRevenue605);
