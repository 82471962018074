import React from "react";
//import { Box, Typography, Stack, Select, MenuItem, Grid, CircularProgress } from "@mui/material";
import "../../admin/components/Admin.scss";
//import { useNavigate } from "react-router-dom";
//import { connect } from "react-redux";
//import * as actionType from "../../shared/actions";
export default function NotFound() {
  //const navigate = useNavigate();
  //const [pId, setPid] = useState([]);
  //const [practices, setPractices] = useState([]);
  //const { profileDetails, loading, actions } = props;
  // useEffect(() => {
  //   if (!loading) {
  //     setPractices(profileDetails["practiceList"]);
  //   }
  // }, [profileDetails]);

  // const handlePracticeChange = (e) => {
  //   localStorage.setItem("practiceId", e.target.value);
  //   setPid(e.target.value);
  //   navigate(`/workspace/financial-estimates`);
  //   actions.enablePracticeNavFlag();
  // };
  //console.log("practices[0]", practices);
  return (
    <></>
    // {loading &&
    //     <Stack justifyContent="center" p={5} alignItems="center">
    //       <CircularProgress size={50} />
    //     </Stack>
    // <Box className="stage-details-section m-10">
    //   <Stack direction="row">
    //     <Grid container sx={{ justifyContent: "center", alignItems: "center" }}>
    //       <Grid item xs={2}>
    //         <Typography>Practice</Typography>
    //       </Grid>
    //       <Grid item xs={4}>
    //         <Select
    //           fullWidth
    //           name="pid"
    //           id="pid"
    //           value={practices ? practices?.[0]?.["practiceId"] : pId}
    //           onChange={(e) => handlePracticeChange(e)}
    //           className="stage-practice-dropdown">
    //           {practices?.map((item, index) => {
    //             return (
    //               <MenuItem value={item.practiceId} key={index}>
    //                 {item.practiceName} -{item.practiceId}
    //               </MenuItem>
    //             );
    //           })}
    //         </Select>
    //       </Grid>
    //     </Grid>
    //   </Stack>
    // </Box>
  );
}
// const mapStateToProps = ({}) => {
//   return {
//     // profileDetails: profileDrawerReducer.profileDetails,
//     // loading: profileDrawerReducer.loading
//   };
// };

// const mapDispatchToProps = () => ({
//   actions: {
//     // enablePracticeNavFlag: () => {
//     //   dispatch(actionType.enablePracticeNavFlag());
//     //}
//   }
// });

// export default connect(mapStateToProps, mapDispatchToProps)(NotFound);
