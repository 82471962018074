import * as actionType from "../actions/ActionTypes";

const initialState = {
  adjustments: null,
  updateAdjustment: null,
  adjustmentList: [],
  stages: [],
  necessary: [],
  optional: [],
  loading: false,
  error: null,
  success: null,
  adjustmentLoading: false,
  necessaryLoading: false,
  stageLoading: false
};

const costCoverageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ESTIMATE_STAGE_LIST_REQUESTED: {
      return {
        ...state,
        stageLoading: true
      };
    }
    case actionType.ESTIMATE_STAGE_LIST_SUCCESS: {
      return {
        ...state,
        stages: action.payload,
        stageLoading: false
      };
    }
    case actionType.ESTIMATE_STAGE_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        stageLoading: false
      };
    }

    case actionType.NECESSARY_LIST_REQUESTED: {
      return {
        ...state,
        necessaryLoading: true
      };
    }
    case actionType.NECESSARY_LIST_SUCCESS: {
      return {
        ...state,
        necessary: action.payload,
        necessaryLoading: false
      };
    }
    case actionType.NECESSARY_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        necessaryLoading: false
      };
    }

    case actionType.OPTIONAL_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.OPTIONAL_LIST_SUCCESS: {
      return {
        ...state,
        optional: action.payload,
        loading: false
      };
    }
    case actionType.OPTIONAL_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.ADD_ADJUSTMENTS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_ADJUSTMENTS_SUCCESS: {
      return {
        ...state,
        adjustments: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_ADJUSTMENTS_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.GET_ADJUSTMENTS_REQUESTED: {
      return {
        ...state,
        adjustmentLoading: true
      };
    }
    case actionType.GET_ADJUSTMENTS_SUCCESS: {
      return {
        ...state,
        adjustmentList: action.payload,
        adjustmentLoading: false
      };
    }
    case actionType.GET_ADJUSTMENTS_FAILED: {
      return {
        ...state,
        error: action.message,
        adjustmentLoading: false
      };
    }

    case actionType.UPDATE_ADJUSTMENTS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.UPDATE_ADJUSTMENTS_SUCCESS: {
      return {
        ...state,
        updateAdjustment: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.UPDATE_ADJUSTMENTS_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_COST_COVERAGE_MESSAGE: {
      return {
        ...state,
        // adjustments: null,
        stages: [],
        necessary: [],
        optional: [],
        error: false,
        success: false
      };
    }

    case actionType.RESET_STAGES: {
      return {
        ...state,
        stages: [],
        error: false,
        success: false
      };
    }

    case actionType.RESET_OPTIONAL: {
      return {
        ...state,
        optional: [],
        error: false,
        success: false
      };
    }

    case actionType.RESET_NECESSARY: {
      return {
        ...state,
        necessary: [],
        error: false,
        success: false
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: false,
        success: false
      };
    }

    case actionType.RESET_UPDATE_ADJUSTMENTS: {
      return {
        ...state,
        updateAdjustment: null,
        error: false,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default costCoverageReducer;
