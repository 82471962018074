import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  IconButton,
  Button,
  Typography,
  Stack,
  Grid,
  // Select,
  // MenuItem,
  Autocomplete,
  TextField
} from "@mui/material";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import DeleteModal from "../../shared/components/DeleteModal";
import { contractTemplateMappingConfig } from "../../shared/constants/renderConfigs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const CTM = (props) => {
  let contractTemplateMappingFormData = {
    templateId: "",
    practiceId: "",
    programId: "",
    effectiveDate: null,
    endDate: null,
    treatmentPlanId: "",
    partner: ""
  };

  let initialData = {};
  const practices = useSelector((state) => state.practices.practices);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [templateProgramId, setTemplateProgramId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [values, setValues] = useState("");
  const [activeGDM, setActiveGDM] = useState("");
  const [practiceId, setPracticeId] = useState(localStorage.getItem("practiceId") || "");
  const navigate = useNavigate();
  // const [practiceName, setPracticeName] = useState("");

  const columns = [
    {
      field: "templateProgramId",
      headerName: "SR NO",
      width: 100,
      flex: 1
    },
    {
      field: "programName",
      headerName: "PROGRAM",
      width: 100,
      flex: 1
    },
    {
      field: "treatmentName",
      headerName: "TREATMENT PLAN",
      width: 100,
      flex: 1
    },
    {
      field: "treatmentPlanId",
      headerName: "TREATMENT PLAN ID",
      width: 100,
      flex: 1
    },
    {
      field: "partner",
      headerName: "PARTNER",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "templateName",
      headerName: "TEMPLATE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.effectiveDate ? moment(row?.effectiveDate).format("L") : "-";
      }
    },
    {
      field: "endDate",
      headerName: "END DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row?.endDate ? moment(row?.endDate).format("L") : "-";
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.5,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            onClick={() => {
              handleEditContractTemplateMapping(row);
            }}>
            <EditIcon color="primary"></EditIcon>
          </IconButton>
          <IconButton
            onClick={() => {
              handleDeleteProgram(row);
            }}>
            <DeleteIcon color="primary"></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];

  const {
    contractTemplateMappingList = [],
    actions,
    addContreactTemplateMapping,
    editContreactTemplateMapping,
    loading,
    errorMsg,
    successMsg,
    loadingPractice,
    programsData = [],
    templateList = [],
    gdmLoading,
    gdmList = [],
    treatmentPlanList = []
  } = props;

  useEffect(() => {
    if (practices.length > 0) {
      let payload = {};
      if (!practiceId) {
        setPracticeId(practices[0].practiceId);
        localStorage.setItem("practiceId", practices[0].practiceId);
        actions.getGlobalData(practices[0].practiceId);

        payload.practiceId = practices[0].practiceId;
      }
      if (practiceId) {
        // payload.practiceId = practiceId == "" ? practices[0].practiceId : practiceId;
        payload.practiceId = practiceId;
        actions.getGlobalData(practiceId);
      }
      actions.getCTM(payload);
    }
  }, [practices, loadingPractice, addContreactTemplateMapping, editContreactTemplateMapping]);

  useEffect(() => {
    if (gdmList?.length > 0) {
      let currentDate = moment().format("YYYY-MM-DD");

      let activeGDM = gdmList?.filter(
        (pd) =>
          pd.status === "Active" && moment(currentDate).isBetween(pd.effectiveDate, pd.endDate)
      );

      let gdmId = activeGDM && activeGDM[0] ? activeGDM[0].gdmId : null;
      setActiveGDM(gdmId);
      let payload = {
        practiceId: practiceId,
        gdmId: gdmId
      };
      actions.getTreatmentPlan(payload);
    }
  }, [gdmList, gdmLoading]);

  const handlePracticeChange = (value) => {
    if (value) {
      setPracticeId(value?.value);
      localStorage.setItem("practiceId", value?.value);

      let payload = {};
      payload.practiceId = value?.value;
      // let selectedPractice = practices.filter((pr) => pr.practiceId == value?.value);
      // setPracticeName(selectedPractice[0].name);
      actions.getCTM(payload);
      actions.getGlobalData(value?.value);
    }
  };

  const initFetch = useCallback(() => {
    if (!practices.length) {
      actions.getPractices();
    }
    if (practiceId) {
      let payload = { practiceId };
      actions.getCTM(payload);
    }
  }, [practiceId, addContreactTemplateMapping, editContreactTemplateMapping]);

  useEffect(() => {
    actions.getCTM();
    actions.getTemplate();
    if (practiceId != "") {
      actions.getProgramsdata(practiceId);
    }
  }, [practiceId]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (
      contractTemplateMappingList.length == 0 ||
      addContreactTemplateMapping ||
      editContreactTemplateMapping
    ) {
      initFetch();
    }
    return () => {
      actions.resetCTMList();
    };
  }, [practiceId, addContreactTemplateMapping, editContreactTemplateMapping, errorMsg, successMsg]);

  useEffect(() => {
    if (!practices.length) {
      actions.getPractices();
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  const addNewCTM = () => {
    setValues(contractTemplateMappingFormData);
    setIsAdd(true);
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const saveContractTemplateMapping = (event) => {
    event.preventDefault();
    const programId = programsData.filter((item) => item.programName == values.programName);
    const templateId = templateList.filter((item) => item.templateName == values.templateName);

    values.effectiveDate = values.effectiveDate
      ? moment(values.effectiveDate).format("YYYY-MM-DD")
      : "";
    values.endDate = values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : "";

    if (isAdd) {
      const payload = {
        practiceId: practiceId,
        programId: programId[0].programId,
        templateId: templateId[0].templateId,
        effectiveDate: values.effectiveDate,
        endDate: values.endDate,
        treatmentPlanId: values.treatmentPlanId,
        partner: values.partner
      };
      actions.createNewCTM(payload);
    } else {
      actions.editCTM({
        ...values,
        programId: programId[0].programId,
        templateId: templateId[0].templateId
      });
    }
    closeModal();
  };

  const handleEditContractTemplateMapping = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    contractTemplateMappingFormData = {
      templateProgramId: row.templateProgramId,
      templateName: row.templateName,
      practiceId: practiceId,
      programName: row.programName,
      effectiveDate: row.effectiveDate ? moment(row.effectiveDate).format("YYYY-MM-DD") : null,
      endDate: row.endDate ? moment(row.endDate).format("YYYY-MM-DD") : null,
      treatmentPlanId: row.treatmentPlanId,
      partner: row.partner
    };
    setValues(contractTemplateMappingFormData);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    initialData = {
      templateProgramId: templateProgramId
    };
    actions.deleteCTM(initialData);
    closeDeleteModal();
  };

  const handleDeleteProgram = (row) => {
    setDeleteModal(true);
    setTemplateProgramId(row.templateProgramId);
  };

  const contractTemplateMapping = contractTemplateMappingConfig({
    values,
    setValues,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    options: programsData.map((item) => {
      return item.programName;
    }),
    option: templateList.map((item) => {
      return item.templateName;
    }),
    disabled:
      values.programName &&
      values.templateName &&
      values.treatmentPlanId &&
      values.partner &&
      values.effectiveDate != null &&
      values.endDate != null
        ? false
        : true,
    treatmentPlanOptions: treatmentPlanList.map((item) => {
      return {
        id: item.treatmentPlanId,
        name: item.treatmentPlanName + " (" + item.treatmentPlanId + ")"
      };
    })
  });

  const rows = contractTemplateMappingList.filter((item, index) => {
    if (item.practiceId == practiceId) {
      // item.effectiveDate = new Date(item.effectiveDate).toLocaleDateString();
      item.id = index;
      return item;
    }
  });

  const handleFileUpload = () => {
    //navigate(`loadByExcel? gdm_id= ${activeGDM}`);

    navigate({
      pathname: "loadByExcel",
      search: `?gdm_id=${activeGDM}`
    });
  };

  return (
    <>
      <Box
        className={`${
          loading || loadingPractice
            ? "stage-details-section m-10 disable-section"
            : "stage-details-section m-10"
        }`}>
        <Stack direction="row" className="modal-stack pad-10">
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={1}>
              <Typography>Practice</Typography>
            </Grid>
            <Grid item xs={4}>
              {/* <Select
                fullWidth
                name="practiceId"
                value={practiceId}
                onChange={handlePracticeChange}
                className="stage-practice-dropdown">
                {practices.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.name} -{item.practiceId}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Autocomplete
                disablePortal
                fullWidth
                name="practiceId"
                value={
                  practices.find((item) => item.practiceId === practiceId)
                    ? `${
                        practices.find((item) => item.practiceId === practiceId).name
                      } - ${practiceId}`
                    : ""
                }
                options={practices.map((item, index) => {
                  return {
                    id: index,
                    label: `${item.name} - ${item.practiceId}`,
                    name: "practiceId",
                    value: item.practiceId
                  };
                })}
                onChange={(e, value) => {
                  handlePracticeChange(value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." />}
                className="stage-practice-dropdown"
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
      <Box
        sx={{ ml: 1 }}
        className={`${
          loading || loadingPractice ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"
        }`}>
        <Stack direction="row" className="grid-header">
          <Typography>Contract Template Mapping</Typography>
          <Stack direction="row">
            {
              <Button variant="outlined" className="btn-primary m-r-10" onClick={handleFileUpload}>
                Load from Excel
              </Button>
            }
            <Button variant="contained" className="btn-usf" onClick={addNewCTM}>
              Add New Template
            </Button>
          </Stack>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading || loadingPractice}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal
        open={isOpen}
        config={contractTemplateMapping}
        isAdd={isAdd}
        handleSubmit={saveContractTemplateMapping}
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <DeleteModal
        open={deleteModal}
        id={templateProgramId}
        close={closeDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};
const mapStateToProps = ({
  practices,
  ctmReducer,
  programReducer,
  globalDataModelReducer,
  treatmentPlanReducer
}) => {
  return {
    loadingPractice: practices.loading,
    loading: ctmReducer.loading,
    contractTemplateMappingList: ctmReducer.contractTemplateMappingList,
    addContreactTemplateMapping: ctmReducer.addContreactTemplateMapping,
    editContreactTemplateMapping: ctmReducer.editContreactTemplateMapping,
    deleteContreactTemplateMapping: ctmReducer.deleteContreactTemplateMapping,
    errorMsg: ctmReducer.error,
    successMsg: ctmReducer.success,
    programList: programReducer.programList,
    programsData: ctmReducer.programsData,
    templateList: ctmReducer.templateList,
    gdmList: globalDataModelReducer.globalData,
    gdmLoading: globalDataModelReducer.loading,
    treatmentPlanList: treatmentPlanReducer.treatmentPlanList
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    getCTM: (payload) => {
      dispatch(actionType.getCTM(payload));
    },
    createNewCTM: (payload) => {
      dispatch(actionType.createNewCTM(payload));
    },
    editCTM: (payload) => {
      dispatch(actionType.editCTM(payload));
    },
    resetCTMList: () => {
      dispatch(actionType.resetCTMList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getPrograms: (payload) => {
      dispatch(actionType.getPrograms(payload));
    },
    deleteCTM: (payload) => {
      dispatch(actionType.deleteCTM(payload));
    },
    getProgramsdata: (payload) => {
      dispatch(actionType.getProgramsdata(payload));
    },
    getTemplate: () => {
      dispatch(actionType.getTemplate());
    },
    getGlobalData: (payload) => {
      dispatch(actionType.getGlobalData(payload));
    },
    getTreatmentPlan: (payload) => {
      dispatch(actionType.getTreatmentPlan(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(CTM);
