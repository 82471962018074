import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchAddressList(action) {
  let payload = action.payload;
  try {
    const params = {
      offset: payload.offset,
      limit: payload.limit
    };
    const requestBody = createRequestBody(
      "get",
      `/locations/${payload.locationId}/addresses`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADDRESS_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ADDRESS_LIST_FAILED,
        message: error.message
      });
    }
  }
}

function* getAddressSaga() {
  yield takeEvery(actionTypes.ADDRESS_LIST_REQUESTED, fetchAddressList);
}

function* addAddress(action) {
  const payload = action.payload;
  try {
    const requestBody = createRequestBody(
      "post",
      `/locations/${payload.locationId}/addresses`,
      "",
      payload.address
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.ADD_ADDRESS_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ADD_ADDRESS_FAILED,
        message: error.response.data
      });
    }
  }
}

function* addAddressSaga() {
  yield takeEvery(actionTypes.ADD_ADDRESS_REQUESTED, addAddress);
}

function* editAddress(action) {
  const payload = action.payload;
  try {
    const requestBody = createRequestBody(
      "put",
      `/locations/${payload.locationId}/addresses/${payload.addressId}`,
      "",
      payload.address
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_ADDRESS_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_ADDRESS_FAILED,
        message: error.message
      });
    }
  }
}

function* editAddressSaga() {
  yield takeEvery(actionTypes.EDIT_ADDRESS_REQUESTED, editAddress);
}

export default function* addressSaga() {
  yield all([getAddressSaga(), addAddressSaga(), editAddressSaga()]);
}
