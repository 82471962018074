import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPaymentTimingList(payload) {
  try {
    const params = {
      offset: payload.payload.offset,
      limit: payload.payload.limit
    };
    const requestBody = createRequestBody("get", `/paymentTiming`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    console.log("response", response);
    yield put({
      type: actionTypes.PAYMENT_TIMING_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.PAYMENT_TIMING_LIST_FAILED,
        message: error.message
      });
    }
  }
}

function* getPaymentTimingSaga() {
  yield takeEvery(actionTypes.PAYMENT_TIMING_LIST_REQUESTED, fetchPaymentTimingList);
}

// Add payment timing
function* addPaymentTiming(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody("post", `/paymentTiming`, "", payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_PAYMENT_TIMING_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_PAYMENT_TIMING_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addPaymentTimingSaga() {
  yield takeEvery(actionTypes.ADD_PAYMENT_TIMING_REQUESTED, addPaymentTiming);
}

/* Edit practice payment */
function* editPaymentTiming(action) {
  const { payload } = action;
  const { paymentTimingId, description } = payload;
  const body = {
    description: description
  };
  try {
    // const params = {
    //   offset: "",
    //   limit: "100"
    // };
    const requestBody = createRequestBody("put", `/paymentTiming/${paymentTimingId}`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_PAYMENT_TIMING_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_PAYMENT_TIMING_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editPaymentTimingSaga() {
  yield takeEvery(actionTypes.EDIT_PAYMENT_TIMING_REQUESTED, editPaymentTiming);
}

export default function* practicePaymentSaga() {
  yield all([getPaymentTimingSaga(), addPaymentTimingSaga(), editPaymentTimingSaga()]);
}
