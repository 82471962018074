import React, { useState, useEffect } from "react";
import Address from "./Address";
import {
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  Button,
  Tab,
  CircularProgress
} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ExternalId from "./ExternalId";
import TabPanel from "@mui/lab/TabPanel";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CommonModal from "../../shared/components/CommonModal";
import { locationConfig } from "../../shared/constants/renderConfigs";
import MessageModal from "../../shared/components/MessageModal";

function LocationDetails(props) {
  const { locationId, practiceId } = useParams();
  const locationStoreList = useSelector((state) => state.locationReducer.locations);
  const paymentTimingListStore = useSelector((state) => state.paymentTiming.paymentTimingList);
  const optionData = [];
  paymentTimingListStore.map((item) => {
    optionData.push({ id: item.paymentTimingId, name: item.description });
  });
  let locationRowData;
  locationStoreList.forEach((item) => {
    if (item.locationId == locationId) {
      locationRowData = {
        locationId: item?.locationId,
        name: item?.name,
        longName: item?.longName,
        relationship: item?.relationship,
        ivfPaymentTiming: item?.ivfPaymentTiming || "",
        fetPaymentTiming: item?.fetPaymentTiming || "",
        freezeAllPaymentTiming: item?.freezeAllPaymentTiming || "",
        gl: item?.gl || ""
      };
    }
  });
  const [locationDetailsData, setLocationDetailsData] = useState(locationRowData);
  const [tabValue, setTabValue] = useState("1");
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(locationDetailsData);
  const { actions, editLocation, loading, errorMsg, successMsg } = props;
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (editLocation) {
      optionData.forEach((item) => {
        if (editLocation.ivfPaymentTiming == item.id) {
          editLocation.ivfPaymentTiming = item.name;
        }
        if (editLocation.fetPaymentTiming == item.id) {
          editLocation.fetPaymentTiming = item.name;
        }
        if (editLocation.freezeAllPaymentTiming == item.id) {
          editLocation.freezeAllPaymentTiming = item.name;
        }
      });
      setLocationDetailsData(editLocation);
    }
  }, [editLocation]);

  useEffect(() => {
    if (locationStoreList.length == 0) {
      actions.getLocations(practiceId);
    }
    if (locationRowData) {
      setLocationDetailsData(locationRowData);
    }
  }, [locationStoreList]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    actions.resetLocationList();
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  const saveLocation = (event) => {
    event.preventDefault();
    values.practiceId = practiceId;
    values.ivfPaymentTiming = values.ivfPaymentTiming.length ? values.ivfPaymentTiming : null;
    values.fetPaymentTiming = values.fetPaymentTiming.length ? values.fetPaymentTiming : null;
    values.freezeAllPaymentTiming = values.freezeAllPaymentTiming.length
      ? values.freezeAllPaymentTiming
      : null;
    values.gl = values.gl.length ? values.gl : null;
    actions.editLocation(values);
    closeModal();
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const handleEditLocation = () => {
    setIsAdd(false);
    let ivfPaymentTiming = "",
      fetPaymentTiming = "",
      freezeAllPaymentTiming = "";
    paymentTimingListStore.forEach((item) => {
      if (item.description == locationDetailsData.ivfPaymentTiming) {
        ivfPaymentTiming = item.paymentTimingId;
      }
      if (item.description == locationDetailsData.fetPaymentTiming) {
        fetPaymentTiming = item.paymentTimingId;
      }
      if (item.description == locationDetailsData.freezeAllPaymentTiming) {
        freezeAllPaymentTiming = item.paymentTimingId;
      }
    });
    let locationFormData = {
      practiceId: practiceId,
      locationId: locationDetailsData.locationId,
      name: locationDetailsData.name,
      longName: locationDetailsData.longName,
      relationship: locationDetailsData.relationship,
      ivfPaymentTiming: ivfPaymentTiming,
      fetPaymentTiming: fetPaymentTiming,
      freezeAllPaymentTiming: freezeAllPaymentTiming,
      gl: locationDetailsData?.gl || ""
    };
    setValues(locationFormData);
    setIsOpen(true);
  };
  const locationDetails = locationConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values?.name && values?.longName && values.relationship ? false : true,
    optionData: optionData
  });

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  return (
    <Box className={`${loading ? "practice-details disable-section" : "practice-details"}`}>
      <Box className="practice-details-section">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="body1">Practice: {practiceId}</Typography>
            <Typography variant="body2">Location Details</Typography>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              className="btn-primary btn-edit-practice"
              onClick={handleEditLocation}>
              Edit
            </Button>
          </Grid>
        </Grid>
        {loading ? (
          <Stack justifyContent="center" alignItems="center" m={2}>
            <CircularProgress />
          </Stack>
        ) : (
          <Grid container>
            <Grid item xs={5}>
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Name:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{locationDetailsData?.name}</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Relationship:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{locationDetailsData?.relationship}</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">FET Payment Timing:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{locationDetailsData?.fetPaymentTiming}</Typography>
                </Grid>
              </Stack>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={6} sx={{ marginLeft: 4 }}>
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography variant="body1">Long Name:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{locationDetailsData?.longName}</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" className="m-t-10">
                <Grid item xs={3}>
                  <Typography>IVF Payment Timing:</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant="body2">{locationDetailsData?.ivfPaymentTiming}</Typography>
                </Grid>
              </Stack>
              <Stack direction="row" className="m-t-10">
                <Grid item xs={5}>
                  <Typography>FREEZE ALL Payment Timing:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2">
                    {locationDetailsData?.freezeAllPaymentTiming}
                  </Typography>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box flexDirection="row">
        <TabContext value={tabValue}>
          <Box>
            <Stack direction="row" className="loc-details-tabs">
              <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                <Tab label="Address" value="1" />
                <Tab label="External ID" value="2" />
                <Tab label="Reimbursement Prices" value="3" />
              </TabList>
            </Stack>
          </Box>
          <TabPanel value="1" className="pad-0">
            <Address locationId={locationId} />
          </TabPanel>
          <TabPanel value="2">
            <ExternalId locationId={locationId} practiceId={practiceId} />
          </TabPanel>
          <TabPanel value="3">ReimbursementPrices</TabPanel>
        </TabContext>
      </Box>
      <CommonModal
        open={isOpen}
        config={locationDetails}
        isAdd={isAdd}
        handleSubmit={saveLocation}
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </Box>
  );
}
const mapStateToProps = ({ locationReducer }) => {
  return {
    editLocation: locationReducer.editLocation,
    loading: locationReducer.loading,
    errorMsg: locationReducer.error,
    successMsg: locationReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getLocations: (practiceId) => {
      dispatch(actionType.getLocations(practiceId));
    },
    editLocation: (payload) => {
      dispatch(actionType.editLocation(payload));
    },
    resetLocationList: () => {
      dispatch(actionType.resetLocationList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(LocationDetails);
