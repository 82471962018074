import * as actionType from "../actions/ActionTypes";
const initialState = {
  refundFilterList: [],
  loading: false,
  error: null
};
const refundFilterListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.REFUND_FILTER_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.REFUND_FILTER_LIST_SUCCESS: {
      return {
        ...state,
        refundFilterList: action.payload,
        loading: false
      };
    }
    case actionType.REFUND_FILTER_LIST_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_REFUND_FILTER_LIST: {
      return {
        ...state,
        refundFilterList: []
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default refundFilterListReducer;
