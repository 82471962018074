import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { PropTypes } from "prop-types";
import { Stack } from "@mui/material";
import isAccess from "../../shared/components/CheckUserRights";

const ListItemLink = (props) => {
  const { icon, primary, onClick, selected, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItem
      selected={selected}
      button
      component={renderLink}
      disablePadding
      sx={{
        minHeight: "70px"
      }}
      onClick={onClick}>
      <Stack direction="row">
        {icon ? <ListItemIcon sx={{ ml: 2, minWidth: "0" }}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </Stack>
    </ListItem>
  );
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onclick: PropTypes.func,
  selected: PropTypes.bool.isRequired
};

const TrakConfiguration = (props) => {
  const location = useLocation();
  const practiceConfig = [
    {
      title: "CASE LOOKUP",
      url: "/trak/caselookup",
      text: "CaseLookup",
      icon: "/Assets/payment.png",
      screenRights: "cl"
    },
    {
      title: "CONTRACT",
      url: "/trak/contract",
      text: "Contract",
      icon: "/Assets/payment.png",
      screenRights: "ct"
    },
    {
      title: "PROVIDER REIMBURSEMENT",
      url: "/trak/provider-Reimbursement",
      text: "ProviderReimbursement",
      icon: "/Assets/payment.png",
      screenRights: "pvrm"
    },
    {
      title: "REFUND",
      url: "/trak/refund",
      text: "Refund",
      icon: "/Assets/payment.png",
      screenRights: "rf"
    },
    {
      title: "REVENUE",
      url: "/trak/revenue",
      text: "Revenue",
      icon: "/Assets/payment.png",
      screenRights: "rn"
    },
    {
      title: "REPORT",
      url: "/trak/report",
      text: "Report",
      icon: "/Assets/payment.png",
      screenRights: "rp"
    },
    {
      title: "REFUND APPLICATION",
      url: "/trak/refund-application",
      text: "Refund Application",
      icon: "/Assets/payment.png",
      screenRights: "rfap"
    },
    {
      title: "FINANCIAL ESTIMATES",
      url: "/trak/financial-estimates",
      text: "Financial Estimates",
      icon: "/Assets/payment.png",
      screenRights: "rfap"
    },
    {
      title: "REQUESTED CYCLES",
      url: "/trak/requested-cycle",
      text: "REQUESTED CYCLES",
      icon: "/Assets/payment.png",
      screenRights: "rqc"
    }
  ];

  const selectedPath = practiceConfig.findIndex((item) => item.url === location.pathname);

  const [selectedIndex, setSelectedIndex] = useState(selectedPath);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };
  return (
    <Box className="sidebar-container">
      <List disablePadding>
        {practiceConfig.map(
          ({ title, text, url, icon, screenRights }, index) =>
            isAccess(screenRights) && (
              <ListItemLink
                key={index}
                selected={selectedIndex === index}
                onClick={() => {
                  handleListItemClick(index);
                  props.getSelectedConfig(text);
                }}
                to={url}
                primary={title}
                icon={<img src={icon} alt="" />}
              />
            )
        )}
      </List>
    </Box>
  );
};

export default TrakConfiguration;
