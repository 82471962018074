import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchPGTAList() {
  try {
    const params = {
      offset: "",
      limit: ""
    };
    const requestBody = createRequestBody("get", `/practices/pgtas`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.PGTA_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.PGTA_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* getPGTASaga() {
  yield takeEvery(actionTypes.PGTA_LIST_REQUESTED, fetchPGTAList);
}

function* editPGTA(action) {
  const { payload } = action;
  //const { practiceId, selectionChoice } = payload;
  // const body = [
  //   {
  //     practiceId: practiceId,
  //     selectionChoice: selectionChoice
  //   }
  // ];

  try {
    const requestBody = createRequestBody("put", `/practices/pgtas`, "", payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_PGTA_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_PGTA_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* editPGTASaga() {
  yield takeEvery(actionTypes.EDIT_PGTA_REQUESTED, editPGTA);
}

export default function* multiplierSaga() {
  yield all([getPGTASaga(), editPGTASaga()]);
}
