import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select
} from "@mui/material";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  enumUsStates,
  medCasePatientForm,
  medCasePartnerForm,
  handleValidityBtn,
  patientPayload,
  partnerPayload,
  defaultPracticeValues
} from "../../../shared/constants/constants";
import {
  getLocations,
  getPhysicians,
  getcontactList,
  getGlobalData,
  getTreatmentPlan,
  getProgramMultiplier
} from "../../../admin/actions";
import { getPartnerData } from "../../../patients/actions";
import * as actionType from "../../actions";

const MedCaseModal = (props) => {
  const {
    actions,
    locations,
    physicians,
    contactList,
    treatmentPlanList,
    medcaseCreatedM,
    linkMedcaseCreated,
    patientData,
    isManual,
    loading,
    success,
    error
  } = props;

  const dispatch = useDispatch();
  const [values, setValues] = useState(medCasePatientForm);
  const [partnerValues, setPartnerValues] = useState(medCasePartnerForm);
  const [practiceValues, setPracticeValues] = useState(defaultPracticeValues);
  const [practiceId, setPracticeId] = useState("");
  const [isActivePartner, setIsActivePArtner] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [disableTpSection, setDisableTpSection] = useState(true);
  const partnerLoading = useSelector((state) => state.patientReducer.loading);
  const partnerDetails = useSelector((state) => state.patientReducer.partnerData);
  const practices = useSelector((state) => state.practices.practices);
  const gdmList = useSelector((state) => state.globalDataModelReducer.globalData);
  const programList = useSelector((state) => state.multiplier.programMultiplier);
  //const gdmLoading = useSelector((state) => state.globalDataModelReducer.loading);
  const tpLoading = useSelector((state) => state.multiplier.loading);
  const gdmId = gdmList.length > 0 && gdmList?.filter((pd) => pd.status === "Active")[0]?.gdmId;

  const handleNetworkCall = (value) => {
    dispatch(getLocations(value));
    dispatch(getPhysicians(value));
    dispatch(getcontactList(value));
    dispatch(getGlobalData(value));
  };

  useEffect(() => {
    if (success || error) {
      setSuccessModal(true);
    }
  }, [error, success]);

  useEffect(() => {
    if (medcaseCreatedM.patient != undefined) {
      setValues({ ...values, personKey: medcaseCreatedM.patient.personKey });
      setDisableTpSection(false);
    }
  }, [medcaseCreatedM]);

  useEffect(() => {
    if (partnerDetails.personId != undefined) {
      let phoneNumber, addressLine1, addressLine2, city, state, zip;

      if (partnerDetails.defaultPhone == "cell") {
        phoneNumber = partnerDetails.cellPhone;
      }
      if (partnerDetails.defaultPhone == "work") {
        phoneNumber = partnerDetails.workPhone;
      }

      if (partnerDetails.defaultPhone == "home") {
        phoneNumber = partnerDetails.homePhone;
      }

      if (partnerDetails.defaultAddress == "mailing") {
        addressLine1 = partnerDetails.mailingAddrLine1;
        addressLine2 = partnerDetails.mailingAddrLine2;
        city = partnerDetails.mailingCity;
        state = partnerDetails.mailingState;
        zip = partnerDetails.mailingZip;
      }

      if (partnerDetails.defaultAddress == "billing") {
        addressLine1 = partnerDetails.billingAddrLine1;
        addressLine2 = partnerDetails.billingAddrLine2;
        city = partnerDetails.billingCity;
        state = partnerDetails.billingState;
        zip = partnerDetails.billingZip;
      }
      setPartnerValues({
        ...partnerValues,
        ...partnerDetails,
        defaultPhone: partnerDetails.defaultPhone == null ? "" : partnerDetails.defaultPhone,
        phoneNumber,
        addressLine1,
        addressLine2,
        city,
        state,
        zip
      });
    }
  }, [partnerLoading]);

  useEffect(() => {
    setValues(medCasePatientForm);
    setPartnerValues(medCasePartnerForm);
    setPracticeValues(defaultPracticeValues);
    if (!isManual) {
      setPracticeId(patientData.practiceId);
      let phoneNumber, addressLine1, addressLine2, city, state, zip;
      if (patientData.defaultPhone == "cell") {
        phoneNumber = patientData.cellPhone;
      }
      if (patientData.defaultPhone == "work") {
        phoneNumber = patientData.workPhone;
      }

      if (patientData.defaultPhone == "home") {
        phoneNumber = patientData.homePhone;
      }

      if (patientData.defaultAddress == "mailing") {
        addressLine1 = patientData.mailingAddrLine1;
        addressLine2 = patientData.mailingAddrLine2;
        city = patientData.mailingCity;
        state = patientData.mailingState;
        zip = patientData.mailingZip;
      }

      if (patientData.defaultAddress == "billing") {
        addressLine1 = patientData.billingAddrLine1;
        addressLine2 = patientData.billingAddrLine2;
        city = patientData.billingCity;
        state = patientData.billingState;
        zip = patientData.billingZip;
      }

      setValues({
        ...values,
        ...patientData,
        defaultPhone: patientData.defaultPhone == null ? "" : patientData.defaultPhone,
        phoneNumber,
        addressLine1,
        addressLine2,
        city,
        state,
        zip
      });
      setIsActivePArtner(patientData.activePartner == null ? false : true);
      handleNetworkCall(patientData.practiceId);
      setPracticeId(patientData.practiceId);

      if (patientData.activePartner !== null) {
        let payload = {
          practiceId: patientData.practiceId,
          personId: patientData.activePartner.partnerId
        };
        dispatch(getPartnerData(payload));
      }
    }
  }, []);

  useEffect(() => {
    if (gdmList?.length > 0) {
      let payload = {
        practiceId: practiceId == "" ? partnerDetails.practiceId : practiceId,
        gdmId: gdmId
      };
      setPracticeValues((prev) => ({
        ...prev,
        gdmId: gdmId
      }));
      dispatch(getTreatmentPlan(payload));
    }
  }, [gdmList]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value
    });
  };
  const handleActivePartner = (e) => {
    setPartnerValues({ ...medCasePartnerForm });
    setIsActivePArtner(e.target.value == "true" ? true : false);
  };

  const handlePracticeChange = (event) => {
    const { name, value } = event.target;
    if (name == "practiceId") {
      setPracticeId(event.target.value);
      handleNetworkCall(event.target.value);
    }
    if (name == "tpId") {
      dispatch(
        getProgramMultiplier({
          practiceId,
          gdmId: practiceValues.gdmId,
          tpId: value,
          patientId: isManual ? medcaseCreatedM?.patient?.personId : values.personId
        })
      );
      setPracticeValues({ ...practiceValues, [name]: value });
    } else {
      setPracticeValues({ ...practiceValues, [name]: value });
    }
  };

  const handleChangePartner = (e) => {
    const { name, value } = e.target;
    setPartnerValues({
      ...partnerValues,
      [name]: value
    });
  };

  const handleClose = () => {
    setValues({ ...medCasePatientForm });
    setPartnerValues({ ...medCasePartnerForm });
    setPracticeValues({ ...defaultPracticeValues });
    setIsActivePArtner(false);
    actions.resetMedcase();
    props.handleClose();
  };

  const handleCreate = (e) => {
    e.preventDefault();
    let payload = {
      ...practiceValues,
      linkMedcaseId: practiceValues.linkMedcaseId == "" ? null : practiceValues.linkMedcaseId,
      contactId: practiceValues.contactId == "" ? null : practiceValues.contactId,
      physicianId: practiceValues.physicianId == "" ? null : practiceValues.physicianId,
      locationId: practiceValues.locationId == "" ? null : practiceValues.locationId,
      patientId: isManual
        ? medcaseCreatedM.patient != undefined && medcaseCreatedM.patient.personId
        : values.personId,
      practiceId
    };
    actions.createLinkMedcase(payload);
  };

  const handleSave = () => {
    if (!isManual) {
      let partnerInfo = {
        ...partnerPayload(partnerValues),
        mailingCountry: partnerValues.defaultAddress == "mailing" ? "USA" : "",
        billingCountry: partnerValues.defaultAddress == "billing" ? "USA" : "",
        practiceId,
        personKey: values.personKey,
        birthDate: partnerValues.birthDate.format("MM/DD/YYYY"),
        activePartner: {
          patientPartnerId: null,
          patientId: values.personId,
          partnerId: null,
          active: true
        }
      };

      let payload = { practiceId, partnerInfo, personId: values.personId };

      actions.savePartner(payload);
    } else {
      let PatientPartnerCouple = {
        patient: {
          ...patientPayload(values),
          practiceId,
          defaultPhone: values.defaultPhone == "" ? null : values.defaultPhone,
          cellPhone: values.phoneNumber == "" ? null : values.phoneNumber,
          homePhone: values.phoneNumber == "" ? null : values.phoneNumber,
          workPhone: values.phoneNumber == "" ? null : values.phoneNumber,
          birthDate: values.birthDate.format("MM/DD/YYYY"),
          mailingCountry: values.defaultAddress == "mailing" ? "USA" : "",
          billingCountry: values.defaultAddress == "billing" ? "USA" : ""
        },
        partner: isActivePartner
          ? {
              ...partnerPayload(partnerValues),
              defaultPhone: partnerValues.defaultPhone == "" ? null : partnerValues.defaultPhone,
              cellPhone: partnerValues.phoneNumber == "" ? null : partnerValues.phoneNumber,
              homePhone: partnerValues.phoneNumber == "" ? null : partnerValues.phoneNumber,
              workPhone: partnerValues.phoneNumber == "" ? null : partnerValues.phoneNumber,
              birthDate: partnerValues.birthDate.format("MM/DD/YYYY"),
              mailingCountry: partnerValues.defaultAddress == "mailing" ? "USA" : "",
              billingCountry: partnerValues.defaultAddress == "billing" ? "USA" : "",
              practiceId
            }
          : null
      };

      let payload = { practiceId, PatientPartnerCouple };

      actions.savePatient(payload);
    }
    if (gdmList?.length > 0) {
      let payload = {
        practiceId: practiceId == "" ? partnerDetails.practiceId : practiceId,
        gdmId: gdmId
      };
      setPracticeValues((prev) => ({
        ...prev,
        gdmId: gdmId
      }));
      dispatch(getTreatmentPlan(payload));
    }
  };

  const closeSuccessModal = () => {
    actions.resetMessage();
    if (success) {
      if (linkMedcaseCreated.medcaseId != undefined) {
        setSuccessModal(false);
        handleClose();
      }
    }
    setSuccessModal(false);
  };
  const renderPartnerForm = (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <InputLabel required className="m-l-8">
                First Name
              </InputLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                fullWidth
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                autoComplete="off"
                name="firstName"
                value={partnerValues?.firstName}
                onChange={handleChangePartner}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <InputLabel required className="m-l-8">
                Last Name
              </InputLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                fullWidth
                autoComplete="off"
                name="lastName"
                value={partnerValues?.lastName}
                onChange={handleChangePartner}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={2}>
              <InputLabel required className="m-l-8">
                DOB
              </InputLabel>
            </Grid>
            <Grid item xs={8}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  openTo="day"
                  name="birthDate"
                  disableFuture
                  disabled={
                    !isManual && patientData != undefined && patientData.activePartner != null
                  }
                  value={partnerValues?.birthDate ? partnerValues?.birthDate : null}
                  onChange={(newValue) => {
                    setPartnerValues({ ...partnerValues, birthDate: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" autoComplete="off" sx={{ flex: 8 }} />
                  )}></DatePicker>
              </LocalizationProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container mt={1}>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <InputLabel className="m-l-8">Phone Type</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <Select
                size="small"
                fullWidth
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                value={partnerValues?.defaultPhone}
                onChange={handleChangePartner}
                className="add-description"
                name="defaultPhone">
                <MenuItem value="cell">Cell Phone</MenuItem>
                <MenuItem value="home">Home Phone</MenuItem>
                <MenuItem value="work">Work Phone</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <InputLabel className="m-l-8">Phone No</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                fullWidth
                autoComplete="off"
                name="phoneNumber"
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                value={partnerValues.phoneNumber}
                onChange={handleChangePartner}
                className="add-description"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={2}>
              <InputLabel className="m-l-8">Email *</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                fullWidth
                autoComplete="off"
                name="email"
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                value={partnerValues?.email}
                onChange={handleChangePartner}
                className="add-description"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container mt={1}>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <InputLabel required>Address Type</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <Select
                size="small"
                fullWidth
                defaultValue="mailing"
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                value={partnerValues?.defaultAddress}
                onChange={handleChangePartner}
                className="add-description"
                name="defaultAddress">
                <MenuItem value="mailing">Mailing Address</MenuItem>
                <MenuItem value="billing">Billing Address</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={2}>
              <InputLabel required className="m-l-8">
                Address Line 1
              </InputLabel>
            </Grid>
            <Grid item xs={9}>
              <TextField
                size="small"
                fullWidth
                autoComplete="off"
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                name="addressLine1"
                value={partnerValues.addressLine1}
                onChange={handleChangePartner}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container mt={1}>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <InputLabel required>City</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                fullWidth
                autoComplete="off"
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                name="city"
                value={partnerValues?.city}
                onChange={handleChangePartner}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={8}>
          <Grid container>
            <Grid item xs={2}>
              <InputLabel className="m-l-8">Address Line 2</InputLabel>
            </Grid>
            <Grid item xs={9}>
              <TextField
                size="small"
                fullWidth
                autoComplete="off"
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                name="addressLine2"
                value={partnerValues?.addressLine2}
                onChange={handleChangePartner}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container mt={1}>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <InputLabel required>State</InputLabel>
            </Grid>
            <Grid item xs={8}>
              <Select
                size="small"
                fullWidth
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                name="state"
                value={partnerValues.state}
                onChange={handleChangePartner}
                className="add-description">
                {enumUsStates.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container>
            <Grid item xs={4}>
              <InputLabel required className="m-l-8">
                Zip
              </InputLabel>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                fullWidth
                disabled={
                  !isManual && patientData != undefined && patientData.activePartner != null
                }
                autoComplete="off"
                name="zip"
                value={partnerValues?.zip}
                onChange={handleChangePartner}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Fragment>
      <Modal
        open={props.open}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box className="modal-style-medcase">
          <Stack direction="row" className="modal-stack">
            <Typography>
              {isManual ? "New Medcase Manually" : "New Medcase For Selected Patient"}
            </Typography>
            <IconButton onClick={handleClose}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Box className="medcase-form-container">
            <Stack className="medcase-form">
              <Typography className="font-w-500 m-t-10">Patient</Typography>
              <Grid container mt={1}>
                <Grid item xs={4}>
                  <Grid container className="align-center">
                    <Grid item xs={4}>
                      <InputLabel required>First Name</InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        size="small"
                        fullWidth
                        disabled={!isManual}
                        autoComplete="off"
                        name="firstName"
                        value={values?.firstName}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container className="align-center">
                    <Grid item xs={4}>
                      <InputLabel required className="m-l-8">
                        Last Name
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        size="small"
                        fullWidth
                        disabled={!isManual}
                        autoComplete="off"
                        name="lastName"
                        value={values?.lastName}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container className="align-center">
                    <Grid item xs={2}>
                      <InputLabel required className="m-l-8">
                        DOB
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          openTo="day"
                          name="birthDate"
                          disabled={!isManual}
                          disableFuture
                          value={values?.birthDate ? values?.birthDate : null}
                          onChange={(newValue) => {
                            setValues({ ...values, birthDate: newValue });
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size="small" sx={{ flex: 8 }} />
                          )}></DatePicker>
                      </LocalizationProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={4}>
                  <Grid container className="align-center">
                    <Grid item xs={4}>
                      <InputLabel>Phone Type</InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        size="small"
                        fullWidth
                        placeholder=""
                        value={values?.defaultPhone}
                        onChange={handleChange}
                        disabled={!isManual}
                        className="add-description"
                        name="defaultPhone">
                        <MenuItem value="cell">Cell Phone</MenuItem>
                        <MenuItem value="home">Home Phone</MenuItem>
                        <MenuItem value="work">Work Phone</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container className="align-center">
                    <Grid item xs={4}>
                      <InputLabel className="m-l-8">Phone No </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        size="small"
                        fullWidth
                        disabled={!isManual}
                        name="phoneNumber"
                        autoComplete="off"
                        value={values?.phoneNumber}
                        onChange={handleChange}
                        className="add-description"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container>
                    <Grid item xs={2}>
                      <InputLabel className="m-l-8">Email *</InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        size="small"
                        fullWidth
                        autoComplete="off"
                        disabled={!isManual}
                        name="email"
                        value={values?.email}
                        onChange={handleChange}
                        className="add-description"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={4}>
                  <Grid container className="align-center">
                    <Grid item xs={4}>
                      <InputLabel required>Address Type</InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        size="small"
                        fullWidth
                        placeholder=""
                        disabled={!isManual}
                        defaultValue="mailing"
                        value={values?.defaultAddress}
                        onChange={handleChange}
                        className="add-description"
                        name="defaultAddress">
                        <MenuItem value="mailing">Mailing Address</MenuItem>
                        <MenuItem value="billing">Billing Address</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Grid container className="align-center">
                    <Grid item xs={2}>
                      <InputLabel required className="m-l-8">
                        Address Line 1
                      </InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        size="small"
                        fullWidth
                        name="addressLine1"
                        disabled={!isManual}
                        autoComplete="off"
                        value={values.addressLine1}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={4}>
                  <Grid container className="align-center">
                    <Grid item xs={4}>
                      <InputLabel required>City</InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        size="small"
                        fullWidth
                        autoComplete="off"
                        name="city"
                        disabled={!isManual}
                        value={values.city}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={8}>
                  <Grid container className="align-center">
                    <Grid item xs={2}>
                      <InputLabel className="m-l-8">Address Line 2</InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        size="small"
                        fullWidth
                        disabled={!isManual}
                        autoComplete="off"
                        name="addressLine2"
                        value={values.addressLine2}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={1}>
                <Grid item xs={4}>
                  <Grid container className="align-center">
                    <Grid item xs={4}>
                      <InputLabel required>State</InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <Select
                        size="small"
                        fullWidth
                        autoComplete="off"
                        name="state"
                        value={values.state}
                        disabled={!isManual}
                        onChange={handleChange}
                        className="add-description">
                        {enumUsStates.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container className="align-center">
                    <Grid item xs={4}>
                      <InputLabel required className="m-l-8">
                        Zip
                      </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        size="small"
                        fullWidth
                        disabled={!isManual}
                        autoComplete="off"
                        name="zip"
                        value={values.zip}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <FormControl sx={{ mt: 1 }}>
                <Typography id="demo-controlled-radio-buttons-group">
                  Does Patient have any active partner?*
                </Typography>

                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  value={isActivePartner}
                  onChange={handleActivePartner}>
                  <FormControlLabel
                    disabled={
                      !isManual && patientData != undefined && patientData.activePartner != null
                    }
                    value={true}
                    control={<Radio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    disabled={
                      !isManual && patientData != undefined && patientData.activePartner != null
                    }
                    value={false}
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>

              {isActivePartner ? renderPartnerForm : null}
              <Typography mt={1} className="font-w-500">
                Person No
              </Typography>

              <Grid container xs={6} mt={1}>
                <Grid item xs={2.6}>
                  <InputLabel className="m-l-8">Person No</InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    size="small"
                    fullWidth
                    disabled
                    autoComplete="off"
                    name="personKey"
                    value={values?.personKey}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Typography mt={1} className="font-w-500">
                Practice Details
              </Typography>
              <Grid container mt={1}>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={2.6}>
                      <InputLabel required className="m-l-8">
                        Practice
                      </InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                      <Select
                        fullWidth
                        name="practiceId"
                        placeholder="Select Practice"
                        disabled={!isManual}
                        value={practiceId}
                        onChange={handlePracticeChange}
                        className="stage-practice-dropdown">
                        {practices.map((item, index) => {
                          return (
                            <MenuItem value={item.practiceId} key={index}>
                              {item.name} - {item.practiceId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={2}>
                      <InputLabel className="m-l-8">Location</InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                      <Select
                        size="small"
                        fullWidth
                        placeholder="Select Location"
                        value={practiceValues.locationId}
                        onChange={handlePracticeChange}
                        className="add-description"
                        name="locationId">
                        {locations?.map((item, index) => {
                          return (
                            <MenuItem value={item.locationId} key={index}>
                              {item.name} -{item.locationId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={2.6}>
                      <InputLabel className="m-l-8">Physician</InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                      <Select
                        size="small"
                        fullWidth
                        placeholder="Select Physician"
                        value={practiceValues?.physicianId}
                        onChange={handlePracticeChange}
                        className="add-description"
                        name="physicianId">
                        {physicians?.map((item, index) => {
                          return (
                            <MenuItem value={item.physicianId} key={index}>
                              {item.firstName} - {item.lastName} - {item.physicianId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={2}>
                      <InputLabel className="m-l-8">Contact</InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                      <Select
                        size="small"
                        fullWidth
                        placeholder="Select Contact"
                        value={practiceValues?.contactId}
                        onChange={handlePracticeChange}
                        className="add-description"
                        name="contactId">
                        {contactList?.map((item, index) => {
                          return (
                            <MenuItem value={item.contactId} key={index}>
                              {item.firstName} -{item.contactId}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Stack
                direction="row"
                spacing={2}
                sx={{ my: 3, mr: 5, alignItems: "center", justifyContent: "flex-end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  className="btn-usf"
                  disabled={
                    handleValidityBtn(
                      values,
                      partnerValues,
                      isActivePartner,
                      practiceId,
                      isManual,
                      patientData
                    ) && medcaseCreatedM.patient == undefined
                      ? false
                      : true
                  }
                  onClick={handleSave}>
                  Save
                </Button>
              </Stack>

              <Typography mt={1} className="font-w-500">
                Treatment Plan Details
              </Typography>

              <Grid container mt={1}>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={2.6}>
                      <InputLabel required className="word-break">
                        Treatment Plan
                      </InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                      <Select
                        size="small"
                        fullWidth
                        placeholder="Select Treatment Plan"
                        value={practiceValues?.tpId}
                        onChange={handlePracticeChange}
                        className="add-description"
                        name="tpId"
                        disabled={isManual ? disableTpSection : false}>
                        {treatmentPlanList &&
                          treatmentPlanList.map((item, index) => {
                            return (
                              <MenuItem value={item.treatmentPlanId} key={index}>
                                {item.treatmentPlanName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container>
                    <Grid item xs={2}>
                      <InputLabel required className="m-l-8">
                        Program
                      </InputLabel>
                    </Grid>
                    <Grid item xs={9}>
                      <Select
                        size="small"
                        fullWidth
                        placeholder="Select Program"
                        value={practiceValues?.pmId}
                        onChange={handlePracticeChange}
                        className="add-description"
                        name="pmId"
                        disabled={isManual ? disableTpSection : false}>
                        {programList &&
                          programList.map((item, index) => {
                            return (
                              <MenuItem value={item.programMultiplierId} key={index}>
                                {item.programName}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/* <Typography mt={1} className="font-w-500">
                Link Medcase
              </Typography> */}

              {/* <Grid container xs={6} mt={1}>
                <Grid item xs={2.6}>
                  <InputLabel className="m-l-8">Medcase</InputLabel>
                </Grid>
                <Grid item xs={9}>
                  <Stack direction="row">
                    <TextField
                      fullWidth
                      size="small"
                      value={practiceValues?.linkMedcaseId}
                      placeholder=""
                      name="linkMedcaseId"
                      onChange={handlePracticeChange}
                    />
                  </Stack>
                </Grid>
              </Grid> */}
            </Stack>
          </Box>

          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 3, mr: 7, alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant="outlined" className="btn-primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              className="btn-usf"
              disabled={practiceId == "" || practiceValues.tpId == "" || practiceValues.pmId == ""}
              onClick={handleCreate}>
              Create
            </Button>
          </Stack>
        </Box>
      </Modal>

      {loading || tpLoading ? (
        <Modal
          open={loading || tpLoading ? true : false}
          backdrop="static"
          aria-labelledby="modal-add-title">
          <Box className="alert-modal" width="100px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Modal>
      ) : null}

      {/* Success Modal */}
      <Modal open={successModal} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="alert-modal">
          <Stack direction="row" className="modal-stack">
            <Typography>Alert</Typography>
            <IconButton onClick={closeSuccessModal}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", py: 2 }}>
            {success && (
              <Typography variant="body2" color="#42ba96">
                {success}
              </Typography>
            )}
            {error && (
              <Typography variant="body2" color="#A6315C">
                {error}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center" }}>
            <Button variant="contained" className="btn-primary" onClick={closeSuccessModal}>
              OK
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = ({
  medcaseReducer,
  treatmentPlanReducer,
  contactReducer,
  physiciansReducer,
  locationReducer,
  multiplier
}) => {
  return {
    medcaseCreatedM: medcaseReducer.medcaseCreatedM,
    treatmentPlanList: treatmentPlanReducer.treatmentPlanList,
    linkMedcaseCreated: medcaseReducer.linkMedcaseCreated,
    programList: multiplier.multiplierList,
    contactList: contactReducer.contactList,
    physicians: physiciansReducer.physicians,
    locations: locationReducer.locations,
    success: medcaseReducer.success,
    error: medcaseReducer.error,
    loading: medcaseReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    savePatient: (payload) => {
      dispatch(actionType.createMedcaseManually(payload));
    },
    savePartner: (payload) => {
      dispatch(actionType.savePartner(payload));
    },
    resetMedcase: () => {
      dispatch(actionType.resetMedcase());
    },
    createLinkMedcase: (payload) => {
      dispatch(actionType.createLinkMedcase(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(MedCaseModal);
