import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Stack,
  InputLabel,
  Button,
  Divider,
  Typography,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow
} from "@mui/material";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import rolerightsTable from "../../shared/constants/rolerightsTable";
import SuperData from "../../shared/constants/SuperData";

const StaffAdminUserDetails = (props) => {
  const cognitoId = useSelector((state) => state.staffAdminReducer.cognitoId);
  const [practiceList, setPracticeList] = useState();
  const [rightsList, setRightsList] = useState([]);
  const [pRole, setPRole] = useState();
  const [screenRights, setScreenRights] = useState([]);
  const [arrGR, setArrGR] = useState([]);
  const [arrSR, setArrSR] = useState([]);
  const [loadRights, setLoadRights] = useState(true);

  const { actions, staffAdminUserDetails = [], loading } = props;
  useEffect(() => {
    // actions.resetStaffAdminUsersList();
    if (cognitoId) {
      actions.getStaffAdminUserDetails({ cognitoId: cognitoId });
    }
    setPRole(staffAdminUserDetails.primaryRole);
  }, [cognitoId]);

  useEffect(() => {
    setPRole(staffAdminUserDetails.primaryRole);
  }, [staffAdminUserDetails]);

  useEffect(() => {
    const userRightList = staffAdminUserDetails?.userRightList;
    if (staffAdminUserDetails.length != 0) {
      setPracticeList(staffAdminUserDetails?.practiceList);
      setRightsList(Object.keys(userRightList));
      setScreenRights(Object.values(userRightList));
      for (let key in rolerightsTable[0]) {
        if (rolerightsTable[0][key].displayName === pRole) {
          const arr = [];
          for (let a in rolerightsTable[0][key].groupname_grouprights) {
            if (rolerightsTable[0][key].groupname_grouprights[a].group_name_attr == rightsList[a]) {
              arr.push(rolerightsTable[0][key].groupname_grouprights[a].group_name_display);
              setArrGR(arr);
            }
          }
        }
      }
    }
  }, [staffAdminUserDetails, pRole]);

  useEffect(() => {
    const arr3 = [];
    for (let c in SuperData) {
      for (let b in arrGR) {
        if (SuperData[c].group_name_display === arrGR[b]) {
          const arr2 = [];
          for (let k in Object.keys(SuperData[c].group_rights)) {
            for (let r in screenRights[b]?.split(":").filter((e) => e))
              if (
                Object.keys(SuperData[c].group_rights)[k] ==
                screenRights[b].split(":").filter((e) => e)[r]
              ) {
                arr2.push(Object.values(SuperData[c].group_rights)[k]);
              }
          }
          arr3.push(":" + arr2.join(":") + ":");
        }
      }
    }
    setArrSR(arr3);
  }, [staffAdminUserDetails, arrGR]);

  useEffect(() => {
    if (arrGR.length > 0 && arrSR.length > 0) {
      setLoadRights(false);
    }
  }, [arrGR, arrSR]);
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Box
          sx={{ ml: 1 }}
          className={`${loading ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"}`}>
          <Stack direction="row" className="grid-header">
            <Typography>User Details</Typography>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="row" className="modal-stack">
            <InputLabel className="flex-2">First Name</InputLabel>
            <Typography sx={{ flex: 6 }}>{staffAdminUserDetails?.firstName}</Typography>
          </Stack>
          <Stack direction="row" className="modal-stack">
            <InputLabel className="flex-2">Last Name</InputLabel>
            <Typography sx={{ flex: 6 }}>{staffAdminUserDetails?.lastName}</Typography>
          </Stack>
          <Stack direction="row" className="modal-stack">
            <InputLabel className="flex-2">Email Id</InputLabel>
            <Typography sx={{ flex: 6 }}>{staffAdminUserDetails?.email}</Typography>
          </Stack>
          <Stack direction="row" className="modal-stack">
            <InputLabel className="flex-2">Roles</InputLabel>
            <Typography sx={{ flex: 6 }}>{staffAdminUserDetails?.primaryRole}</Typography>
          </Stack>
          <Stack direction="row" className="modal-stack">
            <InputLabel className="flex-2">Practice</InputLabel>
            <Stack sx={{ flex: 6 }} direction="column" style={{ justifyContent: "flex-start" }}>
              {practiceList &&
                practiceList.map((item, index) => {
                  return <Typography key={index}>{item.practiceLongName}</Typography>;
                })}
            </Stack>
          </Stack>
          <Stack spacing={2} className="modal-stack-left">
            <InputLabel className="flex-2"> User Rights </InputLabel>
            {loadRights ? (
              <Stack justifyContent="center" alignItems="center" m={2}>
                <Typography>No rights for this user</Typography>
              </Stack>
            ) : (
              <Table>
                <TableHead>
                  <TableRow className="border-l-r-none border-b-t">
                    <TableCell className="border-l-r-none  ">#</TableCell>
                    <TableCell className="border-l-r-none  ">
                      <Typography className="bold">GROUP RIGHTS</Typography>
                    </TableCell>
                    <TableCell className="border-l-r-none  ">
                      <Typography className="bold">SCREEN RIGHTS</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {arrGR.length > 0 &&
                    arrGR.map((item, j) => (
                      <TableRow key={j}>
                        <TableCell className="border-l-r-none  border-t-none "> {j + 1}</TableCell>
                        <TableCell className="border-l-r-none border-t-none " sx={{ width: "50%" }}>
                          <Typography>{item}</Typography>
                        </TableCell>
                        {arrSR.length > 0 &&
                          arrSR[j]
                            ?.split(":")
                            .filter((e) => e)
                            .map((i) => (
                              <TableRow key={i}>
                                <TableCell
                                  className="border-l-r-none border-t-none"
                                  sx={{ width: 510 }}>
                                  <Typography>{i}</Typography>
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            )}
          </Stack>
          <Stack direction="row" spacing={2} className="modal-stack">
            <Link to={"/admin/staff-admin"}>
              <Button variant="contained" className="btn-primary">
                Close
              </Button>
            </Link>
          </Stack>
        </Box>
      )}
    </>
  );
};
const mapStateToProps = ({ staffAdminUserReducer }) => {
  return {
    staffAdminUserDetails: staffAdminUserReducer.staffAdminUserDetails,
    loading: staffAdminUserReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStaffAdminUserDetails: (payload) => {
      dispatch(actionType.getStaffAdminUserDetails(payload));
    },
    resetStaffAdminUsersList: () => {
      dispatch(actionType.resetStaffAdminUsersList());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(StaffAdminUserDetails);
