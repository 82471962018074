import ActionButton from "../components/ActionButton";
import HeaderField from "../components/HeaderField";
import InputField from "../components/InputField";
import DatePickerField from "../components/DatePickerField";
import DateInputField from "../components/DateInputField";
import DatePickerFieldCustom from "../components/DatePickerFieldCustom";
import LocationSelectField from "../components/LocationSelectField";
import SelectField from "../components/SelectField";
import CheckBoxField from "../components/CheckBoxField";
import { usStates, externalIdoptions } from "./constants";
import DatePickerFieldDOB from "../components/DatePickerFieldDOB";
import RadioField from "../components/RadioField";
import RichTextEditor from "../components/RichTextEditor";
import DisplayField from "../components/DisplayField";
import moment from "moment/moment";
import isAccess from "../components/CheckUserRights";

export const practicePaymentConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Practice Payment" : "Edit Practice Payment",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "description",
        name: "description",
        component: InputField,
        label: "Description",
        onChange: onChange,
        value: values.description,
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const practiceConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Practice" : "Edit Practice",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "name",
        name: "name",
        component: InputField,
        label: "Name",
        onChange: onChange,
        value: values?.name,
        mandatory: true
      },
      {
        key: "longName",
        name: "longName",
        component: InputField,
        label: "Long Name",
        onChange: onChange,
        value: values?.longName,
        mandatory: true
      },
      {
        key: "status",
        name: "status",
        component: SelectField,
        label: "Status",
        onChange: onChange,
        value: values?.status,
        options: ["Active", "Inactive"],
        mandatory: true
      },
      {
        key: "phoneNumber",
        name: "phoneNumber",
        component: InputField,
        label: "Phone Number",
        onChange: onChange,
        value: values?.phoneNumber || "",
        placeholder: "Ex. 123-123-1234"
      },
      {
        key: "website",
        name: "website",
        component: InputField,
        label: "Website",
        onChange: onChange,
        value: values?.website || ""
      },
      {
        key: "externalNextgenPracticeId",
        name: "externalNextgenPracticeId",
        component: InputField,
        label: "Next Gen External Practice ID",
        onChange: onChange,
        value: values?.externalNextgenPracticeId,
        mandatory: true
      },
      {
        key: "externalFertPracticeId",
        name: "externalFertPracticeId",
        component: InputField,
        label: "Fert External Practice ID",
        onChange: onChange,
        value: values?.externalFertPracticeId,
        mandatory: true
      },
      {
        key: "fertLink",
        name: "fertLink",
        component: InputField,
        label: "Fert Link",
        onChange: onChange,
        value: values?.fertLink
      },
      {
        key: "payYourBillLink",
        name: "payYourBillLink",
        component: InputField,
        label: "Pay Your Bill Link",
        onChange: onChange,
        value: values?.payYourBillLink
      },
      {
        key: "practiceLogo",
        name: "practiceLogo",
        component: InputField,
        label: "Practice Logo",
        type: "file",
        // access: "image/jpeg",
        onChange: onChange,
        src: values?.practiceLogo
      },
      {
        key: "practicePdfLogo",
        name: "practicePdfLogo",
        component: InputField,
        label: "Practice Pdf Logo",
        type: "file",
        // access: "image/jpeg",
        onChange: onChange,
        src: values?.practicePdfLogo
      },
      {
        key: "vendorId",
        name: "vendorId",
        component: InputField,
        label: "VendorId",
        onChange: onChange,
        value: values?.vendorId,
        mandatory: true
      },
      {
        key: "accountNumber",
        name: "accountNumber",
        component: InputField,
        label: "Account Number",
        onChange: onChange,
        value: values?.accountNumber,
        mandatory: true
      },
      {
        key: "projectId",
        name: "projectId",
        component: InputField,
        label: "Project Id",
        onChange: onChange,
        value: values?.projectId,
        mandatory: true
      },
      {
        key: "locationId",
        name: "locationId",
        component: InputField,
        label: "Location Id",
        onChange: onChange,
        value: values?.locationId,
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const emailConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Email" : "Edit Email",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "emailName",
        name: "emailName",
        component: InputField,
        label: "Email Name",
        onChange: onChange,
        value: values.emailName,
        mandatory: true
      },
      {
        key: "emailAddress",
        name: "emailAddress",
        component: InputField,
        label: "Email Address",
        onChange: onChange,
        value: values.emailAddress,
        mandatory: true,
        message: "Please enter the email Id in valid format"
      },
      {
        key: "status",
        name: "status",
        component: SelectField,
        label: "Status",
        options: ["Active", "Inactive"],
        onChange: onChange,
        value: values?.status
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const emailDocumentConfig = ({ isAddModal, state, onChange, onClick, required }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAddModal ? "New Email Document" : "Edit Email Document",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "emailName",
        id: "emailName",
        component: SelectField,
        label: "Email Name",
        onChange: onChange("emailName"),
        value: state["emailName"],
        required: required
      },
      {
        key: "emailAddress",
        id: "emailAddress",
        component: InputField,
        label: "Email Address",
        onChange: onChange("emailAddress"),
        value: state["emailAddress"],
        required: required
      },
      {
        key: "status",
        id: "status",
        component: SelectField,
        label: "Status",
        onChange: onChange("status"),
        value: state["status"],
        options: ["Active", "Inactive"]
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAddModal ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick
      }
    ]
  };
};

export const programConfig = ({ isAddModal, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAddModal ? "New Program" : "Edit Program",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "programName",
        name: "programName",
        component: InputField,
        label: "Program Name",
        onChange: onChange,
        value: values?.programName,
        mandatory: true
      },
      {
        key: "progShortName",
        name: "progShortName",
        component: InputField,
        label: "Program Short Name",
        onChange: onChange,
        value: values?.progShortName,
        mandatory: true
      },
      {
        key: "noOfCycle",
        name: "noOfCycle",
        component: InputField,
        label: "No of Cycles",
        type: "number",
        min: 0,
        max: 10,
        onChange: onChange,
        value: values?.noOfCycle,
        mandatory: true
      },
      {
        key: "refund",
        name: "refund",
        component: SelectField,
        label: "Refund Type",
        options: ["Refund", "Non-Refund"],
        onChange: onChange,
        value: values?.refund,
        mandatory: true
      },
      {
        key: "deptId",
        name: "deptId",
        component: InputField,
        label: "Dept ID",
        onChange: onChange,
        value: values?.deptId,
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAddModal ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const multiplierConfig = ({ isAdd, values, onChange, onClick }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Multiplier" : "Edit Multiplier",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "program",
        name: "program",
        component: SelectField,
        label: "Program ",
        onChange,
        value: values.program,
        options: ["Test1", "Test2"],
        mandatory: true
      },
      {
        key: "treatmentPlan",
        name: "treatmentPlan",
        component: SelectField,
        label: "Treatment Plan",
        onChange,
        value: values.treatmentPlan,
        options: ["Test1", "Test2"],
        mandatory: true
      },
      {
        key: "globalPrice",
        name: "globalPrice",
        component: InputField,
        label: "Global Price",
        onChange,
        value: values.globalPrice
      },
      {
        key: "assistPrice",
        name: "assistPrice",
        component: InputField,
        label: "Assist Price",
        onChange,
        value: values.assistPrice
      },
      {
        key: "ageMin",
        name: "ageMin",
        component: InputField,
        label: "Age Min",
        onChange,
        value: values.ageMin
      },
      {
        key: "ageMax",
        name: "ageMax",
        component: InputField,
        label: "Age Max",
        onChange,
        value: values.ageMax
      },
      {
        key: "multiplier",
        name: "multiplier",
        component: InputField,
        label: "Multiplier",
        onChange,
        value: values.multiplier
      },
      {
        key: "programPriceGlobal",
        name: "programPriceGlobal",
        component: InputField,
        label: "Program Price (Global)",
        onChange,
        value: values.programPriceGlobal
      },
      {
        key: "programPriceAssist",
        name: "programPriceAssist",
        component: InputField,
        label: "Program Price (Assist)",
        onChange,
        value: values.programPriceAssist
      },
      {
        key: "refund",
        name: "refund",
        component: InputField,
        label: "Refund%",
        onChange,
        value: values.refund
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick
      }
    ]
  };
};

export const addressConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Address" : "Edit Address",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "streetLine1",
        name: "streetLine1",
        component: InputField,
        label: "Street Line1",
        onChange: onChange,
        value: values.streetLine1
      },
      {
        key: "streetLine2",
        name: "streetLine2",
        component: InputField,
        label: "Street Line2",
        onChange: onChange,
        value: values.streetLine2
      },
      {
        key: "city",
        name: "city",
        component: InputField,
        label: "City",
        onChange: onChange,
        value: values.city,
        mandatory: true
      },
      {
        key: "state",
        name: "state",
        component: SelectField,
        label: "State",
        onChange: onChange,
        value: values?.state,
        mandatory: true,
        options: usStates
      },
      {
        key: "zip",
        name: "zip",
        component: InputField,
        label: "Zip",
        onChange: onChange,
        value: values?.zip,
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};
export const personNumberConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "Add Person Number" : "Edit Person Number",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },

    modalForm: [
      {
        key: "number",
        name: "number",
        component: InputField,
        label: "Number",
        onChange: onChange,
        value: values?.personKey,
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const locationConfig = ({ isAdd, values, onChange, onClick, disabled, optionData }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Location" : "Edit Location",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "name",
        name: "name",
        component: InputField,
        label: "Name",
        onChange: onChange,
        value: values?.name,
        mandatory: true
      },
      {
        key: "longName",
        name: "longName",
        component: InputField,
        label: "Long Name",
        onChange: onChange,
        value: values?.longName,
        mandatory: true
      },
      {
        key: "relationship",
        name: "relationship",
        component: SelectField,
        label: "Relationship",
        onChange: onChange,
        value: values?.relationship,
        options: ["Affiliate", "Partner"],
        mandatory: true
      },
      {
        key: "ivfPaymentTiming",
        name: "ivfPaymentTiming",
        component: SelectField,
        label: "IVF Payment Timing",
        onChange: onChange,
        value: values?.ivfPaymentTiming,
        options: optionData
      },
      {
        key: "fetPaymentTiming",
        name: "fetPaymentTiming",
        component: SelectField,
        label: "FET Payment Timing",
        onChange: onChange,
        value: values?.fetPaymentTiming,
        options: optionData
      },
      {
        key: "freezeAllPaymentTiming",
        name: "freezeAllPaymentTiming",
        component: SelectField,
        label: "Freeze All Payment Timing",
        onChange: onChange,
        value: values?.freezeAllPaymentTiming,
        options: optionData
      },
      {
        key: "gl",
        name: "gl",
        component: InputField,
        label: "GL#",
        onChange: onChange,
        value: values?.gl
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const treatmentPlanConfig = ({
  isAdd,
  values,
  onChange,
  //setEffectiveDate,
  onClick,
  disabled
}) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New TreatmentPlan" : "Edit TreatmentPlan",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "treatmentPlan",
        name: "treatmentPlan",
        component: InputField,
        label: "Plan Name",
        onChange: onChange,
        value: values.treatmentPlan,
        mandatory: true
      }
      // {
      //   key: "effectiveDate",
      //   name: "effectiveDate",
      //   component: DatePickerFieldCustom,
      //   label: "Effective Date",
      //   setEffectiveDate: setEffectiveDate,
      //   value: values.effectiveDate,
      //   mandatory: false
      // }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const physicianConfig = ({ isAdd, values, onChange, onClick, disabled, optionData }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Physician" : "Edit Physician",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "firstName",
        name: "firstName",
        component: InputField,
        label: "First Name",
        onChange: onChange,
        value: values.firstName,
        mandatory: true
      },
      {
        key: "lastName",
        name: "lastName",
        component: InputField,
        label: "Last Name",
        onChange: onChange,
        value: values.lastName,
        mandatory: true
      },
      {
        key: "emailAddress",
        name: "emailAddress",
        component: InputField,
        label: "Email",
        onChange: onChange,
        value: values.emailAddress,
        mandatory: true
      },
      {
        key: "npiNo",
        name: "npiNo",
        component: InputField,
        label: "NPI No",
        onChange: onChange,
        value: values?.npiNo
      },
      {
        key: "externalId",
        name: "externalId",
        component: InputField,
        label: "Next Gen External ID",
        onChange: onChange,
        value: values?.externalId
      },
      {
        key: "fertExternalId",
        name: "fertExternalId",
        component: InputField,
        label: "Fert External ID",
        onChange: onChange,
        value: values?.fertExternalId
      },
      {
        key: "locations",
        name: "locations",
        component: LocationSelectField,
        label: "Location",
        onChange: onChange,
        options: optionData,
        value: values?.locations
      },
      {
        key: "status",
        name: "status",
        component: SelectField,
        label: "Status",
        onChange: onChange,
        value: values?.status,
        options: ["Active", "Inactive"],
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const serviceItemsConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Service Item" : "Edit Service Item",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "serviceCode",
        name: "serviceCode",
        component: InputField,
        label: "Service Item ID/CPT 4 Code",
        onChange: onChange,
        value: values?.serviceCode,
        mandatory: true
      },
      {
        key: "serviceDesc",
        name: "serviceDesc",
        component: InputField,
        label: "Description",
        onChange: onChange,
        value: values.serviceDesc,
        mandatory: true
      },
      {
        key: "unitPrice",
        name: "unitPrice",
        component: InputField,
        label: "Current Price",
        onChange: onChange,
        value: values.unitPrice,
        mandatory: true
        // warningMessage: "Please enter rounded values nearest to 100 in Unit Price."
      },
      {
        key: "stagePassthrough",
        name: "stagePassthrough",
        component: CheckBoxField,
        label: "Passthrough Fee",
        onChange: onChange,
        value: values?.stagePassthrough
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};
export const stageConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Stage" : "Edit Stage",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "stageCode",
        name: "stageCode",
        component: InputField,
        label: "Stage code/SIM group",
        onChange: onChange,
        value: values?.stageCode,
        mandatory: true
      },
      {
        key: "stageName",
        name: "stageName",
        component: InputField,
        label: "Description",
        onChange: onChange,
        value: values?.stageName,
        mandatory: true
      },
      {
        key: "stageGroup",
        name: "stageGroup",
        component: InputField,
        label: "Stage Group",
        onChange: onChange,
        value: values?.stageGroup
      },
      {
        key: "sortStage",
        name: "sortStage",
        component: InputField,
        label: "Sort Order",
        onChange: onChange,
        value: values?.sortStage,
        mandatory: true
      },
      {
        key: "stageCategory",
        name: "stageCategory",
        component: SelectField,
        label: "Stage Category",
        onChange: onChange,
        value: values?.stageCategory,
        options: [
          { id: "Included", name: "Included" },
          { id: "Necessary", name: "Necessary but Not Included" },
          { id: "Optional", name: "Optional/May Become Necessary" }
        ]
      },

      {
        key: "isrange",
        name: "isrange",
        component: CheckBoxField,
        label: "Is Range ",
        onChange: onChange,
        value: values?.isrange
      },
      {
        key: "stagePassthrough",
        name: "stagePassthrough",
        component: CheckBoxField,
        label: "Is Passthrough Fee ",
        onChange: onChange,
        value: values?.stagePassthrough
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const editStageConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  let modalFrom = [
    {
      key: "stageCode",
      name: "stageCode",
      component: InputField,
      label: "Stage code/SIM group",
      onChange: onChange,
      value: values?.stageCode,
      mandatory: true
    },
    {
      key: "stageName",
      name: "stageName",
      component: InputField,
      label: "Description",
      onChange: onChange,
      value: values?.stageName,
      mandatory: true
    },
    {
      key: "sortStage",
      name: "sortStage",
      component: InputField,
      label: "Sort Order",
      onChange: onChange,
      value: values?.sortStage,
      mandatory: true
    },
    {
      key: "stageGroup",
      name: "stageGroup",
      component: InputField,
      label: "Stage Group",
      onChange: onChange,
      value: values?.stageGroup
    },
    {
      key: "stageCategory",
      name: "stageCategory",
      component: SelectField,
      label: "Stage Category",
      onChange: onChange,
      value: values?.stageCategory,
      options: [
        { id: "Included", name: "Included" },
        { id: "Necessary", name: "Necessary but Not Included" },
        { id: "Optional", name: "Optional/May Become Necessary" }
      ]
    },

    {
      key: "isrange",
      name: "isrange",
      component: CheckBoxField,
      label: "Is Range ",
      onChange: onChange,
      value: values?.isrange
    }
  ];
  if (values.stageCategory === "Included") {
    modalFrom = [
      {
        key: "stageCode",
        name: "stageCode",
        component: InputField,
        label: "Stage code/SIM group",
        onChange: onChange,
        value: values?.stageCode,
        mandatory: true
      },
      {
        key: "stageName",
        name: "stageName",
        component: InputField,
        label: "Description",
        onChange: onChange,
        value: values?.stageName,
        mandatory: true
      },
      {
        key: "sortStage",
        name: "sortStage",
        component: InputField,
        label: "Sort Order",
        onChange: onChange,
        value: values?.sortStage,
        mandatory: true
      },
      {
        key: "stageGroup",
        name: "stageGroup",
        component: InputField,
        label: "Stage Group",
        onChange: onChange,
        value: values?.stageGroup
      },
      {
        key: "stageCategory",
        name: "stageCategory",
        component: SelectField,
        label: "Stage Category",
        onChange: onChange,
        value: values?.stageCategory,
        options: [
          { id: "Included", name: "Included" },
          { id: "Necessary", name: "Necessary but Not Included" },
          { id: "Optional", name: "Optional/May Become Necessary" }
        ]
      },
      {
        key: "globalPrice",
        name: "globalPrice",
        component: InputField,
        label: "Global Stage Price",
        onChange: onChange,
        value: values?.globalPrice
      },
      {
        key: "isrange",
        name: "isrange",
        component: CheckBoxField,
        label: "Is Range ",
        onChange: onChange,
        value: values?.isrange
      }
    ];
  }

  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Stage" : "Edit Stage",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: modalFrom,
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const treatmentPlanDetailsConfig = ({ isAdd, values, onChange, onClick }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: "Edit",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "treatmentName",
        name: "treatmentName",
        component: InputField,
        label: "Treatment Name",
        onChange: onChange,
        value: values?.treatmentName,
        mandatory: true
      },
      {
        key: "treatmentInternalName",
        name: "treatmentInternalName",
        component: InputField,
        label: "Treatment Internal Name",
        onChange: onChange,
        value: values?.treatmentInternalName,
        disabled: true
      },
      {
        key: "totalTreatmentPrice",
        name: "totalTreatmentPrice",
        component: InputField,
        label: "Total Treatment Price",
        onChange: onChange,
        value: values?.totalTreatmentPrice,
        disabled: true
      },
      {
        key: "globalTreatmentPrice",
        name: "globalTreatmentPrice",
        component: InputField,
        label: "Global Treatment Price",
        onChange: onChange,
        value: values?.globalTreatmentPrice,
        disabled: true
      },
      {
        key: "assitTreatmentPrice",
        name: "assitTreatmentPrice",
        component: InputField,
        label: "Assist Treatment Price",
        onChange: onChange,
        value: values?.assitTreatmentPrice,
        disabled: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick
        // disabled: disabled
      }
    ]
  };
};

export const externalIdConfig = ({
  isAdd,
  values,
  onChange,
  onClick,
  disabled,
  isDefaultPracticeId
}) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New External Id" : "Edit External Id",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "externalSystem",
        name: "externalSystem",
        component: SelectField,
        label: "External System",
        onChange: onChange,
        value: values?.externalSystem,
        mandatory: true,
        options: externalIdoptions
      },
      {
        key: "externalPracticeId",
        name: "externalPracticeId",
        component: InputField,
        label: "External Practice ID",
        onChange: onChange,
        value: values.externalPracticeId,
        disabled: isDefaultPracticeId
      },
      {
        key: "externalLocationId",
        name: "externalLocationId",
        component: InputField,
        label: "External Location ID",
        onChange: onChange,
        value: values.externalLocationId
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const treatmentPlanStageConfig = ({ isAdd, values, onChange, onClick }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: "Edit",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "globalPrice",
        name: "globalPrice",
        component: InputField,
        label: "Global Price",
        onChange: onChange,
        value: values?.globalPrice,
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick
        // disabled: disabled
      }
    ]
  };
};

export const relativeValueConfig = ({
  isAdd,
  values,
  onChange,
  onClick,
  disabled,
  options,
  relativeTypeList,
  setEffectiveDate,
  setEndDate,
  cycleNumbers
}) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Relative Value" : "Edit Relative Value",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "relativeValueNumber",
        name: "relativeValueNumber",
        component: InputField,
        label: "Relative Value %",
        onChange: onChange,
        value: values?.relativeValueNumber,
        mandatory: true
      },
      {
        key: "program",
        name: "program",
        component: SelectField,
        label: "Program",
        onChange: onChange,
        value: values?.program,
        options: options && options.length > 0 ? options : [],
        mandatory: true
      },
      {
        key: "type",
        name: "type",
        component: SelectField,
        label: "Type",
        onChange: onChange,
        value: values?.type,
        // options: ["fresh", "frozen"],
        options: relativeTypeList,
        mandatory: true
      },
      {
        key: "effectiveDate",
        name: "effectiveDate",
        component: DatePickerFieldCustom,
        label: "Effective Date",
        setEffectiveDate: setEffectiveDate,
        value: values.effectiveDate,
        mandatory: true
      },
      {
        key: "endDate",
        name: "endDate",
        component: DatePickerFieldCustom,
        label: "End Date",
        setEffectiveDate: setEndDate,
        value: values.endDate,
        mandatory: true
      },
      {
        key: "donor",
        name: "donor",
        component: CheckBoxField,
        label: "Donor",
        onChange: onChange,
        value: values?.donor
      },
      {
        key: "cycleNumber",
        name: "cycleNumber",
        component: SelectField,
        label: "Cycle No.",
        onChange: onChange,
        value: values?.cycleNumber,
        options: cycleNumbers,
        mandatory: values?.type && values.type === "PGT-A Analysis" ? true : false
      }
      /* {
        key: "cycleNumber",
        name: "cycleNumber",
        component: InputField,
        label: "Cycle No.",
        onChange: onChange,
        value: values?.cycleNumber,
        mandatory: values?.type && values.type === "PGT-A Analysis" ? true : false
      } */
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const commentConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Comment" : "Edit Comment",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "comment",
        name: "comment",
        component: InputField,
        label: "Comment",
        onChange: onChange,
        value: values?.comment,
        mandatory: true
      },
      {
        key: "commentType",
        name: "commentType",
        component: SelectField,
        label: "Comment Type",
        onChange: onChange,
        value: values?.commentType,
        options: ["Clinical", "Financial", "Patient Communication"],
        mandatory: true
      },
      {
        key: "markImp",
        name: "markImp",
        component: CheckBoxField,
        label: "Mark Important",
        onChange: onChange,
        value: values?.markImp,
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

// export const statusConfig = ({ isAdd, values, onChange, onClick, disabled, isActive }) => {
//   return {
//     modalHeader: {
//       key: "modalHeader",
//       title:
//         isActive == "Active"
//           ? "Do you want to Inactivate Medcase Status?"
//           : "Do you want to Activate Medcase Status",
//       id: "modalHeaderId",
//       onClose: onClick,
//       component: HeaderField
//     },
//     modalForm: [
//       {
//         key: "status",
//         name: "status",
//         component: TypographyField,
//         value:
//           isActive == "Active"
//             ? "Do you want to Inactivate Medcase Status?"
//             : "Do you want to Activate Medcase Status"
//       },
//       {
//         key: "reason",
//         name: "reason",
//         component: InputField,
//         label: "Reason",
//         onChange: onChange,
//         value: values?.reason,
//         options: ["fresh", "frozen"],
//         mandatory: true
//       }
//     ],
//     modalActions: [
//       {
//         key: "save",
//         id: "save",
//         label: isAdd ? "Create" : "Save",
//         varient: "contained",
//         component: ActionButton,
//         onClose: onClick,
//         disabled: disabled
//       }
//     ]
//   };
// };

export const finSnapshotConfig = ({ isAdd, values, onChange, onClick, setValues, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "Add Program Fee" : "Add Program Fee",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "netProgramFee",
        name: "netProgramFee",
        component: InputField,
        label: "Net Program Fee",
        onChange: onChange,
        value: values?.netProgramFee,
        // mandatory: true,
        disabled: true
      },
      {
        key: "feeType",
        name: "feeType",
        component: SelectField,
        label: "Fee Type",
        onChange: onChange,
        value: values?.feeType,
        options: ["Main Program Fee", "ICSI", "Monitoring", "Miscellaneous"],
        mandatory: true
      },
      {
        key: "amount",
        name: "amount",
        component: InputField,
        label: "Amount",
        onChange: onChange,
        value: values?.amount,
        mandatory: true
      },
      {
        key: "addRadio",
        name: "addRadio",
        component: RadioField,
        label: "Add",
        onChange: onChange,
        value: values?.addRadio
      },
      {
        key: "subRadio",
        name: "subRadio",
        component: RadioField,
        label: "Subtract",
        onChange: onChange,
        value: values?.subRadio
      },
      {
        key: "recordedDate",
        name: "recordedDate",
        component: DatePickerField,
        label: "Recorded Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "recordedDate"
      },
      {
        key: "transactionDate",
        name: "transactionDate",
        component: DatePickerField,
        label: "Transaction Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "transactionDate",
        mandatory: true
      },
      {
        key: "comment",
        name: "comment",
        component: InputField,
        label: "Comment",
        onChange: onChange,
        value: values?.comment
      },
      {
        key: "markImp",
        name: "markImp",
        component: CheckBoxField,
        label: "Mark Important",
        onChange: onChange,
        value: values?.markImp
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const providerReimbursementConfig = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "Medcase Status Change" : "Edit Comment",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "reason",
        name: "reason",
        component: InputField,
        label: "Reason",
        onChange: onChange,
        value: values?.reason,
        options: ["fresh", "frozen"],
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const address1Config = ({
  isAdd,
  values,
  onChange,
  onClick,
  disabled,
  inputNames = {},
  options
}) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "Add Address" : "Edit Address",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "type",
        name: "type",
        component: InputField,
        label: "Type",
        onChange: onChange,
        value: values?.type,
        options: ["Billing", "Mailing"],
        disabled: true
      },
      {
        key: inputNames.line1,
        name: inputNames.line1,
        component: InputField,
        label: "Street 1",
        onChange: onChange,
        mandatory: true,
        value: values[inputNames.line1]
      },
      {
        key: inputNames.line2,
        name: inputNames.line2,
        component: InputField,
        label: "Street 2",
        onChange: onChange,
        value: values[inputNames.line2]
      },
      {
        key: inputNames.city,
        name: inputNames.city,
        component: InputField,
        label: "City",
        onChange: onChange,
        mandatory: true,
        value: values[inputNames.city]
      },

      {
        key: inputNames.state,
        name: inputNames.state,
        component: SelectField,
        label: "State",
        onChange: onChange,
        mandatory: true,
        value: values[inputNames.state],
        options: options
      },
      {
        key: inputNames.zip,
        name: inputNames.zip,
        component: InputField,
        label: "Zip",
        onChange: onChange,
        mandatory: true,
        value: values[inputNames.zip],
        type: "number"
      },
      {
        key: inputNames.default,
        name: inputNames.default,
        component: CheckBoxField,
        label: "Default",
        onChange: onChange,
        defaultChecked: values?.defaultAddress == values.type ? true : false
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

function manageTransactionActions(transactionDate) {
  // Get the current date with Moment.js
  const currentDate = moment();

  // Parse the transaction date with Moment.js
  const postedDate = moment(transactionDate);

  // Calculate the last date for editing/deleting: 10th of the following month
  const editDeleteDeadline = postedDate.clone().add(1, "months").date(10);
  console.log("editDeleteDeadline", editDeleteDeadline);

  // Initialize button states as disabled by default

  let editEnabled = false;

  // Enable Delete and Edit if user has "eft" role and within allowed date range
  if (isAccess("eft") && currentDate.isSameOrBefore(editDeleteDeadline)) {
    editEnabled = true;
  }

  // Return functions for external use (like posting a transaction)
  return editEnabled;
}

export const transactionDetailsConfig = ({ values, onChange, onClick, setValues, onEditClick }) => {
  console.log("values", values);
  let chkEditDeleteFeatures = isAccess("eft");
  let chkNegativeAmountFeatures = isAccess("pnft");
  console.log("chkEditFeatures", chkEditDeleteFeatures, chkNegativeAmountFeatures);

  let isEnable = manageTransactionActions(values.transactionDate);
  console.log("isEnabke", isEnable);

  return {
    modalHeader: {
      key: "modalHeader",
      title: "Transaction Details",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "feeType",
        name: "feeType",
        component: InputField,
        label: "Fee Type",
        onChange: onChange,
        value: values?.feeType,
        mandatory: true,
        disabled: !isEnable
      },
      {
        key: "amount",
        name: "amount",
        component: InputField,
        label: "Amount",
        onChange: onChange,
        // value: !isEnable ? values?.amount : values?.originalAmount,
        value: values?.amount,
        mandatory: true,
        disabled: !isEnable
      },
      {
        key: "user",
        name: "user",
        component: InputField,
        label: "User",
        onChange: onChange,
        value: values?.user,
        disabled: true
      },
      {
        key: "recordedDate",
        name: "recordedDate",
        component: InputField,
        label: "Recorded Date",
        onChange: onChange,
        value: values.recordedDate,
        setValues,
        fieldName: "recordedDate",
        disabled: true
      },
      {
        key: "transactionDate",
        name: "transactionDate",
        component: InputField,
        label: "Transaction Date",
        onChange: onChange,
        value: values.transactionDate,
        setValues,
        fieldName: "transactionDate",
        disabled: true
      },
      {
        key: "comment",
        name: "comment",
        component: InputField,
        label: "Comment",
        onChange: onChange,
        value: values.comment,
        disabled: !isEnable
      }
    ],
    modalActions: [
      {
        key: "delete",
        id: "delete",
        label: "Delete",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        //disabled: !moment().isSame(moment(values?.transactionDate), "day")
        disabled: !isEnable,
        onEditClick: onEditClick,
        isEdit: true
      }
    ]
  };
};

export const phoneConfig = ({ isAdd, values, onChange, onClick, disabled, defaultType }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "Add Phone" : "Edit Phone",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },

    modalForm: [
      {
        key: "type",
        name: "type",
        component: InputField,
        label: "Type",
        onChange: onChange,
        value: values?.type,
        options: ["Home", "Work", "Cell"],
        disabled: true
      },
      {
        key: "number",
        name: "number",
        component: InputField,
        label: "Number",
        onChange: onChange,
        value: values?.number,
        type: "number"
      },
      {
        key: defaultType,
        name: defaultType,
        component: CheckBoxField,
        label: "Default",
        onChange: onChange,
        defaultChecked: values?.defaultPhone == values?.defaultChecked ? true : false
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const patientPaymentDetailsConfig = ({ values, onChange, onClick, disabled, setValues }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: "Delete PatientPayment Details",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "paymentType",
        name: "paymentType",
        component: SelectField,
        label: "Payment Type",
        onChange: onChange,
        value: values?.feeType,
        options: ["Main Program Fee", "ICSI", "Monitoring", "Miscellaneous"],
        mandatory: true
      },
      {
        key: "amount",
        name: "amount",
        component: InputField,
        label: "Amount",
        onChange: onChange,
        value: values?.amount,
        mandatory: true
      },
      {
        key: "user",
        name: "user",
        component: InputField,
        label: "User",
        onChange: onChange,
        value: values?.user
      },
      {
        key: "recordedDate",
        name: "recordedDate",
        component: DatePickerField,
        label: "Recorded Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "recordedDate"
      },
      {
        key: "transactionDate",
        name: "transactionDate",
        component: DatePickerField,
        label: "Transaction Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "transactionDate"
      },
      {
        key: "comment",
        name: "comment",
        component: InputField,
        label: "Comment",
        onChange: onChange,
        value: values?.comment
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: "Create",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const email1Config = ({ isAdd, values, onChange, onClick, disabled }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "Add Email" : "Edit Email",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "email",
        name: "email",
        component: InputField,
        label: "Email",
        onChange: onChange,
        value: values?.email
      },
      {
        key: "default",
        name: "default",
        component: CheckBoxField,
        label: "Default",
        onChange: onChange,
        defaultChecked: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const partnerConfig = ({ isAdd, values, onChange, onClick, disabled, setValues }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "Add Partner Info" : "Edit Address",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "firstName",
        name: "firstName",
        component: InputField,
        label: "First Name",
        onChange: onChange,
        value: values?.firstName,
        mandatory: true
      },
      {
        key: "lastName",
        name: "lastName",
        component: InputField,
        label: "Last Name",
        onChange: onChange,
        value: values?.lastName,
        mandatory: true
      },
      {
        key: "dob",
        name: "dob",
        component: DatePickerFieldDOB,
        label: "DOB",
        onChange: onChange,
        value: values,
        setValues,
        mandatory: true,
        fieldName: "dob"
      },
      {
        key: "phoneType",
        name: "phoneType",
        component: SelectField,
        label: "Phone Type",
        onChange: onChange,
        value: values?.phoneType,
        options: ["home", "work", "cell", "add_cell"]
      },
      {
        key: "phoneNumber",
        name: "phoneNumber",
        component: InputField,
        label: "Phone Number",
        onChange: onChange,
        value: values?.phoneNumber,
        type: "number"
      },
      {
        key: "email",
        name: "email",
        component: InputField,
        label: "Email",
        onChange: onChange,
        value: values?.email,
        mandatory: true
      },
      {
        key: "addressType",
        name: "addressType",
        component: SelectField,
        label: "Address Type",
        onChange: onChange,
        value: values?.addressType,
        options: ["billing", "mailing"]
      },
      {
        key: "addressLine1",
        name: "addressLine1",
        component: InputField,
        label: "Address Line 1",
        onChange: onChange,
        value: values?.addressLine1,
        mandatory: true
      },
      {
        key: "addressLine2",
        name: "addressLine2",
        component: InputField,
        label: "Address Line 2",
        onChange: onChange,
        value: values?.addressLine2
      },
      {
        key: "city",
        name: "city",
        component: InputField,
        label: "City",
        onChange: onChange,
        value: values?.city,
        mandatory: true
      },
      {
        key: "state",
        name: "state",
        component: SelectField,
        label: "State",
        onChange: onChange,
        value: values?.state,
        options: usStates,
        mandatory: true
      },
      {
        key: "zip",
        name: "zip",
        component: InputField,
        label: "Zip",
        onChange: onChange,
        value: values?.zip,
        mandatory: true
      },
      {
        key: "country",
        name: "country",
        component: InputField,
        label: "Country",
        onChange: onChange,
        value: values?.country,
        mandatory: true
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};
export const refundDetailsConfig = ({ values, onChange, onClick, setValues, amount }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: "Refund Transaction Details",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "transactionDate",
        name: "transactionDate",
        component: DatePickerField,
        label: "Transaction Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "transactionDate",
        mandatory: true
      },

      {
        key: "medcaseId",
        name: "medcaseId",
        component: InputField,
        label: "Medcase Id",
        onChange: onChange,
        value: values?.medcaseId
      },
      {
        key: "transactionType",
        name: "transactionType",
        component: SelectField,
        label: "Refund Type",
        onChange: onChange,
        value: values?.refundType,
        options: ["Program Refund", "Pregnancy Loss Refund", "Miscellaneous Refund"],
        mandatory: true
      },
      {
        key: "amount",
        name: "amount",
        component: InputField,
        label: "Amount",
        onChange: onChange,
        value: values?.amount,
        mandatory: true
      },
      {
        key: "recordedDate",
        name: "recordedDate",
        component: DatePickerField,
        label: "Recorded Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "recordedDate",
        mandatory: true
      },
      {
        key: "isImportant",
        name: "isImportant",
        component: CheckBoxField,
        label: "Mark Important",
        onChange: onChange,
        value: values?.isImportant
      },
      {
        key: "comment",
        name: "comment",
        component: InputField,
        label: "Comment",
        onChange: onChange,
        value: values?.comment
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled:
          values.transactionDate &&
          values.transactionType &&
          (amount || values?.amount) &&
          values.recordedDate
            ? false
            : true
      }
    ]
  };
};

export const delRefundTransactionDetailsConfig = ({ values, onChange, onClick, onEditClick }) => {
  console.log("values Refund", values);
  let chkEditDeleteFeatures = isAccess("eft");
  let chkNegativeAmountFeatures = isAccess("pnft");
  console.log("chkEditFeatures Refund", chkEditDeleteFeatures, chkNegativeAmountFeatures);

  let isEnable = manageTransactionActions(values.transactionDate);
  console.log("isEnabke Refund", isEnable);
  return {
    modalHeader: {
      key: "modalHeader",
      title: "Transaction Details",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "refundType",
        name: "refundType",
        component: InputField,
        label: "Revenue Type",
        onChange: onChange,
        value: values?.refundType,
        disabled: true
      },
      {
        key: "cycle",
        name: "cycle",
        component: InputField,
        label: "Cycle #",
        onChange: onChange,
        value: values?.cycle,
        // mandatory: true,
        disabled: !isEnable
      },
      {
        key: "amount",
        name: "amount",
        component: InputField,
        label: "Amount",
        onChange: onChange,
        // value: !isEnable ? values?.amount : values?.originalAmount,
        value: values?.amount,
        disabled: !isEnable
      },
      {
        key: "user",
        name: "user",
        component: InputField,
        label: "User",
        onChange: onChange,
        value: values?.user,
        disabled: true
      },
      {
        key: "recordedDate",
        name: "recordedDate",
        component: InputField,
        label: "Recorded Date",
        value: values.recordedDate,
        fieldName: "recordedDate",
        disabled: true
      },
      {
        key: "transactionDate",
        name: "transactionDate",
        component: InputField,
        label: "Transaction Date",
        value: values.transactionDate,
        fieldName: "transactionDate",
        disabled: true
      },
      {
        key: "comment",
        name: "comment",
        component: InputField,
        label: "Comment",
        onChange: onChange,
        // value: values.isImportant ? values.comment : "",
        value: values?.comment,
        disabled: !isEnable
      }
    ],
    modalActions: [
      {
        key: "delete",
        id: "delete",
        label: "Delete",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        // disabled:
        //   new Date(values?.transactionDate).getUTCMonth() === new Date().getUTCMonth() &&
        //   new Date(values?.transactionDate).getFullYear() === new Date().getFullYear()
        //     ? false
        //     : true
        disabled: !isEnable,
        onEditClick: onEditClick,
        isEdit: true
      }
    ]
  };
};

export const addNewManageLabConfig = ({ values, onChange, onClick, disabled, isAdd, options }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Lab" : "Edit Lab",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "labName",
        name: "labName",
        component: InputField,
        label: "NAME*",
        onChange: onChange,
        value: values?.labName
      },
      {
        key: "labAddress",
        name: "labAddress",
        component: InputField,
        label: "ADDRESS",
        onChange: onChange,
        value: values?.labAddress
      },
      {
        key: "city",
        name: "city",
        component: InputField,
        label: "CITY*",
        onChange: onChange,
        value: values?.city
      },
      {
        key: "state",
        name: "state",
        component: SelectField,
        label: "STATE*",
        onChange: onChange,
        value: values?.state,
        options: options
      },
      {
        key: "zip",
        name: "zip",
        component: InputField,
        label: "ZIP*",
        onChange: onChange,
        value: values?.zip
      },
      {
        key: "labPhone",
        name: "labPhone",
        component: InputField,
        label: "PHONE*",
        onChange: onChange,
        value: values?.labPhone
      },
      {
        key: "labEmail",
        name: "labEmail",
        component: InputField,
        label: "EMAIL*",
        onChange: onChange,
        value: values?.labEmail
      },
      {
        key: "contactFName",
        name: "contactFName",
        component: InputField,
        label: "CONTACT FIRST NAME*",
        onChange: onChange,
        value: values?.contactFName
      },
      {
        key: "contactLName",
        name: "contactLName",
        component: InputField,
        label: "CONTACT LAST NAME*",
        onChange: onChange,
        value: values?.contactLName
      },
      {
        key: "isActive",
        name: "isActive",
        component: SelectField,
        label: "STATUS*",
        options: ["Active", "Inactive"],
        onChange: onChange,
        value: values?.isActive
      }
    ],
    modalActions: [
      {
        key: "add",
        id: "add",
        label: isAdd ? "Add" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const delPRTransactionDetailsConfig = ({
  values,
  onChange,
  onClick,
  setValues,
  onEditClick
}) => {
  console.log("values PR", values);
  let chkEditDeleteFeatures = isAccess("eft");
  let chkNegativeAmountFeatures = isAccess("pnft");
  console.log("chkEditFeatures PR", chkEditDeleteFeatures, chkNegativeAmountFeatures);

  let isEnable = manageTransactionActions(values.transactionDate);
  console.log("isEnabke PR", isEnable);
  return {
    modalHeader: {
      key: "modalHeader",
      title: "Transaction Details",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "transactionType",
        name: "transactionType",
        component: InputField,
        label: "Transaction Type",
        onChange: onChange,
        value: values?.transactionType,
        disabled: true
      },
      {
        key: "cycle",
        name: "cycle",
        component: InputField,
        label: "Cycle #",
        onChange: onChange,
        value: values?.cycle,
        disabled: !isEnable
      },
      {
        key: "amount",
        name: "amount",
        component: InputField,
        label: "Amount",
        onChange: onChange,
        value: !isEnable ? values?.amount : values?.originalAmount,
        disabled: !isEnable
      },
      {
        key: "user",
        name: "user",
        component: InputField,
        label: "User",
        onChange: onChange,
        value: values?.user,
        disabled: true
      },
      {
        key: "recordedDate",
        name: "recordedDate",
        component: InputField,
        label: "Recorded Date",
        value: values.recordedDate,
        setValues,
        fieldName: "recordedDate",
        disabled: true
      },
      {
        key: "transactionDate",
        name: "transactionDate",
        component: InputField,
        label: "Transaction Date",
        value: values.transactionDate,
        setValues,
        fieldName: "transactionDate",
        disabled: true
      },
      {
        key: "comment",
        name: "comment",
        component: InputField,
        label: "Comment",
        onChange: onChange,
        // value: values.isImportant ? values.comment : "",
        value: values?.comment,
        disabled: !isEnable
      }
    ],
    modalActions: [
      {
        key: "delete",
        id: "delete",
        label: "Delete",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        // disabled:
        // new Date(values?.transactionDate).getUTCMonth() === new Date().getUTCMonth() &&
        // new Date(values?.transactionDate).getFullYear() === new Date().getFullYear()
        //   ? false
        //   : true
        disabled: !isEnable,
        onEditClick: onEditClick,
        isEdit: true
      }
    ]
  };
};

export const revenueDetailsConfig = ({
  values,
  onChange,
  onClick,
  disabled,
  setValues,
  options,
  isPGTA,
  isCommentReq
}) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: "Revenue Transaction Details",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "cycle",
        name: "cycle",
        component: SelectField,
        label: "Cycle",
        onChange: onChange,
        value: values?.cycle,
        options: options,
        mandatory: isPGTA,
        disabled: isPGTA
      },
      {
        key: "transactionType",
        name: "transactionType",
        component: SelectField,
        label: "Revenue Type",
        onChange: onChange,
        value: values?.revenueType,
        options: ["Correction Revenue", "Completion Revenue", "Service Revenue", "Success Revenue"],
        mandatory: true
      },
      {
        key: "amount",
        name: "amount",
        component: InputField,
        label: "Amount",
        onChange: onChange,
        value: values?.amount,
        mandatory: true
      },
      {
        key: "transactionDate",
        name: "transactionDate",
        component: DatePickerField,
        label: "Transaction Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "transactionDate",
        mandatory: true
      },

      {
        key: "medcaseId",
        name: "medcaseId",
        component: InputField,
        label: "Medcase Id",
        onChange: onChange,
        value: values?.medcaseId
      },
      {
        key: "recordedDate",
        name: "recordedDate",
        component: DatePickerField,
        label: "Recorded Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "recordedDate"
      },
      {
        key: "isImportant",
        name: "isImportant",
        component: CheckBoxField,
        label: "Mark Important",
        onChange: onChange,
        value: values?.isImportant
      },
      {
        key: "comment",
        name: "comment",
        component: InputField,
        label: "Comment",
        onChange: onChange,
        value: values?.comment,
        mandatory: isCommentReq,
        required: isCommentReq
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const contractTemplateMappingConfig = ({
  isAdd,
  values,
  setValues,
  onChange,
  onClick,
  disabled,
  options,
  option,
  treatmentPlanOptions
}) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Contract Template Mapping" : "Edit Contract Template Mapping",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },

    modalForm: [
      {
        key: "programName",
        name: "programName",
        component: SelectField,
        label: "Program",
        onChange: onChange,
        value: values?.programName,
        options: options && options.length > 0 ? options : [],
        mandatory: true
      },
      {
        key: "templateName",
        name: "templateName",
        component: SelectField,
        label: "Template",
        onChange: onChange,
        value: values?.templateName,
        options: option && option.length > 0 ? option : [],
        mandatory: true
      },
      {
        key: "effectiveDate",
        name: "effectiveDate",
        component: DateInputField,
        label: "Effective Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "effectiveDate",
        validations: { disablePast: true, disableFuture: false },
        mandatory: true
      },
      {
        key: "endDate",
        name: "endDate",
        component: DateInputField,
        label: "End Date",
        onChange: onChange,
        value: values,
        setValues,
        fieldName: "endDate",
        validations: { disablePast: true, disableFuture: false },
        mandatory: true
      },
      {
        key: "treatmentPlanId",
        name: "treatmentPlanId",
        component: SelectField,
        label: "Treatment Plan",
        onChange: onChange,
        value: values?.treatmentPlanId,
        options: treatmentPlanOptions,
        mandatory: true
      },
      {
        key: "partner",
        name: "partner",
        component: SelectField,
        label: "Partner",
        onChange: onChange,
        value: values?.partner,
        options: ["Y", "N"],
        mandatory: true
      }
    ],

    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};

export const editUserFilterConfig = ({ values, onChange, onClick }) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: "Edit User Data",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "sNo",
        name: "sNo",
        component: InputField,
        label: "SR NO",
        onChange: onChange,
        value: values?.sNo,
        disabled: true
      },
      {
        key: "userName",
        name: "userName",
        component: InputField,
        label: "USER NAME",
        onChange: onChange,
        value: values?.userName
      },
      {
        key: "emailId",
        name: "emailId",
        component: InputField,
        label: "EMAIL ID",
        onChange: onChange,
        value: values?.emailId
      },
      {
        key: "userType",
        name: "userType",
        component: InputField,
        label: "USER TYPE",
        onChange: onChange,
        value: values?.userType
      },
      {
        key: "role",
        name: "role",
        component: InputField,
        label: "ROLE",
        onChange: onChange,
        value: values?.role
      },
      {
        key: "practice",
        name: "practice",
        component: InputField,
        label: "PRACTICE",
        onChange: onChange,
        value: values?.practice
      }
    ],

    modalActions: [
      {
        key: "save",
        id: "save",
        label: "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick
      }
    ]
  };
};

export const emailTemplateConfig = ({
  isAdd,
  values,
  onChange,
  onClick,
  disabled,
  setTemplateBody,
  templateSlugs,
  emailVars
}) => {
  return {
    modalHeader: {
      key: "modalHeader",
      title: isAdd ? "New Email Template" : "Edit Email Template",
      id: "modalHeaderId",
      onClose: onClick,
      component: HeaderField
    },
    modalForm: [
      {
        key: "emailSlug",
        name: "emailSlug",
        component: SelectField,
        label: "Template Slug",
        onChange: onChange,
        value: values?.emailSlug,
        options: templateSlugs,
        mandatory: true,
        tooltip: "A unique string to identify Email Templates"
      },
      {
        key: "templateVariables",
        name: "templateVariables",
        component: DisplayField,
        label: "Template Variables",
        value: emailVars,
        tooltip: "List of dynamic fields which we can use in template body."
      },
      {
        key: "toRecipient",
        name: "toRecipient",
        component: InputField,
        label: "Additional To Recipient",
        onChange: onChange,
        value: values?.toRecipient,
        tooltip: "Additional To recipient who will get this E-Mail"
      },
      {
        key: "ccRecipient",
        name: "ccRecipient",
        component: InputField,
        label: "Additional CC Recipient",
        onChange: onChange,
        value: values?.ccRecipient,
        tooltip: "Additional CC recipient who will get this E-Mail"
      },
      {
        key: "bccRecipient",
        name: "bccRecipient",
        component: InputField,
        label: "Additional BCC Recipient",
        onChange: onChange,
        value: values?.bccRecipient,
        tooltip: "Additional BCC recipient who will get this E-Mail"
      },
      {
        key: "emailSubject",
        name: "emailSubject",
        component: InputField,
        label: "Subject",
        onChange: onChange,
        value: values?.emailSubject,
        mandatory: true
      },
      {
        key: "isActive",
        name: "isActive",
        component: SelectField,
        label: "Status",
        onChange: onChange,
        value: values?.isActive,
        options: [
          { name: "Active", id: "1" },
          { name: "Inactive", id: "0" }
        ],
        mandatory: true,
        disabled: isAdd ? true : false
      },
      {
        key: "emailBody",
        name: "emailBody",
        component: RichTextEditor,
        label: "Message Body",
        onChange: setTemplateBody,
        value: values?.emailBody,
        mandatory: true
        // mention: {
        //   separator: " ",
        //   trigger: "@",
        //   suggestions: [
        //     { text: "USER_NAME", value: "{{username}}" },
        //     { text: "USER_EMAIL", value: "{{useremail}}" },
        //     { text: "FIRST_NAME", value: "{{fname}}" }
        //   ]
        // }
      }
    ],
    modalActions: [
      {
        key: "save",
        id: "save",
        label: isAdd ? "Create" : "Save",
        varient: "contained",
        component: ActionButton,
        onClose: onClick,
        disabled: disabled
      }
    ]
  };
};
