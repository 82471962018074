import * as actionType from "../actions/ActionTypes";
const initialState = {
  financialSnapshotPaymentList: [],
  financialSnapshotPgtaList: [],
  error: null,
  emailData: [],
  sendEmail: null,
  success: null,
  loading: false,
  manualReviewSuccess: null,
  clinicalReviewSuccess: null,
  errorEmail: null
};
const financialSnapshotPaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FINANCIAL_SNAPSHOT_PAYMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.FINANCIAL_SNAPSHOT_PAYMENT_SUCCESS: {
      return {
        ...state,
        financialSnapshotPaymentList: action.payload,
        loading: false
      };
    }
    case actionType.FINANCIAL_SNAPSHOT_PAYMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.FINANCIAL_SNAPSHOT_PGTA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.FINANCIAL_SNAPSHOT_PGTA_SUCCESS: {
      return {
        ...state,
        financialSnapshotPgtaList: action.payload,
        loading: false
      };
    }
    case actionType.FINANCIAL_SNAPSHOT_PGTA_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.GET_EMAIL_OPTION_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_EMAIL_OPTION_SUCCESS: {
      return {
        ...state,
        emailData: action.payload,
        loading: false
      };
    }
    case actionType.GET_EMAIL_OPTION_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.POST_EMAIL_OPTION_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.POST_EMAIL_OPTION_SUCCESS: {
      return {
        ...state,
        sendEmail: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.POST_EMAIL_OPTION_FAIL: {
      return {
        ...state,
        loading: false,
        errorEmail: action.errorMsg
      };
    }
    case actionType.RESET_POST_EMAIL_OPTION: {
      return {
        ...state,
        errorEmail: null,
        success: false
      };
    }
    case actionType.UPDATE_MANUAL_REVIEW_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.UPDATE_MANUAL_REVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        manualReviewSuccess: action.successMsg
      };
    }
    case actionType.UPDATE_MANUAL_REVIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.UPDATE_CLINICAL_REVIEW_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.UPDATE_CLINICAL_REVIEW_SUCCESS: {
      return {
        ...state,
        loading: false,
        clinicalReviewSuccess: action.successMsg
      };
    }
    case actionType.UPDATE_CLINICAL_REVIEW_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.RESET_MANUAL_REVIEW: {
      return {
        ...state,
        error: null,
        manualReviewSuccess: false,
        clinicalReviewSuccess: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default financialSnapshotPaymentReducer;
