import Close from "@mui/icons-material/Close";
import { Box, Button, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonModal from "../../../shared/components/CommonModal";
import { addNewManageLabConfig } from "../../../shared/constants/renderConfigs";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { connect } from "react-redux";
import * as actionType from "../../actions";
import { v4 as uuidv4 } from "uuid";
import MessageModal from "../../../shared/components/MessageModal";
import { enumUsStates } from "../../../shared/constants/constants";
import DataGridPremiumComp from "../../../shared/components/DataGridPremiumComp";

const ManageLabModal = (props) => {
  const { open, handleClose, actions, manageLabsList, loading, success, errorMsg } = props;
  const labFrmData = {
    labName: "",
    labAddress: "",
    city: "",
    zip: "",
    state: "",
    labPhone: "",
    labEmail: "",
    contactFName: "",
    contactLName: "",
    isActive: ""
  };
  const options = enumUsStates.map((item) => item.name);

  const [rows, setRows] = useState(labFrmData);
  const [pageSize, setPageSize] = useState();
  const [isOpenAddLab, setIsOpenAddLab] = useState(false);
  const [values, setValues] = useState(labFrmData);
  const [isAdd, setIsAdd] = useState(false);
  const [labId, setLabId] = useState("");
  const [isMessageModal, setIsMessageModal] = useState(false);

  useEffect(() => {
    setRows(manageLabsList);
  }, [props.manageLabsList]);
  useEffect(() => {
    actions.getManageLab();
  }, []);

  useEffect(() => {
    if (success) {
      actions.getManageLab();
    }
  }, [success]);

  useEffect(() => {
    if (success || errorMsg) {
      setIsMessageModal(true);
    }
  }, [success]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetManageLab();
  };

  const handleOpenAddLab = () => {
    setValues(labFrmData);
    setIsAdd(true);
    setIsOpenAddLab(true);
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const closeModal = () => {
    setIsOpenAddLab(false);
  };

  const config = addNewManageLabConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    disabled: false,
    isAdd,
    options
  });

  const columns = [
    {
      field: "labName",
      headerName: "NAME",
      editable: false,
      width: 100
    },
    {
      field: "labAddress",
      headerName: "ADDRESS",
      editable: false,
      width: 100
    },
    {
      field: "city",
      headerName: "CITY",
      editable: false,
      width: 100
    },
    {
      field: "state",
      headerName: "STATE",
      editable: false,
      width: 100
    },
    {
      field: "zip",
      headerName: "ZIP",
      editable: false,
      width: 100
    },
    {
      field: "labPhone",
      headerName: "PHONE",
      editable: false,
      width: 100
    },
    {
      field: "labEmail",
      headerName: "EMAIL",
      editable: false,
      width: 100
    },
    {
      field: "contactFName",
      headerName: "CONTACT FIRST NAME",
      editable: false,
      width: 100
    },
    {
      field: "contactLName",
      headerName: "CONTACT LAST NAME",
      editable: false,
      width: 100
    },
    {
      field: "isActive",
      headerName: "STATUS",
      editable: false,
      width: 100
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 100,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row">
            <IconButton
              onClick={() => {
                const data = manageLabsList.filter(
                  (item) => item.labName == row.labName && item.labPhone == row.labPhone
                );
                setLabId(data[0].labId);
                const obj = {
                  labName: row.labName,
                  labAddress: row.labAddress,
                  city: row.city,
                  state: row.state,
                  zip: row.zip,
                  labPhone: row.labPhone,
                  labEmail: row.labEmail,
                  contactFName: row.contactFName,
                  contactLName: row.contactLName,
                  isActive: row.isActive
                };
                setValues(obj);
                setIsAdd(false);
                setIsOpenAddLab(true);
              }}>
              <EditOutlinedIcon color="primary"></EditOutlinedIcon>
            </IconButton>
          </Stack>
        );
      }
    }
  ];

  const handleSave = (e) => {
    e.preventDefault();
    if (isAdd) {
      actions.addNewManageLab({ ...values });
      setIsOpenAddLab(false);
    } else {
      actions.editManageLab({ ...values, labId: labId });
      setIsOpenAddLab(false);
      setValues(labFrmData);
    }
  };

  return (
    <>
      <Modal open={open} aria-labelledby="modal-add-title" aria-describedby="modal-add-description">
        <Box className="modal-style-contact" style={{ maxHeight: "80vh", overflow: "auto" }}>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography>Manage Lab</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
          <Stack
            direction="row"
            sx={{ my: 1, alignItems: "center", justifyContent: "space-between" }}>
            <Typography>Labs</Typography>
            <Button variant="contained" onClick={handleOpenAddLab}>
              Add New
            </Button>
          </Stack>
          <Box className="grid-style">
            <DataGridPremiumComp
              rows={rows}
              rowCount={rows.length}
              getRowId={() => uuidv4()}
              columns={columns}
              loading={loading}
              pageSizeOptions={[10, 25, 50]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
            />
          </Box>
        </Box>
      </Modal>
      <CommonModal open={isOpenAddLab} config={config} handleSubmit={handleSave} />
      <MessageModal
        open={isMessageModal}
        message={success}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};

const mapStateToProps = ({ geneticTestingReducer }) => {
  return {
    manageLabsList: geneticTestingReducer.manageLabsList,
    loading: geneticTestingReducer.manageLabLoading,
    success: geneticTestingReducer.successManageLab,
    errorMsg: geneticTestingReducer.errorMsg
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getManageLab: () => {
      dispatch(actionType.getManageLab());
    },
    editManageLab: (payload) => {
      dispatch(actionType.editManageLab(payload));
    },
    addNewManageLab: (payload) => {
      dispatch(actionType.addNewManageLab(payload));
    },
    resetManageLab: () => {
      dispatch(actionType.resetManageLab());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageLabModal);
