import { Box, Button, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const DeleteModal = ({
  open,
  close,
  id,
  handleDelete,
  text = "Are you sure you want to delete?"
}) => {
  return (
    <Modal open={open}>
      <Box className="modal-style-delete">
        <Stack direction="row" className="modal-stack">
          <Typography>Delete Item({id})</Typography>
          <IconButton onClick={close}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Stack>
        <Divider className="modal-divider" />
        <Stack direction="column" className="modal-stack">
          <WarningAmberIcon fontSize="large" color="error" />
          <Typography variant="body1">{text}</Typography>
        </Stack>
        <Stack direction="row" className="modal-stack">
          <Button variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button onClick={handleDelete} variant="contained">
            Delete
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default DeleteModal;
