import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
// import * as token from "../../shared/constants/constants";
// import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchUserList(action) {
  let payload = action.payload;
  const params = {
    practiceId: payload.practiceId,
    primaryRole: payload.role,
    name: payload.userName,
    offset: "",
    limit: "100"
  };
  try {
    const requestBody = createRequestBody("get", `/users`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.USER_FILTER_DATA_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.USER_FILTER_DATA_FAIL,
        message: error.message
      });
    }
  }
}

function* getUserListSaga() {
  yield takeEvery(actionTypes.USER_FILTER_DATA_REQUESTED, fetchUserList);
}

function* setPersonDisable(action) {
  let payload = action.payload;
  try {
    const params = {
      userStatus: payload.personStatus
    };
    const requestBody = createRequestBody(
      "put",
      `/users/${payload.cgId}/changeUserStatus`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.SET_EDIT_PERSON_DISABLE_SUCCESS,
      payload: response.data,
      success: "User Disabled Successfully..."
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.SET_EDIT_PERSON_DISABLE_FAIL,
        message: error.message
      });
    }
  }
}

function* setDisableStatus() {
  yield takeEvery(actionTypes.SET_EDIT_PERSON_DISABLE, setPersonDisable);
}

function* fetchCustAuth(action) {
  let payload = action.payload;
  const params = {
    offset: "",
    limit: "100"
  };
  try {
    const requestBody = createRequestBody(
      "get",
      `/users/${payload.userName}/customAuth`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.CUST_AUTH_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CUST_AUTH_FAIL,
        message: error.message
      });
    }
  }
}

function* getCustAuthSaga() {
  yield takeEvery(actionTypes.CUST_AUTH_REQUESTED, fetchCustAuth);
}

export default function* userSaga() {
  yield all([getUserListSaga(), setDisableStatus(), getCustAuthSaga()]);
}
