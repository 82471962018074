import { React, useEffect } from "react";
import { Box, Typography, Divider, Grid, InputLabel } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../shared/components/LoadingSpinner";

const PatientFAP = (props) => {
  const { actions, fapData, fapDataLoading } = props;
  const { personId } = useParams();

  useEffect(() => {
    if (fapData && !Object.keys(fapData).length) {
      actions.getFAPDetails({ patientId: personId });
    }
  }, []);

  return (
    <Box className="pad-l-10 m-t-10">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" py={1}>
            Fertility Access Program
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Program Name</InputLabel>
          <Typography>{fapData?.programName ? fapData?.programName : "---"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Requested Service</InputLabel>
          <Typography>{fapData?.requestedService ? fapData?.requestedService : "---"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Status</InputLabel>
          <Typography>{fapData?.medcaseStatus ? fapData?.medcaseStatus : "---"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Current Cycle Authorization</InputLabel>
          <Typography>
            {fapData?.currentCycleAuthorization ? fapData?.currentCycleAuthorization : "---"}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h2" py={1}>
            Current Authorized Cycle
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Current Authorized Cycle</InputLabel>
          <Typography>
            {fapData?.currentAuthorizedCycle ? fapData?.currentAuthorizedCycle : "---"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Last Cycle</InputLabel>
          <Typography>{fapData?.lastCycle ? fapData?.lastCycle : "---"}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h2" py={1}>
            Most Recent Application
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Application Created</InputLabel>
          <Typography>
            {fapData?.applicationCreated ? fapData?.applicationCreated : "---"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Submitted By</InputLabel>
          <Typography>{fapData?.submittedBy ? fapData?.submittedBy : "---"}</Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Application Submitted</InputLabel>
          <Typography>
            {fapData?.applicationSubmittedDate ? fapData?.applicationSubmittedDate : "---"}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <InputLabel>Assigned To/Date</InputLabel>
          <Typography>
            {fapData?.refundApplicationAssignedTo ? fapData?.refundApplicationAssignedTo : "---"}
          </Typography>
        </Grid>
      </Grid>
      <Box className="m-8">{fapDataLoading && <LoadingSpinner top="33%" />}</Box>
    </Box>
  );
};

const mapStateToProps = ({ patientReducer }) => {
  return {
    fapData: patientReducer.fapData,
    fapDataLoading: patientReducer.fapDataLoading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getFAPDetails: (payload) => {
      dispatch(actionType.getFAPDetails(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientFAP);
