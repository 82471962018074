import * as actionType from "../actions/ActionTypes";
const initialState = {
  revenueFilterList: [],
  loading: false,
  error: null,
  success: null
};
const revenueReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.REVENUE_FILTER_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.REVENUE_FILTER_LIST_SUCCESS: {
      return {
        ...state,
        revenueFilterList: action.payload,
        loading: false
      };
    }
    case actionType.REVENUE_FILTER_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_REVENUE_FILTER_LIST: {
      return {
        ...state,
        revenueFilterList: []
      };
    }
    case actionType.RUN_REVENUE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.RUN_REVENUE_SUCCESS: {
      return {
        ...state,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.RUN_REVENUE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_RUN_REVENUE: {
      return {
        ...state,
        success: null,
        error: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default revenueReducer;
