import { Close } from "@mui/icons-material";
import {
  Modal,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  IconButton,
  Grid,
  Select,
  MenuItem
} from "@mui/material";
import React, { useState, useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};

export default function ExistingGlobalDataModal(props) {
  const [gdmId, setGdmId] = useState("");
  const { gdmData } = props;
  useEffect(() => {
    if (gdmData.length > 0) {
      setGdmId(gdmData[0].gdmId);
    }
  }, [props]);
  const handleClose = () => {
    props.handleClose();
  };
  const handleSave = () => {
    let gdmDataSelected = {};
    gdmDataSelected = gdmData.filter((ele) => ele.gdmId == gdmId);
    props.handleCreateEGdm(gdmDataSelected[0]);
  };

  const handleGdmChange = (event) => {
    setGdmId(event.target.value);
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box sx={style}>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography>
              {/* {props.edit ? "Edit Global Data Modal" : "New Global Data Modal"} */}
              Create New from Existing
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ backgroundColor: "#177483" }} orientation="horizontal" />
          <Stack direction="row" className="modal-stack pad-10">
            <Grid item xs={12}>
              <Select
                fullWidth
                name="gmId"
                value={gdmId}
                onChange={handleGdmChange}
                className="stage-practice-dropdown">
                {gdmData.map((item, index) => {
                  return (
                    <MenuItem value={item.gdmId} key={index}>
                      {item.gdmVersion}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant="outlined" className="btn-primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" className="btn-usf" onClick={handleSave}>
              {props.edit ? "Save" : "Create"}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
