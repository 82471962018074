import addressSaga from "../../modules/admin/sagas/addressSaga";
import auditLogsSaga from "../../modules/admin/sagas/auditLogsSaga";
import caseListSaga from "../../modules/trak/sagas/caseListSaga";
import caseUpdateSaga from "../../modules/trak/sagas/caseUpdateSaga";
import commentsSaga from "../../modules/trak/sagas/commentsSaga";
import contactSaga from "../../modules/admin/sagas/contactSaga";
import contractSaga from "../../modules/trak/sagas/contractSaga";
import costCoverageSaga from "../../modules/workspace/sagas/costCoverageSaga";
import demographicSaga from "../../modules/workspace/sagas/demographicSaga";
import documentSaga from "../../modules/trak/sagas/documentSaga";
import emailDocSaga from "../../modules/admin/sagas/emailDocumentSaga";
import emailSaga from "../../modules/admin/sagas/emailSaga";
import estimateSaga from "../../modules/workspace/sagas/estimateSaga";
import enableDisableSaga from "../../modules/admin/sagas/enableDisableSaga";
import externalIdSaga from "../../modules/admin/sagas/externalIdSaga";
import fapSaga from "../../modules/workspace/sagas/fapSaga";
import feeSchedule from "../../modules/admin/sagas/feeScheduleSaga";
import feeScheduleSaga from "../../modules/admin/sagas/feeScheduleSaga";
import finSnapshotSaga from "../../modules/trak/sagas/finSnapshotSaga";
import financialSnapshotPaymentSaga from "../../modules/trak/sagas/financialSnapshotPaymentSaga";
import financialSnapshotPgtaSaga from "../../modules/trak/sagas/financialSnapshotPgtaSaga";
import finSnapshot605AllSaga from "../../modules/trak/sagas/finSnapshot605AllSaga";
import globalDataSaga from "../../modules/admin/sagas/globalDataSaga";
import locationProgramSaga from "../../modules/admin/sagas/locationProgramSaga";
import locationSaga from "../../modules/admin/sagas/locationSaga";
import ManualRefundListSaga from "../../modules/trak/sagas/manualRefundSaga";
import medcaseHeaderSaga from "../../modules/trak/sagas/medcaseHeaderSaga";
import medcaseSaga from "../../modules/trak/sagas/medcaseSaga";
import medcaseRevenue605Saga from "../../modules/trak/sagas/medcaseRevenue605Saga";
import multiplierSaga from "../../modules/admin/sagas/multiplierSaga";
import participantSaga from "../../modules/trak/sagas/participantSaga";
import partnerSaga from "../../modules/trak/sagas/partnerSaga";
import patientPortalAdminSaga from "../../modules/admin/sagas/patientPortalAdminSaga";
import patientPaymentSaga from "../../modules/trak/sagas/patientPaymentSaga";
import patientSaga from "../../modules/patients/sagas/patientSaga";
import pgtaSaga from "../../modules/admin/sagas/pgtaSaga";
import phoneSaga from "../../modules/admin/sagas/phoneSaga";
import physcianSaga from "../../modules/admin/sagas/physicianSaga";
import practicePaymentSaga from "../../modules/admin/sagas/practicePaymentSaga";
import practiceSaga from "../../modules/admin/sagas/practiceSaga";
import programCycleSaga from "../../modules/trak/sagas/programCycleSaga";
import programNameSaga from "../../modules/trak/sagas/programNameSaga";
import programSaga from "../../modules/admin/sagas/programSaga";
import programVisualizationSaga from "../../modules/admin/sagas/programVisualizationSaga";
import prsSaga from "../../modules/admin/sagas/prsSaga";
import questionSaga from "../../modules/admin/sagas/questionSaga";
import quickAddSaga from "../../modules/shared/sagas/quickAddSaga";
import refundAgeSaga from "../../modules/admin/sagas/refundAgeSaga";
import refundFilterListSaga from "../../modules/trak/sagas/refundSaga";
import refundSaga from "../../modules/workspace/sagas/refundApplicationSaga";
import resetEmailSaga from "../../modules/admin/sagas/resetEmailSaga";
import relativeValueSaga from "../../modules/admin/sagas/relativeValueSaga";
import emailTemplateSaga from "../../modules/admin/sagas/emailTemplateSaga";
import pgtaLabSaga from "../../modules/admin/sagas/pgtaLabSaga";
import serviceItemSaga from "../../modules/admin/sagas/serviceItemSaga";
import stageDetailSaga from "../../modules/admin/sagas/stageDetailSaga";
import stageSaga from "../../modules/admin/sagas/stageSaga";
import statusSaga from "../../modules/trak/sagas/statusSaga";
import trackOutcomeFormSaga from "../../modules/trak/sagas/trackOutcomeFormSaga";
import transactionLogSaga from "../../modules/trak/sagas/transactionLogSaga";
import treatmentPlanQuestionSaga from "../../modules/admin/sagas/treatmentPlanQuestionSaga";
import treatmentPlanSaga from "../../modules/admin/sagas/treatmentPlanSaga";
import geneticTestingSaga from "../../modules/trak/sagas/geneticTestingSaga";
import { fork } from "redux-saga/effects";
import genTestLabPayoutSaga from "../../modules/trak/sagas/geneticTestingLabPayoutSaga";
import ProviderReimbursementSaga from "../../modules/trak/sagas/ProviderReimbursementSaga";
import newPrSaga from "../../modules/trak/sagas/newPrSaga";
import revenueSaga from "../../modules/trak/sagas/revenueSaga";
import refundApplicationTrakSaga from "../../modules/trak/sagas/refundApplicationTrakSaga";
import reportSaga from "../../modules/trak/sagas/reportSaga";
import paymentRegSaga from "../../modules/trak/sagas/paymentRegSaga";
import enrollSaga from "../../modules/trak/sagas/enrollSaga";
import activeParticipantReportSaga from "../../modules/trak/sagas/activeParticipantReportSaga";
import revenueValidationSaga from "../../modules/trak/sagas/revenueValidationSaga";
import inactiveParticipantReportSaga from "../../modules/trak/sagas/inactiveParticipantReportSaga";
import staffAdminSaga from "../../modules/admin/sagas/staffAdminSaga";
import utilitySearchSaga from "../../modules/shared/sagas/utilitySearchSaga";
import userSaga from "../../modules/admin/sagas/userSaga";
import userNotificationSaga from "../../modules/utilities/sagas/notificationViewSaga";
import createNewUserSaga from "../../modules/admin/sagas/createNewUserSaga";
import profileDrawerSaga from "../../modules/shared/sagas/profileDrawerSaga";
import staffAdminUserSaga from "../../modules/admin/sagas/staffAdminUserSaga";
import ctmSaga from "../../modules/admin/sagas/ctmSaga";
import messagesSaga from "../../modules/shared/sagas/messageSaga";
import loadFromExcelSaga from "../../modules/admin/sagas/loadFromExcelSaga";
import pricingModalSaga from "../../modules/trak/sagas/pricingModalSaga";
import trakFinancialEstimatesSaga from "../../modules/trak/sagas/trakFinancialEstimatesSaga";
import outcomeReportSaga from "../../modules/trak/sagas/outcomeReportSaga";
import enrollReportSaga from "../../modules/trak/sagas/enrollReportSaga";

export default function* rootSaga() {
  yield fork(addressSaga);
  yield fork(auditLogsSaga);
  yield fork(caseListSaga);
  yield fork(caseUpdateSaga);
  yield fork(commentsSaga);
  yield fork(contactSaga);
  yield fork(contractSaga);
  yield fork(costCoverageSaga);
  yield fork(demographicSaga);
  yield fork(documentSaga);
  yield fork(emailDocSaga);
  yield fork(emailSaga);
  yield fork(enrollSaga);
  yield fork(estimateSaga);
  yield fork(enableDisableSaga);
  yield fork(externalIdSaga);
  yield fork(fapSaga);
  yield fork(feeSchedule);
  yield fork(feeScheduleSaga);
  yield fork(finSnapshotSaga);
  yield fork(financialSnapshotPaymentSaga);
  yield fork(financialSnapshotPgtaSaga);
  yield fork(finSnapshot605AllSaga);
  yield fork(globalDataSaga);
  yield fork(locationProgramSaga);
  yield fork(locationSaga);
  yield fork(ManualRefundListSaga);
  yield fork(medcaseHeaderSaga);
  yield fork(medcaseSaga);
  yield fork(medcaseRevenue605Saga);
  yield fork(multiplierSaga);
  yield fork(participantSaga);
  yield fork(partnerSaga);
  yield fork(patientPaymentSaga);
  yield fork(patientPortalAdminSaga);
  yield fork(patientSaga);
  yield fork(pgtaSaga);
  yield fork(phoneSaga);
  yield fork(physcianSaga);
  yield fork(practiceSaga);
  yield fork(practicePaymentSaga);
  yield fork(pricingModalSaga);
  yield fork(profileDrawerSaga);
  yield fork(programCycleSaga);
  yield fork(programNameSaga);
  yield fork(programSaga);
  yield fork(programVisualizationSaga);
  yield fork(prsSaga);
  yield fork(questionSaga);
  yield fork(quickAddSaga);
  yield fork(refundAgeSaga);
  yield fork(refundFilterListSaga);
  yield fork(refundSaga);
  yield fork(relativeValueSaga);
  yield fork(emailTemplateSaga);
  yield fork(pgtaLabSaga);
  yield fork(resetEmailSaga);
  yield fork(serviceItemSaga);
  yield fork(staffAdminSaga);
  yield fork(staffAdminUserSaga);
  yield fork(stageDetailSaga);
  yield fork(stageSaga);
  yield fork(statusSaga);
  yield fork(trackOutcomeFormSaga);
  yield fork(transactionLogSaga);
  yield fork(treatmentPlanQuestionSaga);
  yield fork(treatmentPlanSaga);
  yield fork(utilitySearchSaga);
  yield fork(geneticTestingSaga);
  yield fork(genTestLabPayoutSaga);
  yield fork(ProviderReimbursementSaga);
  yield fork(newPrSaga);
  yield fork(revenueSaga);
  yield fork(refundApplicationTrakSaga);
  yield fork(reportSaga);
  yield fork(paymentRegSaga);
  yield fork(activeParticipantReportSaga);
  yield fork(inactiveParticipantReportSaga);
  yield fork(revenueValidationSaga);
  yield fork(outcomeReportSaga);
  yield fork(userSaga);
  yield fork(userNotificationSaga);
  yield fork(createNewUserSaga);
  yield fork(ctmSaga);
  yield fork(messagesSaga);
  yield fork(loadFromExcelSaga);
  yield fork(trakFinancialEstimatesSaga);
  yield fork(enrollReportSaga);
}
