import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";

const RichTextEditor = ({ label, mandatory, onChange, value, mention }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (typeof value == "string") {
      const contentBlock = htmlToDraft(value);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const bodyState = EditorState.createWithContent(contentState);
        setEditorState(bodyState);
        onChange(convertToRaw(bodyState.getCurrentContent()));
      }
    }
  }, []);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    onChange(convertToRaw(editorState.getCurrentContent()));
  };
  return (
    <>
      <Stack direction="row" className="modal-stack">
        {label ? (
          <InputLabel required={mandatory} className="flex-2">
            {label}
          </InputLabel>
        ) : null}
        <Stack sx={{ flex: 6, width: 100 }}>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
            mention={mention}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default RichTextEditor;
