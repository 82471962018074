import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const InputField = ({
  label,
  mandatory,
  value,
  type,
  min,
  max,
  pattern,
  placeholder,
  onChange,
  tooltip,
  ...props
}) => {
  return (
    <>
      <Stack direction="row" className="modal-stack">
        {label ? (
          <>
            <InputLabel required={mandatory} className="flex-2 word-break">
              {label}
              {tooltip ? (
                <Tooltip title={tooltip} arrow followCursor={true}>
                  <ErrorOutlineIcon fontSize="small" />
                </Tooltip>
              ) : null}
            </InputLabel>
          </>
        ) : null}
        <TextField
          {...props}
          size="small"
          sx={{ flex: 6 }}
          value={value}
          type={type}
          // onInvalid={(e) => e.target.setCustomValidity(message)}
          placeholder={placeholder}
          onChange={onChange}
          InputProps={{
            inputProps: { min, max, pattern }
          }}
        />
      </Stack>
    </>
  );
};

export default InputField;
