import ArticleIcon from "@mui/icons-material/Article";
import { Link } from "react-router-dom";
import { useState, useRef, useEffect, useCallback, Fragment } from "react";
import PatientDemoGraphic from "./PatientDemoGraphic";
import { Modal, IconButton, CircularProgress, Autocomplete } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  Box,
  Grid,
  Typography,
  // Select,
  Divider,
  TextField,
  Stack,
  // MenuItem,
  Button
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Workspace.scss";
import QuickAdd from "../../shared/components/QuickAdd";
import {
  // DATE_RANGE,
  REFUND_APPLICATION_STATUS,
  MEDCASE_STATUS,
  CURRENT_AUTHORIZATION_CYCLE
} from "../../shared/constants/constants";
import * as actionType from "../../../modules/workspace/actions";
import { connect } from "react-redux";
import moment from "moment";
import FelixOutcomeForm from "./FelixOutcomeForm";
import LightTooltip from "../../shared/components/LightTooltip";
import { getLocaleDateTime } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const FAP = (props) => {
  const {
    actions,
    fapList = [],
    loading,
    successAuthMSG,
    practiceUsers,
    loadingPractice,
    success,
    programList
  } = props;

  const practiceId = localStorage.getItem("practiceId");
  const inputValue = {
    patientNameOrId: "",
    currentAuthorizationCycle: "All",
    medcaseStatus: "All",
    refundApplicationStatus: "All",
    assignedTo: "All",
    dateRange: 60,
    practiceId: practiceId,
    enrollmentDateFrom: "2024-04-01",
    enrollmentDateTo: moment().format("YYYY-MM-DD"),
    programName: ""
  };
  const columns = [
    {
      field: "patientNameOrId",
      headerName: "Patient Name & ID",
      width: 100,
      renderCell: ({ row }) => {
        return (
          <Stack className="word-wrap">
            <Typography>{row.patientName}</Typography>
            <Typography>#{row.personKey}</Typography>
          </Stack>
        );
      }
    },
    {
      field: "refundApplicationDetails",
      headerName: "Refund App",
      width: 70,
      renderCell: ({ row }) => {
        let showButton = showrefundButton(row);
        if (showButton) {
          return (
            <Link
              to={row.patientId}
              onClick={() => {
                actions.FapRowData({
                  estimateType: row.estimateType,
                  estimateStatus: row.estimateStatus,
                  estimateDescription: row.estimateDescription,
                  estimateId: row.estimateId,
                  estimateTpId: row.estimateTpId
                });
                actions.resetFapList();
                localStorage.setItem("patientName", row.patientName);
                actions.resetEstimateCards();
              }}>
              <ArticleIcon />
            </Link>
          );
        } else {
          return <Typography className="word-wrap">--</Typography>;
        }
      }
    },
    {
      field: "refundApplicationStatus",
      headerName: "Refund App Status",
      width: 100,
      renderCell: ({ row }) => {
        if (
          row.refundApplicationNotes !== "PGTA_REFUND_UNAVAILABLE" &&
          row.treatmentTypeName != "PGT-A Analysis"
        ) {
          return (
            <Typography className="word-wrap">
              {row.refundApplicationStatus ? row.refundApplicationStatus : "--"}
            </Typography>
          );
        } else {
          return <Typography className="word-wrap">--</Typography>;
        }
      }
    },
    {
      field: "financialEstimatedetails",
      headerName: "Estimate",
      width: 70,
      renderCell: ({ row }) => {
        if (row.treatmentTypeName == "IVF" || row.treatmentTypeName == "PGT-A Analysis") {
          return (
            <Link
              to={
                row.estimateId
                  ? `../financial-estimates/${row.patientId}?estimateId=${row.estimateId}`
                  : `../financial-estimates/${row.patientId}`
              }
              onClick={() => {
                let payload = {
                  practiceId,
                  personId: row.patientId
                };
                actions.setEstimateId(row.estimateId);
                actions.resetEstimateViewList();
                actions.inputValues(values);
                actions.getFinancialEstimateViewList(payload);
                localStorage.setItem("patientName", row.patientName);
              }}>
              <ArticleIcon />
            </Link>
          );
        } else {
          return <Typography>{"--"}</Typography>;
        }
      }
    },
    // {
    //   field: "medcaseID",
    //   headerName: "MEDCASE ID",
    //   renderCell: ({ row }) => <Typography>{row.medcaseID ? row.medcaseID : "--"}</Typography>
    // },
    {
      field: "medcaseStatus",
      headerName: "Medcase Status",
      width: 100,
      renderCell: ({ row }) => (
        <Typography className="word-wrap">
          {row.medcaseStatus ? row.medcaseStatus : "--"}
        </Typography>
      )
    },
    {
      field: "progShortName",
      headerName: "Program Short Name",
      width: 150,
      renderCell: ({ row }) => (
        <Typography className="word-wrap">
          {row.progShortName ? row.progShortName : "--"}
        </Typography>
      )
    },
    {
      field: "treatmentPlan",
      headerName: "Treatment Plan",
      renderCell: ({ row }) => (
        <Typography className="word-wrap">
          {row?.treatmentPlanCustomName !== null ? row.treatmentPlanCustomName : row.treatmentPlan}
        </Typography>
      )
    },
    {
      field: "currentAuthorizationCycleShdw",
      headerName: "Auth",
      minWidth: 100,
      renderCell: ({ row }) => {
        if (
          row.refundApplicationNotes !== "PGTA_REFUND_UNAVAILABLE" &&
          row.treatmentTypeName != "PGT-A Analysis" &&
          (row.cycleNo || row.currentAuthorizationCycleShdw || row.medcaseID)
          // &&
          // row.medcaseStatus === "Enrolled"
        ) {
          return (
            (row.currentAuthorizationCycleShdw && (
              <Stack>
                {row.currentAuthorizationCycleShdw.includes("Requested") && (
                  <Button
                    color="primary"
                    variant="text"
                    className="word-wrap"
                    onClick={() => {
                      setIsViewOnly(false);
                      setIsOpen(true);
                    }}>
                    {row.currentAuthorizationCycleShdw}
                  </Button>
                )}
                {!row.currentAuthorizationCycleShdw.includes("Requested") &&
                  !row.currentAuthorizationCycleShdw.includes("Request") && (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => {
                        setIsViewOnly(false);
                        setIsOpen(true);
                      }}
                      className="word-wrap">
                      {row.currentAuthorizationCycleShdw}
                    </Button>
                  )}
                {row.currentAuthorizationCycleShdw.includes("Request") &&
                  row.currentAuthorizationCycleShdw.substring(0, 9) !== "Requested" && (
                    <Stack>
                      <Button variant="text" color="primary" onClick={cycleRequestHandler}>
                        {row.currentAuthorizationCycleShdw}
                      </Button>
                    </Stack>
                  )}
              </Stack>
            )) || (
              <Stack>
                <Typography>{"--"}</Typography>
                {/* <Button
                  color="primary"
                  variant="text"
                  onClick={() => {
                    setIsViewOnly(true);
                    setIsOpen(true);
                  }}
                  className="word-wrap">
                  VIEW */}
                {/* row.lastKnownEvent */}
                {/* </Button> */}
              </Stack>
            )
          );
        } else {
          return <Typography>{"--"}</Typography>;
        }
      }
    },
    {
      field: "cycleNo",
      headerName: "Cycle No.",
      width: 60,
      renderCell: ({ row }) => {
        if (
          row.refundApplicationNotes !== "PGTA_REFUND_UNAVAILABLE" &&
          row.treatmentTypeName != "PGT-A Analysis"
        ) {
          return <Typography>{row.cycleNo ? row.cycleNo : "--"}</Typography>;
        } else {
          return <Typography>{"--"}</Typography>;
        }
      }
    },
    {
      field: "lastKnownEventDate",
      headerName: "Outcome",
      type: "date",
      width: 150,
      renderCell: ({ row }) => {
        if (
          row.refundApplicationNotes !== "PGTA_REFUND_UNAVAILABLE" &&
          row.treatmentTypeName != "PGT-A Analysis"
        ) {
          return (
            <Typography className="word-wrap">
              {row.lastKnownEvent ? row.lastKnownEvent : "--"}
            </Typography>
          );
        } else {
          return <Typography>{"--"}</Typography>;
        }
      }
    },
    {
      field: "normalEmbryosRemain",
      headerName: "Normal Embryos Remain",
      width: 60
    },
    {
      field: "requestedCycle",
      headerName: "Requested Cycle1",
      width: 60,
      renderCell: ({ row }) => <Typography>{row.requestedCycle}</Typography>
    },
    {
      field: "estimatedCycleStartDate",
      headerName: "Est Cycle Start Date",
      renderCell: ({ row }) => {
        if (
          row.refundApplicationNotes !== "PGTA_REFUND_UNAVAILABLE" &&
          row.treatmentTypeName != "PGT-A Analysis"
        ) {
          return (
            <Typography>
              {row.estimatedCycleStartDate
                ? getLocaleDateTime(
                    row.estimatedCycleStartDate,
                    "L"
                  ) /* moment(row.estimatedCycleStartDate).format("L") */
                : "--"}
            </Typography>
          );
        } else {
          return <Typography>{"--"}</Typography>;
        }
      }
    },
    {
      field: "refundApplicationAssignedTo",
      headerName: "Refund App Assigned To",
      renderCell: ({ row }) => {
        if (
          row.refundApplicationNotes !== "PGTA_REFUND_UNAVAILABLE" &&
          row.treatmentTypeName != "PGT-A Analysis"
        ) {
          return (
            <Typography className="word-wrap">
              {row.refundApplicationAssignedTo ? row.refundApplicationAssignedTo : "--"}
            </Typography>
          );
        } else {
          return <Typography>{"--"}</Typography>;
        }
      }
    },
    {
      field: "refundApplicationNotes",
      headerName: "Refund App Notes",
      width: 70,
      renderCell: ({ row }) => {
        let showButton = showrefundButton(row);
        if (showButton) {
          return (
            <LightTooltip
              title={row.refundApplicationNotes ? row.refundApplicationNotes : "--"}
              placement="left-start">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: "pointer" }}>
                <ArticleIcon />
              </div>
            </LightTooltip>
          );
        } else {
          return <Typography>{"--"}</Typography>;
        }
      }
    },
    // {
    //   field: "refundApplicationWithdrawnBy",
    //   headerName: "REFUND APPLICATION WITHDRAWN",
    //   renderCell: ({ row }) => {
    //     if (row.refundApplicationNotes !== "PGTA_REFUND_UNAVAILABLE") {
    //       return (
    //         <Typography className="word-wrap">
    //           {row.refundApplicationWithdrawnBy ? row.refundApplicationWithdrawnBy : "--"}
    //         </Typography>
    //       );
    //     } else {
    //       return <Typography>{"--"}</Typography>;
    //     }
    //   }
    // },
    {
      field: "medcaseID",
      headerName: "Medcase ID",
      width: 60
    }
  ];
  const [assignTo, setAssignTo] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(true);
  const prevCountRef = useRef();
  const [selectedPatientId, setSelectedPatientId] = useState(0);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [values, setValues] = useState({ ...inputValue });
  const [toggle, setToggle] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [requestedService, setRequestedService] = useState("");
  const [successModal, setsuccessModal] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  // const [programText, setProgramText] = useState("");

  function lastKnownEventDate(str) {
    const date = str.split(" ");
    console.log(date);
    console.log(new Date(date));
    return new Date(date);
  }

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      estimatedCycleStartDate: row.estimatedCycleStartDate
        ? new Date(row.estimatedCycleStartDate)
        : "",
      lastKnownEventDate: row.lastKnownEvent ? lastKnownEventDate(row.lastKnownEvent) : ""
    }));
  };

  const rows = fapList.map((item, index) => {
    item.id = index;
    item.patientNameOrId = item.patientName + item.personKey;
    return item;
  });

  prepareCsvData(rows);

  const showrefundButton = (row) => {
    let patientAge = row && Math.ceil(row.patientAge);
    let donorMinAge = row && row.donorMinAge;
    let donorMaxAge = row && row.donorMaxAge;
    let nonDonorMinAge = row && row.nonDonorMinAge;
    let nonDonorMaxAge = row && row.nonDonorMaxAge;
    let tratmentType = row && row.treatmentTypeName;
    let isDonor = row && row.isDonor;
    let isButtonVisiable = false;
    if (tratmentType.toString().toLowerCase().includes("ivf")) {
      if (isDonor) {
        if (patientAge >= donorMinAge && patientAge <= donorMaxAge) {
          isButtonVisiable = true;
        } else {
          isButtonVisiable = false;
        }
      } else {
        if (patientAge >= nonDonorMinAge && patientAge <= nonDonorMaxAge) {
          isButtonVisiable = true;
        } else {
          isButtonVisiable = false;
        }
      }
      // if (patientAge >= donorMinAge && patientAge <= donorMaxAge) {
      //   isButtonVisiable = true;
      // } else if (patientAge >= nonDonorMinAge && patientAge <= nonDonorMaxAge) {
      //   isButtonVisiable = true;
      // } else {
      //   isButtonVisiable = false;
      // }
    }
    //console.log("isButtonVisiable", isButtonVisiable);

    return isButtonVisiable;
  };
  let initFetch = useCallback(() => {
    if (practiceId) {
      actions.getFapList(values);
    }
  }, [values]);
  useEffect(() => {
    if (fapList?.length == 0 || successAuthMSG || success) {
      initFetch();
    }
  }, [values, successAuthMSG, success]);

  useEffect(() => {
    let payload = {};
    payload.practiceId = practiceId;
    actions.getProgramsList(payload);
  }, []);

  useEffect(() => {
    prevCountRef.current = selectedPatientId;
    if (!toggle & (selectedPatientId === prevCountRef.current)) {
      setSelectedPatientId(0);
    }
  }, [toggle]);
  useEffect(() => {
    if (practiceUsers) {
      let users = practiceUsers.map((item) => {
        return item.firstName + "-" + item.lastName;
      });
      setAssignTo(["All", ...users]);
    }
  }, [loadingPractice]);
  const showAdvancedFilter = () => {
    setShowAdvanced(!showAdvanced);
  };
  const updateSelectedPatient = ({ row }) => {
    setSelectedPatientId(row.patientId);
    setSelectedPatient(row);
  };
  const setClose = (data) => {
    setToggle(!toggle);
    setSelectedPatientId(data);
  };
  const handleSearchClick = (e, searchText) => {
    if (searchText) {
      values.patientNameOrId = searchText;
    }

    var start = moment(values.enrollmentDateFrom, "YYYY-MM-DD");
    var end = moment(values.enrollmentDateTo, "YYYY-MM-DD");

    //Difference in number of days
    var dateRange = end.diff(start, "days");
    values.dateRange = dateRange;
    setValues({ ...values });
    actions.resetFapList();
  };
  const clearSearchClick = () => {
    values.patientNameOrId = "";
    setValues({ ...values });
  };
  // const handleChange = (e) => {
  //   actions.resetFapList();
  //   if (e?._d) {
  //     values.lastKnownEvent = moment(e._d).format("YYYY-MM-DD");
  //     setValues({
  //       ...values
  //     });
  //   } else {
  //     const { name, value } = e.target;
  //     setValues((state) => {
  //       return { ...state, [name]: value };
  //     });
  //   }
  // };

  const handleAutoChange = (e, name, value) => {
    console.log("zhr value:", value);
    if (value != null) {
      setValues((state) => {
        return { ...state, [value.name]: value.value };
      });
    } else {
      setValues({ ...values, [name]: "" });
    }
    actions.resetFapList();
  };

  const commentChangeHandler = (e) => {
    if (e.target.value) {
      values.comments = e.target.value;
      setValues({
        ...values
      });
    }
  };

  const resetFilter = () => {
    setValues(inputValue);
  };
  const cycleRequestHandler = () => {
    setRequestModalOpen(true);
  };

  const closeRequestModal = () => {
    setRequestModalOpen(false);
  };
  const handleServiceChoosen = (event) => {
    setRequestedService(event.target.value);
  };
  const estimatedStartDateHandler = (e) => {
    values.estimatedCycleStartDate = moment(e._d).format("YYYY-MM-DD");
    setValues({
      ...values
    });
  };

  const enrollmentStartDateHandler = (e) => {
    values.enrollmentDateFrom = moment(e._d).format("YYYY-MM-DD");
    setValues({
      ...values
    });
  };
  const enrollmentEndDateHandler = (e) => {
    values.enrollmentDateTo = moment(e._d).format("YYYY-MM-DD");
    setValues({
      ...values
    });
  };
  const getPracticeUser = () => {
    if (practiceUsers?.length == 0) {
      actions.getPracticeUsers(practiceId);
    }
  };
  const submitRequestModal = () => {
    setsuccessModal(true);
  };
  const closeSuccessModal = () => {
    setsuccessModal(false);
    closeRequestModal();
    let payload = {
      medcaseId: selectedPatient.medcaseID,
      clinicalTreatmentId: selectedPatient.clinicalTreatmentTd,
      values: {
        estimatedCycleStartDate: values.estimatedCycleStartDate,
        requestedService: requestedService,
        comments: values.comments
      }
    };
    actions.saveEditedAuthForm(payload);
  };
  const closeCancelModal = () => {
    setsuccessModal(false);
  };
  const closeOutComeModal = () => {
    //console.log("close model popup");
    setIsOpen(false);
    setIsViewOnly(false);
  };
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} sx={{ mx: 1 }}>
          <Box
            className={`${
              loading
                ? "align-center pad-5 teal-bg-color m-l-5 disable-section"
                : "align-center pad-5 teal-bg-color m-l-5"
            }`}>
            <Grid container>
              <Grid item xs={10}>
                <QuickAdd
                  text={1.2}
                  search={9}
                  handleSearchClick={handleSearchClick}
                  clearSearchClick={clearSearchClick}
                />
              </Grid>
            </Grid>

            <Grid container className="align-center mt-10">
              <Grid item xs={1}>
                <Typography>Refund Application Status</Typography>
              </Grid>
              <Grid item xs={3}>
                {/* <Select
                    value={values.refundApplicationStatus}
                    fullWidth
                    name="refundApplicationStatus"
                    onChange={handleChange}>
                    {REFUND_APPLICATION_STATUS.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select> */}
                <Autocomplete
                  disablePortal
                  required
                  name="refundApplicationStatus"
                  value={values.refundApplicationStatus}
                  options={REFUND_APPLICATION_STATUS.map((item, index) => {
                    return {
                      id: index,
                      label: item,
                      name: "refundApplicationStatus",
                      value: item
                    };
                  })}
                  onChange={(e, value) => {
                    handleAutoChange(e, "refundApplicationStatus", value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Refund App Status..."
                      sx={{ paddingTop: "7px" }}
                    />
                  )}
                  className="flex-4"
                />
              </Grid>
              <Grid item xs={1}>
                <Typography className="m-l-30">Auth</Typography>
              </Grid>
              <Grid item xs={3}>
                {/* <Select
                    value={values.currentAuthorizationCycle}
                    fullWidth
                    className="m-l-40"
                    name="currentAuthorizationCycle"
                    onChange={handleChange}>
                    {CURRENT_AUTHORIZATION_CYCLE.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select> */}
                <Autocomplete
                  disablePortal
                  required
                  name="currentAuthorizationCycle"
                  value={values.currentAuthorizationCycle}
                  options={CURRENT_AUTHORIZATION_CYCLE.map((item, index) => {
                    return {
                      id: index,
                      label: item,
                      name: "currentAuthorizationCycle",
                      value: item
                    };
                  })}
                  onChange={(e, value) => {
                    handleAutoChange(e, "currentAuthorizationCycle", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Auth..." sx={{ paddingTop: "7px" }} />
                  )}
                  className="flex-4 m-l-40"
                />
              </Grid>

              <Grid item xs={4}>
                <Stack direction="row" className="align-end">
                  <Button
                    variant="text"
                    disabled={
                      false
                      /* values.patientNameOrId == inputValue.patientNameOrId &&
                        values.currentAuthorizationCycle == inputValue.currentAuthorizationCycle &&
                        values.medcaseStatus == inputValue.medcaseStatus &&
                        values.refundApplicationStatus == inputValue.refundApplicationStatus &&
                        values.lastKnownEvent == inputValue.lastKnownEvent &&
                        values.assignedTo == inputValue.assignedTo &&
                        values.dateRange == inputValue.dateRange
                          ? true
                          : false */
                    }
                    color="primary"
                    onClick={resetFilter}>
                    <Typography variant="subtitle1">RESET ALL</Typography>
                  </Button>
                  <Divider sx={{ height: 12, m: "0px 10px" }} orientation="vertical" />
                  <Typography
                    underline="none"
                    variant="subtitle1"
                    onClick={showAdvancedFilter}
                    className="cursor-pointer"
                    color="primary">
                    SHOW ADVANCED
                  </Typography>
                  {showAdvanced ? (
                    <KeyboardArrowUpIcon
                      className="cursor-pointer"
                      color="primary"></KeyboardArrowUpIcon>
                  ) : (
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      color="primary"></KeyboardArrowDownIcon>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={1} paddingTop={2}>
                <Typography>Medcase Status</Typography>
              </Grid>
              <Grid item xs={3} paddingTop={2}>
                {/* <Select
                    value={values.medcaseStatus}
                    fullWidth
                    name="medcaseStatus"
                    onChange={handleChange}>
                    {MEDCASE_STATUS.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select> */}
                <Autocomplete
                  disablePortal
                  required
                  name="medcaseStatus"
                  value={values.medcaseStatus}
                  options={MEDCASE_STATUS.map((item, index) => {
                    return {
                      id: index,
                      label: item,
                      name: "medcaseStatus",
                      value: item
                    };
                  })}
                  onChange={(e, value) => {
                    handleAutoChange(e, "medcaseStatus", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Medcase Status..." sx={{ paddingTop: "7px" }} />
                  )}
                  className="flex-4"
                />
              </Grid>
            </Grid>

            {showAdvanced && (
              <Grid container className="align-center mt-10">
                <Grid item xs={1}>
                  <Typography>Assigned to</Typography>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    fullWidth
                    value={values.assignedTo}
                    disableClearable
                    options={assignTo}
                    onChange={(e, value) => {
                      actions.resetFapList();
                      setValues((state) => {
                        return { ...state, assignedTo: value };
                      });
                    }}
                    loading={loadingPractice}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onClick={getPracticeUser}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loadingPractice ? <CircularProgress size={20} /> : null}
                              <Typography>{params.InputProps.endAdornment}</Typography>
                            </Fragment>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            {showAdvanced && (
              <Grid container className="align-center mt-10">
                <Grid item xs={1}>
                  <Typography>Medcase Created Date From</Typography>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      openTo="day"
                      name="enrollmentDateFrom"
                      views={["day"]}
                      value={values.enrollmentDateFrom}
                      onChange={enrollmentStartDateHandler}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "YYYY-DD-MM",
                            disabled: true
                          }}
                          className="flex-4"
                          fullWidth
                        />
                      )}></DatePicker>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={1}>
                  <Typography className="m-l-30">Medcase Created Date To</Typography>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      openTo="day"
                      name="enrollmentDateTo"
                      views={["day"]}
                      value={values.enrollmentDateTo}
                      onChange={enrollmentEndDateHandler}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "YYYY-DD-MM",
                            disabled: true
                          }}
                          className="flex-4"
                          fullWidth
                        />
                      )}></DatePicker>
                  </LocalizationProvider>
                </Grid>
                {/* <Grid item xs={1}>
                    <Typography className="m-l-30">Date Range</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      value={values.dateRange}
                      fullWidth
                      className="m-l-40"
                      name="dateRange"
                      onChange={handleChange}>
                      {DATE_RANGE.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item + " days prior"}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid> */}
              </Grid>
            )}

            {showAdvanced && (
              <Grid container className="align-center mt-10">
                <Grid item xs={1} paddingTop={2}>
                  <Typography>Search By Program</Typography>
                </Grid>
                <Grid item xs={3}>
                  {/* <TextField
                      fullWidth
                      size="small"
                      value={programText}
                      placeholder="Enter a program name"
                      onChange={(e) => {
                        setProgramText(e.target.value);
                      }}
                    /> */}
                  <Autocomplete
                    disablePortal
                    required
                    name="programName"
                    value={values.programName}
                    options={programList.map((item, index) => {
                      return {
                        id: index,
                        label: item.programName,
                        name: "programName",
                        value: item.programName
                      };
                    })}
                    onChange={(e, value) => {
                      handleAutoChange(e, "programName", value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Program Name..." sx={{ paddingTop: "7px" }} />
                    )}
                    className="flex-4"
                  />
                </Grid>
              </Grid>
            )}
          </Box>
          <Box
            sx={{ mx: 1, height: "650px", width: "100%" }}
            className={`${loading ? "grid-style disable-section" : "grid-style"}`}>
            <Typography>{rows?.length} Estimates matching your criteria</Typography>
            <DataGridPremiumComp
              rows={rows}
              rowCount={rows?.length}
              columns={columns}
              loading={loading}
              className="mt-8"
              onRowClick={(e) => {
                setToggle(!toggle);
                updateSelectedPatient(e);
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
              isExport={true}
            />
          </Box>
        </Grid>
        <Grid item xs={3} className={selectedPatientId !== 0 ? "patient-context" : "display-none"}>
          {selectedPatientId !== 0 ? (
            <PatientDemoGraphic personId={selectedPatientId} close={setClose} />
          ) : null}
        </Grid>
      </Grid>
      <Modal open={requestModalOpen} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="modal-style" width="500px">
          <Stack direction="row" className="modal-stack">
            <Typography className="font-w-500">REQUEST AUTHORIZATION</Typography>
            <IconButton onClick={closeRequestModal}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Typography ml={47}>*Required fields</Typography>
          <Stack direction="column" sx={{ alignItems: "center", justifyContent: "center", py: 3 }}>
            <Grid container>
              <Grid item xs={5}>
                <Typography>Patient name and Id</Typography>
              </Grid>
              <Grid item xs={7}>
                <Stack direction="row">
                  <Typography>{selectedPatient.patientName} </Typography>
                  <Typography> (Id# {selectedPatient.personKey} ) </Typography>
                </Stack>
              </Grid>
              <Grid item xs={5} py={1}>
                <Typography>Medcase Id</Typography>
              </Grid>
              <Grid item xs={7} py={1}>
                <Typography>{selectedPatient.medcaseID}</Typography>
              </Grid>
              <Grid item xs={5} py={1}>
                <Typography>Requested service*</Typography>
              </Grid>
              <Grid item xs={7} py={1}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={requestedService}
                    onChange={handleServiceChoosen}>
                    <FormControlLabel value="Retrieval" control={<Radio />} label="Retrieval" />
                    <FormControlLabel value="FET" control={<Radio />} label="FET" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={5} py={1}>
                <Typography>Comments</Typography>
              </Grid>
              <Grid item xs={7} py={1}>
                <textarea
                  rows={5}
                  value={values.comments}
                  onChange={commentChangeHandler}></textarea>
              </Grid>
              <Grid item xs={5} py={1}>
                <Typography>Estimated cycle start date*</Typography>
              </Grid>
              <Grid item xs={7} py={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    openTo="day"
                    name="estimatedCycleStartDate"
                    minDate={new Date()}
                    views={["day"]}
                    value={values.estimatedCycleStartDate}
                    onChange={estimatedStartDateHandler}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "YYYY-DD-MM",
                          disabled: true
                        }}
                        className="patient-dropdown"
                      />
                    )}></DatePicker>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Stack>
          <Stack direction="row" spacing={2} className="modal-bottom-section">
            <Button variant="outlined" onClick={closeRequestModal}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={submitRequestModal}>
              SUBMIT
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Modal open={successModal} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="modal-style">
          <Stack direction="row" className="modal-stack">
            <Typography className="font-w-500">Confirmation</Typography>
            <IconButton onClick={closeSuccessModal}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "center", py: 2 }}>
            <Typography variant="body1">
              Are you sure you want to request for Retrieval (Cycle no) or FET (cycle no)?
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2} className="modal-bottom-section">
            <Button variant="outlined" onClick={closeCancelModal}>
              CANCEL
            </Button>
            <Button variant="contained" onClick={closeSuccessModal}>
              CONFIRM
            </Button>
          </Stack>
        </Box>
      </Modal>
      {isOpen ? (
        <FelixOutcomeForm
          isOpen={isOpen}
          isViewOnly={isViewOnly}
          patientId={selectedPatientId}
          selectedPatient={selectedPatient}
          close={closeOutComeModal}
        />
      ) : null}
    </Box>
  );
};

const mapStateToProps = ({ fapReducer, refundApplicationReducer, programVisualizationReducer }) => {
  return {
    fapList: fapReducer.fapList,
    loading: fapReducer.loading,
    successAuthMSG: fapReducer.successAuthMSG,
    success: fapReducer.success,
    practiceUsers: refundApplicationReducer.practiceUsers,
    loadingPractice: refundApplicationReducer.loadingPractice,
    programList: programVisualizationReducer.programList
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getFapList: (payload) => {
      dispatch(actionType.getFapList(payload));
    },
    resetFapList: () => {
      dispatch(actionType.resetFapList());
    },
    resetRefundQuestions: () => {
      dispatch(actionType.resetRefundQuestions());
    },
    FapRowData: (payload) => {
      dispatch(actionType.FapRowData(payload));
    },
    saveEditedAuthForm: (payload) => {
      dispatch(actionType.saveEditedAuthForm(payload));
    },
    getFinancialEstimateViewList: (payload) => {
      dispatch(actionType.getFinancialEstimateViewList(payload));
    },
    inputValues: (payload) => {
      dispatch(actionType.inputValues(payload));
    },
    resetEstimateViewList: () => {
      dispatch(actionType.resetEstimateViewList());
    },
    setEstimateId: (payload) => {
      dispatch(actionType.setEstimateId(payload));
    },
    resetEstimateCards: () => {
      dispatch(actionType.resetEstimateCards());
    },
    getPracticeUsers: (payload) => {
      dispatch(actionType.getPracticeUsers(payload));
    },
    getProgramsList: (payload) => {
      dispatch(actionType.getProgramsList(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(FAP);
