// partner data
export const PARTNER_DATA_REQUESTED = "PARTNER_DATA_REQUESTED";
export const PARTNER_DATA_SUCCESS = "PARTNER_DATA_SUCCESS";
export const PARTNER_DATA_FAILED = "PARTNER_DATA_FAILED";

export const PERSON_DATA_REQUESTED = "PERSON_DATA_REQUESTED";
export const PERSON_DATA_SUCCESS = "PERSON_DATA_SUCCESS";
export const PERSON_DATA_FAILED = "PERSON_DATA_FAILED";

// task data
export const TASK_DATA_REQUESTED = "TASK_DATA_REQUESTED";
export const TASK_DATA_SUCCESS = "TASK_DATA_SUCCESS";
export const TASK_DATA_FAILED = "TASK_DATA_FAILED";

// task checked data
export const TASK_CHECKED_DATA_REQUESTED = "TASK_CHECKED_DATA_REQUESTED";
export const TASK_CHECKED_DATA_SUCCESS = "TASK_CHECKED_DATA_SUCCESS";
export const TASK_CHECKED_DATA_FAILED = "TASK_CHECKED_DATA_FAILED";

export const UPDATE_CHECKED_DATA_REQUESTED = "UPDATE_CHECKED_DATA_REQUESTED";
export const UPDATE_CHECKED_DATA_SUCCESS = "UPDATE_CHECKED_DATA_SUCCESS";
export const UPDATE_CHECKED_DATA_FAIL = "UPDATE_CHECKED_DATA_FAIL";

//Personal data
export const PERSONAL_DATA_REQUESTED = "PERSONAL_DATA_REQUESTED";
export const PERSONAL_DATA_SUCCESS = "PERSONAL_DATA_SUCCESS";
export const PERSONAL_DATA_FAILED = "PERSONAL_DATA_FAILED";
export const RESET_PERSONAL_LIST = "RESET_PERSONAL_LIST";

// Add patient
export const ADD_PATIENT_DETAILS_REQUESTED = "ADD_PATIENT_DETAILS_REQUESTED";
export const ADD_PATIENT_DETAILS_SUCCESS = "ADD_PATIENT_DETAILS_SUCCESS";
export const ADD_PATIENT_DETAILS_FAILED = "ADD_PATIENT_DETAILS_FAILED";
export const RESET_PATIENT_DETAILS_MSG = "RESET_PATIENT_DETAILS_MSG";

// edit patient details
export const UPDATE_PATIENT_DETAILS_REQUESTED = "UPDATE_PATIENT_DETAILS_REQUESTED";
export const UPDATE_PATIENT_DETAILS_SUCCESS = "UPDATE_PATIENT_DETAILS_SUCCESS";
export const UPDATE_PATIENT_DETAILS_FAILED = "UPDATE_PATIENT_DETAILS_FAILED";
export const RESET_UPDATE_PATIENT_DETAILS_MSG = "RESET_UPDATE_PATIENT_DETAILS_MSG";

// get patient partner list
export const GET_PATIENT_PARTNER_REQUESTED = "GET_PATIENT_PARTNER_REQUESTED";
export const GET_PATIENT_PARTNER_SUCCESS = "GET_PATIENT_PARTNER_SUCCESS";
export const GET_PATIENT_PARTNER_FAILED = "GET_PATIENT_PARTNER_FAILED";
export const RESET_PATIENT_PARTNER_LIST = "RESET_PATIENT_PARTNER_LIST";
export const RESET_PARTNER_DATA = "RESET_PARTNER_DATA";
// FAP Data
export const FAP_DATA_REQUESTED = "FAP_DATA_REQUESTED";
export const FAP_DATA_SUCCESS = "FAP_DATA_SUCCESS";
export const FAP_DATA_FAILED = "FAP_DATA_FAILED";
export const RESET_FAP_DATA = "RESET_FAP_DATA";

// get Primary MD
export const GET_PRIMARY_MD_REQUESTED = "GET_PRIMARY_MD_REQUESTED";
export const GET_PRIMARY_MD_SUCCESS = "GET_PRIMARY_MD_SUCCESS";
export const GET_PRIMARY_MD_FAILED = "GET_PRIMARY_MD_FAILED";
export const RESET_PRIMARY_MD_LIST = "GET_PRIMARY_MD_RESET";
