import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Modal,
  Select,
  TextField
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import StyledMenu from "../../shared/components/styledMenu";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as actionType from "../actions";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import LoadingModal from "../../trak/components/modals/loadingModal";
import CircleIcon from "@mui/icons-material/Circle";
import DataGridPremiumComp from "../components/DataGridPremiumComp";

const Inbox = (props) => {
  const {
    actions,
    ListOfMessages,
    ListOfMessagesLoading,
    toggleSuccess,
    updatedSuccessMsg,
    passedQueryParamters,
    assignToUsersList,
    updateMessagesLoading,
    toggleLoading,
    profileDetails,
    downloadedFileUrl,
    fileLoading
  } = props;
  const userId = localStorage.getItem("userId");
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [openSelectedMessage, setOpenSelectedMessage] = useState({});
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [rows, setRows] = useState([]);
  const [assignToMeModal, setAssignToMeModal] = useState(false);
  const [openAssignToModal, setOpenAssignToModal] = useState(false);
  const [assignToList, setAssignToList] = useState([]);
  const [assignToUser, setAssignToUser] = useState("");
  const [assignToUserName, setAssignToUserName] = useState({});
  const [notesKept, setNotesKept] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [isEnable, setIsEnable] = useState(false);
  const [deleteMessageModalOpen, setDeleteMessageModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userIdData, setUserUserId] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    setAssignToList(assignToUsersList);
  }, [assignToUsersList]);
  useEffect(() => {
    if (profileDetails) {
      setUserUserId(profileDetails?.userId);
    }
  }, [profileDetails]);
  useEffect(() => {
    if (userIdData) {
      let params = {
        userId: userIdData,
        messageStatus: "Inbox",
        queryParams: passedQueryParamters
      };
      params.queryParams && actions.getMessagesList(params);
      actions.resetMessageExecuteStatus();
    }
  }, [profileDetails, userIdData]);
  const updateSelectedMessage = ({ row }) => {
    setOpenSelectedMessage(row);
    row.notes !== null ? setNotesKept(row.notes) : setNotesKept("");
  };
  const deletingIdsFromSelectedMessages = () => {
    if (selectedMessages.length) {
      selectedMessages.map((item) => {
        delete item.id;
      });
    }
  };
  const assignToModalOpenHandler = () => {
    setOpenAssignToModal(true);
  };
  const assignToCloseHandler = () => {
    deletingIdsFromSelectedMessages();
    let assignToMessagesBody = selectedMessages.map((value) => ({
      ...value,
      assignedTo: assignToUser != "" ? assignToUser : userId,
      assignedToName: null,
      notes: notesKept,
      messageRecipient: [
        {
          messageId: value.messageId,
          toUser: assignToUser != "" ? assignToUser : userId,
          isRead: false
        }
      ]
    }));
    let payload = {
      userId,
      updatedMessages: assignToMessagesBody
    };
    actions.updateMultipleMessages(payload);
    setSelectionModel([]);
    setAssignToMeModal(true);
  };

  const assignToMeModalCloseHandler = () => {
    setDeleteMessageModalOpen(false);
    setAssignToMeModal(false);
    setOpenAssignToModal(false);
    setAssignToUser("");
  };

  var toggleIsReadMessageStatus = [];
  const changeMessageIsReadStatus = (messageStatus) => {
    toggleIsReadMessageStatus = [];
    selectedMessages.map((value) => {
      toggleIsReadMessageStatus.push({
        toUser: userId,
        isRead: messageStatus,
        messageId: value.messageId
      });
    });
  };

  const markAsReadClicked = () => {
    changeMessageIsReadStatus(true);
    let payload = {
      userId,
      toggleIsReadMessageStatus
    };
    actions.toggleMessageIsReadStatus(payload);
    setSelectionModel([]);
    handleClose();
  };

  const markAsUnreadClicked = () => {
    changeMessageIsReadStatus(false);
    let payload = {
      userId,
      toggleIsReadMessageStatus
    };
    actions.toggleMessageIsReadStatus(payload);
    setSelectionModel([]);
    handleClose();
  };
  useEffect(() => {
    if (toggleSuccess || updatedSuccessMsg) {
      let params = {
        userId,
        messageStatus: "Inbox",
        queryParams: passedQueryParamters
      };
      actions.getMessagesList(params);
      actions.resetMessageExecuteStatus();
    }
  }, [toggleLoading, updatedSuccessMsg]);

  const saveTheAddedNotes = () => {
    deletingIdsFromSelectedMessages();
    let addedNotesData = selectedMessages.map((value) => ({
      ...value,
      notes: notesKept
    }));
    let payload = {
      userId,
      updatedMessages: addedNotesData
    };
    actions.updateMultipleMessages(payload);
  };

  const deleteMessageHandler = () => {
    setDeleteMessageModalOpen(true);
    deletingIdsFromSelectedMessages();
    let updatedMessages = selectedMessages.map((value) => ({
      ...value,
      sentDate: null,
      status: "Deleted",
      updationDate: null
    }));
    let payload = {
      userId,
      updatedMessages
    };
    actions.updateMultipleMessages(payload);
    setSelectionModel([]);
  };
  const downloadHandler = () => {
    selectedMessages[0].messageAttachment.map((item) => {
      let payload = {
        filePath: item.attachmentUrl
      };
      actions.getFile(payload);
    });
    setSelectionModel([]);
  };
  useEffect(() => {
    if (downloadedFileUrl) {
      let a = document.createElement("a");
      a.href = downloadedFileUrl;
      a.download = "document.pdf";
      a.click();
      actions.resetMessageExecuteStatus();
    }
  }, [downloadedFileUrl]);
  const handleInputChange = (e) => {
    setAssignToUser(e.target.value);
  };
  const setNotesHandler = (e) => {
    setNotesKept(e.target.value);
  };

  const messageReplyHandler = () => {
    navigate(`/messages/${selectedMessages[0].messageId}/reply`, {
      state: {
        data: selectedMessages[0]
      }
    });
  };
  useEffect(() => {
    setRows([]);
    if (ListOfMessages) {
      let rows = ListOfMessages.messageList.map((item, index) => {
        item.id = index;
        return item;
      });
      setRows(rows);
    }
  }, [ListOfMessages, updatedSuccessMsg]);
  const handleNewNoteButtonClick = () => {
    setIsEnable(true);
  };
  const handleSaveNoteButtonClick = () => {
    setIsEnable(false);
    saveTheAddedNotes();
  };
  useEffect(() => {
    if (assignToUser == userId) {
      setAssignToUserName(profileDetails?.firstName + " " + profileDetails?.lastName);
    } else {
      setAssignToUserName(assignToList.find((item) => item.userId == assignToUser));
    }
  }, [assignToUser]);
  const columns = [
    {
      field: "sentDate",
      headerName: "DATE",
      type: "date",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography>{row.sentDate}</Typography>;
      }
    },
    {
      field: "type",
      headerName: "TYPE",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Stack direction="row">
            <CircleIcon
              className={row.messageRecipient[0].isRead == true ? "color-blue" : "color-pink"}
              sx={{ fontSize: "11.5px", marginTop: "4px" }}
            />
            <Typography className="word-wrap" marginLeft={2}>
              {row.type}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "assignedToName",
      headerName: "ASSIGNED TO",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography className="word-wrap">
            {row.assignedToName ? row.assignedToName : "--"}
          </Typography>
        );
      }
    },
    {
      field: "patientName",
      headerName: "PATIENT",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Typography className="word-wrap">
            {row.patientFirstName ? row.patientFirstName + " " + row.patientLastName : "--"}
          </Typography>
        );
      }
    },
    {
      field: "subject",
      headerName: "SUBJECT",
      flex: 1,
      renderCell: ({ row }) => {
        return <Typography className="word-wrap">{row.subject}</Typography>;
      }
    }
  ];

  return (
    <Box
      className={`${
        toggleLoading || fileLoading || ListOfMessagesLoading ? "disable-section" : ""
      }`}>
      <Grid container className="mt-15">
        <Grid item xs={0.9} ml={3}>
          <Button
            variant="outlined"
            onClick={messageReplyHandler}
            disabled={selectedMessages.length != 1}>
            Reply
          </Button>
        </Grid>
        <Grid item xs={0.95}>
          <Button
            variant="outlined"
            disabled={selectedMessages.length == 0}
            onClick={deleteMessageHandler}>
            Delete
          </Button>
        </Grid>
        <Grid item xs={2.15}>
          <Button
            variant="outlined"
            onClick={downloadHandler}
            disabled={
              selectedMessages.length != 1 || !selectedMessages[0]?.messageAttachment?.length
            }>
            Download Attachment
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row">
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="outlined"
              disableElevation
              onClick={(event) => setAnchorEl(event.currentTarget)}
              endIcon={<KeyboardArrowDownIcon />}
              disabled={selectedMessages.length == 0}>
              More
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button"
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem onClick={markAsReadClicked} value="read">
                Mark as read
              </MenuItem>
              <MenuItem onClick={markAsUnreadClicked} value="unread">
                Mark as unread
              </MenuItem>
              <MenuItem onClick={assignToModalOpenHandler} value="assignTo">
                Assign to
              </MenuItem>
              <MenuItem onClick={assignToCloseHandler} value="assignToMe">
                Assign to me
              </MenuItem>
            </StyledMenu>
          </Stack>
        </Grid>
      </Grid>
      <Box className="grid-style-Inbox" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          sx={{ mx: 1 }}
          loading={ListOfMessagesLoading || fileLoading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          onRowClick={(e) => {
            updateSelectedMessage(e);
          }}
          checkboxSelection={true}
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
            const selectedIds = new Set(ids);
            setSelectedMessages(rows.filter((row) => selectedIds.has(row.id)));
          }}
          selectionModel={selectionModel}
        />
      </Box>

      <Modal open={assignToMeModal} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="alert-modal">
          <Stack direction="row" className="modal-stack">
            <Typography className="font-w-500">Alert</Typography>
            <IconButton onClick={assignToMeModalCloseHandler}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Box sx={{ py: 1 }}>
            <Typography>
              Message is successfully assigned to{" "}
              {assignToUserName && Object.keys(assignToUserName).length != 0
                ? assignToUserName == profileDetails?.firstName + " " + profileDetails?.lastName
                  ? assignToUserName
                  : assignToUserName?.firstName + " " + assignToUserName?.lastName
                : profileDetails?.firstName + " " + profileDetails?.lastName}
            </Typography>
          </Box>
        </Box>
      </Modal>

      <Modal open={openAssignToModal} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="alert-modal">
          <Stack direction="row" className="modal-stack">
            <Typography className="font-w-500">Assign To</Typography>
            <IconButton onClick={assignToMeModalCloseHandler}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Box sx={{ py: 1 }}>
            <Grid container gap={1}>
              <Grid item xs={4}>
                <Typography>Assign To</Typography>
              </Grid>
              <Grid item xs={7}>
                <Select
                  fullWidth
                  name="assignTo"
                  className="patient-dropdown"
                  value={assignToUser}
                  onChange={handleInputChange}>
                  <MenuItem value={userId}>Me</MenuItem>
                  {assignToList &&
                    assignToList.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.userId}>
                          {item.firstName + " " + item.lastName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Typography>Note (Not visible to patient)</Typography>
              </Grid>
              <Grid item xs={7}>
                <TextField fullWidth onChange={setNotesHandler}></TextField>
              </Grid>
            </Grid>
            <Stack
              direction="row"
              justifyContent="flex-end"
              className="modal-bottom-section"
              spacing={2}>
              <Button onClick={assignToMeModalCloseHandler}>Cancel</Button>
              <Button
                variant="outlined"
                onClick={assignToCloseHandler}
                disabled={assignToUser == ""}>
                Save
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
      {updateMessagesLoading ? (
        <LoadingModal />
      ) : (
        <Modal open={deleteMessageModalOpen} backdrop="static" aria-labelledby="modal-add-title">
          <Box className="alert-modal">
            <Stack direction="row" className="modal-stack">
              <Typography className="font-w-500">Alert</Typography>
              <IconButton onClick={assignToMeModalCloseHandler}>
                <img alt="" src="/Assets/close.png" />
              </IconButton>
            </Stack>
            <Divider className="modal-divider" />
            <Box sx={{ py: 1 }}>
              <Typography>Message is moved to deleted tab succesfully.</Typography>
            </Box>
          </Box>
        </Modal>
      )}
      {Object.keys(openSelectedMessage).length != 0 && (
        <Box className="selected-message-content">
          <Stack>
            <Typography>
              Your patient has selected a {openSelectedMessage.subject} in the patient portal
            </Typography>
            <Typography>
              From:
              {openSelectedMessage.patientFirstName +
                " " +
                openSelectedMessage.patientLastName +
                " "}
              on {openSelectedMessage.sentDate}
            </Typography>
            <Divider fitted="true" sx={{ mt: 2, mb: 2 }} />
            <Stack direction="row" justifyContent="space-between">
              <Stack>
                <Typography>Notes</Typography>
                <Typography>(Not visible to patient)</Typography>
              </Stack>
              <TextField
                disabled={!isEnable}
                fullWidth
                label="Add a new note by using the ADD button"
                variant="outlined"
                value={notesKept}
                onChange={setNotesHandler}
                InputProps={{
                  endAdornment: !isEnable ? (
                    <Button onClick={handleNewNoteButtonClick} color="primary">
                      ADD <AddCircleOutlineIcon />
                    </Button>
                  ) : (
                    <Button onClick={handleSaveNoteButtonClick} color="primary">
                      SAVE
                    </Button>
                  )
                }}
              />
            </Stack>
            <Divider fitted="true" sx={{ mt: 2, mb: 2 }} />
            {openSelectedMessage.body.includes("#br#") ? (
              <Typography style={{ whiteSpace: "pre-line" }} component="span" variant="body1">
                {openSelectedMessage?.body?.split("#br#").join("\n")}
              </Typography>
            ) : (
              <Box
                dangerouslySetInnerHTML={{
                  __html: openSelectedMessage?.body
                }}
              />
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};
const mapStateToProps = ({ messageReducer, profileDrawerReducer }) => {
  return {
    ListOfMessages: messageReducer.ListOfMessages,
    ListOfMessagesLoading: messageReducer.ListOfMessagesLoading,
    toggleLoading: messageReducer.toggleLoading,
    toggleSuccess: messageReducer.toggleSuccess,
    updatedSuccessMsg: messageReducer.updatedSuccessMsg,
    updateMessagesLoading: messageReducer.updateMessagesLoading,
    passedQueryParamters: messageReducer.passedQueryParamters,
    assignToUsersList: messageReducer.assignToUsersList,
    profileDetails: profileDrawerReducer.profileDetails,
    downloadedFileUrl: messageReducer.downloadedFileUrl,
    fileLoading: messageReducer.fileLoading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMessagesList: (payload) => {
      dispatch(actionType.getMessagesList(payload));
    },
    toggleMessageIsReadStatus: (payload) => {
      dispatch(actionType.toggleMessageIsReadStatus(payload));
    },
    updateMultipleMessages: (payload) => {
      dispatch(actionType.updateMultipleMessages(payload));
    },
    resetMessageExecuteStatus: () => {
      dispatch(actionType.resetMessageExecuteStatus());
    },
    getFile: (payload) => {
      dispatch(actionType.getFile(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Inbox);
