import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  MenuItem,
  // Select,
  Button,
  Menu,
  Autocomplete,
  TextField
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import QuickAdd from "../../shared/components/QuickAdd";
import { connect } from "react-redux";
import * as actionType from "../actions";
import PatientDemoGraphic from "./PatientDemoGraphic";
import { Link } from "react-router-dom";
import PatientDetails from "./PatientDetails";
//import EditPatient from "./EditPatient";
import LoadingModal from "../../trak/components/modals/loadingModal";
import { useSelector } from "react-redux";
import isAccess from "../../shared/components/CheckUserRights";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const PatientDirectory = (props) => {
  let inputValue = {
    md: "",
    cycleType: "",
    clinicalLocation: ""
  };
  const columns = [
    {
      field: "firstName",
      headerName: "PATIENT NAME & ID",
      width: 90,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Stack>
            <Typography>{row.firstName + " " + row.lastName}</Typography>
            <Typography>(ID#{row.personKey})</Typography>
          </Stack>
        );
      }
    },
    {
      field: "details",
      headerName: "DETAILS",
      width: 50,
      editable: true,
      flex: 1,
      renderCell: ({ row }) => (
        <Link to={`/patients/${row.personId}/personal`}>
          <ArticleIcon></ArticleIcon>
        </Link>
      )
    },
    {
      field: "partnerName",
      headerName: "PARTNER",
      width: 150,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <Typography>
          {row && row.activePartner && row.activePartner.patientPartnerName
            ? row.activePartner.patientPartnerName
            : "--"}
        </Typography>
      )
    },
    /* {
      field: "mailingCity",
      headerName: "CLINICAL LOCATION",
      width: 150,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => <Typography>{row.mailingCity ? row.mailingCity : "--"}</Typography>
    }, */
    {
      field: "cycleType",
      headerName: "CYCLE TYPE",
      width: 150,
      editable: false,
      flex: 1,
      renderCell: () => <Typography>--</Typography>
    },
    {
      field: "primaryMd",
      headerName: "PRIMARY MD",
      width: 110,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => <Typography>{row.primaryMd ? row.primaryMd : "--"}</Typography>
    }
  ];
  const [toggle, setToggle] = useState(false);
  const [selectedPatientId, setSelectedPatientId] = useState(0);
  const [selectedPatient, setSelectedPatient] = useState({});
  const [values, setValues] = useState(inputValue);
  const [isHide, setIsHide] = useState(true);
  const [primaryMDOptions, setPrimaryMDOptions] = useState([]);
  const prevCountRef = useRef();
  const { actions, personalData = [], primaryMdList = [], loading } = props;
  const [filteredRowData, setFilteredRowData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openAddPatient, setOpenAddPatient] = useState(false);
  const [editPatient, setEditPatient] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState({});
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const practiceId = localStorage.getItem("practiceId");
  const open = Boolean(anchorEl);
  const getProfileDetailsLoading = useSelector((state) => state.profileDrawerReducer.loading);
  useEffect(() => {
    if (personalData.length) {
      //let options = [];
      let rows = personalData.map((item, index) => {
        item.id = index;
        //console.log("zhr 111", !options.find((op) => op.id === item.ivfMD));
        if (item.ivfMDFirstName && item.ivfMD) {
          item.primaryMd = item.ivfMDFirstName + " " + item.ivfMDLastName;
          // options.push({ id: item.ivfMD, name: item.primaryMd });
        }
        if (item.ivfMDFirstName && item.ivfMD) {
          item.primaryMd = item.ivfMDFirstName + " " + item.ivfMDLastName;
        }
        return item;
      });
      // setPrimaryMDOptions(options);
      setFilteredRowData(rows);
    }
  }, [loading]);
  useEffect(() => {
    setFilteredRowData([]);
  }, []);
  useEffect(() => {
    actions.getPrimaryMdList({ practiceId: practiceId });
  }, [practiceId]);

  useEffect(() => {
    if (primaryMdList.length > 0) {
      let options = [];
      primaryMdList.map((item) => {
        options.push({ id: item.ivfMD, name: item.ivfMDFirstName + " " + item.ivfMDLastName });
      });
      setPrimaryMDOptions(options);
    }
  }, [primaryMdList]);

  useEffect(() => {
    if (!toggle & (selectedPatientId === prevCountRef.current)) {
      setSelectedPatientId(0);
    }
  }, [toggle]);

  useEffect(() => {
    prevCountRef.current = selectedPatientId;
  }, [toggle]);

  useEffect(() => {
    actions.resetFAPDetails();
  }, []);

  const toggleFilter = () => {
    setIsHide(!isHide);
  };
  const updateSelectedPatient = ({ row }) => {
    setSelectedPatientId(row.personId);
    setSelectedPerson(row);
    setSelectedPatient(row);
  };
  const handleSearchClick = (e, searchText) => {
    actions.resetPersonalList();
    if (practiceId) {
      setFilteredRowData([]);
      values.nameOrId = searchText;
      actions.getPersonalList({ practiceId, values });
    }
  };
  const clearSearchClick = () => {
    setFilteredRowData([]);
    //setPrimaryMDOptions([]);
  };
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setValues({
  //     ...values,
  //     [name]: value
  //   });
  // };

  const handleAutoChange = (e, name, value) => {
    if (value) {
      setValues({
        ...values,
        [value?.name]: value?.value
      });
    } else {
      setValues({
        ...values,
        [name]: ""
      });
    }
  };
  const resetFilters = () => {
    setValues(inputValue);
  };
  const setClose = (data) => {
    setToggle(!toggle);
    setSelectedPatientId(data);
  };
  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleActionClose = () => {
    setAnchorEl(null);
  };
  const goToPatientForm = () => {
    setOpenAddPatient(true);
    handleActionClose();
  };
  const gotoEditPatientForm = () => {
    setEditPatient(true);
    handleActionClose();
  };
  const closePatientForm = () => {
    setOpenAddPatient(false);
    setEditPatient(false);
    setSelectedPerson({});
    handleActionClose();
  };
  return (
    <>
      <Box
        className={`${
          loading
            ? "align-center pad-5 teal-bg-color m-l-5 directory-container disable-section"
            : "align-center pad-5 teal-bg-color m-l-5 directory-container"
        }`}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={10.5}>
                <QuickAdd
                  text={1.2}
                  search={8}
                  handleSearchClick={handleSearchClick}
                  clearSearchClick={clearSearchClick}
                />
              </Grid>
              <Grid item xs={1}>
                <Stack direction="row" sx={{ width: "fit-content" }}>
                  <Button
                    variant="contained"
                    aria-controls={open ? "actions-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    endIcon={<KeyboardArrowDownIcon />}
                    onClick={handleActionsClick}>
                    Actions
                  </Button>
                  <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleActionClose}
                    MenuListProps={{
                      "aria-labelledby": "actions-button"
                    }}>
                    <MenuItem onClick={goToPatientForm} disabled={isAccess("adpt") ? false : true}>
                      Add patient details
                    </MenuItem>
                    <MenuItem
                      onClick={gotoEditPatientForm}
                      disabled={Object.keys(selectedPerson).length ? false : true}>
                      Edit Patient detail
                    </MenuItem>
                  </Menu>
                </Stack>
              </Grid>
            </Grid>
            <Stack className="pad-5" direction="row">
              <Grid container className="align-center">
                <Grid item xs={1}>
                  <Typography>Primary MD</Typography>
                </Grid>
                <Grid item xs={3} sx={{ marginLeft: "4px" }}>
                  {/* <Select
                    name="md"
                    value={values.md}
                    className="patient-dropdown"
                    onChange={handleInputChange}
                    disabled={!filteredRowData.length}>
                    <MenuItem value="default">All</MenuItem>
                    {primaryMDOptions.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </Select> */}
                  <Autocomplete
                    disablePortal
                    required
                    // disabled={!filteredRowData.length}
                    name="md"
                    value={primaryMDOptions.find((md) => md.id === values.md)?.name}
                    options={primaryMDOptions.map((item, index) => {
                      return {
                        id: index,
                        label: item.name,
                        name: "md",
                        value: item.id
                      };
                    })}
                    onChange={(e, value) => {
                      handleAutoChange(e, "md", value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Primary MD..." sx={{ paddingTop: "7px" }} />
                    )}
                    className="patient-dropdown"
                  />
                </Grid>
                <Grid item xs={1}>
                  <Typography>Cycle Type</Typography>
                </Grid>
                <Grid item xs={3}>
                  {/* <Select
                    name="cycleType"
                    value={values.cycleType}
                    disabled={true}
                    fullWidth
                    sx={{ flex: 6, height: 35 }}
                    onChange={handleInputChange}>
                    <MenuItem value="default">Select</MenuItem>
                  </Select> */}
                  <Autocomplete
                    disablePortal
                    required
                    fullWidth
                    //disabled={!filteredRowData.length}
                    sx={{ flex: 6, height: 35 }}
                    disabled={true}
                    name="cycleType"
                    value={values.cycleType}
                    options={[]}
                    onChange={(e, value) => {
                      handleAutoChange(e, "cycleType", value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Cycle Type..." sx={{ paddingTop: "7px" }} />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Stack direction="row" className="m-l-40 align-center" justifyContent="flex-end">
                    <Typography
                      className="cursor-pointer"
                      color="primary"
                      variant="subtitle1"
                      disabled
                      onClick={resetFilters}>
                      RESET ALL
                    </Typography>
                    <Divider orientation="vertical" sx={{ height: 16, m: "0 6px" }} />

                    <Typography
                      className="cursor-pointer"
                      color="primary"
                      variant="subtitle1"
                      onClick={toggleFilter}>
                      {isHide ? "SHOW ADVANCED" : "HIDE ADVANCED"}
                    </Typography>
                    {isHide ? (
                      <KeyboardArrowDownIcon
                        color="primary"
                        className="cursor-pointer"
                        onClick={toggleFilter}></KeyboardArrowDownIcon>
                    ) : (
                      <KeyboardArrowUpIcon
                        className="cursor-pointer"
                        color="primary"
                        onClick={toggleFilter}></KeyboardArrowUpIcon>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            {!isHide && (
              <Stack direction="row" className="pad-5" sx={{ marginTop: "10px" }}>
                <Grid container className="align-center">
                  <Grid item xs={1}>
                    <Typography>Clinical Location</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {/* <Select
                      name="clinicalLocation"
                      value={values.clinicalLocation}
                      className="patient-dropdown"
                      disabled={true}
                      onChange={handleInputChange}>
                      <MenuItem value="default">Select</MenuItem>
                    </Select> */}
                    <Autocomplete
                      disablePortal
                      className="patient-dropdown"
                      disabled={true}
                      name="clinicalLocation"
                      value={values.clinicalLocation}
                      options={[]}
                      onChange={(e, value) => {
                        handleAutoChange(e, "clinicalLocation", value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Clinical Location..." />
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
            )}
            <Box className="grid-style" sx={{ height: "660px" }}>
              <Typography>{filteredRowData.length} Estimates matching your criteria</Typography>
              <DataGridPremiumComp
                rows={filteredRowData}
                rowCount={filteredRowData.length}
                columns={columns}
                className="m-t-5"
                loading={loading}
                onRowClick={(e) => {
                  setToggle(!toggle);
                  updateSelectedPatient(e);
                  sessionStorage.setItem("personId", e.row.personId);
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                pageSize={pageSize.pageSize}
                paginationModel={pageSize}
                onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
                pagination
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            className={selectedPatientId !== 0 ? "patient-context" : "display-none"}>
            {selectedPatientId !== 0 ? (
              <PatientDemoGraphic patientData={selectedPatient} close={setClose} />
            ) : null}
          </Grid>
        </Grid>
        {openAddPatient ? (
          <PatientDetails isEdit={false} open={openAddPatient} close={closePatientForm} />
        ) : null}
        {editPatient ? (
          <PatientDetails
            open={editPatient}
            isEdit={true}
            selectedPatient={selectedPerson}
            close={closePatientForm}
          />
        ) : null}
      </Box>
      <LoadingModal open={getProfileDetailsLoading} />
    </>
  );
};
const mapStateToProps = ({ patientReducer }) => {
  return {
    personalData: patientReducer.personalData.data,
    loading: patientReducer.loading,
    primaryMdList: patientReducer.primaryMdList
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPersonalList: (payload) => {
      dispatch(actionType.getPersonalList(payload));
    },
    resetPersonalList: () => {
      dispatch(actionType.resetPersonalList());
    },
    resetFAPDetails: () => {
      dispatch(actionType.resetFAPDetails());
    },
    getPrimaryMdList: (payload) => {
      dispatch(actionType.getPrimaryMdList(payload));
    },
    resetPrimaryMd: () => {
      dispatch(actionType.resetPrimaryMd());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientDirectory);
