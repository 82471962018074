import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, Box, Button, TextField, Typography } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import {
  GRID_AGGREGATION_FUNCTIONS,
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext
} from "@mui/x-data-grid-premium";
import MessageModal from "../../shared/components/MessageModal";
import ReimbursementDetails from "./ReimbursementDetails";
import PricingModal from "./modals/PricingModal";
import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
import moment from "moment";
import { getPractices } from "../../admin/actions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as XLSX from "xlsx";

const Exported = (props) => {
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [selectedPRId, setSelectedPRId] = useState(0);
  const [selectedPR, setSelectedPR] = useState({});
  const [openPricingModal, setIsOpenPricingModal] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const updateSelectedPR = (e, check) => {
    console.log("e:", e);
    if (check === "cell") {
      const { row, value } = e;
      if (!value) {
        setSelectedPRId(row.medcaseId);
        setSelectedPR(row);
      } else {
        setSelectedPRId(0);
        setSelectedPR({});
      }
    }
  };

  const formateCommasepratedNumber = (actualAmount) => {
    let amt = new Intl.NumberFormat().format(actualAmount);
    let convertedAmt = amt.toLocaleString("en-US");
    return convertedAmt;
  };

  const totalSumAggregation = {
    apply: (params) => {
      if (params.values.length === 0) {
        return null;
      }
      let sum = 0;

      params.values.forEach((value) => {
        // let cur = value.split("$");
        // console.log("cur: ", cur);
        // console.log("cur1", cur[0]);
        // console.log("cur2", cur[1]);
        sum += Number(value);
        //sum += Number(cur[1].replace(/,/g, ""));
      });

      let updatedValu = formateCommasepratedNumber(sum);

      return updatedValu;
    },
    label: "",
    valueFormatter: (value) => `${value}`,
    columnTypes: ["number"]
  };

  const aggregationHeading = {
    apply: () => {},
    label: "",
    valueFormatter: () => `Total: `,
    columnTypes: ["string"]
  };

  const columns = [
    {
      field: "medcaseId",
      headerName: "MEDCASE ID",
      width: 90,
      editable: false,
      availableAggregationFunctions: ["heading"]
    },
    {
      field: "clinicalTreatmentNo",
      headerName: "CYCLE NO.",
      width: 80,
      editable: false
    },
    {
      field: "patientFirstName",
      headerName: "FIRST NAME",
      width: 100,
      editable: false
    },
    {
      field: "patientLastName",
      headerName: "LAST NAME",
      width: 90,
      editable: false
    },
    {
      field: "approvedReimbursementAmount",
      headerName: "AMOUNT",
      width: 75,
      editable: false,
      availableAggregationFunctions: ["totalSum"],
      valueParser: (e) => Number(e),
      valueFormatter: (e) => formateCommasepratedNumber(e),
      type: "number"
      // renderCell: ({ row }) => {
      //   return "$" + priceFormat(row.approvedReimbursementAmount);
      // }
    },
    {
      field: "progShortName",
      headerName: "PROGRAM SHORT NAME",
      width: 80,
      editable: false
    },
    {
      field: "medcaseStatus",
      headerName: "MEDCASE STATUS",
      width: 80,
      editable: false
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      width: 80,
      editable: false
    },
    {
      field: "transactionTypeDesc",
      headerName: "TRANSACTION TYPE",
      width: 140,
      editable: false
    },
    {
      field: "outcomeStatus",
      headerName: "OUTCOME",
      width: 90,
      editable: false
    },
    {
      field: "embryosRemaining",
      headerName: "EMBRYOS REMAINING",
      width: 100,
      editable: false
    },
    {
      field: "normalembryosRemaining",
      headerName: "NORMAL EMBRYOS REMAINING",
      width: 100,
      editable: false
    },
    {
      field: "requestedTreatmentPlan",
      headerName: "AUTH SERVICE",
      width: 85,
      editable: false
    },
    {
      field: "creationDate",
      headerName: "CREATED DATE",
      width: 150,
      editable: false,
      renderCell: ({ row }) =>
        row.creationDate ? moment(row.creationDate).format("MM/DD/YYYY") : ""
    },
    {
      field: "createdBy",
      headerName: "CREATED USER",
      width: 150,
      editable: false
    },
    {
      field: "approvedDateUI",
      headerName: "APPROVED DATE",
      width: 150,
      editable: false
    },
    {
      field: "approvedUser",
      headerName: "APPROVED USER",
      width: 150,
      editable: false
    },
    {
      field: "exportedDate",
      headerName: "EXPORTED DATE",
      width: 150,
      editable: false
    },
    {
      field: "exportedUser",
      headerName: "EXPORTED USER",
      width: 150,
      editable: false
    },
    {
      field: "rejectedDatez",
      headerName: "REJECTED DATE",
      width: 150,
      editable: false
    },
    {
      field: "rejectedUserz",
      headerName: "REJECTED USER",
      width: 150,
      editable: false
    }
  ];

  const {
    providerReimbursementList = [],
    actions,
    addUnApproved,
    editUnApproved,
    loading,
    errorMsg,
    successMsg,
    newPrList,
    practices
  } = props;

  // formate date in mm/dd/yy formate
  // arg as new Date()
  const formatDate = (date) => {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, "0");
    const day = d.getDate().toString().padStart(2, "0");
    const year = d.getFullYear().toString();
    const format2 = "YYYY-MM-DD";
    const newValue = moment(`${month}-${day}-${year}`).format(format2);
    return newValue;
  };

  const defaultValues = {
    practiceId: "all",
    from: formatDate(new Date()),
    to: formatDate(new Date())
  };

  const [values, setValues] = useState(defaultValues);

  const modifyCol = (rows) => {
    return rows.map((row) => {
      return {
        ...row,
        approvedUser: row.updatedBy,
        exportedUser: row.updatedBy,
        creationDate: row.creationDate ? moment(row.creationDate).format("MM/DD/YYYY") : null,
        exportedDate: row.exportedDate ? moment(row.exportedDate).format("MM/DD/YYYY") : null,
        approvedDateUI: row.approvedDate ? moment(row.approvedDate).format("MM/DD/YYYY") : null
      };
    });
  };

  const rows = modifyCol(
    providerReimbursementList.map((item, index) => {
      item.id = index;
      return item;
    })
  );

  const initFetch = useCallback(() => {
    const payload = {
      exportedDateFrom: values.from,
      exportedDateTo: values.to,
      practiceId: values.practiceId,
      status: "Exported"
    };
    actions.getProviderReimbursement(payload);
  }, [addUnApproved, editUnApproved]);

  useEffect(() => {
    const payload = {
      exportedDateFrom: values.from,
      exportedDateTo: values.to,
      practiceId: values.practiceId,
      status: "Exported"
    };
    actions.getProviderReimbursement(payload);
    actions.getPractices();
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal();
    }

    if (providerReimbursementList.length == 0 || addUnApproved || editUnApproved) {
      initFetch();
    }
    return () => {
      actions.resetProviderReimbursementList();
    };
  }, [addUnApproved, editUnApproved, errorMsg, successMsg]);
  useEffect(() => {
    if (Object.entries(selectedPR).length > 0) {
      let payload = {
        medcaseId: selectedPR.medcaseId,
        clinicalTreatmentNo: selectedPR.clinicalTreatmentNo
      };
      actions.createProgramCycle(payload);
    }
  }, [selectedPRId, providerReimbursementList]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const refresh = () => {
    const payload = {
      exportedDateFrom: values.from,
      exportedDateTo: values.to,
      practiceId: values.practiceId,
      status: "Exported"
    };
    actions.getProviderReimbursement(payload);
  };
  const openPricing = () => {
    setIsOpenPricingModal(true);
  };
  const closePricingModal = () => {
    setIsOpenPricingModal(false);
  };
  // const disablePricing = () => {
  //   if (selectedPRId != null) {
  //     return false;
  //   } else {
  //     return true;
  //   }
  // };

  const handleValuesChange = (e, name, value) => {
    if (value) {
      setValues({ ...values, [name]: value.value });
    } else {
      setValues({ ...values, [name]: "" });
    }
  };

  const [excelDownloadForPP, setExcelDownloadForPP] = useState(false);
  const [excelDownloadForPR, setExcelDownloadForPR] = useState(false);

  const handlePaymentPosting = () => {
    const payload = {
      exportedDateFrom: values.from,
      exportedDateTo: values.to,
      practiceId: values.practiceId,
      status: "Exported"
    };
    console.log("zhr payload", payload);
    actions.getProviderReimbursement(payload);

    setExcelDownloadForPP(true);
  };

  const handleProviderReimbursement = () => {
    const payload = {
      exportedDateFrom: values.from,
      exportedDateTo: values.to,
      practiceId: values.practiceId,
      status: "Exported"
    };
    console.log("zhr payload", payload);
    actions.getProviderReimbursement(payload);

    setExcelDownloadForPR(true);
  };

  const handleSubmit = () => {
    const payload = {
      exportedDateFrom: values.from,
      exportedDateTo: values.to,
      practiceId: values.practiceId,
      status: "Exported"
    };
    console.log("zhr payload", payload);
    actions.getProviderReimbursement(payload);
  };

  useEffect(() => {
    if (excelDownloadForPP) {
      // Process data to get the columns we need
      try {
        const reportData = providerReimbursementList.map((item) => ({
          "Person No": item.personKey,
          "Medcase ID": item.medcaseId,
          "Enrolled Date": item.enrollementDate
            ? moment(item.enrollementDate).format("MM/DD/YYYY")
            : "",
          "Last Name": item.patientLastName,
          "First Name": item.patientFirstName,
          DOB: item.dob ? moment(item.dob).format("MM/DD/YYYY") : "",
          Practice: item?.practiceName,
          Amount: `$ ${priceFormat(item?.approvedReimbursementAmount)}`,
          "Cycle No": item.clinicalTreatmentNo,
          "Performed Service": item.performedTreatmentPlan,
          Outcome: item.outcomeStatus,
          "Outcome Date": item.outcomeDate ? moment(item.outcomeDate).format("MM/DD/YYYY") : "",
          Comments: item.comment
        })); // Generate Excel file

        const totalAmount = providerReimbursementList.reduce(
          (sum, item) => sum + item.approvedReimbursementAmount,
          0
        );

        reportData.push({
          "Person No": "Total: ",
          "Medcase ID": "",
          "Enrolled Date": "",
          "Last Name": "",
          "First Name": "",
          DOB: "",
          Practice: "",
          Amount: `$ ${priceFormat(totalAmount)}`,
          "Cycle No": "",
          "Performed Service": "",
          Outcome: "",
          "Outcome Date": "",
          Comments: ""
        });

        const worksheet = XLSX.utils.json_to_sheet(reportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Payment Posting Report"); // Write workbook to binary string
        const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" }); // Create Blob and trigger download
        const blob = new Blob([wbout], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "PaymentPostingReport.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error generating report:", error);
      }

      setExcelDownloadForPP(false);
    }
  }, [providerReimbursementList]);

  useEffect(() => {
    if (excelDownloadForPR) {
      // Process data to get the columns we need
      try {
        const reportData = providerReimbursementList.map((item) => ({
          BILL_NO: item?.patientLastName,
          Vendor_ID: item?.vendorId,
          Created_Date: item?.exportedDate ? moment(item.exportedDate).format("MM/DD/YYYY") : "",
          Description: `${item?.patientFirstName} ${moment(item?.dob).format("MM/DD/YYYY")}`,
          Memo: `${item?.patientFirstName} ${moment(item?.dob).format("MM/DD/YYYY")}`,
          LINE_NO: item?.lineNumber,
          ACCT_NO: item?.accountNumber,
          LOCATION_ID: item?.practiceProjectLocation,
          DEPT_ID: item?.deptId,
          APBILLITEM_PROJECTID: item?.projectId,
          AMOUNT: `$ ${priceFormat(item?.approvedReimbursementAmount)}`,
          APBILLITEM_VENDORID: item?.vendorId
        })); // Generate Excel file

        const totalAmount = providerReimbursementList.reduce(
          (sum, item) => sum + item.approvedReimbursementAmount,
          0
        );

        reportData.push({
          BILL_NO: "Total: ",
          Vendor_ID: "",
          Created_Date: "",
          Description: "",
          Memo: "",
          LINE_NO: "",
          ACCT_NO: "",
          LOCATION_ID: "",
          DEPT_ID: "",
          APBILLITEM_PROJECTID: "",
          AMOUNT: `$ ${priceFormat(totalAmount)}`,
          APBILLITEM_VENDORID: ""
        });

        const worksheet = XLSX.utils.json_to_sheet(reportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Provider Reimbursement Report"); // Write workbook to binary string
        const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" }); // Create Blob and trigger download
        const blob = new Blob([wbout], { type: "application/octet-stream" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "ProviderReimbursement.xlsx";
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error generating report:", error);
      }

      setExcelDownloadForPR(false);
    }
  }, [providerReimbursementList]);

  const getRowsFromCurrentPage = ({ apiRef }) =>
    gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

  const CustomToolbar = () => {
    const apiRef = useGridApiContext();
    const handleExport = (options) => apiRef.current.exportDataAsCsv(options);
    return (
      <Box sx={{ backgroundColor: "#f5f5f5", padding: "10px 20px", borderRadius: "8px" }}>
        {/* First Row - Filters */}
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginBottom: 2 }}>
          <Typography sx={{ fontWeight: "bold" }}>Exported List</Typography>
          {/* Practice Dropdown */}
          <Autocomplete
            disablePortal
            required
            name="practiceId"
            value={
              values?.practiceId !== "all"
                ? practices.find((practice) => practice.practiceId === values?.practiceId)?.name
                : "all"
            }
            options={[
              { id: 1, label: "All", value: "all" },
              ...practices.map((item, index) => ({
                id: index + 1,
                label: item.name,
                value: item.practiceId
              }))
            ]}
            onChange={(e, value) => {
              handleValuesChange(e, "practiceId", value);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Practice" sx={{ minWidth: "200px" }} />
            )}
          />
          {/* Exported Date From */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              openTo="day"
              views={["day", "year", "month"]}
              disableFuture
              value={values.from}
              onChange={(newValue) => {
                const format2 = "YYYY-MM-DD";
                newValue = moment(newValue).format(format2);
                setValues({ ...values, from: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  className="patient-dropdown"
                  label="Export date from"
                />
              )}></DatePicker>
          </LocalizationProvider>
          {/* Exported Date To */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              openTo="day"
              views={["day", "year", "month"]}
              disableFuture
              value={values.to}
              onChange={(newValue) => {
                const format2 = "YYYY-MM-DD";
                newValue = moment(newValue).format(format2);
                setValues({ ...values, to: newValue });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  className="patient-dropdown"
                  label="Export date to"
                />
              )}></DatePicker>
          </LocalizationProvider>
        </Box>
        {/* Second Row - Buttons */}
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#009688",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              "&:hover": { backgroundColor: "#00796b" },
              width: "100px"
            }}
            onClick={refresh}>
            Refresh
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#e0e0e0",
              color: "#9e9e9e",
              fontWeight: "bold",
              textTransform: "none",
              width: "100px"
            }}
            onClick={openPricing}
            disabled={selectionModel.length === 0}>
            Pricing
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#009688",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              "&:hover": { backgroundColor: "#00796b" },
              width: "140px"
            }}
            onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage })}>
            Send To Excel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#00796b",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              width: "180px",
              "&:hover": { backgroundColor: "#004d40" }
            }}
            onClick={handlePaymentPosting}>
            PAYMENT POSTING
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#00796b",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              width: "200px",
              "&:hover": { backgroundColor: "#004d40" }
            }}
            onClick={handleProviderReimbursement}>
            PROVIDER REIMBURSEMENTS
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#009688",
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
              "&:hover": { backgroundColor: "#00796b" },
              width: "100px"
            }}
            onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onCellClick={(e) => {
            updateSelectedPR(e, "cell");
          }}
          onColumnHeaderClick={(e) => {
            updateSelectedPR(e, "header");
          }}
          checkboxSelection
          onRowSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
          rowSelectionModel={selectionModel}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          components={<CustomToolbar />}
          aggregationFunctions={{
            ...GRID_AGGREGATION_FUNCTIONS,
            totalSum: totalSumAggregation,
            heading: aggregationHeading
          }}
          initialState={{
            aggregation: {
              model: {
                medcaseId: "heading",
                approvedReimbursementAmount: "totalSum"
              }
            },
            sorting: {
              sortModel: [{ field: "creationDate", sort: "desc" }]
            }
          }}
          headerText={`Exported Practice: ${values?.practiceId} Start Date: ${values.from} End Date: ${values.to}`}
          disableRowSelectionOnClick={true}
          customAggModel={{
            medcaseId: "heading",
            approvedReimbursementAmount: "totalSum"
          }}
        />
      </Box>
      {openPricingModal ? (
        <PricingModal
          open={openPricingModal}
          close={closePricingModal}
          newPrList={newPrList}
          selectedPR={selectedPR}
        />
      ) : (
        ""
      )}

      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <ReimbursementDetails
        selectedPRId={selectedPRId}
        selectedPR={selectedPR}
        tabName="Exported"
      />
    </>
  );
};
const mapStateToProps = ({ providerReimbursementReducer, newPrReducer, practices }) => {
  return {
    loading: providerReimbursementReducer.loading,
    providerReimbursementList: providerReimbursementReducer.providerReimbursementList,
    addProviderReimbursement: providerReimbursementReducer.addProviderReimbursement,
    errorMsg: providerReimbursementReducer.errorMsg,
    successMsg: providerReimbursementReducer.success,
    newPrList: newPrReducer.newPrList,
    practices: practices.practices
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getProviderReimbursement: (payload) => {
      dispatch(actionType.getProviderReimbursement(payload));
    },
    addAproovedReimbursement: (payload) => {
      dispatch(actionType.addAproovedReimbursement(payload));
    },
    addNewProviderReimbursement: (payload) => {
      dispatch(actionType.addNewProviderReimbursement(payload));
    },
    resetProviderReimbursementList: () => {
      dispatch(actionType.resetProviderReimbursementList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    createProgramCycle: (payload) => {
      dispatch(actionType.createProgramCycle(payload));
    },
    getPractices: () => {
      dispatch(getPractices());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Exported);
