import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button, TextField, InputLabel } from "@mui/material";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "60%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4
};
const EditStagesTreatmentPlan = (props) => {
  const [globalPrice, setGlobalPrice] = useState("");
  const [btnText, setCreateBtn] = useState("Save");
  useEffect(() => {
    setCreateBtn("Save");
    if (props) {
      setGlobalPrice(props.data.globalPrice);
    }
  }, [props]);
  const handleEditStages = () => {
    let payload = {};
    payload.globalPrice = globalPrice;
    payload.edit = true;
    payload.stageData = props.data;
    props.editStages(payload);
  };

  return (
    <div>
      <Modal
        open={props.open}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box sx={style}>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography id="modal-new-practice-payment" color="text.primary">
              Edit Stages
            </Typography>
            <IconButton
              onClick={() => {
                props.closeModal();
              }}>
              <Close color="primary" />
            </IconButton>
          </Stack>
          <Divider sx={{ color: "primary" }} orientation="horizontal" />
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 2, alignItems: "center", justifyContent: "flex-end" }}>
            <InputLabel className="flex-2">Description</InputLabel>
            <TextField
              sx={{ flex: 6 }}
              name="name"
              size="small"
              className="background-grey"
              value={props.data.stageName}
              disabled
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 2, alignItems: "center", justifyContent: "flex-end" }}>
            <InputLabel className="flex-2 word-break">Stage Code/SIM Group</InputLabel>
            <TextField
              sx={{ flex: 6 }}
              name="name"
              size="small"
              className="background-grey"
              value={props.data.stageCode}
              disabled
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 2, alignItems: "center", justifyContent: "flex-end" }}>
            <InputLabel className="flex-2 word-break">Total Stage Price</InputLabel>
            <TextField
              sx={{ flex: 6 }}
              name="name"
              size="small"
              type="number"
              className="background-grey"
              value={props.data.totalPrice}
              disabled
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 2, alignItems: "center", justifyContent: "flex-end" }}>
            <InputLabel className="flex-2 word-break">Global Stage Price</InputLabel>
            <TextField
              sx={{ flex: 6 }}
              name="name"
              size="small"
              type="number"
              value={globalPrice}
              onChange={(e) => setGlobalPrice(e.target.value)}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 2, alignItems: "center", justifyContent: "flex-end" }}>
            <InputLabel className="flex-2 word-break">Assist Stage Price</InputLabel>
            <TextField
              sx={{ flex: 6 }}
              name="name"
              size="small"
              type="number"
              className="background-grey"
              value={props.data.assistPrice}
              disabled
            />
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
            <Button
              variant="outlined"
              onClick={() => {
                props.closeModal();
              }}
              sx={{ border: "2px solid primary" }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleEditStages}
              disabled={props.loading}
              sx={{
                background: "primary",
                "&:hover": {
                  color: "primary"
                }
              }}>
              {btnText}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
};

export default EditStagesTreatmentPlan;
