import * as actionType from "../actions/ActionTypes";

const initialState = {
  providerReimbursementList: [],
  approvedList: [],
  addServiceItems: null,
  editServiceItems: null,
  loading: false,
  error: null,
  errorMsg: null,
  success: null,
  successPR: null,
  errorPR: null,
  successRunPR: null,
  successEditPR: null,
  successAdd: null,
  successCheck: null
};

const providerReimbursementReducer = (state = initialState, action) => {
  switch (action.type) {
    //get unapproved
    case actionType.PROVIDEREIMBURSEMENT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PROVIDEREIMBURSEMENT_LIST_SUCCESS: {
      return {
        ...state,
        providerReimbursementList: action.payload,
        loading: false
      };
    }
    case actionType.PROVIDEREIMBURSEMENT_LIST_FAIL: {
      return {
        ...state,
        errorMsg: action.errorMsg,
        loading: false
      };
    }
    //add Approved
    case actionType.ADD_APPROVED_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_APPROVED_SUCCESS: {
      return {
        ...state,
        approvedList: action.payload,
        loading: false,
        successCheck: true
      };
    }
    case actionType.ADD_APPROVED_FAILED: {
      return {
        ...state,
        errorMsg: action.errorMsg || action.message,
        loading: false,
        successCheck: false
      };
    }
    case actionType.RUN_PR_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.RUN_PR_SUCCESS: {
      return {
        ...state,
        loading: false,
        successRunPR: action.successMsg
      };
    }
    case actionType.RUN_PR_FAILED: {
      return {
        ...state,
        errorMsg: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_PROVIDEREIMBURSEMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_PROVIDEREIMBURSEMENT_SUCCESS: {
      return {
        ...state,
        // addRelativeValue: action.payload,
        loading: false,
        successAdd: action.successMsg
      };
    }
    case actionType.ADD_NEW_PROVIDEREIMBURSEMENT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.EDIT_PROVIDEREIMBURSEMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PROVIDEREIMBURSEMENT_SUCCESS: {
      return {
        ...state,
        // editRelativeValue: action.payload,
        successEditPR: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_PROVIDEREIMBURSEMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.EDIT_PR_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PR_SUCCESS: {
      return {
        ...state,
        loading: false,
        successEditPR: action.successMsg
      };
    }
    case actionType.EDIT_PR_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false,
        successRunPR: false,
        successEditPR: false,
        successCheck: false,
        errorMsg: null
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default providerReimbursementReducer;
