import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Admin from "../modules/admin";
import Contract from "../modules/trak/components/Contract";
import CaseLookup from "../modules/trak/components/CaseLookup";
import CaseLookupContext from "../modules/trak/components/CaseLookupContext";
import Comment from "../modules/trak/components/Comment";
import CaseUpdate from "../modules/trak/components/CaseUpdate";
import Document from "../modules/trak/components/Document";
import DocumentLibrary from "../modules/admin/components/DocumentLibrary";
import FinancialEstimates from "../modules/workspace/components/FinancialEstimates";
import FinancialEstimateDetails from "../modules/workspace/components/FinancialEstimateDetails";
import FeeSchedule from "../modules/admin/components/FeeSchedule";
import FinSnapshot from "../modules/trak/components/FinSnapshot";
import FAPDetails from "../modules/workspace/components/FAPDetails";
import FAP from "../modules/workspace/components/FAP";
import GeneticTesting from "../modules/trak/components/GeneticTesting";
import GlobalDataModelContext from "../modules/admin/components/GlobalDataModelContext";
import GlobalDataModel from "../modules/admin/components/GlobalDataModel";
import Header from "../modules/shared/components/Header";
import LocationDetails from "../modules/admin/components/LocationDetails";
import LocationProgramDef from "../modules/admin/components/LocationProgramDef";
import NotFound from "../modules/shared/components/NotFound";
import Pgta from "../modules/admin/components/Pgta";
import PhysicianReimburesmentScale from "../modules/admin/components/PhysicianReimburesmentScale";
import ProviderReimbursement from "../modules/trak/components/ProviderReimbursement";
import PatientInfo from "../modules/trak/components/PatientInfo";
import PatientInfoAddress from "../modules/trak/components/PatientInfoAddress";
import Phone from "../modules/trak/components/Phone";
import PatientInfoEmail from "../modules/trak/components/PatientInfoEmail";
import PartnerEmail from "../modules/trak/components/PartnerEmail";
import PartnerAddress from "../modules/trak/components/PartnerAddress";
import PartnerPhone from "../modules/trak/components/PartnerPhone";
import ProgramVisualization from "../modules/admin/components/ProgramVisualization";
import PatientPaymentDetails from "../modules/trak/components/PatientPaymentDetails";
import PatientDirectory from "../modules/patients/components/PatientDirectory";
import Patients from "../modules/patients";
import Personal from "../modules/patients/components/Personal";
import PracticePayment from "../modules/admin/components/PracticePayment";
import Practice from "../modules/admin/components/Practice";
import PatientPortalAdmin from "../modules/admin/components/PatientPortalAdmin";
import PracticeDetails from "../modules/admin/components/PracticeDetails";
import ProgramMultiplier from "../modules/admin/components/ProgramMultiplier";
import Questions from "../modules/admin/components/Questions";
import Report from "../modules/trak/components/Report";
import RefundApplication from "../modules/trak/components/RefundApplication";
import Refund from "../modules/trak/components/Refund";
import ReimbursementDetails from "../modules/trak/components/ReimbursementDetails";
import RefundAge from "../modules/admin/components/RefundAge";
import RelativeValue from "../modules/admin/components/RelativeValue";
import Revenue from "../modules/trak/components/Revenue";
import Status from "../modules/trak/components/Status";
import ServiceItems from "../modules/admin/components/ServiceItems";
import StaffAdmin from "../modules/admin/components/StaffAdmin";
import Stages from "../modules/admin/components/Stages";
import StageDetails from "../modules/admin/components/StageDetails";
import TreatmentPlan from "../modules/admin/components/TreatmentPlan";
import TreatmentPlanDetails from "../modules/admin/components/TreatmentPlanDetails";
import Trak from "../modules/trak";
import Workspace from "../modules/workspace";
import Clinical from "../modules/patients/components/Clinical";
import Messages from "../modules/shared/messages";
import Inbox from "../modules/shared/messages/Inbox";
import Deleted from "../modules/shared/messages/Deleted";
import Draft from "../modules/shared/messages/Draft";
import Sent from "../modules/shared/messages/Sent";
import Reply from "../modules/shared/messages/Reply";
import CreateNewUser from "../modules/admin/components/userroles/CreateNewUser";
import UsersFilter from "../modules/admin/components/NewUserForm";
import NotificationView from "../modules/utilities/components/NotificationView";
import Utilities from "../modules/utilities";
import ContractMapping from "../modules/admin/components/ContractMapping";
import EmailTemplate from "../modules/admin/components/EmailTemplate";
import PGTALabs from "../modules/admin/components/PGTALabs";
import StaffAdminUserDetails from "../modules/admin/components/StaffAdminUserDetails";
import Logout from "../modules/shared/components/Logout";
import Login from "../modules/shared/components/Login";
import LoadFromExcel from "../modules/admin/components/LoadFromExcel";
import PatientFAP from "../modules/patients/components/PatientFAP";
import RequestedCycle from "../modules/trak/components/RequestedCycle";
import TrakFinancialEstimates from "../modules/trak/components/TrakFinancialEstimates";

const FelixRoutes = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/workspace" element={<Workspace />} key={1}>
          <Route path="financial-estimates" element={<FinancialEstimates />} />
          <Route path="fertility-access-program" element={<FAP />} />
          <Route path="financial-estimates/:personId" element={<FinancialEstimateDetails />} />
          <Route path="fertility-access-program/:patientId" element={<FAPDetails />} />
        </Route>
        <Route exact path="/patients" element={<Patients />} key={2}>
          <Route exact path="patient-directory" element={<PatientDirectory />} />
          <Route exact path=":personId/personal" element={<Personal />} />
          <Route exact path=":personId/clinical" element={<Clinical />} />
          <Route exact path=":personId/fertilityAccessProgram" element={<PatientFAP />} />
          <Route exact path=":personId/document" element={<Personal />} />
        </Route>
        <Route exact path="/admin" element={<Admin />} key={3}>
          <Route path="practice-payment" element={<PracticePayment />} />
          <Route path="practice" element={<Practice />} />
          <Route path="serviceItems" element={<ServiceItems />} />
          <Route path="treatmentPlan" element={<TreatmentPlan />} />
          <Route path="practice/:practiceId" element={<PracticeDetails />} />
          <Route path="practice/:practiceId/:locationId" element={<LocationDetails />} />
          <Route path="practice/:practiceId/:locationId" element={<LocationDetails />} />
          <Route path="physician-reimbursement-scale" element={<PhysicianReimburesmentScale />} />
          <Route path="practiceConfiguration/relativeValue" element={<RelativeValue />} />
          <Route path="gdm" element={<GlobalDataModel />} />
          <Route path="refund-age" element={<RefundAge />} />
          <Route path="program-visualization" element={<ProgramVisualization />} />
          <Route path="pgta" element={<Pgta />} />
          <Route path="patient-portal-admin" element={<PatientPortalAdmin />} />
          <Route path="staff-admin" element={<StaffAdmin />} />
          <Route path="staff-admin/user-details" element={<StaffAdminUserDetails />} />
          <Route path="document-library" element={<DocumentLibrary />} />
          {/* <Route path="newuserform" element={<NewUserForm />} /> */}
          <Route path="createuserform" element={<CreateNewUser />} />
          {/* <Route path="user-tab" element={<UsersFilter/>} /> */}
          <Route path="user-tab" element={<UsersFilter />} />
          <Route path="contract-Template-Mapping" element={<ContractMapping />} />
          <Route path="contract-Template-Mapping/loadByExcel" element={<LoadFromExcel />} />
          <Route path="email-template" element={<EmailTemplate />} />
          <Route path="pgta-labs" element={<PGTALabs />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route exact path="/admin/gdm" element={<GlobalDataModelContext />} key={4}>
          <Route path="globalDataModelContext" element={<ServiceItems />} />
          <Route path="serviceItems" element={<ServiceItems />} />
          <Route path="stages" element={<Stages />} />
          <Route path="stages/:stageId" element={<StageDetails />} />
          <Route path="questions" element={<Questions />} />
          <Route path="treatmentPlan" element={<TreatmentPlan />} />
          <Route path="treatmentPlan/:treatmentPlanId" element={<TreatmentPlanDetails />} />
          <Route path="treatmentPlanDetails" element={<TreatmentPlanDetails />} />
          <Route path="fees-schedule" element={<FeeSchedule />} />
          <Route path="program-multiplier" element={<ProgramMultiplier />} />
          <Route path="location-program-definition" element={<LocationProgramDef />} />
          <Route path="serviceItems/loadByExcel" element={<LoadFromExcel />} />
          <Route path="stages/loadByExcel" element={<LoadFromExcel />} />
          <Route path="treatmentPlan/loadByExcel" element={<LoadFromExcel />} />
          <Route path="program-multiplier/loadByExcel" element={<LoadFromExcel />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route exact path="/trak" element={<Trak />} key={2}>
          <Route path="caselookup" element={<CaseLookup />} />
          <Route path="caselookup/caselist" element={<CaseLookup />} />
          <Route path="contract" element={<Contract />} />
          <Route path="provider-reimbursement" element={<ProviderReimbursement />} />
          <Route path="reimbursement-Details" element={<ReimbursementDetails />} />
          <Route path="refund" element={<Refund />} />
          <Route path="revenue" element={<Revenue />} />
          <Route path="report" element={<Report />} />
          <Route path="refund-application" element={<RefundApplication />} />
          <Route path="refund-application/:patientId" element={<FAPDetails />} />
          <Route exact path="trak-patient-portal" element={<PatientPortalAdmin />} />
          <Route path="requested-cycle" element={<RequestedCycle />} />
          <Route path="financial-estimates" element={<TrakFinancialEstimates />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route exact path="/trak/caselookup/caselist" element={<CaseLookup />} />

        <Route path="/trak/caselookup/caselist/:id" element={<CaseLookupContext />}>
          <Route path="status" element={<Status />} />
          <Route path="comment" element={<Comment />} />
          <Route path="document" element={<Document />} />
          <Route path="case-update" element={<CaseUpdate />} />
          <Route path="patient-info" element={<PatientInfo />} />
          <Route path="patientInfoAddress" element={<PatientInfoAddress />} />
          <Route path="partnerAddress" element={<PartnerAddress />} />
          <Route path="partnerEmail" element={<PartnerEmail />} />
          <Route path="partnerPhone" element={<PartnerPhone />} />
          <Route path="phone" element={<Phone />} />
          <Route path="patientInfoEmail" element={<PatientInfoEmail />} />
          <Route path="financial-snapshot" element={<FinSnapshot />} />
          <Route path="genetic-testing" element={<GeneticTesting />} />
          <Route path="patientPaymentDetails" element={<PatientPaymentDetails />} />
        </Route>
        <Route exact path="/messages" element={<Messages />} key={5}>
          <Route path="/messages/inbox" element={<Inbox />} />
          <Route path="/messages/drafts" element={<Draft />} />
          <Route path="/messages/sent" element={<Sent />} />
          <Route path="/messages/deleted" element={<Deleted />} />
        </Route>
        <Route path="/messages/:messageId/reply" element={<Reply />} />
        <Route path="/utilities" element={<Utilities />}>
          <Route exact path="notifications" element={<NotificationView />} />
        </Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/login" element={<Login />}></Route>
        {/* comment */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
export default FelixRoutes;
