import * as actionType from "../actions/ActionTypes";

const initialState = {
  contractList: [],
  loading: false,
  successMsg: null,
  error: null,
  errorDownload: null
};

const contractReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CONTRACT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CONTRACT_LIST_SUCCESS: {
      return {
        ...state,
        contractList: action.payload,
        loading: false
      };
    }
    case actionType.CONTRACT_LIST_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.CONTRACT_DOCUMENT_DOWNLOAD_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CONTRACT_DOCUMENT_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        successMsg: action.payload,
        loading: false
      };
    }
    case actionType.CONTRACT_DOCUMENT_DOWNLOAD_FAILED: {
      return {
        ...state,
        errorDownload: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_CONTRACT_DOCUMENT_DOWNLOAD: {
      return {
        ...state,
        successMsg: null,
        errorDownload: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default contractReducer;
