import React from "react";
import { DataGridPremium, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-premium";
import { Button } from "@mui/material";

const CustomToolbar = ({ components, isExport, onPrint }) => (
  <GridToolbarContainer
    sx={{
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      position: "relative"
    }}>
    {components ? components : ""}
    {isExport && (
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%"
        }}>
        <Button
          onClick={onPrint}
          style={{ margin: "10px" }}
          // variant="contained"
          // className="btn-usf"
        >
          Print
        </Button>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </div>
    )}
  </GridToolbarContainer>
);

const DataGridPremiumComp = (props) => {
  const {
    rows,
    columns,
    loading,
    pageSizeOptions,
    // pageSize,
    paginationModel,
    onPaginationModelChange,
    pagination,
    localeText,
    components,
    initialState,
    onAggregationModelChange,
    isExport,
    onRowClick,
    getRowId,
    columnVisibilityModel,
    getCellClassName,
    className,
    autoHeight,
    checkboxSelection,
    onCellClick,
    getRowClassName,
    rowCount,
    onSelectionModelChange,
    selectionModel,
    rowSelectionModel,
    sx,
    onRowSelectionModelChange,
    onCellSelectionModelChange,
    onColumnHeaderClick,
    disableMultipleSelection,
    disabled,
    disableColumnFilter,
    aggregationFunctions,
    headerText,
    disableRowSelectionOnClick,
    customAggModel = {}
  } = props;

  const handlePrint = () => {
    const printWindow = window.open("", "", "width=800,height=600");
    printWindow.document.write("<html><head><title>Print Table</title>");
    printWindow.document.write("</head><body>");
    printWindow.document.write(
      "<style>table, th, td { border: 1px solid black; border-collapse: collapse; padding: 5px; }</style>"
    );
    printWindow.document.write(`<h4>${headerText ? headerText : ""}</h4>`);
    printWindow.document.write('<table style="width: 100%;">');
    printWindow.document.write("<thead><tr>");
    columns.forEach((col) => {
      if (col.hide) return;
      printWindow.document.write(`<th>${col.headerName || col.field}</th>`);
    });
    printWindow.document.write("</tr></thead>");
    printWindow.document.write("<tbody>");
    rows.forEach((row) => {
      printWindow.document.write("<tr>");
      columns.forEach((col) => {
        if (col.hide) return;
        printWindow.document.write(`<td>${(row[col.field] && row[col.field]) || ""}</td>`);
      });
      printWindow.document.write("</tr>");
    });

    if (Object.keys(customAggModel).length > 0) {
      let mapOfSum = new Map();
      Object.entries(customAggModel).forEach(([key, value]) => {
        if (value === "totalSum") {
          let sum = 0;
          sum += rows.reduce((acc, row) => {
            return acc + (row[key] ? row[key] : 0);
          }, 0);

          if (sum % 1 !== 0 && sum.toFixed(2) != sum) {
            sum = parseFloat(sum.toFixed(1));
          }

          mapOfSum.set(key, sum);
        }

        if (value === "heading") {
          mapOfSum.set(key, "Total: ");
        }
      });

      printWindow.document.write("<tr>");
      columns.forEach((col) => {
        if (Object.keys(customAggModel).includes(col.field)) {
          printWindow.document.write(`<td>${mapOfSum.get(col.field)}</td>`);
        } else {
          printWindow.document.write(`<td></td>`);
        }
      });
      printWindow.document.write("</tr>");
    }

    printWindow.document.write("</tbody></table>");
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  return (
    <DataGridPremium
      sx={{
        "& .MuiDataGrid-cell": {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          display: "flex",
          justifyContent: "center",
          width: "100%",
          fontWeight: "bold"
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          display: "flex",
          justifyContent: "center",
          width: "100%"
        },
        "& .MuiDataGrid-columnHeader": {
          textAlign: "center",
          borderBottom: "1px solid #1BA3AD",
          borderRight: "1px solid #e8e8e8"
        },
        "& .MuiDataGrid-columnHeaderTitleContainerContent": {
          display: "flex",
          justifyContent: "center",
          width: "100%"
        },
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: 1
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "10px"
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "10px"
        },
        ...sx
      }}
      rows={rows || []}
      rowCount={rowCount || rows.length}
      columns={columns || []}
      loading={loading || false}
      pageSizeOptions={pageSizeOptions || [10, 25, 50, 100]}
      paginationModel={paginationModel || {}}
      onPaginationModelChange={onPaginationModelChange || (() => {})}
      pagination={pagination || true}
      localeText={{ toolbarExport: localeText || "" }}
      slots={{
        toolbar: () => (
          <CustomToolbar
            components={components || null}
            isExport={isExport || false}
            headerText={headerText || ""}
            onPrint={handlePrint}
          />
        )
      }}
      initialState={initialState || {}}
      onAggregationModelChange={onAggregationModelChange || (() => {})}
      onRowClick={onRowClick || (() => {})}
      getRowId={getRowId || ((row) => row?.id)}
      columnVisibilityModel={columnVisibilityModel || {}}
      getCellClassName={getCellClassName || (() => "")}
      autoHeight={autoHeight || false}
      className={className || ""}
      checkboxSelection={checkboxSelection || false}
      onCellClick={onCellClick || (() => {})}
      getRowClassName={getRowClassName || (() => "")}
      paginationMode="client"
      selectionModel={selectionModel || []}
      rowSelectionModel={rowSelectionModel || []}
      onSelectionModelChange={onSelectionModelChange || (() => {})}
      onRowSelectionModelChange={onRowSelectionModelChange || (() => {})}
      onCellSelectionModelChange={onCellSelectionModelChange || (() => {})}
      onColumnHeaderClick={onColumnHeaderClick || (() => {})}
      disableMultipleSelection={disableMultipleSelection || false}
      disabled={disabled || false}
      disableColumnFilter={disableColumnFilter ? true : false}
      disableRowSelectionOnClick={disableRowSelectionOnClick || false}
      aggregationFunctions={aggregationFunctions || {}}
      getRowHeight={() => "auto"}
      disableColumnPinning
      disableRowGrouping
    />
  );
};

export default DataGridPremiumComp;
