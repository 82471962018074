import * as actionType from "../actions/ActionTypes";

const initialState = {
  locationProgramList: [],
  metadata: null,
  loading: false,
  error: null,
  success: false
};

const locationProgramReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOCATION_PROGRAM_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.LOCATION_PROGRAM_LIST_SUCCESS: {
      return {
        ...state,
        locationProgramList: action.payload,
        loading: false
      };
    }
    case actionType.LOCATION_PROGRAM_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.LOCATION_PROGRAM_UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.LOCATION_PROGRAM_UPDATE_SUCCESS: {
      return {
        ...state,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.LOCATION_PROGRAM_UPDATE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_LOCATION_PROGRAM_DEF: {
      return {
        ...state,
        //locationProgramList: [],
        loading: null
      };
    }
    case actionType.METADATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.METADATA_SUCCESS: {
      console.log(action);
      return {
        ...state,
        metadata: action.payload
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default locationProgramReducer;
