import { Divider, Grid, IconButton, InputLabel, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Stack } from "@mui/system";
import { Close } from "@mui/icons-material";
import * as actionTypeP from "../../../trak/actions";
import * as actionType from "../../../admin/actions";
import { connect } from "react-redux";
import { priceFormat } from "../../../shared/common";
import DataGridPremiumComp from "../../../shared/components/DataGridPremiumComp";
import {
  DataGridPremium,
  // useKeepGroupedColumnsHidden,
  useGridApiRef
} from "@mui/x-data-grid-premium";
import moment from "moment/moment";

const PricingModal = (props) => {
  const {
    treatmentPlan,
    newPrList,
    actions,
    pricingModalDataList,
    historyPricingModalDataList,
    selectedPR,
    loading
  } = props;

  const [values, setValues] = useState([]);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const handleClose = () => {
    props.close();
  };
  const columns = [
    {
      field: "stageName",
      headerName: "STAGES",
      minWidth: 100,
      editable: false,
      flex: 2
    },

    {
      field: "globalPrice",
      headerName: "STAGE PRICE SUBJECT TO DISCOUNT",
      minWidth: 100,
      editable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.globalPrice);
      }
    },
    {
      field: "globalPassthroughAmount",
      headerName: "PASSTHROUGH",
      minWidth: 100,
      editable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.globalPassthroughAmount);
      }
    },
    {
      field: "cancelGlobalDiscount",
      headerName: "CYCLE PRICE SUBJECT TO DISCOUNT IF CANCELED AFTER",
      minWidth: 100,
      editable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.cancelGlobalDiscount);
      }
    },
    {
      field: "assistPrice",
      headerName: "ASSIST STAGE PRICE SUBJECT TO DISCOUNT",
      minWidth: 100,
      editable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.assistPrice);
      }
    },
    {
      field: "assistPassthroughAmount",
      headerName: " ASSIST PASSTHROUGH",
      minWidth: 100,
      editable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.assistPassthroughAmount);
      }
    },
    {
      field: "cancelAssistDiscount",
      headerName: "ASSIST CYCLE PRICE SUBJECT TO DISCOUNT IF CANCELED AFTER ",
      minWidth: 100,
      editable: false,
      flex: 2,
      renderCell: ({ row }) => {
        return "$" + priceFormat(row.cancelAssistDiscount);
      }
    }
  ];
  useEffect(() => {
    actions.getPricingModalData({
      offset: 0,
      limit: 20,
      feesSch: true,
      category: "included",
      practiceId: newPrList.practiceId,
      gdmId: newPrList.gdmId,
      treatmentPlanId: selectedPR?.requestedTreatmentPlanId || newPrList.requestedTreatmentPlanId
    });
    actions.getHistoryPricingModalData({
      offset: 0,
      limit: 20,
      feesSch: true,
      category: "included",
      practiceId: newPrList.practiceId,
      gdmId: newPrList.gdmId,
      treatmentPlanId: selectedPR?.requestedTreatmentPlanId || newPrList.requestedTreatmentPlanId
    });
  }, [treatmentPlan]);
  useEffect(() => {
    let val = values;
    const pricingModalData = Object.keys(pricingModalDataList)?.map((index) => {
      pricingModalDataList[index]["id"] = pricingModalDataList[index]?.stageId + "_" + index;
      pricingModalDataList[index]["isPassThrough"] = false;
      if (pricingModalDataList[index].passthroughAmount == null)
        pricingModalDataList[index].passthroughAmount = 0;
      return pricingModalDataList[index];
    });
    val = pricingModalData;
    setValues(val);
  }, [pricingModalDataList]);

  const apiRef = useGridApiRef();
  // const initialState = useKeepGroupedColumnsHidden({
  //   apiRef,
  //   initialState: {
  //     rowGrouping: {
  //       model: ["stageName"]
  //     }
  //   }
  // });

  //console.log("==>", initialState);

  React.useEffect(() => {
    console.log("==>", apiRef); // This should give you the available methods on apiRef
  }, [apiRef]);

  const columns_history = [
    {
      field: "version",
      headerName: "GLOBAL DATA MODEL VERSION",
      minWidth: 220,
      editable: false,
      flex: 1
    },
    {
      field: "stageName",
      headerName: "STAGES",
      minWidth: 130,
      editable: false,
      flex: 1
    },

    {
      field: "globalPrice",
      headerName: "STAGE PRICE SUBJECT TO DISCOUNT",
      minWidth: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (row.globalPrice && "$" + priceFormat(row.globalPrice)) || "";
      }
    },
    {
      field: "globalPassthroughAmount",
      headerName: "PASSTHROUGH",
      minWidth: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          (row.globalPassthroughAmount && "$" + priceFormat(row.globalPassthroughAmount)) || ""
        );
      }
    },
    {
      field: "cancelGlobalDiscount",
      headerName: "CYCLE PRICE SUBJECT TO DISCOUNT IF CANCELED AFTER",
      minWidth: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (row.cancelGlobalDiscount && "$" + priceFormat(row.cancelGlobalDiscount)) || "";
      }
    },
    {
      field: "assistPrice",
      headerName: "ASSIST STAGE PRICE SUBJECT TO DISCOUNT",
      minWidth: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (row.assistPrice && "$" + priceFormat(row.assistPrice)) || "";
      }
    },
    {
      field: "assistPassthroughAmount",
      headerName: " ASSIST PASSTHROUGH",
      minWidth: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          (row.assistPassthroughAmount && "$" + priceFormat(row.assistPassthroughAmount)) || ""
        );
      }
    },
    {
      field: "cancelAssistDiscount",
      headerName: "ASSIST CYCLE PRICE SUBJECT TO DISCOUNT IF CANCELED AFTER ",
      minWidth: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (row.cancelAssistDiscount && "$" + priceFormat(row.cancelAssistDiscount)) || "";
      }
    },
    {
      field: "passthroughAmount",
      headerName: "PASSTHROUGH AMOUNT (TO PRACTICE)",
      minWidth: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (row.passthroughAmount && "$" + priceFormat(row.passthroughAmount)) || "";
      }
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      minWidth: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (row.effectiveDate && moment(row.effectiveDate).format("L")) || "";
      }
    },
    {
      field: "endDate",
      headerName: "EFFECTIVE END DATE",
      minWidth: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (row.endDate && moment(row.endDate).format("L")) || "";
      }
    }
  ];

  const rows_history =
    historyPricingModalDataList &&
    historyPricingModalDataList.length > 0 &&
    historyPricingModalDataList.flatMap((entry, index) =>
      entry.gdmInfo.map((info, infoIndex) => ({
        id: `${index}-${infoIndex}`, // Unique id for each row
        //gdmId: entry.gdmId, // Include gdmId for grouping
        ...info
      }))
    );

  const initialState = {
    rowGrouping: {
      model: ["version"]
    },
    columns: {
      columnVisibilityModel: {
        version: false // Hide the 'gdmId' grouping column if needed
      }
    }
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box className="modal-style-contact" style={{ maxHeight: "80vh", overflow: "auto" }}>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography>Fee Schedule </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ backgroundColor: "#177483" }} orientation="horizontal" />
          <Grid container>
            <Grid item xs={6}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Practice</InputLabel>
                  </Grid>
                  {console.log("newPrList", newPrList)}
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleId"
                      value={newPrList.practiceName}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Est Created Date</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleId"
                      value={
                        (newPrList.estimateCreatedDate &&
                          moment(newPrList.estimateCreatedDate).format("L")) ||
                        ""
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel style={{ whiteSpace: "break-spaces" }}>TREATMENT PLAN</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleNo"
                      value={newPrList.requestedTreatmentPlan}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Est Pulished Date</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleId"
                      value={
                        (newPrList.estimatePublishDate &&
                          moment(newPrList.estimatePublishDate).format("L")) ||
                        ""
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Cycle Outcome</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleId"
                      value={newPrList.outcomeStatus}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Enrollment Date</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleId"
                      value={
                        (newPrList.enrollementDate &&
                          moment(newPrList.enrollementDate).format("L")) ||
                        ""
                      }
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Location Reimbursement</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleId"
                      value={newPrList.reimbursementPercentage}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Net Program Fee</InputLabel>
                  </Grid>

                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleId"
                      value={"$" + priceFormat(newPrList.programFee)}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>

            <Grid item xs={4}>
              <Stack></Stack>
            </Grid>
          </Grid>
          <Box className="grid-style">
            <Grid item xs={6}>
              <InputLabel>GDM Linked To Medcase : {newPrList?.gdmVersion || ""}</InputLabel>
            </Grid>
            <DataGridPremiumComp
              rows={values}
              rowCount={values.length}
              columns={columns}
              loading={loading}
              pageSizeOptions={[10, 25, 50, 100]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
            />
          </Box>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant="contained" className="btn-primary" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
          <Box className="grid-style">
            <Grid item xs={6}>
              <InputLabel>Historic Price</InputLabel>
            </Grid>

            <DataGridPremium
              apiRef={apiRef}
              rows={rows_history}
              columns={columns_history}
              initialState={initialState}
              groupingColDef={{ width: 250 }}

              //disableRowSelectionOnClick
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
const mapStateToProps = ({
  treatmentPlanReducer,
  pricingModalReducer,
  historyPricingModalReducer
}) => {
  return {
    treatmentPlan: treatmentPlanReducer.treatmentPlanList,
    pricingModalDataList: pricingModalReducer.pricingModalDataList,
    historyPricingModalDataList: historyPricingModalReducer.historyPricingModalDataList,
    loading: pricingModalReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getTreatmentPlan: (payload) => {
      dispatch(actionType.getTreatmentPlan(payload));
    },
    getPricingModalData: (payload) => {
      dispatch(actionTypeP.getPricingModalData(payload));
    },
    getHistoryPricingModalData: (payload) => {
      dispatch(actionTypeP.getHistoryPricingModalData(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PricingModal);
