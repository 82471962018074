import * as actionType from "../actions/ActionTypes";

const initialState = {
  resetEmailResponse: [],
  loading: false,
  error: null,
  success: false
};

const resetEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.RESET_EMAIL_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.RESET_EMAIL_SUCCESS: {
      console.log("REDUCER", action);
      return {
        ...state,
        resetEmailResponse: action.payload,
        loading: false
      };
    }
    case actionType.RESET_EMAIL_FAIL: {
      console.log("REDUCER", action);
      return {
        ...state,
        resetEmailResponse: [action.errorMsg],
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default resetEmailReducer;
