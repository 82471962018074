import { call, put, takeEvery, all, delay } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* fetchUserProfileDetails(action) {
  const { payload } = action;
  try {
    let savedUser = localStorage.getItem("userProfile");
    let userProfile = (savedUser && JSON.parse(savedUser)) || null;
    if (userProfile && userProfile.userId) {
      yield delay(100);

      yield put({
        type: actionTypes.USER_PROFILE_DETAILS_SUCCESS,
        payload: userProfile
      });
    } else {
      const params = {
        offset: "0",
        limit: "100"
      };
      const requestBody = createRequestBody("get", `/users/${payload.cognitoUserId}`, params, "");
      const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
      const response = yield call(() => apis(request));
      yield put({
        type: actionTypes.USER_PROFILE_DETAILS_SUCCESS,
        payload: response.data
      });
    }
  } catch (error) {
    yield put({ type: actionTypes.USER_PROFILE_DETAILS_FAIL, errorMsg: error.message });
  }
}

function* getProfileDrawerSaga() {
  yield takeEvery(actionTypes.USER_PROFILE_DETAILS_REQUESTED, fetchUserProfileDetails);
}

export default function* profileDrawerSaga() {
  yield all([getProfileDrawerSaga()]);
}
