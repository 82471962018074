import * as actionType from "../actions/ActionTypes";

const initialState = {
  questonList: [],
  saveQuestions: null,
  loading: false,
  error: null,
  success: false
};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ESTIMATE_QUESTIONS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ESTIMATE_QUESTIONS_LIST_SUCCESS: {
      return {
        ...state,
        questonList: action.payload,
        loading: false
      };
    }
    case actionType.ESTIMATE_QUESTIONS_LIST_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.SAVE_ESTIMATE_QUESTIONS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.SAVE_ESTIMATE_QUESTIONS_LIST_SUCCESS: {
      return {
        ...state,
        saveQuestions: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.SAVE_ESTIMATE_QUESTIONS_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_ESTIMATE_QUESTIONS: {
      return {
        ...state,
        questonList: [],
        saveQuestions: null,
        error: null,
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default questionsReducer;
