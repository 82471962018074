import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchTransactionLogList(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/financialSnapshot/transactionLog`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.TRANSACTION_LOG_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.TRANSACTION_LOG_FAILED, errorMsg: error.message });
    }
  }
}

function* getTransactionLogSaga() {
  yield takeEvery(actionTypes.TRANSACTION_LOG_REQUESTED, fetchTransactionLogList);
}

export default function* transactionLogSaga() {
  yield all([getTransactionLogSaga()]);
}
