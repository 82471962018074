import { Link } from "react-router-dom";

const CaseLookUpMedcaseIdLink = ({ row }) => {
  return (
    <>
      <Link
        style={{
          margin: "1rem",
          textDecoration: "none",
          color: "#1BA3AD",
          fontFamily: "Rubik",
          fontWeight: 500
        }}
        to={{
          pathname: `/trak/caselookup/caselist/${row.medcaseId}/status`,
          state: { data: row.medcaseId }
        }}>
        {row.medcaseId}
      </Link>
    </>
  );
};

export default CaseLookUpMedcaseIdLink;
