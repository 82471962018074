import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";
import { MSG } from "../../shared/constants/constants";

function* fetchPatientPortalAdmin(action) {
  const { payload } = action;
  try {
    const params = {
      loginEndDate: payload.loginEndDate,
      patientNameId: payload.patientNameId,
      endDate: payload.endDate,
      startDate: payload.startDate,
      loginStartDate: payload.loginStartDate,
      practiceId: payload?.practiceId
    };
    const requestBody = createRequestBody("get", `/adminPatientPortal`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.PATIENT_PORTAL_ADMIN_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PATIENT_PORTAL_ADMIN_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getPatientPortalAdminSaga() {
  yield takeEvery(actionTypes.PATIENT_PORTAL_ADMIN_LIST_REQUESTED, fetchPatientPortalAdmin);
}

function* deleteUsers(action) {
  const { payload } = action;
  console.log("payload", payload);
  try {
    const requestBody = createRequestBody(
      "delete",
      `/adminPatientPortal/person/${payload.personId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.DELETE_USERS_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.DELETE_USERS_FAILED, errorMsg: error.message });
    }
  }
}

function* deleteUsersSaga() {
  yield takeEvery(actionTypes.DELETE_USERS_REQUESTED, deleteUsers);
}

export default function* patientPortalAdminSaga() {
  yield all([getPatientPortalAdminSaga(), deleteUsersSaga()]);
}
