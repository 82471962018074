import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Box,
  Stack,
  Typography,
  Grid,
  // Select,
  // MenuItem,
  CircularProgress,
  Modal,
  Button,
  IconButton,
  Autocomplete,
  TextField
} from "@mui/material";
import { Divider } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { getPractices } from "../actions";
import QuickAdd from "../../shared/components/QuickAdd";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { v4 as uuidv4 } from "uuid";
import * as actionType from "../actions";
import CommonModal from "../../shared/components/CommonModal";
import { editUserFilterConfig } from "../../shared/constants/renderConfigs";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PlaceIcon from "@mui/icons-material/Place";
import moment from "moment";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import MessageModal from "../../shared/components/MessageModal";
import LoadingModal from "../../trak/components/modals/loadingModal";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 75
  }
}));

const UsersFilter = (props) => {
  const userFilterColumns = [
    {
      field: "srNo",
      headerName: "SR NO",
      width: 100,
      flex: 1,
      align: "center"
    },
    {
      field: "userName",
      headerName: "USER NAME",
      width: 100,
      flex: 1,
      align: "center"
    },
    {
      field: "email",
      headerName: "EMAIL ID",
      width: 100,
      flex: 1,
      align: "center"
    },
    {
      field: "userType",
      headerName: "USER TYPE",
      width: 100,
      flex: 1,
      align: "center"
    },
    {
      field: "primaryRole",
      headerName: "ROLE",
      width: 100,
      flex: 1,
      align: "center"
    },
    {
      field: "practice",
      headerName: "PRACTICE",
      width: 100,
      flex: 1,
      align: "center",
      renderCell: (params) => {
        return (
          <LightTooltip
            title={getPracticeName(params.row.practiceList).toString()}
            placement="left-start">
            <div
              className="d-flex justify-content-between align-items-center"
              style={{ cursor: "pointer" }}>
              <PlaceIcon />
            </div>
          </LightTooltip>
        );
      }
    },
    {
      field: "date",
      headerName: "DATE",
      width: 100,
      flex: 1,
      align: "center",
      renderCell: ({ row }) => {
        return moment(row.creationDate).format("L");
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 100,
      flex: 1,
      align: "center",
      renderCell: ({ row }) => {
        return (
          <Stack direction="row">
            <IconButton
              onClick={() => {
                //setValues(row);
                actions.setEnableEditFlg(true);
                actions.getUserRolesData(row.cognitoUserId);
                actions.getSelectedRowdetails(row);
                handleNewUser();
              }}>
              <EditOutlinedIcon color="primary"></EditOutlinedIcon>
            </IconButton>
            <IconButton
              onClick={() => {
                handleDisableClick(row);
              }}>
              <PersonOffIcon color="primary" />
            </IconButton>
          </Stack>
        );
      }
    }
  ];
  const { userdisable_success, userdisable_loading, userdisable_data, userdisable_error } = props;
  const getPracticeName = (row) => {
    let practiceName = [];
    row.map((item) => {
      practiceName.push(item.practiceName);
    });
    return practiceName;
  };
  const handleDisableClick = (row) => {
    setCognitoId(row.cognitoUserId);
    setIsDisable(true);
  };
  const handleDisable = () => {
    let cgId = cognitoId;
    let personStatus = "disable";
    let payload = {
      cgId: cgId,
      personStatus: personStatus
    };
    actions.setEditPersonStatus(payload);
    setIsDisable(false);
  };

  const formData = {
    userName: "",
    practiceId: "",
    role: ""
  };

  const dispatch = useDispatch();
  const { loading, actions, userList } = props;
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [values, setValues] = useState(formData);
  const [isOpen, setIsOpen] = useState(false);
  const practices = useSelector((state) => state.practices.practices);
  const practicesLoading = useSelector((state) => state.practices.loading);
  const [isDisable, setIsDisable] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [cognitoId, setCognitoId] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    if (!practices.length) dispatch(getPractices());
    actions.setEnableEditFlg(false);
    actions.resetUserFilter();
  }, []);
  useEffect(() => {
    if (userdisable_success) {
      setIsMessageModal(true);
      setIsDisable(false);
      const payload = {
        ...values,
        userName: searchVal
      };
      actions.userFilterList(payload);
    }
  }, [userdisable_success, userdisable_loading]);
  useEffect(() => {
    return () => {
      setIsMessageModal(false);
    };
  }, []);

  const handleNewUser = () => {
    navigate("/admin/createuserform");
  };

  const handleChangePractice = (value) => {
    if (value) {
      setValues({
        ...values,
        [value?.name]: value?.value
      });
    }
  };

  const userSearch = (e, search) => {
    const payload = {
      ...values,
      userName: search
    };
    setSearchVal(search);
    actions.userFilterList(payload);
  };

  function UserFilterGridToolbar() {
    return (
      <>
        <Typography>User List</Typography>
        <Button variant="contained" className="btn-usf" onClick={handleNewUser}>
          Create New User
        </Button>
      </>
    );
  }

  const roleArray = [
    "Financial Counselor",
    "Financial Counselor(lite)",
    "Felix super admin",
    "FA Ops Manager",
    "FA OPERATIONS",
    "Practice/clinical user",
    "Super User",
    "Patient"
  ];

  const editCloseModal = () => {
    setIsOpen(false);
  };

  const editConfig = editUserFilterConfig({
    values,
    //onChange: handleInputChange,
    onClick: editCloseModal
  });

  const handleCloseDisable = () => {
    setIsDisable(false);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetPersonDiable();
  };

  const getActiveUsers = userList.map((item, index) => {
    if (item.active) {
      item.srNo = index + 1;
      item.userName = item.firstName + "," + item.lastName;
      item.userType = item.domainUser ? "Domain" : "Non-Domain";
      item.id = index;
      return item;
    }
  });
  const rowsData = getActiveUsers.filter((n) => n !== undefined);
  return (
    <>
      <Box className={`${practicesLoading || loading ? "disable-section" : ""}`}>
        <Typography sx={{ padding: "5px", fontWeight: "medium" }}>User Filter</Typography>
        <Box>
          <QuickAdd text={1.5} search={7.5} handleSearchClick={userSearch} labelText={"User"} />
          <Stack direction="row" className="pad-5">
            <Grid container className="align-center">
              <Grid item xs={1.5}>
                <Typography>Practice</Typography>
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row">
                  {/*<Select
                    fullWidth
                    name="practiceId"
                    value={values.practiceId}
                    onChange={handleChangePractice}
                    className="stage-practice-dropdown"
                    mandatory="true">
                    {practices &&
                      practices.map((item, index) => {
                        return (
                          <MenuItem value={item.practiceId} key={index}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select> */}
                  <Autocomplete
                    disablePortal
                    fullWidth
                    required
                    name="practiceId"
                    value={
                      practices.find((item) => item.practiceId === values.practiceId)
                        ? practices.find((item) => item.practiceId === values.practiceId).name
                        : ""
                    }
                    options={practices.map((item, index) => {
                      return {
                        id: index,
                        label: item.name,
                        name: "practiceId",
                        value: item.practiceId
                      };
                    })}
                    onChange={(e, value) => {
                      handleChangePractice(value);
                    }}
                    renderInput={(params) => <TextField {...params} label="Practice..." />}
                    className="stage-practice-dropdown"
                  />
                </Stack>
              </Grid>
              <Grid item xs={1.5}>
                <Typography style={{ marginLeft: "15px" }}>Role</Typography>
              </Grid>
              <Grid item xs={3}>
                {/* <Select
                  fullWidth
                  name="role"
                  className="stage-practice-dropdown"
                  value={values.role}
                  onChange={handleChangePractice}>
                  {roleArray.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select> */}
                <Autocomplete
                  disablePortal
                  fullWidth
                  required
                  name="role"
                  value={values.role}
                  options={roleArray.map((item, index) => {
                    return {
                      id: index,
                      label: item,
                      name: "role",
                      value: item
                    };
                  })}
                  onChange={(e, value) => {
                    handleChangePractice(value);
                  }}
                  renderInput={(params) => <TextField {...params} label="Role..." />}
                  className="stage-practice-dropdown"
                />
              </Grid>
            </Grid>
          </Stack>
          <MessageModal
            open={isMessageModal}
            message={userdisable_success ? userdisable_data : userdisable_error}
            closeModal={closeMessageModal}
          />
          <LoadingModal open={userdisable_loading} />
        </Box>
        <Box className="grid-style pad-5" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rowsData}
            rowCount={rowsData.length}
            columns={userFilterColumns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            pagination
            getRowId={() => uuidv4()}
            components={<UserFilterGridToolbar />}
          />
        </Box>
      </Box>

      {practicesLoading || loading ? (
        <Modal
          open={practicesLoading || loading}
          backdrop="static"
          aria-labelledby="modal-add-title">
          <Box className="alert-modal" width="100px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
              <CircularProgress size={50} />
            </Box>
          </Box>
        </Modal>
      ) : null}
      <CommonModal open={isOpen} config={editConfig} />
      <Modal
        open={isDisable}
        onClose={handleCloseDisable}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box className="modal-style-contact" style={{ maxHeight: "30vh", maxWidth: "30vw" }}>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography>Disable User</Typography>
            <IconButton onClick={handleCloseDisable}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
          <Stack>
            <Typography sx={{ textAlign: "center", margin: "20px" }}>
              Are you sure you want to disable this user?
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 2, alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant="outlined" className="btn-primary" onClick={handleCloseDisable}>
              No
            </Button>
            <Button type="submit" variant="contained" className="btn-usf" onClick={handleDisable}>
              Yes
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
const mapStateToProps = ({ userReducer }) => {
  return {
    loading: userReducer.loading,
    userList: userReducer.userList,
    userdisable_data: userReducer.userdisable_data,
    userdisable_loading: userReducer.userdisable_loading,
    userdisable_success: userReducer.userdisable_success,
    userdisable_error: userReducer.userdisable_error
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    userFilterList: (payload) => {
      dispatch(actionType.userFilterList(payload));
    },
    getSelectedRowdetails: (payload) => {
      dispatch(actionType.getSelectedRowdetails(payload));
    },
    setEnableEditFlg: (payload) => {
      dispatch(actionType.setEnableEditFlg(payload));
    },
    getUserRolesData: (payload) => {
      dispatch(actionType.getUserRolesData(payload));
    },
    setEditPersonStatus: (payload) => {
      dispatch(actionType.setEditPersonStatus(payload));
    },
    resetPersonDiable: () => {
      dispatch(actionType.resetPersonDiable());
    },
    resetUserFilter: () => {
      dispatch(actionType.resetUserFilter());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(UsersFilter);
