import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchLocations(action) {
  const { payload } = action;
  const practiceId = payload;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody("get", `/practices/${practiceId}/locations`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.LOCATION_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.LOCATION_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getLocationSaga() {
  yield takeEvery(actionTypes.LOCATION_LIST_REQUESTED, fetchLocations);
}

function* createNewLocation(action) {
  const { payload } = action;
  const params = {
    name: payload.name,
    longName: payload.longName,
    relationship: payload.relationship,
    ivfPaymentTiming: payload.ivfPaymentTiming,
    fetPaymentTiming: payload.fetPaymentTiming,
    freezeAllPaymentTiming: payload.freezeAllPaymentTiming,
    gl: payload.gl
  };

  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/locations`,
      "",
      params
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_NEW_LOCATION_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_LOCATION_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* addNewLocationSaga() {
  yield takeEvery(actionTypes.ADD_NEW_LOCATION_REQUESTED, createNewLocation);
}

function* editLocation(action) {
  const { payload } = action;
  const params = {
    name: payload.name,
    longName: payload.longName,
    relationship: payload.relationship,
    ivfPaymentTiming: payload.ivfPaymentTiming,
    fetPaymentTiming: payload.fetPaymentTiming,
    freezeAllPaymentTiming: payload.freezeAllPaymentTiming,
    gl: payload.gl
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}/locations/${payload.locationId}`,
      "",
      params
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_LOCATION_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_LOCATION_FAILED,
        message: err.response.data,
        errorMsg: err.response.data
      });
    }
  }
}

function* editLocationSaga() {
  yield takeEvery(actionTypes.EDIT_LOCATION_REQUESTED, editLocation);
}

export default function* locationSaga() {
  yield all([getLocationSaga(), addNewLocationSaga(), editLocationSaga()]);
}
