import * as actionType from "../actions/ActionTypes";

const initialState = {
  cognitoId: null,
  staffAdminList: [],
  loading: false,
  error: null,
  success: null
};

const staffAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.STAFFADMIN_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.STAFFADMIN_LIST_SUCCESS: {
      return {
        ...state,
        staffAdminList: action.payload,
        loading: false
      };
    }
    case actionType.STAFFADMIN_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_STAFFADMIN_LIST: {
      return {
        ...state,
        staffAdminList: []
      };
    }
    case actionType.SET_COGNITO_ID: {
      return {
        ...state,
        cognitoId: action.payload,
        loading: null
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default staffAdminReducer;
