import * as actionType from "../actions/ActionTypes";

const initialState = {
  refundAge: {},
  ageSave: null,
  editRefundAge: null,
  loading: false,
  error: null,
  success: false
};
const refundAgeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SAVE_AGE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.SAVE_AGE_SUCCESS: {
      return {
        ...state,
        ageSave: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.SAVE_AGE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.SAVE_AGE_RESET: {
      return {
        ...state,
        error: false,
        success: false,
        editRefundAge: null,
        refundAge: null,
        ageSave: null
      };
    }
    case actionType.GET_REFUNDAGE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_REFUNDAGE_SUCCESS: {
      return {
        ...state,
        refundAge: action.payload,
        loading: false
      };
    }
    case actionType.GET_REFUNDAGE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.EDIT_REFUNDAGE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_REFUNDAGE_SUCCESS: {
      return {
        ...state,
        editRefundAge: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_REFUNDAGE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default refundAgeReducer;
