import * as actionType from "../actions/ActionTypes";

const initialState = {
  refundQuestions: [],
  withdrawRefund: null,
  assignIncomplete: null,
  practiceUsers: [],
  loadingPractice: false,
  loading: false,
  error: null,
  success: null,
  loadingRefundQstns: false,
  fapRow: {},
  withdrawLoading: false,
  assignLoading: false,
  saveRefund: {},
  submitRefund: {},
  saveLoading: false,
  submitLoading: false,
  saveError: false,
  saveSuccess: false,
  submitError: false,
  submitSuccess: false,
  refundResponse: {},
  refundResponseLoading: false,
  approveRefundLoading: false
};

const refundApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.REFUND_QUESTIONS_REQUESTED: {
      return {
        ...state,
        loadingRefundQstns: true
      };
    }
    case actionType.REFUND_QUESTIONS_SUCCESS: {
      return {
        ...state,
        refundQuestions: action.payload,
        loadingRefundQstns: false
      };
    }
    case actionType.REFUND_QUESTIONS_FAILED: {
      return {
        ...state,
        error: action.message,
        loadingRefundQstns: false
      };
    }

    case actionType.WITHDRAW_REFUND_APP_REQUESTED: {
      return {
        ...state,
        withdrawLoading: true
      };
    }
    case actionType.WITHDRAW_REFUND_APP_SUCCESS: {
      return {
        ...state,
        withdrawRefund: action.payload,
        withdrawLoading: false,
        success: "Withdrawn successfully"
      };
    }
    case actionType.WITHDRAW_REFUND_APP_FAILED: {
      return {
        ...state,
        error: true,
        withdrawLoading: false
      };
    }

    case actionType.ASIGNEE_APP_REQUESTED: {
      return {
        ...state,
        assignLoading: true
      };
    }
    case actionType.ASIGNEE_APP_SUCCESS: {
      return {
        ...state,
        assignIncomplete: action.payload,
        assignLoading: false,
        success: "Assign incomplete successfully"
      };
    }
    case actionType.ASIGNEE_APP_FAILED: {
      return {
        ...state,
        error: true,
        assignLoading: false
      };
    }

    case actionType.PRACTICE_USERS_REQUESTED: {
      return {
        ...state,
        loadingPractice: true
      };
    }
    case actionType.PRACTICE_USERS_SUCCESS: {
      return {
        ...state,
        practiceUsers: action.payload,
        loadingPractice: false
      };
    }
    case actionType.PRACTICE_USERS_FAILED: {
      return {
        ...state,
        error: action.message,
        loadingPractice: false
      };
    }

    case actionType.SAVE_REFUND_APP_REQUESTED: {
      return {
        ...state,
        saveLoading: true
      };
    }
    case actionType.SAVE_REFUND_APP_SUCCESS: {
      return {
        ...state,
        saveRefund: action.payload,
        saveLoading: false,
        success: action.successMsg
      };
    }
    case actionType.SAVE_REFUND_APP_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        saveLoading: false
      };
    }

    case actionType.SAVE_DRAFT_REFUND_APP_REQUESTED: {
      return {
        ...state,
        submitLoading: true
      };
    }
    case actionType.SAVE_DRAFT_REFUND_APP_SUCCESS: {
      return {
        ...state,
        submitRefund: action.payload,
        submitLoading: false,
        success: action.successMsg
      };
    }
    case actionType.SAVE_DRAFT_REFUND_APP_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        submitLoading: false
      };
    }

    case actionType.GET_REFUND_RESPONSE_REQUESTED: {
      return {
        ...state,
        refundResponseLoading: true
      };
    }
    case actionType.GET_REFUND_RESPONSE_SUCCESS: {
      return {
        ...state,
        refundResponse: action.payload,
        refundResponseLoading: false,
        success: action.successMsg
      };
    }
    case actionType.GET_REFUND_RESPONSE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        refundResponseLoading: false
      };
    }
    case actionType.RESET_REFUND_RESPONSE: {
      return {
        ...state,
        refundResponse: {},
        refundResponseLoading: false
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: false,
        success: false
      };
    }

    case actionType.RESET_REFUND_QUESTIONS: {
      return {
        ...state,
        refundQuestions: [],
        loadingRefundQstns: false
      };
    }
    case actionType.FAP_ROW_DATA_REQUESTED: {
      return {
        ...state,
        fapRow: action.payload
      };
    }

    case actionType.RESET_WITHDRAW_RESPONSE: {
      return {
        ...state,
        withdrawLoading: false,
        success: false,
        error: false,
        withdrawRefund: null
      };
    }

    case actionType.RESET_ASSIGNEE_RESPONSE: {
      return {
        ...state,
        assignLoading: false,
        success: false,
        error: false,
        assignIncomplete: null
      };
    }

    case actionType.RESET_SAVE_REFUND_APP: {
      return {
        ...state,
        saveRefund: {},
        saveLoading: false,
        success: false,
        error: false
      };
    }

    case actionType.RESET_SUBMIT_REFUND_APP: {
      return {
        ...state,
        submitRefund: {},
        submitLoading: false,
        success: false,
        error: false
      };
    }

    case actionType.APPROVE_DENY_REFUND_APPLICATION_REQUESTED: {
      return {
        ...state,
        approveRefundLoading: true
      };
    }

    case actionType.APPROVE_DENY_REFUND_APPLICATION_SUCCESS: {
      return {
        ...state,
        approveRefundLoading: false,
        success: action.successMsg
      };
    }

    case actionType.APPROVE_DENY_REFUND_APPLICATION_FAILED: {
      return {
        ...state,
        approveRefundLoading: false,
        error: action.errorMsg
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default refundApplicationReducer;
