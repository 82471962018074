import * as actionType from "../actions/ActionTypes";

const initialState = {
  questions: {},
  estimateList: [],
  treatmentQstns: [],
  estimateViewList: null,
  addEstimate: [],
  addTreatment: [],
  fapList: {},
  fapLoading: false,
  notes: null,
  addNote: null,
  estimateCards: [],
  publish: null,
  estimateCardLoading: false,
  estimateViewLoading: false,
  treatmentQstnsLoading: false,
  loading: false,
  error: false,
  success: false,
  errorAddEstimate: false,
  successAddEstimate: false,
  cardClickAction: false,
  inputValue: {},
  publishLoading: false,
  pulishError: false,
  publishSuccess: false,
  editTreatment: [],
  editTrtment: false,
  estimateId: "",
  personalData: {},
  noteLoading: false,
  systemConfiguration: [],
  estimateQuestionFailed: false,
  isPdfLoading: false,
  estimatePdfData: [],
  successNote: ""
};

const estimateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FINANCIAL_ESTIMATE_QUESTIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        estimateQuestionFailed: false
      };
    }
    case actionType.FINANCIAL_ESTIMATE_QUESTIONS_SUCCESS: {
      return {
        ...state,
        questions: action.payload,
        loading: false,
        estimateQuestionFailed: false
      };
    }
    case actionType.FINANCIAL_ESTIMATE_QUESTIONS_FAILED: {
      return {
        ...state,
        estimateQuestionFailed: action.message,
        loading: false
      };
    }

    case actionType.CREATE_TREATMENT_PLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }

    case actionType.CREATE_TREATMENT_PLAN_SUCCESS: {
      return {
        ...state,
        addTreatment: action.payload,
        loading: false,
        successAddEstimate: action.successMsg
      };
    }
    case actionType.CREATE_TREATMENT_PLAN_FAILED: {
      return {
        ...state,
        errorAddEstimate: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_TREATMENT_PLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }

    case actionType.EDIT_TREATMENT_PLAN_SUCCESS: {
      return {
        ...state,
        editTreatment: action.payload,
        loading: false,
        successAddEstimate: action.successMsg
      };
    }
    case actionType.EDIT_TREATMENT_PLAN_FAILED: {
      return {
        ...state,
        errorAddEstimate: action.errorMsg,
        loading: false
      };
    }

    case actionType.GET_TREATMENT_QUESTIONS_REQUESTED: {
      return {
        ...state,
        treatmentQstnsLoading: true
      };
    }
    case actionType.GET_TREATMENT_QUESTIONS_SUCCESS: {
      return {
        ...state,
        treatmentQstns: action.payload,
        treatmentQstnsLoading: false
      };
    }
    case actionType.GET_TREATMENT_QUESTIONS_FAILED: {
      return {
        ...state,
        error: action.message,
        treatmentQstnsLoading: false
      };
    }

    case actionType.CREATE_FINANCIAL_ESTIMATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_FINANCIAL_ESTIMATE_SUCCESS: {
      return {
        ...state,
        addEstimate: action.payload,
        loading: false,
        successAddEstimate: action.successMsg
      };
    }
    case actionType.CREATE_FINANCIAL_ESTIMATE_FAILED: {
      return {
        ...state,
        errorAddEstimate: action.errorMsg,
        loading: false
      };
    }

    case actionType.FINANCIAL_ESTIMATE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.FINANCIAL_ESTIMATE_LIST_SUCCESS: {
      return {
        ...state,
        estimateList: action.payload,
        loading: false
      };
    }
    case actionType.FINANCIAL_ESTIMATE_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.FINANCIAL_ESTIMATE_VIEW_LIST_REQUESTED: {
      return {
        ...state,
        estimateViewLoading: true
      };
    }
    case actionType.FINANCIAL_ESTIMATE_VIEW_LIST_SUCCESS: {
      return {
        ...state,
        estimateViewList: action.payload,
        estimateViewLoading: false
      };
    }
    case actionType.FINANCIAL_ESTIMATE_VIEW_LIST_FAILED: {
      return {
        ...state,
        estimateViewList: [],
        error: action.message,
        estimateViewLoading: false
      };
    }

    case actionType.FINANCIAL_ESTIMATE_NOTES_REQUESTED: {
      return {
        ...state,
        noteLoading: true
      };
    }
    case actionType.FINANCIAL_ESTIMATE_NOTES_SUCCESS: {
      return {
        ...state,
        notes: action.payload,
        noteLoading: false
      };
    }
    case actionType.FINANCIAL_ESTIMATE_NOTES_FAILED: {
      return {
        ...state,
        error: action.message,
        noteLoading: false
      };
    }

    case actionType.FINANCIAL_ESTIMATE_ADD_NOTES_REQUESTED: {
      return {
        ...state,
        noteLoading: true
      };
    }
    case actionType.FINANCIAL_ESTIMATE_ADD_NOTES_SUCCESS: {
      return {
        ...state,
        addNote: action.payload,
        noteLoading: false,
        successNote: action.successMsg
      };
    }
    case actionType.FINANCIAL_ESTIMATE_ADD_NOTES_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        noteLoading: false
      };
    }

    case actionType.FINANCIAL_ESTIMATE_CARDS_REQUESTED: {
      return {
        ...state,
        estimateCardLoading: true
      };
    }
    case actionType.FINANCIAL_ESTIMATE_CARDS_SUCCESS: {
      return {
        ...state,
        estimateCards: action.payload,
        estimateCardLoading: false
      };
    }
    case actionType.FINANCIAL_ESTIMATE_CARDS_FAILED: {
      return {
        ...state,
        error: action.message,
        estimateCardLoading: false
      };
    }

    case actionType.SYSTEM_CONFIGURATION_VIEW_LIST_REQUESTED: {
      return {
        ...state,
        estimateCardLoading: true
      };
    }
    case actionType.SYSTEM_CONFIGURATION_VIEW_LIST_SUCCESS: {
      return {
        ...state,
        systemConfiguration: action.payload,
        estimateCardLoading: false
      };
    }
    case actionType.SYSTEM_CONFIGURATION_VIEW_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        estimateCardLoading: false
      };
    }

    case actionType.FERTILTY_ACCESS_PROGRAMS_REQUESTED: {
      return {
        ...state,
        fapLoading: true
      };
    }
    case actionType.FERTILTY_ACCESS_PROGRAMS_SUCCESS: {
      return {
        ...state,
        fapList: action.payload,
        fapLoading: false
      };
    }
    case actionType.FERTILTY_ACCESS_PROGRAMS_FAILED: {
      return {
        ...state,
        error: action.message,
        fapLoading: false
      };
    }

    case actionType.PUBLISH_REQUESTED: {
      return {
        ...state,
        publishLoading: true
      };
    }

    case actionType.PUBLISH_SUCCESS: {
      return {
        ...state,
        publish: action.payload,
        publishLoading: false,
        publishSuccess: action.successMsg
      };
    }

    case actionType.PUBLISH_FAILED: {
      return {
        ...state,
        pulishError: action.errorMsg,
        publishLoading: false
      };
    }

    case actionType.PERSONAL_DATA_IS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PERSONAL_DATA_IS_SUCCESS: {
      return {
        ...state,
        personalData: action.payload,
        loading: false
      };
    }
    case actionType.PERSONAL_DATA_IS_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.GET_PARTNER_DETAIL_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_PARTNER_DETAIL_SUCCESS: {
      return {
        ...state,
        partnerDetails: action.payload,
        loading: false
      };
    }
    case actionType.GET_PARTNER_DETAIL_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.CARD_CLICK_ACTION: {
      return {
        ...state,
        cardClickAction: true
      };
    }

    case actionType.RESET_CARD_CLICK: {
      return {
        ...state,
        cardClickAction: false
      };
    }

    case actionType.RESET_ESTIMATE_CARDS: {
      return {
        ...state,
        estimateCards: [],
        loading: false
      };
    }

    case actionType.RESET_FINANCIAL_ESTIMATE_LIST: {
      return {
        ...state,
        estimateList: [],
        loading: false
      };
    }

    case actionType.RESET_ADD_ESTIMATES: {
      return {
        ...state,
        addEstimate: [],
        loading: false
      };
    }

    case actionType.RESET_ADD_TREATMENT: {
      return {
        ...state,
        addTreatment: [],
        loading: false
      };
    }

    case actionType.RESET_FINANCIAL_ESTIMATE_VIEW_LIST: {
      return {
        ...state,
        estimateViewList: null,
        loading: false
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: false,
        success: false
      };
    }

    case actionType.RESET_NOTE_MESSAGE: {
      return {
        ...state,
        error: false,
        successNote: ""
      };
    }

    case actionType.RESET_MESSAGE_MODAL: {
      return {
        ...state,
        errorAddEstimate: false,
        successAddEstimate: false
      };
    }
    case actionType.RESET_PUBLISH_MSG: {
      return {
        ...state,
        publish: null,
        publishLoading: false,
        pulishError: false,
        publishSuccess: false
      };
    }

    case actionType.INPUT_VALUES: {
      return {
        ...state,
        inputValue: action.payload
      };
    }

    case actionType.RESET_GET_QSTNS: {
      return {
        ...state,
        treatmentQstns: [],
        treatmentQstnsLoading: false,
        error: false
      };
    }

    case actionType.EDIT_TREATMENT: {
      return {
        ...state,
        editTrtment: true
      };
    }

    case actionType.RESET_EDIT_TREATMENT: {
      return {
        ...state,
        addEstimate: [],
        editTreatment: [],
        addTreatment: [],
        editTrtment: false,
        error: false,
        success: false,
        errorAddEstimate: false,
        successAddEstimate: false
      };
    }

    case actionType.SET_ESTIMATE_ID: {
      return {
        ...state,
        estimateId: action.payload
      };
    }

    case actionType.RESET_PARTNER_DATA: {
      return {
        ...state,
        partnerDetails: {}
      };
    }

    case actionType.DELETE_ESTIMATE_TREATMENT_PLAN_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_ESTIMATE_TREATMENT_PLAN_SUCCESS: {
      return {
        ...state,
        deleteEstimateTreatmentPlan: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_ESTIMATE_TREATMENT_PLAN_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ESTIMATED_LIST_PDF_REQUESTED: {
      return {
        ...state,
        isPdfLoading: true,
        estimatePdfData: []
      };
    }
    case actionType.ESTIMATED_LIST_PDF_SUCCESS: {
      return {
        ...state,
        estimatePdfData: action.payload,
        isPdfLoading: false
      };
    }
    case actionType.ESTIMATED_LIST_PDF_FAILED: {
      return {
        ...state,
        isPdfLoading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default estimateReducer;
