import React, { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, Typography, Stack } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { email1Config } from "../../shared/constants/renderConfigs";
import Radio from "@mui/material/Radio";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const PartnerEmail = (props) => {
  let emailFormData = {
    email: ""
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState("");
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });

  const {
    actions,
    partnerList,
    // addEmail,
    // editEmail,
    loading,
    errorMsg,
    successMsg,
    emailData
  } = props;

  const columns = [
    {
      field: "default",
      headerName: "DEFAULT",
      width: 100,
      flex: 1,
      renderCell: () => <Radio checked />
    },
    {
      field: "email",
      headerName: "EMAIL",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.email == null ? "--" : row.email;
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          <IconButton
            onClick={() => {
              handleEditEmail(row);
            }}>
            <EditIcon color="primary"></EditIcon>
          </IconButton>
        </>
      )
    }
  ];
  const rows = emailData.map((item, index) => {
    item.id = index;
    return item;
  });

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const saveEmail = (event) => {
    event.preventDefault();
    const payload = { practiceId: props.practiceId, value: values, ...props };
    actions.editPartner(payload);
    closeModal();
  };

  const handleEditEmail = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    emailFormData = {
      email: row.email,
      personId: partnerList.personId
    };
    setValues(emailFormData);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const email = email1Config({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.email ? false : true
  });
  return (
    <>
      <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
        <Stack direction="row" className="grid-header" sx={{ marginBottom: "10px" }}>
          <Typography>Email</Typography>
        </Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
            // minHeight: "650px"
          }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal open={isOpen} config={email} isAdd={isAdd} handleSubmit={saveEmail} />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};
const mapStateToProps = ({ emailReducer, participantReducer, partnerReducer }) => {
  return {
    loading: emailReducer.loading,
    emailList: emailReducer.emailList,
    addEmail: emailReducer.addEmail,
    editEmail: emailReducer.editEmail,
    deleteEmail: emailReducer.deleteEmail,
    errorMsg: emailReducer.error,
    successMsg: emailReducer.success,
    participantList: participantReducer.participantList,
    partnerList: partnerReducer.partnerList
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEmail: () => {
      dispatch(actionType.getEmail());
    },
    addNewEmail: (payload) => {
      dispatch(actionType.addNewEmail(payload));
    },
    editEmail: (payload) => {
      dispatch(actionType.editEmail(payload));
    },
    resetEmailList: () => {
      dispatch(actionType.resetEmailList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    deleteEmail: (payload) => {
      dispatch(actionType.deleteEmail(payload));
    },
    editPartner: (payload) => {
      dispatch(actionType.editPartner(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PartnerEmail);
