import * as actionType from "../actions/ActionTypes";

const initialState = {
  validatedFileData: {},
  uploadedFileData: {},
  errorMessage: null,
  loading: false
};

const loadFromExcelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.VALIDATE_EXCEL_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.VALIDATE_EXCEL_SUCCESS: {
      return {
        ...state,
        validatedFileData: action.payload,
        loading: false
      };
    }
    case actionType.VALIDATE_EXCEL_FAIL: {
      return {
        ...state,
        // validatedFileData: action.payload,
        loading: false
      };
    }
    case actionType.UPLOAD_EXCEL_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.UPLOAD_EXCEL_SUCCESS: {
      return {
        ...state,
        uploadedFileData: action.payload,
        loading: false
      };
    }
    case actionType.UPLOAD_EXCEL_FAIL: {
      return {
        ...state,
        uploadedFileData: action.payload ? action.payload : state.uploadedFileData,
        errorMessage: action.message,
        loading: false
      };
    }
    case actionType.EMPTY_ERRORMSG_REQUESTED: {
      return {
        ...state
      };
    }
    case actionType.EMPTY_ERRORMSG_SUCCESS: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage
      };
    }
    case actionType.EMPTY_ERRORMSG_FAIL: {
      return {
        ...state
      };
    }

    case actionType.EMPTY_EXCEL_STATES_SUCCESS: {
      return {
        ...state,
        validatedFileData: {},
        uploadedFileData: {},
        errorMessage: null,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default loadFromExcelReducer;
