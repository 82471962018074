/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { addressConfig } from "../../shared/constants/renderConfigs";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
function Address(props) {
  let addressFormData = { streetLine1: "", streetLine2: "", city: "", state: "", zip: "" };
  //const [page, setPage] = useState(0);
  //const [offsetVal, setOffsetVal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState(addressFormData);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const { actions, addressList, addAddress, editAddress, locationId, success, error, loading } =
    props;

  const columns = [
    {
      field: "addressId",
      headerName: "ID",
      width: 50,
      flex: 0.5,
      sortable: false
    },
    {
      field: "streetLine1",
      headerName: "STREET",
      width: 100,
      editable: false,
      flex: 2
    },
    {
      field: "city",
      headerName: "CITY",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "state",
      headerName: "STATE",
      width: 50,
      editable: false,
      flex: 0.5
    },
    {
      field: "zip",
      headerName: "ZIP",
      width: 50,
      editable: false,
      flex: 0.5
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleEditAddress(row);
          }}>
          <EditIcon color="primary"></EditIcon>
        </IconButton>
      )
    }
  ];
  const rows = addressList.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    let stLn1 = item.streetLine1 == null || item.streetLine1 == "" ? "" : item.streetLine1;
    let stLn2 = item.streetLine2 == null || item.streetLine2 == "" ? "" : item.streetLine2;
    item.streetLine1 = stLn1 + stLn2;
    item.id = index;
    return item;
  });

  useEffect(() => {
    actions.getAddressList({ locationId: locationId, offset: 0, limit: 100 });
  }, []);
  const initFetch = useCallback(() => {
    actions.getAddressList({ locationId: locationId, offset: 0, limit: 100 });
  }, [addAddress, editAddress]);

  useEffect(() => {
    if (success || error) {
      setIsMessageModal(true);
    }
    if (addressList.length === 0 || addAddress || editAddress) {
      initFetch();
    }
    return () => {
      actions.resetAddressList();
    };
  }, [addAddress, editAddress, error, success]);

  const saveAddress = (event) => {
    event.preventDefault();
    if (isAdd) {
      actions.addNewAddress({ locationId: locationId, address: values });
    } else {
      actions.editAddress({ locationId: locationId, addressId: values.addressId, address: values });
    }
    closeModal();
  };

  const handleEditAddress = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    addressFormData = {
      addressId: row.addressId,
      streetLine1: row.streetLine1,
      streetLine2: row.streetLine2,
      city: row.city,
      state: row.state,
      phoneNumber: row.phoneNumber,
      zip: row.zip
    };
    setValues(addressFormData);
  };

  // const pageChange = (e) => {
  //   setOffsetVal(e * 10);
  //   actions.getPaymentTimingList({ offset: e * 10, limit: 10 });
  //   setPage(e);
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  const closeModal = () => {
    setValues(addressFormData);
    setIsOpen(false);
  };

  const config = addressConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.city && values.state && values.zip ? false : true
  });
  const addNewAddress = () => {
    setValues(addressFormData);
    setIsAdd(true);
    setIsOpen(true);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  return (
    <>
      <Box>
        <Button variant="contained" className="btn-primary tab-panel-btn" onClick={addNewAddress}>
          New Address
        </Button>
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
        />
        <CommonModal open={isOpen} config={config} isAdd={isAdd} handleSubmit={saveAddress} />
        <MessageModal
          open={isMessageModal}
          message={success}
          error={error}
          closeModal={closeMessageModal}
        />
      </Box>
    </>
  );
}

const mapStateToProps = ({ address }) => {
  return {
    addressList: address.addressList,
    addAddress: address.addAddress,
    editAddress: address.editAddress,
    loading: address.loading,
    success: address.success,
    error: address.error
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getAddressList: (payload) => {
      dispatch(actionType.getAddressList(payload));
    },
    addNewAddress: (payload) => {
      dispatch(actionType.addAddress(payload));
    },
    editAddress: (payload) => {
      dispatch(actionType.editAddress(payload));
    },
    resetAddressList: () => {
      dispatch(actionType.resetAddressList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Address);
