// import { getQueryString } from "../modules/shared/constants/constants";
// import { url, config } from "../modules/shared/constants/cognitoConstants";
import { clearSignInItems } from "../modules/shared/components/SignApi";
import { AWS_CREDENTIALS } from "../modules/shared/constants/cognitoConstants";

export const appAuthencationCheck = (error) => {
  // console.log("error", error);
  // console.log("error.code ERR_NETWORK", error.code);
  // console.log("error.response.status", error.response.status);
  let isValid = true;
  if (error && error.code === "ERR_NETWORK") {
    clearSignInItems([AWS_CREDENTIALS, "screenRights", "cognitoUserId"]);

    //window.location.href = url + "logout" + getQueryString(config);

    isValid = false;
  }
  return isValid;
};
