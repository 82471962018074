import React, { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddContactModal from "./modals/AddContactModal";
import MessageModal from "../../shared/components/MessageModal";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const Contacts = (props) => {
  const columns = [
    {
      field: "contactId",
      headerName: "CONTACT ID",
      flex: 1.7
    },
    {
      field: "type",
      headerName: "TYPE",
      editable: false,
      flex: 1.7
    },
    {
      field: "firstName",
      headerName: "NAME",
      editable: false,
      flex: 1.7,
      renderCell: (params) => params.row.firstName + " " + params.row.lastName
    },
    {
      field: "status",
      headerName: "STATUS",
      flex: 1.7,
      renderCell: ({ row }) => (
        <span style={{ color: row.status == "Active" ? "green" : "red" }}>{row.status}</span>
      )
    },
    {
      field: "showInMedcase",
      headerName: "SHOW IN MEDCASE",
      editable: false,
      flex: 1.7,
      renderCell: ({ row }) => (
        <IconButton>
          <CheckCircleOutlineIcon color={row.showInMedcase ? "primary" : "#8C8C8C"} />
        </IconButton>
      )
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      editable: false,
      flex: 1.7,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      editable: true,
      flex: 1.7,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleEditContact(row);
          }}>
          <EditIcon color="primary" />
        </IconButton>
      )
    }
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isChildModal, setIsChildModal] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const { practiceId } = useParams();
  const [rowData, setRowData] = useState();
  const { contactsList, addContact, editContact, actions, loading, success, error } = props;

  const rows = contactsList.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    actions.getcontactList(practiceId);
  }, [addContact, editContact]);

  useEffect(() => {
    if (error || success) {
      setIsChildModal(true);
    }
    if (contactsList.length == 0 || addContact || editContact) {
      initFetch();
    }
  }, [addContact, editContact, success, error]);

  const closeChildModal = () => {
    setIsChildModal(false);
  };

  const handleAddContact = () => {
    setIsAdd(true);
    setIsOpen(true);
  };

  const handleEditContact = (row) => {
    let phonePayload = {
      practiceId,
      contactId: row.contactId
    };
    actions.getPhoneData(phonePayload);
    actions.getEmailData(phonePayload);
    setRowData(row);
    setIsEdit(true);
    setIsAdd(false);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsEdit(false);
    setIsAdd(false);
    setIsOpen(false);
  };

  return (
    <>
      <Box>
        <Button
          variant="contained"
          className="btn-primary tab-panel-btn"
          onClick={handleAddContact}>
          New Contact
        </Button>
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
        />
      </Box>
      <AddContactModal
        open={isOpen}
        add={isAdd}
        edit={isEdit}
        close={closeModal}
        rowData={rowData}
      />

      {/* Duplicate entry modal*/}
      <MessageModal
        open={isChildModal}
        error={error}
        message={success}
        closeModal={closeChildModal}
      />
    </>
  );
};

const mapStateToProps = ({ contactReducer, phoneReducer }) => {
  return {
    contactsList: contactReducer.contactList,
    loading: contactReducer.loading,
    phoneData: phoneReducer.phoneData,
    emailData: phoneReducer.emailData,
    addContact: contactReducer.addContact,
    editContact: contactReducer.editContact,
    success: contactReducer.success,
    error: contactReducer.error
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getcontactList: (payload) => {
      dispatch(actionType.getcontactList(payload));
    },
    addNewContact: (payload) => {
      dispatch(actionType.addContact(payload));
    },
    editContact: (payload) => {
      dispatch(actionType.editContact(payload));
    },
    resetContactList: () => {
      dispatch(actionType.resetContactList());
    },
    getPhoneData: (payload) => {
      dispatch(actionType.getContactPhoneData(payload));
    },
    getEmailData: (payload) => {
      dispatch(actionType.getContactEmailData(payload));
    },

    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
