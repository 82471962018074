import * as actionType from "../actions/ActionTypes";

const initialState = {
  globalData: [],
  addGlobalData: null,
  editGlobalData: null,
  loading: false,
  error: null,
  success: false,
  gdmRowData: [],
  practiceId: null,
  pId: null
};

const globalDataModelReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GDM_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GDM_LIST_SUCCESS: {
      return {
        ...state,
        globalData: action.payload,
        pId: action.pId,
        loading: false
      };
    }
    case actionType.GDM_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_GDM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_GDM_SUCCESS: {
      return {
        ...state,
        addGlobalData: action.payload,
        loading: false,
        success: action.successMsg,
        error: ""
      };
    }
    case actionType.ADD_NEW_GDM_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        success: false
      };
    }
    case actionType.EDIT_GDM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_GDM_SUCCESS: {
      return {
        ...state,
        editGlobalData: action.payload,
        loading: false,
        success: action.success,
        error: action.error
      };
    }
    case actionType.EDIT_GDM_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        success: action.success
      };
    }
    case actionType.ADD_NEW_EGDM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_EGDM_SUCCESS: {
      return {
        ...state,
        addeGlobalData: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_EGDM_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_GDM_DATA: {
      return {
        ...state,
        addGlobalData: null,
        editGlobalData: null,
        loading: false
      };
    }

    case actionType.RESET_GDM_LIST: {
      return {
        ...state,
        globalData: [],
        loading: false
      };
    }
    case actionType.GDM_ROW: {
      return {
        ...state,
        gdmRowData: action.payload,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default globalDataModelReducer;
