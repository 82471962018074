import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { Checkbox } from "@mui/material";

const LocationSelectField = ({
  label,
  required,
  mandatory,
  onChange,
  value,
  options,
  ...props
}) => {
  return (
    <>
      <Stack direction="row" className="modal-stack">
        {label ? (
          <InputLabel required={mandatory} className="flex-2">
            {label}
          </InputLabel>
        ) : null}
        <Select
          multiple
          size="small"
          sx={{ flex: 6, height: 35, width: 100 }}
          onChange={onChange}
          required={required}
          renderValue={(selected) => selected.join(", ")}
          value={value}
          {...props}>
          {options.map((item, index) => {
            return (
              <MenuItem key={index} value={item.name}>
                <Checkbox checked={value.indexOf(item.name) > -1} />
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
    </>
  );
};

export default LocationSelectField;
