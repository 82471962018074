import * as actionType from "../actions/ActionTypes";
import { TREATMENTPLAN_LIST_SUCCESS } from "../../admin/actions/ActionTypes";
const initialState = {
  planList: [],
  clinicalTreatmentOutcomesList: [],
  editClinicalTreatmentOutcomesList: [],
  loading: false,
  error: null,
  errorResponse: null,
  success: null,
  sendAuth: null
};

const trackOutComeFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.CLINICAL_TREATMENT_OUTCOMES_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CLINICAL_TREATMENT_OUTCOMES_SUCCESS: {
      return {
        ...state,
        clinicalTreatmentOutcomesList: action.payload,
        loading: false
      };
    }
    case actionType.CLINICAL_TREATMENT_OUTCOMES_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.EDIT_CLINICAL_TREATMENT_OUTCOMES_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_CLINICAL_TREATMENT_OUTCOMES_SUCCESS: {
      return {
        ...state,
        editClinicalTreatmentOutcomesList: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_CLINICAL_TREATMENT_OUTCOMES_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        errorResponse: action.payload,
        loading: false
      };
    }
    case actionType.CREATE_SEND_AUTHORIZATION_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_SEND_AUTHORIZATION_SUCCESS: {
      return {
        ...state,
        sendAuth: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.CREATE_SEND_AUTHORIZATION_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case TREATMENTPLAN_LIST_SUCCESS: {
      return {
        ...state,
        planList: action.payload,
        loading: false
      };
    }
    case actionType.RESET_OUTCOMELIST: {
      return {
        ...state,
        editClinicalTreatmentOutcomesList: null,
        clinicalTreatmentOutcomesList: null,
        success: null,
        error: null,
        loading: null,
        errorResponse: null
      };
    }
    case actionType.RESET_RES: {
      return {
        ...state,
        success: null,
        error: null,
        errorResponse: null
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default trackOutComeFormReducer;
