import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import {
  InputLabel,
  Stack,
  Typography,
  Divider,
  Button,
  Grid,
  TextField,
  IconButton
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import * as actionType from "../actions";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFinancialEstimateViewList,
  setEstimateId,
  resetEstimateViewList
} from "../../workspace/actions";
import { useDispatch } from "react-redux";

const SearchDrawer = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { actions, list = [] } = props;
  const [open, setOpen] = useState(false);
  const [utilitySearchList, setUtilitySearchList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const practiceId = localStorage.getItem("practiceId");
  const [inWorkspace, setInWorkspace] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    setUtilitySearchList(list);
  }, [list]);

  useEffect(() => {
    setSearchText("");
    actions.resetUtilitySearchList();
  }, [props.openSearch]);
  const handleClose = () => {
    if (open === true) {
      setOpen(false);
      props.close();
    } else {
      setOpen(true);
    }
  };
  const clearSearchClick = () => {
    setSearchText("");
    actions.resetUtilitySearchList();
  };
  const handleSearchClick = (e, searchText) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let param, inWspace;
    if (searchText != "") {
      if (
        location.pathname.indexOf("workspace") > -1 &&
        location.pathname.indexOf("financial-estimates") > -1
      ) {
        inWspace = true;
        param = { patientNameOrId: searchText, estimateType: "All" };
      } else {
        inWspace = false;
        param = { nameOrId: searchText };
      }
      let payload = {
        inWspace,
        practiceId,
        param
      };
      setInWorkspace(inWspace);
      actions.getUtilitySearchList(payload);
    }
  };
  const goToPatientDirectory = () => {
    navigate(`/patients/patient-directory`);
    setOpen(false);
    setSearchText("");
    props.close();
  };
  const handleSelectPatient = (personId, name, estimateId) => {
    localStorage.setItem("patientName", name);
    if (
      location.pathname.indexOf("workspace") > -1 &&
      location.pathname.indexOf("financial-estimates") > -1
    ) {
      let payload = {
        practiceId: practiceId,
        personId: personId
      };
      dispatch(setEstimateId(estimateId));
      dispatch(resetEstimateViewList());
      dispatch(getFinancialEstimateViewList(payload));
      navigate(`/workspace/financial-estimates/${personId}`);
    } else {
      navigate(`/patients/${personId}/personal`);
    }
    setSearchText("");
    props.close();
  };
  return (
    <>
      <Drawer
        sx={{ zIndex: 1 }}
        disableScrollLock
        hideBackdrop={true}
        anchor={"right"}
        open={props.openSearch}
        onClose={handleClose}>
        <Box sx={{ width: 250, paddingTop: 11 }} role="presentation">
          <Grid item>
            <Stack style={{ marginLeft: 10, width: 220 }} direction="row">
              <TextField
                fullWidth
                size="small"
                value={searchText}
                placeholder=" Search "
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <>
                      {searchText && (
                        <IconButton
                          className="m-r-m10"
                          onClick={() => {
                            setSearchText("");
                            clearSearchClick();
                          }}>
                          <Close sx={{ fontSize: 12 }} />
                        </IconButton>
                      )}
                    </>
                  )
                }}
              />
              <Button
                variant="contained"
                className="search-pat-btn"
                onClick={(e) => {
                  handleSearchClick(e, searchText);
                }}>
                <SearchIcon />
              </Button>
            </Stack>
          </Grid>
          <Stack direction="row" justifyContent={"flex-end"}>
            <Typography
              style={{ marginTop: 5 }}
              underline="none"
              variant="subtitle1"
              onClick={goToPatientDirectory}
              className="cursor-pointer"
              color="primary">
              ADVANCED SEARCH
            </Typography>
            <KeyboardArrowRightIcon
              className="cursor-pointer"
              color="primary"></KeyboardArrowRightIcon>
          </Stack>
          <Stack direction="column" className="m-l-20 m-t-20 m-r-20">
            {utilitySearchList &&
              utilitySearchList.map((item, index) => {
                return (
                  <Box className="stage-details-section m-5 " key={index}>
                    <Stack style={{ marginBottom: 10 }}>
                      {inWorkspace === true ? (
                        <Typography>
                          {item.patientFirstName + " " + item.patientLastName}
                        </Typography>
                      ) : (
                        <Typography>{item.firstName + " " + item.lastName}</Typography>
                      )}
                      <InputLabel>ID - {item.personId}</InputLabel>
                      {location.pathname.includes("/trak") ? (
                        <Button
                          variant="contained"
                          className="btn-primary"
                          onClick={() => {
                            const estimateId = null;
                            const name = item.firstName + " " + item.lastName;
                            handleSelectPatient(item.personId, name, estimateId);
                          }}>
                          SELECT PATIENT
                        </Button>
                      ) : (
                        // <Link to={`${path}`}>
                        <Button
                          variant="contained"
                          className="btn-primary"
                          onClick={() => {
                            const estimateId = inWorkspace === true ? item.estimateId : null;
                            const name =
                              inWorkspace === true
                                ? item.patientFirstName + " " + item.patientLastName
                                : item.firstName + " " + item.lastName;
                            handleSelectPatient(item.personId, name, estimateId);
                          }}>
                          SELECT PATIENT
                        </Button>
                        // </Link>
                      )}
                    </Stack>
                  </Box>
                );
              })}
            <Divider className="div-finS" />
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
const mapStateToProps = ({ utilitySearchReducer }) => {
  return {
    list: utilitySearchReducer.utilitySearchList,
    loading: utilitySearchReducer.loading
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getUtilitySearchList: (payload) => {
      dispatch(actionType.getUtilitySearchList(payload));
    },
    resetUtilitySearchList: () => {
      dispatch(actionType.resetUtilitySearchList());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchDrawer);
