import * as actionType from "../actions/ActionTypes";

const initialState = {
  programList: [],
  loading: false,
  addProgram: null,
  editProgram: null,
  deleteProgram: null,
  error: null,
  success: null
};

const programReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PROGRAM_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PROGRAM_LIST_SUCCESS: {
      return {
        ...state,
        programList: action.payload,
        loading: false
      };
    }
    case actionType.PROGRAM_LIST_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.ADD_PROGRAM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_PROGRAM_SUCCESS: {
      return {
        ...state,
        addProgram: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_PROGRAM_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_PROGRAM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PROGRAM_SUCCESS: {
      return {
        ...state,
        editProgram: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_PROGRAM_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.DELETE_PROGRAM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_PROGRAM_SUCCESS: {
      return {
        ...state,
        deleteProgram: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_PROGRAM_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_PROGRAM: {
      return {
        ...state,
        addProgram: null,
        editProgram: null,
        deleteProgram: null,
        loading: null
      };
    }
    case actionType.RESET_PROGRAM_LIST: {
      return {
        ...state,
        programList: [],
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default programReducer;
