import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { MSG } from "../../shared/constants/constants";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchAuditLogs(action) {
  const { payload } = action;
  try {
    const params = {
      patientEmail: payload.email
    };
    if (payload.impersonate) {
      params.impersonate = "Y";
    }
    const requestBody = createRequestBody("get", `/adminPatientPortal/auditLogs`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    if (payload.impersonate) {
      yield put({
        type: actionTypes.IMPERSONATE_LOGS_SUCCESS,
        payload: response.data
      });
    } else {
      yield put({
        type: actionTypes.AUDIT_LOGS_SUCCESS,
        payload: response.data
      });
    }
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.AUDIT_LOGS_FAIL, errorMsg: error.message });
    }
  }
}

function* getAuditLogsSaga() {
  yield takeEvery(actionTypes.AUDIT_LOGS_REQUESTED, fetchAuditLogs);
}

function* addImpersonateLog(action) {
  const { payload } = action;

  const body = {
    impersonatorId: payload.impersonatorId,
    eventName: payload.eventName,
    screenName: payload.screenName,
    eventType: payload.eventType,
    ipAddress: payload.ipAddress,
    patientId: payload.patientId
  };
  try {
    const requestBody = createRequestBody("post", `/adminPatientPortal/auditLogs`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_IMPERSONATE_LOGS_SUCCESS,
      payload: response.data,
      success: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ADD_IMPERSONATE_LOGS_FAIL,
        message: error.response.data
      });
    }
  }
}
function* addImpersonateLogSaga() {
  yield takeEvery(actionTypes.ADD_IMPERSONATE_LOGS_REQUESTED, addImpersonateLog);
}

export default function* auditLogsSaga() {
  yield all([getAuditLogsSaga(), addImpersonateLogSaga()]);
}
