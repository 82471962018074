import * as actionType from "../actions/ActionTypes";

const initialState = {
  addRevenueList: [],
  deleteTransaction: null,
  loading: false,
  error: null,
  success: null,
  revenueData: []
};

const addRevenueReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.REVENUE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.REVENUE_LIST_SUCCESS: {
      return {
        ...state,
        addRevenueList: action.payload,
        loading: false
      };
    }
    case actionType.REVENUE_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.POST_REVENUE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.POST_REVENUE_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.POST_REVENUE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.RESET_REVENUE: {
      return {
        ...state,
        success: null
      };
    }
    case actionType.GET_REVENUE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_REVENUE_SUCCESS: {
      return {
        ...state,
        revenueData: action.payload,
        loading: false
      };
    }
    case actionType.GET_REVENUE_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default addRevenueReducer;
