import React, { useEffect, useState } from "react";
import { Box, Stack, Grid, Button, Typography } from "@mui/material";
import CaseLookUpMedcaseIdLink from "./CaseLookUpMedcaseIdLink";
import moment from "moment";
import * as actionType from "../actions";
import { connect } from "react-redux";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const ActiveParticipant = (props) => {
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const { activeParticipantList = [], actions, loading } = props;
  const [currentDate, setCurrentDate] = useState();
  const dateFormat = "MM/DD/YYYY";
  const callAPI = () => {
    actions.getActiveParticipantReport({
      offset: 0,
      participantType: "active",
      limit: 1000
    });
    const currDate = moment().format(dateFormat);
    setCurrentDate(currDate);
  };

  const [rows, setRows] = useState([]);
  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      patientName:
        row?.patientFirstName || row?.patientLastName
          ? row?.patientFirstName + " " + row?.patientLastName
          : " ",
      medcaseStatusDate: row?.medcaseStatusDate
        ? moment(row?.medcaseStatusDate).format("MM/DD/YYYY")
        : "",
      forecastDate: row?.forecastDate ? moment(row?.forecastDate).format("MM/DD/YYYY") : ""
    }));
  };

  useEffect(() => {
    const rows = activeParticipantList.map((item, index) => {
      item.id = index;
      return item;
    });

    setRows(prepareCsvData(rows));
  }, [activeParticipantList]);

  function ActiveParticipantToolBar() {
    return (
      <Grid container className="align-center">
        <Grid item xs={1.25}>
          <Typography style={{ marginLeft: "10px" }}>Current Date</Typography>
        </Grid>
        <Grid item xs={2.25}>
          <Typography style={{ marginLeft: "10px" }}>{currentDate}</Typography>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={2}>
          <Button variant="contained" className="btn-usf" onClick={callAPI}>
            Run Report
          </Button>
        </Grid>
      </Grid>
    );
  }

  const columns = [
    {
      field: "medcaseId",
      headerName: "MEDCASE ID",
      width: 130,
      editable: false,
      renderCell: ({ row }) => <CaseLookUpMedcaseIdLink row={row} />
    },
    {
      field: "patientName",
      headerName: "PATIENT NAME",
      width: 130,
      editable: false,
      renderCell: ({ row }) => {
        return "".concat(row.patientFirstName, " ", row.patientLastName);
      }
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      width: 130,
      editable: false
    },
    {
      field: "medcaseStatus",
      headerName: "STATUS",
      width: 130,
      editable: false
    },
    {
      field: "medcaseStatusDate",
      headerName: "STATUS DATE",
      width: 130,
      editable: false
    },
    {
      field: "personNo",
      headerName: "PERSON NUMBER",
      width: 130,
      editable: false
    },
    {
      field: "progShortName",
      headerName: "PROGRAM SHORT NAME",
      width: 130,
      editable: false
    },
    {
      field: "cycleNo",
      headerName: "CYCLE NO",
      width: 130,
      editable: false
    },
    {
      field: "authorizedTpName",
      headerName: "AUTHORIZED TREATMENT",
      width: 130,
      editable: false
    },
    {
      field: "normalFrozenEmbryosRemaining",
      headerName: "NORMAL EMBRYOS REMAIN",
      width: 90,
      editable: false
    },
    {
      field: "authDate",
      headerName: "AUTHORIZED DATE",
      width: 130,
      editable: false
    },
    {
      field: "forecastDate",
      headerName: "FORECAST DATE",
      width: 130,
      editable: false
    },
    {
      field: "forecastEvent",
      headerName: "FORECAST EVENT",
      width: 130,
      editable: false
    },
    {
      field: "daysPastDue",
      headerName: "DAYS PAST DUE",
      width: 130,
      editable: false
    }
  ];
  return (
    <>
      <Stack direction="row" className="pad-5 m-t-8"></Stack>
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 20, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            localeText={"Export Button"}
            components={<ActiveParticipantToolBar />}
            isExport={true}
            headerText={`Active Participant Current Date ${currentDate}`}
            disableRowSelectionOnClick={true}
          />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({ activeParticipantReducer }) => {
  return {
    loading: activeParticipantReducer.loading,
    activeParticipantList: activeParticipantReducer.activeParticipantList,
    errorMsg: activeParticipantReducer.error,
    successMsg: activeParticipantReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getActiveParticipantReport: (payload) => {
      dispatch(actionType.getActiveParticipantReport(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveParticipant);
