import * as actionType from "../actions/ActionTypes";

const initialState = {
  pgtaList: [],
  loading: false,
  editPGTA: null,
  error: null,
  success: null
};

const pgtaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PGTA_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PGTA_LIST_SUCCESS: {
      return {
        ...state,
        pgtaList: action.payload,
        loading: false
      };
    }
    case actionType.PGTA_LIST_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.EDIT_PGTA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_PGTA_SUCCESS: {
      return {
        ...state,
        editPGTA: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_PGTA_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.PGTA_LIST_RESET: {
      return {
        ...state,
        pgtaList: [],
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default pgtaReducer;
