import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

// get All email Document list
function* fetchEmailDocList(action) {
  const { payload } = action;
  const practiceId = payload;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/emailDocuments`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EMAIL_DOC_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EMAIL_DOC_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* fetchEmailDocSaga() {
  yield takeEvery(actionTypes.EMAIL_DOC_LIST_REQUESTED, fetchEmailDocList);
}

// add Email Doc
function* addEmailDoc(action) {
  const { payload } = action;
  const { practiceId } = payload;

  const body = {
    emailName: payload.emailName,
    agreements: payload.agreements ?? false,
    outcome: payload.outcome,
    clearance: payload.clearance,
    authorization: payload.authorization,
    receipt: payload.receipt,
    refund: payload.refund,
    termination: payload.termination
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${practiceId}/emailDocuments`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_EMAIL_DOC_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_EMAIL_DOC_FAILED,
        message: err.response.data
      });
    }
  }
}

function* addEmailDocSaga() {
  yield takeEvery(actionTypes.ADD_EMAIL_DOC_REQUESTED, addEmailDoc);
}

// Edit Email Doc
function* editEmailDoc(action) {
  const { payload } = action;
  const { practiceId } = payload;
  const { emailId } = payload;

  const body = {
    emailName: payload.emailName,
    agreements: payload.agreements ?? false,
    outcome: payload.outcome,
    clearance: payload.clearance,
    authorization: payload.authorization,
    receipt: payload.receipt,
    refund: payload.refund,
    termination: payload.termination
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/emailDocuments/${emailId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.EDIT_EMAIL_DOC_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_EMAIL_DOC_FAILED,
        message: err.response.data
      });
    }
  }
}

function* editEmailDocSaga() {
  yield takeEvery(actionTypes.EDIT_EMAIL_DOC_REQUESTED, editEmailDoc);
}

export default function* emailDocSaga() {
  yield all([fetchEmailDocSaga(), addEmailDocSaga(), editEmailDocSaga()]);
}
