import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchGlobalData(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody("get", `/practices/${payload}/gdms`, "", "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GDM_LIST_SUCCESS,
      payload: response.data,
      pId: payload
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.GDM_LIST_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* getGlobalSaga() {
  yield takeEvery(actionTypes.GDM_LIST_REQUESTED, fetchGlobalData);
}

function* addNewGdm(action) {
  const { payload } = action;
  try {
    const params = {
      cloneFrom: payload.cloneFrom
    };
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/gdms`,
      params,
      payload.gdmData
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_NEW_GDM_SUCCESS,
      payload: response.data,
      pId: payload.practiceId,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.ADD_NEW_GDM_FAILED,
        errorMsg: err.response.data
      });
    }
  }
}

function* createNewGdmSaga() {
  yield takeEvery(actionTypes.ADD_NEW_GDM_REQUESTED, addNewGdm);
}

function* updateNewGdm(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}/gdms/${payload.gdmId}`,
      "",
      payload.gdmData
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_GDM_SUCCESS,
      payload: response.data,
      success: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_GDM_FAILED,
        errorMsg: err.response.data,
        success: false
      });
    }
  }
}

function* editNewGdmSaga() {
  yield takeEvery(actionTypes.EDIT_GDM_REQUESTED, updateNewGdm);
}
updateNewGdm;
export default function* globalDataSaga() {
  yield all([getGlobalSaga(), createNewGdmSaga(), editNewGdmSaga()]);
}
