import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchRefundAge(action) {
  const { payload } = action;
  const practiceId = payload;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/refund-age-criteria`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GET_REFUNDAGE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GET_REFUNDAGE_FAIL, errorMsg: error.message });
    }
  }
}

function* getRefundAgeSaga() {
  yield takeEvery(actionTypes.GET_REFUNDAGE_REQUESTED, fetchRefundAge);
}

function* saveAge(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/refund-age-criteria`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.SAVE_AGE_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.SAVE_AGE_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* saveAgeSaga() {
  yield takeEvery(actionTypes.SAVE_AGE_REQUESTED, saveAge);
}
function* editRefundAge(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}/refund-age-criteria`,
      "",
      payload.data
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_REFUNDAGE_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.EDIT_REFUNDAGE_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* editRefundAgeSaga() {
  yield takeEvery(actionTypes.EDIT_REFUNDAGE_REQUESTED, editRefundAge);
}

export default function* refundAgeSaga() {
  yield all([getRefundAgeSaga(), saveAgeSaga(), editRefundAgeSaga()]);
}
