import * as actionType from "../actions/ActionTypes";

const initialState = {
  medcasetList: [],
  medcaseCreatedM: {},
  updatePartner: {},
  linkMedcaseCreated: {},
  loading: false,
  listLoading: false,
  successLink: null,
  successUnlink: null,
  errorLink: null,
  error: false,
  success: false,
  selectedPatient: false,
  loadingLink: false
};

const medcaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.MEDCASE_LIST_REQUESTED: {
      return {
        ...state,
        listLoading: true
      };
    }
    case actionType.MEDCASE_LIST_SUCCESS: {
      return {
        ...state,
        medcasetList: action.payload,
        listLoading: false
      };
    }
    case actionType.MEDCASE_LIST_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        listLoading: false
      };
    }
    case actionType.UPDATE_PARTNER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.UPDATE_PARTNER_SUCCESS: {
      return {
        ...state,
        updatePartner: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.UPDATE_PARTNER_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.CREATE_MEDCASE_MANUAL_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_MEDCASE_MANUAL_SUCCESS: {
      return {
        ...state,
        medcaseCreatedM: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.CREATE_MEDCASE_MANUAL_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.CREATE_LINK_MEDCASE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_LINK_MEDCASE_SUCCESS: {
      return {
        ...state,
        linkMedcaseCreated: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.CREATE_LINK_MEDCASE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_MEDCASE: {
      return {
        ...state,
        medcaseCreatedM: {},
        updatePartner: {},
        linkMedcaseCreated: {},
        loading: false,
        error: false,
        success: false
      };
    }
    case actionType.LINK_MEDCASE_REQUESTED: {
      return {
        ...state,
        loadingLink: true
      };
    }
    case actionType.LINK_MEDCASE_SUCCESS: {
      return {
        ...state,
        successLink: action.successMsg,
        loadingLink: false
      };
    }
    case actionType.LINK_MEDCASE_FAIL: {
      return {
        ...state,
        errorLink: action.errorMsg,
        loadingLink: false
      };
    }
    case actionType.UNLINK_MEDCASE_REQUESTED: {
      return {
        ...state,
        loadingLink: true
      };
    }
    case actionType.UNLINK_MEDCASE_SUCCESS: {
      return {
        ...state,
        successUnlink: action.successMsg,
        loadingLink: false
      };
    }
    case actionType.UNLINK_MEDCASE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loadingLink: false
      };
    }
    case actionType.RESET_LINK_MEDCASE: {
      return {
        ...state,
        successUnlink: null,
        successLink: null
      };
    }
    case actionType.RESET_MEDCASE_LIST: {
      return {
        ...state,
        medcasetList: [],
        error: false,
        success: false,
        loading: false,
        selectedPatient: false
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: false,
        success: false,
        loading: false
      };
    }

    case actionType.SELECTED_PATIENT_FOR_MEDCASE: {
      return {
        ...state,
        selectedPatient: true
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default medcaseReducer;
