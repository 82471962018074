import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Button, Typography, Stack } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import DeleteModal from "../../shared/components/DeleteModal";
import { addNewManageLabConfig } from "../../shared/constants/renderConfigs";
import { enumUsStates } from "../../shared/constants/constants";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const PGTALabs = (props) => {
  let labForm = {
    labName: "",
    labAddress: "",
    city: "",
    zip: "",
    state: "",
    labPhone: "",
    labEmail: "",
    contactFName: "",
    contactLName: "",
    isActive: ""
  };
  const options = enumUsStates.map((item) => item.name);

  let initialData = {};
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  // const [labId, setLabId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [values, setValues] = useState("");
  const columns = [
    {
      field: "labName",
      headerName: "NAME",
      editable: false,
      width: 150
    },
    {
      field: "labAddress",
      headerName: "ADDRESS",
      editable: false,
      width: 150
    },
    {
      field: "city",
      headerName: "CITY",
      editable: false,
      width: 100
    },
    {
      field: "state",
      headerName: "STATE",
      editable: false,
      width: 100
    },
    {
      field: "zip",
      headerName: "ZIP",
      editable: false,
      width: 100
    },
    {
      field: "labPhone",
      headerName: "PHONE",
      editable: false,
      width: 100
    },
    {
      field: "labEmail",
      headerName: "EMAIL",
      editable: false,
      width: 200
    },
    {
      field: "contactFName",
      headerName: "CONTACT FIRST NAME",
      editable: false,
      width: 200
    },
    {
      field: "contactLName",
      headerName: "CONTACT LAST NAME",
      editable: false,
      width: 200
    },
    {
      field: "isActive",
      headerName: "STATUS",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            onClick={() => {
              handleEditLab(row);
            }}>
            <EditIcon color="primary"></EditIcon>
          </IconButton>
          {/* <IconButton
            onClick={() => {
              handleDeleteLab(row);
            }}>
            <DeleteIcon color="primary"></DeleteIcon>
          </IconButton> */}
        </>
      )
    }
  ];

  const { labList = [], actions, addLab, editLab, loading, errorMsg, successMsg } = props;

  const rows = labList
    ? labList.map((item, index) => {
        item.id = index;
        return item;
      })
    : [];

  const initFetch = useCallback(() => {
    actions.getPGTALab();
  }, [addLab, editLab]);

  useEffect(() => {
    actions.getPGTALab();
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if ((labList && labList.length == 0) || addLab || editLab) {
      initFetch();
    }

    // return () => {
    //   actions.resetManageLab();
    // };
  }, [addLab, editLab, errorMsg, successMsg]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setValues({
      ...values,
      [name]: value
    });
  };

  const handleAddLab = () => {
    setValues(labForm);
    setIsAdd(true);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const saveLab = (event) => {
    event.preventDefault();

    if (isAdd) {
      actions.createPGTALab({ ...values });
    } else {
      actions.updatePGTALab({ ...values });
    }
    closeModal();
  };

  const handleEditLab = (row) => {
    setIsAdd(false);
    setIsOpen(true);

    labForm = {
      labId: row.labId,
      labName: row.labName,
      labAddress: row.labAddress,
      city: row.city,
      state: row.state,
      zip: row.zip,
      labPhone: row.labPhone,
      labEmail: row.labEmail,
      contactFName: row.contactFName,
      contactLName: row.contactLName,
      isActive: row.isActive
    };
    setValues(labForm);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetPGTALabMessage();
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    initialData = {
      // labId: labId
    };
    actions.deleteLab(initialData);
    closeDeleteModal();
  };

  // const handleDeleteLab = (row) => {
  //   setDeleteModal(true);
  //   setLabId(row.labId);
  // };

  const emailTemplate = addNewManageLabConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled:
      values.labName &&
      values.city &&
      values.state &&
      values.zip &&
      values.labPhone &&
      values.labEmail &&
      values.contactFName &&
      values.contactLName &&
      values.isActive
        ? false
        : true,
    options
  });

  return (
    <>
      <Box
        sx={{ ml: 1 }}
        className={`${loading ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>PGTA Lab List</Typography>
          <Button variant="contained" className="btn-usf" onClick={handleAddLab}>
            New PGTA Lab
          </Button>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal
        open={isOpen}
        config={emailTemplate}
        isAdd={isAdd}
        handleSubmit={saveLab}
        modalClass="large-modal"
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <DeleteModal
        open={deleteModal}
        id={"" /* labId */}
        close={closeDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};
const mapStateToProps = ({ pgtaLabReducer }) => {
  return {
    loading: pgtaLabReducer.loading,
    labList: pgtaLabReducer.labList,
    addLab: pgtaLabReducer.addLab,
    editLab: pgtaLabReducer.editLab,
    deleteLab: pgtaLabReducer.deleteLab,
    errorMsg: pgtaLabReducer.error,
    successMsg: pgtaLabReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPGTALab: () => {
      dispatch(actionType.getPGTALab());
    },
    createPGTALab: (payload) => {
      dispatch(actionType.createPGTALab(payload));
    },
    updatePGTALab: (payload) => {
      dispatch(actionType.updatePGTALab(payload));
    },
    resetPGTALabList: () => {
      dispatch(actionType.resetPGTALabList());
    },
    resetPGTALabMessage: () => {
      dispatch(actionType.resetPGTALabMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PGTALabs);
