import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { Box, Tooltip } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const DisplayField = ({ label, value, tooltip }) => {
  return (
    <>
      <Stack direction="row" className="modal-stack">
        {label ? (
          <InputLabel className="flex-2 word-break">
            {label}
            {tooltip ? (
              <Tooltip title={tooltip} arrow followCursor={true}>
                <span>
                  <ErrorOutlineIcon fontSize="small" />
                </span>
              </Tooltip>
            ) : null}
          </InputLabel>
        ) : null}
        <Box sx={{ flex: 6 }} style={{ border: "1px solid #f1f1f1", padding: "5px" }}>
          {value && Array.isArray(value)
            ? value.map((item, index) => {
                return <span key={index + "_temp-vars"}>{item} &nbsp;&nbsp;</span>;
              })
            : value}
        </Box>
      </Stack>
    </>
  );
};

export default DisplayField;
