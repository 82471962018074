import * as actionType from "../actions/ActionTypes";

const initialState = {
  personalData: [],
  patientsList: [],
  partnerData: {},
  personDataById: {},
  taskList: [],
  taskCheckList: [],
  updateCheckList: [],
  loading: false,
  error: null,
  success: null,
  addPatientLoading: false,
  addPatientError: null,
  addPatientSuccess: null,
  partnerList: [],
  partnerLoading: false,
  fapData: {},
  mdListLoading: false,
  primaryMdList: [],
  newPatient: {}
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PERSONAL_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PERSONAL_DATA_SUCCESS: {
      if (action.payload.addPatient == "Add Patient") {
        return {
          ...state,
          patientsList: action.payload.patientResponse,
          loading: false
        };
      } else {
        return {
          ...state,
          personalData: action.payload.patientResponse,
          loading: false
        };
      }
    }
    case actionType.PERSONAL_DATA_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.RESET_PERSONAL_LIST: {
      return {
        ...state,
        personalData: [],
        patientsList: [],
        error: null,
        success: null
      };
    }
    case actionType.PARTNER_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PARTNER_DATA_SUCCESS: {
      return {
        ...state,
        partnerData: action.payload,
        loading: false
      };
    }
    case actionType.PARTNER_DATA_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.RESET_PARTNER_DATA: {
      return {
        ...state,
        partnerData: {}
        // personDataById: {}
      };
    }
    case actionType.PERSON_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PERSON_DATA_SUCCESS: {
      return {
        ...state,
        personDataById: action.payload,
        loading: false
      };
    }
    case actionType.PERSON_DATA_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.TASK_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.TASK_DATA_SUCCESS: {
      return {
        ...state,
        taskList: action.payload,
        loading: false
      };
    }
    case actionType.TASK_DATA_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.TASK_CHECKED_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.TASK_CHECKED_DATA_SUCCESS: {
      return {
        ...state,
        taskCheckList: action.payload,
        loading: false
      };
    }
    case actionType.TASK_CHECKED_DATA_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.UPDATE_CHECKED_DATA_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.UPDATE_CHECKED_DATA_SUCCESS: {
      return {
        ...state,
        updateCheckList: action.payload,
        loading: false
      };
    }
    case actionType.UPDATE_CHECKED_DATA_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.ADD_PATIENT_DETAILS_REQUESTED: {
      return {
        ...state,
        addPatientLoading: true
      };
    }
    case actionType.ADD_PATIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        newPatient: action.payload,
        addPatientSuccess: action.successMsg,
        addPatientLoading: false
      };
    }
    case actionType.ADD_PATIENT_DETAILS_FAILED: {
      return {
        ...state,
        addPatientError: action.errorMsg,
        addPatientLoading: false
      };
    }
    case actionType.RESET_PATIENT_DETAILS_MSG: {
      return {
        ...state,
        addPatientError: null,
        addPatientSuccess: null
      };
    }
    case actionType.UPDATE_PATIENT_DETAILS_REQUESTED: {
      return {
        ...state,
        addPatientLoading: true
      };
    }
    case actionType.UPDATE_PATIENT_DETAILS_SUCCESS: {
      return {
        ...state,
        addPatientLoading: false,
        addPatientSuccess: action.successMsg
      };
    }
    case actionType.UPDATE_PATIENT_DETAILS_FAILED: {
      return {
        ...state,
        addPatientLoading: false,
        addPatientError: action.errorMsg
      };
    }
    case actionType.RESET_UPDATE_PATIENT_DETAILS_MSG: {
      return {
        ...state,
        addPatientError: null,
        addPatientSuccess: null
      };
    }
    case actionType.GET_PATIENT_PARTNER_REQUESTED: {
      return {
        ...state,
        partnerLoading: true
      };
    }
    case actionType.GET_PATIENT_PARTNER_SUCCESS: {
      return {
        ...state,
        partnerList: action.payload,
        partnerLoading: false
      };
    }
    case actionType.GET_PATIENT_PARTNER_FAILED: {
      return {
        ...state,
        error: action.message,
        partnerLoading: false
      };
    }
    case actionType.RESET_PATIENT_PARTNER_LIST: {
      return {
        ...state,
        partnerList: []
      };
    }
    case actionType.FAP_DATA_REQUESTED: {
      return {
        ...state,
        fapDataLoading: true
      };
    }
    case actionType.FAP_DATA_SUCCESS: {
      return {
        ...state,
        fapDataLoading: false,
        fapData: action.payload
      };
    }
    case actionType.FAP_DATA_FAILED: {
      return {
        ...state,
        fapDataLoading: false,
        fapDataError: action.message
      };
    }
    case actionType.RESET_FAP_DATA: {
      return {
        ...state,
        fapData: {}
      };
    }
    case actionType.GET_PRIMARY_MD_REQUESTED: {
      return {
        ...state,
        mdListLoading: true
      };
    }
    case actionType.GET_PRIMARY_MD_SUCCESS: {
      return {
        ...state,
        mdListLoading: false,
        primaryMdList: action.payload
      };
    }
    case actionType.GET_PRIMARY_MD_FAILED: {
      return {
        ...state,
        mdListLoading: false
      };
    }
    case actionType.RESET_PRIMARY_MD_LIST: {
      return {
        ...state,
        primaryMdList: []
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default patientReducer;
