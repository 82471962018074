import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
// import * as token from "../../shared/constants/constants";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchProviderReimbursementList(action) {
  const { payload } = action;
  let params = {};
  try {
    if (payload.exportedDateFrom || payload.exportedDateTo || payload.practiceId) {
      params = {
        PRStatus: payload.status,
        startDate: payload.exportedDateFrom,
        endDate: payload.exportedDateTo,
        practiceId: payload.practiceId
      };
    } else {
      params = {
        PRStatus: payload.status
      };
    }
    const requestBody = createRequestBody("get", `/providerReimbursements`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.PROVIDEREIMBURSEMENT_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PROVIDEREIMBURSEMENT_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getProviderReimbursementSaga() {
  yield takeEvery(actionTypes.PROVIDEREIMBURSEMENT_LIST_REQUESTED, fetchProviderReimbursementList);
}

function* addApproveList(action) {
  const payload = action.payload;
  const body = payload.value;
  //   {
  //     prId: payload.value.prId,
  //     practiceId: payload.value.practiceId,
  //     practiceName: payload.value.practiceName,
  //     glNo: payload.value.glNo,
  //     medcaseId: payload.value.medcaseId,
  //     locationId: payload.value.locationId,
  //     locationName: payload.value.locationName,
  //     patientId: payload.value.patientId,
  //     patientFirstName: payload.value.patientFirstName,
  //     patientLastName: payload.value.patientLastName,
  //     dob: payload.value.dob,
  //     transactionDate: payload.value.transactionDate,
  //     transactionTypeDesc: payload.value.transactionTypeDesc,
  //     clinicalTreatmentId: payload.value.clinicalTreatmentId,
  //     clinicalCycleNo: payload.value.clinicalCycleNo,
  //     clinicalTreatmentNo: payload.value.clinicalTreatmentNo,
  //     outcomeDescription: payload.value.outcomeDescription,
  //     outcomeDate: payload.value.outcomeDate,
  //     enrollementDate: payload.value.enrollementDate,
  //     medcaseStatus: payload.value.medcaseStatus,
  //     initReimbursementAmount: payload.value.initReimbursementAmount,
  //     approvedReimbursementAmount: payload.value.approvedReimbursementAmount,
  //     gdmId: payload.value.gdmId,
  //     requestedTreatmentPlanId: payload.value.requestedTreatmentPlanId,
  //     requestedTreatmentPlan: payload.value.requestedTreatmentPlan,
  //     performedTreatmentPlanId: payload.value.performedTreatmentPlanId,
  //     performedTreatmentPlan: payload.value.performedTreatmentPlan,
  //     programName: payload.value.programName,
  //     programFee: payload.value.programFee,
  //     outcomeId: payload.value.outcomeId,
  //     outcomeStatus: payload.value.outcomeStatus,
  //     providerReimbursementScale: payload.value.providerReimbursementScale,
  //     reimbursementPercentage: payload.value.reimbursementPercentage,
  //     reimbursementStatus: payload.reimbursementStatus,
  //     isManualReview: true,
  //     isManualEntry: payload.value.isManualEntry,
  //     approvedDate: payload.value.approvedDate,
  //     exportedDate: payload.value.exportedDate,
  //     rejectedDate: payload.value.rejectedDate,
  //     comment: payload.value.comment,
  //     createdBy: payload.value.createdBy,
  //     creationDate: payload.value.creationDate,
  //     updatedBy: payload.value.updatedBy,
  //     updationDate: payload.value.updationDate
  //   }
  // ];
  // const options = {
  //   method: "PUT",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: token.token
  //   }
  // };

  try {
    const requestBody = createRequestBody("put", `/providerReimbursements`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_APPROVED_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ADD_APPROVED_FAILED,
        message: error.response.data || error.message
      });
    }
  }
}

function* addApproveSaga() {
  yield takeEvery(actionTypes.ADD_APPROVED_REQUESTED, addApproveList);
}

function* runPR() {
  try {
    const requestBody = createRequestBody("post", `/calculations/providerReimbursements`, "", {});
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.RUN_PR_SUCCESS,
      payload: response.data,
      successMsg: "Calculated Successfully"
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.RUN_PR_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}

function* runPRSaga() {
  yield takeEvery(actionTypes.RUN_PR_REQUESTED, runPR);
}

function* editPR(action) {
  const payload = action.payload;
  const body = [
    {
      prId: payload.prId,
      practiceId: payload.practiceId,
      practiceName: payload.practiceName,
      glNo: payload.glNo,
      medcaseId: payload.medcaseId,
      locationId: payload.locationId,
      locationName: payload.locationName,
      patientId: payload.patientId,
      patientFirstName: payload.patientFirstName,
      patientLastName: payload.patientLastName,
      dob: payload.dob,
      transactionDate: payload.transactionDate,
      transactionTypeDesc: payload.transactionTypeDesc,
      clinicalTreatmentId: payload.clinicalTreatmentId,
      clinicalCycleNo: payload.clinicalCycleNo,
      clinicalTreatmentNo: payload.clinicalTreatmentNo,
      outcomeDescription: payload.outcomeDescription,
      outcomeDate: payload.outcomeDate,
      enrollementDate: payload.enrollementDate,
      medcaseStatus: payload.medcaseStatus,
      initReimbursementAmount: payload.initReimbursementAmount,
      approvedReimbursementAmount: payload.approvedReimbursementAmount,
      gdmId: payload.gdmId,
      requestedTreatmentPlanId: payload.requestedTreatmentPlanId,
      requestedTreatmentPlan: payload.requestedTreatmentPlan,
      performedTreatmentPlanId: payload.performedTreatmentPlanId,
      performedTreatmentPlan: payload.performedTreatmentPlan,
      programName: payload.programName,
      programFee: payload.programFee,
      outcomeId: payload.outcomeId,
      outcomeStatus: payload.outcomeStatus,
      providerReimbursementScale: payload.providerReimbursementScale,
      reimbursementPercentage: payload.reimbursementPercentage,
      reimbursementStatus: payload.reimbursementStatus,
      isManualReview: true,
      isManualEntry: payload.isManualEntry,
      approvedDate: payload.approvedDate,
      exportedDate: payload.exportedDate,
      rejectedDate: payload.rejectedDate,
      comment: payload.comment,
      createdBy: payload.createdBy,
      creationDate: payload.creationDate,
      updatedBy: payload.updatedBy,
      updationDate: payload.updationDate
    }
  ];
  try {
    const requestBody = createRequestBody("put", `/providerReimbursements`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_PR_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_PR_FAILED,
        errorMsg: error.response.data
      });
    }
  }
}

function* editPRSaga() {
  yield takeEvery(actionTypes.EDIT_PR_REQUESTED, editPR);
}
export default function* ProviderReimbursementSaga() {
  yield all([getProviderReimbursementSaga(), addApproveSaga(), runPRSaga(), editPRSaga()]);
}
