import * as actionType from "../actions/ActionTypes";

const initialState = {
  stageDetailList: [],
  addStageToServiceItem: null,
  editStageToServiceItem: null,
  deleteStageToServiceItem: null,
  loading: false,
  error: null,
  success: null
};

const stageDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.STAGE_DETAIL_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.STAGE_DETAIL_LIST_SUCCESS: {
      return {
        ...state,
        stageDetailList: action.payload,
        loading: false
      };
    }
    case actionType.STAGE_DETAIL_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.ADD_SERVICE_ITEM_STAGES_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_SERVICE_ITEM_STAGES_SUCCESS: {
      return {
        ...state,
        addStageToServiceItem: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_SERVICE_ITEM_STAGES_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_SERVICE_ITEM_STAGES_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_SERVICE_ITEM_STAGES_SUCCESS: {
      return {
        ...state,
        editStageToServiceItem: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_SERVICE_ITEM_STAGES_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.DELETE_SERVICE_ITEM_STAGES_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_SERVICE_ITEM_STAGES_SUCCESS: {
      return {
        ...state,
        deleteStageToServiceItem: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_SERVICE_ITEM_STAGES_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_SERVICE_ITEM_STAGES: {
      return {
        ...state,
        addStageToServiceItem: null,
        editStageToServiceItem: null,
        deleteStageToServiceItem: null,
        loading: null
      };
    }
    case actionType.RESET_SERVICE_ITEM_STAGES_LIST: {
      return {
        ...state,
        stageDetailList: [],
        loading: null
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default stageDetailReducer;
