import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchDocumentList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/documents`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.DOCUMENT_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.DOCUMENT_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getDocumentSaga() {
  yield takeEvery(actionTypes.DOCUMENT_LIST_REQUESTED, fetchDocumentList);
}

export default function* documentSaga() {
  yield all([getDocumentSaga()]);
}
