import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* editEnableDisable(action) {
  const payload = action.payload;
  const userId = payload.userName;
  try {
    const paramsStatus = { userStatus: payload.userStatus };
    const requestBody = createRequestBody(
      "put",
      `/users/${userId}/changeUserStatus`,
      paramsStatus,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    console.log(response);
    const body = {
      loginEndDate: payload.loginEndDate,
      patientNameId: payload.patientNameId,
      endDate: payload.endDate,
      startDate: payload.startDate,
      loginStartDate: payload.loginStartDate
    };
    const requestBody2 = createRequestBody("get", `/adminPatientPortal`, "", body);
    const request2 = signatureV4CreateCanonicalRequest(requestBody2, getTemporaryCredentials());
    const responseAdmin = yield call(() => apis(request2));
    yield put({
      type: actionTypes.PATIENT_PORTAL_ADMIN_LIST_SUCCESS,
      payload: responseAdmin.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ENABLE_DISABLE_FAIL,
        message: error.message
      });
    }
  }
}

function* enableDisable() {
  yield takeEvery(actionTypes.ENABLE_DISABLE_REQUESTED, editEnableDisable);
}

export default function* enableDisableSaga() {
  yield all([enableDisable()]);
}
