import {
  Box,
  Typography,
  Stack,
  // MenuItem,
  // Select,
  Grid,
  InputLabel,
  TextField,
  Button,
  Autocomplete
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../../shared/components/LoadingSpinner";
import MessageModal from "../../shared/components/MessageModal";
import * as actionType from "../actions";

const RefundAge = (props) => {
  const [practiceId, setPracticeId] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [nonDonorMinAge, setNonDonorMinAge] = useState("");
  const [nonDonorMaxAge, setNonDonorMaxAge] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isMessageModalOpen, setIsMessageModal] = useState(false);
  const {
    actions,
    loadingPractice,
    loading,
    ageSaved,
    error,
    success,
    refundAge = {},
    editRefundAge,
    practices = []
  } = props;

  useEffect(() => {
    if (!practices.length) {
      actions.getPractices();
    }
  }, []);
  useEffect(() => {
    if (refundAge) {
      setIsEdit(true);
      setMinAge(refundAge.minimumAge);
      setMaxAge(refundAge.maximumAge);
      setNonDonorMinAge(refundAge.nonDonorMinimumAge);
      setNonDonorMaxAge(refundAge.nonDonorMaximumAge);
      if (editRefundAge) {
        setMinAge(editRefundAge.minimumAge);
        setMaxAge(editRefundAge.maximumAge);
        setNonDonorMinAge(editRefundAge.nonDonorMinimumAge);
        setNonDonorMaxAge(editRefundAge.nonDonorMaximumAge);
      }
    } else {
      setIsEdit(false);
    }
  }, [refundAge, editRefundAge, isEdit]);

  useEffect(() => {
    setPracticeId(practices[0]?.practiceId);
    actions.getRefundAge(practices[0]?.practiceId);
  }, [loadingPractice]);

  const handlePracticeChange = (value) => {
    if (value) {
      actions.resetMessage();
      setMinAge("");
      setMaxAge("");
      setNonDonorMinAge("");
      setNonDonorMaxAge("");
      setPracticeId(value?.value);
      actions.getRefundAge(value?.value);
    }
  };
  useEffect(() => {
    if (success || error) {
      setIsMessageModal(true);
    }
    if (refundAge || editRefundAge) {
      setIsEdit(true);
    }
  }, [loading, error, success, ageSaved, editRefundAge]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const handleSaveAge = () => {
    let payload = {};
    payload.practiceId = practiceId;
    payload.data = {
      minimumAge: minAge,
      maximumAge: maxAge,
      nonDonorMinimumAge: nonDonorMinAge,
      nonDonorMaximumAge: nonDonorMaxAge
    };

    if (isEdit) actions.editRefundAge(payload);
    else actions.saveAge(payload);
  };
  return (
    <>
      <Box
        className={`${
          loading ? "stage-details-section m-10 disable-section" : "stage-details-section m-10"
        }`}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item xs={6}>
            <Stack
              direction="row"
              sx={{
                my: 3,
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <Typography sx={{ flex: 2 }}>Practice</Typography>
              {/* <Select
                sx={{ flex: 7 }}
                fullWidth
                name="practiceId"
                value={practiceId}
                onChange={handlePracticeChange}
                className="stage-practice-dropdown">
                {practices.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.name} -{item.practiceId}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Autocomplete
                disablePortal
                sx={{ flex: 7 }}
                fullWidth
                name="practiceId"
                value={
                  practices.find((item) => item.practiceId === practiceId)
                    ? `${
                        practices.find((item) => item.practiceId === practiceId).name
                      } - ${practiceId}`
                    : ""
                }
                options={practices.map((item, index) => {
                  return {
                    id: index,
                    label: `${item.name} - ${item.practiceId}`,
                    name: "practiceId",
                    value: item.practiceId
                  };
                })}
                onChange={(e, value) => {
                  handlePracticeChange(value);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." />}
                className="stage-practice-dropdown"
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box
        className={`${
          loading ? "stage-details-section m-10 disable-section" : "stage-details-section m-10"
        }`}>
        <Grid item xs={8}>
          <Stack
            direction="row"
            sx={{
              my: 3,
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <Stack
              direction="row"
              sx={{
                my: 3,
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <InputLabel required sx={{ flex: 4 }}>
                Min Age (Donor)
              </InputLabel>
              <TextField
                sx={{ flex: 4 }}
                size="small"
                id="filled-basic"
                type="number"
                min={9}
                value={minAge}
                onChange={(e) => {
                  if (e.target.value < 0 || !e.target.value) setMinAge("");
                  else if (e.target.value <= 100) setMinAge(e.target.value);
                }}
                className="add-description"
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                my: 3,
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <InputLabel required sx={{ flex: 4 }}>
                Max Age (Donor)
              </InputLabel>
              <TextField
                sx={{ flex: 4, marginRight: "15px" }}
                size="small"
                id="filled-basic"
                type="number"
                value={maxAge}
                onChange={(e) => {
                  if (e.target.value < 0 || !e.target.value) setMaxAge("");
                  else if (e.target.value <= 100) setMaxAge(e.target.value);
                }}
                className="add-description"
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                my: 3,
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <InputLabel required sx={{ flex: 4 }}>
                &nbsp;
              </InputLabel>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack
            direction="row"
            sx={{
              my: 3,
              alignItems: "center",
              justifyContent: "space-between"
            }}>
            <Stack
              direction="row"
              sx={{
                my: 3,
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <InputLabel required sx={{ flex: 4 }}>
                Min Age ( Non-Donor)
              </InputLabel>

              <TextField
                sx={{ flex: 4, marginRight: "15px" }}
                size="small"
                id="nonDonorMinAge"
                name="nonDonorMinAge"
                type="number"
                min={9}
                value={nonDonorMinAge}
                onChange={(e) => {
                  if (e.target.value < 0 || !e.target.value) setNonDonorMinAge("");
                  else if (e.target.value <= 100) setNonDonorMinAge(e.target.value);
                }}
                className="add-description"
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                my: 3,
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <InputLabel required sx={{ flex: 4 }}>
                Max Age (Non-Donor)
              </InputLabel>
              <TextField
                sx={{ flex: 4, marginRight: "15px" }}
                size="small"
                id="nonDonorMaxAge"
                name="nonDonorMaxAge"
                type="number"
                value={nonDonorMaxAge}
                onChange={(e) => {
                  if (e.target.value < 0 || !e.target.value) setNonDonorMaxAge("");
                  else if (e.target.value <= 100) setNonDonorMaxAge(e.target.value);
                }}
                className="add-description"
              />
            </Stack>
            <Stack
              direction="row"
              sx={{
                my: 3,
                alignItems: "center",
                justifyContent: "space-between"
              }}>
              <Button
                variant="contained"
                className="btn-primary"
                disabled={minAge == "" || maxAge == "" || minAge == maxAge || minAge >= maxAge}
                onClick={handleSaveAge}>
                Save
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Box>
      <MessageModal
        open={isMessageModalOpen}
        message={success}
        error={error}
        closeModal={closeMessageModal}
      />
      {(loadingPractice || loading) && <LoadingSpinner />}
    </>
  );
};

const mapStateToProps = ({ practices, refundAgeReducer }) => {
  return {
    loadingPractice: practices.loading,
    refundAge: refundAgeReducer.refundAge,
    ageSaved: refundAgeReducer.ageSaved,
    editRefundAge: refundAgeReducer.editRefundAge,
    loading: refundAgeReducer.loading,
    success: refundAgeReducer.success,
    error: refundAgeReducer.error,
    practices: practices.practices
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    getRefundAge: (practiceId) => {
      dispatch(actionType.getRefundAge(practiceId));
    },
    saveAge: (payload) => {
      dispatch(actionType.saveAge(payload));
    },
    editRefundAge: (payload) => {
      dispatch(actionType.editRefundAge(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessageAge());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(RefundAge);
