import React, { useCallback, useEffect, useState } from "react";
import { Box, IconButton, Button, Typography, Stack } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { stageConfig } from "../../shared/constants/renderConfigs";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const Stages = (props) => {
  const navigate = useNavigate();
  let stageFormData = {
    stageCode: "",
    stageName: "",
    stageGroup: "",
    stageCategory: "Included",
    isrange: false,
    stagePassthrough: false,
    isFirstTime: false,
    sortStage: ""
  };
  const practiceId = sessionStorage.getItem("practiceId");
  const gdmId = sessionStorage.getItem("gdmId");
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [values, setValues] = useState(stageFormData);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const disabled = localStorage.getItem("status") !== "In-progress" ? true : false;
  const globalData = useSelector((state) => state.globalDataModelReducer.globalData);

  const columns = [
    {
      field: "stageCode",
      width: 50,
      flex: 1,
      renderHeader: () => (
        <span style={{ lineHeight: 1, fontSize: 12 }}>
          STAGE CODE/
          <br />
          SIM GROUP
        </span>
      )
    },
    {
      field: "stageName",
      headerName: "DESCRIPTION",
      width: 100,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Link
            to={`${row.stageId}?value=${value}`}
            onClick={() => {
              actions.resetStageDetailsList();
            }}
            className="word-break">
            {row.stageName}
          </Link>
        );
      }
    },
    {
      field: "stageGroup",
      headerName: "GROUP",
      width: 100,
      editable: false,
      flex: 0.7
    },
    {
      field: "stageCategory",
      headerName: "CATEGORY",
      width: 100,
      editable: false,
      flex: 1
    },

    {
      field: "totalPrice",
      headerName: "TOTAL PRICE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>$ {row.totalPrice}</div>;
      }
    },
    {
      field: "globalPrice",
      headerName: "GLOBAL PRICE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>$ {row.globalPrice}</div>;
      }
    },
    {
      field: "assistPrice",
      headerName: "ASSIST PRICE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>$ {Math.round(row.assistPrice)}</div>;
      }
    },
    {
      field: "sortStage",
      headerName: "Sort Order",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <div> {row.sortStage || "-"}</div>;
      }
    },
    {
      field: "isrange",
      headerName: "IS RANGE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton>
          <CheckCircleOutlineIcon
            color={row.isrange ? "primary" : "#8C8C8C"}></CheckCircleOutlineIcon>
        </IconButton>
      )
    },
    {
      field: "stagePassthrough",
      headerName: "IS PASSTHROUGH",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    }
  ];
  const { stages = [], stagesNotMapped = [], actions, addStage, loading, success, error } = props;

  const rows = stages.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    if (item.stageCategory == "Necessary") {
      item.stageCategory = "Necessary but Not Included";
    }
    if (item.stageCategory == "Optional") {
      item.stageCategory = "Optional/May Become Necessary";
    }
    item.id = index;
    return item;
  });
  const rowsNotMapped = stagesNotMapped.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    if (item.stageCategory == "Necessary") {
      item.stageCategory = "Necessary but Not Included";
    }
    if (item.stageCategory == "Optional") {
      item.stageCategory = "Optional/May Become Necessary";
    }
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    let payload = { practiceId: practiceId, gdmId: gdmId };
    actions.getStages(payload);
    actions.getStagesNotMapped(payload);
  }, []);

  useEffect(() => {
    if (success || error) {
      setIsMessageModal(true);
    }
    if (addStage) {
      actions.resetMessage();
      navigate(addStage, { state: values });
    }
    if (stages.length == 0) {
      initFetch();
    }
    return () => {
      //actions.resetStageList();
      actions.resetStageDetailsList();
    };
  }, [addStage, error]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    if (name == "isrange" || name == "stagePassthrough") {
      value = e.target.checked;
    }
    console.log("zhr name, value", name, value);
    setValues({
      ...values,
      [name]: value
    });
  };
  const addNewStage = () => {
    setValues(stageFormData);
    setIsOpen(true);
    setIsAdd(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const saveStage = (event) => {
    event.preventDefault();

    values.practiceId = practiceId;
    values.gdmId = gdmId;
    console.log("zhr values", values);

    if (isAdd) {
      actions.addNewStage(values);
      closeModal();
    }
  };
  const stage = stageConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.stageCode && values.stageName && values.sortStage ? false : true
  });

  const handleFileUpload = () => {
    navigate(`loadByExcel`);
  };

  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const isDisabled =
    globalData && globalData.filter((row) => row.gdmId === gdmId && row.status === "In-progress");

  return (
    <>
      <Box
        sx={{ ml: 1 }}
        className={`${loading ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"}`}>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="Stage List" value="1" />
                <Tab label="Stage List (Not Mapped)" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Stack direction="row" className="grid-header">
                <Typography>&nbsp;</Typography>
                <Stack direction="row">
                  <Button
                    variant="outlined"
                    className="btn-primary m-r-10"
                    onClick={handleFileUpload}
                    disabled={isDisabled.length ? false : true}>
                    Load from Excel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={disabled}
                    className="btn-usf"
                    onClick={addNewStage}>
                    New Stage
                  </Button>
                </Stack>
              </Stack>
              <Box className="grid-style" sx={{ height: "660px" }}>
                <DataGridPremiumComp
                  rows={rows}
                  rowCount={rows.length}
                  columns={columns}
                  loading={loading}
                  pageSizeOptions={[10, 25, 50, 100]}
                  pageSize={pageSize.pageSize}
                  paginationModel={pageSize}
                  onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
                  pagination
                />
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Stack direction="row" className="grid-header">
                <Typography>&nbsp;</Typography>
                <Stack direction="row">
                  <Button
                    variant="outlined"
                    className="btn-primary m-r-10"
                    onClick={handleFileUpload}
                    disabled={isDisabled.length ? false : true}>
                    Load from Excel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={disabled}
                    className="btn-usf"
                    onClick={addNewStage}>
                    New Stage
                  </Button>
                </Stack>
              </Stack>
              <Box className="grid-style" sx={{ height: "660px" }}>
                <DataGridPremiumComp
                  rows={rowsNotMapped}
                  columns={columns}
                  loading={loading}
                  pageSizeOptions={[10, 25, 50, 100]}
                  pageSize={pageSize.pageSize}
                  paginationModel={pageSize}
                  onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
                  pagination
                />
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
      {/* Add/edit stage modal*/}
      <CommonModal open={isOpen} config={stage} isAdd={isAdd} handleSubmit={saveStage} />
      {/* Add/edit practice modal end*/}
      <MessageModal
        open={isMessageModal}
        message={success}
        error={error}
        closeModal={closeMessageModal}
      />
    </>
  );
};
const mapStateToProps = ({ stageReducer }) => {
  return {
    loading: stageReducer.loading,
    stages: stageReducer.stageList,
    stagesNotMapped: stageReducer.stageListNotMapped,
    addStage: stageReducer.addStage,
    success: stageReducer.success,
    error: stageReducer.error
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getStages: (payload) => {
      dispatch(actionType.getStages(payload));
    },
    getStagesNotMapped: (payload) => {
      dispatch(actionType.getStagesNotMapped(payload));
    },
    addNewStage: (payload) => {
      dispatch(actionType.addNewStage(payload));
    },
    resetStageList: () => {
      dispatch(actionType.resetStageList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    resetStageDetailsList: () => {
      dispatch(actionType.resetStageDetailsList());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Stages);
