import { getQueryString } from "../constants/constants";
import { url, config } from "../constants/cognitoConstants";
import { clearSignInItems } from "./SignApi";
import { AWS_CREDENTIALS } from "../constants/cognitoConstants";

export default function Login() {
  //TODO need to implement some logic which will take care of environment specific config

  //deleting all items required for cognito & role based access
  clearSignInItems([AWS_CREDENTIALS, "screenRights", "cognitoUserId"]);

  window.location.href = url + "login" + getQueryString(config);
  return null;
}
