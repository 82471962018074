import React, { useState, useEffect } from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import { connect } from "react-redux";
import { medCaseColumns } from "../constants";
import MedCaseModal from "./modals/MedCaseModal";
import moment from "moment";
import * as actionType from "../actions";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const MedcaseList = (props) => {
  const { medcaseList, loading, actions, selectedPatient } = props;
  const [open, setOpen] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [selectedRow, setSelectedRow] = useState(null);
  const [patientSelected, setPatientSelected] = useState(false);
  const [isManual, setIsManual] = useState(false);
  const [patientData, setPatientData] = useState({});

  useEffect(() => {
    setSelectedRow(null);
    setPatientData({});
  }, [loading]);

  let rows = [];
  if (medcaseList)
    rows = medcaseList.map((item, index) => {
      item.id = index;
      if (item.birthDate)
        item.dob = moment(
          new Date(
            item?.birthDate.replace(/^(\d{4})(\d\d)(\d\d)(\d\d)(\d\d)(\d\d)$/, "$4:$5:$6 $2/$3/$1")
          )
        ).format("L");
      else item.dob = "";
      item.patientName = item.firstName + " " + item.lastName;
      item.personName = item.firstName + " " + item.lastName;
      item.primaryMD =
        item.ivfMDFirstName && item.ivfMDLastName
          ? item.ivfMDFirstName + " " + item.ivfMDLastName
          : "--";
      return item;
    });
  const handleMedcase = () => {
    setIsManual(true);
    setOpen(true);
  };
  const handleRadio = (e, data) => {
    setSelectedRow(data.id);
    setPatientData(data);
    actions.selectedPatient();
  };
  const handleClose = () => {
    setOpen(false);
    setPatientSelected(false);
  };
  const createMedCaseForSelected = () => {
    setIsManual(false);
    setOpen(true);
    setPatientSelected(true);
  };

  return (
    <div>
      <Box className="m-8">
        <Stack direction="row" className="grid-header">
          <Typography>{rows.length ? rows.length : 0} Patients matching your criteria</Typography>
          <Stack spacing={2} direction="row">
            <Button
              variant="contained"
              disabled={selectedPatient ? false : true}
              className="btn-usf "
              onClick={createMedCaseForSelected}>
              Create Medcase for Selected Patient
            </Button>
            <Button variant="contained" className="btn-usf" onClick={handleMedcase}>
              New Medcase Manually
            </Button>
          </Stack>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={medCaseColumns(handleRadio, selectedRow)}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            disableMultipleSelection={true}
            components={
              <div
                style={{
                  position: "absolute",
                  top: "300px",
                  zIndex: 99,
                  left: "40%"
                }}>
                <Typography>
                  Unable to Find Patient. Please
                  <Button onClick={handleMedcase}>Create Medcase</Button> {loading && "test"}
                  Manually
                </Typography>
              </div>
            }
          />
        </Box>
      </Box>
      {open ? (
        <MedCaseModal
          open={open}
          handleClose={handleClose}
          isManual={isManual}
          patientData={patientData}
          patientSelected={patientSelected}
          loading={loading}
        />
      ) : null}
    </div>
  );
};
const mapStateToProps = ({ medcaseReducer }) => {
  return {
    linkMedcaseCreated: medcaseReducer.linkMedcaseCreated,
    medcaseList: medcaseReducer.medcasetList,
    loading: medcaseReducer.listLoading,
    medcaseCreatedM: medcaseReducer.medcaseCreatedM,
    success: medcaseReducer.success,
    error: medcaseReducer.error,
    selectedPatient: medcaseReducer.selectedPatient
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    selectedPatient: () => {
      dispatch(actionType.selectedPatient());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(MedcaseList);
