// import Checkbox from "@mui/material/Checkbox";
import { Button, Checkbox, Radio, Typography } from "@mui/material";
import moment from "moment";

import { Link } from "react-router-dom";
export const medCaseColumns = (handleRadio, data) => [
  {
    field: "patientName",
    headerName: "PATIENT NAME",
    width: 100,
    flex: 1,
    sortable: false
  },
  {
    field: "personKey",
    headerName: "PERSON NO",
    width: 100,
    editable: false,
    flex: 1
  },
  {
    field: "practiceId",
    headerName: "PRACTICE",
    width: 75,
    editable: false,
    flex: 1
  },
  {
    field: "mailingCity",
    headerName: "LOCATION",
    width: 75,
    editable: false,
    flex: 1
  },
  {
    field: "dob",
    headerName: "DOB",
    width: 100,
    editable: false,
    flex: 1
  },
  {
    field: "primaryMD",
    headerName: "PRIMARY MD",
    width: 100,
    editable: false,
    flex: 1
  },
  {
    field: "select",
    headerName: "SELECT",
    width: 50,
    editable: false,
    flex: 1,
    renderCell: ({ row }) => (
      <Radio
        id={"radio" + row.id}
        checked={row.id == data}
        value={row.id}
        onClick={(e) => handleRadio(e, row)}></Radio>
    )
  }
];

export const caseListColumns = (handleRadio, data, setIsOpenComment, setFullComment) => [
  {
    field: "select",
    headerName: "SELECT",
    width: 90,
    editable: false,
    renderCell: ({ row }) => (
      <Radio
        id={"radio" + row.medcaseId}
        checked={row.medcaseId == data}
        value={row.medcaseId}
        onClick={(e) => handleRadio(e, row)}
        disabled={
          sessionStorage.getItem("Medcase_Link") == "Link New Medcase" ? false : true
        }></Radio>
    )
  },
  {
    field: "medcaseId",
    headerName: "MEDCASE ID",
    width: 90,
    sortable: false,
    renderCell: ({ row }) => (
      <Link
        style={{
          margin: "1rem",
          textDecoration: "none",
          color: "#1BA3AD",
          fontFamily: "Rubik",
          fontWeight: 500
        }}
        to={{
          pathname: `/trak/caselookup/caselist/${row.medcaseId}/status`,
          state: { data: row.medcaseId }
        }}>
        {row.medcaseId}
      </Link>
    )
  },
  {
    field: "personKey",
    headerName: "PERSON NO",
    width: 90,
    editabe: false
  },
  {
    field: "patientName",
    headerName: "PATIENT NAME",
    width: 90,
    editable: false
  },
  {
    field: "progShortName",
    headerName: "PROGRAM Short Name",
    width: 90,
    editable: false
  },
  {
    field: "practice",
    headerName: "PRACTICE",
    width: 90,
    editable: false
  },
  {
    field: "location",
    headerName: "LOCATION",
    width: 90,
    editable: false
  },
  {
    field: "physician",
    headerName: "PHYSICIAN",
    width: 90,
    editable: false
  },
  {
    field: "contact",
    headerName: "CONTACT",
    width: 90,
    editable: false
  },
  {
    field: "currentAuthorizedCycle",
    headerName: "CURRENT AUTHORIZED CYCLE",
    width: 90,
    editable: false,
    renderCell: ({ row }) => {
      let currentAuthorizedCycle = row?.currentAuthorizedCycle;
      console.log("currentAuthorizedCycle: ", currentAuthorizedCycle);

      let cycleNo;
      if (currentAuthorizedCycle) {
        cycleNo = currentAuthorizedCycle.split(" ");
      }

      const url =
        cycleNo && cycleNo.length > 0
          ? `/trak/caselookup/caselist/${row?.medcaseId}/financial-snapshot?cycle=${cycleNo[0]}`
          : "";

      return (
        <div className="word-wrap">
          <Link
            style={{
              textDecoration: "none",
              color: "#1BA3AD",
              fontFamily: "Rubik",
              fontWeight: 500
            }}
            target="_blank"
            to={url}>
            {row?.currentAuthorizedCycle}
          </Link>
          <Typography>{row?.authorizationStatus}</Typography>
        </div>
      );
    }
  },
  {
    field: "outcome",
    headerName: "OUTCOME",
    width: 90,
    editable: false
  },
  {
    field: "normalFrozenEmbryosRemaining",
    headerName: "NORMAL EMBRYOS REMAIN",
    width: 90,
    editable: false
  },
  {
    field: "statusDate",
    headerName: "STATUS DATE",
    type: "date",
    width: 90,
    editable: false,
    renderCell: ({ row }) =>
      // row.statusDate && !row.pgtaStatus ? moment(row.statusDate).format("MM/DD/YYYY") : ""
      row.medcaseStatusDate ? moment(row.medcaseStatusDate).format("MM/DD/YYYY") : ""
  },
  {
    field: "medcaseStatus",
    headerName: "MEDCASE STATUS",
    width: 90,
    editable: false
  },
  {
    field: "contractStatus",
    headerName: "CONTRACT STATUS",
    width: 90,
    editable: false
  },
  {
    field: "pgtaStatus",
    headerName: "PGTA STATUS",
    width: 90,
    editable: false
  },
  {
    field: "pgtaStatusDate",
    headerName: "PGTA STATUS DATE",
    width: 90,
    editable: false,
    renderCell: ({ row }) =>
      row.pgtaStatus && row.statusDate ? moment(row.statusDate).format("MM/DD/YYYY") : ""
  },
  {
    field: "paymentStatus",
    headerName: "PATIENT PAYMENT STATUS",
    width: 90,
    editable: false
  },
  {
    field: "mcpNeReason",
    headerName: "IVF Not Enrolled",
    width: 90,
    editable: false
  },
  {
    field: "pgtaNeReason",
    headerName: "PGTA Not Enrolled",
    width: 90,
    editable: false
  },
  {
    field: "estPublishedBy",
    headerName: "Estimate Pulished By",
    width: 90,
    editable: false
  },
  {
    field: "patientComm",
    headerName: "PATIENT COMM",
    width: 50,
    editable: false,
    flex: 1,
    renderCell: ({ row }) => {
      return row?.patientComm == "true" ? (
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => {
            setIsOpenComment(true);
            setFullComment(row?.comment);
          }}
          onClick={() => {
            setIsOpenComment(true);
            setFullComment(row?.comment);
          }}>
          <Checkbox
            sx={{
              color: "#13c552",
              "&.Mui-checked": {
                color: "red"
              }
            }}
            disabled
            checked={row?.patientComm}
          />
        </div>
      ) : (
        <Checkbox
          sx={{
            color: "#13c552",
            "&.Mui-checked": {
              color: "red"
            }
          }}
          disabled
          checked={row?.patientComm}
        />
      );
    }
  }
];

export const contractColumns = [
  {
    field: "patientId",
    headerName: "PATIENT ID",
    width: 100,
    flex: 1,
    sortable: false
  },
  {
    field: "medcaseId",
    headerName: "MEDCASE ID",
    width: 100,
    editable: false,
    flex: 1
  },
  {
    field: "patientName",
    headerName: "PATIENT NAME",
    width: 75,
    editable: false,
    flex: 1
  },
  {
    field: "contractGenerated",
    headerName: "CONTRACT GENERATED DATE",
    width: 75,
    editable: false,
    flex: 1,
    renderCell: ({ row }) =>
      row.contractGenerated ? moment(row.contractGenerated).format("MM/DD/YYYY") : ""
  },
  {
    field: "contractStatus",
    headerName: "CONTRACT STATUS",
    width: 100,
    editable: false,
    flex: 1
  },
  {
    field: "paymentStatus",
    headerName: "PAYMENT STATUS",
    width: 100,
    editable: false,
    flex: 1
  },
  {
    field: "medcaseStatus",
    headerName: "MEDCASE STATUS",
    width: 100,
    editable: false,
    flex: 1
  },
  {
    field: "pgtaProgramStatus",
    headerName: "PGTA PROGRAM STATUS",
    width: 100,
    editable: false,
    flex: 1
  },
  {
    field: "reminderDate",
    headerName: "REMAINDER DATE",
    width: 100,
    editable: false,
    flex: 1,
    type: "date",
    renderCell: ({ row }) => (row.reminderDate ? moment(row.reminderDate).format("MM/DD/YYYY") : "")
  }
];

export const programCyclesColumns = (handleProgramClick) => [
  {
    field: "clinicalTreatmentNo",
    headerName: "CYCLE NO",
    width: 70,
    sortable: false,
    renderCell: ({ row }) => (
      <Button
        id={"radio" + row.id}
        type="link"
        value={row.clinicalTreatmentNo}
        onClick={(e) => handleProgramClick(e, row)}>
        {row.clinicalTreatmentNo}
      </Button>
    ),
    align: "center"
  },
  {
    field: "authorizedTreatmentCustom",
    headerName: "AUTHORIZED TREATMENT",
    width: 100,
    editable: false,
    align: "center"
  },
  {
    field: "performedTreatment",
    headerName: "PERFORMED TREATMENT",
    width: 100,
    editable: false,
    align: "center"
  },
  {
    field: "outcome",
    headerName: "OUTCOME",
    width: 110,
    editable: false,
    align: "center"
  },
  {
    field: "outcomeDate",
    headerName: "OUTCOME DATE",
    width: 90,
    editable: false,
    align: "center",
    renderCell: ({ row }) => (row.outcomeDate ? moment(row.outcomeDate).format("MM/DD/YYYY") : "")
  },
  {
    field: "embryosRemaining",
    headerName: "NORMAL EMBRYOS REMAINING",
    width: 100,
    editable: false,
    align: "center"
  },
  {
    field: "cycleTransferDate",
    headerName: "TRANSFER DATE",
    width: 100,
    editable: false,
    align: "center",
    renderCell: ({ row }) =>
      row.cycleTransferDate ? moment(row.cycleTransferDate).format("MM/DD/YYYY") : ""
  },
  {
    field: "misscarriageDate",
    headerName: "MISCARRIAGE DATE",
    width: 120,
    editable: false,
    renderCell: ({ row }) => (row.misscarriageDate ? `${row.misscarriageDate}` : "--"),
    align: "center"
  },
  {
    field: "authorizedDate",
    headerName: "AUTH DATE",
    width: 90,
    editable: false,
    renderCell: ({ row }) =>
      row.authorizedDate ? moment(row.authorizedDate).format("MM/DD/YYYY") : ""
  },
  {
    field: "clinicalDecisions",
    headerName: "CLINICAL DECISIONS",
    width: 80,
    editable: false
  },
  {
    field: "requestedCycle",
    headerName: "REQUESTED CYCLE",
    width: 90,
    editable: false,
    renderCell: ({ row }) => {
      let isWhole = Number.isInteger(row.requestedCycle);
      if (isWhole === true) {
        return row.requestedCycle + ".0";
      } else {
        return row.requestedCycle;
      }
    }
  },
  {
    field: "requestedDate",
    headerName: "REQUESTED DATE",
    width: 90,
    editable: false
  },
  {
    field: "comment",
    headerName: "COMMENT",
    width: 90,
    editable: false,
    renderCell: ({ row }) => {
      return <Checkbox key={"row" + row.comment} disabled checked={row.comment} />;
    }
  },
  {
    field: "isClinicalReview",
    headerName: "REVIEW",
    width: 90,
    editable: false,
    renderCell: ({ row }) => {
      return (
        <Checkbox key={"row" + row.isClinicalReview} disabled checked={row.isClinicalReview} />
      );
    }
  }
];

// export const transactionLogColumns = [
//   {
//     field: "transaction",
//     headerName: "TRANSACTION NO",
//     width: 100,
//     flex: 1,
//     sortable: false,
//     renderCell: ({ row }) => (
//       <Link
//         to={row.transaction}
//         // onClick={() => {
//         //   actions.setPracticeId(row.practiceId);
//         // }}
//       >
//         {row.name}
//       </Link>
//     )
//   },
//   {
//     field: "amount",
//     headerName: "AMOUNT",
//     width: 100,
//     editable: false,
//     flex: 1
//   },
//   {
//     field: "transactionType",
//     headerName: "TRANSACTION TYPE",
//     width: 75,
//     editable: false,
//     flex: 1
//   },
//   {
//     field: "paymentType",
//     headerName: "PAYMENT TYPE",
//     width: 75,
//     editable: false,
//     flex: 1
//   },
//   {
//     field: "transactionDate",
//     headerName: "TRANSACTION DATE",
//     width: 100,
//     editable: false,
//     flex: 1
//   },
//   {
//     field: "recordedDate",
//     headerName: "RECORD DATE",
//     width: 100,
//     editable: false,
//     flex: 1
//   },
//   {
//     field: "user",
//     headerName: "USER",
//     width: 100,
//     editable: false,
//     flex: 1
//   },
//   {
//     field: "isImportant",
//     headerName: "IMPORTANT CHECKBOX",
//     width: 50,
//     editable: false,
//     flex: 1,
//     renderCell: ({ row }) => {
//       <CheckBox id={"radio" + row.id} value={row.isImportant} />;
//     }
//   },
//   {
//     field: "comment",
//     headerName: "COMMENT",
//     width: 50,
//     editable: false,
//     flex: 1
//   }
// ];

export const expiaryMonths = [
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" }
];

export const expiaryYear = [
  { value: 0, label: "01" },
  { value: 1, label: "02" },
  { value: 2, label: "03" },
  { value: 3, label: "04" },
  { value: 4, label: "05" },
  { value: 5, label: "06" },
  { value: 6, label: "07" },
  { value: 7, label: "08" },
  { value: 8, label: "09" },
  { value: 9, label: "10" },
  { value: 10, label: "11" }
];
// export const expiaryYear = [
//   { value: 1, label: "01" },
//   { value: 2, label: "02" },
//   { value: 3, label: "03" },
//   { value: 4, label: "04" },
//   { value: 5, label: "05" },
//   { value: 6, label: "06" },
//   { value: 7, label: "07" },
//   { value: 8, label: "08" },
//   { value: 9, label: "09" },
//   { value: 10, label: "10" }
// ];
export const currentYear = new Date().getFullYear();

export const patientPaymentWarning =
  "Total Patient Payment(s) to patient account is greater than net Program fee .Please review Financials";
