import * as actionType from "../actions/ActionTypes";

const initialState = {
  loading: false,
  error: null,
  success: null
};

const enrollReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.EDIT_ENROLL_REQUESTED: {
      return {
        ...state,
        loading: true,
        error: null,
        success: null
      };
    }
    case actionType.EDIT_ENROLL_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_ENROLL_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default enrollReducer;
