import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Button, Typography, Stack } from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import DeleteModal from "../../shared/components/DeleteModal";
import { emailTemplateConfig } from "../../shared/constants/renderConfigs";
import draftToHtml from "draftjs-to-html";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const EmailTemplate = (props) => {
  let emailTemplateForm = {
    emailSubject: "",
    emailSlug: "",
    emailBody: "",
    toRecipient: "",
    ccRecipient: "",
    bccRecipient: "",
    isActive: "1"
  };

  let initialData = {};
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  // const [emailTemplateId, setEmailTemplateId] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [values, setValues] = useState("");
  const [emailVars, setEmailVars] = useState([]);
  const columns = [
    {
      field: "emailTemplateId",
      headerName: "TEMPLATE ID",
      width: 100,
      flex: 1
    },
    {
      field: "emailSubject",
      headerName: "SUBJECT",
      width: 100,
      flex: 1
    },
    {
      field: "emailSlug",
      headerName: "TYPE",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "isActive",
      headerName: "STATUS",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return row.isActive == "1" ? "Active" : "Inactive";
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.5,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            onClick={() => {
              handleEditEmailTemplate(row);
            }}>
            <EditIcon color="primary"></EditIcon>
          </IconButton>
          {/* <IconButton
            onClick={() => {
              handleDeleteEmailTemplate(row);
            }}>
            <DeleteIcon color="primary"></DeleteIcon>
          </IconButton> */}
        </>
      )
    }
  ];

  const {
    emailTemplateList = [],
    emailTemplateSettings = [],
    actions,
    addEmailTemplate,
    editEmailTemplate,
    loading,
    errorMsg,
    successMsg
  } = props;

  const rows = emailTemplateList
    ? emailTemplateList.map((item, index) => {
        item.id = index;
        return item;
      })
    : [];

  const initFetch = useCallback(() => {
    actions.getEmailTemplate();
  }, [addEmailTemplate, editEmailTemplate]);

  useEffect(() => {
    actions.getEmailTemplate();
    actions.getEmailTemplateSetting();
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (
      (emailTemplateList && emailTemplateList.length == 0) ||
      addEmailTemplate ||
      editEmailTemplate
    ) {
      initFetch();
    }

    return () => {
      actions.resetEmailTemplateList();
    };
  }, [addEmailTemplate, editEmailTemplate, errorMsg, successMsg]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    if (name === "emailSlug") {
      const selectedTemplate = emailTemplateSettings.filter((val) => {
        return val.name === value;
      });

      setEmailVars(
        (selectedTemplate && selectedTemplate[0] && selectedTemplate[0].emailVars) || []
      );
    }

    setValues({
      ...values,
      [name]: value
    });
  };

  const handleAddEmailTemplate = () => {
    setEmailVars([]);
    setValues(emailTemplateForm);
    setIsAdd(true);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const saveEmailTemplate = (event) => {
    event.preventDefault();

    let payload = JSON.parse(JSON.stringify(values));
    payload.emailBody = btoa(draftToHtml(values.emailBody));
    payload.emailVars = emailVars.toString();

    if (isAdd) {
      actions.createEmailTemplate(payload);
    } else {
      actions.updateEmailTemplate(payload);
    }
    closeModal();
  };

  const handleEditEmailTemplate = (row) => {
    setIsAdd(false);
    setIsOpen(true);

    emailTemplateForm = {
      emailTemplateId: row.emailTemplateId,
      emailSubject: row.emailSubject,
      emailSlug: row.emailSlug,
      emailBody: atob(row.emailBody),
      toRecipient: row.toRecipient,
      ccRecipient: row.ccRecipient,
      bccRecipient: row.bccRecipient,
      isActive: row.isActive
    };
    setValues(emailTemplateForm);

    const selectedTemplate = emailTemplateSettings.filter((val) => {
      return val.name === row.emailSlug;
    });

    setEmailVars((selectedTemplate && selectedTemplate[0] && selectedTemplate[0].emailVars) || []);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetEmailTemplateMessage();
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    initialData = {
      // emailTemplateId: emailTemplateId
    };
    actions.deleteEmailTemplate(initialData);
    closeDeleteModal();
  };

  // const handleDeleteEmailTemplate = (row) => {
  //   setDeleteModal(true);
  //   setEmailTemplateId(row.emailTemplateId);
  // };

  const setTemplateBody = (data) => {
    setValues({
      ...values,
      ["emailBody"]: data
    });
  };

  const emailTemplate = emailTemplateConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled:
      values.emailSubject && values.emailSlug && values.emailBody && values.isActive ? false : true,
    setTemplateBody: setTemplateBody,
    templateSlugs:
      emailTemplateSettings && emailTemplateSettings.length
        ? emailTemplateSettings.map((v) => v.name)
        : [],
    emailVars
  });

  console.log(emailTemplateSettings, "emailTemplateSettingsemailTemplateSettings");

  return (
    <>
      <Box
        sx={{ ml: 1 }}
        className={`${loading ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>Email Template List</Typography>
          <Button variant="contained" className="btn-usf" onClick={handleAddEmailTemplate}>
            New Email Template
          </Button>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal
        open={isOpen}
        config={emailTemplate}
        isAdd={isAdd}
        handleSubmit={saveEmailTemplate}
        modalClass="large-modal"
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <DeleteModal
        open={deleteModal}
        id={"" /* emailTemplateId */}
        close={closeDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  );
};
const mapStateToProps = ({ emailTemplateReducer }) => {
  return {
    loading: emailTemplateReducer.loading,
    emailTemplateList: emailTemplateReducer.emailTemplateList,
    addEmailTemplate: emailTemplateReducer.addEmailTemplate,
    editEmailTemplate: emailTemplateReducer.editEmailTemplate,
    deleteEmailTemplate: emailTemplateReducer.deleteEmailTemplate,
    errorMsg: emailTemplateReducer.error,
    successMsg: emailTemplateReducer.success,
    emailTemplateSettings: emailTemplateReducer.emailTemplateSettings
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEmailTemplate: () => {
      dispatch(actionType.getEmailTemplate());
    },
    createEmailTemplate: (payload) => {
      dispatch(actionType.createEmailTemplate(payload));
    },
    updateEmailTemplate: (payload) => {
      dispatch(actionType.updateEmailTemplate(payload));
    },
    deleteEmailTemplate: (payload) => {
      dispatch(actionType.deleteEmailTemplate(payload));
    },
    resetEmailTemplateList: () => {
      dispatch(actionType.resetEmailTemplateList());
    },
    resetEmailTemplateMessage: () => {
      dispatch(actionType.resetEmailTemplateMessage());
    },
    getEmailTemplateSetting: () => {
      dispatch(actionType.getEmailTemplateSetting());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplate);
