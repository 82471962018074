import * as actionType from "../actions/ActionTypes";

const initialState = {
  boxList: [],
  programList: [],
  addProgram: null,
  deleteProgram: null,
  addBox: null,
  deleteBox: null,
  loading: false,
  error: null,
  success: false
};
const programVisualizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.BOX_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.BOX_LIST_SUCCESS: {
      return {
        ...state,
        boxList: action.payload,
        loading: false
      };
    }
    case actionType.BOX_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_BOX_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_BOX_SUCCESS: {
      return {
        ...state,
        deleteBox: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_BOX_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_PROGRAM_IN_BOX_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_PROGRAM_IN_BOX_SUCCESS: {
      return {
        ...state,
        deleteProgram: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_PROGRAM_IN_BOX_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.CREATE_BOX_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_BOX_SUCCESS: {
      return {
        ...state,
        addBox: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.CREATE_BOX_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.CREATE_PROGRAM_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.CREATE_PROGRAM_SUCCESS: {
      return {
        ...state,
        addProgram: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.CREATE_PROGRAM_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_BOX_DATA: {
      return {
        ...state,
        error: false,
        success: false
      };
    }
    case actionType.PROGRAM_LIST_VISUALIZATION_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PROGRAM_LIST_VISUALIZATION_SUCCESS: {
      return {
        ...state,
        programList: action.payload,
        loading: false
      };
    }
    case actionType.PROGRAM_LIST_VISUALIZATION_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default programVisualizationReducer;
