import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import * as actionType from "../actions";
import MessageModal from "../../shared/components/MessageModal";
import { Box, Button, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const LocationProgramDef = (props) => {
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);
  const gdmId = sessionStorage.getItem("gdmId");
  const practiceId = sessionStorage.getItem("practiceId");
  const status = localStorage.getItem("status");
  const { locationProgramDefList, loading, error, addSuccess, metadata = {}, actions } = props;
  const [values, setValues] = useState([]);
  useEffect(() => {
    actions.getLocationProgramDef({ gdmId: gdmId, practiceId: practiceId });
    actions.getMetadata({ gdmId: gdmId, practiceId: practiceId });
  }, []);
  const columns = [
    {
      field: "program",
      headerName: "PROGRAM",
      width: 100,
      sortable: false
    },
    {
      field: "treatmentPlan",
      headerName: "TREATMENT PLAN",
      width: 150,
      editable: false,
      renderCell: ({ row }) => {
        return <div>{row?.treatmentPlan}</div>;
      }
    },
    {
      field: "globalPrice",
      headerName: "GLOBAL PRICE",
      width: 100,
      editable: false
    },
    {
      field: "assistPrice",
      headerName: "ASSIST PRICE",
      width: 100,
      editable: false
    },
    {
      field: "ageCategory",
      headerName: "AGE CATEGORY",
      width: 150,
      editable: false
    },
    {
      field: "multiplier",
      headerName: "MULTIPLIER",
      width: 100,
      editable: false
    },
    {
      field: "programPriceGlobal",
      headerName: "PROGRAM PRICE (GLOBAL)",
      width: 150,
      editable: false,
      renderCell: ({ row }) => {
        return <div>{Math.round(row.programPriceGlobal)}</div>;
      }
    },
    {
      field: "programPriceAssist",
      headerName: "PROGRAM PRICE (ASSIST)",
      width: 150,
      editable: false,
      renderCell: ({ row }) => {
        return <div>{Math.round(row.programPriceAssist)}</div>;
      }
    },
    {
      field: "refund",
      headerName: "REFUND %",
      width: 75,
      editable: false
    },
    {
      field: "ivfCycle",
      headerName: "IVF NO.OF CYCLES",
      width: 100,
      editable: false
    },
    {
      field: "fetTreatment",
      headerName: "FET TREATMENT",
      editable: true,
      width: 150,
      renderCell: ({ row }) => {
        return (
          <Select
            fullWidth
            size="small"
            name="fetTpName"
            value={row.fetTpId == null ? "" : row.fetTpId}
            onChange={(e) => handleChange(e, row)}>
            {metadata == undefined ? (
              <MenuItem value="" key=""></MenuItem>
            ) : (
              Object.keys(metadata).length > 0 &&
              metadata?.fetTreatmentPlans.map((item, index) => {
                return (
                  <MenuItem value={item.treatmentPlanId} key={index}>
                    {item.treatmentPlanName}
                  </MenuItem>
                );
              })
            )}
          </Select>
        );
      }
    },
    {
      field: "fetCycles",
      headerName: "FET NO.OF CYCLES",
      editable: false,
      width: 100,
      renderCell: ({ row }) => {
        return (
          <TextField
            size="small"
            name="fetCycles"
            value={row.fetCycles}
            onChange={(e) => handleChange(e, row)}
          />
        );
      }
    },
    {
      field: "prScale",
      headerName: "PROVIDER REIMBURSEMENT SCALE",
      editable: true,
      width: 200,
      renderCell: ({ row }) => {
        return (
          <Select
            fullWidth
            name="prScaleDescription"
            value={row.prScaleId == null ? "" : row.prScaleId}
            onChange={(e) => handleChange(e, row)}
            className="stage-practice-dropdown">
            {metadata == undefined ? (
              <MenuItem value="" key=""></MenuItem>
            ) : (
              Object.keys(metadata).length > 0 &&
              metadata?.prScales.map((item, index) => {
                return (
                  <MenuItem value={item.pr_id} key={index}>
                    {item.description}
                  </MenuItem>
                );
              })
            )}
          </Select>
        );
      }
    }
  ];

  const rows =
    values.length > 0 &&
    values?.map((item, index) => {
      item.effectiveDate = new Date(item.effectiveDate).toLocaleDateString();
      item.programPriceGlobal = item.multiplier * item.globalPrice;
      item.programPriceAssist = item.multiplier * item.assistPrice;
      item.id = index;
      return item;
    });

  useEffect(() => {
    setValues([...locationProgramDefList]);
  }, [locationProgramDefList]);

  const handleChange = (e, row) => {
    const updatedLocationPrograms = locationProgramDefList.map((item) => {
      if (item.pmId == row.pmId) {
        if (e.target.name == "fetCycles") {
          item[e.target.name] = e.target.value;
        }
        if (e.target.name == "fetTpName") {
          item["fetTpId"] = e.target.value;
        }
        if (e.target.name == "prScaleDescription") {
          item["prScaleId"] = e.target.value;
        }
        item.updated = true;
      }
      return item;
    });
    setValues(updatedLocationPrograms);
  };

  const initFetch = useCallback(() => {
    actions.getMetadata({ gdmId: gdmId, practiceId: practiceId });
    actions.getLocationProgramDef({ gdmId: gdmId, practiceId: practiceId });
  }, [addSuccess]);

  useEffect(() => {
    if (addSuccess || error) {
      setIsMessageModal(true);
    }
    if (locationProgramDefList.length === 0 || addSuccess) {
      initFetch();
    }
    return () => {
      actions.resetLocationProgramDef();
    };
  }, [error, addSuccess]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const handleSave = () => {
    let data = values?.filter((val) => val.updated == true);
    actions.updatedLocationPrograms({ gdmId: gdmId, practiceId: practiceId, data: data });
  };

  return (
    <>
      <Box sx={{ ml: 1 }} className={`${loading ? "m-r-8 disable-section" : "m-r-8"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>Location Program Definition</Typography>
          <Button
            variant="contained"
            className="btn-usf"
            onClick={handleSave}
            disabled={status === "In-progress" ? false : true}>
            Save
          </Button>
        </Stack>

        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows.length > 0 ? rows : []}
            rowCount={rows?.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            //sx={{ overflowX: "scroll" }}
          />
        </Box>
      </Box>

      <MessageModal
        open={isMessageModal}
        message={addSuccess}
        error={error}
        closeModal={closeMessageModal}
      />
    </>
  );
};

const mapStateToProps = ({ locationProgram }) => {
  return {
    locationProgramDefList: locationProgram.locationProgramList,
    loading: locationProgram.loading,
    error: locationProgram.error,
    addSuccess: locationProgram.success,
    metadata: locationProgram.metadata
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getLocationProgramDef: (payload) => {
      dispatch(actionType.getLocationProgramDef(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getMetadata: (payload) => {
      dispatch(actionType.getLPDMetadata(payload));
    },
    updatedLocationPrograms: (payload) => {
      dispatch(actionType.updateLocationProgramList(payload));
    },
    resetLocationProgramDef: () => {
      dispatch(actionType.resetLocationProgramDef());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationProgramDef);
