import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchContactList(action) {
  let payload = action.payload;
  try {
    const params = {
      offset: payload.offset || 0,
      limit: payload.limit || 10000
    };
    const requestBody = createRequestBody("get", `/practices/${payload}/contacts`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.CONTACT_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.CONTACT_LIST_FAILED,
        message: error.message
      });
    }
  }
}
function* getContactSaga() {
  yield takeEvery(actionTypes.CONTACT_LIST_REQUESTED, fetchContactList);
}
function* addContact(action) {
  const { payload } = action;

  const { practiceId } = payload;
  const body = {
    emails: payload.emails,
    phoneNumbers: payload.phoneNumbers,
    firstName: payload.firstName,
    lastName: payload.lastName,
    showInMedcase: payload.showInMedcase,
    status: payload.status,
    type: payload.type
  };
  try {
    const requestBody = createRequestBody("post", `/practices/${practiceId}/contacts`, "", body);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_CONTACT_LIST_SUCCESS,
      payload: response.data,
      success: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ADD_CONTACT_LIST_FAILED,
        message: error.response.data
      });
    }
  }
}
function* addContactSaga() {
  yield takeEvery(actionTypes.ADD_CONTACT_LIST_REQUESTED, addContact);
}
function* editContact(action) {
  const { payload } = action;
  const { practiceId, contactId } = payload;
  const body = {
    emails: payload.emails,
    phoneNumbers: payload.phoneNumbers,
    firstName: payload.firstName,
    lastName: payload.lastName,
    showInMedcase: payload.showInMedcase,
    status: payload.status,
    type: payload.type
  };
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/contacts/${contactId}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_CONTACT_LIST_SUCCESS,
      payload: response.data,
      success: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.EDIT_CONTACT_LIST_FAILED,
        message: error.message
      });
    }
  }
}
function* editContactSaga() {
  yield takeEvery(actionTypes.EDIT_CONTACT_LIST_REQUESTED, editContact);
}

export default function* ContactSaga() {
  yield all([getContactSaga(), addContactSaga(), editContactSaga()]);
}
