const groupname_grouprights = [
  {
    group_name_display: "Felix",
    group_name_attr: "felix",
    group_rights: {
      pt: "Felix - Patients",
      fe: "Felix - Workspace - Financial estimates",
      ftacpg: "Felix - Workspace - Fertility access program",
      spt: "Felix - Utility - Search patient",
      me: "Felix - Utility - Messages",
      pf: "Felix - Utility - Profile",
      nf: "Felix - Utility - Notification"
    }
  },
  {
    group_name_display: "Felix (light)",
    group_name_attr: "felix_light",
    group_rights: {
      pt: "Felix - Patients",
      adpt: "Add Patient manually",
      fe: "Felix - Workspace - Financial estimates",
      ftacpg: "Felix - Workspace - Fertility access program",
      spt: "Felix - Utility - Search patient",
      me: "Felix - Utility - Messages",
      pf: "Felix - Utility - Profile",
      nf: "Felix - Utility - Notification"
    }
  },
  {
    group_name_display: "Admin portal (Felix)",
    group_name_attr: "adm_p_felix",
    group_rights: {
      ptpla: "Patient portal admin",
      //rtimpt: "Right to impersonate the patient portal of selected patient",
      sfa: "Staff admin"
      //dclb: "Document Library"
    }
  },
  {
    group_name_display: "Admin portal (Practice Configuration)",
    group_name_attr: "adm_p_pr_cfg",
    group_rights: {
      prpm: "Practice payment",
      pr: "Practice",
      gdm: "Global Data Model",
      pgv: "Program Visualization",
      rfagc: "Refund age configuration",
      phrmsc: "Physician Reimbursement scale",
      rlvltl: "Relative value table",
      pgtalc: "PGTA analysis configuration",
      contract: "Contract Template Mapping"
    }
  },
  {
    group_name_display: "Trak",
    group_name_attr: "trak",
    group_rights: {
      cl: "Case lookup",
      ct: "Contract",
      rfap: "Refund application",
      pvrm: "Physician Reimbursement",
      rn: "Revenue",
      rf: "Refund",
      rp: "Report"
    }
  },
  {
    group_name_display: "Patient Portal",
    group_name_attr: "patient_p",
    group_rights: {
      srgptp: "Self registration for patient portal",
      fe: "Financial Estimate",
      pyb: "Pay your bill",
      dc: "Documents",
      me: "Messages",
      pf: "Profile"
    }
  },
  {
    group_name_display: "Users",
    group_name_attr: "users",
    group_rights: {
      ucron: "User creation and onboarding"
    }
  }
];
export default groupname_grouprights;
