import * as actionType from "../actions/ActionTypes";

const initialState = {
  trakFinancialEstimateList: [],
  loading: false,
  error: null
};

const trakFinancialEstimateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TRAK_FINANCIAL_ESTIMATE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.TRAK_FINANCIAL_ESTIMATE_SUCCESS: {
      return {
        ...state,
        trakFinancialEstimateList: action.payload,
        loading: false
      };
    }
    case actionType.TRAK_FINANCIAL_ESTIMATE_FAILURE: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_TRAK_FINANCIAL_ESTIMATE_LIST: {
      return {
        ...state,
        trakFinancialEstimateList: []
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default trakFinancialEstimateReducer;
