import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
// import * as token from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* getPatientHeader(action) {
  //api call
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/patientinfoheader`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.PATIENT_DETAILS_HEADER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PATIENT_DETAILS_HEADER_FAILED, errorMsg: error.response.data });
    }
  }
}

function* getMedcaseHeader(action) {
  //api call
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/medcaseDetailsHeader`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.MEDCASE_DETAILS_HEADER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.MEDCASE_DETAILS_HEADER_FAILED, errorMsg: error.response.data });
    }
  }
}

function* getPatientHeaderSaga() {
  yield takeEvery(actionTypes.PATIENT_DETAILS_HEADER_REQUESTED, getPatientHeader);
}

function* getMedcaseHeaderSaga() {
  yield takeEvery(actionTypes.MEDCASE_DETAILS_HEADER_REQUESTED, getMedcaseHeader);
}

export default function* medcaseHeaderSaga() {
  yield all([getPatientHeaderSaga(), getMedcaseHeaderSaga()]);
}
