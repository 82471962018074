import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import Breadcrumb from "../shared/components/Breadcrumb";
import PatientCard from "./components/PatientCard";
import PatientConfiguration from "./components/PatientConfiguration";
import "./components/Patients.scss";
import RightSideBar from "./components/RightSideBar";
import { useSelector } from "react-redux";
const Patients = () => {
  const getProfileDetails = useSelector((state) => state.profileDrawerReducer.profileDetails);
  //localStorage.setItem("practiceId", "FC-68");
  const { personId } = useParams();
  const [setPracticeData] = useState();
  const [patientDetails, setPatientData] = useState();
  const getSelectedConfig = (selected) => {
    setPracticeData(selected);
  };
  const getPatientDetails = (pData) => {
    setPatientData(pData);
  };

  return (
    <Box className="container">
      <Grid container>
        <Grid item xs={12} className="pad-10">
          <Stack direction="row" className="align-center">
            <Typography className="txt-capitalize">
              {getProfileDetails.firstName}&nbsp;
              {getProfileDetails.lastName}
              <br />
              <span>({getProfileDetails.primaryRole})</span>
            </Typography>
            <Divider sx={{ height: 26, m: "0px 10px" }} orientation="vertical" />
            <Breadcrumb />
          </Stack>
        </Grid>
        {/* below grid is for patient card */}
        {personId && (
          <Grid item xs={12} className="patient-card-details pad-l-15">
            <PatientCard getPatientData={getPatientDetails} />
          </Grid>
        )}
        {/* below grid is for patient configuration */}
        <Grid container>
          {personId && (
            <Grid item xs={2} sx={{ background: "#1BA3AD", minHeight: "110vh" }}>
              <PatientConfiguration getSelectedConfig={getSelectedConfig} id={personId} />
            </Grid>
          )}
          <Grid className="pad-r-10" item xs={personId ? 7 : 12}>
            <Outlet />
          </Grid>
          {personId && (
            <Grid className="pad-l-10 vertical-divider" item xs={3}>
              <RightSideBar patientDetails={patientDetails} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
export default Patients;
