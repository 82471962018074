import { Fragment } from "react";
import { Box } from "@mui/material";

const mapPropsToConfig = (config) => {
  const configWithProps = [];

  if (config.modalHeader) {
    const { component, ...props } = config.modalHeader;
    configWithProps.push({
      ...props,
      Component: component
    });
  }
  if (config.modalForm) {
    config.modalForm.forEach((item) => {
      const { component, ...props } = item;
      configWithProps.push({
        ...props,
        Component: component
      });
    });
  }
  if (config.modalActions) {
    config.modalActions.forEach((item) => {
      const { component, ...props } = item;
      configWithProps.push({
        ...props,
        Component: component
      });
    });
  }

  return configWithProps;
};
export const RenderChild = ({ config }) => {
  const configWithProps = mapPropsToConfig(config);
  const renderComponents = (items) => {
    return items.map((item) => {
      const { Component, ...props } = item;

      return (
        <Fragment key={props.key}>
          <Component {...props} />
          {(props && props.warningMessage && props.warningMessage !== "" && (
            <Box className="alert" style={{ marginTop: 2 }}>
              {props.warningMessage}
            </Box>
          )) ||
            ""}
        </Fragment>
      );
    });
  };

  return renderComponents(configWithProps);
};
