import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchStaffAdminUserDetails(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const url = `/users/${payload.cognitoId}`;
    const requestBody = createRequestBody("get", url, params, payload);
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.STAFF_ADMIN_USER_DETAILS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.STAFF_ADMIN_USER_DETAILS_FAIL, errorMsg: error.message });
    }
  }
}

function* getStaffAdminUserSaga() {
  yield takeEvery(actionTypes.STAFF_ADMIN_USER_DETAILS_REQUESTED, fetchStaffAdminUserDetails);
}

export default function* staffAdminUserSaga() {
  yield all([getStaffAdminUserSaga()]);
}
