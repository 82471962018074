import * as actionType from "../actions/ActionTypes";

const initialState = {
  stageList: [],
  stageListNotMapped: [],
  addStage: null,
  editStage: null,
  loading: false,
  error: null,
  success: null
};

const stageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.STAGE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }

    case actionType.STAGE_LIST_SUCCESS: {
      return {
        ...state,
        stageList: action.payload,
        loading: false
      };
    }
    case actionType.STAGE_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.STAGE_LIST_REQUESTED_NOT_MAPPED: {
      return {
        ...state,
        loading: true
      };
    }

    case actionType.STAGE_LIST_NOT_MAPPED_SUCCESS: {
      return {
        ...state,
        stageListNotMapped: action.payload,
        loading: false
      };
    }

    case actionType.STAGE_LIST_NOT_MAPPED_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.ADD_NEW_STAGE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_STAGE_SUCCESS: {
      return {
        ...state,
        addStage: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_STAGE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_STAGE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_STAGE_SUCCESS: {
      return {
        ...state,
        editStage: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_STAGE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_STAGE_LIST: {
      return {
        ...state,
        addStage: null,
        editStage: null,
        error: null,
        loading: null
      };
    }
    case actionType.RESET_STAGES: {
      return {
        ...state,
        stageList: [],
        loading: null
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};
export default stageReducer;
