import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { programCyclesColumns } from "../constants";
import { connect } from "react-redux";
import * as actionType from "../actions";
import TrakOutcomeFormModal from "./modals/TrakOutcomeFormModal";
import ProgramCycleModal from "./modals/ProgramCycleModal";
import { useParams } from "react-router-dom";
import WarnningMessageModal from "../../shared/components/WarnningMessageModal";
import moment from "moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const ProgramCycles = (props) => {
  // const serachParm = new URLSearchParams(window.location.search);
  // const Urlterm = serachParm.get("cycle");
  const [urlTerm] = useState(new URLSearchParams(window.location.search).get("cycle"));

  const { id } = useParams();
  const {
    actions,
    programCycle,
    loading,
    medcaseId,
    clinicalTreatmentNo,
    medcaseDetails,
    medcaseType,
    successEnroll
  } = props;
  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isManual, setIsManual] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpenTrack, setIsOpenTrack] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [rowEvent, setRowEvent] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedPRId, setSelectedPRId] = useState(0);
  const [selectedPR, setSelectedPR] = useState({});
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (successEnroll) {
      actions.getProgramCycle({ medcaseId: medcaseId });
      let payload = { medcaseId: medcaseId, clinicalTreatmentNo: "1.0" };
      actions.createProgramCycle(payload);
      actions.getMedcaseDetailsHeader({ medcaseId: id });
    }
  }, [successEnroll]);

  useEffect(() => {
    const rows =
      programCycle &&
      programCycle.map((item, index) => {
        item.outcomeDate = item.outcomeDate ? moment(item.outcomeDate).format("L") : "";
        item.transferDate = item.transferDate ? moment(item.transferDate).format("L") : "";
        item.misscarriageDate = item.misscarriageDate
          ? moment(item.misscarriageDate).format("L")
          : "";
        item.authorizedDate = item.authorizedDate ? moment(item.authorizedDate).format("L") : "";
        item.requestedDate = item.requestedDate ? moment(item.requestedDate).format("L") : "";
        item.id = index;
        return item;
      });

    setRows(rows);
  }, [programCycle]);

  useEffect(() => {
    if (urlTerm && urlTerm !== "") {
      if (rows && rows.length > 0) {
        let cycleData =
          rows &&
          rows.filter((item) => {
            return item.clinicalTreatmentNo == urlTerm;
          });
        if (cycleData && cycleData.length > 0) {
          setSelectedData(cycleData[0]);
          setIsOpenTrack(true);
        }
      }

      // Cleanup function to clear the timeout if the component unmounts
      //return () => clearTimeout(timeoutId);
    }
  }, [urlTerm, rows]);

  const closeWarningModal = () => {
    setIsWarningOpen(false);
    setWarningMessage("");
  };
  const handleClose = () => {
    setOpen(false);
  };

  const createProgramCycle = () => {
    setOpen(false);
    let payload = { medcaseId: medcaseId, clinicalTreatmentNo: "1.0" };
    actions.createProgramCycle(payload);
  };

  const handleProgramClick = (e, data) => {
    setSelectedData(data);
    setIsOpenTrack(true);
  };
  const handleProgramCycle = () => {
    if (rowEvent === true) {
      // if (
      //   selectedPR &&
      //   selectedPR.outcome &&
      //   !selectedPR.outcome.toString().toLowerCase().includes("no status")
      // ) {
      setIsManual(true);
      setOpen(true);
      // }
      // else {
      //   setIsWarningOpen(true);
      //   setWarningMessage("Current cycle must have outcome to authorize a new cycle!");
      // }
    } else {
      setIsWarningOpen(true);
      setWarningMessage("Cycle must be selected");
    }
  };

  const disablePR = () => {
    let outcomeStatus =
      (programCycle && programCycle.length > 0 && programCycle[0].outcome) || null;
    if (
      (medcaseDetails?.medcaseStatus === "Enrolled" ||
        medcaseDetails?.medcaseStatus === "Re-Enrolled") &&
      outcomeStatus != null
    ) {
      return false;
    } else {
      return true;
    }
  };
  const handleChange = () => {
    if (expanded === true) {
      setExpanded(false);
    }
    if (expanded === false) {
      setExpanded(true);
    }
  };
  const updateSelectedPR = ({ row }) => {
    setSelectedPRId(row.medcaseId);
    setSelectedPR(row);
  };
  return (
    <>
      <Box className="m-8">
        <Accordion
          key="programCycles"
          onChange={handleChange}
          expanded={medcaseType != "PGTA" && expanded}
          defaultExpanded={false}
          disabled={medcaseType == "PGTA"}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              flexDirection: "row-reverse"
            }}>
            <Stack direction="row" className="grid-header">
              <Typography>Program Cycles</Typography>
              {console.log("disablePR", disablePR())}
              <Button
                variant="contained"
                className="btn-primary tab-panel-btnn"
                onClick={handleProgramCycle}
                //disabled={disablePR()}
              >
                New PR
              </Button>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%"
              // minHeight: rows ? (rows.length > 2 ? "650pX" : "650px") : "650px"
            }}>
            <DataGridPremiumComp
              rows={
                medcaseType == "PGTA"
                  ? []
                  : rows.filter((row) => row.authorizedDate && row.authorizedDate !== "")
              }
              columns={programCyclesColumns(handleProgramClick)}
              loading={loading}
              pageSizeOptions={[10, 25, 50, 100]}
              onRowClick={() => {
                setRowEvent(true);
                setIsWarningOpen(false);
              }}
              onCellClick={(e) => {
                updateSelectedPR(e);
              }}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
              getRowClassName={(params) => {
                return params.row.isClinicalReview ? "imp-trans" : "";
              }}
              autoHeight={true}
            />
            {open ? (
              <ProgramCycleModal
                open={open}
                handleClose={handleClose}
                isManual={isManual}
                loading={loading}
                createMedCase={createProgramCycle}
                clinicalTreatmentNo={clinicalTreatmentNo}
                medcaseId={medcaseId}
                selectedPRId={selectedPRId}
                selectedPR={selectedPR}
                medcaseDetails={medcaseDetails}
              />
            ) : (
              ""
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {isOpenTrack && (
        <TrakOutcomeFormModal
          isOpenTrack={isOpenTrack}
          rowData={selectedData}
          medcaseId={props.medcaseId}
          close={() => {
            setIsOpenTrack(false);
            // actions.getProgramCycle({ medcaseId: medcaseId });
          }}
          practiceId={medcaseDetails.practiceId}
          gdmId={props.newPrList.gdmId}
          cycleList={
            medcaseType == "PGTA"
              ? []
              : rows.filter((row) => row.authorizedDate && row.authorizedDate !== "")
          }
        />
      )}

      <WarnningMessageModal
        open={isWarningOpen}
        message={warningMessage}
        closeModal={closeWarningModal}
      />
    </>
  );
};

const mapStateToProps = ({
  programCycleReducer,
  medcaseHeaderReducer,
  newPrReducer,
  enrollReducer
}) => {
  return {
    programCycle: programCycleReducer.programCycle,
    newPrList: newPrReducer.newPrList,
    medcaseDetails: medcaseHeaderReducer.medcaseDetails,
    errorMsg: programCycleReducer.error,
    successMsg: programCycleReducer.success,
    loading: programCycleReducer.loading,
    successEnroll: enrollReducer.success
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getProgramCycle: (payload) => {
      dispatch(actionType.getProgramCycle(payload));
    },
    createProgramCycle: (payload) => {
      dispatch(actionType.createProgramCycle(payload));
    },
    resetProgram: () => {
      dispatch(actionType.resetProgram());
    },
    getMedcaseDetailsHeader: (payload) => {
      dispatch(actionType.getMedcaseDetailsHeader(payload));
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connect(mapStateToProps, mapDispatchToProps)(ProgramCycles));
