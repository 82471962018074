import React, { useState } from "react";
import { Box, Stack, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CaseList from "./CaseList";
import Medcase from "./Medcase";
import isAccess from "../../shared/components/CheckUserRights";
import LoadingModal from "../../trak/components/modals/loadingModal";
import { useSelector } from "react-redux";
const CaseLookup = () => {
  const [tabValue, setTabValue] = useState("1");
  const getProfileDetailsLoading = useSelector((state) => state.profileDrawerReducer.loading);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  return (
    <>
      {(isAccess("cl") || isAccess("ucron")) && (
        <Box flexDirection="row" sx={{ m: 1 }}>
          <TabContext value={tabValue}>
            <Box>
              <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                  <Tab label="Case List" value="1" />
                  <Tab label="New Medcase" value="2" />
                </TabList>
              </Stack>
            </Box>
            <TabPanel value="1">
              <CaseList />
            </TabPanel>
            <TabPanel value="2">
              <Medcase />
            </TabPanel>
          </TabContext>
        </Box>
      )}
      <LoadingModal open={getProfileDetailsLoading} />
    </>
  );
};

export default CaseLookup;
