import React from "react";
import Checkbox from "@mui/material/Checkbox";
const UserRightsTable = (props) => {
  const { tableHeader, selectedValue, data, handleTableCheckBox } = props;
  const [tableData, setTableData] = React.useState([]);
  React.useEffect(() => {
    if (selectedValue !== "" && selectedValue !== undefined && selectedValue.length > 0) {
      let tempData = data[`${selectedValue[0]["value"]}`]["groupname_grouprights"];
      setTableData(tempData);
    } else {
      setTableData([]);
    }
  }, [selectedValue, data]);
  return (
    <>
      <table className="customTable">
        <tr id="header">
          {tableHeader.map((i, index) => {
            return <th key={index}>{i.lable}</th>;
          })}
        </tr>

        {tableData.length !== 0 ? (
          tableData.map((tableItem) => {
            return (
              <>
                <tr id="row1">
                  <td style={{ width: "25%" }}>{tableItem.group_name_display}</td>
                  <td style={{ width: "55%" }}>
                    {Object.values(tableItem.group_rights).map((subList) => {
                      return (
                        <>
                          <div className={"custom-table-color"} style={{ height: "42px" }}>
                            <label>{subList.label}</label>
                          </div>
                        </>
                      );
                    })}
                  </td>
                  <td style={{ width: "10%" }}>
                    {Object.values(tableItem.group_rights).map((subList) => {
                      return (
                        <>
                          <div className={"custom-table-color"} style={{ paddingLeft: "60px" }}>
                            <Checkbox
                              id={subList.value}
                              name={subList.label}
                              value={subList.value}
                              checked={subList.checked}
                              // onChange={(e) => handleChecks(e, tableItem.group_name_display)}
                              onChange={(e) => handleTableCheckBox(e, tableItem.group_name_attr)}
                            />
                            {/* <label>{subList.label}</label> */}
                          </div>
                        </>
                      );
                    })}
                  </td>
                </tr>
              </>
            );
          })
        ) : (
          <>
            <tr id="row1">
              <td>No Data</td>
              <td>No Data</td>
            </tr>
          </>
        )}
      </table>
    </>
  );
};
export default UserRightsTable;
