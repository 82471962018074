import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Link,
  Typography
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as actionType from "../actions";
import * as actionTypeEstimate from "../../workspace/actions";
import { calculateAge, getGengerFullName } from "../../shared/constants/constants";

const PatientDemoGraphic = (props) => {
  const { patientData, partnerData, taskList, close, actions, taskLoading } = props;
  const [value, setValue] = useState("2");
  const navigate = useNavigate();
  const practiceId = localStorage.getItem("practiceId");

  const handleClose = () => {
    close(0);
  };

  useEffect(() => {
    actions.getTaskData();
    if (patientData.activePartner != null) {
      let payload = {
        practiceId,
        personId: patientData.activePartner.partnerId
      };
      actions.getPartnerData(payload);
    }
  }, []);

  useEffect(() => {
    if (taskList.length) {
      actions.getCheckedTaskData(patientData.personId);
    }
  }, [taskList.length]);

  const handleChangeTask = (e, item) => {
    let payload = {
      patientTaskMappingId: patientData.personKey,
      personId: patientData.personId,
      patientTaskId: item.patientTaskId,
      isChecked: e.target.checked
    };
    actions.UpdateCheckedTaskData(payload);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ mx: 1 }}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <Box className="mt-17">
          <IconButton onClick={handleClose}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Box>
      </Stack>
      <Box sx={{ p: 1, background: "#EFEFEF" }}>
        <Stack direction="row" gap={2} flex={1}>
          <img alt="" src="/Assets/user_icon.png" />
          <Stack direction="column">
            <Stack direction="row" gap={1}>
              <Link color="primary">
                {patientData.firstName} {patientData.lastName}
              </Link>
            </Stack>
            <Stack direction="row" gap={1}>
              <Typography>ID #</Typography>
              <Typography>{patientData.personKey}</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Grid container>
          <Grid item xs={6}>
            <InputLabel>Sex | Age(DOB)</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Typography>
                {getGengerFullName(patientData.gender)} | {calculateAge(patientData.birthDate)}
              </Typography>
              <Typography>{patientData.birthDate}</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid container py={1}>
          <Grid item xs={6}>
            <InputLabel>
              Partner ({partnerData.gender ? getGengerFullName(partnerData.gender) : "-"})
            </InputLabel>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {partnerData.firstName} {partnerData.lastName}
            </Typography>
            <Typography>ID# {partnerData.personKey}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={6}>
            <InputLabel>Pref Phone #</InputLabel>
          </Grid>
          <Grid item xs={6}>
            <Typography>{patientData.cellPhone}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Box flexDirection="row">
        <TabContext value={value}>
          <Box>
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <TabList aria-label="lab API tabs example" onChange={handleChange}>
                <Tab label="TASK" value="1" />
                <Tab label="ACTION" value="2" />
              </TabList>
            </Stack>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            <Box sx={{ ml: 1 }}>
              <Stack flexDirection="column">
                {taskLoading ? (
                  <Stack className="align-center" m={1}>
                    <CircularProgress />
                  </Stack>
                ) : (
                  taskList.map((item, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        onChange={(e) => {
                          e.preventDefault();
                          handleChangeTask(e, item);
                        }}
                        control={<Checkbox />}
                        label={item.description}
                      />
                    );
                  })
                )}
              </Stack>
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            <Box sx={{ m: 1 }}>
              <Stack flexDirection="column" gap={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    localStorage.setItem(
                      "patientName",
                      patientData.firstName + " " + patientData.lastName
                    );
                    actions.resetEstimateViewList();
                    navigate(`/workspace/financial-estimates/${patientData.personId}?PT=PD`);
                    //     navigate("/workspace/financial-estimates");
                  }}>
                  Create a Financial Estimates
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/workspace/fertility-access-program");
                  }}>
                  Fertility Access Program
                </Button>
              </Stack>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

const mapStateToProps = ({ patientReducer }) => {
  return {
    partnerData: patientReducer.partnerData,
    taskList: patientReducer.taskList,
    taskLoading: patientReducer.loading
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPartnerData: (practiceId) => {
      dispatch(actionType.getPartnerData(practiceId));
    },
    getTaskData: () => {
      dispatch(actionType.getTaskData());
    },
    getCheckedTaskData: (personId) => {
      dispatch(actionType.getCheckedTaskData(personId));
    },
    UpdateCheckedTaskData: (payload) => {
      dispatch(actionType.UpdateCheckedTaskData(payload));
    },
    resetEstimateViewList: () => {
      dispatch(actionTypeEstimate.resetEstimateViewList());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PatientDemoGraphic);
