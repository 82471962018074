import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import * as actionType from "../actions";
import CommonModal from "../../shared/components/CommonModal";
import { connect } from "react-redux";
import { externalIdConfig } from "../../shared/constants/renderConfigs";
import MessageModal from "../../shared/components/MessageModal";
import moment from "moment/moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
function ExternalId(props) {
  const columns = [
    {
      field: "externalSystem",
      headerName: "EXTERNAL SYSTEM",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "externalPracticeId",
      headerName: "EXTERNAL PRACTICE ID",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "externalLocationId",
      headerName: "EXTERNAL LOCATION ID",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 1,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            handleEditExternalId(row);
          }}>
          <EditIcon></EditIcon>
        </IconButton>
      )
    }
  ];

  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isDefaultPracticeId, setIsDefaultPracticeId] = useState(false);
  const {
    externalIdList = [],
    actions,
    locationId,
    loading,
    addExternalDetails,
    editExternalDetails,
    practiceId,
    success,
    error,
    externalSystemPracticeId = ""
  } = props;
  let externalIdFormData = {
    externalLocationId: "",
    externalPracticeId: "",
    practiceId: practiceId,
    externalSystem: "",
    locationId: locationId
  };
  const [values, setValues] = useState(externalIdFormData);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  const closeModal = () => {
    setValues(externalIdFormData);
    setIsOpen(false);
  };
  const addNewExternalId = () => {
    setValues(externalIdFormData);
    setIsAdd(true);
    setIsOpen(true);
    actions.resetExternalSystemDetail("");
  };
  const config = externalIdConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.externalSystem ? false : true,
    isDefaultPracticeId
  });
  useEffect(() => {
    actions.getExternalIdList({ locationId: locationId, practiceId: practiceId });
  }, []);
  useEffect(() => {
    if (success || error) {
      setIsMessageModal(true);
    }
    actions.getExternalIdList({ locationId: locationId, practiceId: practiceId });
  }, [addExternalDetails, editExternalDetails, error, success]);

  useEffect(() => {
    if (values.externalSystem)
      actions.getExternalSystemDetail({
        locationId: locationId,
        practiceId: practiceId,
        externalSystemId: values.externalSystem
      });
  }, [values.externalSystem]);

  useEffect(() => {
    setValues({
      ...values,
      externalPracticeId: externalSystemPracticeId || ""
    });

    setIsDefaultPracticeId(values.externalSystem && externalSystemPracticeId ? true : false);
  }, [externalSystemPracticeId]);

  const rows = externalIdList.map((item, index) => {
    item.id = index;
    return item;
  });

  const handleEditExternalId = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    externalIdFormData = {
      externalSystem: row.externalSystem,
      externalPracticeId: row.externalPracticeId,
      externalLocationId: row.externalLocationId,
      practiceId: practiceId,
      locationId: locationId
    };
    setValues(externalIdFormData);
  };
  const saveExternalId = (event) => {
    event.preventDefault();
    let payload = {};
    if (isAdd) {
      payload.locationId = locationId;
      payload.externalDetails = values;
      actions.addNewExternalDetails(payload);
    } else {
      payload.locationId = locationId;
      payload.externalDetails = values;
      actions.editExternalId(payload);
    }
    closeModal();
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetExternalIdList();
  };

  return (
    <>
      <Box>
        <Button
          variant="contained"
          className="btn-primary tab-panel-btn"
          onClick={addNewExternalId}>
          New External ID
        </Button>
      </Box>
      <Box className="grid-style" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
        />
        <CommonModal open={isOpen} config={config} isAdd={isAdd} handleSubmit={saveExternalId} />
        <MessageModal
          open={isMessageModal}
          message={success}
          error={error}
          closeModal={closeMessageModal}
        />
      </Box>
    </>
  );
}

const mapStateToProps = ({ externalDetailsReducer }) => {
  return {
    externalIdList: externalDetailsReducer.externalIdsList,
    loading: externalDetailsReducer.loading,
    addExternalDetails: externalDetailsReducer.addExternalDetails,
    editExternalDetails: externalDetailsReducer.editExternalDetails,
    externalSystemPracticeId: externalDetailsReducer.externalSystemPracticeId,
    success: externalDetailsReducer.success,
    error: externalDetailsReducer.error
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getExternalIdList: (payload) => {
      dispatch(actionType.getExternalIdList(payload));
    },
    getExternalSystemDetail: (payload) => {
      dispatch(actionType.getExternalSystemDetail(payload));
    },
    addNewExternalDetails: (payload) => {
      dispatch(actionType.addExternalId(payload));
    },
    editExternalId: (payload) => {
      dispatch(actionType.editExternalId(payload));
    },
    resetExternalIdList: () => {
      dispatch(actionType.resetExternalIdList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    resetExternalSystemDetail: (payload) => {
      dispatch(actionType.resetExternalSystemDetail(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ExternalId);
