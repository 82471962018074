import React, { useCallback, useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Button, Typography, Stack } from "@mui/material";
import * as actionType from "../actions";
import { connect, useSelector } from "react-redux";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { Link, useNavigate } from "react-router-dom";
import { treatmentPlanConfig } from "../../shared/constants/renderConfigs";
import DeleteModal from "../../shared/components/DeleteModal";
import moment from "moment";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const TreatmentPlan = (props) => {
  let treatmentPlanFormData = {
    treatmentPlan: ""
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const gdmId = sessionStorage.getItem("gdmId");
  const practiceId = sessionStorage.getItem("practiceId");
  const [isMessageModal, setIsMessageModal] = useState(false);
  //const gdmRowData = useSelector((state) => state.globalDataModelReducer.gdmRowData);
  const [values, setValues] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState({});
  const disabled = localStorage.getItem("status") !== "In-progress" ? true : false;
  const globalData = useSelector((state) => state.globalDataModelReducer.globalData);
  const columns = [
    {
      field: "treatmentPlanId",
      headerName: "TREATMENT PLAN ID",
      width: 50,
      editable: true,
      flex: 0.5,
      renderHeader: () => (
        <span style={{ lineHeight: 1, fontSize: 12 }}>
          TREATMENT
          <br />
          PLAN ID
        </span>
      )
    },
    {
      field: "treatmentPlanName",
      headerName: "TREATMENT PLAN",
      width: 100,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Link to={row.treatmentPlanId} state={{ saved: true }}>
            {row.treatmentPlanName}
          </Link>
        );
      }
    },
    {
      field: "treatmentPlanInternalName",
      headerName: "TREATMENT PLAN INTERNAL NAME",
      width: 100,
      editable: false,
      flex: 1.5,
      renderHeader: () => (
        <span style={{ lineHeight: 1, fontSize: 12 }}>
          TREATMENT PLAN
          <br />
          INTERNAL NAME
        </span>
      )
    },
    {
      field: "globalPrice",
      headerName: "GLOBAL PRICE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>$ {row.globalPrice}</div>;
      }
    },
    {
      field: "assistPrice",
      headerName: "ASSIST PRICE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>$ {Math.round(row.assistPrice)}</div>;
      }
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      flex: 0.5
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 0.6,
      filterable: false,
      renderCell: ({ row }) => (
        <>
          <IconButton
            disabled={disabled}
            onClick={() => {
              handleEditTreatmentPlan(row);
            }}>
            <EditIcon color={disabled ? "disabled" : "primary"}></EditIcon>
          </IconButton>
          <IconButton
            disabled={disabled}
            onClick={() => {
              setDeleteModal(true);
              setSelectedTreatmentId({
                treatmentPlanId: row.treatmentPlanId,
                treatmentPlanName: row.treatmentPlanName
              });
            }}>
            <DeleteIcon color={disabled ? "disabled" : "primary"}></DeleteIcon>
          </IconButton>
        </>
      )
    }
  ];
  const {
    treatmentPlan = [],
    addTreatmentPlan,
    editTreatmentPlan,
    loading,
    actions,
    errorMsg,
    successMsg
  } = props;
  const navigate = useNavigate();
  const rows = treatmentPlan.map((item, index) => {
    item.effectiveDate = moment(item.effectiveDate).format("L");
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    const payload = { practiceId: practiceId, gdmId: gdmId };
    actions.getTreatmentPlan(payload);
  }, [addTreatmentPlan, editTreatmentPlan]);
  useEffect(() => {
    treatmentPlan.map((item) => {
      item.effectiveDate = moment(item.effectiveDate).format("L");
      return item;
    });
  }, [treatmentPlan]);
  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (treatmentPlan.length == 0 || addTreatmentPlan || editTreatmentPlan) {
      initFetch();
    }
    return () => {
      actions.resetTreatmentPlanList();
    };
  }, [addTreatmentPlan, editTreatmentPlan, errorMsg, successMsg]);

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
  };
  // const handleEffectiveDateChange = (e) => {
  //   setValues({
  //     ...values,
  //     ["effectiveDate"]: e
  //   });
  // };
  const addNewTreatmentPlan = () => {
    treatmentPlanFormData = { practiceId: practiceId, gdmId: gdmId };
    setValues(treatmentPlanFormData);
    setIsOpen(true);
    setIsAdd(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    actions.resetMessage();
  };
  useEffect(() => {
    if (addTreatmentPlan != null) {
      navigate(addTreatmentPlan.treatmentPlanId, { state: addTreatmentPlan });
    }
    if (addTreatmentPlan && addTreatmentPlan.length == 0) {
      initFetch();
    }
    return () => {
      actions.resetTreatmentPlanList();
    };
  }, [addTreatmentPlan]);
  const saveTreatmentPlan = (event) => {
    event.preventDefault();
    if (isAdd) {
      navigate(values.treatmentPlan, { state: { saved: false } });
    } else {
      //values.effectiveDate = moment(values.effectiveDate).format("YYYY-MM-DD");
      actions.editTreatmentPlan(values);
    }
    closeModal();
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleEditTreatmentPlan = (row) => {
    setIsAdd(false);
    treatmentPlanFormData = {
      treatmentPlanId: row.treatmentPlanId,
      treatmentPlan: row.treatmentPlanName,
      treatmentPlanInternalName: row.treatmentPlanInternalName,
      globalPrice: row.globalPrice,
      assistPrice: row.assistPrice,
      effectiveDate: row.effectiveDate,
      endDate: row.endDate,
      practiceId: practiceId,
      gdmId: gdmId
    };
    setValues(treatmentPlanFormData);
    setIsOpen(true);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    let initialData = {
      practiceId: practiceId,
      treatmentPlanId: selectedTreatmentId,
      gdmId
    };
    actions.deleteTreatmentPlanList(initialData);
    closeDeleteModal();
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const treatment = treatmentPlanConfig({
    values,
    onChange: handleInputChange,
    // setEffectiveDate: handleEffectiveDateChange,
    onClick: closeModal,
    isAdd,
    disabled: values.treatmentPlan ? false : true
  });

  const handleFileUpload = () => {
    navigate(`loadByExcel`);
  };

  const isDisabled =
    globalData && globalData.filter((row) => row.gdmId === gdmId && row.status === "In-progress");

  return (
    <>
      <Box
        sx={{ ml: 1 }}
        className={`${loading ? "m-r-8 m-t-10 disable-section" : "m-r-8 m-t-10"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>Treatment Plans</Typography>
          <Stack direction="row">
            <Button
              variant="outlined"
              className="btn-primary  m-r-10"
              onClick={handleFileUpload}
              disabled={isDisabled.length ? false : true}>
              Load from Excel
            </Button>
            <Button
              variant="contained"
              disabled={disabled}
              className="btn-usf"
              onClick={addNewTreatmentPlan}>
              New Plan
            </Button>
          </Stack>
        </Stack>
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={rows}
            rowCount={rows.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
          />
        </Box>
      </Box>
      <CommonModal
        open={isOpen}
        config={treatment}
        isAdd={isAdd}
        handleSubmit={saveTreatmentPlan}
      />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <DeleteModal handleDelete={handleDelete} open={deleteModal} id="1" close={closeDeleteModal} />
    </>
  );
};
const mapStateToProps = ({ treatmentPlanReducer }) => {
  return {
    loading: treatmentPlanReducer.loading,
    treatmentPlan: treatmentPlanReducer.treatmentPlanList,
    addTreatmentPlan: treatmentPlanReducer.addTreatmentPlan,
    editTreatmentPlan: treatmentPlanReducer.editTreatmentPlan,
    errorMsg: treatmentPlanReducer.error,
    successMsg: treatmentPlanReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPractices: () => {
      dispatch(actionType.getPractices());
    },
    getTreatmentPlan: (payload) => {
      dispatch(actionType.getTreatmentPlan(payload));
    },
    addNewTreatmentPlan: (payload) => {
      dispatch(actionType.addNewTreatmentPlan(payload));
    },
    editTreatmentPlan: (payload) => {
      dispatch(actionType.editTreatmentPlan(payload));
    },
    resetTreatmentPlanList: () => {
      dispatch(actionType.resetTreatmentPlanList());
    },
    deleteTreatmentPlanList: (payload) => {
      dispatch(actionType.deleteTreatmentPlan(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(TreatmentPlan);
