import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../constants/api-url";
import { MSG } from "../constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";

function* fetchQuickAdds(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${payload.practiceId}/users/${payload.userId}/patients/search-text`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.QUICK_ADD_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionTypes.QUICK_ADD_LIST_FAIL, errorMsg: error.message });
  }
}

function* getQuickAddSaga() {
  yield takeEvery(actionTypes.QUICK_ADD_LIST_REQUESTED, fetchQuickAdds);
}

function* createNewQuickAdd(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "post",
      `/practices/${payload.practiceId}/users/${payload.userId}/patients/search-text`,
      "",
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ADD_NEW_QUICK_ADD_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    yield put({
      type: actionTypes.ADD_NEW_QUICK_ADD_FAILED,
      errorMsg: err.response.data
    });
  }
}

function* addNewQuickAddSaga() {
  yield takeEvery(actionTypes.ADD_NEW_QUICK_ADD_REQUESTED, createNewQuickAdd);
}

function* editQuickAdd(action) {
  const { payload } = action;

  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${payload.practiceId}/users/${payload.userId}/patients/search-text/${payload.searchTextId}`,
      "",
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.EDIT_QUICK_ADD_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    yield put({
      type: actionTypes.EDIT_QUICK_ADD_FAILED,
      errorMsg: err.response.data
    });
  }
}

function* editQuickAddSaga() {
  yield takeEvery(actionTypes.EDIT_QUICK_ADD_REQUESTED, editQuickAdd);
}
function* deleteQuickAdd(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "delete",
      `/practices/${payload.practiceId}/users/${payload.userId}/patients/search-text/${payload.searchTextId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.DELETE_QUICK_ADD_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    yield put({
      type: actionTypes.DELETE_QUICK_ADD_FAIL,
      errorMsg: err.response.data
    });
  }
}

function* deleteQuickAddSaga() {
  yield takeEvery(actionTypes.DELETE_QUICK_ADD_REQUESTED, deleteQuickAdd);
}
// Quick add for admin patient portal
function* getQuickAddList(action) {
  const { payload } = action;
  try {
    const params = {
      offset: "",
      limit: "100"
    };
    const requestBody = createRequestBody(
      "get",
      `/users/${payload.userId}/patients/search-text`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.USER_ID_QUICK_ADD_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({ type: actionTypes.USER_ID_QUICK_ADD_LIST_FAILED, errorMsg: error.message });
  }
}

function* getQuickAddListSaga() {
  yield takeEvery(actionTypes.USER_ID_QUICK_ADD_LIST_REQUESTED, getQuickAddList);
}

function* postNewQuickAdd(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "post",
      `/users/${payload.userId}/patients/search-text`,
      "",
      { patientSearchText: payload.patientSearchText }
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.USER_ID_ADD_NEW_QUICK_ADD_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    yield put({
      type: actionTypes.USER_ID_ADD_NEW_QUICK_ADD_FAILED,
      errorMsg: err.response.data
    });
  }
}
function* postNewQuickAddSaga() {
  yield takeEvery(actionTypes.USER_ID_ADD_NEW_QUICK_ADD_REQUESTED, postNewQuickAdd);
}

function* updateQuickAdd(action) {
  const { payload } = action;

  try {
    const requestBody = createRequestBody(
      "put",
      `/users/${payload.userId}/patients/search-text/${payload.searchTextId}`,
      "",
      { patientSearchText: payload.patientSearchText }
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.USER_ID_EDIT_QUICK_ADD_SUCCESS,
      payload: response.data,
      successMsg: MSG.UPDATED_SUCCESSFULLY
    });
  } catch (err) {
    yield put({
      type: actionTypes.USER_ID_EDIT_QUICK_ADD_FAILED,
      errorMsg: err.response.data
    });
  }
}
function* updateQuickAddSaga() {
  yield takeEvery(actionTypes.USER_ID_EDIT_QUICK_ADD_REQUESTED, updateQuickAdd);
}

function* removeQuickAdd(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "delete",
      `/users/${payload.userId}/patients/search-text/${payload.searchTextId}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.USER_ID_DELETE_QUICK_ADD_SUCCESS,
      payload: response.data,
      successMsg: MSG.DELETED_SUCCESSFULLY
    });
  } catch (err) {
    yield put({
      type: actionTypes.USER_ID_DELETE_QUICK_ADD_FAILED,
      errorMsg: err.response.data
    });
  }
}
function* removeQuickAddSaga() {
  yield takeEvery(actionTypes.USER_ID_DELETE_QUICK_ADD_REQUESTED, removeQuickAdd);
}
export default function* quickAddSaga() {
  yield all([
    getQuickAddSaga(),
    addNewQuickAddSaga(),
    editQuickAddSaga(),
    deleteQuickAddSaga(),
    getQuickAddListSaga(),
    postNewQuickAddSaga(),
    updateQuickAddSaga(),
    removeQuickAddSaga()
  ]);
}
