import * as actionType from "../actions/ActionTypes";

const initialState = {
  finSnapshot605AllList: [],
  loading: false,
  error: null,
  success: null
};
const finSnapshot605AllReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.FIN_SNAPSHOT_605_ALL_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.FIN_SNAPSHOT_605_ALL_SUCCESS: {
      return {
        ...state,
        finSnapshot605AllList: action.payload,
        loading: false
      };
    }
    case actionType.FIN_SNAPSHOT_605_ALL_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default finSnapshot605AllReducer;
