import * as actionType from "../actions/ActionTypes";

const initialState = {
  documentList: [],
  addDocument: null,
  editDocument: null,
  loading: false,
  error: null,
  success: null
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.DOCUMENT_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DOCUMENT_LIST_SUCCESS: {
      return {
        ...state,
        documentList: action.payload,
        loading: false
      };
    }
    case actionType.DOCUMENT_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_DOCUMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_DOCUMENT_SUCCESS: {
      return {
        ...state,
        addDocument: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_DOCUMENT_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.EDIT_DOCUMENT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_DOCUMENT_SUCCESS: {
      return {
        ...state,
        editDocument: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_DOCUMENT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_DOCUMENT: {
      return {
        ...state,
        addDocument: null,
        loading: null
      };
    }
    // case actionType.RESET_MESSAGE: {
    //   return {
    //     ...state,
    //     error: null,
    //     success: false
    //   };
    // }
    case actionType.RESET_DOCUMENT_LIST: {
      return {
        ...state,
        documentList: [],
        loading: null
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default documentReducer;
