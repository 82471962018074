import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as actionType from "../actions";
import { practicePaymentConfig } from "../../shared/constants/renderConfigs";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import moment from "moment/moment";
import isAccess from "../../shared/components/CheckUserRights";
import LoadingModal from "../../trak/components/modals/loadingModal";
import { useSelector } from "react-redux";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";
const PracticePayment = (props) => {
  let practicePaymentFormData = { description: "" };
  //const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState(practicePaymentFormData);
  const getProfileDetailsLoading = useSelector((state) => state.profileDrawerReducer.loading);
  //const [offsetVal, setOffsetVal] = useState(0);
  const {
    paymentTiming,
    addPaymentTiming,
    editPaymentTiming,
    loading,
    error,
    addSuccess,
    actions
  } = props;
  const columns = [
    {
      field: "paymentTimingId",
      headerName: "ID",
      width: 50,
      flex: 0.5,
      sortable: false
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      width: 100,
      editable: false,
      flex: 4
    },
    {
      field: "effectiveDate",
      headerName: "EFFECTIVE DATE",
      width: 50,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        return moment(row.effectiveDate).format("L");
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 50,
      editable: true,
      flex: 1,
      sortable: false,
      filterable: false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={() => {
            editPracticePayment(row);
          }}>
          <EditIcon color="primary"></EditIcon>
        </IconButton>
      )
    }
  ];

  const rows = paymentTiming.map((item, index) => {
    item.effectiveDate = new Date(item.effectiveDate);
    item.id = index;
    return item;
  });

  const initFetch = useCallback(() => {
    //actions.getPaymentTimingList({ offset: offsetVal, limit: 10 });
    actions.getPaymentTimingList({ offset: 0, limit: 100 });
  }, [addPaymentTiming, editPaymentTiming]);

  useEffect(() => {
    if (addSuccess || error) {
      setIsMessageModal(true);
    }
    if (paymentTiming.length === 0 || addPaymentTiming || editPaymentTiming) {
      initFetch();
    }
    return () => {
      actions.resetPaymentList();
    };
  }, [addPaymentTiming, editPaymentTiming, error, addSuccess]);

  const AddNewPracticePayment = () => {
    setValues(practicePaymentFormData);
    setIsAdd(true);
    setIsOpen(true);
  };

  const editPracticePayment = (row) => {
    setIsAdd(false);
    setIsOpen(true);
    practicePaymentFormData = {
      paymentTimingId: row.paymentTimingId,
      description: row.description
    };
    setValues(practicePaymentFormData);
  };

  const savePracticePayment = (event) => {
    event.preventDefault();
    if (isAdd) {
      actions.addPaymentTimingList(values);
    } else {
      actions.editPaymentTimingList(values);
    }
    closeModal();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  };

  const closeModal = () => {
    setValues(practicePaymentFormData);
    setIsOpen(false);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const practicePayment = practicePaymentConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    disabled: values.description ? false : true
  });

  // const pageChange = (e) => {
  //   setOffsetVal(e * 10);
  //   actions.getPaymentTimingList({ offset: e * 10, limit: 10 });
  //   setPage(e);
  // };
  return (
    <>
      {(isAccess("prpm") || isAccess("ucron")) && (
        <Box sx={{ ml: 1 }} className={`${loading ? "m-r-8 disable-section" : "m-r-8"}`}>
          <Stack direction="row" className="grid-header">
            <Typography>Practice Payment Timing List</Typography>
            <Button variant="contained" className="btn-usf" onClick={AddNewPracticePayment}>
              New Practice Payment
            </Button>
          </Stack>

          <Box className="grid-style" sx={{ height: "660px" }}>
            <DataGridPremiumComp
              rows={rows}
              rowCount={rows.length}
              columns={columns}
              loading={loading}
              pageSizeOptions={[10, 25, 50, 100]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => {
                return setPageSize(newPageSize);
              }}
              //page={page}
              //onPageChange={(e) => pageChange(e)}
              //pageSize={10}
              //rowCount={100}
              pagination
              //paginationMode="server"
            />
          </Box>
        </Box>
      )}

      {/* Add/Edit practice payment */}
      <CommonModal
        open={isOpen}
        isAdd={isAdd}
        config={practicePayment}
        handleSubmit={savePracticePayment}
      />
      <MessageModal
        open={isMessageModal}
        message={addSuccess}
        error={error}
        closeModal={closeMessageModal}
      />
      <LoadingModal open={getProfileDetailsLoading} />
    </>
  );
};

const mapStateToProps = ({ paymentTiming }) => {
  return {
    paymentTiming: paymentTiming.paymentTimingList,
    addPaymentTiming: paymentTiming.addPaymentTiming,
    editPaymentTiming: paymentTiming.editPaymentTiming,
    loading: paymentTiming.loading,
    error: paymentTiming.error,
    addSuccess: paymentTiming.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPaymentTimingList: (payload) => {
      dispatch(actionType.getPaymentTimingList(payload));
    },
    addPaymentTimingList: (payload) => {
      dispatch(actionType.addPaymentTimingList(payload));
    },
    editPaymentTimingList: (payload) => {
      dispatch(actionType.editPaymentTimingList(payload));
    },
    resetPaymentList: () => {
      dispatch(actionType.resetPaymentTimingList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PracticePayment);
