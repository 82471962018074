import * as actionType from "../actions/ActionTypes";

const initialState = {
  relativeValueList: [],
  relativeTypeList: [],
  metaData: [],
  addRelativeValue: null,
  editRelativeValue: null,
  deleteRelativeValue: null,
  loading: false,
  error: null,
  success: null
};

const relativeValueReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.RELATIVEVALUE_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.RELATIVEVALUE_LIST_SUCCESS: {
      return {
        ...state,
        relativeValueList: action.payload,
        loading: false
      };
    }
    case actionType.RELATIVEVALUE_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_RELATIVEVALUE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_RELATIVEVALUE_SUCCESS: {
      return {
        ...state,
        addRelativeValue: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_RELATIVEVALUE_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.EDIT_RELATIVEVALUE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_RELATIVEVALUE_SUCCESS: {
      return {
        ...state,
        editRelativeValue: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.EDIT_RELATIVEVALUE_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_RELATIVEVALUE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_RELATIVEVALUE_SUCCESS: {
      return {
        ...state,
        deleteRelativeValue: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_RELATIVEVALUE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_RELATIVEVALUE: {
      return {
        ...state,
        addRelativeValue: null,
        editRelativeValue: null,
        loading: null
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    case actionType.RESET_RELATIVEVALUE_LIST: {
      return {
        ...state,
        relativeValueList: [],
        loading: null
      };
    }
    case actionType.METADATA_RELATIVE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.METADATA_RELATIVE_SUCCESS: {
      return {
        ...state,
        metaData: action.payload,
        loading: false
      };
    }
    case actionType.METADATA_RELATIVE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RELATIVE_TYPE_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.RELATIVE_TYPE_SUCCESS: {
      return {
        ...state,
        relativeTypeList: action.payload,
        loading: false
      };
    }
    case actionType.RELATIVE_TYPE_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default relativeValueReducer;
