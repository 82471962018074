import FelixRoutes from "./routes/FelixRoute";

function App() {
  return (
    <div className="app">
      <FelixRoutes />
    </div>
  );
}

export default App;
