import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

// get All question list
function* fetchQuestions(action) {
  const { payload } = action;
  const { practiceId, gdmId } = payload;
  try {
    const params = {
      offset: "",
      limit: "10000"
    };
    const requestBody = createRequestBody(
      "get",
      `/practices/${practiceId}/gdms/${gdmId}/estimateQuestions`,
      params,
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.ESTIMATE_QUESTIONS_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.ESTIMATE_QUESTIONS_LIST_FAIL,
        message: error.message
      });
    }
  }
}

function* fetchQuestionSaga() {
  yield takeEvery(actionTypes.ESTIMATE_QUESTIONS_LIST_REQUESTED, fetchQuestions);
}

function* saveQuestions(action) {
  const { payload } = action;
  const { practiceId, gdmId, editRows } = payload;
  try {
    const requestBody = createRequestBody(
      "put",
      `/practices/${practiceId}/gdms/${gdmId}/estimateQuestions`,
      "",
      editRows
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.SAVE_ESTIMATE_QUESTIONS_LIST_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (err) {
    if (appAuthencationCheck(err) === true) {
      yield put({
        type: actionTypes.SAVE_ESTIMATE_QUESTIONS_LIST_FAIL,
        errorMsg: err.response.data
      });
    }
  }
}

function* saveQuestionSaga() {
  yield takeEvery(actionTypes.SAVE_ESTIMATE_QUESTIONS_LIST_REQUESTED, saveQuestions);
}

export default function* questionSaga() {
  yield all([fetchQuestionSaga(), saveQuestionSaga()]);
}
