import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import {
  TextField,
  Box,
  Button,
  Stack,
  Typography,
  Divider,
  Modal,
  Grid,
  CircularProgress
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Close from "@mui/icons-material/Close";
import { connect } from "react-redux";
import * as actionType from "../actions";
import EditIcon from "@mui/icons-material/Edit";
import MessageModal from "./MessageModal";
import DeleteModal from "./DeleteModal";

const QuickAdd = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenTextarea, setOpenTextarea] = useState(false);
  const [quickAddValue, setQuickAddValue] = useState("");
  const [item, setItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const practiceId = localStorage.getItem("practiceId");
  const {
    text,
    search,
    handleSearchClick,
    clearSearchClick,
    actions,
    quickAddData = [],
    loading,
    addQuickAdd,
    editQuickAdd,
    deleteQuickAdd,
    successMsg,
    errorMsg,
    mandatory,
    labelText,
    isOnlyUserId,
    quickAddUserList = []
  } = props;
  const [quickAddList, setQuickAddList] = useState([]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }
    if (addQuickAdd || editQuickAdd || deleteQuickAdd) {
      if (isOnlyUserId) {
        let payload = { userId: 1 };
        actions.userIdGetQuickAddList(payload);
      } else {
        let payload = { userId: 1, practiceId };
        actions.getQuickAddList(payload);
      }
    }
    return () => {
      actions.resetQuickAddList();
    };
  }, [addQuickAdd, editQuickAdd, deleteQuickAdd, successMsg, errorMsg]);

  useEffect(() => {
    if (isOnlyUserId) {
      setQuickAddList(quickAddUserList);
    } else {
      setQuickAddList(quickAddData);
    }
  }, [loading]);

  const getQuickAddList = () => {
    if (isOnlyUserId) {
      let payload = { userId: 1 };
      if (quickAddUserList.length == 0) {
        actions.userIdGetQuickAddList(payload);
      }
    } else {
      let payload = { userId: 1, practiceId };
      if (quickAddData.length == 0) {
        actions.getQuickAddList(payload);
      }
    }

    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
    setOpenTextarea(false);
  };
  const addNewQuickAdd = () => {
    setQuickAddValue("");
    setOpenTextarea(true);
  };
  const handleSearchChange = (e) => {
    let result;
    if (isOnlyUserId) {
      result = quickAddUserList.filter((item) => {
        return Object.values(item.patientSearchText)
          .join("")
          .toLowerCase()
          .includes(e.target.value);
      });
    } else {
      result = quickAddData.filter((item) => {
        return Object.values(item.patientSearchText)
          .join("")
          .toLowerCase()
          .includes(e.target.value);
      });
    }
    setQuickAddList(result);
  };
  const saveNewQuickAdd = () => {
    if (isOnlyUserId) {
      let payload = { userId: 1, patientSearchText: quickAddValue };
      if (isEdit) {
        payload.searchTextId = item.searchTextId;
        actions.userIdEditQuickAdd(payload);
      } else {
        payload.patientSearchText = quickAddValue;
        actions.userIdAddNewQuickAdd(payload);
      }
    } else {
      let payload = { userId: 1, practiceId, patientSearchText: quickAddValue };
      if (isEdit) {
        payload.searchTextId = item.searchTextId;
        actions.editQuickAdd(payload);
      } else {
        payload.patientSearchText = quickAddValue;
        actions.addNewQuickAdd(payload);
      }
    }

    setOpenTextarea(false);
  };
  const handleEditClick = (item) => {
    setIsEdit(true);
    setOpenTextarea(true);
    setItem(item);
    setQuickAddValue(item.patientSearchText);
  };
  const handleCancelClick = () => {
    setIsEdit(false);
    setOpenTextarea(false);
  };
  const handleDeletelClick = () => {
    if (isOnlyUserId) {
      let payload = {
        userId: 1,
        searchTextId: item.searchTextId
      };
      actions.userIdDeleteQuickAdd(payload);
    } else {
      let payload = {
        userId: 1,
        practiceId,
        searchTextId: item.searchTextId
      };
      actions.deleteQuickAdd(payload);
    }
    setIsEdit(false);
    setOpenTextarea(false);
    setDeleteModal(false);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };
  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  return (
    <Box>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={text}>
            <Typography>
              Find a {labelText ? labelText : "Patient"} {mandatory ? "*" : ""}
            </Typography>
          </Grid>
          <Grid item xs={search}>
            <Stack direction="row">
              <TextField
                fullWidth
                size="small"
                value={searchText}
                placeholder="Search by Patient Name or ID"
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <>
                      {searchText && (
                        <IconButton
                          className="m-r-m10"
                          onClick={() => {
                            setSearchText("");
                            clearSearchClick();
                          }}>
                          <Close sx={{ fontSize: 12 }} />
                        </IconButton>
                      )}
                      <IconButton className="m-r-m15" onClick={getQuickAddList}>
                        <AddCircleOutlineIcon
                          color="primary"
                          fontSize="small"
                          titleAccess="Quick add"
                          className="cursor-pointer"
                        />
                      </IconButton>
                    </>
                  )
                }}
              />
              <Button
                variant="outlined"
                className="search-pat-btn"
                onClick={(e) => handleSearchClick(e, searchText)}>
                <SearchIcon />
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Stack>

      {/* Quick search add  modal start*/}
      <Modal open={isOpen} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="modal-style" sx={{ width: 320 }}>
          <Stack direction="row" className="modal-stack">
            <Typography>QUICK ADD</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="row" className="modal-stack">
            <TextField
              sx={{ flex: 6 }}
              required
              name="name"
              size="small"
              placeholder="Search"
              onChange={(e) => handleSearchChange(e)}
            />
          </Stack>
          {!loading && (
            <Box>
              <Stack direction="row" className="modal-stack m-b-10">
                <Typography>{quickAddList.length} Items</Typography>
              </Stack>
              <Divider className="modal-divider" />
            </Box>
          )}
          <Box className="scroll">
            {loading ? (
              <Stack className="modal-stack">
                <CircularProgress />
              </Stack>
            ) : (
              quickAddList.map((item, index) => {
                return (
                  <Box key={index}>
                    <Stack direction="row" className="modal-stack m-t-0">
                      <Typography
                        className="cursor-pointer"
                        color="primary"
                        onClick={() => {
                          setIsOpen(false);
                          setSearchText(item.patientSearchText);
                        }}>
                        {item.patientSearchText}
                      </Typography>
                      <IconButton onClick={() => handleEditClick(item)}>
                        <EditIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Stack>
                    <Divider className="q-divider" />
                  </Box>
                );
              })
            )}
          </Box>
          <Stack
            direction="row"
            spacing={2}
            className="modal-bottom-section"
            sx={{ justifyContent: "flex-start" }}>
            <Box className="cursor-pointer">
              <AddCircleOutlineIcon fontSize="small" className="circle-icon">
                ADD NEW
              </AddCircleOutlineIcon>
              <Button onClick={addNewQuickAdd}>ADD NEW</Button>
            </Box>
          </Stack>
        </Box>
      </Modal>
      {/* Quick search add modal end*/}

      {/* Quick add  modal start*/}
      <Modal open={isOpenTextarea} backdrop="static" aria-labelledby="modal-add-title">
        <Box className="modal-style">
          <Stack direction="row" className="modal-stack">
            <Typography>CREATE NEW QUICK ADD</Typography>
            <IconButton onClick={handleCancelClick}>
              <Close />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Typography className="m-t-10">*QUICK ADD TEXT</Typography>
          <Stack className="modal-stack">
            <TextareaAutosize
              maxRows={4}
              aria-label="maximum height"
              placeholder=""
              value={quickAddValue}
              onChange={(e) => setQuickAddValue(e.target.value)}
              style={{ width: 400, height: 80 }}
            />
          </Stack>
          <Stack direction="row" spacing={2} className="modal-bottom-section">
            {isEdit && (
              <Button
                variant="outlined"
                sx={{ marginRight: "160px" }}
                className="btn-primary"
                onClick={() => {
                  setDeleteModal(true);
                }}>
                Delete
              </Button>
            )}
            <Button variant="outlined" className="btn-primary" onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button variant="contained" className="btn-usf" onClick={saveNewQuickAdd}>
              Save
            </Button>
          </Stack>
        </Box>
      </Modal>
      {/* Quick add modal end*/}
      {/* message modal here */}
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      {/* delete modal here */}
      <DeleteModal
        open={deleteModal}
        id={quickAddValue}
        handleDelete={handleDeletelClick}
        close={closeDeleteModal}
      />
    </Box>
  );
};
const mapStateToProps = ({ quickAddReducer }) => {
  return {
    quickAddData: quickAddReducer.quickAddData,
    addQuickAdd: quickAddReducer.addQuickAdd,
    editQuickAdd: quickAddReducer.editQuickAdd,
    deleteQuickAdd: quickAddReducer.deleteQuickAdd,
    errorMsg: quickAddReducer.error,
    successMsg: quickAddReducer.success,
    loading: quickAddReducer.loading,
    quickAddUserList: quickAddReducer.quickAddUserList
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getQuickAddList: (payload) => {
      dispatch(actionType.getQuickAddList(payload));
    },
    addNewQuickAdd: (payload) => {
      dispatch(actionType.addNewQuickAdd(payload));
    },
    editQuickAdd: (payload) => {
      dispatch(actionType.editQuickAdd(payload));
    },
    deleteQuickAdd: (payload) => {
      dispatch(actionType.deleteQuickAdd(payload));
    },
    resetQuickAddList: () => {
      dispatch(actionType.resetQuickAddList());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    userIdGetQuickAddList: (payload) => {
      dispatch(actionType.userIdGetQuickAddList(payload));
    },
    userIdAddNewQuickAdd: (payload) => {
      dispatch(actionType.userIdAddNewQuickAdd(payload));
    },
    userIdEditQuickAdd: (payload) => {
      dispatch(actionType.userIdEditQuickAdd(payload));
    },
    userIdDeleteQuickAdd: (payload) => {
      dispatch(actionType.userIdDeleteQuickAdd(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(QuickAdd);
