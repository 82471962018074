import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Grid, InputLabel, MenuItem, Checkbox } from "@mui/material";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LoadingSpinner from "../../../shared/components/LoadingSpinner";
import * as actionType from "../../actions";
// import { CheckBox } from "@mui/icons-material";
import PricingModal from "./PricingModal";
import MessageModal from "../../../shared/components/MessageModal";
import moment from "moment";
import DataGridPremiumComp from "../../../shared/components/DataGridPremiumComp";

const ProgramCycleModal = (props) => {
  const formTransactionDate = {
    transactionDate: new Date()
  };
  const { newPrList, loading, actions, clinicalList, errorPR, successPR, medcaseDetails } = props;
  const [values, setValues] = useState({
    transactionTypeDesc: ""
  });
  const [date, setDate] = useState(formTransactionDate);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [openPricing, setOpenPricing] = useState(false);
  const [zeroError, setZeroError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const newPricing = () => {
    setOpenPricing(true);
  };
  const rows = clinicalList
    ? clinicalList.map((item, index) => {
        item.id = index;
        return item;
      })
    : [];
  const columns = [
    {
      field: "clinicalCycleNo",
      headerName: "Cycle No",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "authorizedTpName",
      headerName: "Authorized Service",
      width: 100,
      editable: false,
      flex: 1
    },
    {
      field: "authDate",
      headerName: "Authorization Date",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "performedTpName",
      headerName: "Performed Service",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "outcomeCategory",
      headerName: "Outcome",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "outcomeDate",
      headerName: "Outcome Date",
      width: 50,
      editable: false,
      flex: 1
    },
    {
      field: "enrollmentDate",
      headerName: "Enrollment Date",
      width: 50,
      editable: false,
      flex: 1
    }
  ];
  useEffect(() => {
    actions.getClinicalTreatmentOutcomes({ medcaseId: props.medcaseId });
  }, []);

  useEffect(() => {
    if (medcaseDetails && !(values && values.transactionTypeDesc)) {
      if (medcaseDetails.medcaseStatus === "Enrolled") {
        setValues({
          ...values,
          ["transactionTypeDesc"]: "Practice"
        });
      } else if (medcaseDetails.medcaseStatus === "Re-Enrolled") {
        setValues({
          ...values,
          ["transactionTypeDesc"]: "Practice - Medical Cost Reserve"
        });
      }
    }
  }, []);

  useEffect(() => {
    let payload = {
      medcaseId: props.medcaseId,
      clinicalTreatmentNo: props.selectedPR.clinicalTreatmentNo
    };
    actions.createProgramCycle(payload);
  }, []);
  const handleChange = (e) => {
    if (e.target.value != 0 || e.target.value == "") {
      setZeroError(false);
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value
      });
      setErrorMessage([]);
    } else {
      const { name, value } = e.target;
      setErrorMessage(["Amount should not be 0"]);
      setZeroError(true);
      setValues({
        ...values,
        [name]: value
      });
    }
  };
  const handleClose = () => {
    props.handleClose();
  };
  const saveNewPR = (event) => {
    console.log("values", values);
    event.preventDefault();
    if (values && values.transactionTypeDesc == "") {
      alert("Transaction Type must be entered to add a New PR");
      return;
    }
    date.transactionDate = moment(date.transactionDate).format("YYYY-MM-DD");
    const payload = {
      value: newPrList,
      outcomeStatus: props.selectedPR.outcome,
      medcaseId: props.medcaseId,
      clinicalTreatmentNo: props.selectedPR.clinicalTreatmentNo,
      performedTreatmentPlan: props.selectedPR.performedTreatment,
      requestedTreatmentPlan: props.selectedPR.authorizedTreatment,
      newValue: values,
      transactionDate: date.transactionDate
    };
    actions.newPrPost(payload);
    setDate(formTransactionDate);
  };
  useEffect(() => {
    if (errorPR) {
      setIsMessageModal(true);
    }
    if (successPR) {
      setIsMessageModal(true);
    }
  }, [errorPR, successPR]);
  const closeMessageModal = () => {
    setIsMessageModal(false);
    props.handleClose();
    actions.resetMessage();
  };
  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-add-title"
        aria-describedby="modal-add-description">
        <Box className="modal-style-contact" style={{ maxHeight: "80vh", overflow: "auto" }}>
          <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
            <Typography>Add Reimbursement</Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Stack>
          <Divider sx={{ backgroundColor: "#177483" }} orientation="horizontal" />
          <Grid container className="modal-stackMedCase">
            <Stack>
              <Typography sx={{ fontWeight: "bold" }}>Reimbursement detail</Typography>
            </Stack>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Cycle Id</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleId"
                      value={newPrList.clinicalTreatmentId}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Patient Name</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="patientName"
                      value={newPrList.patientFirstName + " " + newPrList.patientLastName}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Cycle No</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleNo"
                      value={props.selectedPR.clinicalTreatmentNo}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Create date </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        openTo="day"
                        views={["day"]}
                        name="creationDate"
                        disableFuture
                        value={values?.creationDate}
                        onChange={(newValue) => {
                          setValues({ ...values, creationDate: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" sx={{ flex: 8 }} disabled />
                        )}></DatePicker>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Medcase</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="medcase"
                      value={newPrList.medcaseId}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel required>Amount </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      placeholder="Amount should not be 0"
                      size="small"
                      fullWidth
                      error={zeroError}
                      helperText={errorMessage}
                      autoComplete="off"
                      name="approvedReimbursementAmount"
                      value={newPrList.approvedReimbursementAmount}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Payable To</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="payableTo"
                      value={newPrList.practiceName}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel style={{ whiteSpace: "break-spaces" }}>
                      Transaction Date *
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        //   openTo="day"
                        views={["day", "year", "month"]}
                        name="transactionDate"
                        disableFuture
                        value={date?.transactionDate}
                        onChange={(newValue) => {
                          setDate({ date, transactionDate: newValue });
                        }}
                        renderInput={(params) => (
                          <TextField {...params} size="small" sx={{ flex: 8 }} />
                        )}></DatePicker>
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel> GL NO</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="glno"
                      value={newPrList.glNo}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel style={{ whiteSpace: "break-spaces" }}> Cycle outcome</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="cycleoutcome"
                      value={props.selectedPR.outcome}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Grid container className="modal-stackMedCase">
                <Grid item xs={4}>
                  <InputLabel required style={{ whiteSpace: "break-spaces" }}>
                    Transaction type
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    size="small"
                    fullWidth
                    id="select"
                    autoComplete="off"
                    name="transactionTypeDesc"
                    value={values.transactionTypeDesc}
                    onChange={handleChange}
                    select>
                    <MenuItem value="Practice">Practice</MenuItem>
                    <MenuItem value="Practice - Medical Cost Reserve">
                      Practice - Medical Cost Reserve
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel style={{ whiteSpace: "break-spaces" }}>Medcase status</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="medcasestatus"
                      value={newPrList.medcaseStatus}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel>Program Fee</InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="programFee"
                      value={newPrList.programFee}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel style={{ whiteSpace: "break-spaces" }}>
                      Performed Service
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="performedService"
                      value={props.selectedPR.performedTreatment}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel required style={{ whiteSpace: "break-spaces" }}>
                      Manual Review
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <Checkbox
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="manualReviewcheckbox"
                      value={newPrList.isManualReview}
                      checked={newPrList.isManualReview}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4.5}>
                    <InputLabel style={{ whiteSpace: "break-spaces" }}>
                      Location Reimbursement
                    </InputLabel>
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="locationReimbursement"
                      value={newPrList.reimbursementPercentage}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack>
                <Grid container className="modal-stackMedCase">
                  <Grid item xs={4}>
                    <InputLabel style={{ whiteSpace: "break-spaces" }}>
                      Authorized service
                    </InputLabel>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      size="small"
                      fullWidth
                      autoComplete="off"
                      name="authorizedservice"
                      value={props.selectedPR.authorizedTreatment}
                      onChange={handleChange}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Grid>
            <Grid container className="modal-stackMedCase">
              <Grid item xs={4}>
                <InputLabel required>Comment box</InputLabel>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  size="small"
                  fullWidth
                  autoComplete="off"
                  name="comment"
                  value={newPrList.comment}
                  onChange={handleChange}
                />
              </Grid>
              <Box className="grid-style">
                <Typography>History of all cycle</Typography>
                <DataGridPremiumComp
                  rows={rows}
                  rowCount={rows.length}
                  columns={columns}
                  loading={loading}
                  pageSizeOptions={[10, 25, 50]}
                  pageSize={pageSize.pageSize}
                  paginationModel={pageSize}
                  onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
                  pagination
                />
              </Box>
            </Grid>
          </Grid>
          {props.loading ? <LoadingSpinner /> : ""}
          <Stack
            direction="row"
            spacing={2}
            sx={{ my: 3, alignItems: "center", justifyContent: "flex-end" }}>
            <Button variant="outlined" className="btn-primary" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" className="btn-usf" onClick={saveNewPR}>
              Save & Close
            </Button>
            <Button type="submit" variant="contained" className="btn-usf" onClick={newPricing}>
              Pricing
            </Button>
          </Stack>
        </Box>
      </Modal>
      {openPricing ? (
        <PricingModal
          open={openPricing}
          close={() => setOpenPricing(false)}
          newPrList={newPrList}
        />
      ) : (
        ""
      )}
      {loading && <LoadingSpinner />}
      <MessageModal
        open={isMessageModal}
        message={successPR}
        error={errorPR}
        closeModal={closeMessageModal}
      />
    </>
  );
};
const mapStateToProps = ({
  locationReducer,
  physiciansReducer,
  contactReducer,
  globalDataModelReducer,
  treatmentPlanReducer,
  programReducer,
  newPrReducer,
  trackOutComeFormReducer
}) => {
  return {
    locations: locationReducer.locations,
    clinicalList: trackOutComeFormReducer.clinicalTreatmentOutcomesList,
    newPrList: newPrReducer.newPrList,
    physicians: physiciansReducer.physicians,
    contactList: contactReducer.contactList,
    gdmList: globalDataModelReducer.globalData,
    treatmentPlanList: treatmentPlanReducer.treatmentPlanList,
    programList: programReducer.programList,
    loading:
      locationReducer.loading ||
      contactReducer.loading ||
      physiciansReducer.loading | locationReducer.loading ||
      newPrReducer.loading,
    errorPR: newPrReducer.errorPR,
    successPR: newPrReducer.successPR
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMedcaseList: (payload) => {
      dispatch(actionType.getMedcaseList(payload));
    },
    getClinicalTreatmentOutcomes: (payload) => {
      dispatch(actionType.getClinicalTreatmentOutcomes(payload));
    },
    getProgramCycle: (payload) => {
      dispatch(actionType.getProgramCycle(payload));
    },
    createProgramCycle: (payload) => {
      dispatch(actionType.createProgramCycle(payload));
    },
    newPrPost: (payload) => {
      dispatch(actionType.newPrPost(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ProgramCycleModal);
