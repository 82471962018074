import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  // Select,
  // MenuItem,
  Button,
  Autocomplete
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { connect } from "react-redux";
import * as actionType from "../actions";
import { getPractices, getLocations } from "../../admin/actions";
import {
  gridPaginatedVisibleSortedGridRowIdsSelector,
  useGridApiContext
} from "@mui/x-data-grid-premium";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import RelativeValueModal from "./modals/RelativeValueModal";
import MessageModal from "../../shared/components/MessageModal";
import LoadingModal from "./modals/loadingModal";
import { priceFormat } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const revenueColumns = [
  {
    field: "medcaseId",
    headerName: "MEDCASE ID",
    width: 100
  },
  {
    field: "medcaseTransactionId",
    headerName: "TRANS ID",
    width: 100
  },
  {
    field: "patientName",
    headerName: "PATIENT NAME",
    width: 100
  },
  {
    field: "locationName",
    headerName: "LOCATION",
    width: 100
  },
  {
    field: "practiceName",
    headerName: "PRACTICE",
    width: 100
  },
  {
    field: "transactionDate",
    headerName: "TRANS DATE",
    width: 100
  },
  {
    field: "transactionType",
    headerName: "TRANS TYPE",
    width: 100
  },
  {
    field: "cycle",
    headerName: "CYCLE #",
    width: 100
  },
  {
    field: "amount",
    headerName: "AMOUNT",
    width: 100,
    renderCell: ({ row }) => {
      return "$" + priceFormat(row.amount);
    }
  },
  {
    field: "progShortName",
    headerName: "PROGRAM SHORT NAME",
    width: 100
  },
  {
    field: "refundPercentage",
    headerName: "REFUND %",
    width: 100
  },
  {
    field: "outcomeDate",
    headerName: "OUTCOME DATE",
    width: 100
  },
  {
    field: "status",
    headerName: "STATUS",
    width: 100
  }
];

const Revenue = (props) => {
  let formData = {
    lastName: "",
    firstName: "",
    medcaseId: "",
    practiceId: "",
    locationId: "",
    from: null,
    to: null
  };
  const {
    actions,
    practices = [],
    locations = [],
    revenueFilterList = [],
    loading,
    success,
    error,
    loadingLocation
  } = props;
  const [practiceId, setPracticeId] = useState("");
  const [locationId, setLocationId] = useState("");
  const [values, setValues] = useState(formData);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [isOpenMessage, setIsMessageModal] = useState(false);
  const [pName, setPName] = useState("");
  const [locationName, setLocationName] = useState("");
  useEffect(() => {
    actions.getMetaData();
    actions.getPractices();
  }, []);
  useEffect(() => {
    if (practiceId) {
      actions.getLocations(practiceId);
    }
    if (success || error) {
      setIsMessageModal(true);
    }
    if (success) {
      const payload = {
        medcaseId: "",
        firstName: "",
        lastName: "",
        program: "",
        practiceId: "",
        location: "",
        transactionDateFrom: "",
        transactionDateTo: ""
      };
      actions.getRevenueFilter(payload);
    }
    return () => {
      actions.resetRevenueList();
    };
  }, [practiceId, success, error]);

  useEffect(() => {
    setData(revenueFilterList);
  }, [revenueFilterList]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    //actions.resetRunRevenue();
  };

  const search = (e) => {
    e.preventDefault();
    const payload = {
      medcaseId: values.medcaseId || "",
      firstName: values.firstName || "",
      lastName: values.lastName || "",
      program: values.programId || "",
      practiceId: pName || "",
      location: locationName || "",
      transactionDateFrom: values.from == null ? "" : moment(values.from).format("YYYY-MM-DD"),
      transactionDateTo: values.to == null ? "" : moment(values.to).format("YYYY-MM-DD")
    };
    let validPayload = false;
    Object.values(payload).forEach((val) => {
      if (val !== "") {
        validPayload = true;
      }
    });
    if (validPayload) actions.getRevenueFilter(payload);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const clearSearch = () => {
    setValues(formData);
    setPracticeId("");
    setLocationId("");
    actions.resetRevenueList();
  };

  const handleRunRevenue = () => {
    actions.runRevenue();
  };

  const getRowsFromCurrentPage = ({ apiRef }) =>
    gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);

  const CustomToolbar = () => {
    const apiRef = useGridApiContext();
    const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end",
          margin: "10px"
        }}>
        <Button
          variant="contained"
          className="btn-usf"
          onClick={clearSearch}
          style={{ marginRight: 20 }}>
          Clear
        </Button>
        <Button
          variant="contained"
          className="btn-usf"
          onClick={search}
          style={{ marginRight: 20 }}>
          Search
        </Button>
        <Button
          variant="contained"
          className="btn-usf"
          onClick={handleRunRevenue}
          style={{ marginRight: 20 }}
          disabled="true">
          Run Revenue
        </Button>
        <Button
          variant="contained"
          className="btn-usf"
          onClick={() => handleExport({ getRowsToExport: getRowsFromCurrentPage })}
          style={{ marginRight: 20 }}>
          Send To Excel
        </Button>
        <Button
          variant="contained"
          className="btn-usf"
          onClick={() => setIsOpen(true)}
          style={{ marginRight: 20 }}>
          Relative Value Table
        </Button>
      </Box>
    );
  };

  const rows =
    data.length > 0 &&
    data.map((item, index) => {
      item.patientName = item.firstName + " " + item.lastname;
      item.transactionDate = item.transactionDate && moment(item.transactionDate).format("L");
      item.outcomeDate = item.outcomeDate && moment(item.outcomeDate).format("L");
      item.id = index;
      return item;
    });

  return (
    <>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Last Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <TextField
                fullWidth
                size="small"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Typography style={{ marginLeft: "10px" }}>First Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <TextField
                fullWidth
                size="small"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Practice</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              {/* <Select
                fullWidth
                name="practiceId"
                value={practiceId}
                className="patient-dropdown"
                onChange={(e) => {
                  setPracticeId(e.target.value);
                  const practice = practices.filter((item) => item.practiceId == e.target.value);
                  setPName(practice[0].name);
                }}>
                <MenuItem value="">Select</MenuItem>
                {practices.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Autocomplete
                disablePortal
                fullWidth
                name="practiceId"
                value={
                  practices.find((item) => item.practiceId === practiceId)
                    ? practices.find((item) => item.practiceId === practiceId).name
                    : ""
                }
                options={practices.map((item, index) => {
                  return {
                    id: index,
                    label: item.name,
                    name: "practiceId",
                    value: item.practiceId
                  };
                })}
                onChange={(e, value) => {
                  setPracticeId(value?.value);
                  const practice = practices.filter((item) => item.practiceId == value?.value);
                  setPName(practice[0].name);
                }}
                renderInput={(params) => <TextField {...params} label="Practice..." />}
                className="patient-dropdown"
              />
            </Stack>
          </Grid>
          <Grid item xs={2}>
            <Typography style={{ marginLeft: "10px" }}>Location</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              fullWidth
              name="locationId"
              value={locationId}
              className="patient-dropdown"
              disabled={practiceId == ""}
              onChange={(e) => {
                setLocationId(e.target.value);
                const location = locations.filter((item) => item.locationId == e.target.value);
                setLocationName(location[0].name);
              }}>
              <MenuItem value="">Select</MenuItem>
              {locations.map((item, index) => {
                return (
                  <MenuItem value={item.locationId} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select> */}
            <Autocomplete
              disablePortal
              fullWidth
              disabled={practiceId == ""}
              name="locationId"
              value={
                locations.find((item) => item.locationId === locationId)
                  ? locations.find((item) => item.locationId === locationId).name
                  : ""
              }
              options={locations.map((item, index) => {
                return {
                  id: index,
                  label: item.name,
                  name: "locationId",
                  value: item.locationId
                };
              })}
              onChange={(e, value) => {
                setLocationId(value?.value);
                const location = locations.filter((item) => item.locationId == value?.value);
                setLocationName(location[0].name);
              }}
              renderInput={(params) => <TextField {...params} label="Location..." />}
              className="patient-dropdown"
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Medcase ID</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <TextField
                fullWidth
                size="small"
                name="medcaseId"
                value={values.medcaseId}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography> Transaction Date From</Typography>
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disableFuture
                openTo="day"
                value={values.from}
                onChange={(newValue) => {
                  setValues({ ...values, from: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}></DatePicker>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={2}>
            <Typography style={{ marginLeft: "10px" }}>To</Typography>
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disableFuture
                openTo="day"
                value={values.to}
                onChange={(newValue) => {
                  setValues({ ...values, to: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                )}></DatePicker>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Stack>
      <Box className="grid-style pad-5" sx={{ height: "660px" }}>
        <DataGridPremiumComp
          rows={rows}
          rowCount={rows?.length ? rows.length : 0}
          columns={revenueColumns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          pagination
          getRowId={() => uuidv4()}
          components={<CustomToolbar />}
        />
      </Box>
      <RelativeValueModal isOpen={isOpen} handleClose={handleCloseModal} />
      <MessageModal
        open={isOpenMessage}
        message={success}
        error={error}
        closeModal={closeMessageModal}
      />
      <LoadingModal open={loadingLocation} />
    </>
  );
};

const mapStateToProps = ({
  caseUpdateReducer,
  practices,
  locationReducer,
  physiciansReducer,
  contactReducer,
  revenueReducer
}) => {
  return {
    metaData: caseUpdateReducer.metaData,
    practices: practices.practices,
    locations: locationReducer.locations,
    loadingLocation: locationReducer.loading,
    physicians: physiciansReducer.physicians,
    contacts: contactReducer.contactList,
    revenueFilterList: revenueReducer.revenueFilterList,
    loading: revenueReducer.loading,
    success: revenueReducer.success,
    error: revenueReducer.error
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    },
    getPractices: () => {
      dispatch(getPractices());
    },
    getLocations: (practiceId) => {
      dispatch(getLocations(practiceId));
    },
    getRevenueFilter: (payload) => {
      dispatch(actionType.getRevenueFilter(payload));
    },
    runRevenue: () => {
      dispatch(actionType.runRevenue());
    },
    resetRunRevenue: () => {
      dispatch(actionType.resetRunRevenue());
    },
    resetRevenueList: () => {
      dispatch(actionType.resetRevenueFilter());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Revenue);
