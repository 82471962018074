import React, { useState, useEffect, useRef } from "react";
import {
  IconButton,
  Box,
  Grid,
  Typography,
  Select,
  Divider,
  TextField,
  Button,
  Modal,
  Autocomplete
} from "@mui/material";
import { Stack, MenuItem } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./Workspace.scss";
import QuickAdd from "../../shared/components/QuickAdd";
import ArticleIcon from "@mui/icons-material/Article";
import DescriptionIcon from "@mui/icons-material/Description";
import { Link } from "react-router-dom";
import { DATE_RANGE, ESTIMATE_STATUS, ESTIMATE_TYPE } from "../../shared/constants/constants";
import * as actionType from "../../../modules/workspace/actions";
import { connect } from "react-redux";
import moment from "moment/moment";
import { Close } from "@mui/icons-material";
import PatientDemoGraphic from "./PatientDemoGraphic";
import isAccess from "../../shared/components/CheckUserRights";
import LoadingModal from "../../trak/components/modals/loadingModal";
import { useSelector } from "react-redux";
import { getLocaleDateTime } from "../../shared/common";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const FinancialDirectory = (props) => {
  const getProfileDetailsLoading = useSelector((state) => state?.profileDrawerReducer?.loading);
  const profileDetails = useSelector((state) => state?.profileDrawerReducer?.profileDetails);
  console.log("zhr profileDetails:", profileDetails);

  const { actions, estimateList = [], loading, inputValue } = props;

  const [practiceId, setPracticeId] = useState(null);

  useEffect(() => {
    const checkPracticeId = () => {
      // Get practiceId from localStorage
      const localStoragePracticeId = localStorage.getItem("practiceId");

      if (localStoragePracticeId) {
        // If localStorage value exists, use it
        setPracticeId(localStoragePracticeId);
      } else if (profileDetails?.defaultpracticeId) {
        // If localStorage value is not found, fallback to profileDetails
        setPracticeId(profileDetails.defaultpracticeId);
      }
    };

    // Run the function every 1 second until practiceId is found
    const intervalId = setInterval(() => {
      checkPracticeId();

      // Clear the interval if practiceId is set
      if (practiceId) {
        clearInterval(intervalId);
      }
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [practiceId]); // Dependency on practiceId to re-run only when it changes

  // const yesterday = new Date();
  // yesterday.setDate(yesterday.getDate() - 1);
  const defaultValue = {
    patientNameOrId: "",
    dateRange: "",
    estimateType: "D",
    estimateStatus: "",
    createdBy: "",
    publishedBy: "",
    modifiedDate: ""
  };
  const columns = [
    {
      field: "patientFullName",
      headerName: "PATIENT NAME",
      renderCell: ({ row }) => {
        return (
          <Stack>
            <Typography>{row.patientFullName}</Typography>
            <Typography>#{row.personKey}</Typography>
          </Stack>
        );
      }
    },
    {
      field: "details",
      headerName: "DETAILS",
      editable: false,
      renderCell: ({ row }) => (
        <>
          {(row.fullEstimateType === "Not Estimated" && <ArticleIcon color="disabled" />) || (
            <Link
              to={row.personId}
              onClick={() => {
                let payload = {
                  practiceId: row.practiceId,
                  personId: row.personId
                };

                actions.setEstimateId(row.estimateId);
                actions.resetEstimateViewList();
                actions.inputValues(values);
                actions.getFinancialEstimateViewList(payload);
                localStorage.setItem(
                  "patientName",
                  row.patientFirstName + " " + row.patientLastName
                );
              }}>
              <ArticleIcon />
            </Link>
          )}
        </>
      )
    },
    {
      field: "fullEstimateType",
      headerName: "ESTIMATE TYPE",
      editable: false,
      renderCell: ({ row }) => {
        return <Typography className="word-break">{row.fullEstimateType}</Typography>;
      }
    },
    {
      field: "estimateStatus",
      headerName: "ESTIMATE STATUS",
      editable: false
    },
    {
      field: "treatmentPlanName",
      headerName: "TREATMENT PLAN",
      editable: false,
      renderCell: ({ row }) => <Typography>{row.treatmentPlanName}</Typography>
    },
    {
      field: "estimateCreatedDate",
      headerName: "CREATED DATE",
      type: "date",
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <Typography>{row.createdBy ? row.createdBy : "--"}</Typography>
            <Typography>
              {row.estimateCreatedDate
                ? getLocaleDateTime(
                    row.estimateCreatedDate,
                    "L",
                    true
                  ) /* moment(row.estimateCreatedDate).format("L") */
                : "--"}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "publishedDate",
      headerName: "PUBLISHED DATE",
      type: "date",
      renderCell: ({ row }) => {
        return (
          <Stack direction="column">
            <Typography>{row.publishedBy}</Typography>
            <Typography>
              {row.publishedDate
                ? getLocaleDateTime(
                    row.publishedDate,
                    "L",
                    true
                  ) /* moment(row.publishedDate).format("L") */
                : "--"}
            </Typography>
          </Stack>
        );
      }
    },
    {
      field: "treatmentSelected",
      headerName: "TREATMENT SELECTED",
      renderCell: ({ row }) =>
        row.estimateTreatmentPlans && (
          <Typography>
            {row &&
              row?.estimateTreatmentPlans &&
              row?.estimateTreatmentPlans[0] &&
              row?.estimateTreatmentPlans[0].treatmentSelected}
          </Typography>
        )
    },
    {
      field: "optionSelected",
      headerName: "OPTION SELECTED",
      renderCell: ({ row }) =>
        row.estimateTreatmentPlans && (
          <Typography className="word-break">
            {row &&
            row.estimateTreatmentPlans &&
            row?.estimateTreatmentPlans[0] &&
            row?.estimateTreatmentPlans[0].optionSelected
              ? row?.estimateTreatmentPlans[0]?.optionSelected
              : "--"}
          </Typography>
        )
    },
    {
      field: "programSelectedDate",
      headerName: "SELECTED DATE",
      renderCell: ({ row }) => (
        <Typography>{row.programSelectedDate ? row.programSelectedDate : "--"}</Typography>
      )
    },

    {
      field: "refundApplicationStatus",
      headerName: "REFUND APPLICATION STATUS "
    },
    {
      field: "refundAppStatusDate",
      headerName: "REFUND APPLICATION STATUS DATE",
      type: "date",
      renderCell: ({ row }) =>
        row.estimateTreatmentPlans && (
          <Typography className="word-break">
            {row.refundAppStatusDate
              ? getLocaleDateTime(
                  row.refundAppStatusDate,
                  "L",
                  true
                ) /* moment(row.publishedDate).format("L") */
              : "--"}
          </Typography>
        )
    },
    {
      field: "estimateNotes",
      headerName: "NOTE",
      renderCell: ({ row }) =>
        row.estimateNotes ? (
          <IconButton onClickCapture={() => handleNotesClick(event, row.estimateNotes)}>
            <DescriptionIcon titleAccess="View Details" />
          </IconButton>
        ) : (
          <Typography>--</Typography>
        )
    }
  ];
  const [values, setValues] = useState(defaultValue);
  const [toggle, setToggle] = useState(false);
  const [rows, setRows] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [publishedBy, setPublishedBy] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [estimateNote, setEstimateNote] = useState("");
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [toggleContextbar, setToggleContextbar] = useState(false);
  const prevCountRef = useRef();
  const [selectedPatientId, setSelectedPatientId] = useState(0);
  const [selectedPatientData, setSelectedPatientData] = useState({});
  const [csvData, setCsvData] = useState([]);

  // useEffect(() => {
  //   console.log("profileDetails==>", profileDetails);
  //   console.log("profileDetails==>", localStorage.getItem("practiceId"));
  //   if (
  //     profileDetails.defaultPracticeId &&
  //     (localStorage.getItem("practiceId") === null ||
  //       localStorage.getItem("practiceId") === undefined)
  //   ) {
  //     console.log("zhr profileDetails.defaultPracticeId", profileDetails.defaultPracticeId);
  //     setPracticeId(profileDetails.defaultPracticeId);
  //   }
  // }, [profileDetails]);

  const updateSelectedPatient = ({ row }) => {
    setSelectedPatientId(row.personId);
    setSelectedPatientData(row);
  };
  const setClose = (data) => {
    setSelectedPatientId(data);
    setToggleContextbar(!toggleContextbar);
  };
  useEffect(() => {
    if (inputValue.estimateType) {
      setValues(inputValue);
    }
  }, []);
  useEffect(() => {
    if (!toggleContextbar & (selectedPatientId === prevCountRef.current)) {
      setSelectedPatientId(0);
    }
  }, [toggleContextbar]);
  useEffect(() => {
    prevCountRef.current = selectedPatientId;
  }, [toggleContextbar]);
  useEffect(() => {
    if (estimateList?.length === 0) {
      getList();
    }
  }, [values, practiceId]);

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      estimateCreatedDate: row?.estimateCreatedDate ? new Date(row?.estimateCreatedDate) : "",
      publishedDate: row?.publishedDate ? new Date(row?.publishedDate) : "",
      refundAppStatusDate:
        row?.estimateTreatmentPlans && row?.estimateTreatmentPlans[0]?.refundApplicationStatusDate
          ? new Date(row?.estimateTreatmentPlans[0]?.refundApplicationStatusDate)
          : ""
    }));
  };

  useEffect(() => {
    if (estimateList?.length) {
      let createdBy = new Set(),
        publishedBy = new Set();
      let rows = estimateList.map((item, index) => {
        item.id = index;
        item.patientFullName = item.patientFirstName + " " + item.patientLastName;
        if (item.estimateType == "D") {
          item.fullEstimateType = "Detailed";
        } else if (item.estimateType == "P") {
          item.fullEstimateType = "Preliminary";
        } else {
          item.fullEstimateType = "Not Estimated";
        }
        if (item.estimateTreatmentPlans) {
          item.treatmentPlanName =
            item &&
            item?.estimateTreatmentPlans &&
            item?.estimateTreatmentPlans[0] &&
            item?.estimateTreatmentPlans[0]?.treatmentPlanCustomName != null
              ? item?.estimateTreatmentPlans[0]?.treatmentPlanCustomName
              : item?.estimateTreatmentPlans[0]?.treatmentPlanName;
        }
        item.refundApplicationStatus = item.estimateTreatmentPlans
          ? item &&
            item.estimateTreatmentPlans &&
            item?.estimateTreatmentPlans[0] &&
            item?.estimateTreatmentPlans[0]?.refundApplicationStatus
          : "--";
        if (item?.createdBy) createdBy.add(item?.createdBy);
        if (item?.publishedBy) publishedBy.add(item?.publishedBy);
        return item;
      });
      console.dir(rows);
      setRows(rows);
      const csvDataMode = prepareCsvData(rows);
      console.log("csvDataMode: ", csvDataMode);
      setCsvData(csvDataMode);
      setCreatedBy([...createdBy.values()]);
      setPublishedBy([...publishedBy.values()]);
    } else {
      setCsvData([]);
    }
  }, [loading, estimateList]);

  const toggleFilter = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    console.log("profileDetails==>", practiceId);
    if (practiceId) {
      actions.getFinancialEstimateList({ practiceId, values });
    }
  }, [practiceId]);

  const handleInputChange = (e) => {
    if (e?._d) {
      setValues({
        ...values,
        modifiedDate: moment(e._d).format("YYYY-MM-DD")
      });
    } else {
      const { name, value } = e.target;
      console.log(name, value);
      setValues({
        ...values,
        [name]: value
      });
    }
    actions.resetFinancialEstimateList();
  };

  const handleAutoChange = (e, name, value) => {
    console.log("zhr value:", value);
    console.log("zhr e:", e);
    if (value != null) {
      setValues({
        ...values,
        [value?.name]: value?.value
      });
    } else if (value === null) {
      setValues({
        ...values,
        [name]: ""
      });
    }
    actions.resetFinancialEstimateList();
  };

  const handleSearchClick = (e, searchText) => {
    if (searchText) {
      values.patientNameOrId = searchText;
    }
    setValues({
      ...values
    });
    actions.resetFinancialEstimateList();
  };
  const getList = () => {
    actions.resetFinancialEstimateList();
    setRows([]);
    if (practiceId) {
      console.log("zhr practiceId getList", practiceId);
      actions.getFinancialEstimateList({ practiceId, values });
    }
  };
  const clearSearchClick = () => {
    values.patientNameOrId = "";
    setValues({ ...values });
  };
  const resetFilter = () => {
    console.log("zhr resrt");
    setValues(defaultValue);
    getList();
  };
  const handleNotesClick = (event, note) => {
    setEstimateNote(note);
    setIsOpen(true);
    event.stopPropagation();
  };
  return (
    <>
      {(isAccess("fe") || isAccess("ucron")) && (
        <Box
          className={`${loading ? "financial-directory disable-section" : "financial-directory"}`}>
          <Box className="pad-5">
            <QuickAdd
              text={1}
              search={7}
              handleSearchClick={handleSearchClick}
              clearSearchClick={clearSearchClick}
            />
            <Grid container className="align-center pad-5">
              <Grid item xs={1}>
                <Typography>Estimate Status</Typography>
              </Grid>
              <Grid item xs={3}>
                {/* <Select
                  size="small"
                  flex={3}
                  name="estimateStatus"
                  className="patient-dropdown"
                  value={values?.estimateStatus}
                  onChange={handleInputChange}>
                  {ESTIMATE_STATUS.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select> */}
                <Autocomplete
                  disablePortal
                  required
                  name="estimateStatus"
                  value={values.estimateStatus}
                  options={ESTIMATE_STATUS.map((item, index) => {
                    return {
                      id: index,
                      label: item,
                      name: "estimateStatus",
                      value: item
                    };
                  })}
                  onChange={(e, value) => {
                    handleAutoChange(e, "estimateStatus", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Estimate Status..." sx={{ paddingTop: "7px" }} />
                  )}
                  className="patient-dropdown"
                  autoSelect={false}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography>Estimate Type</Typography>
              </Grid>
              <Grid item xs={3}>
                {/* <Select
                  sx={{ width: "100%" }}
                  size="small"
                  name="estimateType"
                  className="patient-dropdown"
                  value={values?.estimateType}
                  onChange={handleInputChange}>
                  {ESTIMATE_TYPE.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select> */}
                <Autocomplete
                  sx={{ width: "100%" }}
                  disablePortal
                  required
                  name="estimateType"
                  value={
                    values?.estimateType === "D"
                      ? "Detailed Estimate"
                      : values?.estimateType === "No Estimates"
                      ? "Not Estimated"
                      : values?.estimateType === "P"
                      ? "Preliminary Estimate"
                      : ""
                  }
                  options={ESTIMATE_TYPE.map((item, index) => {
                    return {
                      id: index,
                      label: item?.label,
                      name: "estimateType",
                      value: item?.value
                    };
                  })}
                  onChange={(e, value) => {
                    handleAutoChange(e, "estimateType", value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Estimate Type..." sx={{ paddingTop: "7px" }} />
                  )}
                  className="patient-dropdown"
                />
              </Grid>
              <Grid item xs={4}>
                <Stack direction="row" className="align-end">
                  <Button
                    variant="text"
                    disabled={
                      false /*
                      values.estimateStatus == defaultValue.estimateStatus &&
                      values.estimateType == defaultValue.estimateType &&
                      values.dateRange == defaultValue.dateRange &&
                      values.createdBy == defaultValue.createdBy &&
                      values.publishedBy == defaultValue.publishedBy &&
                      values.modifiedDate == defaultValue.modifiedDate
                        ? true
                        : false
                */
                    }
                    color="primary"
                    onClick={resetFilter}>
                    <Typography variant="subtitle1">RESET ALL</Typography>
                  </Button>
                  <Divider sx={{ height: 12, m: "0px 10px" }} orientation="vertical" />
                  <Typography
                    color="primary"
                    className="cursor-pointer"
                    variant="subtitle1"
                    onClick={toggleFilter}>
                    SHOW ADVANCED
                  </Typography>
                  {toggle ? (
                    <KeyboardArrowUpIcon
                      className="cursor-pointer"
                      color="primary"></KeyboardArrowUpIcon>
                  ) : (
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      color="primary"></KeyboardArrowDownIcon>
                  )}
                </Stack>
              </Grid>
            </Grid>
            {toggle && (
              <Box>
                <Grid container className="align-center pad-5">
                  <Grid item xs={1}>
                    <Typography>Modified Date</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        openTo="day"
                        name="modifiedDate"
                        views={["day"]}
                        value={values.modifiedDate}
                        onChange={handleInputChange}
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              disabled: true
                            }}
                            className="patient-dropdown"
                          />
                        )}></DatePicker>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>Date Range</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      sx={{ width: "100%" }}
                      size="small"
                      name="dateRange"
                      value={values?.dateRange}
                      className="patient-dropdown"
                      onChange={handleInputChange}>
                      {DATE_RANGE.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item + " days prior"}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>

                <Grid container className="align-center pad-5">
                  <Grid item xs={1}>
                    <Typography>Created By</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      size="small"
                      className="patient-dropdown"
                      name="createdBy"
                      value={values?.createdBy}
                      onChange={handleInputChange}>
                      {createdBy.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>Published By</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Select
                      sx={{ width: "100%" }}
                      size="small"
                      name="publishedBy"
                      value={values?.publishedBy}
                      onChange={handleInputChange}
                      className="patient-dropdown">
                      {publishedBy.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
          <Box className="grid-style" sx={{ height: "660px" }}>
            <Typography>{rows.length} Estimates matching your criteria</Typography>
            <DataGridPremiumComp
              loading={loading}
              rows={csvData}
              rowCount={rows.length}
              columns={columns}
              className="mt-8"
              onRowClick={(e) => {
                setToggleContextbar(!toggleContextbar);
                updateSelectedPatient(e);
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
              pagination
              sx={{
                ".MuiDataGrid-columnHeaderTitle": {
                  fontFamily: "Economica",
                  fontWeight: "bold",
                  fontSize: "13px"
                }
              }}
            />
          </Box>
          <Grid
            item
            container
            xs={3}
            className={selectedPatientId !== 0 ? "patient-context" : "display-none"}>
            {selectedPatientId !== 0 ? (
              <PatientDemoGraphic
                personId={selectedPatientId}
                close={setClose}
                treatmentPlanName={
                  selectedPatientData?.estimateTreatmentPlans?.[0]?.treatmentPlanCustomName ??
                  selectedPatientData?.estimateTreatmentPlans?.[0]?.treatmentPlanName ??
                  ""
                }
              />
            ) : null}
          </Grid>
          <Modal open={isOpen} backdrop="static">
            <Box className="modal-style">
              <Stack direction="row" className="modal-stack m-t-m10">
                <Typography>NOTE</Typography>
                <IconButton onClick={() => setIsOpen(false)}>
                  <Close size="small" />
                </IconButton>
              </Stack>
              <Divider className="modal-divider" />
              <Typography className="m-t-10">{estimateNote}</Typography>
              <Stack direction="row" spacing={2} className="modal-bottom-section">
                <Button
                  variant="contained"
                  className="btn-primary"
                  onClick={() => setIsOpen(false)}>
                  CLOSE
                </Button>
              </Stack>
            </Box>
          </Modal>
        </Box>
      )}
      <LoadingModal open={getProfileDetailsLoading} />
    </>
  );
};
const mapStateToProps = ({ estimateReducer }) => {
  return {
    estimateList: estimateReducer.estimateList,
    loading: estimateReducer.loading,
    inputValue: estimateReducer.inputValue
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getFinancialEstimateList: (payload) => {
      dispatch(actionType.getFinancialEstimateList(payload));
    },
    resetFinancialEstimateList: () => {
      dispatch(actionType.resetFinancialEstimateList());
    },
    getFinancialEstimateViewList: (payload) => {
      dispatch(actionType.getFinancialEstimateViewList(payload));
    },
    inputValues: (payload) => {
      dispatch(actionType.inputValues(payload));
    },
    resetEstimateViewList: () => {
      dispatch(actionType.resetEstimateViewList());
    },
    setEstimateId: (payload) => {
      dispatch(actionType.setEstimateId(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(FinancialDirectory);
