import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchManualRefundList(action) {
  const { payload } = action;

  try {
    const requestBody = createRequestBody(
      "post",
      `/medcases/${payload.medcaseId}/transactions/refunds`,
      "",
      payload.value
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.MANUAL_REFUND_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.MANUAL_REFUND_FAILED, errorMsg: error.response.data });
    }
  }
}
function* getManualRefundListSaga() {
  yield takeEvery(actionTypes.MANUAL_REFUND_REQUESTED, fetchManualRefundList);
}
export default function* ManualRefundListSaga() {
  yield all([getManualRefundListSaga()]);
}
