import * as actionType from "../actions/ActionTypes";

const initialState = {
  multiplierList: [],
  loading: false,
  addMultiplier: null,
  editMultiplier: null,
  deleteMultiplier: null,
  error: null,
  success: null,
  programMultiplier: []
};

const multiplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.MULTIPLIER_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.MULTIPLIER_LIST_SUCCESS: {
      return {
        ...state,
        multiplierList: action.payload,
        loading: false
      };
    }
    case actionType.MULTIPLIER_LIST_FAIL: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.ADD_MULTIPLIER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_MULTIPLIER_SUCCESS: {
      return {
        ...state,
        addMultiplier: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_MULTIPLIER_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.EDIT_MULTIPLIER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_MULTIPLIER_SUCCESS: {
      return {
        ...state,
        editMultiplier: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_MULTIPLIER_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.DELETE_MULTIPLIER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_MULTIPLIER_SUCCESS: {
      return {
        ...state,
        deleteMultiplier: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_MULTIPLIER_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    case actionType.RESET_MULTIPLIER: {
      return {
        ...state,
        addMultiplier: null,
        editMultiplier: null,
        deleteMultiplier: null,
        loading: null
      };
    }

    case actionType.RESET_MULTIPLIER_LIST: {
      return {
        ...state,
        multiplierList: [],
        loading: null
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    case actionType.GET_PROGRAM_MULTIPLIER_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_PROGRAM_MULTIPLIER_SUCCESS: {
      return {
        ...state,
        programMultiplier: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.GET_PROGRAM_MULTIPLIER_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default multiplierReducer;
