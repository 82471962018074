import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import { Radio } from "@mui/material";

const RadioField = ({ label, mandatory, value, ...props }) => {
  return (
    <>
      <Stack direction="row" className="modal-stack">
        {label ? (
          <InputLabel required={mandatory} className="flex-2 word-break">
            {label}
          </InputLabel>
        ) : null}
        <Radio
          size="small"
          sx={{ flex: 6 }}
          value={value}
          // onInvalid={(e) => e.target.setCustomValidity(message)}
          checked={value}
          {...props}
        />
      </Stack>
    </>
  );
};

export default RadioField;
