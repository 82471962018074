import * as actionType from "../actions/ActionTypes";

const initialState = {
  finSnapshotReportList: [],
  loading: false,
  error: null,
  success: null
};
const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_FINSNAPSHOT_REPORT_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.GET_FINSNAPSHOT_REPORT_SUCCESS: {
      return {
        ...state,
        finSnapshotReportList: action.payload,
        loading: false
      };
    }
    case actionType.GET_FINSNAPSHOT_REPORT_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default reportReducer;
