import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import { Box, Button } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const AccordianWDataGrid = (props) => {
  const accordionSx = { bgcolor: "#ededed", m: 2 };
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  return (
    <Accordion sx={accordionSx}>
      <AccordionSummary
        sx={{ flexDirection: "row-reverse", flexGrow: 0 }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography>
          {props.accordianName} ({props.rows?.length})
          <Button
            variant="contained"
            onClick={(e) => props.handleAdd(e, "Stages Included", "Stages")}
            disabled={props.disabled}
            className="btn-primary tab-panel-btnn">
            {props.btnName}
          </Button>
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <TableContainer component={Paper}>
          <Box className="grid-styles">
            <DataGridPremiumComp
              rows={props.rows}
              loading={props.loading}
              rowCount={props.rows.length}
              pagination
              columns={props.columns}
              getRowId={() => uuidv4()}
              pageSizeOptions={[10, 25, 50, 100]}
              pageSize={pageSize.pageSize}
              paginationModel={pageSize}
              onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </Box>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordianWDataGrid;
