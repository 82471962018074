import * as actionType from "../actions/ActionTypes";

const initialState = {
  quickAddData: [],
  addQuickAdd: null,
  editQuickAdd: null,
  deleteQuickAdd: null,
  quickAddUserList: [],
  loading: false,
  error: null,
  success: null
};

const quickAddReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.QUICK_ADD_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.QUICK_ADD_LIST_SUCCESS: {
      return {
        ...state,
        quickAddData: action.payload,
        loading: false
      };
    }
    case actionType.QUICK_ADD_LIST_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.ADD_NEW_QUICK_ADD_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_NEW_QUICK_ADD_SUCCESS: {
      return {
        ...state,
        addQuickAdd: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_NEW_QUICK_ADD_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.EDIT_QUICK_ADD_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_QUICK_ADD_SUCCESS: {
      return {
        ...state,
        editQuickAdd: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_QUICK_ADD_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.DELETE_QUICK_ADD_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.DELETE_QUICK_ADD_SUCCESS: {
      return {
        ...state,
        deleteQuickAdd: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.DELETE_QUICK_ADD_FAIL: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_QUICK_ADD_LIST: {
      return {
        ...state,
        addQuickAdd: null,
        editQuickAdd: null,
        loading: false
      };
    }
    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: null
      };
    }
    case actionType.USER_ID_QUICK_ADD_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.USER_ID_QUICK_ADD_LIST_SUCCESS: {
      return {
        ...state,
        quickAddUserList: action.payload,
        loading: false
      };
    }
    case actionType.USER_ID_QUICK_ADD_LIST_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.USER_ID_ADD_NEW_QUICK_ADD_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.USER_ID_ADD_NEW_QUICK_ADD_SUCCESS: {
      return {
        ...state,
        addQuickAdd: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.USER_ID_ADD_NEW_QUICK_ADD_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    case actionType.USER_ID_EDIT_QUICK_ADD_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.USER_ID_EDIT_QUICK_ADD_SUCCESS: {
      return {
        ...state,
        editQuickAdd: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.USER_ID_EDIT_QUICK_ADD_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.USER_ID_DELETE_QUICK_ADD_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.USER_ID_DELETE_QUICK_ADD_SUCCESS: {
      return {
        ...state,
        deleteQuickAdd: action.payload,
        success: action.successMsg,
        loading: false
      };
    }
    case actionType.USER_ID_DELETE_QUICK_ADD_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.errorMsg
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default quickAddReducer;
