import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-premium";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import * as actionType from "../actions";
import MessageModal from "../../shared/components/MessageModal";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const Questions = (props) => {
  const [importQstn, setImportQstn] = useState(false);
  const practiceId = sessionStorage.getItem("practiceId");
  const gdmId = sessionStorage.getItem("gdmId");
  //const disabled = localStorage.getItem("status") !== "In-progress" ? true : false;
  const disabled = false;
  const [rows, setRows] = useState([]);
  const [editRows, setEditRows] = useState([]);
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const [isMessageModal, setIsMessageModal] = useState(false);

  const { questonList = [], actions, saveQuestion, loading, success, error } = props;

  const initialRows = questonList.map((item, index) => {
    item.id = index;
    return item;
  });
  useEffect(() => {
    setRows(initialRows);
    setRows((prevRows) =>
      prevRows.map((row) => ({ ...row, required: row.required ? "Yes" : "No" }))
    );
  }, [loading, saveQuestion]);

  const initFetch = useCallback(() => {
    let payload = { practiceId, gdmId };
    actions.getEstimateQuestions(payload);
  }, []);

  useEffect(() => {
    if (success || error) {
      setIsMessageModal(true);
    }
    if (questonList.length == 0 || saveQuestion) {
      initFetch();
    }
    return () => {
      actions.resetQuestions();
    };
  }, [saveQuestion]);

  const supress = useCallback(
    (params) => () => {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === params.id ? { ...row, supressed: true } : row))
      );

      let updatedRows = {
        questionId: params.row.questionId,
        questionLabel: params.row.questionLabel,
        isRequired: params.row.required === "Yes" ? true : false,
        responseNo: params.row.responseNo,
        isSupressed: true
      };

      if (editRows.length == 0) {
        setEditRows((prev) => [...prev, updatedRows]);
      } else {
        if (editRows.some((a) => a.responseNo != params.row.responseNo)) {
          setEditRows((prev) => [...prev, updatedRows]);
        }
      }
    },
    [rows, editRows]
  );
  const unSupress = useCallback(
    (params) => () => {
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === params.id ? { ...row, supressed: false } : row))
      );

      let updatedRows = {
        questionId: params.row.questionId,
        questionLabel: params.row.questionLabel,
        isRequired: params.row.isRequired === "Yes" ? true : false,
        responseNo: params.row.responseNo,
        isSupressed: false
      };

      if (editRows.length == 0) {
        setEditRows((prev) => [...prev, updatedRows]);
      } else {
        if (editRows.some((a) => a.responseNo != params.row.responseNo)) {
          setEditRows((prev) => [...prev, updatedRows]);
        }
      }

      // let responseNo = rows.filter((item) => {
      //   return item.responseNo === params.row.responseNo;
      // });
      // if (responseNo.length) {
      //   setEditRows((prevRows) =>
      //     prevRows.map((row) =>
      //       row.responseNo === params.row.responseNo
      //         ? {
      //             ...row,
      //             questionId: row.questionId,
      //             questionLabel: row.questionLabel,
      //             isRequired: row.isRequired === "Yes" ? true : false,
      //             responseNo: row.responseNo,
      //             isSupressed: false
      //           }
      //         : row
      //     )
      //   );
      // } else {
      //   setEditRows((prev) => [...prev, updatedRows]);
      // }
    },
    [rows, editRows]
  );

  const supressAll = useCallback(
    (params) => () => {
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.questionLabel === params.row.questionLabel ? { ...row, supressed: true } : row
        )
      );

      let supressAllEdited = rows
        .filter((item) => {
          return item.questionLabel === params.row.questionLabel;
        })
        .map((item) => {
          item.isRequired = item.required;
          delete item.queRespMappingId;
          delete item.questionText;
          delete item.responseText;
          delete item.responseType;
          delete item.treatmentType;
          delete item.treatmentTypeId;
          delete item.required;
          delete item.supressed;
          delete item.id;

          return item;
        });

      setEditRows(supressAllEdited);
      setEditRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          questionId: row.questionId,
          questionLabel: row.questionLabel,
          isRequired: row.isRequired === "Yes" ? true : false,
          responseNo: row.responseNo,
          isSupressed: true
        }))
      );
    },
    [rows, editRows]
  );

  const unSupressAll = useCallback(
    (params) => () => {
      setRows((prevRows) =>
        prevRows.map((row) =>
          row.questionLabel === params.row.questionLabel ? { ...row, supressed: false } : row
        )
      );

      let unSupressAllEdited = rows
        .filter((item) => {
          return item.questionLabel === params.row.questionLabel;
        })
        .map((item) => {
          item.isRequired = item.required;
          delete item.queRespMappingId;
          delete item.questionText;
          delete item.responseText;
          delete item.responseType;
          delete item.treatmentType;
          delete item.treatmentTypeId;
          delete item.required;
          delete item.supressed;
          delete item.id;

          return item;
        });

      setEditRows(unSupressAllEdited);
      setEditRows((prevRows) =>
        prevRows.map((row) => ({
          ...row,
          questionId: row.questionId,
          questionLabel: row.questionLabel,
          isRequired: row.isRequired === "Yes" ? true : false,
          responseNo: row.responseNo,
          isSupressed: false
        }))
      );
    },
    [rows, editRows]
  );

  const handleChange = useCallback(
    (params) => (event) => {
      const { value, name } = event.target;
      setRows((prevRows) =>
        prevRows.map((row) => (row.id === params.id ? { ...row, [name]: value } : row))
      );

      let updatedRows = {
        questionId: params.row.questionId,
        questionLabel: params.row.questionLabel,
        isRequired: value === "Yes" ? true : false,
        responseNo: params.row.responseNo,
        isSupressed: params.row.supressed
      };

      let responseNo = editRows.filter((item) => {
        return item.responseNo === params.row.responseNo;
      });
      if (responseNo.length) {
        setEditRows((prevRows) =>
          prevRows.map((row) =>
            row.responseNo === params.row.responseNo
              ? {
                  ...row,
                  questionId: row.questionId,
                  questionLabel: row.questionLabel,
                  isRequired: value === "Yes" ? true : false,
                  responseNo: row.responseNo,
                  isSupressed: row.isSupressed
                }
              : row
          )
        );
      } else {
        setEditRows((prev) => [...prev, updatedRows]);
      }
      params.api.setCellMode(params.id, params.field, "view");
      event.stopPropagation();
    },
    [editRows]
  );

  const handleChangeQuestions = useCallback(
    (params) => (event) => {
      event.preventDefault();
      const { value, name } = event.target;

      setRows((prevRows) =>
        prevRows.map((row) => (row.id === params.id ? { ...row, [name]: value } : row))
      );

      if (value.length > 1) {
        setTimeout(() => {
          let updatedRows = {
            questionId: params.row.questionId,
            questionLabel: value,
            isRequired: params.row.required === "Yes" ? true : false,
            responseNo: params.row.responseNo,
            isSupressed: params.row.supressed
          };
          let responseNo = editRows.filter((item) => {
            return item.responseNo === params.row.responseNo;
          });

          if (responseNo.length) {
            setEditRows((prevRows) =>
              prevRows.map((row) =>
                row.responseNo === params.row.responseNo
                  ? {
                      ...row,
                      questionId: row.questionId,
                      questionLabel: value,
                      isRequired: row.isRequired === "Yes" ? true : false,
                      responseNo: row.responseNo,
                      isSupressed: row.isSupressed
                    }
                  : row
              )
            );
          } else {
            setEditRows((prev) => [...prev, updatedRows]);
          }
          params.api.setCellMode(params.id, params.field, "view");
        }, 1500);
      } else {
        params.api.setCellMode(params.id, params.field, "edit");
      }
    },
    [rows, editRows]
  );

  const columns = useMemo(
    () => [
      {
        field: "questionLabel",
        headerName: "QUESTION LABEL",
        width: 100,
        flex: 0.5,
        editable: disabled ? false : true,
        renderEditCell: (params) => {
          return (
            <>
              <TextField
                size="small"
                name="questionLabel"
                value={params.row.questionLabel}
                onChange={handleChangeQuestions(params)}
              />
            </>
          );
        }
      },
      {
        field: "treatmentType",
        headerName: "TREATMENT TYPE",
        width: 100,
        editable: false,
        flex: 0.5
      },
      {
        field: "treatmentTypeId",
        headerName: "TREATMENT TYPE ID",
        width: 100,
        editable: false,
        flex: 0.5
      },
      {
        field: "questionId",
        headerName: "QUESTION ID",
        width: 100,
        editable: false,
        flex: 0.6
      },
      {
        field: "questionText",
        headerName: "QUESTION TEXT",
        editable: false,
        flex: 1
      },
      {
        field: "required",
        headerName: "REQUIRED",
        editable: disabled ? false : true,
        flex: 0.5,
        renderEditCell: (params) => {
          return (
            <>
              <Select
                size="small"
                name="required"
                value={params.row.required}
                onChange={handleChange(params)}>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </>
          );
        }
      },
      {
        field: "responseType",
        headerName: "RESPONSE TYPE",
        width: 100,
        editable: false,
        flex: 0.5
      },
      {
        field: "responseNo",
        headerName: "RESPONSE ID",
        width: 100,
        editable: false,
        flex: 0.5
      },
      {
        field: "responseText",
        headerName: "RESPONSE TEXT",
        width: 100,
        editable: false,
        flex: 0.8
      },
      {
        field: "supressed",
        headerName: "SUPRESS",
        width: 100,
        editable: false,
        flex: 0.5
      },
      {
        field: "actions",
        type: "actions",
        headerName: "ACTION",
        width: 100,
        flex: 0.5,
        getActions: (params) => [
          <GridActionsCellItem
            key={1}
            //disabled={disabled}
            label="Supress response"
            showInMenu
            onClick={supress(params)}
          />,
          <GridActionsCellItem
            key={2}
            // disabled={disabled}
            onClick={unSupress(params)}
            label="Unsupress response"
            showInMenu
          />,
          <GridActionsCellItem
            key={3}
            //disabled={disabled}
            onClick={supressAll(params)}
            label="Supress all responses"
            showInMenu
          />,
          <GridActionsCellItem
            key={4}
            // disabled={disabled}
            onClick={unSupressAll(params)}
            label="Unsupress all responses"
            showInMenu
          />
        ]
      }
    ],
    [supress, unSupress, supressAll, unSupressAll]
  );

  const saveQuestions = (e) => {
    e.preventDefault();
    let payload = { practiceId, gdmId, editRows };
    actions.saveEstimateQuestions(payload);
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
    setEditRows([]);
  };

  useEffect(() => {
    if (success || error) {
      setIsMessageModal(true);
    }
  }, [error]);

  return (
    <>
      <Box className={`${loading ? "m-r-10 m-l-10 disable-section" : "m-r-10 m-l-10"}`}>
        <Stack direction="row" className="grid-header">
          <Typography>Question List</Typography>
          <Stack direction="row">
            <Button
              variant="contained"
              disabled={disabled}
              sx={{ mx: 1 }}
              onClick={() => {
                setImportQstn(true);
              }}>
              Import Questions
            </Button>
            <Button
              variant="contained"
              disabled={disabled || editRows.length == 0}
              onClick={saveQuestions}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box
        className={`${loading ? "grid-style disable-section" : "grid-style"}`}
        sx={{ height: "660px" }}>
        <DataGridPremiumComp
          className="m-r-10 m-l-10"
          rows={rows}
          rowCount={rows.length}
          columns={columns}
          loading={loading}
          pageSizeOptions={[10, 25, 50, 100]}
          pageSize={pageSize.pageSize}
          paginationModel={pageSize}
          onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
          pagination
          getRowHeight={() => "auto"}
        />
      </Box>
      <Modal open={importQstn} backdrop="static" aria-labelledby="modal-add-title">
        <Box component="form" className="modal-style">
          <Stack direction="row" className="modal-stack">
            <Typography>Import Question</Typography>
            <IconButton
              onClick={() => {
                setImportQstn(false);
              }}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="column" className="modal-stack">
            <Typography>Feature Coming Soon</Typography>
          </Stack>
        </Box>
      </Modal>

      <MessageModal
        open={isMessageModal}
        message={success}
        error={error}
        closeModal={closeMessageModal}
      />
    </>
  );
};

const mapStateToProps = ({ questionsReducer }) => {
  return {
    loading: questionsReducer.loading,
    questonList: questionsReducer.questonList,
    saveQuestion: questionsReducer.saveQuestions,
    success: questionsReducer.success,
    error: questionsReducer.error
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getEstimateQuestions: (payload) => {
      dispatch(actionType.getEstimateQuestions(payload));
    },
    saveEstimateQuestions: (payload) => {
      dispatch(actionType.saveEstimateQuestions(payload));
    },
    resetQuestions: () => {
      dispatch(actionType.resetQuestions());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Questions);
