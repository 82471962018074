import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { PropTypes } from "prop-types";
import { Stack } from "@mui/material";
import isAccess from "../../shared/components/CheckUserRights";

const ListItemLink = (props) => {
  const { icon, primary, onClick, selected, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <ListItem
      selected={selected}
      button
      component={renderLink}
      disablePadding
      sx={{
        minHeight: "70px"
      }}
      onClick={onClick}>
      <Stack direction="row">
        {icon ? <ListItemIcon sx={{ ml: 2, minWidth: "0" }}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </Stack>
    </ListItem>
  );
};

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onclick: PropTypes.func,
  selected: PropTypes.bool.isRequired
};

const PracticeConfiguration = (props) => {
  const location = useLocation();
  const practiceConfig = [
    {
      title: "PRACTICE PAYMENT TIMING",
      url: "/admin/practice-payment",
      text: "Practice payment timing",
      icon: "/Assets/payment.png",
      screenRights: "prpm"
    },
    {
      title: "PRACTICE",
      url: "/admin/practice",
      text: "Practice",
      icon: "/Assets/practice.png",
      screenRights: "pr"
    },
    {
      title: "GLOBAL DATA MODEL",
      url: "/admin/gdm",
      text: "Global Data Model",
      icon: "/Assets/payment.png",
      screenRights: "gdm"
    },
    {
      title: "PROGRAM VISUALIZATION",
      url: "/admin/program-visualization",
      text: "Program Visualzation",
      icon: "/Assets/program.png",
      screenRights: "pgv"
    },
    {
      title: "REFUND AGE",
      url: "/admin/refund-age",
      text: "Refund Age",
      icon: "/Assets/program.png",
      screenRights: "rfagc"
    },
    {
      title: "PHYSICIAN REIMBURSEMENT SCALE",
      url: "/admin/physician-reimbursement-scale",
      text: "Physician Reimbursement Scale",
      icon: "/Assets/program.png",
      screenRights: "phrmsc"
    },
    {
      title: "RELATIVE VALUE",
      url: "/admin/PracticeConfiguration/relativeValue",
      text: "Relative Value",
      icon: "/Assets/program.png",
      screenRights: "rlvltl"
    },
    {
      title: "PGTA",
      url: "/admin/PGTA",
      text: "PGTA",
      icon: "/Assets/program.png",
      screenRights: "pgtalc"
    },
    {
      title: "USER TAB",
      url: "/admin/user-tab",
      text: "User Roles",
      icon: "/Assets/payment.png",
      screenRights: "ucron"
    },
    {
      title: "CONTRACT TEMPLATE MAPPING",
      url: "/admin/Contract-Template-Mapping",
      text: "User Roles",
      icon: "/Assets/payment.png",
      screenRights: "contract"
    },
    {
      title: "EMAIL TEMPLATE",
      url: "/admin/email-template",
      text: "User Roles",
      icon: "/Assets/payment.png",
      screenRights: "contract"
    },
    {
      title: "PGTA LABS",
      url: "/admin/pgta-labs",
      text: "User Roles",
      icon: "/Assets/payment.png",
      screenRights: "contract"
    }
  ];

  const selectedPath = practiceConfig.findIndex((item) => item.url === location.pathname);

  const [selectedIndex, setSelectedIndex] = useState(selectedPath);

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };
  return (
    <Box className="sidebar-container">
      <List disablePadding>
        {practiceConfig.map(
          ({ title, text, url, icon, screenRights }, index) =>
            isAccess(screenRights) && (
              <ListItemLink
                key={index}
                selected={selectedIndex === index}
                onClick={() => {
                  handleListItemClick(index);
                  props.getSelectedConfig(text);
                }}
                to={url}
                primary={title}
                icon={<img src={icon} alt="" />}
              />
            )
        )}
      </List>
    </Box>
  );
};

export default PracticeConfiguration;
