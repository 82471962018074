import { useState, useEffect } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  Tab,
  Typography
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { connect } from "react-redux";
import * as actionType from "../actions";
import { calculateAge, getGengerFullName } from "../../shared/constants/constants";

const PatientDemoGraphic = (props) => {
  const { personId, partnerDetails = {}, actions, personalData = {}, treatmentPlanName } = props;
  const [value, setValue] = useState("2");
  const [patientData, setPatientData] = useState({});
  const [partnerData, setPartnerData] = useState({});
  const practiceId = localStorage.getItem("practiceId");
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (personId) {
      actions.resetPartnerData();
      let payload = {
        practiceId,
        personId: personId
      };
      console.log("Inside patientDemoGraphics: ", personId);
      actions.getPersonalData(payload);
    }
  }, [personId]);
  useEffect(() => {
    if (Object.values(personalData).length) {
      setPatientData(personalData);
      if (personalData.activePartner !== null) {
        let payload = {
          practiceId,
          partnerId: personalData.activePartner.partnerId
        };
        actions.getPartnerData(payload);
      }
    }
  }, [personalData]);
  useEffect(() => {
    // if (Object.values(partnerDetails).length) {

    // } else {
    //   setPartnerData({});
    // }
    setPartnerData(partnerDetails);
  }, [partnerDetails]);
  const handleClose = () => {
    props.close(0);
  };

  return (
    <Box sx={{ mx: 1, width: "100%" }}>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <Box className="mt-17">
          <IconButton onClick={handleClose}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Box>
      </Stack>
      <Box sx={{ p: 1, background: "#EFEFEF" }}>
        <Stack direction="row" gap={2} flex={1}>
          <img alt="" src="/Assets/user_icon.png" />
          <Stack direction="column">
            <Stack direction="column" gap={1}>
              <Link variant="contained" to={`/patients/${personId}/personal`}>
                <Button
                  color="primary"
                  variant="text"
                  style={{ fontWeight: "bold", fontSize: "18px", textDecoration: "underline" }}>
                  {patientData.firstName} {patientData.lastName}
                </Button>
              </Link>
              <InputLabel>ID# {patientData.personKey}</InputLabel>

              <InputLabel>Treatment Plan:- {treatmentPlanName}</InputLabel>
            </Stack>
          </Stack>
        </Stack>
        <Grid container py={1}>
          <Grid item xs={6}>
            <InputLabel>
              Partner ({partnerData?.gender ? getGengerFullName(partnerData.gender) : "-"})
            </InputLabel>
          </Grid>
          <Grid item xs={1}>
            <SyncAltIcon color="primary" />
          </Grid>
          <Grid item xs={5} px={2}>
            {partnerData ? (
              <Stack>
                <Typography flex={1}>
                  {(partnerData && partnerData.firstName) ||
                    ("" + " " + partnerData && partnerData.lastName) ||
                    ""}
                </Typography>
                <Typography>ID# {(partnerData && partnerData.personKey) || ""}</Typography>
              </Stack>
            ) : (
              "--"
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={7}>
            <InputLabel>Sex | Age(DOB)</InputLabel>
          </Grid>
          <Grid item xs={5} px={2}>
            {patientData && (
              <Stack direction="column">
                <Typography>
                  {getGengerFullName(patientData.gender)} | {calculateAge(patientData.birthDate)}
                </Typography>
                <Typography>({patientData.birthDate})</Typography>
              </Stack>
            )}
          </Grid>
        </Grid>

        <Grid container py={1}>
          <Grid item xs={7}>
            <InputLabel>Preferred Phone</InputLabel>
          </Grid>
          <Grid item xs={5} px={2}>
            {personalData && <Typography flex={1}>{personalData.cellPhone}</Typography>}
          </Grid>
        </Grid>
        <Grid container py={1}>
          <Grid item xs={7}>
            <InputLabel>Primary MD</InputLabel>
          </Grid>
          <Grid item xs={5} px={2}>
            {patientData && (
              <Typography flex={1}>
                {patientData.ivfMDFirstName + " " + patientData.ivfMDLastName}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container py={1}>
          <Grid item xs={4}>
            <InputLabel>Primary Email</InputLabel>
          </Grid>
          <Grid item xs={8} px={2}>
            {patientData && <Typography flex={1}>{patientData.email}</Typography>}
          </Grid>
        </Grid>
      </Box>
      <Box flexDirection="row" sx={{ p: 1, background: "#F9F9F9" }}>
        <TabContext value={value}>
          <Box>
            <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <TabList aria-label="lab API tabs example" onChange={handleChange}>
                <Tab label="TASK" value="1" />
                <Tab label="ACTION" value="2" />
              </TabList>
            </Stack>
          </Box>
          <TabPanel value="1" sx={{ padding: 0 }}>
            <Box sx={{ ml: 1 }}>
              <Stack flexDirection="column">
                <FormControlLabel control={<Checkbox />} label="Financial Program discussion" />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Treatment Options under considerations"
                />
                <FormControlLabel
                  control={<Checkbox />}
                  label="Financial Program under considerations"
                />
              </Stack>
            </Box>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: 0 }}>
            <Box sx={{ m: 1 }}>
              <Stack flexDirection="column" gap={1}>
                <Button
                  variant="contained"
                  onClick={() => {
                    localStorage.setItem(
                      "patientName",
                      patientData.firstName + " " + patientData.lastName
                    );
                    actions.resetEstimateViewList();
                    navigate(`/workspace/financial-estimates/${patientData.personId}?PT=PD`);
                  }}>
                  Create a Financial Estimate
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate("/workspace/fertility-access-program");
                  }}>
                  Fertility Access Program
                </Button>
              </Stack>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};
const mapStateToProps = ({ estimateReducer }) => {
  return {
    partnerDetails: estimateReducer.partnerDetails,
    personalData: estimateReducer.personalData.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPartnerData: (payload) => {
      dispatch(actionType.getPartnerData(payload));
    },
    getPersonalData: (payload) => {
      dispatch(actionType.getPersonalData(payload));
    },
    resetPartnerData: () => {
      dispatch(actionType.resetPartnerData());
    },
    resetEstimateViewList: () => {
      dispatch(actionType.resetEstimateViewList());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientDemoGraphic);
