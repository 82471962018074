import * as actionType from "../actions/ActionTypes";
const initialState = {
  programName: [],
  loading: false,
  error: null
};
const programNameReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PROGRAM_NAME_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.PROGRAM_NAME_SUCCESS: {
      // console.log("action.payload", action.payload);
      return {
        ...state,
        programName: action.payload,
        loading: false
      };
    }
    case actionType.PROGRAM_NAME_FAILED: {
      return {
        ...state,
        error: action.errorMsg,
        loading: false
      };
    }
    case actionType.RESET_PROGRAM: {
      return {
        ...state,
        error: null,
        success: false
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default programNameReducer;
