import {
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Link,
  Typography,
  IconButton
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import EstimateCardDetails from "./EstimateCardDetails";
import FapView from "./FapView";
import * as actionType from "../actions";
import { useNavigate, useParams } from "react-router";
import DeleteModal from "../../shared/components/DeleteModal";
import MessageModal from "../../shared/components/MessageModal";

const EstimateCards = (props) => {
  const {
    data,
    actions,
    adjustments,
    loading,
    estimate,
    cardClickAction,
    fapLoading,
    openCost,
    isNewInserted,
    patientDetails,
    setEstimateCard,
    deleteEstimateTreatmentPlan,
    errorMsg,
    successMsg,
    deleteEstimateLoading,
    fapList
  } = props;

  const [selectedCard, setSelectedCard] = useState({});
  const [deleteItem, setDeleteItem] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const navigate = useNavigate();
  const { personId } = useParams();
  const practiceId = localStorage.getItem("practiceId");
  const [tpPrice, setPrice] = useState(0);
  const [planName, setPlanName] = useState("");
  const [costData, setCostData] = useState([]);
  // const [mcpShowEstimate, setMcpShowEstimate] = useState(true);
  let cardIndex;
  useEffect(() => {
    actions.resetCardClick();
  }, [loading]);
  const cardClick = (item, tpLinkId, price, name, value, index) => {
    setSelectedCard({});
    setEstimateCard(item);
    setCostData([]);
    actions.resetUpdateAdjustments();
    actions.cardClickedAction();
    actions.resetMessage();
    cardIndex = index;
    const selected = value == "pgta" ? item.linkedEstimateTreatmentPlan : item;
    setSelectedCard(selected);
    let tpPrice = tpLinkId ? item.linkedEstimateTreatmentPlan.tpPrice : item.tpPrice;
    let planName = tpLinkId
      ? item.linkedEstimateTreatmentPlan.treatmentPlanName
      : item.treatmentPlanName;

    let cardPayload = tpLinkId
      ? {
          practiceId,
          gdmId: value == "pgta" ? item.linkedEstimateTreatmentPlan.gdmId : item.gdmId,
          treatmentPlanId:
            value == "pgta"
              ? item.linkedEstimateTreatmentPlan.treatmentPlanId
              : item.treatmentPlanId
        }
      : { practiceId, gdmId: item.gdmId, treatmentPlanId: item.treatmentPlanId };

    let payload = {
      estimateId: estimate.estimateId,
      estimateTpId: item.finEstimateTpId
    };
    setPrice(price ? price : tpPrice);
    setPlanName(name ? name : planName);

    setCostData((prev) => [...prev, selected]);

    actions.getStages(cardPayload);
    actions.getOptional(cardPayload);
    actions.getNecessary(cardPayload);
    actions.getAdjustments(payload);
    if (item.treatmentType.toString().toLowerCase().includes("ivf")) {
      actions.getFertilityAccessPrograms({ ...payload, practiceId });
    }
  };
  // const getFapVisibilityData = (status) => {
  //   // setMcpShowEstimate(status);
  //   // console.log(mcpShowEstimate);
  // };
  const handleRefundClick = (event, estimate, item) => {
    event.stopPropagation();
    event.preventDefault();
    actions.FapRowData({
      estimateType: estimate.estimateType,
      estimateStatus: estimate.estimateStatus,
      estimateDescription: estimate.estimateDescr,
      estimateId: estimate.estimateId,
      estimateTpId: item.finEstimateTpId
    });
    navigate(`/workspace/fertility-access-program/${personId}`);
  };
  useEffect(() => {
    if (data && data.length > 0) {
      let item = data && data[0];

      if (isNewInserted) {
        item = data && data[data.length - 1];
      }

      if (item.tpLinkId && item.treatmentType.includes("IVF")) {
        cardClick(item, item.tpLinkId, item.tpPrice, item.treatmentPlanName, "nonPgta");
      } else if (item.tpLinkId) {
        cardClick(
          item,
          item.tpLinkId,
          item.linkedEstimateTreatmentPlan.tpPrice,
          item.linkedEstimateTreatmentPlan.treatmentPlanName,
          "pgta",
          0
        );
      } else {
        cardClick(item, item.tpLinkId);
      }
    }
  }, [data, isNewInserted]);

  useEffect(() => {
    if (deleteItem) {
      if (successMsg || errorMsg) {
        setIsMessageModal(true);
      }
      if (deleteEstimateTreatmentPlan && successMsg) {
        let payload = {
          practiceId,
          personId
        };
        actions.getFinancialEstimateViewList(payload);
      }
    }
  }, [deleteEstimateTreatmentPlan, errorMsg, successMsg]);

  const showRefundApplicationBtton = (cardData) => {
    const patientDetail = patientDetails && patientDetails[0];
    const patientAge = patientDetail && Math.floor(patientDetail.patientAge);
    const donorMinAge = patientDetail && patientDetail.donorMinAge;
    const donorMaxAge = patientDetail && patientDetail.donorMaxAge;
    const nonDonorMinAge = patientDetail && patientDetail.nonDonorMinAge;
    const nonDonorMaxAge = patientDetail && patientDetail.nonDonorMaxAge;
    const primaryQuestionId = cardData && cardData.questionId; //  Question ID should be  : 2
    const primaryQuestionresponse = cardData && cardData.responseNo; //yes = 1, No = 2;
    // const primaryQuestionId = data && data[0].questionId; //  Question ID should be  : 2
    // const primaryQuestionresponse = data && data[0].responseNo; //yes = 1, No = 2;
    const estimateStatus = estimate.estimateStatus; //In-Progress"
    let isButtonVisiable = false;

    if (
      cardData &&
      cardData.refundApplicationStatus == null &&
      cardData.treatmentType.toString().toLowerCase().includes("ivf") &&
      fapList &&
      fapList.programVisualization &&
      fapList.programVisualization.length > 0
    ) {
      if (primaryQuestionId === 2 && estimateStatus === "In-Progress") {
        if (primaryQuestionresponse === 1) {
          // Yes Case
          if (patientAge >= donorMinAge && patientAge <= donorMaxAge) {
            isButtonVisiable = true;
          } else {
            isButtonVisiable = false;
          }
        } else {
          // No Case
          if (patientAge >= nonDonorMinAge && patientAge <= nonDonorMaxAge) {
            isButtonVisiable = true;
          } else {
            isButtonVisiable = false;
          }
        }
      }
    }

    // OLD CODE BAKCUP
    // estimate.estimateStatus == "In-Progress" &&
    //                         item.refundApplicationStatus === null &&
    //                         item.treatmentPlanName.indexOf("IVF") != -1 &&
    //                         estimate.estimateType == "D"

    return isButtonVisiable;
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    let initialData = {
      estimateId: estimate.estimateId,
      estimateTpId:
        deleteItem && deleteItem.isLinked
          ? deleteItem.linkedEstimateTreatmentPlan.finEstimateTpId
          : deleteItem?.finEstimateTpId
    };
    actions.deleteEstimateTreatmentPlan(initialData);

    closeDeleteModal();
  };

  const isBundledSaving = (type) => {
    return ["ivf"].some((val) => {
      return (
        type.toString().toLowerCase().includes(val) &&
        fapList &&
        fapList.programVisualization &&
        fapList.programVisualization.length > 0
      );
    });
  };

  const closeMessageModal = () => {
    setIsMessageModal(false);
    setDeleteItem({});
    actions.resetMessage();
  };

  return (
    <>
      <Box className="card-section">
        {loading || deleteEstimateLoading ? (
          <Stack justifyContent="center" p={5} alignItems="center">
            <CircularProgress size={50} />
          </Stack>
        ) : (
          <Grid container spacing={1}>
            {data.map((item, index) => {
              return (
                <React.Fragment key={"card" + index}>
                  <Grid item xs={4} key={index}>
                    <Card variant="outlined" sx={{ minHeight: "300px" }}>
                      <Stack direction="row" p={1} justifyContent="space-between">
                        <Typography variant="body2">
                          {item.treatmentPlanName}
                          {/* {item.assist ? " + With Assist" : ""} */}
                        </Typography>
                        {estimate && estimate.estimateStatus === "In-Progress" ? (
                          <IconButton
                            className="estimate-del-ico"
                            onClick={() => {
                              setDeleteModal(true);
                              setDeleteItem({ ...item, isLinked: false });
                            }}>
                            <DeleteIcon color={"primary"}></DeleteIcon>
                          </IconButton>
                        ) : null}
                      </Stack>
                      <Divider sx={{ background: "#1BA3AD", m: 1, height: "1px" }} />
                      {item.tpLinkId ? (
                        <>
                          <Card variant="outlined" sx={{ mx: 1, my: 1, minHeight: 250 }}>
                            <CardActionArea
                              sx={{ backgroundColor: cardClickAction ? "#f9f9f9" : "" }}
                              onClick={() =>
                                cardClick(
                                  item,
                                  item.tpLinkId,
                                  item.tpPrice,
                                  item.treatmentPlanName,
                                  "nonPgta"
                                )
                              }>
                              <Stack
                                direction="column"
                                p={1}
                                minHeight={230}
                                justifyContent="space-between">
                                <Stack direction="column" p={1}>
                                  <InputLabel>
                                    {item.treatmentType === "PGT-A Analysis"
                                      ? "Package price"
                                      : "Amount due to Practice"}
                                  </InputLabel>
                                  {/* <Typography variant="body2">{item.treatmentPlanName}</Typography> */}
                                  <Typography>
                                    {item.treatmentType === "PGT-A Analysis"
                                      ? item.originalTpPrice &&
                                        "$" + item.originalTpPrice.toLocaleString("en")
                                      : item.tpPrice && "$" + item.tpPrice.toLocaleString("en")}
                                    {/* $ {item.tpPrice && item.tpPrice.toLocaleString("en")}{" "} */}
                                    {item.treatmentType === "PGT-A Analysis"
                                      ? " for the PGT-A Analysis Unlimited Program"
                                      : " per cycle"}
                                  </Typography>
                                  {isBundledSaving(item.treatmentType) ? (
                                    <Typography>(Bundled savings available)</Typography>
                                  ) : null}
                                </Stack>
                                {showRefundApplicationBtton(item) ? (
                                  <Stack direction="column" p={1}>
                                    <Button
                                      component="span"
                                      sx={{
                                        my: 1,
                                        ml: 1,
                                        width: "fit-content",
                                        justifyContent: "flex-end",
                                        fontFamily: "Economica !important",
                                        fontSize: 16
                                      }}
                                      onMouseDown={(event) => event.stopPropagation()}
                                      onClick={(event) => {
                                        handleRefundClick(event, estimate, item);
                                      }}
                                      variant="contained"
                                      disabled={!item.mcpShowEstimate}>
                                      Initiate Refund Application
                                    </Button>
                                  </Stack>
                                ) : item.refundApplicationStatus !== null &&
                                  item.treatmentType.toString().toLowerCase().includes("ivf") ? (
                                  <Stack direction="column" p={1}>
                                    <Stack py={1} p={1} justifyContent="space-around">
                                      <Typography variant="body2">
                                        REFUND APPLICATION: {item.refundApplicationStatus}
                                      </Typography>
                                      <Link
                                        className="go-to-application"
                                        underline="none"
                                        onClick={(event) => {
                                          handleRefundClick(event, estimate, item);
                                        }}>
                                        Go to Application
                                      </Link>
                                    </Stack>
                                    <Box>
                                      <InputLabel>Refund Application Created by</InputLabel>
                                      <Typography variant="body2">
                                        {item.refundCreatedBy} {item.refundApplicationStatusDate}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                ) : null}
                              </Stack>
                            </CardActionArea>
                          </Card>
                        </>
                      ) : (
                        <Card variant="outlined" sx={{ mx: 1, my: 1, minHeight: 250 }}>
                          <CardActionArea
                            sx={{ backgroundColor: cardClickAction ? "#f9f9f9" : "" }}
                            onClick={() => cardClick(item, item.tpLinkId)}>
                            <Stack
                              direction="column"
                              minHeight={250}
                              justifyContent="space-between">
                              <Stack direction="column" p={1}>
                                <InputLabel>
                                  {item.treatmentType === "PGT-A Analysis"
                                    ? "Package price"
                                    : "Amount due to Practice"}
                                </InputLabel>
                                <Typography variant="body2">
                                  {item.treatmentType === "PGT-A Analysis"
                                    ? item.originalTpPrice &&
                                      "$" + item.originalTpPrice.toLocaleString("en")
                                    : item.tpPrice && "$" + item.tpPrice.toLocaleString("en")}
                                  {/* $ {item.tpPrice && item.tpPrice.toLocaleString("en")} */}
                                  {item.treatmentType === "PGT-A Analysis"
                                    ? " for the PGT-A Analysis Unlimited Program"
                                    : " per cycle"}
                                </Typography>

                                {isBundledSaving(item.treatmentType) ? (
                                  <Typography>(Bundled savings available)</Typography>
                                ) : null}
                              </Stack>
                              {showRefundApplicationBtton(item) ? (
                                <Button
                                  component="span"
                                  sx={{
                                    my: 1,
                                    ml: 1,
                                    width: "fit-content",
                                    fontFamily: "Economica !important",
                                    fontSize: 16
                                  }}
                                  onMouseDown={(event) => event.stopPropagation()}
                                  onClick={(event) => {
                                    handleRefundClick(event, estimate, item);
                                  }}
                                  variant="contained"
                                  disabled={!item.mcpShowEstimate}>
                                  Initiate Refund Application
                                </Button>
                              ) : item.refundApplicationStatus !== null &&
                                item.treatmentType.toString().toLowerCase().includes("ivf") ? (
                                <>
                                  <Stack py={1} p={1}>
                                    <Typography variant="body2">
                                      REFUND APPLICATION: {item.refundApplicationStatus}
                                    </Typography>
                                    <Link
                                      className="go-to-application"
                                      underline="none"
                                      onClick={(event) => {
                                        handleRefundClick(event, estimate, item);
                                      }}>
                                      Go to Application
                                    </Link>
                                  </Stack>
                                  <Stack p={1}>
                                    <Box>
                                      <InputLabel>Refund Application Created by</InputLabel>
                                      <Typography variant="body2">
                                        {item.refundCreatedBy} {item.refundApplicationStatusDate}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </>
                              ) : null}
                            </Stack>
                          </CardActionArea>
                        </Card>
                      )}
                    </Card>
                  </Grid>

                  {item.tpLinkId && (
                    <Grid item xs={4} key={index + 1000}>
                      <Card variant="outlined" sx={{ minHeight: "300px" }}>
                        <Stack direction="row" p={1} justifyContent="space-between">
                          <Typography variant="body2">
                            {item.linkedEstimateTreatmentPlan.treatmentPlanName}
                          </Typography>
                          {estimate && estimate.estimateStatus === "In-Progress" ? (
                            <IconButton
                              className="estimate-del-ico"
                              onClick={() => {
                                setDeleteModal(true);
                                setDeleteItem({ ...item, isLinked: true });
                              }}>
                              <DeleteIcon color={"primary"}></DeleteIcon>
                            </IconButton>
                          ) : null}
                        </Stack>
                        <Divider sx={{ background: "#1BA3AD", m: 1, height: "1px" }} />
                        <Card variant="outlined" sx={{ mx: 1, my: 1, minHeight: 250 }}>
                          <CardActionArea
                            sx={{
                              backgroundColor: cardIndex == index ? "#f9f9f9" : ""
                            }}
                            onClick={() =>
                              cardClick(
                                item,
                                item.tpLinkId,
                                item.linkedEstimateTreatmentPlan.tpPrice,
                                item.linkedEstimateTreatmentPlan.treatmentPlanName,
                                "pgta",
                                index
                              )
                            }>
                            <Stack direction="column" p={1}>
                              <InputLabel>Services</InputLabel>
                              {/* <Typography variant="body2">
                                {item.linkedEstimateTreatmentPlan.treatmentPlanName}
                              </Typography> */}
                              <Typography>
                                ${" "}
                                {item.linkedEstimateTreatmentPlan.tpPrice &&
                                  item.linkedEstimateTreatmentPlan.tpPrice.toLocaleString("en")}
                              </Typography>
                            </Stack>
                          </CardActionArea>
                        </Card>
                      </Card>
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        )}

        {cardClickAction ? (
          <EstimateCardDetails
            estimate={estimate}
            data={selectedCard}
            adjustmentList={adjustments}
            estimateId={estimate.estimateId}
            openCost={openCost}
            planName={planName}
            tpPrice={tpPrice}
            costData={costData}
          />
        ) : null}

        {fapLoading ? (
          <Stack justifyContent="center" p={5} alignItems="center">
            <CircularProgress size={50} />
          </Stack>
        ) : cardClickAction &&
          fapList &&
          fapList.programVisualization &&
          fapList.programVisualization.length > 0 &&
          selectedCard.treatmentType &&
          selectedCard.treatmentType.toString().toLowerCase().includes("ivf") ? (
          <FapView
            // fapVData={getFapVisibilityData}
            estimate={estimate}
            refundApplicationStatus={selectedCard.refundApplicationStatus}
          />
        ) : null}
      </Box>
      <DeleteModal
        handleDelete={handleDelete}
        open={deleteModal}
        close={closeDeleteModal}
        id={
          deleteItem.treatmentPlanName +
          (deleteItem.assist ? " + With Assist" : "") +
          (deleteItem.tpLinkId ? " + PGTA Analysis" : "")
        }
      />

      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
    </>
  );
};

const mapStateToProps = ({ estimateReducer, costCoverageReducer, fapReducer }) => {
  return {
    adjustments: costCoverageReducer.adjustmentList,
    loading: estimateReducer.estimateCardLoading,
    fapLoading: estimateReducer.fapLoading,
    fapList: estimateReducer.fapList,
    cardClickAction: estimateReducer.cardClickAction,
    deleteEstimateTreatmentPlan: estimateReducer.deleteEstimateTreatmentPlan,
    deleteEstimateLoading: estimateReducer.loading,
    updateAdjustment: costCoverageReducer.updateAdjustment,
    editProgramData: fapReducer.editProgramData,
    errorMsg: estimateReducer.error,
    successMsg: estimateReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getAdjustments: (payload) => {
      dispatch(actionType.getAdjustments(payload));
    },
    getFertilityAccessPrograms: (payload) => {
      dispatch(actionType.getFertilityAccessPrograms(payload));
    },
    cardClickedAction: () => {
      dispatch(actionType.cardClickedAction());
    },
    resetCardClick: () => {
      dispatch(actionType.resetCardClick());
    },
    FapRowData: (payload) => {
      dispatch(actionType.FapRowData(payload));
    },
    getStages: (payload) => {
      dispatch(actionType.getStages(payload));
    },
    getNecessary: (payload) => {
      dispatch(actionType.getNecessary(payload));
    },
    getOptional: (payload) => {
      dispatch(actionType.getOptional(payload));
    },
    resetUpdateAdjustments: () => {
      dispatch(actionType.resetUpdateAdjustments());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    getEditProgramFapVisibility: (payload) => {
      dispatch(actionType.getEditProgramFapVisibility(payload));
    },
    deleteEstimateTreatmentPlan: (payload) => {
      dispatch(actionType.deleteEstimateTreatmentPlan(payload));
    },
    getFinancialEstimateViewList: (payload) => {
      dispatch(actionType.getFinancialEstimateViewList(payload));
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EstimateCards);
