import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  TextField,
  // Select,
  // MenuItem,
  Button,
  Autocomplete
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { connect } from "react-redux";
import * as actionType from "../actions";
import { getPractices, getLocations, getPhysicians, getcontactList } from "../../admin/actions";

const SearchHeader = (props) => {
  let formData = {
    lastName: "",
    firstName: "",
    medcaseId: "",
    personKey: "",
    programId: "",
    locationId: "",
    physicianId: "",
    contactId: "",
    currentAuthorizedCycle: "",
    outcome: "",
    medcaseStatus: "",
    contractStatus: "",
    pgtaStatus: "",
    paymentStatus: "",
    authorizationStatus: "",
    requestedCycle: "",
    from: null,
    to: null
  };
  const {
    metaData = {},
    actions,
    practices = [],
    locations = [],
    physicians = [],
    contacts = []
  } = props;
  const [practiceId, setPracticeId] = useState("");
  const [values, setValues] = useState(formData);
  const [disable, setDisable] = useState(true);
  const [pName, setPName] = useState("");
  useEffect(() => {
    actions.getMetaData();
    actions.getPractices();
    actions.resetMessage();
  }, []);
  useEffect(() => {
    if (practiceId) {
      actions.getLocations(practiceId);
      actions.getPhysicians(practiceId);
      actions.getcontactList(practiceId);
      setDisable(false);
    }
  }, [practiceId]);
  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function handleSearch(e, name, value) {
    if (value !== null || value !== undefined || value !== "")
      setValues({ ...values, [name]: value });
  }
  const practiceChange = (name, value) => {
    if (value != null) {
      if (!value.value) {
        setPName("");
        setDisable(true);
      } else {
        console.log("value:", value);
        setPracticeId(value.value);
        setPName(value.label);
      }
    }
  };
  const search = (e) => {
    e.preventDefault();
    const payload = {
      medcaseId: values.medcaseId,
      personKey: values.personKey,
      firstName: values.firstName,
      lastName: values.lastName,
      patientName: values.firstName + values.lastName,
      program: values.programId,
      practice: pName,
      physician: values.physicianId,
      location: values.locationId,
      contact: values.contactId,
      medcaseStatus: values.medcaseStatus,
      pgtaStatus: values.pgtaStatus,
      contractStatus: values.contractStatus,
      paymentStatus: values.paymentStatus,
      currentAuthorizedCycle: values.currentAuthorizedCycle,
      authorizationStatus: values.authorizationStatus,
      fromRecentStatusDate: values.from || "",
      toRecentStatusDate: values.to || "",
      requestedCycle: values.requestedCycle
    };
    actions.getCaseList(payload);
  };
  return (
    <>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Last Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <TextField
                fullWidth
                size="small"
                name="lastName"
                value={values.lastName}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>First Name</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <TextField
                fullWidth
                size="small"
                name="firstName"
                value={values.firstName}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Medcase ID</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <TextField
                fullWidth
                size="small"
                name="medcaseId"
                value={values.medcaseId}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Person No</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              <TextField
                fullWidth
                size="small"
                name="personKey"
                value={values.personKey}
                onChange={handleChange}
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Program</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              fullWidth
              name="programId"
              value={values.programId}
              className="patient-dropdown"
              onChange={handleChange}>
              <MenuItem value="">Select</MenuItem>
              {Object.keys(metaData).length > 0 &&
                metaData?.programName.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select> */}
            <Autocomplete
              disablePortal
              required
              name="programId"
              value={values?.programId}
              options={
                Object.keys(metaData).length > 0 &&
                metaData?.programName.map((programId, index) => {
                  return {
                    id: index,
                    label: programId
                  };
                })
              }
              onChange={(e, value) => {
                handleSearch(e, "programId", value?.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Program..." sx={{ paddingTop: "7px" }} />
              )}
              className="flex-4"
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Practice</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              {/* <Select
                fullWidth
                name="practiceId"
                value={practiceId}
                className="patient-dropdown"
                onChange={practiceChange}>
                <MenuItem value="">Select</MenuItem>
                {practices.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="practiceId"
                value={values?.practiceId}
                options={practices.map((item, index) => {
                  return {
                    id: index,
                    label: item.name,
                    value: item.practiceId
                  };
                })}
                onChange={(e, value) => {
                  practiceChange("practiceId", value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Practice..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Location</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              disabled={disable}
              fullWidth
              name="locationId"
              value={values.locationId}
              className="patient-dropdown"
              onChange={handleChange}>
              <MenuItem value="">Select</MenuItem>
              {locations.map((item, index) => {
                return (
                  <MenuItem value={item.name} key={index}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </Select> */}
            <Autocomplete
              disablePortal
              required
              disabled={disable}
              name="locationId"
              value={values?.locationId}
              options={locations.map((item, index) => {
                return {
                  id: index,
                  label: item.name
                };
              })}
              onChange={(e, value) => {
                handleSearch(e, "locationId", value?.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Location..." sx={{ paddingTop: "7px" }} />
              )}
              className="flex-4"
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Physician</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              disabled={disable}
              fullWidth
              name="physicianId"
              value={values.physicianId}
              className="patient-dropdown"
              onChange={handleChange}>
              <MenuItem value="">Select</MenuItem>
              {physicians.map((item, index) => {
                return (
                  <MenuItem value={item.firstName} key={index}>
                    {item.firstName}
                  </MenuItem>
                );
              })}
            </Select> */}
            <Autocomplete
              disablePortal
              required
              disabled={disable}
              name="physicianId"
              value={values?.physicianId}
              options={physicians.map((item, index) => {
                return {
                  id: index,
                  label: item.firstName
                };
              })}
              onChange={(e, value) => {
                handleSearch(e, "physicianId", value?.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Physician..." sx={{ paddingTop: "7px" }} />
              )}
              className="flex-4"
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Contact</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              disabled={disable}
              fullWidth
              name="contactId"
              value={values.contactId}
              className="patient-dropdown"
              onChange={handleChange}>
              <MenuItem value="">Select</MenuItem>
              {contacts.map((item, index) => {
                return (
                  <MenuItem value={item.firstName} key={index}>
                    {item.firstName}
                  </MenuItem>
                );
              })}
            </Select> */}
            <Autocomplete
              disablePortal
              required
              disabled={disable}
              name="contactId"
              value={values?.contactId}
              options={contacts.map((item, index) => {
                return {
                  id: index,
                  label: item.firstName
                };
              })}
              onChange={(e, value) => {
                handleSearch(e, "contactId", value?.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Contact..." sx={{ paddingTop: "7px" }} />
              )}
              className="flex-4"
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Current Authorized Cycle </Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              {/* <Select
                fullWidth
                name="currentAuthorizedCycle"
                value={values.currentAuthorizedCycle}
                className="patient-dropdown"
                onChange={handleChange}>
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="IVF">IVF</MenuItem>
                <MenuItem value="FET">FET</MenuItem>
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="currentAuthorizedCycle"
                value={values?.currentAuthorizedCycle}
                options={[
                  { id: 1, label: "All" },
                  { id: 1, label: "IVF" },
                  { id: 1, label: "FET" }
                ]}
                onChange={(e, value) => {
                  handleSearch(e, "currentAuthorizedCycle", value.label);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Cycle..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Medcase Status</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              fullWidth
              name="medcaseStatus"
              value={values.medcaseStatus}
              className="patient-dropdown"
              onChange={handleChange}>
              <MenuItem value="">Select</MenuItem>
              {Object.keys(metaData).length > 0 &&
                metaData?.medcaseStatus.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select> */}
            <Autocomplete
              disablePortal
              required
              name="medcaseStatus"
              value={values?.medcaseStatus}
              options={
                Object.keys(metaData).length > 0 &&
                metaData?.medcaseStatus.map((item, index) => {
                  return {
                    id: index,
                    label: item
                  };
                })
              }
              onChange={(e, value) => {
                handleSearch(e, "medcaseStatus", value?.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Medcase Status..." sx={{ paddingTop: "7px" }} />
              )}
              className="flex-4"
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Contract Status</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              fullWidth
              name="contractStatus"
              value={values.contractStatus}
              className="patient-dropdown"
              onChange={handleChange}>
              <MenuItem value="">Select</MenuItem>
              {Object.keys(metaData).length > 0 &&
                metaData?.contractStatus.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select> */}
            <Autocomplete
              disablePortal
              required
              name="contractStatus"
              value={values?.contractStatus}
              options={
                Object.keys(metaData).length > 0 &&
                metaData?.contractStatus.map((item, index) => {
                  return {
                    id: index,
                    label: item
                  };
                })
              }
              onChange={(e, value) => {
                handleSearch(e, "contractStatus", value?.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Contract Status..." sx={{ paddingTop: "7px" }} />
              )}
              className="flex-4"
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>PGTA Status</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              fullWidth
              name="pgtaStatus"
              value={values.pgtaStatus}
              className="patient-dropdown"
              onChange={handleChange}>
              <MenuItem value="">Select</MenuItem>
              {Object.keys(metaData).length > 0 &&
                metaData?.pgtaAnalysisStatus.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select> */}
            <Autocomplete
              disablePortal
              required
              name="pgtaStatus"
              value={values?.pgtaStatus}
              options={
                Object.keys(metaData).length > 0 &&
                metaData?.pgtaAnalysisStatus.map((item, index) => {
                  return {
                    id: index,
                    label: item
                  };
                })
              }
              onChange={(e, value) => {
                handleSearch(e, "pgtaStatus", value?.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="PGTA Status..." sx={{ paddingTop: "7px" }} />
              )}
              className="flex-4"
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Patient Payment Status</Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <Select
              fullWidth
              name="paymentStatus"
              value={values.paymentStatus}
              className="patient-dropdown"
              onChange={handleChange}>
              <MenuItem value="">Select</MenuItem>
              {Object.keys(metaData).length > 0 &&
                metaData?.paymentStatus.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
            </Select> */}
            <Autocomplete
              disablePortal
              required
              name="paymentStatus"
              value={values?.paymentStatus}
              options={
                Object.keys(metaData).length > 0 &&
                metaData?.paymentStatus.map((item, index) => {
                  return {
                    id: index,
                    label: item
                  };
                })
              }
              onChange={(e, value) => {
                handleSearch(e, "paymentStatus", value.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Payment Status..." sx={{ paddingTop: "7px" }} />
              )}
              className="flex-4"
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Requested Cycle</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              {/* <Select
                fullWidth
                name="requestedCycle"
                value={values.requestedCycle}
                className="patient-dropdown"
                onChange={handleChange}>
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="FET">FET</MenuItem>
                <MenuItem value="Retrieval">Retrieval</MenuItem>
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="requestedCycle"
                value={values?.requestedCycle}
                options={[
                  {
                    id: 1,
                    label: "FET"
                  },
                  {
                    id: 2,
                    label: "Retrieval"
                  }
                ]}
                onChange={(e, value) => {
                  handleSearch(e, "requestedCycle", value.label);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Requested Cycle..." sx={{ paddingTop: "7px" }} />
                )}
                className="flex-4"
              />
            </Stack>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Authorization Status</Typography>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="row">
              {/* <Select
                fullWidth
                name="authorizationStatus"
                value={values.authorizationStatus}
                className="patient-dropdown"
                onChange={handleChange}>
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Request">Request</MenuItem>
                <MenuItem value="Requested">Requested</MenuItem>
                <MenuItem value="Authorized">Authorized</MenuItem>
                <MenuItem value="Expired">Expired</MenuItem>
              </Select> */}
              <Autocomplete
                disablePortal
                required
                name="authorizationStatus"
                value={values?.authorizationStatus}
                options={[
                  {
                    id: 1,
                    label: "Request"
                  },
                  {
                    id: 2,
                    label: "Requested"
                  },
                  {
                    id: 3,
                    label: "Authorized"
                  },
                  {
                    id: 4,
                    label: "Expired"
                  }
                ]}
                onChange={(e, value) => {
                  handleSearch(e, "authorizationStatus", value.label);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Authorization Status..."
                    sx={{ paddingTop: "7px" }}
                  />
                )}
                className="flex-4"
              />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack direction="row" className="pad-5">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography>Recent Status Date From</Typography>
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disableFuture
                openTo="day"
                views={["day"]}
                value={values.from}
                onChange={(newValue) => {
                  setValues({ ...values, from: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" fullWidth />
                )}></DatePicker>
            </LocalizationProvider>
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>To</Typography>
          </Grid>
          <Grid item xs={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                disableFuture
                openTo="day"
                views={["day"]}
                value={values.to}
                onChange={(newValue) => {
                  setValues({ ...values, to: newValue });
                }}
                renderInput={(params) => (
                  <TextField {...params} size="small" className="patient-dropdown" fullWidth />
                )}></DatePicker>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Stack>

      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "end"
        }}>
        <Button
          variant="contained"
          className="btn-usf"
          onClick={search}
          style={{ marginRight: 20 }}>
          Search
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = ({
  caseUpdateReducer,
  practices,
  locationReducer,
  physiciansReducer,
  contactReducer,
  caseListReducer
}) => {
  return {
    metaData: caseUpdateReducer.metaData,
    practices: practices.practices,
    locations: locationReducer.locations,
    physicians: physiciansReducer.physicians,
    contacts: contactReducer.contactList,
    caseList: caseListReducer.caseList
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMetaData: () => {
      dispatch(actionType.getMetaData());
    },
    getPractices: () => {
      dispatch(getPractices());
    },
    getLocations: (practiceId) => {
      dispatch(getLocations(practiceId));
    },
    getPhysicians: (practiceId) => {
      dispatch(getPhysicians(practiceId));
    },
    getcontactList: (practiceId) => {
      dispatch(getcontactList(practiceId));
    },
    getCaseList: (payload) => {
      dispatch(actionType.getCaseList(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchHeader);
