import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, InputLabel, Grid, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import * as actionType from "../actions";
import { connect } from "react-redux";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import AddLinkIcon from "@mui/icons-material/AddLink";
import MessageModal from "../../shared/components/MessageModal";
import LoadingModal from "./modals/loadingModal";

const CaseLookupCard = (props) => {
  const { actions, patientDetails, loadingLink } = props;
  let { id } = useParams();
  const [isMessageModal, setIsMessageModal] = useState(false);
  sessionStorage.setItem("Medcase_Link", "");

  useEffect(() => {
    if (props.success) {
      setIsMessageModal(true);
      actions.getPatientDetailsHeader({ medcaseId: id });
    }
  }, [props.success]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetLinkMedcase();
  };

  const unlinkMedcase = () => {
    actions.unlinkMedcase({ medcaseId: id });
  };
  useEffect(() => {
    actions.getPatientDetailsHeader({ medcaseId: id });
  }, []);

  const navigate = useNavigate();

  const navigateStages = () => {
    sessionStorage.setItem("link_medcase_id", id);
    sessionStorage.setItem("Medcase_Link", "Link New Medcase");
    navigate("/trak/caselookup");
  };

  const navigateLinkMedcase = () => {
    if (patientDetails.linkedMedcase && patientDetails.linkedMedcase != "") {
      navigate(`/trak/caselookup/caselist/${patientDetails.linkedMedcase}/status`);
      location.reload();
    }
  };

  return (
    <>
      <Box className="m-10" sx={{ background: "#F9F9F9" }}>
        <Stack direction="row" className="pad-10 flex-between">
          <Typography sx={{ fontWeight: "bold" }}>
            {patientDetails.patientFirstName != null && patientDetails.patientLastName != null
              ? patientDetails.patientFirstName + " " + patientDetails.patientLastName + " |"
              : "Patient Details:"}{" "}
            <Typography
              sx={{
                color: patientDetails.linkedMedcase ? "red" : "inherit",
                display: "inline"
              }}>
              {patientDetails.medcaseId}
            </Typography>
          </Typography>
          <Stack
            direction="row"
            className="flex-start"
            sx={{ alignItems: "center", margin: "0px" }}>
            <Button onClick={navigateLinkMedcase}>
              Linked Medcase (
              {patientDetails.linkedMedcase == null ? (
                "0"
              ) : (
                <Typography sx={{ display: "inline", color: "red" }}>
                  {patientDetails?.linkedMedcase}
                </Typography>
              )}
              )
            </Button>
            {patientDetails.linkedMedcase ? (
              <LinkOffIcon color="primary" onClick={unlinkMedcase} sx={{ marginLeft: "10px" }} />
            ) : (
              <AddLinkIcon color="primary" onClick={navigateStages} sx={{ marginLeft: "10px" }} />
            )}
          </Stack>
        </Stack>
        <Grid container className="pad-10">
          <Grid item xs={2.5}>
            <Stack direction="column">
              <InputLabel>Patient Name</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.patientFirstName != null && patientDetails.patientLastName != null
                  ? patientDetails.patientFirstName + " " + patientDetails.patientLastName
                  : "--"}
              </Typography>
              <InputLabel>Age At Medcase Created</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.ageAtMedcaseCreation === null
                  ? "--"
                  : patientDetails.ageAtMedcaseCreation}
              </Typography>
              <InputLabel>Patient DOB </InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.patientDob === null ? "--" : patientDetails.patientDob}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction="column">
              <InputLabel>Person No </InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.personKey === null ? "--" : patientDetails.personKey}
              </Typography>
              <InputLabel>Current Age</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.currentAge === null ? "--" : patientDetails.currentAge}
              </Typography>
              <InputLabel>Patient Email</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.patientEmail === null ? "--" : patientDetails.patientEmail}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction="column">
              <InputLabel>Partner</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.partnerFirstName != null && patientDetails.partnerLastName != null
                  ? patientDetails.partnerFirstName + " " + patientDetails.partnerLastName
                  : "--"}
              </Typography>
              <InputLabel>Partner Email</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.partnerEmail === null ? "--" : patientDetails.partnerEmail}
              </Typography>
              <InputLabel>Partner Cell Phone </InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.partnerCellPhone === null ? "--" : patientDetails.partnerCellPhone}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={2.5}>
            <Stack direction="column">
              <InputLabel>Address </InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.patientAddress === null ? "--" : patientDetails.patientAddress}
              </Typography>
              <InputLabel>Home Phone</InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.patientHomePhone ? patientDetails.patientHomePhone : "--"}
              </Typography>
              <InputLabel>Cell Phone </InputLabel>
              <Typography style={{ marginBottom: 5 }}>
                {patientDetails.patientCellPhone === null ? "--" : patientDetails.patientCellPhone}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <MessageModal open={isMessageModal} message={props.success} closeModal={closeMessageModal} />
      <LoadingModal open={loadingLink} />
    </>
  );
};

const mapStateToProps = ({ medcaseHeaderReducer, medcaseReducer }) => {
  return {
    patientDetails: medcaseHeaderReducer.patientDetails,
    loading: medcaseHeaderReducer.loading,
    errorMsg: medcaseHeaderReducer.error,
    success: medcaseReducer.successUnlink,
    loadingLink: medcaseReducer.loadingLink
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPatientDetailsHeader: (payload) => {
      dispatch(actionType.getPatientDetailsHeader(payload));
    },
    unlinkMedcase: (payload) => {
      dispatch(actionType.unlinkMedcase(payload));
    },
    resetLinkMedcase: () => {
      dispatch(actionType.resetLinkMedcase());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(CaseLookupCard);
