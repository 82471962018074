import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchStaffAdminUsers(action) {
  const { payload } = action;

  let params = {
    ...payload
  };
  try {
    const requestBody = createRequestBody("get", `/users`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.STAFFADMIN_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.STAFFADMIN_LIST_FAIL, errorMsg: error.message });
    }
  }
}

function* getStaffAdminSaga() {
  yield takeEvery(actionTypes.STAFFADMIN_LIST_REQUESTED, fetchStaffAdminUsers);
}

export default function* staffAdminSaga() {
  yield all([getStaffAdminSaga()]);
}
