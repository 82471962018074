import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchActiveParticipants(action) {
  const { payload } = action;
  try {
    const params = {
      participantType: payload.participantType
    };
    const requestBody = createRequestBody(
      "get",
      `/financial_snapshots/participant-reports`,
      params,
      payload
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.GET_ACTIVE_PARTICIPANTS_REPORT_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.GET_ACTIVE_PARTICIPANTS_REPORT_FAILED,
        errorMsg: error.message
      });
    }
  }
}

function* getActiveParticipantReportSaga() {
  yield takeEvery(actionTypes.GET_ACTIVE_PARTICIPANTS_REPORT_REQUESTED, fetchActiveParticipants);
}
export default function* activeParticipantReportSaga() {
  yield all([getActiveParticipantReportSaga()]);
}
