import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import { capitalizeFirstLetter, capitalizeString } from "../constants/constants";
import "../../admin/components/Admin.scss";
import { Typography, Select, MenuItem, Grid, Stack, Breadcrumbs } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
function Breadcrumb(props) {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const patientName = localStorage.getItem("patientName");
  const [practices, setPractices] = useState([]);
  const navigate = useNavigate();
  // const [pId, setPid] = useState([]);
  const [pId, setPid] = useState("");
  const { profileDetails } = props;
  useEffect(() => {
    setPractices(profileDetails["practiceList"]);

    let locaStoragePracticeId = localStorage.getItem("practiceId");
    let practiceId = "";
    console.log("profileDetails[defaultPracticeId]", profileDetails["defaultPracticeId"]);
    if (locaStoragePracticeId !== "undefined" && locaStoragePracticeId !== null) {
      console.log("hi");
      practiceId = locaStoragePracticeId;
    } else {
      practiceId = profileDetails["defaultPracticeId"];
    }
    // let practiceId = locaStoragePracticeId
    //   ? locaStoragePracticeId
    //   : profileDetails["defaultPracticeId"];
    console.log("practiceI", practiceId);
    localStorage.setItem("practiceId", practiceId);
    setPid(practiceId);
  }, [profileDetails]);
  const handlePracticeChange = (e) => {
    localStorage.setItem("practiceId", e.target.value);

    setPid(e.target.value);

    window.location.reload();
    navigate(location.pathname);
  };

  return (
    <Stack
      direction="row"
      sx={{ justifyContent: "space-between", alignItems: "center", width: "100%" }}>
      <Grid container>
        <Grid item xs={8}>
          <Breadcrumbs
            sx={{ mx: 2, width: "500px" }}
            separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            {pathnames.map((item, index) => {
              if (pathnames.includes("workspace")) {
                const last = index === pathnames.length - 1;
                const first = index === pathnames.length - pathnames.length;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                const value =
                  item == "workspace" ? capitalizeString(item) : capitalizeFirstLetter(item);

                return last || first ? (
                  <Typography
                    className="txt-capitalize"
                    key={index}
                    sx={{ justifyContent: "center", alignItems: "center" }}>
                    {last && item != "financial-estimates" && item != "fertility-access-program" ? (
                      <span style={{ fontWeight: "bold" }}>{patientName}</span>
                    ) : (
                      value
                    )}
                  </Typography>
                ) : (
                  <Link
                    underline="hover"
                    className="bread-hover"
                    key={index}
                    color="inherit"
                    to={to}>
                    {value}
                  </Link>
                );
              } else {
                const last = index === pathnames.length - 1;
                const first = index === pathnames.length - pathnames.length;
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;
                const value = item == "gdm" ? capitalizeString(item) : capitalizeFirstLetter(item);

                return last || first ? (
                  <Typography className="txt-capitalize" key={index}>
                    {value === "Trak"
                      ? "FA OPERATIONS"
                      : item === value && last
                      ? patientName
                      : value}
                  </Typography>
                ) : (
                  <Link
                    underline="hover"
                    className="bread-hover"
                    key={index}
                    color="inherit"
                    to={to}>
                    {value === "Trak" ? "FA OPERATIONS" : value}
                  </Link>
                );
              }
            })}
          </Breadcrumbs>
        </Grid>
        <Grid item xs={4}>
          {pathnames.includes("workspace") || pathnames.includes("patients") ? (
            <Stack direction="row" sx={{ justifyContent: "flex-end", alignItems: "center" }}>
              <Typography mr={1}>Practice</Typography>
              <Select
                fullWidth
                name="pid"
                id="pid"
                value={pId}
                onChange={(e) => handlePracticeChange(e)}>
                {practices?.map((item, index) => {
                  return (
                    <MenuItem value={item.practiceId} key={index}>
                      {item.practiceName} -{item.practiceId}
                    </MenuItem>
                  );
                })}
              </Select>
            </Stack>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
}
const mapStateToProps = ({ profileDrawerReducer }) => {
  return {
    profileDetails: profileDrawerReducer.profileDetails
  };
};

const mapDispatchToProps = () => ({
  actions: {
    //
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
