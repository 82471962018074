import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchRefundApplicationTrakList(action) {
  const { payload } = action;
  const { values } = payload;

  try {
    const params = {
      offset: "0",
      limit: "10000",
      ...values
    };
    const requestBody = createRequestBody("get", `/estimates/refundApplications`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.REFUND_APPLICATION_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.REFUND_APPLICATION_LIST_FAILED,
        errorMsg: error.message
      });
    }
  }
}

function* fetchRefundApplicationTrakSaga() {
  yield takeEvery(actionTypes.REFUND_APPLICATION_LIST_REQUESTED, fetchRefundApplicationTrakList);
}

function* getRequestedCycleList(action) {
  const { payload } = action;
  const { values } = payload;
  console.log("values", values);
  try {
    const params = {
      offset: "0",
      limit: "10000",
      ...values
    };
    const requestBody = createRequestBody("get", `/medcases/requestedCycles`, params, "");
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    yield put({
      type: actionTypes.REQUESTED_CYCLE_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({
        type: actionTypes.REQUESTED_CYCLE_FAILURE,
        errorMsg: error.message
      });
    }
  }
}

function* getRequestedCycleSaga() {
  yield takeEvery(actionTypes.REQUESTED_CYCLE_REQUESTED, getRequestedCycleList);
}

export default function* refundApplicationTrakSaga() {
  yield all([fetchRefundApplicationTrakSaga(), getRequestedCycleSaga()]);
}
