import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Grid,
  Button,
  InputAdornment,
  Box,
  Modal,
  IconButton,
  Divider
} from "@mui/material";
import * as actionType from "../actions";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import EditIcon from "@mui/icons-material/Edit";
import { partnerConfig, personNumberConfig } from "../../shared/constants/renderConfigs";
import PartnerAddress from "./PartnerAddress";
import PartnerEmail from "./PartnerEmail";
import PartnerPhone from "./PartnerPhone";
import { enumUsStates } from "../../shared/constants/constants";
import LoadingModal from "./modals/loadingModal";
import moment from "moment";

const Partner = (props) => {
  let partnerFormData = { dob: null };
  const [isOpen, setIsOpen] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [values, setValues] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [valuesPersonNumber, setValuesPersonNumber] = useState("");
  const [isOpenPersonNumber, setIsOpenPersonNumber] = useState(false);
  const [isAddPersonNumber, setIsAddPersonNumber] = useState(false);
  const [billingAddress, setBillingAddress] = useState([]);
  const [phoneInfo, setPhoneInfo] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [warningOpen, setWarningOpen] = useState(false);

  const {
    partnerList = [],
    actions,
    addPartner,
    editPartner,
    errorMsg,
    successMsg,
    patientDetails,
    participantList = [],
    loading,
    medcaseSuccess
  } = props;

  const person_id = sessionStorage.getItem("person_id");
  const practice_id = sessionStorage.getItem("practiceId");
  const partnerId = sessionStorage.getItem("partnerId");
  const payload = {
    practiceId: patientDetails.practiceId,
    personId: patientDetails.personId || person_id
  };
  const initFetch = useCallback(() => {
    actions.getPraticipant(payload);
    let person_id = patientDetails.partnerId;
    if (addPartner != null) {
      person_id = addPartner.personId;
    }

    actions.getPartner({
      practiceId: patientDetails.practiceId || practice_id,
      personId: person_id || partnerId
    });
  }, [addPartner, editPartner, loading]);

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(partnerList, "partner")) {
      partnerList["partner"] = { ...partnerList };
    }
    const arr = [];
    const billingAdd = {
      type: "billing",
      billingAddrLine1: partnerList?.partner?.billingAddrLine1,
      billingAddrLine2: partnerList?.partner?.billingAddrLine2,
      billingCity: partnerList?.partner?.billingCity,
      billingState: partnerList?.partner?.billingState,
      billingZip: partnerList?.partner?.billingZip
    };
    const mailingAdd = {
      type: "mailing",
      billingAddrLine1: partnerList?.partner?.mailingAddrLine1,
      billingAddrLine2: partnerList?.partner?.mailingAddrLine2,
      billingCity: partnerList?.partner?.mailingCity,
      billingState: partnerList?.partner?.mailingState,
      billingZip: partnerList?.partner?.mailingZip
    };
    const telephoneData = [
      {
        type: "Home",
        number: partnerList?.partner?.homePhone
      },
      {
        type: "Work",
        number: partnerList?.partner?.workPhone
      },
      {
        type: "Cell",
        number: partnerList?.partner?.cellPhone
      },
      {
        type: "Additional",
        number: partnerList?.partner?.additionalPhone
      }
    ];
    const emailDatas = [
      {
        email: partnerList?.partner?.email
      }
    ];
    arr.push(billingAdd);
    arr.push(mailingAdd);
    setBillingAddress(arr);
    setPhoneInfo(telephoneData);
    setEmailData(emailDatas);
    sessionStorage.setItem("person_id", patientDetails.personId);
  }, [partnerList]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      setIsMessageModal(true);
    }

    if (medcaseSuccess) {
      if (partnerList.length == 0 || editPartner || medcaseSuccess) {
        initFetch();
        setValues(partnerFormData);
      }
    }
    return () => {};
  }, [addPartner, editPartner, errorMsg, successMsg, medcaseSuccess]);

  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetMessage();
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    if (name.includes("state")) {
      const stateObject = enumUsStates.filter((item) => item.name == value)[0];
      setValues({
        ...values,
        [name]: stateObject.value
      });
    } else {
      setValues({
        ...values,
        [name]: value
      });
    }
  };
  const addNewPartner = () => {
    if (partnerList.length != 0) {
      setWarningOpen(true);
    } else {
      setWarningOpen(false);
      setIsOpen(true);
    }
  };
  const closeModal = () => {
    setIsOpen(false);
    setIsOpenPersonNumber(false);
  };
  const savePatner = (event) => {
    const idData = {
      patientId: patientDetails.personId,
      partnerId: patientDetails.partnerId
    };
    event.preventDefault();
    if (isAdd) {
      actions.addPartner({ ...values, idData, personKey: participantList.personKey });
    } else {
      actions.addPartner({ ...values, idData, personKey: participantList.personKey });
    }
    closeModal();
  };

  const partner = partnerConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd,
    setValues,
    options: enumUsStates.map((item) => {
      return item.value;
    }),
    disabled:
      values.firstName &&
      values.lastName &&
      values.dob &&
      values.addressLine1 &&
      values.email &&
      values.city &&
      values.state &&
      values.zip &&
      values.country
        ? false
        : true
  });

  const handleInputChangeNumber = (e) => {
    var { name, value } = e.target;
    setValuesPersonNumber({
      [name]: value
    });
  };

  const personNumber = personNumberConfig({
    valuesPersonNumber,
    onChange: handleInputChangeNumber,
    onClick: closeModal,
    isAdd,
    setValues
  });

  const handleChange = () => {
    if (partnerList?.personKey == null || partnerList?.personKey == "") {
      setIsOpenPersonNumber(true);
      setIsAddPersonNumber(false);
    }
  };

  const IconTextField = ({ iconEnd, ...props }) => {
    return (
      <TextField
        {...props}
        InputProps={{
          endAdornment: iconEnd ? (
            <InputAdornment size="small" position="end">
              {iconEnd}
            </InputAdornment>
          ) : null
        }}
      />
    );
  };

  const closeWarning = () => {
    setWarningOpen(false);
  };

  const proceedCreatePartner = () => {
    setIsAdd(true);
    setIsOpen(true);
    setWarningOpen(false);
  };

  return (
    <>
      <Stack className="pad-5" direction="row">
        <Grid container className="align-center">
          <Grid item xs={9.98}></Grid>
          <Grid item xs={2.02}>
            <Button
              variant="contained"
              sx={{ width: "100%" }}
              className="btn-usf"
              onClick={addNewPartner}>
              Add New Partner Info
            </Button>
          </Grid>
        </Grid>
      </Stack>

      <Stack className="pad-5" direction="row">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography sx={{ ml: 0.4 }}>First Name</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              sx={{ flex: 6 }}
              size="small"
              fullWidth
              name="dob"
              id="filled-basic"
              value={partnerList?.firstName}
              className="add-description"
              disabled
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}>Last Name</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              sx={{ flex: 6 }}
              size="small"
              fullWidth
              name="dob"
              id="filled-basic"
              value={partnerList?.lastName}
              className="add-description"
              disabled
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack className="pad-5" direction="row">
        <Grid container className="align-center">
          <Grid item xs={1.5}>
            <Typography sx={{ ml: 0.4 }}>DOB</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <TextField
              sx={{ flex: 6 }}
              size="small"
              fullWidth
              name="dob"
              id="filled-basic"
              value={partnerList.birthDate ? moment(partnerList.birthDate).format("L") : null}
              className="add-description"
              disabled
            />
          </Grid>
          <Grid item xs={0.5}></Grid>
          <Grid item xs={1.5}>
            <Typography style={{ marginLeft: "10px" }}> Person Number</Typography>
          </Grid>
          <Grid item xs={4.25}>
            <IconTextField
              sx={{ flex: 6 }}
              size="small"
              fullWidth
              name="personkey"
              id="filled-basic"
              value={partnerList?.personKey}
              className="add-description"
              iconEnd={<EditIcon onClick={handleChange} />}
              disabled={
                partnerList?.personKey == null || partnerList?.personKey == "" ? false : true
              }
            />
          </Grid>
        </Grid>
      </Stack>
      <Box flexDirection="row">
        <PartnerAddress
          defaultCheckRadio={partnerList.defaultAddress}
          billingAddress={billingAddress}
          tabValue="2"
          partnerList={partnerList}
          participantList={participantList}
          practiceId={patientDetails.practiceId}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <PartnerPhone
            phoneInfo={phoneInfo}
            participantList={participantList}
            practiceId={patientDetails.practiceId}
          />
        </Grid>
        <Grid item sm={6}>
          <PartnerEmail
            emailData={emailData}
            participantList={participantList}
            practiceId={patientDetails.practiceId}
          />
        </Grid>
      </Grid>
      <CommonModal open={isOpen} config={partner} isAdd={isAdd} handleSubmit={savePatner} />
      <CommonModal open={isOpenPersonNumber} config={personNumber} isAdd={isAddPersonNumber} />
      <MessageModal
        open={isMessageModal}
        message={successMsg}
        error={errorMsg}
        closeModal={closeMessageModal}
      />
      <Modal open={warningOpen}>
        <Box className="modal-style-delete">
          <Stack direction="row" className="modal-stack">
            <Typography>Add New Partner</Typography>
            <IconButton onClick={closeWarning}>
              <img alt="" src="/Assets/close.png" />
            </IconButton>
          </Stack>
          <Divider className="modal-divider" />
          <Stack direction="column">
            <Typography variant="body1" sx={{ my: 2 }}>
              ADD NEW PARTNER INFORMATION!!!
            </Typography>
          </Stack>
          <Stack direction="column">
            <Typography sx={{ my: 2 }}>
              This will erase the existing partner information(Demographic ,Address, Phone Number ,
              Email)& user has to create a new Partner
            </Typography>
          </Stack>
          <Stack direction="row" className="modal-stack">
            <Button variant="outlined" onClick={closeWarning}>
              Cancel
            </Button>
            <Button variant="contained" onClick={proceedCreatePartner}>
              Proceed
            </Button>
          </Stack>
        </Box>
      </Modal>
      <LoadingModal open={loading} />
    </>
  );
};
const mapStateToProps = ({ partnerReducer, medcaseHeaderReducer, participantReducer }) => {
  return {
    loading: partnerReducer.loading,
    participantList: participantReducer.participantList,
    partnerList: partnerReducer.partnerList,
    addPartner: partnerReducer.addPartner,
    editPartner: partnerReducer.editPartner,
    errorMsg: partnerReducer.error,
    successMsg: partnerReducer.success,
    patientDetails: medcaseHeaderReducer.patientDetails,
    medcaseSuccess: medcaseHeaderReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getPraticipant: (payload) => {
      dispatch(actionType.getParticiPant(payload));
    },
    getPartner: (payload) => {
      dispatch(actionType.getPartner(payload));
    },
    addPartner: (payload) => {
      dispatch(actionType.addPartner(payload));
    },
    editPartner: (payload) => {
      dispatch(actionType.editPartner(payload));
    },
    resetPartnerList: () => {
      dispatch(actionType.resetPartnerList());
    },
    getPatientDetailsHeader: (payload) => {
      dispatch(actionType.getPatientDetailsHeader(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Partner);
