import {
  Divider,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionType from "../actions";

const EstimateView = ({ data, loading, selectedView, actions }) => {
  const [estimates, setEstimates] = useState("");
  const serachParm = new URLSearchParams(window.location.search);
  const estimateId = serachParm.get("estimateId");

  // const estimateId = useSelector((state) => {
  //   console.log("=>state", state.estimateReducer);
  //   return state.estimateReducer.estimateId;
  // });

  useEffect(() => {
    if (data && data.length) {
      let esID = data && data[0] && data[0].estimateId;

      if (estimateId) {
        let filterDta = data.filter((item) => item.estimateId == estimateId);

        if (filterDta.length > 0) {
          selectedView(filterDta[0]);
          setEstimates(filterDta[0].estimateId);
        }
      } else {
        selectedView(data[0]);
        setEstimates(esID);
      }
      actions.resetCardClick();
    }
  }, [loading]);

  return (
    <Select
      size="small"
      sx={{ minWidth: 444 }}
      onChange={(e) => {
        actions.resetstages();
        actions.resetOptional();
        actions.resetNecessary();
        actions.resetCardClick();
        const filteredText = data.filter((item) => item.estimateId === e.target.value)[0];
        selectedView(filteredText);
        setEstimates(filteredText.estimateId);
      }}
      value={estimates}
      renderValue={(selected) => {
        if (data && data.length > 0) {
          const renderedItem = data.filter((item) => item.estimateId === selected)[0];
          return (
            <>
              {/* {selected} */}
              {renderedItem && renderedItem.estimateType == "D"
                ? "Detailed" + " " + renderedItem.estimateDescr
                : "Preliminary" + " " + renderedItem?.estimateDescr}
            </>
          );
        } else {
          return <>{selected}</>;
        }
      }}>
      {data &&
        data.map((item, index) => {
          let key, subHeader, divider, estimateTypLabel;
          estimateTypLabel = item.estimateType == "D" ? "Detailed" : "Preliminary";
          if (index == 0) {
            key = data.length + 1;
            subHeader = "Last Modified";
            divider = <Divider key={data.length + 2} />;
          } else if (index == 1) {
            key = data.length + 3;
            subHeader = "Older";
          }
          return [
            <ListSubheader className="list-subheader" key={key}>
              {subHeader}
            </ListSubheader>,
            <MenuItem key={index} value={item.estimateId}>
              <Stack>
                <Typography className="font-w-500">
                  {estimateTypLabel + " "}
                  {item.estimateDescr}
                </Typography>
                <InputLabel>{item.treatmentPlanName}</InputLabel>
                <InputLabel>{item.estimateStatus}</InputLabel>
                <InputLabel>
                  Created By {item.createdBy} | Create on{" "}
                  {new Date(item.estimateCreatedDate).toLocaleDateString()}
                </InputLabel>
                <InputLabel>
                  Last modified By {item?.updatedBy ? item.updatedBy : "--"} | Last modified on{" "}
                  {new Date(item.updationDate).toLocaleDateString()}
                </InputLabel>
              </Stack>
            </MenuItem>,
            divider
          ];
        })}
    </Select>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    resetstages: () => {
      dispatch(actionType.resetstages());
    },
    resetOptional: () => {
      dispatch(actionType.resetOptional());
    },
    resetNecessary: () => {
      dispatch(actionType.resetNecessary());
    },
    resetCardClick: () => {
      dispatch(actionType.resetCardClick());
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    }
  }
});
export default connect(null, mapDispatchToProps)(EstimateView);
