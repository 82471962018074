// Notifications
export const GET_USER_NOTIFICATIONS_REPORT_REQUESTED = "GET_USER_NOTIFICATIONS_REPORT_REQUESTED";
export const RESET_MESSAGE = "RESET_MESSAGE";
export const GET_USER_NOTIFICATIONS_REPORT_SUCCESS = "GET_USER_NOTIFICATIONS_REPORT_SUCCESS";
export const GET_USER_NOTIFICATIONS_REPORT_FAILURE = "GET_USER_NOTIFICATIONS_REPORT_FAILURE";

export const GET_USER_NOTIFICATION_METADATA_REPORT_REQUESTED =
  "GET_USER_NOTIFICATION_METADATA_REPORT_REQUESTED";
export const GET_USER_NOTIFICATION_METADATA_REPORT_SUCCESS =
  "GET_USER_NOTIFICATION_METADATA_REPORT_SUCCESS";
export const GET_USER_NOTIFICATION_METADATA_REPORT_FAILURE =
  "GET_USER_NOTIFICATION_METADATA_REPORT_FAILURE";

export const UPDATE_USER_NOTIFICATION_REPORT_REQUESTED =
  "UPDATE_USER_NOTIFICATION_REPORT_REQUESTED";
export const UPDATE_USER_NOTIFICATION_REPORT_SUCCESS = "UPDATE_USER_NOTIFICATION_REPORT_SUCCESS";
export const UPDATE_USER_NOTIFICATION_REPORT_FAILURE = "UPDATE_USER_NOTIFICATION_REPORT_FAILURE";
