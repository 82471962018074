import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import moment from "moment";
import { MSG } from "../../shared/constants/constants";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchNewPrList(action) {
  const { payload } = action;
  try {
    const requestBody = createRequestBody(
      "get",
      `/providerReimbursements/${payload.medcaseId}/clinicalTreatment/${payload.clinicalTreatmentNo}`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.NEW_PR_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.NEW_PR_FAILED, errorMsg: error.message });
    }
  }
}

function* getNewPrSaga() {
  yield takeEvery(actionTypes.NEW_PR_REQUESTED, fetchNewPrList);
}

function* fetchNewPrPostList(action) {
  const { payload } = action;
  let formatDob = moment(payload.value.dob).format("YYYY-MM-DD");
  const body = {
    practiceId: payload.value.practiceId,
    practiceName: payload.value.practiceName,
    glNo: payload.value.glNo,
    medcaseId: payload.value.medcaseId,
    patientId: payload.value.patientId,
    patientFirstName: payload.value.patientFirstName,
    patientLastName: payload.value.patientLastName,
    dob: formatDob,
    locationId: payload.value.locationId,
    locationName: payload.value.locationName,
    transactionDate: payload.transactionDate,
    transactionTypeDesc: payload.newValue.transactionTypeDesc,
    clinicalTreatmentId: payload.value.clinicalTreatmentId,
    clinicalTreatmentNo: payload.clinicalTreatmentNo,
    clinicalCycleNo: payload.value.clinicalCycleNo,
    medcaseStatus: payload.value.medcaseStatus,
    initReimbursementAmount: payload.newValue.approvedReimbursementAmount,
    approvedReimbursementAmount: payload.newValue.approvedReimbursementAmount,
    requestedTreatmentPlanId: payload.value.requestedTreatmentPlanId,
    requestedTreatmentPlan: payload.requestedTreatmentPlan,
    performedTreatmentPlanId: payload.value.performedTreatmentPlanId || "",
    performedTreatmentPlan: payload.performedTreatmentPlan || "",
    programFee: payload.value.programFee,
    programName: payload.value.programName,
    gdmId: payload.value.gdmId,
    isManualEntry: true,
    isManualReview: true,
    outcomeId: payload.value.outcomeId,
    outcomeStatus: payload.outcomeStatus,
    // providerReimbursementScale: payload.value.providerReimbursementScale,
    // reimbursementPercentage: payload.value.reimbursementPercentage,
    providerReimbursementScale: payload.value.providerReimbursementScale,
    reimbursementPercentage: payload.value.reimbursementPercentage,
    reimbursementStatus: "Unapproved",
    comment: payload.newValue.comment
  };
  try {
    const requestBody = createRequestBody(
      "post",
      `/providerReimbursements/${payload.medcaseId}/clinicalTreatment/${payload.clinicalTreatmentNo}`,
      "",
      body
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));

    yield put({
      type: actionTypes.NEW_PR_POST_SUCCESS,
      payload: response.data,
      successMsg: MSG.SAVED_SUCCESSFULLY
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.NEW_PR_POST_FAILED, errorMsg: error.message });
    }
  }
}

function* getNewPrPostSaga() {
  yield takeEvery(actionTypes.NEW_PR_POST_REQUESTED, fetchNewPrPostList);
}
export default function* newPrSaga() {
  yield all([getNewPrSaga(), getNewPrPostSaga()]);
}
