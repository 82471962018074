import * as actionType from "../actions/ActionTypes";

const initialState = {
  addressList: [],
  addAddress: null,
  editAddress: null,
  loading: false,
  error: null,
  success: false
};

const addressReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.ADDRESS_LIST_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADDRESS_LIST_SUCCESS: {
      return {
        ...state,
        addressList: action.payload,
        loading: false
      };
    }
    case actionType.ADDRESS_LIST_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }
    case actionType.ADD_ADDRESS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.ADD_ADDRESS_SUCCESS: {
      return {
        ...state,
        addAddress: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.ADD_ADDRESS_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.EDIT_ADDRESS_REQUESTED: {
      return {
        ...state,
        loading: true
      };
    }
    case actionType.EDIT_ADDRESS_SUCCESS: {
      return {
        ...state,
        editAddress: action.payload,
        loading: false,
        success: action.successMsg
      };
    }
    case actionType.EDIT_ADDRESS_FAILED: {
      return {
        ...state,
        error: action.message,
        loading: false
      };
    }

    case actionType.RESET_ADDRESS_LIST: {
      return {
        ...state,
        addAddress: null,
        editAddress: null,
        loading: null
      };
    }

    case actionType.RESET_MESSAGE: {
      return {
        ...state,
        error: null,
        success: false
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default addressReducer;
