import React, { useState, useEffect } from "react";
import { Box, Stack, Grid, Button, Typography } from "@mui/material";
import moment from "moment";
import { connect } from "react-redux";
import * as actionType from "../actions";
import DataGridPremiumComp from "../../shared/components/DataGridPremiumComp";

const InactiveParticipant = (props) => {
  const [pageSize, setPageSize] = useState({
    pageSize: 100,
    page: 0
  });
  const { inactiveParticipantList: inactiveParticipantList = [], actions, loading } = props;
  const [currentDate, setCurrentDate] = useState();
  const [csvData, setCsvData] = useState([]);

  const dateFormat = "YYYY-MM-DD";
  const callAPI = () => {
    actions.getInactiveParticipantReport({
      offset: 0,
      participantType: "inactive",
      limit: 1000
    });
    const currDate = moment().format(dateFormat);
    setCurrentDate(currDate);
  };

  const prepareCsvData = (rows) => {
    return rows.map((row) => ({
      ...row,
      patientName:
        row.patientFirstName && row.patientLastName
          ? row.patientFirstName + " " + row.patientLastName
          : "",
      outcomeDate: row.outcomeDate ? moment(row.outcomeDate).format("MM/DD/YYYY") : "",
      limboFollowUpDate: row.limboFollowUpDate
        ? moment(row.limboFollowUpDate).format("MM/DD/YYYY")
        : "",
      medcaseStatusDate: row.medcaseStatusDate
        ? moment(row.medcaseStatusDate).format("MM/DD/YY")
        : ""
    }));
  };

  useEffect(() => {
    const rows = inactiveParticipantList.map((item, index) => {
      item.id = index;
      return item;
    });

    const csvDataList = prepareCsvData(rows);
    console.log("csvDataList: ", csvDataList);
    setCsvData(csvDataList);
  }, [inactiveParticipantList]);

  function InActiveParticipantToolBar() {
    return (
      <Grid container className="align-center">
        <Grid item xs={1.25}>
          <Typography style={{ marginLeft: "10px" }}>Current Date</Typography>
        </Grid>
        <Grid item xs={2.25}>
          <Typography style={{ marginLeft: "10px" }}>
            {currentDate ? moment(currentDate).format("MM/DD/YYYY") : ""}
          </Typography>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={2}>
          <Button variant="contained" className="btn-usf" onClick={callAPI}>
            Run Report
          </Button>
        </Grid>
      </Grid>
    );
  }

  const columns = [
    {
      field: "medcaseId",
      headerName: "MEDCASE ID",
      width: 100,
      editable: false
    },
    {
      field: "patientName",
      headerName: "PATIENT NAME",
      width: 100,
      editable: false,
      renderCell: ({ row }) => {
        return "".concat(row.patientFirstName, " ", row.patientLastName);
      }
    },
    {
      field: "practiceName",
      headerName: "PRACTICE",
      width: 100,
      editable: false
    },
    {
      field: "locationName",
      headerName: "LOCATION",
      width: 100,
      editable: false
    },
    {
      field: "medcaseStatus",
      headerName: "STATUS",
      width: 100,
      editable: false
    },
    {
      field: "medcaseStatusDate",
      headerName: "STATUS DATE",
      width: 100,
      editable: false,
      renderCell: ({ row }) =>
        row.medcaseStatusDate ? moment(row.medcaseStatusDate).format("MM/DD/YYYY") : ""
    },
    {
      field: "personNo",
      headerName: "PERSON NO",
      width: 100,
      editable: false
    },
    {
      field: "progShortName",
      headerName: "PROGRAM SHORT NAME",
      width: 100,
      editable: false
    },
    {
      field: "cycleNo",
      headerName: "CYCLE Number",
      width: 100,
      editable: false
    },
    {
      field: "outcomeCategory",
      headerName: "OUTCOME",
      width: 100,
      editable: false
    },
    {
      field: "normalFrozenEmbryosRemaining",
      headerName: "NORMAL EMBRYOS REMAIN",
      width: 90,
      editable: false
    },
    {
      field: "outcomeDate",
      headerName: "OUTCOME DATE",
      width: 100,
      editable: false,
      renderCell: ({ row }) => {
        return row.outcomeDate ? moment(row.outcomeDate).format("MM/DD/YYYY") : "";
      }
    },
    {
      field: "daysInactive",
      headerName: "DAYS INACTIVE",
      width: 100,
      editable: false
    },
    {
      field: "age",
      headerName: "CURRENT AGE",
      width: 100,
      editable: false
    },
    {
      field: "authorizedTpName",
      headerName: "REQUESTED TREATMENT",
      width: 80,
      editable: false
    },
    {
      field: "limboFollowUpDate",
      headerName: "LIMBO FOLLOW UP DATE",
      width: 80,
      editable: false,
      renderCell: ({ row }) => {
        return row.limboFollowUpDate ? moment(row.limboFollowUpDate).format("MM/DD/YYYY") : "";
      }
    },
    {
      field: "contractExpDate",
      headerName: "Contract Expiration Date",
      width: 80,
      editable: false,
      renderCell: ({ row }) => {
        return row?.contractExpDate ? moment(row.contractExpDate).format("MM/DD/YYYY") : "";
      }
    }
  ];
  return (
    <>
      <Stack direction="row" className="pad-5 m-t-8"></Stack>
      <Box className="m-8">
        <Box className="grid-style" sx={{ height: "660px" }}>
          <DataGridPremiumComp
            rows={csvData}
            rowCount={csvData.length}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 20, 50, 100]}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pagination
            localeText={"Export Button"}
            components={<InActiveParticipantToolBar />}
            isExport={true}
            headerText={`Inactive Participant Current Date ${currentDate}`}
            disableRowSelectionOnClick={true}
          />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = ({ inactiveParticipantReducer }) => {
  return {
    loading: inactiveParticipantReducer.loading,
    inactiveParticipantList: inactiveParticipantReducer.inactiveParticipantList,
    errorMsg: inactiveParticipantReducer.error,
    successMsg: inactiveParticipantReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getInactiveParticipantReport: (payload) => {
      dispatch(actionType.getInactiveParticipantReport(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InactiveParticipant);
