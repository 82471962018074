import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Grid, Divider, InputLabel, Checkbox } from "@mui/material";
import { Stack } from "@mui/system";
import PatientPaymentsModal from "./modals/PatientPaymentsModal";
import ProgramCycles from "./ProgramCycles";
import TransactionLog from "./TransactionLog";
import { connect } from "react-redux";
import * as actionType from "../actions";
import CommonModal from "../../shared/components/CommonModal";
import MessageModal from "../../shared/components/MessageModal";
import { useParams } from "react-router-dom";
import { commentConfig, finSnapshotConfig } from "../../shared/constants/renderConfigs";
import moment from "moment";
//import LoadingSpinner from "../../shared/components/LoadingSpinner";
import MedcaseRefund from "./MedcaseRefund";
import MedcaseEnroll from "./MedcaseEnroll";
import SaveModal from "../../shared/components/SaveModal";
import AddRevenue from "./AddRevenue";
import LoadingModal from "./modals/loadingModal";
import WarnningMessageModal from "../../shared/components/WarnningMessageModal";
import { patientPaymentWarning } from "../constants";
import { priceFormat } from "../../shared/common";
import ClinicalComments from "./ClinicalComments";
import FinancialComments from "./FinancialComments";

const FinSnapshot = (props) => {
  const {
    actions,
    errorMsg,
    success,
    errorPayment,
    programName,
    financialSnapshotPaymentList,
    addPatientPaymentCC,
    addPatientPaymentCH,
    addPatientPaymentLN,
    addPatientPaymentWT,
    loading,
    medcaseHeader,
    // financialSnapshotPgtaList,
    revenueData,
    loadingFee,
    loadingRevenue,
    successRevenue,
    manualReviewSuccess,
    clinicalReviewSuccess,
    successEnroll,
    successFees,
    errorFee,
    successRefund,
    programCycle,
    successMsg,
    errorEmail
  } = props;
  let finSnapshotFormData = {
    netProgramFee: financialSnapshotPaymentList.netProgramFees,
    feeType: "",
    recordedDate: new Date(),
    transactionDate: new Date(),
    amount: "",
    addRadio: false,
    subRadio: false
  };
  const { id } = useParams();
  useEffect(() => {
    actions.getProgramName({ medcaseId: id });
    actions.getFinancialSnapshotPayment({ medcaseId: id });
    actions.getMedcaseHeader({ medcaseId: id });
    actions.getMedcaseStatus({ medcaseId: id });
    actions.getFinancialSnapshotPgta({ medcaseId: id });
    // actions.getMedcaseDetailsHeader({ medcaseId: id });
    actions.getProgramCycle({ medcaseId: id, clinicalTreatmentNo: "1.0" });
    actions.getRevenueFin({ medcaseId: id });
  }, []);

  const [values, setValues] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isRefundOpen, setIsRefundOpen] = useState(false);
  const [isRevenueOpen, setIsRevenueOpen] = useState(false);
  const [isEnrollOpen, setIsEnrollOpen] = useState(false);
  const [isMessageModal, setIsMessageModal] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState(false);

  let commentFormData = {
    type: "",
    comment: "",
    markImp: false,
    commentType: ""
  };
  const [commentsValue, setCommentsValue] = useState(commentFormData);
  const [isOpenForComment, setIsOpenForComment] = useState(false);
  const [isAddForComment, setIsAddForComment] = useState(false);

  const handleInputChangeForComment = (e) => {
    var { name, value } = e.target;

    console.log("zhr finSnapshot: ", name, value);

    if (name == "markImp") {
      value = e.target.checked;
    }
    setCommentsValue({
      ...commentsValue,
      [name]: value
    });
  };

  const addNewComment = () => {
    setCommentsValue(commentFormData);
    setIsAddForComment(true);
    setIsOpenForComment(true);
  };

  const closeModalForComment = () => {
    setIsOpenForComment(false);
  };

  const [isChecked, setIsChecked] = useState(false);
  const [isClinicalChecked, setIsClinicalChecked] = useState(false);

  const saveComment = (event) => {
    event.preventDefault();
    if (isAddForComment) {
      commentsValue.markImp = commentsValue.markImp ? commentsValue.markImp : false;
      commentsValue.medCaseId = id;
      actions.addNewComment(commentsValue);
    }

    actions.updateManualReveiw({ medcaseId: id, isChecked: isChecked });
    actions.updateClinicalReview({ medcaseId: id, isClinicalChecked: isClinicalChecked });

    closeModalForComment();
  };

  const commentConfiguration = commentConfig({
    commentsValue,
    onChange: handleInputChangeForComment,
    onClick: closeModalForComment,
    isAdd: isAddForComment,
    disabled: commentsValue.comment || commentsValue.commentType ? false : true
  });

  const checkStatus = () => {
    if (
      (medcaseHeader.medcaseStatus === "Medically Cleared" ||
        medcaseHeader.pgtaAnalysis === "Pre-Enrolled") &&
      medcaseHeader.paymentStatus === "Paid Full Amount" &&
      medcaseHeader.contractStatus === "Signed Contract"
    ) {
      return false;
    } else {
      return true;
    }
  };
  const [saveModal, setIsSaveModal] = useState(false);
  const handleInputChange = (e) => {
    var { name, value } = e.target;
    if (name === "addRadio") {
      values.subRadio = false;
    }
    if (name === "subRadio") {
      values.amount = -values.amount;
      values.addRadio = false;
    }
    setValues({
      ...values,
      [name]: value
    });
  };
  const closeModal = () => {
    setIsOpen(false);
  };

  const saveStatus = (event) => {
    event.preventDefault();
    values.recordedDate = values.recordedDate
      ? moment(values.recordedDate).format("YYYY-MM-DD")
      : null;
    values.transactionDate = values.transactionDate
      ? moment(values.transactionDate).format("YYYY-MM-DD")
      : "";

    actions.postFees({ ...values, medcaseId: id });
    closeModal();
  };

  const handleSaveStatus = (event) => {
    event.preventDefault();
    setIsSaveModal(false);
  };

  const closeSaveModal = () => {
    setIsSaveModal(false);
  };

  useEffect(() => {
    if (success || successRevenue || manualReviewSuccess || clinicalReviewSuccess) {
      setIsMessageModal(true);
      // Added for comment.
      actions.getComment({ medcaseId: id, commentType: "Financial" });
      actions.getComment({ medcaseId: id, commentType: "Clinical" });
    }
    if (errorPayment) {
      setIsWarningOpen(true);
    }
    if (manualReviewSuccess || successEnroll || clinicalReviewSuccess) {
      actions.getMedcaseHeader({ medcaseId: id });
    }

    return () => {
      // actions.resetAddFeesList();
      // actions.resetProgram();
    };
  }, [
    errorMsg,
    success,
    errorPayment,
    addPatientPaymentCC,
    addPatientPaymentCH,
    addPatientPaymentLN,
    addPatientPaymentWT,
    successRevenue,
    manualReviewSuccess,
    clinicalReviewSuccess,
    successEnroll
  ]);

  useEffect(() => {
    if (
      addPatientPaymentCC.length ||
      addPatientPaymentCH.length ||
      addPatientPaymentLN.length ||
      addPatientPaymentWT.length ||
      successRevenue ||
      successFees ||
      successRefund
    ) {
      actions.getFinancialSnapshotPayment({ medcaseId: id });
      actions.getFinancialSnapshotPgta({ medcaseId: id });
      actions.getRevenueFin({ medcaseId: id });
      actions.getMedcaseStatus({ medcaseId: id });

      actions.getMedcaseHeader({ medcaseId: id });
      // actions.getMedcaseDetailsHeader({ medcaseId: id });
      actions.getProgramName({ medcaseId: id });
      actions.getProgramCycle({ medcaseId: id, clinicalTreatmentNo: "1.0" });
    }
  }, [
    addPatientPaymentCC,
    addPatientPaymentCH,
    addPatientPaymentLN,
    addPatientPaymentWT,
    successRevenue,
    successFees,
    successRefund
  ]);

  useEffect(() => {
    if (errorFee) {
      setIsMessageModal(true);
    }
    if (successFees) {
      setIsSaveModal(true);
    }
  }, [successFees, errorFee]);
  const config = finSnapshotConfig({
    values,
    onChange: handleInputChange,
    onClick: closeModal,
    isAdd: false,
    setValues,
    disabled:
      values.transactionDate && values.amount && values.feeType /* && values.netProgramFee */
        ? false
        : true
  });
  const addFees = () => {
    setValues(finSnapshotFormData);
    setIsOpen(true);
  };
  const openRefundDialog = () => {
    setIsRefundOpen(true);
  };
  const openRevenueDialog = () => {
    setIsRevenueOpen(true);
  };
  const openEnrollDialog = () => {
    setIsEnrollOpen(true);
  };
  const closeMessageModal = () => {
    setIsMessageModal(false);
    actions.resetPatientPayment();
    actions.resetRevenue();
    actions.resetProgram();
    actions.resetManualReview();
    actions.resetMessage();
    actions.resetPostEmail();
    actions.resetEmail();
    initPage();
  };
  const [openPatientPayments, setOpenPatientPayments] = useState(false);
  const handlePatientPayments = () => {
    setOpenPatientPayments(true);
  };
  const handleClose = () => {
    setOpenPatientPayments(false);
  };
  const createPatientPayment = (data) => {
    data.amount = data.amount ? Number(data.amount) : 0;
    data.recordedDate = data.recordedDate ? moment(data.recordedDate).format("YYYY-MM-DD") : null;
    data.transactionDate = data.transactionDate
      ? moment(data.transactionDate).format("YYYY-MM-DD")
      : null;
    if (data.paymentType == "creditCard") {
      delete data.paymentType;
      actions.addCreditCardPayment({ medcaseId: id, data });
    } else if (data.paymentType == "check") {
      delete data.paymentType;
      actions.addCheckPayment({ medcaseId: id, data });
    } else if (data.paymentType == "loan") {
      delete data.paymentType;
      actions.addLoanPayment({ medcaseId: id, data });
    } else if (data.paymentType == "wireTransfer") {
      delete data.paymentType;
      actions.addWiredPayment({ medcaseId: id, data });
    }
    setOpenPatientPayments(false);
  };

  const medcaseStatusListDisable = [
    "Program Complete Successful",
    "Program Complete Unsuccessful",
    "Closed",
    "Application Withdrawn",
    "Patient Withdrew",
    "Fertility Access Withdrew"
  ];

  const initPage = () => {
    actions.getFinancialSnapshotPayment({ medcaseId: id });
    actions.getFinancialSnapshotPgta({ medcaseId: id });
    actions.getRevenueFin({ medcaseId: id });
    actions.getMedcaseStatus({ medcaseId: id });

    actions.getMedcaseHeader({ medcaseId: id });
    // actions.getMedcaseDetailsHeader({ medcaseId: id });
    actions.getProgramName({ medcaseId: id });
    actions.getProgramCycle({ medcaseId: id, clinicalTreatmentNo: "1.0" });
  };

  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const closeWarningModal = () => {
    setIsWarningOpen(false);
    initPage();
    actions.resetPatientPayment();
  };

  useEffect(() => {
    if (successMsg && successMsg !== "") {
      console.log("zhr successMsg", successMsg);
      setIsMessageModal(true);
    }
  }, [successMsg]);
  useEffect(() => {
    // console.log("errorEmail", errorEmail);
    if (errorEmail && errorEmail !== "") {
      setIsWarningOpen(true);
    }
  }, [errorEmail]);

  // const handleCloseWarning = () => {
  //   //console.log("hey warning");
  //   setIsWarningOpen(false);

  //   actions.resetPostEmail();
  //   actions.resetEmail();
  //   //props.close();
  // };

  useEffect(() => {
    if (programCycle.length > 0 && isEmailSend === true) {
      sendEmail();
    }
  }, [programCycle]);

  const sendEmail = () => {
    let clinicalTreatmentNo =
      programCycle &&
      programCycle[programCycle.length - 1] &&
      programCycle[programCycle.length - 1].clinicalTreatmentNo;
    let authorizedTreatment =
      programCycle &&
      programCycle[programCycle.length - 1] &&
      programCycle[programCycle.length - 1].authorizedTreatment;
    let isOutcomeEmail = false;

    actions.postEmailOption({
      medcaseId: id,
      toAddresses: [],
      cycle: clinicalTreatmentNo,
      emailType: isOutcomeEmail
        ? "OUTCOME_SUBMITTED"
        : props.emailType
        ? props.emailType
        : "AUTHORIZATION",
      requestedService: authorizedTreatment
    });

    setIsEmailSend(false);
    return "emailsend";
  };

  const validatePGTA = medcaseHeader?.medcaseType == "PGTA" ? true : false;

  const chkChange = (e) => {
    let isCheck = e.target.checked;
    if (!isCheck) {
      actions.updateManualReveiw({ medcaseId: id, isChecked: isCheck });
    } else {
      setIsChecked(isCheck);
      setIsClinicalChecked(medcaseHeader.isClinicalReview);
      addNewComment();
    }
  };

  const chkChangeForClinicalReview = (e) => {
    let isCheck = e.target.checked;
    if (!isCheck) {
      actions.updateClinicalReview({ medcaseId: id, isClinicalChecked: isCheck });
    } else {
      setIsClinicalChecked(isCheck);
      setIsChecked(medcaseHeader.isManualReview);
      addNewComment();
    }
  };

  // const nearestTo100 = (amount) => {
  //   //const updatedAmount = Math.round(amount / 100) * 100;
  //   return amount.toLocaleString();
  // };

  /*
   * Calculation for net patient payment for disable/ unable the Refund button.
   */
  const isNetPatientPayment = () => {
    console.log(
      "zhr checkk" +
        (financialSnapshotPaymentList &&
          financialSnapshotPaymentList.totalPatientPayment -
            financialSnapshotPaymentList.totalRefunds)
    );
    return (
      (financialSnapshotPaymentList &&
        financialSnapshotPaymentList.totalPatientPayment -
          financialSnapshotPaymentList.totalRefunds) <= 0
    );
  };

  const calculateAmountDue = (dueAmount, totalrefund) => {
    let totalDue = 0;
    if (dueAmount >= 0) {
      totalDue = "$" + priceFormat(parseFloat(dueAmount) + parseFloat(totalrefund));
    } else {
      totalDue = priceFormat(parseFloat(dueAmount) + parseFloat(totalrefund)).replace("-", "-$");
    }

    return totalDue;
  };

  return (
    <>
      <Box sx={{ ml: 1, border: "1px solid #D3D3D3" }} className="m-r-8 m-t-10">
        <Stack
          direction="row"
          sx={{
            marginLeft: "21px",
            fontWeight: "bold",
            marginTop: "15px",
            justifyContent: "flex-start",
            alignItems: "center"
          }}>
          <InputLabel sx={{ fontWeight: "bold" }}>Program: &nbsp;</InputLabel>
          <Typography sx={{ fontWeight: "bold" }}>
            {programName.program === null ? "--" : programName.program}
          </Typography>
        </Stack>
        <Stack direction="row" className="pad-10 flex-between">
          <Stack direction="column" className="pad-10 flex-start">
            <InputLabel>Treatment: </InputLabel>
            <Typography sx={{ fontWeight: "bold" }}>
              {programName?.treatmentPlanCustomName !== null
                ? programName?.treatmentPlanCustomName
                : programName.treatment}
            </Typography>
          </Stack>
          <Stack className="pad-10 flex-start">
            <InputLabel> Program Fee: </InputLabel>
            <Typography sx={{ fontWeight: "bold" }}>
              ${" "}
              {programName.fee === null
                ? "--"
                : programName && programName.fee && priceFormat(programName.fee)}
            </Typography>
          </Stack>
          <Stack className="pad-10 flex-start">
            <InputLabel> Total Discount Amount: </InputLabel>
            <Typography sx={{ fontWeight: "bold" }}>
              ${" "}
              {programName.totalDiscountAmount === null
                ? "--"
                : programName && priceFormat(programName.totalDiscountAmount)}
            </Typography>
          </Stack>
          <Stack className="pad-10 flex-start">
            <InputLabel> Remaining Program Fee: </InputLabel>
            <Typography sx={{ fontWeight: "bold" }}>
              ${" "}
              {(programName &&
                programName.fee &&
                programName.totalDiscountAmount &&
                priceFormat(programName.fee - programName.totalDiscountAmount)) ||
                "0.00"}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" className="pad-8">
          <Button
            variant="contained"
            className="btn-usf"
            sx={{ mx: "10px" }}
            onClick={addFees}
            // disabled={
            //   (medcaseStatusListDisable.indexOf(medcaseHeader.medcaseStatus) > -1 ||
            //   financialSnapshotPaymentList.netProgramFees ===
            //     financialSnapshotPaymentList.totalPatientPayment
            //     ? true
            //     : false) || validatePGTA
            // }
            disabled={medcaseStatusListDisable.indexOf(medcaseHeader.medcaseStatus) > -1}>
            Fees
          </Button>
          <Button
            variant="contained"
            className="btn-usf"
            onClick={handlePatientPayments}
            sx={{ mx: "10px" }}
            // disabled={
            //   (medcaseStatusListDisable.indexOf(medcaseHeader.medcaseStatus) > -1 ||
            //   financialSnapshotPaymentList.netProgramFees ===
            //     financialSnapshotPaymentList.totalPatientPayment
            //     ? true
            //     : false) || validatePGTA
            // }
            disabled={medcaseStatusListDisable.indexOf(medcaseHeader.medcaseStatus) > -1}>
            Patient Payments
          </Button>
          <Button
            variant="contained"
            className="btn-usf"
            sx={{ mx: "10px" }}
            onClick={openEnrollDialog}
            //disabled={checkStatus() || validatePGTA}
            disabled={checkStatus()}>
            Enroll
          </Button>
          <Button
            variant="contained"
            className="btn-usf"
            sx={{ mx: "10px" }}
            onClick={openRefundDialog}
            disabled={isNetPatientPayment()}
            //disabled={validatePGTA}
          >
            Refund
          </Button>
          <Button
            variant="contained"
            className="btn-usf"
            onClick={openRevenueDialog}
            disabled={validatePGTA}>
            Revenue
          </Button>
        </Stack>
        <Box sx={{ ml: 1 }} className="m-r-8 m-t-10">
          <Grid container sx={{ my: "5px" }}>
            <Grid item xs={6}>
              <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
              <Typography
                className={
                  validatePGTA
                    ? "m-l-5 m-t-5 trak-head-medtype pgta"
                    : "m-l-5 m-t-5 trak-head-medtype non-pgta"
                }>
                Payment
              </Typography>
              <Divider
                sx={{
                  border: "1px solid",
                  color: "#13BFEA",
                  backgroundColor: "#13BFEA",
                  marginBottom: "8px"
                }}
              />
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography
                  className={
                    validatePGTA
                      ? " m-l-5 m-t-5 table-title-bold pgta"
                      : " m-l-5 m-t-5 table-title-bold"
                  }>
                  Net Program Fees{" "}
                </Typography>
                <Typography
                  className={
                    validatePGTA ? "m-l-5 m-t-5 value-bold pgta" : " m-l-5 m-t-5 value-bold"
                  }>
                  $
                  {validatePGTA
                    ? "0.00"
                    : financialSnapshotPaymentList &&
                      priceFormat(financialSnapshotPaymentList.netProgramFees)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Total Patient Payments</Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {validatePGTA
                    ? "0.00"
                    : financialSnapshotPaymentList &&
                      priceFormat(financialSnapshotPaymentList.totalPatientPayment)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Program Refunds </Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {validatePGTA
                    ? "0.00"
                    : financialSnapshotPaymentList &&
                      priceFormat(financialSnapshotPaymentList.programRefunds)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Pregnancy Loss Refunds</Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {validatePGTA
                    ? "0.00"
                    : financialSnapshotPaymentList &&
                      priceFormat(financialSnapshotPaymentList.pregnancyLossRefunds)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Miscellaneous Refunds</Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {validatePGTA
                    ? "0.00"
                    : financialSnapshotPaymentList &&
                      priceFormat(financialSnapshotPaymentList.miscellaneousRefunds)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Total Refunds </Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {validatePGTA
                    ? "0.00"
                    : financialSnapshotPaymentList &&
                      priceFormat(financialSnapshotPaymentList.totalRefunds)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography
                  className={
                    validatePGTA
                      ? " m-l-5 m-t-5 table-title-bold pgta"
                      : " m-l-5 m-t-5 table-title-bold"
                  }>
                  Net Patient Payments{" "}
                </Typography>
                <Typography
                  className={
                    validatePGTA ? "m-l-5 m-t-5 value-bold pgta" : " m-l-5 m-t-5 value-bold"
                  }>
                  $
                  {validatePGTA
                    ? "0.00"
                    : financialSnapshotPaymentList &&
                      priceFormat(
                        financialSnapshotPaymentList.totalPatientPayment -
                          financialSnapshotPaymentList.totalRefunds
                      )}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#FACFDA"
                }}>
                <Typography
                  className={
                    validatePGTA
                      ? "m-l-5 m-t-5 table-title-bold pgta"
                      : "m-l-5 m-t-5 table-title-bold"
                  }>
                  Amount Due
                </Typography>
                <Typography
                  className={
                    validatePGTA ? "m-l-5 m-t-5 value-bold pgta" : " m-l-5 m-t-5 value-bold"
                  }>
                  {validatePGTA
                    ? "$0.00"
                    : financialSnapshotPaymentList &&
                      financialSnapshotPaymentList.amountDue &&
                      financialSnapshotPaymentList.amountDue > 0
                    ? "$" +
                      priceFormat(
                        parseFloat(financialSnapshotPaymentList.amountDue) +
                          parseFloat(financialSnapshotPaymentList.totalRefunds)
                      )
                    : calculateAmountDue(
                        financialSnapshotPaymentList.amountDue,
                        financialSnapshotPaymentList.totalRefunds
                      )}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
              <Typography
                className={
                  validatePGTA
                    ? "m-l-5 m-t-5 trak-head-medtype pgta"
                    : "m-l-5 m-t-5 trak-head-medtype non-pgta"
                }>
                Revenue
              </Typography>
              <Divider
                sx={{
                  border: "1px solid",
                  color: "#13BFEA",
                  backgroundColor: "#13BFEA",
                  marginBottom: "8px"
                }}
              />
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Practice Payments </Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {validatePGTA ? "0.00" : revenueData && priceFormat(revenueData.practicePayments)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Medical Cost Reserve </Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {validatePGTA
                    ? "0.00"
                    : revenueData && priceFormat(revenueData.medicalCostReserve)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Total Expenses </Typography>
                <Typography className="m-l-5 m-t-5">
                  ${validatePGTA ? "0.00" : revenueData && priceFormat(revenueData.totalExpenses)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography
                  className={
                    validatePGTA
                      ? "m-l-5 m-t-5 table-title-bold pgta"
                      : "m-l-5 m-t-5 table-title-bold"
                  }>
                  Revenue Recognized
                </Typography>
                <Typography
                  className={
                    validatePGTA ? "m-l-5 m-t-5 value-bold pgta" : " m-l-5 m-t-5 value-bold"
                  }>
                  $
                  {validatePGTA
                    ? "0.00"
                    : revenueData && priceFormat(revenueData.revenueRecognized)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography
                  className={
                    validatePGTA
                      ? "m-l-5 m-t-5 table-title-bold pgta"
                      : "m-l-5 m-t-5 table-title-bold"
                  }>
                  Revenue Deferred
                </Typography>
                <Typography
                  className={
                    validatePGTA ? "m-l-5 m-t-5 value-bold pgta" : " m-l-5 m-t-5 value-bold"
                  }>
                  ${validatePGTA ? "0.00" : revenueData && priceFormat(revenueData.revenueDeferred)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography
                  className={
                    validatePGTA
                      ? "m-l-5 m-t-5 table-title-bold pgta"
                      : "m-l-5 m-t-5 table-title-bold"
                  }>
                  Contract Exp Date
                </Typography>
                <Typography
                  className={
                    validatePGTA ? "m-l-5 m-t-5 value-bold pgta" : " m-l-5 m-t-5 value-bold"
                  }>
                  {validatePGTA
                    ? "mm/dd/yyyy"
                    : revenueData.contractExpDate &&
                      new Date(revenueData.contractExpDate).toLocaleDateString()}
                </Typography>
              </Stack>
              <Grid container className="modal-stackMedCase">
                <Grid item xs={2}>
                  <InputLabel
                    style={{
                      whiteSpace: "break-spaces",
                      color: medcaseHeader.isManualReview == true ? "red" : "black"
                    }}>
                    Financial Review Required
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    size="small"
                    fullWidth
                    autoComplete="off"
                    name="manualReviewcheckbox"
                    checked={medcaseHeader.isManualReview == true ? true : false}
                    onChange={chkChange}
                    style={{
                      color: medcaseHeader.isManualReview == true ? "red" : "black"
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputLabel
                    style={{
                      whiteSpace: "break-spaces",
                      color: medcaseHeader.isClinicalReview == true ? "red" : "black"
                    }}>
                    Clinical Review Required
                  </InputLabel>
                </Grid>
                <Grid item xs={4}>
                  <Checkbox
                    size="small"
                    fullWidth
                    autoComplete="off"
                    name="clinicalReviewcheckbox"
                    checked={medcaseHeader.isClinicalReview == true ? true : false}
                    onChange={chkChangeForClinicalReview}
                    style={{
                      color: medcaseHeader.isClinicalReview == true ? "red" : "black"
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={4}>
              <Divider sx={{ border: "1px solid", color: "#13BFEA", backgroundColor: "#13BFEA" }} />
              <Typography
                className={
                  medcaseHeader?.medcaseType != "PGTA"
                    ? "m-l-5 m-t-5 trak-head-medtype pgta"
                    : "m-l-5 m-t-5 trak-head-medtype non-pgta"
                }>
                PGTA Analysis
              </Typography>
              <Divider
                sx={{
                  border: "1px solid",
                  color: "#13BFEA",
                  backgroundColor: "#13BFEA",
                  marginBottom: "8px"
                }}
              />
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography
                  className={
                    medcaseHeader?.medcaseType != "PGTA"
                      ? "m-l-5 m-t-5 table-title-bold pgta"
                      : "m-l-5 m-t-5 table-title-bold"
                  }>
                  Net Program Fees{" "}
                </Typography>
                <Typography
                  className={
                    medcaseHeader?.medcaseType != "PGTA"
                      ? "m-l-5 m-t-5 value-bold pgta"
                      : " m-l-5 m-t-5 value-bold"
                  }>
                  $
                  {medcaseHeader?.medcaseType != "PGTA"
                    ? "0"
                    : financialSnapshotPgtaList &&
                      financialSnapshotPgtaList.netFees &&
                      nearestTo100(financialSnapshotPgtaList.netFees)}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Total Patient Payments</Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {medcaseHeader?.medcaseType != "PGTA"
                    ? "0"
                    : financialSnapshotPgtaList &&
                      financialSnapshotPgtaList.patientPayment &&
                      financialSnapshotPgtaList.patientPayment.toLocaleString()}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  backgroundColor: "#F9F9F9",
                  marginBottom: "15px",
                  marginRight: "20px"
                }}>
                <Typography className="m-l-5 m-t-5 table-title">Total Lab Payout </Typography>
                <Typography className="m-l-5 m-t-5">
                  $
                  {medcaseHeader?.medcaseType != "PGTA"
                    ? "0"
                    : financialSnapshotPgtaList &&
                      financialSnapshotPgtaList.labPayout &&
                      financialSnapshotPgtaList.labPayout.toLocaleString()}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography
                  className={
                    medcaseHeader?.medcaseType != "PGTA"
                      ? "m-l-5 m-t-5 table-title-bold pgta"
                      : "m-l-5 m-t-5 table-title-bold"
                  }>
                  Service Revenue
                </Typography>
                <Typography
                  className={
                    medcaseHeader?.medcaseType != "PGTA"
                      ? "m-l-5 m-t-5 value-bold pgta"
                      : " m-l-5 m-t-5 value-bold"
                  }>
                  $
                  {medcaseHeader?.medcaseType != "PGTA"
                    ? "0"
                    : financialSnapshotPgtaList &&
                      financialSnapshotPgtaList.serviceRevenue &&
                      financialSnapshotPgtaList.serviceRevenue.toLocaleString()}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#EDEDED"
                }}>
                <Typography
                  className={
                    medcaseHeader?.medcaseType != "PGTA"
                      ? "m-l-5 m-t-5 table-title-bold pgta"
                      : "m-l-5 m-t-5 table-title-bold"
                  }>
                  Completion Revenue
                </Typography>
                <Typography
                  className={
                    medcaseHeader?.medcaseType != "PGTA"
                      ? "m-l-5 m-t-5 value-bold pgta"
                      : " m-l-5 m-t-5 value-bold"
                  }>
                  $
                  {medcaseHeader?.medcaseType != "PGTA"
                    ? "0"
                    : financialSnapshotPgtaList &&
                      financialSnapshotPgtaList.completionRevenue &&
                      financialSnapshotPgtaList.completionRevenue.toLocaleString()}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  marginBottom: "15px",
                  marginRight: "20px",
                  backgroundColor: "#FACFDA"
                }}>
                <Typography
                  className={
                    medcaseHeader?.medcaseType != "PGTA"
                      ? "m-l-5 m-t-5 table-title-bold pgta"
                      : "m-l-5 m-t-5 table-title-bold"
                  }>
                  Amount Due
                </Typography>
                <Typography
                  className={
                    medcaseHeader?.medcaseType != "PGTA"
                      ? "m-l-5 m-t-5 value-bold pgta"
                      : " m-l-5 m-t-5 value-bold"
                  }>
                  $
                  {medcaseHeader?.medcaseType != "PGTA"
                    ? "0"
                    : financialSnapshotPgtaList &&
                      financialSnapshotPgtaList.amountDue &&
                      nearestTo100(financialSnapshotPgtaList.amountDue)}
                </Typography>
              </Stack>
              <Grid container className="modal-stackMedCase">
                <Grid item xs={4}>
                  <InputLabel style={{ whiteSpace: "break-spaces" }}>
                    Manual Review Required
                  </InputLabel>
                </Grid>
                <Grid item xs={8}>
                  <Checkbox
                    size="small"
                    fullWidth
                    autoComplete="off"
                    name="manualReviewcheckbox"
                    checked={medcaseHeader.isManualReview}
                    onChange={chkChange}
                  />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Box>
        <ProgramCycles medcaseId={id} medcaseType={medcaseHeader.medcaseType} />
        <ClinicalComments medcaseType={medcaseHeader.medcaseType} />
        <TransactionLog
          medcaseId={id}
          paymentCreated={success}
          medcaseType={medcaseHeader?.medcaseType}
        />
        <FinancialComments medcaseType={medcaseHeader.medcaseType} />
        {openPatientPayments ? (
          <PatientPaymentsModal
            open={openPatientPayments}
            handleClose={handleClose}
            createPatientPayment={createPatientPayment}
            invoiceId={medcaseHeader?.medcaseInvoice}
            netProgramFee={financialSnapshotPaymentList.netProgramFees}
            totalPatientPayment={financialSnapshotPaymentList?.totalPatientPayment || 0}
            amountDue={financialSnapshotPaymentList.amountDue}
          />
        ) : null}
      </Box>

      <CommonModal open={isOpen} config={config} isAdd={false} handleSubmit={saveStatus} />
      <SaveModal open={saveModal} close={closeSaveModal} handleSave={handleSaveStatus} />
      {isRefundOpen ? (
        <MedcaseRefund
          openDialog={isRefundOpen}
          close={() => {
            setIsRefundOpen(false);
            initPage();
          }}
          netProgramFee={financialSnapshotPaymentList.netProgramFees}
        />
      ) : null}
      {isRevenueOpen ? (
        <AddRevenue
          openDialog={isRevenueOpen}
          close={() => {
            setIsRevenueOpen(false);
            initPage();
          }}
          netProgramFee={financialSnapshotPaymentList.netProgramFees}
          medcaseId={id}
        />
      ) : (
        ""
      )}

      {isEnrollOpen ? (
        <MedcaseEnroll
          openDialog={isEnrollOpen}
          medcaseId={id}
          close={() => {
            setIsEnrollOpen(false);
            initPage();
            setIsEmailSend(true);
          }}
          medcaseStatus={medcaseHeader.medcaseStatus}
          paymentStatus={medcaseHeader.paymentStatus}
          contractStatus={medcaseHeader.contractStatus}
        />
      ) : null}
      <MessageModal
        open={isMessageModal}
        message={
          successRevenue ||
          manualReviewSuccess ||
          clinicalReviewSuccess ||
          errorFee ||
          "Patients Payment Applied"
        }
        //error={errorMsg}
        closeModal={closeMessageModal}
      />
      <WarnningMessageModal
        open={isWarningOpen}
        message={errorPayment || patientPaymentWarning}
        closeModal={closeWarningModal}
        cancelModal={closeWarningModal}
      />
      <LoadingModal open={props.loadingPaymentStatus || loading || loadingFee || loadingRevenue} />
      {isMessageModal && successMsg && (
        <MessageModal open={isMessageModal} message={successMsg} closeModal={closeMessageModal} />
      )}
      <CommonModal
        open={isOpenForComment}
        config={commentConfiguration}
        isAdd={isAddForComment}
        handleSubmit={saveComment}
      />
    </>
  );
};

const mapStateToProps = ({
  programNameReducer,
  financialSnapshotPaymentReducer,
  patientPaymentReducer,
  statusReducer,
  // medcaseHeaderReducer,
  newPrReducer,
  addRevenueReducer,
  transactionLogReducer,
  enrollReducer,
  manualRefundReducer,
  programCycleReducer
}) => {
  return {
    financialSnapshotPgtaList: financialSnapshotPaymentReducer.financialSnapshotPgtaList,
    loadingPaymentStatus: financialSnapshotPaymentReducer.loading,
    medcaseStatus: statusReducer.medcaseStatus,
    newPrList: newPrReducer.newPrList,
    medcaseHeader: statusReducer.medcaseHeader,
    // showPgta: medcaseHeaderReducer.medcaseDetails,
    programName: programNameReducer.programName,
    errorMsg: programNameReducer.error,
    financialSnapshotPaymentList: financialSnapshotPaymentReducer.financialSnapshotPaymentList,
    addPatientPaymentCC: patientPaymentReducer.addPatientPaymentCC,
    addPatientPaymentLN: patientPaymentReducer.addPatientPaymentLN,
    addPatientPaymentCH: patientPaymentReducer.addPatientPaymentCH,
    addPatientPaymentWT: patientPaymentReducer.addPatientPaymentWT,
    success: patientPaymentReducer.success,
    loading: patientPaymentReducer.loading,
    errorPayment: patientPaymentReducer.error,
    revenueData: addRevenueReducer.revenueData,
    loadingFee: transactionLogReducer.loading,
    successRevenue: addRevenueReducer.success,
    loadingRevenue: addRevenueReducer.loading,
    manualReviewSuccess: financialSnapshotPaymentReducer.manualReviewSuccess,
    clinicalReviewSuccess: financialSnapshotPaymentReducer.clinicalReviewSuccess,
    successEnroll: enrollReducer.success,
    successFees: transactionLogReducer.successFees,
    errorFee: transactionLogReducer.errorFee,
    successRefund: manualRefundReducer.success,
    programCycle: programCycleReducer.programCycle,
    successMsg: financialSnapshotPaymentReducer.success,
    errorEmail: financialSnapshotPaymentReducer.errorEmail
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    getMedcaseStatus: (payload) => {
      dispatch(actionType.getMedcaseStatus(payload));
    },
    getMedcaseHeader: (payload) => {
      dispatch(actionType.getMedcaseHeader(payload));
    },
    // getMedcaseDetailsHeader: (payload) => {
    //   dispatch(actionType.getMedcaseDetailsHeader(payload));
    // },
    getProgramName: (payload) => {
      dispatch(actionType.getProgramName(payload));
    },
    getFinancialSnapshotPayment: (payload) => {
      dispatch(actionType.getFinancialSnapshotPayment(payload));
    },
    postFees: (payload) => {
      dispatch(actionType.postFees(payload));
    },
    resetMessage: () => {
      dispatch(actionType.resetMessage());
    },
    addCreditCardPayment: (payload) => {
      dispatch(actionType.addCreditCardPayment(payload));
    },
    addCheckPayment: (payload) => {
      dispatch(actionType.addCheckPayment(payload));
    },
    addLoanPayment: (payload) => {
      dispatch(actionType.addLoanPayment(payload));
    },
    addWiredPayment: (payload) => {
      dispatch(actionType.addWiredPayment(payload));
    },
    resetPatientPayment: () => {
      dispatch(actionType.resetPatientPayment());
    },
    resetProgram: () => {
      dispatch(actionType.resetProgram());
    },
    getFinancialSnapshotPgta: (payload) => {
      dispatch(actionType.getFinancialSnapshotPgta(payload));
    },
    getProgramCycle: (payload) => {
      dispatch(actionType.getProgramCycle(payload));
    },
    getRevenueFin: (payload) => {
      dispatch(actionType.getRevenueFin(payload));
    },
    updateManualReveiw: (payload) => {
      dispatch(actionType.updateManualReview(payload));
    },
    updateClinicalReview: (payload) => {
      dispatch(actionType.updateClinicalReview(payload));
    },
    resetRevenue: () => {
      dispatch(actionType.resetRevenue());
    },
    resetManualReview: () => {
      dispatch(actionType.resetManualReview());
    },
    postEmailOption: (payload) => {
      dispatch(actionType.postEmailOption(payload));
    },
    resetEmail: () => {
      dispatch(actionType.resetEmailList());
    },
    resetPostEmail: () => {
      dispatch(actionType.resetPostEmailOption());
    },
    getComment: (payload) => {
      dispatch(actionType.getComment(payload));
    },
    addNewComment: (payload) => {
      dispatch(actionType.addNewComment(payload));
    }
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connect(mapStateToProps, mapDispatchToProps)(FinSnapshot));
