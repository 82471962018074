import { Box, Button, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";
import { connect } from "react-redux";
import * as actionType from "../../actions";

const LinkMedcaseModal = (props) => {
  const { open, close, medcaseId, actions } = props;
  const handleLink = () => {
    actions.linkMedcase({ medcaseId: medcaseId });
    close();
  };

  return (
    <Modal open={open}>
      <Box className="modal-style-delete">
        <Stack direction="row" className="modal-stack">
          <Typography>Link Medcase</Typography>
          <IconButton onClick={close}>
            <img alt="" src="/Assets/close.png" />
          </IconButton>
        </Stack>
        <Divider className="modal-divider" />
        <Stack direction="column">
          <Typography variant="body1" sx={{ my: 2 }}>
            Are you sure you want to link the selected Medcase no. {medcaseId} To Current Medcase
          </Typography>
        </Stack>
        <Stack direction="row" className="modal-stack">
          <Button variant="outlined" onClick={close}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleLink}>
            Link Medcase
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

const mapStateToProps = ({ medcaseReducer }) => {
  return {
    loading: medcaseReducer.loading,
    errorMsg: medcaseReducer.error,
    successMsg: medcaseReducer.successLink
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    linkMedcase: (payload) => {
      dispatch(actionType.linkMedcase(payload));
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkMedcaseModal);
