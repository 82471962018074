import { call, put, takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/ActionTypes";
import apis from "../../shared/constants/api-url";
import signatureV4CreateCanonicalRequest, {
  createRequestBody
} from "../../shared/components/sigv4Client";
import { getTemporaryCredentials } from "../../shared/components/SignApi";
//import * as token from "../../shared/constants/constants";
import { appAuthencationCheck } from "../../../modules/appAuthencationCheck";

function* fetchGeneticTestingList(action) {
  const { payload } = action;

  try {
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/PGTATransactionLog`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // const response = yield call(() =>
    //   apis.get(`/medcases/${payload.medcaseId}/PGTATransactionLog`)
    // );
    yield put({
      type: actionTypes.GENETICTESTING_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.GENETICTESTING_LIST_FAIL, errorMsg: error.response.data });
    }
  }
}

function* getGeneticTestingSaga() {
  yield takeEvery(actionTypes.GENETICTESTING_LIST_REQUESTED, fetchGeneticTestingList);
}

function* fetchPgtaSummary(action) {
  const { payload } = action;

  try {
    const requestBody = createRequestBody(
      "get",
      `/medcases/${payload.medcaseId}/PGTASummary`,
      "",
      ""
    );
    const request = signatureV4CreateCanonicalRequest(requestBody, getTemporaryCredentials());
    const response = yield call(() => apis(request));
    // const response = yield call(() => apis.get(`/medcases/${payload.medcaseId}/PGTASummary`));
    yield put({
      type: actionTypes.PGTA_SUMMARY_ANALYSIS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    if (appAuthencationCheck(error) === true) {
      yield put({ type: actionTypes.PGTA_SUMMARY_ANALYSIS_FAIL, errorMsg: error.response.data });
    }
  }
}

function* getPgtaSummarySaga() {
  yield takeEvery(actionTypes.PGTA_SUMMARY_ANALYSIS_REQUESTED, fetchPgtaSummary);
}

export default function* geneticTestingSaga() {
  yield all([getGeneticTestingSaga(), getPgtaSummarySaga()]);
}
