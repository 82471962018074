import React, { useState, useEffect, useCallback } from "react";
import Close from "@mui/icons-material/Close";
import { Modal, IconButton, Box, Stack, Typography } from "@mui/material";
import * as actionType from "../../../admin/actions";
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import DataGridPremiumComp from "../../../shared/components/DataGridPremiumComp";

const columns = [
  {
    field: "program",
    headerName: "PROGRAM",
    width: 100,
    flex: 1
  },
  {
    field: "type",
    headerName: "TYPE",
    width: 50,
    editable: false,
    flex: 1
  },
  {
    field: "relativeValueNumber",
    headerName: "RELATIVE VALUE",
    width: 100,
    editable: false,
    flex: 1
  },
  {
    field: "updatedDate",
    headerName: "EFFECTIVE DATE",
    width: 50,
    editable: false,
    flex: 1
  }
];

const RelativeValueModal = (props) => {
  const [pageSize, setPageSize] = useState(10);

  const {
    relativeValueList = [],
    actions,
    loading,
    isOpen,
    handleClose,
    errorMsg,
    successMsg
  } = props;

  // const rows =
  //   relativeValueList.length > 0 &&
  //   relativeValueList.map((item, index) => {
  //     item.id = index;
  //     return item;
  //   });

  const initFetch = useCallback(() => {
    actions.getRelativeValue();
  }, []);

  useEffect(() => {
    if (relativeValueList.length == 0) {
      initFetch();
    }
  }, [errorMsg, successMsg]);

  return (
    <Modal open={isOpen} aria-labelledby="modal-add-title" aria-describedby="modal-add-description">
      <Box className="modal-style-contact" style={{ maxHeight: "80vh", overflow: "auto" }}>
        <Stack direction="row" sx={{ alignItems: "center", justifyContent: "space-between" }}>
          <Typography>Relative Value List</Typography>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
        <Box className="grid-style">
          <DataGridPremiumComp
            rows={relativeValueList}
            columns={columns}
            loading={loading}
            pageSizeOptions={[10, 25, 50]}
            onPaginationModelChange={(newPageSize) => setPageSize(newPageSize)}
            pageSize={pageSize.pageSize}
            paginationModel={pageSize}
            pagination
            getRowId={() => uuidv4()}
          />
        </Box>
      </Box>
    </Modal>
  );
};

const mapStateToProps = ({ relativeValueReducer }) => {
  return {
    loading: relativeValueReducer.loading,
    relativeValueList: relativeValueReducer.relativeValueList,
    errorMsg: relativeValueReducer.error,
    successMsg: relativeValueReducer.success
  };
};
const mapDispatchToProps = (dispatch) => ({
  actions: {
    getRelativeValue: () => {
      dispatch(actionType.getRelativeValue());
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RelativeValueModal);
